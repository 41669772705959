import styled, { css } from "styled-components";

export const WrapperRef = styled.div`
  position: sticky;
`;

export const DropDownSelect = styled.div`
  max-height: 95px;
  border-bottom: 0.1px solid #D6D6D6;
  background-color: white;
  overflow-y: scroll;
  position: relative;
  width: 100%;
`;

export const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 20;
`;

export const DropDownList = styled.ul`
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: opacity 0.3s, visibility 0.3s;
  background: ${({ theme }) => theme.white};
  border-radius: 0 0 5px 5px;
  animation: on 0.2s ease-in;
  animation-fill-mode: forwards;
  z-index: 20;

  @keyframes on {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

type PropsList = {
  widthOption?: string;
};

export const ListItem = styled.li<PropsList>`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 3px;
  height: 41px;
  padding-left: 16px;
  font-style: normal;
  font-weight: 500;
  align-items: center;
  font-size: 14px;
  line-height: 147%;
  z-index: 20;

  ${({ theme, widthOption }) => css`
    background: ${theme.white};
    font-family: ${theme.fontFamily.primary};
    color: ${theme.GunPowder};
    width: cal(${`${widthOption}px`} -5px);
  `};
  &:last-child {
    border-radius: 0 0 5px 5px;
    box-shadow: 0px 8px 26px -18px rgba(0, 0, 0, 0.25);
  }
`;

export const DownArrow = styled.img<{ isOpen: boolean }>`
  position: absolute;
  right: 16px;
  animation: ${({ isOpen }) =>
    isOpen ? "spinOut .3s forwards" : "spinIn .3s forwards"};

  @keyframes spinOut {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
  @keyframes spinIn {
    from {
      transform: rotate(180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;
