import React, { useState, useEffect } from "react";
import { useTranslation, getI18n } from "react-i18next";
import { useDispatch } from "react-redux";
import CampaignsDetailActions from "../../redux/reducers/campaing-detail.reducer";
import { getHasSession } from "../../pages/CampaingNewDetail/CampaingNewDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { capitalizeWord } from "../../lib/utils";
import moment from "moment";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import InvestDetail from "../InvestDetail/InvestDetail";
import "./InvestListAccordion.scss";
import "moment/locale/es";

enum ICampaingTypes {
  Debt = 1,
  Shares = 2,
  ConvertibleNote = 3
}

const BusinessLine: any = {
  1: "debt",
  2: "shares",
  3: "convertibleNote"
};

const InvestListAccordion = ({ investmentData }: any) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(
    investmentData && investmentData.firstElement ? investmentData.id : 0
  );
  const [help, setHelp] = useState(false);
  const dispatch = useDispatch();
  const localLocale = moment(investmentData.investment_updated_at);
  localLocale.locale(getI18n().language);

  const validHelp = () => {
    let widthInvestmentDestinyName: any = document.getElementById(
      `investment_destiny_name_${investmentData.id}`
    );
    if (
      widthInvestmentDestinyName?.scrollHeight >
      widthInvestmentDestinyName?.offsetHeight
    ) {
      setHelp(true);
    } else {
      setHelp(false);
    }
  };

  useEffect(() => {
    validHelp();
    window.addEventListener("resize", validHelp, false);
  });

  const renderAccordionItems = () => {
    return (
      <>
        <div className="investment">
          <p className="investment__date">
            {`${t(
              `investment.investmentsList.date${
                investmentData.investment_state === "canceled" ? "Canceled" : ""
              }`
            )} ${capitalizeWord(
              localLocale.format("dddd, D")
            )} de ${capitalizeWord(localLocale.format("MMMM YYYY"))}`}
          </p>
          <div className="investment__accordion-item">
            <input
              className="investment__accordion-checkbox"
              type="checkbox"
              id={investmentData.id}
              data-testid="accordionCheckbox"
              onChange={() => {
                const itemActivated =
                  active === investmentData.id ? 0 : investmentData.id;
                setActive(itemActivated);
              }}
              checked={active === investmentData.id}
            />
            <label className="investment__accordion-label">
              <div className="investment__accordion-info">
                <div className="investment__accordion-row_tag">
                  <div className="investment__business-line">
                    <div
                      className={`investment__tags ${
                        investmentData.business_line == ICampaingTypes.Shares
                          ? "Shares"
                          : investmentData.business_line ==
                            ICampaingTypes.ConvertibleNote
                          ? "ConvertibleNote"
                          : investmentData.business_line == ICampaingTypes.Debt
                          ? "Debt"
                          : ""
                      }`}
                    >
                      {t(
                        `businessLines.${
                          BusinessLine[investmentData.business_line]
                        }`
                      )}
                      <span
                        id="id-icon-rtt"
                        data-tip={`tag_line_Accordion_${investmentData.id}`}
                        data-for={`tag_line_Accordion_${investmentData.id}`}
                        className={`investment__tags__icon ${
                          investmentData.business_line == ICampaingTypes.Shares
                            ? "Shares_backGround"
                            : investmentData.business_line ==
                              ICampaingTypes.ConvertibleNote
                            ? "ConvertibleNote_backGround"
                            : investmentData.business_line ==
                              ICampaingTypes.Debt
                            ? "Debt_backGround"
                            : ""
                        }`}
                      ></span>
                      <ReactTooltip
                        className={`investment__tooltip ${
                          investmentData.business_line == ICampaingTypes.Debt
                            ? "tooltip_accordeon_deb"
                            : investmentData.business_line ==
                              ICampaingTypes.Shares
                            ? "tooltip_accordeon_share"
                            : investmentData.business_line ==
                              ICampaingTypes.ConvertibleNote
                            ? "tooltip_accordeon_note"
                            : ""
                        }`}
                        id={`tag_line_Accordion_${investmentData.id}`}
                        place="top"
                        effect="solid"
                        placement="topCenter"
                      >
                        <div>
                          {investmentData.business_line == ICampaingTypes.Shares
                            ? "Inviertes comprando acciones de una empresa para volverte su socio"
                            : investmentData.business_line ==
                              ICampaingTypes.ConvertibleNote
                            ? "Instrumento financiero que te da el derecho a recibir acciones de una empresa si se cumplen las condiciones de conversión de la nota."
                            : investmentData.business_line ==
                              ICampaingTypes.Debt
                            ? "Es un préstamo que le haces a la empresa y del cual recibes una rentabilidad periódica."
                            : ""}
                        </div>
                      </ReactTooltip>
                    </div>
                  </div>
                  <div className="investment__status">
                    <div
                      className={`investment__status__oval ${investmentData.investment_state}`}
                    ></div>
                    {t(
                      `investment.investmentsList.${investmentData.investment_state}`
                    )}
                  </div>
                </div>
              </div>
              <div className="icon">
                <label htmlFor={investmentData.id}>
                  <FontAwesomeIcon icon={faChevronDown} />
                </label>
              </div>
            </label>
            <div className="investment__accordion-row_campaign">
              <div
                id={`investment_destiny_${investmentData.id}`}
                className="investment__destiny"
              >
                <div
                  id={`investment_destiny_name_${investmentData.id}`}
                  data-tip={help ? investmentData.campaign_name : ""}
                  className={`investment__destiny__name ${
                    investmentData.business_line == ICampaingTypes.Shares
                      ? "Shares_text"
                      : investmentData.business_line ==
                        ICampaingTypes.ConvertibleNote
                      ? "ConvertibleNote_text"
                      : investmentData.business_line == ICampaingTypes.Debt
                      ? "Debt_text"
                      : ""
                  }`}
                  data-testid="campaign-link"
                >
                  <span
                    className="investTo"
                    id={`investTo_${investmentData.id}`}
                  >
                    {t("investment.investmentsList.investTo")}
                  </span>{" "}
                  <span
                    className="campaign_name"
                    id={`campaign_name_${investmentData.id}`}
                    data-testid="spanCampaignName"
                    onClick={() =>
                      dispatch(
                        CampaignsDetailActions.getCampaingData(
                          investmentData.campaign_id,
                          getHasSession()
                        )
                      )
                    }
                  >
                    {investmentData.campaign_name}
                  </span>
                </div>
                <ReactTooltip effect="solid" className="tooltip_text_destiny" />
              </div>
            </div>
            <div className="investment__content">
              <InvestDetail investDetail={investmentData} />
            </div>
          </div>
        </div>
      </>
    );
  };

  return renderAccordionItems();
};

InvestListAccordion.propTypes = {
  investmentData: PropTypes.shape({
    id: PropTypes.number,
    campaign_id: PropTypes.number,
    campaign_name: PropTypes.string,
    investment_state: PropTypes.string,
    business_line: PropTypes.number
  }).isRequired
};

export default InvestListAccordion;
