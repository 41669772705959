import styled from "styled-components";

export const AlertIco = styled.img`
  width: 15px;
  position: absolute;
  top: 50px;
  right: 30px;
  display: block;
  background: ${({ theme }) => theme.colors.white.bgColor};
`;
