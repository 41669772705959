import React, { useState, useEffect } from "react";
import Certificate from "../Certificates/Certificates";
import {
  onRequestSendMassiveCertificates,
  onRequestDownloadSummaryCertificates,
  onRequestSendSummaryCertificates,
  CertificatesTypes
} from "redux/epics/user-certificates";
import {
  Heading,
  Paragraph,
  Loader
} from "@bvcco/a2censo-component-lib";
import { useTranslation } from "react-i18next";
import { ContainerTitle } from "./Styles";
import UserCertificates, {
  Store,
  Certificates as CertificatesT,
  TypeCertificate
} from "redux/reducers/user-certificates.reducer";
import { useDispatch, useSelector } from "react-redux";
import theme from "styles/LightTheme";
import MoldalActions from "redux/reducers/modal.reducer";
import UserModel from "lib/models/user.model";
import StateSendMail from "assets/images/state-sent.png";

enum PersonTypeEnum {
  Pyme = "pyme",
  Investor = "investor"
}

type ICertificate = {
  quantity: number;
  description: string;
  onClickEmail?: () => void;
  onClickDownload?: () => void;
  disabledDownload?: boolean;
} & CertificatesT;

type IProps = {
  onClickGoBack: () => void;
  yearList: Array<{ id: number; value: number }>;
  yearInitial: number;
};

const Retention = ({ onClickGoBack, yearList, yearInitial }: IProps) => {
  useEffect(() => {
    const fetchIp = async () => {
      let res = await fetch("https://api.ipify.org?format=json", { method: "GET", headers: {} })
      let ip = await res.json();
      setUserIp(ip.ip);
    }
    fetchIp();
  });
  const [userIp, setUserIp] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [yearSelected, setYear] = useState(yearInitial);
  const isInvestor =
    useSelector(UserModel.getPersonType) !== UserModel.type.pyme;
  const personType =
    useSelector(UserModel.getPersonType) === UserModel.type.pyme
      ? PersonTypeEnum.Pyme
      : PersonTypeEnum.Investor;
  useEffect(() => {
    dispatch(
      UserCertificates.fetchCertificates({
        year: yearSelected,
        type: TypeCertificate.Retention,
        isInvestor
      })
    );
  }, []);

  const { certificatesFilter, loading } = useSelector(
    (store: Store) => store.userCertificates
  );

  const onSendEmailSingleCertificate = (
    campaignCompanyId: number,
    campaignId: number
  ) => {
    dispatch(
      MoldalActions.setConditionalModalState(true, "ModalLoadingCertificate", {
        clearBlur: true,
        css: {
          backgroundColor: theme.transparentMartinique
        },
        image: StateSendMail,
        description: t("myCertificates.modalLoading.descriptionEmail")
      })
    );
    dispatch(
      UserCertificates.onSendEmailRetentionSingle(
        campaignCompanyId,
        yearSelected,
        campaignId
      )
    );
  };

  const certificatesData = certificatesFilter?.map(
    (certificate): ICertificate => ({
      ...certificate,
      quantity:
        certificate.campaignsIHaveInvested ||
        certificate.campaignInvestors ||
        0,
      description:
        certificate.mostRecentInvested || certificate.campaignName || "",
      companyName: certificate.companyName,
      disabledDownload: personType == PersonTypeEnum.Pyme,
      onClickDownload: () => onGetCertificate(certificate.certificateId),
      onClickEmail: () =>
        onSendEmailSingleCertificate(
          certificate.campaignCompanyId,
          certificate.campaignId
        )
    })
  );

  const onGetCertificate = (certificateId: string | number) => {
    dispatch(
      MoldalActions.setConditionalModalState(true, "ModalLoadingCertificate", {
        clearBlur: true,
        css: {
          backgroundColor: theme.transparentMartinique
        },
        description: t("myCertificates.modalLoading.descriptionDownload")
      })
    );
    dispatch(UserCertificates.onDownloadCertificate(certificateId));
  };

  const onSearch = (query: string) => {
    if (isInvestor) {
      dispatch(UserCertificates.searchCertificates(query.toUpperCase()));
    } else {
      dispatch(UserCertificates.searchCertificatesPyme(query.toUpperCase()));
    }
  };

  const onSearchYear = (year: number) => {
    setYear(year);
    dispatch(
      UserCertificates.fetchCertificates({
        year: year,
        type: TypeCertificate.Retention,
        isInvestor
      })
    );
  };

  const onSendEmail = () => {
    const certificateType = {
      [PersonTypeEnum.Pyme]: CertificatesTypes.WithholdingTaxPyme,
      [PersonTypeEnum.Investor]: CertificatesTypes.WithholdingTaxInvestor
    };
    dispatch(
      MoldalActions.setConditionalModalState(true, "ModalLoadingCertificate", {
        clearBlur: true,
        css: {
          backgroundColor: theme.transparentMartinique
        },
        image: StateSendMail,
        description: t("myCertificates.modalLoading.descriptionEmail")
      })
    );
    dispatch(
      onRequestSendMassiveCertificates(
        certificateType[personType],
        yearSelected
      )
    );
  };

  const onSendSummary = (
    userId: number,
    year: string | number,
    typePdf: string | number
  ) => {
    dispatch(
      MoldalActions.setConditionalModalState(true, "ModalLoadingCertificate", {
        clearBlur: true,
        css: {
          backgroundColor: theme.transparentMartinique
        },
        image: StateSendMail,
        description: t("myCertificates.modalLoading.descriptionEmail")
      })
    );
    dispatch(onRequestSendSummaryCertificates(userId, year, typePdf, userIp));
  };

  const onDownloadSummary = (
    userId: number,
    year: string | number,
    typePdf: string | number
  ) => {
    dispatch(
      MoldalActions.setConditionalModalState(true, "ModalLoadingCertificate", {
        clearBlur: true,
        css: {
          backgroundColor: theme.transparentMartinique
        },
        description: t("myCertificates.modalLoading.descriptionDownload")
      })
    );
    dispatch(onRequestDownloadSummaryCertificates(userId, year, typePdf));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Certificate
      certificates={certificatesData}
      onClickGoBack={onClickGoBack}
      onSearch={onSearch}
      yearList={yearList}
      onSearchYear={onSearchYear}
      yearInitial={yearSelected}
      isInvestor={personType == PersonTypeEnum.Investor}
      onClickSendEmail={onSendEmail}
      onClickSendSummary={onSendSummary}
      onClickDownloadSummary={onDownloadSummary}
    >
      <ContainerTitle>
        <Heading>{t("myCertificates.detail.retantion.title")}</Heading>
        <Paragraph>
          {t(`myCertificates.detail.retantion.${personType}.description.part1`)}
          <strong>
            {t(
              `myCertificates.detail.retantion.${personType}.description.bold`
            )}
          </strong>
        </Paragraph>
      </ContainerTitle>
    </Certificate>
  );
};

export default Retention;
