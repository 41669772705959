import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 4px;
  width: 300px;
  height: 391px;
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & p.title-modal-pass {
    font-size: 16x;
    color: ${({ theme }) => theme.cornflowerBlue};
    margin-bottom: 8px;
  }

  & > p {
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.foscousGray};
    width: 250px;
    text-align: center;
  }

  > button {
    margin-top: 33px;
    width: 124px;
    height: 40px;
    background-color: ${({ theme }) => theme.cornflowerBlue};
    border-color: ${({ theme }) => theme.cornflowerBlue};
  }
`;
