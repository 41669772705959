import React from "react";
import {
  Tab,
  ArrowIcon,
  Tooltip,
  TabListContainer,
  TabContainer,
  TooltipContent,
  ExtraElement
} from "./components";
import { Paragraph } from "components/shared/Paragraph";
import { isMobile } from "react-device-detect";

type ITabsParams = {
  id: string;
  title?: string;
  tooltip?: string;
  disableTooltip?: boolean;
};

type IProps = {
  OnClickTab?: any;
  tabs: Array<ITabsParams>;
  activeId?: any;
};

export const TabsList = ({ tabs, OnClickTab, activeId }: IProps) => {
  const renderTabs = () =>
    tabs?.map(({ id, title, tooltip, disableTooltip }: ITabsParams) => (
      <TabContainer id={id} key={id} data-for={`${id}-tooltip`} data-tip={true}>
        <Tab
          id={id}
          data-testid={`${id}-test-id`}
          onClick={OnClickTab}
          isActive={activeId === id}
        >
          <Paragraph>{title}</Paragraph>
        </Tab>
        <Tooltip
          id={`${id}-tooltip`}
          place={isMobile ? "bottom" : "top"}
          type="light"
          effect="solid"
          disable={disableTooltip}
        >
          <TooltipContent data-testid={`${id}-tooltip-test-id`}>
            <Paragraph>{tooltip}</Paragraph>
          </TooltipContent>
        </Tooltip>
      </TabContainer>
    ));

  return (
    <TabListContainer id="tabs-list-container">
      {renderTabs()}
      {isMobile && tabs.length > 2 && <ExtraElement />}
    </TabListContainer>
  );
};
