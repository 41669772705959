import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isMobile, isBrowser } from "react-device-detect";
import PropTypes from "prop-types";
import { Button, Paragraph } from "@bvcco/a2censo-component-lib";
import { openFile } from "../../lib/utils/downloadFile";
import "./Footer.scss"

import {
  PrefooterContainer,
  LeftContainer,
  RightContainer,
  TextContainer,
  ButtonsContainer,
  Image,
  MobileSpan
} from "./Styles";

const PreFooter = ({ showAllButtons, homeData, showCampaingTypes }) => {
  const { t } = useTranslation();
  const contentFooter = homeData.footer;
  const email = useSelector(
    ({ finantialSection }) => finantialSection.commercialEmail
  );
  const handleMailTo = () => {
    window.open(`mailto:${email}`, "_self");
  };
  return (
    <PrefooterContainer showCampaingTypes={showCampaingTypes}>
      <LeftContainer>
        <Image src="/assets/images/footer/contact-img@2x.png" />
      </LeftContainer>
      <RightContainer>
        <TextContainer showAllButtons={showAllButtons}>
          {isMobile && (
            <Paragraph size="large24" fontWeight="normal" color="whiteAndGray">
              <MobileSpan className="bolder">
                {t("footer.text1")}
                <span>
                  {showAllButtons ? t("footer.text3") : t("footer.text2")}
                </span>
              </MobileSpan>
            </Paragraph>
          )}

          {isBrowser && (
            <>
              <Paragraph size="large24" color="whiteAndGray">
                <span className="bolder">{t("footer.text1")}</span>
              </Paragraph>
              <Paragraph size="large24" color="whiteAndGray">
                {showAllButtons ? t("footer.text3") : t("footer.text2")}
              </Paragraph>
            </>
          )}
        </TextContainer>
        <ButtonsContainer showAllButtons={showAllButtons}>
          <Button
            outlined={true}
            rounded={true}
            color="secondary2"
            fontSize="medium18"
            fontWeight="bolder"
            className="footer-button"
            onClick={() => openFile(contentFooter.frequest_questions)}
          >
            {t("footer.faq")}
          </Button>
          {showAllButtons && (
            <Button
            className="footer-button"
              dataTestid="commercial-button"
              rounded={true}
              color="secondary"
              fontSize="medium18"
              fontWeight="bolder"
              onClick={() => handleMailTo()}
            >
              {t("footer.text4")}
            </Button>
          )}
        </ButtonsContainer>
      </RightContainer>
    </PrefooterContainer>
  );
};

PreFooter.propTypes = {
  showAllButtons: PropTypes.bool,
  homeData: PropTypes.shape({
    footer: PropTypes.shape({
      frequest_questions: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  showCampaingTypes: PropTypes.bool
};

PreFooter.defaultProps = {
  showAllButtons: false
};

export default PreFooter;
