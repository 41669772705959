/* eslint-disable prefer-template */
// eslint-disable-next-line prefer-template
/**
 * Formula: https://docs.google.com/spreadsheets/d/1eyUyn7ij_E9XAjH5e_NhUOJeK5r81D2zqWz4oOJIWtM/edit?ts=5d28ff7e#gid=293508883
 */

const toFixed = (num, precision) =>
  /**
   * This function will replace js .ToFixed, since the last one doesn't round the right way the decimals
   */
  Number(
    (+(Math.round(+(num + "e" + precision)) + "e" + -precision)).toFixed(
      precision
    )
  );
const Periodicities = {
  Mensual: 12,
  Trimestral: 4,
  Semestral: 2,
  Anual: 1
};

const Months = {
  Mensual: 1,
  Trimestral: 3,
  Semestral: 6,
  Anual: 12
};

const MonthsNumbers = {
  1: "Mensual",
  3: "Trimestral",
  6: "Semestral",
  12: "Anual"
};

const PeriodicityTypes = {
  Mensual: "Mensual",
  Trimestral: "Trimestral",
  Semestral: "Semestral",
  Anual: "Anual"
};

function calculateAmortizationTableByColumn(amortizationTable = [], column) {
  let amount = 0;

  amortizationTable.forEach((item) => {
    amount += item[column];
  });

  return amount;
}

// Tasa nominal anual
const calcAnnualNominalRate = (efectiveRate, nPeriodsByYear) =>
  ((1 + efectiveRate) ** (1 / nPeriodsByYear) - 1) * nPeriodsByYear;

// Pago de intereses
const calcPeriodicInterest = (annualNominalRate, nPeriodsByYear) =>
  annualNominalRate / nPeriodsByYear;

// Pagos periódicos
const calcPeriodicPayment = (
  investment,
  periodicInterest,
  termInMonths,
  interestPayment,
  gracePeriod
) => {
  const VP = investment;
  const i = periodicInterest;
  const fees = termInMonths / interestPayment;
  const g = gracePeriod ? gracePeriod / interestPayment : 0; // g = 0 si no hay periódo de gracia

  const numerator1 = (1 + i) ** (fees - g) * i;
  const denominator1 = (1 + i) ** (fees - g) - 1;
  return VP * (numerator1 / denominator1);
};

function generateAmortizationTableBullet({
  investmentAmount,
  periodicInterest,
  fees,
  periodicity,
  termInMonths
}) {
  if (fees < 1) {
    return [];
  }

  const auxAmortizationTable = new Array(fees).fill({});
  let beforeItem = {};

  return auxAmortizationTable.map((item, index) => {
    const nFee = index + 1;

    const initialBalance =
      nFee <= fees ? beforeItem.finalBalance || investmentAmount : 0;

    const isPeriodicityTrimestralOrSemestral = [
      PeriodicityTypes.Trimestral,
      PeriodicityTypes.Semestral
    ].includes(periodicity);

    const capital =
      (PeriodicityTypes.Anual === periodicity && termInMonths !== 18 && nFee === fees) ||
      (PeriodicityTypes.Mensual === periodicity && termInMonths === nFee) ||
      (isPeriodicityTrimestralOrSemestral && nFee === fees)
        ? investmentAmount
        : 0;

    const notIsTerm7Or8 = ![7, 8].includes(termInMonths);
    const interest =
      (PeriodicityTypes.Anual === periodicity && notIsTerm7Or8) || nFee <= fees
        ? investmentAmount * periodicInterest
        : 0;

    const fee = nFee > termInMonths ? 0 : interest + capital;
    const finalBalance = investmentAmount - capital;

    beforeItem = {
      initialBalance: toFixed(initialBalance, 2),
      fee: toFixed(fee, 2),
      interest: toFixed(interest, 2),
      capital: toFixed(capital, 2),
      finalBalance: finalBalance > 0 ? toFixed(finalBalance, 2) : 0
    };

    return beforeItem;
  });
}

const calcBullet = ({ rate, termInMonths, investment, periodicity }) => {
  const nPeriodsByYear = Periodicities[periodicity];
  const efectiveRate = rate / 100;

  // Tasa nominal anual
  const annualNominalRate = calcAnnualNominalRate(efectiveRate, nPeriodsByYear);
  const annualNominalRateFixed = toFixed(annualNominalRate, 4);

  // Pago de intereses
  const periodicInterest = calcPeriodicInterest(
    annualNominalRateFixed,
    nPeriodsByYear
  );

  const periodicInterestFixed = toFixed(periodicInterest, 6);

  // Pagos periódicos

  const periodicPayment = periodicity
    ? investment * periodicInterestFixed
    : NaN;

  const fees = termInMonths / Months[periodicity];

  const amortizationTableBullet = generateAmortizationTableBullet({
    fees,
    periodicInterest: periodicInterestFixed,
    investmentAmount: investment,
    periodicity,
    termInMonths
  });

  calculateAmortizationTableByColumn(amortizationTableBullet, "fee");

  const recieveTotal = calculateAmortizationTableByColumn(
    amortizationTableBullet,
    "fee"
  );

  const earnings = calculateAmortizationTableByColumn(
    amortizationTableBullet,
    "interest"
  );

  return [
    toFixed(periodicPayment, 2),
    toFixed(recieveTotal, 2),
    toFixed(earnings, 2),
    toFixed(periodicPayment, 2)
  ];
};

function generateAmortizationTable({
  investmentAmount,
  interestPayment,
  periodicInterest,
  periodicPayment,
  fees,
  gracePeriod
}) {
  const auxAmortizationTable = new Array(fees).fill({});
  let beforeItem = {};

  return auxAmortizationTable.map((item, index) => {
    const nFee = index + 1;

    const initialBalance =
      index === 0 ? investmentAmount : beforeItem.finalBalance;

    const boolCalculateCapital = !(
      nFee <= gracePeriod / interestPayment && nFee <= fees
    );

    const interest = periodicInterest * initialBalance;
    const capital = boolCalculateCapital ? periodicPayment - interest : 0;
    const fee = capital + interest;
    const finalBalance = initialBalance - capital;

    beforeItem = {
      initialBalance: toFixed(initialBalance, 2),
      fee: toFixed(fee, 2),
      interest: toFixed(interest, 2),
      capital: toFixed(capital, 2),
      finalBalance: finalBalance > 0 ? toFixed(finalBalance, 2) : 0
    };

    return beforeItem;
  });
}

export const CalcEarnings = (campaign, investment) => {
  const interestPayment = campaign.interestPayment;
  const rate = campaign.interestRate;
  const investmentAmount = investment;
  const gracePeriod = campaign.capital_grace_period;
  const termInMonths = campaign.termInMonths;

  const toExpiration = 0;
  if (campaign.capitalPayment === toExpiration) {
    return calcBullet({
      rate,
      termInMonths,
      investment,
      periodicity: MonthsNumbers[interestPayment]
    });
  }

  const nPeriodsByYear = 12 / interestPayment;
  const efectiveRate = rate / 100;

  // Tasa nominal anual
  const annualNominalRate = calcAnnualNominalRate(efectiveRate, nPeriodsByYear);
  const annualNominalRateFixed = toFixed(annualNominalRate, 4);

  // Pago de intereses
  const periodicInterest = calcPeriodicInterest(
    annualNominalRateFixed,
    nPeriodsByYear
  );
  const periodicInterestFixed = toFixed(periodicInterest, 6);

  const fees = termInMonths / interestPayment;

  const periodicPayment = calcPeriodicPayment(
    investmentAmount,
    periodicInterestFixed,
    termInMonths,
    interestPayment,
    gracePeriod
  );

  const periodicPaymentFixed = Number(periodicPayment.toFixed(2));

  const gracePeriodPayment = periodicInterestFixed * investmentAmount;

  const amortizationTable = generateAmortizationTable({
    investmentAmount,
    interestPayment,
    fees,
    gracePeriod,
    periodicInterest: periodicInterestFixed,
    periodicPayment: periodicPaymentFixed
  });

  const recieveTotal = calculateAmortizationTableByColumn(
    amortizationTable,
    "fee"
  );

  const earnings = calculateAmortizationTableByColumn(
    amortizationTable,
    "interest"
  );

  return [
    periodicPaymentFixed,
    toFixed(recieveTotal, 2),
    toFixed(earnings, 2),
    gracePeriod ? toFixed(gracePeriodPayment, 2) : null
  ];
};

export default { CalcEarnings };
