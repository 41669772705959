import { lazy } from "react";
import { IRoute } from "./types";

const CampaignDetail = lazy(() =>
  import("pages/CampaignDetail/CampaignDetail")
);

const CampaignNewDetail = lazy(() =>
  import("pages/CampaingNewDetail/CampaingNewDetail")
);

export const campaingsRoutes: IRoute[] = [
  {
    name: "Campaign Detail",
    description: "Vista del detalle de la campaña",
    path: "/campaign/:id",
    component: CampaignDetail
  },
  {
    name: "Campaign New Detail",
    description: "Vista del detalle de la campaña",
    path: "/campaignNew/:id",
    component: CampaignNewDetail
  }
];
