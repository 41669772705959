import React from "react";
import { useDispatch, useSelector } from "react-redux";
import propTypes from "prop-types";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import ModalActions from "../../redux/reducers/modal.reducer";
import { formatAmount } from "../../lib/utils.js";
import AnalyticsActions from "../../redux/reducers/analytics.reducer";
import AnalyticsModel from "../../lib/models/analytics.model";
import { startInvestFlow } from "../../redux/epics/invest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import "./MyBalance.scss";

const MyBalance = ({
  availableMoney,
  availableMoneyExchange,
  investments,
  investmentsExchange,
  generalBalance,
  debt,
  equity
}) => {
  const dispatch = useDispatch();
  const userIdValue = useSelector(({ user }) => user.userInfo.user.id);
  const handleBack = () => {
    dispatch(push("/"));
  };
  const goToRecharge = () => {
    dispatch(startInvestFlow());
    dispatch(AnalyticsActions.trackEvent(AnalyticsModel.goToRecharge));
  };

  const goToWithdrawal = () => {
    if (Number(availableMoney) > 1) {
      dispatch(
        ModalActions.setConditionalModalState(true, "MoneyWithdrawalModal", {
          user_id: userIdValue
        })
      );
      dispatch(AnalyticsActions.trackEvent(AnalyticsModel.goToCancel));
    }
  };

  const { t } = useTranslation();

  const styledBalanceAmount = formatAmount(generalBalance);
  const styledInvestAmount = formatAmount(investments);
  const styledInvestExchangeAmount = formatAmount(investmentsExchange);
  const styledAvailableAmount = formatAmount(availableMoney);
  const styledAvailableExchangeAmount = formatAmount(availableMoneyExchange);
  const styledDebt = formatAmount(debt);
  const styledEquity = formatAmount(equity);

  return (
    <div className="balance-container">
      <div className="balance-content">
        <button id="balance-go-back" onClick={handleBack}>
          <div className="balance-content-button">
            <FontAwesomeIcon
              className="balance-content-button__icon"
              icon={faChevronLeft}
            />
            <p>Volver</p>
          </div>
        </button>
        <div className="balance">
          <div className="title-info">
            <p className="title-balance">{t("header.balance")}</p>
            <p className="description-subtitle">{t("myBalance.subtitle")}</p>
          </div>
          <div className="balance-data">
            <div className="data">
              <div className="amount">
                <div className="amount-cop">
                  <div className="amount-cop__label">COP</div>
                  <h2 id="go-to-movements-id">{styledBalanceAmount}</h2>
                </div>
                <div className="amount-item">
                  <div className="available">
                    <div className="available__title">
                      <p>{t("balance.availableCash")}</p>
                      <img
                        data-tip="subtitle_my_balance_tooltip"
                        data-for="subtitle_my_balance_tooltip"
                        src="/assets/images/question-icon.png"
                        alt="a2censo"
                      />
                      <ReactTooltip
                        className="available__tooltipColor"
                        id="subtitle_my_balance_tooltip"
                        place="top"
                        effect="solid"
                        placement="topCenter"
                      >
                        <div className="available__label">
                          {t("myBalance.tooltip")}
                        </div>
                      </ReactTooltip>
                    </div>
                    <p>COP {styledAvailableAmount}</p>
                  </div>
                  <div className="confirmed">
                    <h2>{t("myBalance.availableExchange")}</h2>
                    <p>COP {styledAvailableExchangeAmount}</p>
                  </div>
                </div>
              </div>
              <div id="line-data" className="line" />
              <div className="amount-data">
                <div className="investments-title">
                  <div className="investments-title__item">
                    <h2>{t("header.investments")}</h2>
                    <p>COP {styledInvestAmount}</p>
                  </div>
                  <div id="line-investments-title" className="line" />
                </div>
                <div className="investments-data">
                  <div className="investments-data__item">
                    <div className="investments-data__item__data">
                      <div className="investments-data__item__data__title">
                        <h2>{t("header.investments")}</h2>
                        <p>{t("myBalance.investmentDebt")}</p>
                      </div>
                      <p>COP {styledDebt}</p>
                    </div>
                  </div>
                  <div className="line" style={{ order: 0 }} />
                  <div className="investments-data__item">
                    <div className="investments-data__item__data">
                      <div className="investments-data__item__data__title">
                        <h2>{t("header.investments")}</h2>
                        <p>{t("myBalance.investmentEquity")}</p>
                      </div>
                      <p>COP {styledEquity}</p>
                    </div>
                  </div>
                  <div className="line" />
                  <div className="investments-data__item-confirmed">
                    <p>{t("myBalance.investmentExchange")}</p>
                    <p>COP {styledInvestExchangeAmount}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="action-balance">
              <div className="action-balance__container">
                <button onClick={goToRecharge} id="go-to-recharge">
                  {t("balance.loadBalance")}
                </button>
                <p
                  onClick={goToWithdrawal}
                  id="go-to-withdrawals"
                  available={availableMoney}
                >
                  {t("balance.withdrawals")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MyBalance.propTypes = {
  availableMoney: propTypes.number.isRequired,
  investments: propTypes.number.isRequired,
  availableMoneyExchange: propTypes.number.isRequired,
  investmentsExchange: propTypes.number.isRequired,
  generalBalance: propTypes.number.isRequired
};

export default MyBalance;
