/* eslint-disable no-underscore-dangle */
import { catchError, flatMap } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import { pathOr } from "ramda";
import App from "../../lib/app";
import MovementsActions, {
  MovementsTypes
} from "../reducers/movements.reducer";
import LoginActions from "../reducers/login.reducer";
import ModalActions from "../reducers/modal.reducer";
import { getErrorIndex } from "../../lib/utils";

export const getMovements = (action$) =>
  action$.pipe(
    ofType(MovementsTypes.GET_MOVEMENTS),
    flatMap((action) => {
      const filterResponse = "";
      const payload = {
        page: action.payload && action.payload.page ? action.payload.page : 1,
        size: action.payload && action.payload.size ? action.payload.size : 10,
        filter: ""
      };
      return Observable.from(
        App.api.a2censo.movements({
          url: {
            page: payload.page,
            size: payload.size,
            filters: payload.filter
          }
        })
      ).pipe(
        flatMap((response) =>
          Observable.of(
            MovementsActions.getMovementsSuccess(
              {
                ...response,
                filterResponse
              },
              pathOr(false, ["payload", "isInfiniteScroll"], action)
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              ),
              Observable.of(MovementsActions.getMovementsFail(e))
            );
          }
          return Observable.concat(
            Observable.of(MovementsActions.getMovementsFail(e)),
            Observable.of(push("/oops"))
          );
        })
      );
    })
  );

export const getFilteredMovements = (action$) =>
  action$.pipe(
    ofType(MovementsTypes.GET_FILTERED_MOVEMENTS),
    flatMap((action) => {
      const filterResponse = action.payload.filters;
      const filterParam =
        action.payload.filters && action.payload.filters.filterBy
          ? `&filter=${action.payload.filters.filterBy}`
          : "";
      const keywordParam =
        action.payload.filters && action.payload.filters.keyWord
          ? `&keyword=${action.payload.filters.keyWord}`
          : "";
      const fromParam =
        action.payload.filters && action.payload.filters.fromDate
          ? `&from=${action.payload.filters.fromDate}`
          : "";
      const toParam =
        action.payload.filters && action.payload.filters.toDate
          ? `&to=${action.payload.filters.toDate}`
          : "";
      const filterString = filterParam + keywordParam + fromParam + toParam;

      const payload = {
        page: action.payload && action.payload.page ? action.payload.page : 1,
        size: action.payload && action.payload.size ? action.payload.size : 10,
        filter: filterString
      };

      return Observable.from(
        App.api.a2censo.movements({
          url: {
            page: payload.page,
            size: payload.size,
            filters: payload.filter
          }
        })
      ).pipe(
        flatMap((response) =>
          Observable.of(
            MovementsActions.getFilteredMovementsSuccess(
              {
                ...response,
                filterResponse
              },
              pathOr(false, ["payload", "isInfiniteScroll"], action)
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              ),
              Observable.of(MovementsActions.getFilteredMovementsFail(e))
            );
          }
          return Observable.concat(
            Observable.of(MovementsActions.getFilteredMovementsFail(e)),
            Observable.of(push("/oops"))
          );
        })
      );
    })
  );

export default combineEpics(getMovements, getFilteredMovements);
