/* eslint-disable no-underscore-dangle */
import { catchError, flatMap, map } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import PlacesActions, { PlacesTypes } from "../reducers/places.reducer";
import ModalActions from "../reducers/modal.reducer";
import { getErrorIndex } from "../../lib/utils";
import paramNames from "../../lib/models/parameter.model";

const { HASHED_CODE_PARAMS } = paramNames;
const { _PARAM_019, _PARAM_013 } = HASHED_CODE_PARAMS();

export const getDepartments = (action$) =>
  action$.pipe(
    ofType(PlacesTypes.GET_DEPARTMENTS),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.getStaticParameters({
          url: { tables: `${_PARAM_013}&country=${action.payload.countryId}` }
        })
      ).pipe(
        map((response) =>
          action.payload.type === "info"
            ? PlacesActions.setDepartmentsInfo(response._department)
            : PlacesActions.setDepartmentsBirth(response._department)
        ),
        catchError((e) => {
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.of(
              ModalActions.setConditionalModalState(true, "ErrorModal", {
                title: "errorModal.title",
                content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                linkRedirectsTo: "/",
                buttonText: "errorModal.success"
              })
            );
          }
          return Observable.empty();
        })
      )
    )
  );

export const getCities = (action$) =>
  action$.pipe(
    ofType(PlacesTypes.GET_CITIES),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.getStaticParameters({
          url: {
            tables: `${_PARAM_019}&department=${action.payload.departmentId}`
          }
        })
      ).pipe(
        map((response) =>
          action.payload.type === "info"
            ? PlacesActions.setCitiesInfo(response._city)
            : PlacesActions.setCitiesBirth(response._city)
        ),
        catchError((e) => {
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.of(
              ModalActions.setConditionalModalState(true, "ErrorModal", {
                title: "errorModal.title",
                content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                linkRedirectsTo: "/",
                buttonText: "errorModal.success"
              })
            );
          }
          return Observable.empty();
        })
      )
    )
  );

export default combineEpics(getDepartments, getCities);
