import { catchError, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import { push } from "connected-react-router";
import { CampaingDetailConnect } from "services/campaing-detail";
import LoginActions from "../reducers/login.reducer";
import ModalActions from "../reducers/modal.reducer";
import CampaignsActions from "../reducers/campaings.reducer";
import CampaignsDetailActions from "../../redux/reducers/campaing-detail.reducer";
import { forkJoin } from "rxjs";

enum IGetCampaingInfoTypes {
  GetCampaingAllInfo = "GET_CAMPAING_DATA"
}

export const getCampaingData = (campaignId: any, hasSession: boolean) => ({
  type: IGetCampaingInfoTypes.GetCampaingAllInfo,
  payload: { campaignId, hasSession }
});

export const campaignDataFail = ({ error }: any) => ({
  type: "CAMPAIGN_DATA_FAIL",
  payload: {
    error
  }
});
const Errors = [500];

const getErrorIndex = (e: any) => {
  let hasErrorIndex = -1;
  if (Array.isArray(e.errors)) {
    e.errors.map((error: { statusCode: any }, indexError: number) => {
      const index = Errors.findIndex(
        (value: any) => error.statusCode === value
      );
      if (index >= 0) {
        hasErrorIndex = indexError;
      }
      return error;
    });
  }
  return hasErrorIndex;
};

export const campaingData = (action$: any) =>
  action$.pipe(
    ofType(IGetCampaingInfoTypes.GetCampaingAllInfo),
    mergeMap((action: any) => {
      let services = forkJoin();
      if (!action.hasSession) {
        services = forkJoin([
          Observable.from(
            new CampaingDetailConnect()
              .getCampaignPublicDetail({ id: action.campaignId })
              .then((res: any) => res)
          ),
          Observable.from(
            new CampaingDetailConnect()
              .getCampaignPublicDetailMoreInfo({ id: action.campaignId })
              .then((res: any) => res)
          )
        ]);
      } else {
        services = forkJoin([
          Observable.from(
            new CampaingDetailConnect()
              .getCampaignDetail({ id: action.campaignId })
              .then((res: any) => res)
          ),
          Observable.from(
            new CampaingDetailConnect()
              .getCampaignPublicDetailMoreInfo({ id: action.campaignId })
              .then((res: any) => res)
          )
        ]);
      }
      return services.pipe(
        mergeMap((response) => {
          return Observable.concat(
            Observable.of(CampaignsDetailActions.clearCampaingData()),
            Observable.of(
              CampaignsDetailActions.setCampaingData(
                response[0],
                response[1].result
              )
            ),
            Observable.of(push(`/campaignNew/${action.campaignId}`, {
              response
            }))
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e.response.data);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "OtherErrorModal", {
                  title: "errorModal.oops",
                  content: `errorCodes.${e.response.data.errors[hasErrorIndex].statusCode}`,
                  linkRedirectsTo: "/"
                })
              ),
              Observable.of(campaignDataFail(e))
            );
          }
          return Observable.concat(
            Observable.of(campaignDataFail(e)),
            Observable.of(CampaignsActions.endCampaignDetail()),
            Observable.of(
              push(`/campaignNew/${action.campaignId}`, {
                response: {},
                error: e,
                campaignId: action.campaignId
              })
            )
          );
        })
      );
    })
  );
export default campaingData;
