import { map, flatMap, catchError, mergeMap } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import { forkJoin } from "rxjs";
import App from "../../lib/app";
import User from "../../lib/models/user.model";
import { ConfirmDataSerializer } from "../../lib/serializers/confirmData.serializer";
import { ConfirmDataLegalSerializer } from "../../lib/serializers/confirmDataLegal.serializer";
import UserActions from "../reducers/user.reducer";
import LoginActions from "../reducers/login.reducer";
import ModalActions from "../reducers/modal.reducer";
import AnalyticsActions from "../reducers/analytics.reducer";
import AnalyticsModel from "../../lib/models/analytics.model";
import { getErrorIndex } from "../../lib/utils";
import paramNames from "../../lib/models/parameter.model";
import {
  ErrorServices,
  attemptsValidationErrors
} from "../../lib/utils/constants.errors";
import theme from "styles/LightTheme";

const {
  QUERIES: { citiesByCountryId },
  COUNTRY_ID: { COL },
  HASHED_CODE_PARAMS
} = paramNames;
const { _PARAM_002 } = HASHED_CODE_PARAMS();

export const ExperianDocumentErrors = ["419", "417", "418", "420", "421"];

const ExperianErrors = [
  "EVIDENTE-05",
  "EVIDENTE-06",
  "EVIDENTE-07",
  "EVIDENTE-08",
  "EVIDENTE-09",
  "EVIDENTE-10",
  "EVIDENTE-11",
  "LISTAS_RESTRICTIVAS-301",
  "LISTAS_RESTRICTIVAS-302",
  ...ExperianDocumentErrors
];

export const initialConfirmDataForm = ({ tableNames }) => ({
  type: "INITIAL_CONFIRM_DATA",
  payload: {
    tableNames
  }
});

export const saveConfirmDataForm = ({ userInfo }) => ({
  type: "SAVE_CONFIRM_DATA",
  payload: {
    userInfo
  }
});

export const confirmDataFail = ({ error }) => ({
  type: "CONFIRM_DATA_FAIL",
  payload: {
    error
  }
});

export const getUserInfo = ({ isLogin = false } = {}) => ({
  type: "GET_USER_INFO",
  payload: {
    isLogin
  }
});

export const validateExperianAttempts = ({ userInfo }) => ({
  type: "VALIDATE_EXPERIAN_ATTEMPTS",
  payload: {
    userInfo
  }
});

export const initialConfirmData = (action$) =>
  action$.pipe(
    ofType("INITIAL_CONFIRM_DATA"),
    flatMap((action) =>
      forkJoin([
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: { tables: action.payload.tableNames }
          })
        ),
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: { tables: `${_PARAM_002}&${citiesByCountryId}=${COL}` }
          })
        )
      ]).pipe(
        map((response) =>
          push("/confirm-data", {
            response
          })
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  code: e.errors[hasErrorIndex].code,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              ),
              Observable.of(confirmDataFail(e))
            );
          }
          return Observable.concat(
            Observable.of(confirmDataFail(e)),
            Observable.of(
              push("/oops", {
                hideButton: true
              })
            )
          );
        })
      )
    )
  );

const mustRedirect = (response, isLogin) => {
  const isNaturalPersonLogin =
    response.user.person_type === User.type.natural && isLogin;

  const enrollmentFinished =
    response.user.enrollment_state === User.state.finished;

  const pendingUpdateDate = !!response.user.data_update_completed;

  const deepLink = App.redux.store.getState().appParams.deepLink;

  const isPymeAndEnrollmentFinished =
    enrollmentFinished &&
    response.user.person_type === User.type.pyme &&
    !deepLink;

  const isInvestorAndEnrollmentFinished =
    enrollmentFinished &&
    response.user.person_type !== User.type.pyme &&
    !deepLink;

  if (
    isNaturalPersonLogin && enrollmentFinished && !pendingUpdateDate && (deepLink
      ? deepLink.includes("?")
        ? deepLink.split("?")[0] !== "/download-certificates"
        : false
      : true)
  ) {
    return Observable.of(push("/update-user-data"));
  }

  if (isLogin) {
    if (isPymeAndEnrollmentFinished) {
      return Observable.of(push("/dashboard-campaigns"));
    }
    if (isInvestorAndEnrollmentFinished) {
      return Observable.of(push("/my-balance"));
    }
    return Observable.of(push(User.getUriFromUserState(response.user)));
  }
  return Observable.of();
};

export const userInfo = (action$) =>
  action$.pipe(
    ofType("GET_USER_INFO"),
    flatMap((action) =>
      Observable.from(App.api.a2censo.usersInfo()).pipe(
        flatMap((response) => {
          const redirectAction = mustRedirect(response, action.payload.isLogin);
          return Observable.concat(
            Observable.of(UserActions.setUserInfo(response)),
            Observable.of(
              UserActions.setInvestorType({
                qualityTax: response.user.quality_tax,
                hasInvestorDocument: !!response.investor
              })
            ),
            redirectAction
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(
            Observable.of(confirmDataFail(e)),
            Observable.of(push("/oops"))
          );
        })
      )
    )
  );

export const experianAttempts = (action$) =>
  action$.pipe(
    ofType("VALIDATE_EXPERIAN_ATTEMPTS"),
    mergeMap(({ payload }) => {
      const {
        api: {
          a2censo: { getAttemptsValidation }
        }
      } = App;
      return Observable.from(
        getAttemptsValidation({
          url: {
            type: 1
          }
        })
      ).pipe(
        mergeMap(() => Observable.of(saveConfirmDataForm(payload))),
        catchError((e) => {
          const attemptErrors = (e.errors || []).find((error) =>
            attemptsValidationErrors.includes(error.code)
          );

          if (attemptErrors) {
            return Observable.concat(
              Observable.of(confirmDataFail(e)),
              Observable.of(
                ModalActions.setConditionalModalState(
                  true,
                  "AttemptsValidation",
                  {
                    clearBlur: true,
                    css: {
                      backgroundColor: theme.transparentSantas
                    }
                  }
                )
              )
            );
          }

          return Observable.concat(
            Observable.of(confirmDataFail(e)),
            Observable.of(push("/oops"))
          );
        })
      );
    })
  );

export const saveConfirmData = (action$) =>
  action$.pipe(
    ofType("SAVE_CONFIRM_DATA"),
    flatMap((action) =>
      Observable.from(
        action.payload.userInfo.person_type === User.type.natural
          ? App.api.a2censo.updateUsers({
              url: { userId: action.payload.userInfo.id },
              body: ConfirmDataSerializer().serialize(action.payload.userInfo)
            })
          : App.api.a2censo.updateUsers({
              url: { userId: action.payload.userInfo.id },
              body: ConfirmDataLegalSerializer().serialize(
                action.payload.userInfo
              )
            })
      ).pipe(
        flatMap(() => {
          const trackEvent = () =>
            action.payload.userInfo.person_type === User.type.natural
              ? AnalyticsActions.trackEvent(AnalyticsModel.saveConfirmDataLegal)
              : AnalyticsActions.trackEvent(AnalyticsModel.saveConfirmData);
          return Observable.concat(
            Observable.of(
              UserActions.setUserState(
                action.payload.userInfo.person_type === User.type.natural
                  ? User.state.identityValidation
                  : User.state.generalInformation
              )
            ),
            Observable.of(
              UserActions.setUserInfo({ user: action.payload.userInfo })
            ),
            Observable.of(
              trackEvent(),
              push(
                action.payload.userInfo.person_type === User.type.natural
                  ? "/enrollment/identity-validation"
                  : "/enrollment/general-information"
              )
            )
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          if (e.statusCode === 417) {
            return Observable.of(
              ModalActions.setConditionalModalState(
                true,
                "ExperianErrorModal",
                {
                  errorCodes: e.errors
                    ? e.errors[0].code
                    : "LISTAS_RESTRICTIVAS-301",
                  redirectUri: "/"
                }
              )
            );
          }
          let hasExperianError = false;
          if (Array.isArray(e.errors)) {
            e.errors.map((error) => {
              const index = ExperianErrors.findIndex(
                (value) => error.code === value
              );
              if (index >= 0) {
                hasExperianError = true;
              }
            });
          }
          if (hasExperianError) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(
                  true,
                  "ExperianErrorModal",
                  {
                    errorCodes: e.errors[0].code,
                    redirectUri: "/"
                  }
                )
              )
            );
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  code: e.errors[hasErrorIndex].code,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.goHome"
                })
              ),
              Observable.of(confirmDataFail(e))
            );
          }

          const errorService = (e.errors || []).find((error) =>
            ErrorServices.includes(error.code)
          );
          if (errorService) {
            return Observable.concat(
              Observable.of(
                push("/oops", {
                  message: `register.userValidation.errorCodes.${errorService.code}`
                })
              ),
              Observable.of(confirmDataFail(e))
            );
          }

          return Observable.concat(
            Observable.of(confirmDataFail(e)),
            Observable.of(
              push("/oops", {
                subtitle: "confirmData.oopsSubtitle",
                message: "confirmData.oopsMessage",
                hideButton: true
              })
            )
          );
        })
      )
    )
  );

export default combineEpics(
  initialConfirmData,
  saveConfirmData,
  userInfo,
  experianAttempts
);
