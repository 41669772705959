import React from "react";
import "./Bullet.styles.scss";

type IProps = {
  campaignDetail: Array<{
    content?: string;
  }>;
};

const Bullet = ({ campaignDetail }: IProps) => (
  <div className="bullet-container">
    <div className="bullet-grid-container">
      <div className="bullet-grid">
        {campaignDetail?.length > 0 &&
          campaignDetail?.map((bullet, i) => (
            <div key={i} className="bullet-item">
              <div className="bullet-content-container">
                <div className="bullet-number-container">
                  <p className="bullet-number">{i + 1}</p>
                </div>
                <p className="bullet-content">{bullet.content}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  </div>
);

export default Bullet;
