import { flatMap, catchError } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import { forkJoin } from "rxjs";
import { push } from "connected-react-router";
import {
  StatesRequestDocuments,
  OriginDocuments,
  AVAILABLE_STEPS,
  FlowUploadFile
} from "../../lib/models/campaign.model";
import LoginActions from "../reducers/login.reducer";
import CodebtorActions from "../reducers/codebtor.reducer";
import AlertActions from "../reducers/alert.reducer";

import CampaignDocumentManagment from "../reducers/campaign-document-managment.reducer";
import App from "../../lib/app";
import inFilesSerializer from "../../lib/serializers/campaign-documents.serializer";
import UploadFileCampaign from "../../lib/services/upload-file-campaign";
import RequestCampaign from "../reducers/request-campaign.reducer";

export const startDeleteFile = ({
  documentId,
  key,
  associateId,
  codebtorId
}) => ({
  type: "START_DELETE_FILE",
  payload: {
    documentId,
    key,
    codebtorId,
    associateId
  }
});

export const startDeleteRequestFile = ({ key }) => ({
  type: "START_DELETE_REQUEST_FILE",
  payload: {
    key
  }
});

export const getRequirementDocuments = () => ({
  type: "START_FETCH_REQUIREMENTS_DOCUMENTS"
});

export const getRequirementDocumentsStepFive = () => ({
  type: "START_FETCH_REQUIREMENTS_DOCUMENTS_STEP_FIVE"
});

export const getRequirementDocumentsStepSix = () => ({
  type: "START_FETCH_REQUIREMENTS_DOCUMENTS_STEP_SIX"
});

export const getRequirementDocumentsAndAssociates = () => ({
  type: "START_FETCH_REQUIREMENTS_DOCUMENTS_AND_ASSOCIATES"
});

export const updateCurrentFile = ({ file, key, codebtorId, associateId }) => ({
  type: "START_UPDATE_CURRENT_FILE",
  payload: {
    key,
    file,
    codebtorId,
    associateId
  }
});

export const setNewUploadCampaignFile = ({
  file,
  key,
  documentStageId,
  codebtorId,
  associateId,
  fileBinary
}) => ({
  type: "START_UPLOAD_FILE_CAMPAIGN",
  payload: {
    key,
    file,
    documentStageId,
    codebtorId,
    associateId,
    fileBinary
  }
});

export const startBulkUpload = ({ files, codebtor, associateId }) => ({
  type: "START_BULK_UPLOAD",
  payload: {
    files,
    codebtor,
    associateId
  }
});

export const getRequestDocumentsStepTwo = () => ({
  type: "START_FETCH_STEP_TWO_REQUEST_DOCUMENTS"
});

export const getRequestDocumentsStepFive = () => ({
  type: "START_FETCH_STEP_FIVE_REQUEST_DOCUMENTS"
});

export const getRequestDocumentsStepFour = () => ({
  type: "START_FETCH_STEP_FOUR_REQUEST_DOCUMENTS"
});

export const getRequestDocumentsStepSix = () => ({
  type: "START_FETCH_STEP_SIX_REQUEST_DOCUMENTS"
});

export const getRequestDocumentsStepSeven = () => ({
  type: "START_FETCH_STEP_SEVEN_REQUEST_DOCUMENTS"
});

export const uploadRequestCampaignFiles = ({ file, key, releaseFile }) => ({
  type: "START_UPLOAD_REQUEST_CAMPAIGN_FILES",
  payload: {
    file,
    key,
    releaseFile
  }
});

export const removeRequestCampaignFiles = ({ releaseFile }) => ({
  type: "START_REMOVE_REQUEST_CAMPAIGN_FILES",
  payload: {
    releaseFile
  }
});

const getPymeDocuments = (resultDocuments = []) => {
  const pymeDocuments = [];
  const codebtorsDocuments = [];

  resultDocuments.forEach(
    ({ documents: { pyme = [], associates = [], codebtors = [] } }) => {
      pyme.forEach((document) => {
        pymeDocuments.push(document);
      });
      associates.forEach((document) => {
        codebtorsDocuments.push(document);
      });
      codebtors.forEach((document) => {
        codebtorsDocuments.push(document);
      });
    }
  );

  return [...pymeDocuments, ...codebtorsDocuments];
};

export const onGetRequestDocumentsStepTwo = ($action, $state) =>
  $action.pipe(
    ofType("START_FETCH_STEP_TWO_REQUEST_DOCUMENTS"),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;

      const stageId = AVAILABLE_STEPS.FIRST_DOCUMENTS;

      const state = `${StatesRequestDocuments.docNotLoaded},${StatesRequestDocuments.docRejected},${StatesRequestDocuments.docLoaded}`;

      return Observable.from(
        App.api.a2censo.getDocumentRequestCampaign({
          url: {
            state,
            stage: stageId,
            campaign_id: requestCampaignId
          }
        })
      ).pipe(
        flatMap((result) => {
          const files = inFilesSerializer({
            documents: { pyme: getPymeDocuments(result) }
          });

          const count = result.length > 0 ? result[0] : {};

          return Observable.of(
            CampaignDocumentManagment.setRequestDocuments(files, {
              totalFiles: count.total_files || 0,
              pendingFiles: count.pending_files || 0
            })
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.of(push("/oops"));
        })
      );
    })
  );

export const onGetRequestDocumentsStepFive = ($action, $state) =>
  $action.pipe(
    ofType("START_FETCH_STEP_FIVE_REQUEST_DOCUMENTS"),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;

      const stageId = AVAILABLE_STEPS.COMMITEE_ANALYSIS;

      const state = `${StatesRequestDocuments.docNotLoaded},${StatesRequestDocuments.docRejected},${StatesRequestDocuments.docLoaded}`;

      return Observable.from(
        App.api.a2censo.getDocumentRequestCampaign({
          url: {
            state,
            stage: stageId,
            campaign_id: requestCampaignId
          }
        })
      ).pipe(
        flatMap((result) => {
          const files = inFilesSerializer({
            documents: { pyme: getPymeDocuments(result) }
          });

          const count = result.length > 0 ? result[0] : {};

          return Observable.of(
            CampaignDocumentManagment.setRequestDocuments(files, {
              totalFiles: count.total_files || 0,
              pendingFiles: count.pending_files || 0
            })
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.of(push("/oops"));
        })
      );
    })
  );

export const onGetRequestDocumentsStepSix = ($action, $state) =>
  $action.pipe(
    ofType("START_FETCH_STEP_SIX_REQUEST_DOCUMENTS"),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;

      const stageId = AVAILABLE_STEPS.PRE_APPROVAL;

      const state = `${StatesRequestDocuments.docNotLoaded},${StatesRequestDocuments.docRejected},${StatesRequestDocuments.docLoaded}`;

      return Observable.from(
        App.api.a2censo.getDocumentRequestCampaign({
          url: {
            state,
            stage: stageId,
            campaign_id: requestCampaignId
          }
        })
      ).pipe(
        flatMap((result) => {
          const files = inFilesSerializer({
            documents: { pyme: getPymeDocuments(result) }
          });

          const count = result.length > 0 ? result[0] : {};

          return Observable.of(
            CampaignDocumentManagment.setRequestDocuments(files, {
              totalFiles: count.total_files || 0,
              pendingFiles: count.pending_files || 0
            })
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.of(push("/oops"));
        })
      );
    })
  );

export const onGetRequestDocumentsStepSeven = ($action, $state) =>
  $action.pipe(
    ofType("START_FETCH_STEP_SEVEN_REQUEST_DOCUMENTS"),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;

      const stageId = AVAILABLE_STEPS.RELEASE;

      const state = `${StatesRequestDocuments.docNotLoaded},${StatesRequestDocuments.docRejected},${StatesRequestDocuments.docLoaded}`;

      return Observable.from(
        App.api.a2censo.getDocumentRequestCampaign({
          url: {
            state,
            stage: stageId,
            campaign_id: requestCampaignId
          }
        })
      ).pipe(
        flatMap((result) => {
          const files = inFilesSerializer({
            documents: { pyme: getPymeDocuments(result) }
          });

          const count = result.length > 0 ? result[0] : {};

          return Observable.of(
            CampaignDocumentManagment.setRequestDocuments(files, {
              totalFiles: count.total_files || 0,
              pendingFiles: count.pending_files || 0
            })
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.of(push("/oops"));
        })
      );
    })
  );

export const onGetRequestDocumentsStepFour = ($action, $state) =>
  $action.pipe(
    ofType("START_FETCH_STEP_FOUR_REQUEST_DOCUMENTS"),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;

      const stageId = AVAILABLE_STEPS.ADDITIONAL_INFORMATION;

      const state = `${StatesRequestDocuments.docNotLoaded},${StatesRequestDocuments.docRejected},${StatesRequestDocuments.docLoaded}`;

      return Observable.from(
        App.api.a2censo.getDocumentRequestCampaign({
          url: {
            state,
            stage: stageId,
            campaign_id: requestCampaignId
          }
        })
      ).pipe(
        flatMap((result) => {
          const files = inFilesSerializer({
            documents: { pyme: getPymeDocuments(result) }
          });

          const count = result.length > 0 ? result[0] : {};

          return Observable.of(
            CampaignDocumentManagment.setRequestDocuments(files, {
              totalFiles: count.total_files || 0,
              pendingFiles: count.pending_files || 0
            })
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.of(push("/oops"));
        })
      );
    })
  );
const onUploadNewFileCatchError = (
  key,
  e,
  deleteFile,
  documentRequestCampaignId
) => {
  const codeError = e.errors && e.errors[0] && e.errors[0].code;

  return Observable.concat(
    Observable.of(
      CampaignDocumentManagment.setUploadFileError(key, codeError || "error")
    ),
    deleteFile
      ? Observable.of(
          App.api.a2censo.deleteDocumentRequestCampaign({
            url: { document_id: documentRequestCampaignId }
          })
        )
      : Observable.of()
  );
};

export const onUploadNewFile = ($action, $state) =>
  $action.pipe(
    ofType("START_UPLOAD_FILE_CAMPAIGN"),
    flatMap(
      ({
        payload: {
          file,
          key,
          documentStageId,
          codebtorId = null,
          associateId = null
        }
      }) => {
        const {
          value: {
            requestCampaign: {
              info: {
                request_campaign: { id: requestCampaignId }
              }
            }
          }
        } = $state;

        let flow = FlowUploadFile.default;

        if (codebtorId) {
          flow = FlowUploadFile.codebtor;
        }
        if (!codebtorId && associateId) {
          flow = FlowUploadFile.associate;
        }

        const { name, path, size, type } = file;

        return Observable.concat(
          Observable.of(
            CampaignDocumentManagment.setUploadFileCampaign(file, key)
          ),
          Observable.from(
            App.api.a2censo.documentRequestCampaign({
              body: {
                data: {
                  attributes: {
                    request_campaign_id: requestCampaignId,
                    document_stage_id: documentStageId,
                    codebtor_id: codebtorId,
                    associate_id: associateId,
                    flow,
                    document_file_metadata: { name, path, size, type }
                  }
                }
              }
            })
          ).pipe(
            flatMap((response) =>
              Observable.from(
                new UploadFileCampaign(
                  response.temp_file_url,
                  key
                ).uploadBinaryPut(file)
              ).pipe(
                flatMap(() =>
                  Observable.from(
                    App.api.a2censo.updatePathFileDocument({
                      body: {
                        data: {
                          attributes: {
                            document_request_campaign_id:
                              response.document_request_campaign_id,
                            path_file: response.complete_key
                          }
                        }
                      }
                    })
                  ).pipe(
                    flatMap(() =>
                      Observable.concat(
                        Observable.of(
                          CampaignDocumentManagment.uploadFileSuccess(key)
                        ),
                        Observable.of(
                          CampaignDocumentManagment.addRequirementDocument(
                            response.document_request_campaign_id,
                            response.key,
                            documentStageId,
                            associateId
                          )
                        )
                      )
                    ),
                    catchError((e) =>
                      onUploadNewFileCatchError(
                        key,
                        e,
                        true,
                        response.document_request_campaign_id
                      )
                    )
                  )
                ),
                catchError((e) =>
                  onUploadNewFileCatchError(
                    key,
                    e,
                    true,
                    response.document_request_campaign_id
                  )
                )
              )
            ),
            catchError((e) => onUploadNewFileCatchError(key, e))
          )
        );
      }
    )
  );

const errorUploadRequestCampaignFiles = (releaseFile) =>
  Observable.concat(
    Observable.of(AlertActions.setAlertState("error", "alert.tryAgain")),
    Observable.of(RequestCampaign.setRequestCampaignFiles(releaseFile, ""))
  );

export const onUploadRequestCampaignFiles = ($action, $state) =>
  $action.pipe(
    ofType("START_UPLOAD_REQUEST_CAMPAIGN_FILES"),
    flatMap(({ payload: { file, key, releaseFile } }) => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;

      const { name } = file;

      return Observable.concat(
        Observable.from(
          App.api.a2censo.saveRequestCampaignFiles({
            url: { requestCampaignId },
            body: {
              file: releaseFile,
              file_name: name
            }
          })
        ).pipe(
          flatMap((response) =>
            Observable.from(
              new UploadFileCampaign(response.temp_url, key).uploadBinaryPut(
                file
              )
            ).pipe(
              flatMap(() =>
                Observable.from(
                  App.api.a2censo.updateRequestCampaignFiles({
                    url: { requestCampaignId },
                    body: {
                      file: releaseFile,
                      file_key: response.key
                    }
                  })
                ).pipe(
                  flatMap((updateResponse) =>
                    Observable.of(
                      RequestCampaign.setRequestCampaignFiles(
                        releaseFile,
                        updateResponse.temp_url
                      )
                    )
                  ),
                  catchError(() => errorUploadRequestCampaignFiles(releaseFile))
                )
              )
            )
          ),
          catchError(() => errorUploadRequestCampaignFiles(releaseFile))
        )
      );
    })
  );

export const onRemoveRequestCampaignFiles = ($action, $state) =>
  $action.pipe(
    ofType("START_REMOVE_REQUEST_CAMPAIGN_FILES"),
    flatMap(({ payload: { releaseFile } }) => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;

      return Observable.concat(
        Observable.from(
          App.api.a2censo.removeRequestCampaignFiles({
            url: {
              requestCampaignId,
              file: releaseFile
            }
          })
        ).pipe(
          flatMap(() =>
            Observable.of(
              RequestCampaign.setRequestCampaignFiles(releaseFile, "")
            )
          ),
          catchError(() =>
            Observable.of(AlertActions.setAlertState("error", "alert.tryAgain"))
          )
        )
      );
    })
  );

export const onDeleteFile = ($action) =>
  $action.pipe(
    ofType("START_DELETE_FILE"),
    flatMap(({ payload: { documentId, associateId, key } }) =>
      Observable.from(
        App.api.a2censo.deleteDocumentRequestCampaign({
          url: { document_id: documentId }
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(
              CampaignDocumentManagment.removeRequirementDocument(
                documentId,
                associateId
              )
            ),
            Observable.of(CampaignDocumentManagment.removeUploadedFile(key))
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      )
    )
  );

export const onDeleteRequestFile = ($action) =>
  $action.pipe(
    ofType("START_DELETE_REQUEST_FILE"),
    flatMap(({ payload: { key } }) =>
      Observable.concat(
        Observable.of(CampaignDocumentManagment.removeRequestDocument(key)),
        Observable.of(CampaignDocumentManagment.removeUploadedFile(key))
      )
    )
  );

export const onGetRequirementDocuments = ($action, $state) =>
  $action.pipe(
    ofType("START_FETCH_REQUIREMENTS_DOCUMENTS"),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: campaignId }
            }
          }
        }
      } = $state;
      return forkJoin([
        Observable.from(
          App.api.a2censo.getDocumentStage({
            url: {
              stage: AVAILABLE_STEPS.FIRST_DOCUMENTS,
              campaign_id: campaignId,
              origin: OriginDocuments.pyme
            }
          })
        ),
        Observable.from(
          App.api.a2censo.getDocumentRequestCampaign({
            url: {
              state: StatesRequestDocuments.docLoaded,
              campaign_id: campaignId,
              stage: AVAILABLE_STEPS.FIRST_DOCUMENTS
            }
          })
        )
      ]).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(
              CampaignDocumentManagment.setRequirementDocuments(response)
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      );
    })
  );

export const onGetRequirementDocumentsStepFive = ($action, $state) =>
  $action.pipe(
    ofType("START_FETCH_REQUIREMENTS_DOCUMENTS_STEP_FIVE"),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: campaignId }
            }
          }
        }
      } = $state;

      return forkJoin([
        Observable.from(
          App.api.a2censo.getDocumentStage({
            url: {
              stage: AVAILABLE_STEPS.COMMITEE_ANALYSIS,
              campaign_id: campaignId,
              origin: OriginDocuments.pyme
            }
          })
        ),
        Observable.from(
          App.api.a2censo.getDocumentRequestCampaign({
            url: {
              state: StatesRequestDocuments.docLoaded,
              campaign_id: campaignId,
              stage: AVAILABLE_STEPS.COMMITEE_ANALYSIS
            }
          })
        )
      ]).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(
              CampaignDocumentManagment.setRequirementDocuments(response)
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      );
    })
  );

export const onGetRequirementDocumentsStepSix = ($action, $state) =>
  $action.pipe(
    ofType("START_FETCH_REQUIREMENTS_DOCUMENTS_STEP_SIX"),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: campaignId }
            }
          }
        }
      } = $state;

      return forkJoin([
        Observable.from(
          App.api.a2censo.getDocumentStage({
            url: {
              stage: AVAILABLE_STEPS.PRE_APPROVAL,
              campaign_id: campaignId,
              origin: OriginDocuments.pyme
            }
          })
        ),
        Observable.from(
          App.api.a2censo.getDocumentRequestCampaign({
            url: {
              state: StatesRequestDocuments.docLoaded,
              campaign_id: campaignId,
              stage: AVAILABLE_STEPS.PRE_APPROVAL
            }
          })
        )
      ]).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(
              CampaignDocumentManagment.setRequirementDocuments(response)
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      );
    })
  );

export const onGetRequirementDocumentsAndAssociates = ($action, $state) =>
  $action.pipe(
    ofType("START_FETCH_REQUIREMENTS_DOCUMENTS_AND_ASSOCIATES"),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: campaignId }
            }
          }
        }
      } = $state;
      return forkJoin([
        Observable.from(
          App.api.a2censo.getDocumentStage({
            url: {
              stage: AVAILABLE_STEPS.ADDITIONAL_INFORMATION,
              campaign_id: campaignId,
              origin: OriginDocuments.pyme
            }
          })
        ),
        Observable.from(App.api.a2censo.getAssociates()),
        Observable.from(
          App.api.a2censo.getDocumentRequestCampaign({
            url: {
              state: `${StatesRequestDocuments.docLoaded},${StatesRequestDocuments.docPending}`,
              campaign_id: campaignId,
              stage: AVAILABLE_STEPS.ADDITIONAL_INFORMATION
            }
          })
        )
      ]).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(
              CampaignDocumentManagment.setRequirementDocumentsAndAssociates(
                response
              )
            ),
            Observable.of(CodebtorActions.getListCodebtor()),
            Observable.of(CodebtorActions.setSelectableAssociates(response[1]))
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      );
    })
  );

export const onUpdateCurrentFile = ($action) =>
  $action.pipe(
    ofType("START_UPDATE_CURRENT_FILE"),
    flatMap(
      ({ payload: { file, key, codebtorId = null, associateId = null } }) => {
        let flow = "default";

        if (codebtorId) {
          flow = "codebtor";
        }
        if (!codebtorId && associateId) {
          flow = "associate";
        }

        const { name, path, size, type } = file;

        return Observable.concat(
          Observable.of(
            CampaignDocumentManagment.setUploadFileCampaign(file, key)
          ),
          Observable.from(
            App.api.a2censo.putDocumentRequestCampaign({
              body: {
                data: {
                  attributes: {
                    codebtor_id: Number(codebtorId),
                    associate_id: Number(associateId),
                    flow,
                    document_file_metadata: { name, path, size, type },
                    document_request_campaign_id: key
                  }
                }
              }
            })
          ).pipe(
            flatMap((response) =>
              Observable.from(
                new UploadFileCampaign(
                  response.temp_file_url,
                  key
                ).uploadBinaryPut(file)
              ).pipe(
                flatMap(() =>
                  Observable.from(
                    App.api.a2censo.updatePathFileDocument({
                      body: {
                        data: {
                          attributes: {
                            document_request_campaign_id: key,
                            path_file: response.complete_key
                          }
                        }
                      }
                    })
                  ).pipe(
                    flatMap(() =>
                      Observable.concat(
                        Observable.of(
                          CampaignDocumentManagment.uploadFileSuccess(key)
                        ),
                        Observable.of(
                          CampaignDocumentManagment.addRequestDocument(
                            key,
                            response.key
                          )
                        )
                      )
                    )
                  )
                )
              )
            ),
            catchError((e) =>
              Observable.of(
                CampaignDocumentManagment.setUploadFileError(
                  key,
                  e[0] ? e[0].message : "error"
                )
              )
            )
          )
        );
      }
    )
  );

export const uploadBulk = ($action, $state) =>
  $action.pipe(
    ofType("START_BULK_UPLOAD"),
    flatMap(({ payload: { files, codebtor = null } }) => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;

      let flow = FlowUploadFile.default;

      if (codebtor) {
        flow = FlowUploadFile.codebtor;
      }

      const isUpdate = files.some((file) => file.documentRequestCampaignId);

      const promiseFiles = files.map((file) => {
        const { name, path, size, type } = file.file;

        return Observable.from(
          !isUpdate
            ? App.api.a2censo.documentRequestCampaign({
                body: {
                  data: {
                    attributes: {
                      request_campaign_id: requestCampaignId,
                      document_stage_id: file.document_stage_id,
                      codebtor_id: Number(codebtor.id),
                      flow,
                      document_file_metadata: { name, path, size, type }
                    }
                  }
                }
              })
            : App.api.a2censo.putDocumentRequestCampaign({
                body: {
                  data: {
                    attributes: {
                      request_campaign_id: requestCampaignId,
                      document_stage_id: file.document_stage_id,
                      codebtor_id: Number(codebtor.id),
                      flow,
                      document_file_metadata: { name, path, size, type },
                      document_request_campaign_id:
                        file.documentRequestCampaignId
                    }
                  }
                }
              })
        );
      });
      return Observable.concat(
        forkJoin(promiseFiles).pipe(
          flatMap((response) => {
            const uploadDocuments = response.map((respItem, index) => {
              const { file } = files[index];
              const key = isUpdate
                ? file.documentRequestCampaignId
                : respItem.key;

              return Observable.from(
                new UploadFileCampaign(
                  respItem.temp_file_url,
                  key
                ).uploadBinaryPut(file)
              );
            });
            return forkJoin(uploadDocuments).pipe(
              flatMap(() => {
                const updatePathDocuments = response.map((respItem, index) => {
                  const { documentRequestCampaignId: docReqCampId } = files[
                    index
                  ];

                  const documentRequestCampaignId = isUpdate
                    ? docReqCampId
                    : respItem.document_request_campaign_id;

                  return Observable.from(
                    App.api.a2censo.updatePathFileDocument({
                      body: {
                        data: {
                          attributes: {
                            document_request_campaign_id: documentRequestCampaignId,
                            path_file: respItem.complete_key
                          }
                        }
                      }
                    })
                  );
                });

                return forkJoin(updatePathDocuments).pipe(
                  flatMap(() =>
                    Observable.concat(
                      Observable.of(
                        CodebtorActions.setEditCodebtorSuccess(
                          files,
                          codebtor,
                          response
                        )
                      ),
                      Observable.of(
                        CampaignDocumentManagment.setValidateFilesComplete()
                      )
                    )
                  )
                );
              })
            );
          }),
          catchError(() => Observable.concat(Observable.of(push("/oops"))))
        )
      );
    })
  );

export default combineEpics(
  onUploadNewFile,
  onDeleteFile,
  onGetRequirementDocuments,
  onGetRequirementDocumentsAndAssociates,
  onGetRequestDocumentsStepTwo,
  onDeleteRequestFile,
  onGetRequestDocumentsStepFour,
  onUpdateCurrentFile,
  onGetRequestDocumentsStepFive,
  onGetRequirementDocumentsStepFive,
  onGetRequirementDocumentsStepSix,
  uploadBulk,
  onGetRequestDocumentsStepSix,
  onUploadRequestCampaignFiles,
  onRemoveRequestCampaignFiles,
  onGetRequestDocumentsStepSeven
);
