import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Input from "../../components/Input/Input";

import { endResetPasswordFlow } from "../../redux/epics/forgot-password";

import {
  Container,
  Error,
  Title,
  Tooltip,
  Form,
  ContinueButton,
  FormContent,
  TooltipImage,
  ToolTipText
} from "./Styles";

import ResetPasswordSchema from "./ResetPassword.schema";

const ResetPassword = ({ location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Container className="container">
      <Formik
        validationSchema={ResetPasswordSchema}
        onSubmit={(values, { setSubmitting }) => {
          // eslint-disable-next-line no-param-reassign
          values.token = location.state.token;
          dispatch(endResetPasswordFlow(values));
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => {
          const errorKeys = Object.keys(errors);
          return (
            <Form onSubmit={handleSubmit}>
              <FormContent className="col-md-4 offset-md-4">
                <Title>
                  {t("forgotPassword.resetTitle")}
                  <div data-tip={true} data-for="clickme" data-event="click">
                    <TooltipImage
                      className="financial-info__tooltip_logo"
                      src="/assets/images/icono_pregunta.png"
                      alt="a2censo"
                    />
                  </div>
                </Title>
                <Tooltip
                  id="clickme"
                  place="right"
                  type="light"
                  effect="solid"
                  clickable={true}
                >
                  <div>
                    <ToolTipText>
                      {t("forgotPassword.validations.password.matches")}
                    </ToolTipText>
                  </div>
                </Tooltip>
                <Input
                  label={t("register.password")}
                  type="password"
                  name="password"
                  id="password"
                  showTooltip={false}
                  placeholder={t("forgotPassword.newPassword")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <Input
                  label={t("register.confirmPassword")}
                  type="password"
                  showTooltip={false}
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder={t("forgotPassword.newPasswordConfirmation")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                />
                <Error>
                  {touched.password && t(errors.password)}
                  <br />
                  {touched.confirmPassword && t(errors.confirmPassword)}
                </Error>
                <ContinueButton
                  id="send_button"
                  type="submit"
                  disabled={isSubmitting || errorKeys.length}
                  value={t("register.continue")}
                >
                  {t("forgotPassword.continue")}
                </ContinueButton>
              </FormContent>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

ResetPassword.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.string,
      token: PropTypes.string
    })
  }).isRequired
};

export default ResetPassword;
