import styled from "styled-components";
import BgImage from "../../assets/images/campaign-approved-bg.jpg";

export const Wrapper = styled.div`
  background-image: url(${BgImage});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 60% 0%;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 74px;
`;

export const ButtonBackContainer = styled.div`
  width: 185px;
  margin-right: 15px;

  button {
    color: ${(props) => props.theme.manatee};
    font-size: ${(props) => props.theme.font.size.medium};
    font-weight: 800;
    background: ${(props) => props.theme.seashell};
    border: none;
  }
`;

export const BackArrow = styled.img`
  margin-right: 8px;
`;

export const ButtonRightContainer = styled.div`
  p {
    color: ${(props) => props.theme.riverBed};
    font-size: ${(props) => props.theme.font.size.medium18};
    font-weight: 800;
  }
`;

export const ImageContainer = styled.img`
  width: 485px;
  height: 307px;
  align-self: center;
  position: relative;
  bottom: 120px;
`;

export const TextContainer = styled.div`
  align-self: center;
  position: relative;
  bottom: 16%;
  text-align: center;

  p {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: ${(props) => props.theme.font.size.large24};
    line-height: 33px;
    color: ${(props) => props.theme.white};
  }

  p:nth-child(2) {
    font-weight: 600;
    font-size: ${(props) => props.theme.font.size.medium};
    line-height: 22px;
    width: 298px;
    margin-top: 8px;
  }
`;

export const BottomButtonsContainer = styled.div`
  display: flex;
  flex-direction: inherit;
  margin-top: 16px;
  button {
    font-weight: bold;
    width: 159px;
    margin-left: -30px;
  }

  button:nth-child(2) {
    width: 173px;
    margin-left: 16px;
    color: ${(props) => props.theme.lightPurple};
  }
`;
