import React from "react";
import "./BulletPresentation.styles.scss";

type IProps = {
  campaignDetail: Array<{
    id?: number;
    title?: String;
    content?: String;
  }>;
};

const BulletPresentation = ({ campaignDetail }: IProps) => (
  <div className="bullet-presentation-container">
    <div className="bullet-presentation-grid">
      {campaignDetail.map(({ id, title, content }) => {
        const contentToDisplay = content && content.split("\\u2022");
        return (
          <div key={id} className="bullet-presentation-item">
            <div className="bullet-presentation-item__container">
              <p className="bullet-presentation-item__title">{title}</p>
              <ul className="bullet-presentation-item__list">
                {contentToDisplay &&
                  contentToDisplay.map((item, i) => {
                    const itemsToDisplay =
                      item && item != ""
                        ? item
                            .split("\n")
                            .filter((line) => line != "\r" && line != "")
                        : [];
                    return (
                      itemsToDisplay.length > 0 && (
                        <li key={i} className="bullet-presentation-item__item">
                          {itemsToDisplay.map((line, j) => (
                            <>
                              <p className="bullet-presentation-item__content">
                                {line}
                              </p>
                              {j < itemsToDisplay.length - 1 && <br />}
                              {}
                            </>
                          ))}
                        </li>
                      )
                    );
                  })}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

export default BulletPresentation;
