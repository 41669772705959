import moment from "moment";
import * as Yup from "yup";

const schema = {
  state: Yup.string()
    .trim()
    .required("register.validations.name.required"),
  quality_tax: Yup.string()
    .trim()
    .required("register.validations.name.required"),
  expiration_date: Yup.date().min(
    moment().format("YYYY-MM-DD"),
    "investment.validations.expirationDate.invalid"
  )
};

export default Yup.object().shape(schema);
