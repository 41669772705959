import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { startInvestFlow, canIInvest } from "../../redux/epics/invest";
import User from "../../lib/models/user.model";
import ModalType from "../../lib/models/modal.model";
import { push } from "connected-react-router";

import {
  Container,
  Paragraph,
  Divider,
  Button,
  CloseButton,
  Icon,
  IconTitleContainer,
  IconTitle
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";

import enrollmentIcon from "assets/images/enrollment_icon.png";

const RegistrationProcessModal = ({ campaign, config }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userState = useSelector(({ user }) => ({ ...user }));

  const handleCloseButton = (closeButton) => {
    if (ModalType.handleCloseButton.BACK === closeButton) {
      dispatch(push("/"));
    }
    dispatch(ModalActions.setConditionalModalState(false));
  };

  let userPayload = {};
  if (userState.userInfo) {
    const { user } = userState.userInfo;
    userPayload = {
      personType: user.person_type,
      documentNumber: user.document_number,
      documentType: User.documentType[user.document_type],
      name: user.name,
      lastName: user.last_name,
      userId: user.id
    };
  }

  return (
    <Container>
      <CloseButton
        id="simulator-back-button"
        onClick={() =>
          config && config.closeButton
            ? handleCloseButton(config.closeButton)
            : handleCloseButton(ModalType.handleCloseButton.CLOSE)}
      >
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <IconTitleContainer>
        <IconTitle alt="previous" src={enrollmentIcon} />
      </IconTitleContainer>
      <Paragraph>
        {config && config.copy
          ? config.copy
          : `${t("registerProcess.registrationNeeded")}`}
      </Paragraph>
      <Divider />
      <Button
        id="simulator-invest-flow"
        onClick={() => {
          const action = !userState.userInfo
            ? startInvestFlow(campaign && campaign.campaign_id, false)
            : canIInvest(campaign && campaign.campaign_id, userPayload, false);
          dispatch(action);
        }}
      >
        {t("registerProcess.goToRegistrationProcess")}
      </Button>
    </Container>
  );
};

RegistrationProcessModal.propTypes = {
  campaign: PropTypes.shape({
    campaign_name: PropTypes.string.isRequired,
    term_in_months: PropTypes.number.isRequired,
    interest_rate: PropTypes.number.isRequired,
    logo_path: PropTypes.string.isRequired,
    campaign_id: PropTypes.string.isRequired,
    payment_frequency_in_months: PropTypes.number.isRequired
  }).isRequired
};

export default RegistrationProcessModal;
