import styled, { css } from "styled-components";
import { types } from "../types";
import { MidDescription } from "./MidContent";

interface Props {
  type: types;
  isPrePublish?: boolean;
}

interface Image {
  backgroundImage?: string;
}

interface Back {
  img: string;
}

const getGradient = (
  isPrePublish: boolean,
  type: types,
  darkestBlue: string
) => {
  if (isPrePublish) {
    return "linear-gradient(181.25deg, rgba(26, 56, 114, 0) 0%, rgb(18, 27, 42) 85%)";
  }

  return type === types.Primary
    ? "linear-gradient(180deg, rgba(24,54,114,0.18) 0%, #000000 100%);"
    : `linear-gradient(181.25deg, rgba(26,56,114,0.01) 0%, ${darkestBlue} 85%);`;
};

export const getBorderRadius = {
  primary: css`
    border-radius: 5px;
  `,
  secondary: css`
    border-radius: 10px;
  `,
  tertiary: css`
    border-radius: 5px;
  `
};

export const BackgroundContainer = styled.div<Props>`
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  top: 0;
  left: 0;
  ${({ type }) => getBorderRadius[type]}
  transition: all 0.5s;
`;

export const HoverGradient = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background: ${({ theme }) => `
  linear-gradient(
    181.5deg
    , rgba(59, 75, 107, 0) 0%, ${theme.colors.gradientDarkBlue.primary} 30%)`};
  transition: all 0.5s;
`;

export const Gradient = styled.div<Props>`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  background: ${({ type, theme, isPrePublish = false }) =>
    getGradient(isPrePublish, type, theme.darkestBlue)};
  transition: all 0.5s;
`;

export const Background = styled.div<Back>`
  height: 100%;
  width: 100%;
  background: url(${({ img }) => img}) no-repeat;
  background-position: center center;
  background-size: cover;
`;

export const ContentWraper = styled.div<Props & Image>`
  flex: 1;
  display: flex;
  width: 100%;
  ${({ type }) => getBorderRadius[type]}
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px 35px 30px 32px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  &:hover {
    ${BackgroundContainer} {
      transform: scale(1.2);
      background: ${({ backgroundImage, theme }) => `linear-gradient(
               181.5deg,
               rgba(59, 75, 107, 0) 0%,
               ${theme.colors.gradientDarkBlue.primary} 50%
             ),
            url("${backgroundImage}");
          `};
    }
    ${Gradient} {
      opacity: 0.5;
    }
    ${HoverGradient} {
      opacity: 1;
    }
    ${MidDescription} {
      height: 65px;
      opacity: 1;
    }
  }
`;
