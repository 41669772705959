import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Container,
  Image,
  Title,
  Subtitle,
  BottomButtonContainer,
  Button,
  GlobalContainer,
  Icon,
  CloseButton
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";

const ErrorModalStepper = ({ title, content, redirectUri, buttonText }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(push(redirectUri));
  };
  return (
    <GlobalContainer>
      <CloseButton id="button-close" onClick={handleCloseClick}>
        <Icon alt="previous" src="/assets/images/close-icon.svg" />
      </CloseButton>
      <Container>
        <Image src="/assets/images/analysis.png" />
        <Title>{t(title)}</Title>
        <Subtitle
          data-testid="generic-error-modal-content"
          id="generic-error-modal-content"
        >
          {t(content)}
        </Subtitle>
        <BottomButtonContainer>
          <Button data-testid="error-modal-return" onClick={handleCloseClick}>
            {t(buttonText)}
          </Button>
        </BottomButtonContainer>
      </Container>
    </GlobalContainer>
  );
};

ErrorModalStepper.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired,
  buttonText: PropTypes.string
};

ErrorModalStepper.defaultProps = {
  buttonText: "errorModal.success"
};

export default ErrorModalStepper;
