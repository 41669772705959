/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import "./ImageTab.styles.scss";
import environment from "environment";
import ImageDefault from "assets/images/default-user-avatar.png";

type IProps = {
  url_image?: String;
};

const ImageTab = ({ url_image }: IProps) => {
  const [reload, setReload] = useState(0);

  const checkImage = (imageSrc: any, good: any, bad: any) => {
    var img = new Image();
    img.onload = good;
    img.onerror = bad;
    img.src = imageSrc;
  }

  useEffect(() => {
    checkImage(url_image,
      function () {
        setReload(1);
      },
      function () {
        checkImage(`${environment.aws.url}/${url_image}`,
          function () {
            setReload(2);
          },
          function () {
          })
      });
  }, []);

  return (
    reload == 0 ? (<img src={ImageDefault} className="image-presentation" />) :
      reload == 1 ? (<img src={`${url_image}`} className="image-presentation" />) :
        (<img src={`${environment.aws.url}/${url_image}`} className="image-presentation" />)
  );
};

export default ImageTab;
