import styled from "styled-components";
import colors from "./colors";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AccordionItem = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  background-color: ${colors.paleGray};
  box-shadow: 0 9px 28px -28px rgba(0, 0, 0, 0.5);
  margin: 4px 77px;
`;

export const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
  :checked + label > .icon > svg {
    transform: rotate(180deg);
    color: ${colors.purple};
  }
  :checked + label > p {
    color: ${colors.purple};
  }
  :checked ~ div {
    max-height: 100vh;
    padding: 1em 2.5em 2.5em 2.5em;
  }
`;

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  padding: 2em 2.5em;
  cursor: pointer;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    svg {
      transform: rotate(0deg);
      width: 1em;
      height: 1em;
      transition: all 0.35s;
      color: ${colors.palePurple};
    }
  }
`;

export const Content = styled.div`
  max-height: 0;
  transition: all 0.35s;
  color: ${colors.riverBed};
  font-family: Nunito;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 28px;
  padding: 0 2.5em;
`;

export const Title = styled.p`
  color: ${colors.palePurple};
  font-family: Nunito;
  letter-spacing: 0;
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  ${({ theme }) => theme.breakpoints.mobile} {
    max-width: 15em;
  }
`;
