import { catchError, flatMap } from "rxjs/operators";
import * as R from "ramda";
import { push } from "connected-react-router";
import { forkJoin } from "rxjs";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import { CodeValidationSerializer } from "../../lib/serializers/codeValidation.serializer";
import CodeValidationActions, {
  ValidateCodeTypes
} from "../reducers/code-validations.reducer";
import AnalyticsActions from "../reducers/analytics.reducer";
import AnalyticsModel from "../../lib/models/analytics.model";
import User from "../../lib/models/user.model";
import UserActions from "../reducers/user.reducer";
import paramNames from "../../lib/models/parameter.model";

const getOtpSizeParameter = (parametersObject) => {
  const parameters = R.pathOr([], ["_parameter"], parametersObject);
  const paramsFiltered = parameters.filter((item) => item.name === "otp_size");
  return paramsFiltered.length > 0 ? paramsFiltered[0].value : 6;
};

const { HASHED_CODE_PARAMS } = paramNames;
const { _PARAM_004 } = HASHED_CODE_PARAMS();

export const requestNewCode = (action$) =>
  action$.pipe(
    ofType(ValidateCodeTypes.REQUEST_NEW_CODE),
    flatMap((action) =>
      forkJoin([
        Observable.from(
          App.api.a2censo.requestNewCode({
            url: { userId: action.userId }
          })
        ),
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: { tables: `${_PARAM_004}` }
          })
        )
      ]).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(CodeValidationActions.codeSuccess(response[0])),
            Observable.of(
              push(`/code-validation/${action.userId}/${action.token}`, {
                countryCode: response[0].country_code,
                phoneNumber: response[0].phone_number,
                dateGenerationToken: new Date,
                timerLimitMinutes: response[0].otp_timer_limit_minutes,
                token: action.token,
                userId: action.userId,
                response: response.status,
                otpSize: getOtpSizeParameter(response[1])
              })
            )
          )
        ),
        catchError((e) => {
          return Observable.of(CodeValidationActions.codeError(e));
        })
      )
    )
  );
export const validateToken = (action$) =>
  action$.pipe(
    ofType(ValidateCodeTypes.VALIDATE_TOKEN),
    flatMap((action) =>
      forkJoin([
        Observable.from(
          App.api.a2censo.validateToken({
            body: CodeValidationSerializer().serialize({ token: action.token }),
            url: { userId: action.userId }
          })
        ),
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: { tables: `${_PARAM_004}` }
          })
        )
      ]).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(
              UserActions.selectPersonType({
                personType: response[0].person_type
              })
            ),
            Observable.of(CodeValidationActions.codeSuccess(response[0])),
            Observable.of(
              push(`/code-validation/${action.userId}/${action.token}`, {
                countryCode: response[0].country_code,
                phoneNumber: response[0].phone_number,
                dateGenerationToken: new Date,
                timerLimitMinutes: response[0].otp_timer_limit_minutes,
                token: action.token,
                userId: action.userId,
                otpSize: getOtpSizeParameter(response[1])
              })
            )
          )
        ),
        catchError((e) => {
          return Observable.concat(
            Observable.of(CodeValidationActions.codeError(e)),
            Observable.of(
              push(`/code-validation/${action.userId}/${action.token}`, {
                countryCode: "",
                phoneNumber: "",
                dateGenerationToken: ""
              })
            )
          );
        })
      )
    )
  );
export const validateOTP = (action$) =>
  action$.pipe(
    ofType(ValidateCodeTypes.VALIDATE_CODE),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.validateOtp({
          body: CodeValidationSerializer().serialize({
            otp_code: action.code
          }),
          url: { userId: action.userId }
        })
      ).pipe(
        flatMap((response) => {
          const {
            user: { personType }
          } = App.redux.store.getState();

          return Observable.of(
            AnalyticsActions.trackEvent(AnalyticsModel.otpValidation),
            CodeValidationActions.codeSuccess(response),
            push("/success-sign-up", {
              title: `register.successSignUpTitle${
                personType.personType === User.type.pyme ? "PYME" : ""
              }`,
              message: `register.successSignUpMessage${
                personType.personType === User.type.pyme ? "PYME" : ""
              }`,
              buttonText: "register.goToSignIn",
              buttonLink: "/login",
              personType: User.type[personType.personType]
            })
          );
        }),
        catchError((e) => Observable.of(CodeValidationActions.codeError(e)))
      )
    )
  );
export default combineEpics(validateToken, validateOTP, requestNewCode);
