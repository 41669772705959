import styled from "styled-components";

export const Separator = styled.p`
  color: ${({ theme }) => theme.colors.dustyGray.dark};
  overflow: hidden;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;
  &:after {
    content: "•••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••";
    letter-spacing: 7px;
  }
`;
