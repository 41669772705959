import styled from "styled-components";

export const Container = styled.div`
  padding-left: 0;
  padding-right: 0;
  margin: 3px 0px;
  outline: none;
`;

export const SubContainer = styled.div`
  background-color: ${(props) => props.theme.white};
  border-radius: 10px;
  cursor: pointer;
  width: 92%;
  @media (min-width: 769px) {
    display: none;
  }
  outline: none;
`;

export const SubContainer2 = styled.div`
  ${(props) => props.theme.tablet} {
    display: none;
  }
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  background-color: ${(props) =>
    props.open ? props.theme.brightTurquoise : props.theme.white};
  border: 0.5px solid ${(props) => props.theme.brightTurquoise};
  float: right;
  margin: 25px -12.5px 25px 0px;
  outline: none;
`;

export const Span = styled.span`
  margin-top: 2.5px;
  font-weight: bold;
  color: ${(props) =>
    props.open ? props.theme.white : props.theme.brightTurquoise};
  outline: none;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0 0;
  padding: 15px 20px 0;
`;

export const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;
`;

export const Row3 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px 0;
`;

export const Row4 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 20px 10px;
`;

export const HeadText = styled.div`
  font-size: 12px;
  font-family: Roboto;
  font-weight: 300;
  color: ${(props) => props.theme.darkBlue2};
`;

export const Caption = styled.div`
  font-size: 16px;
  font-family: Roboto;
  font-weight: bold;
  color: ${(props) => props.theme.darkBlue2};
`;

export const NumberCaption = styled.div`
  font-size: 16px;
  font-family: Roboto;
  font-weight: bold;
  color: ${(props) => props.theme.darkBlue2};
`;

export const Details = styled.div`
  max-height: ${(props) => (props.open ? "100%" : "0")};
  overflow: hidden;
  padding: ${(props) => (props.open ? "20px 0 5px" : "0")};
  transition: all 0.4s ${(props) => (props.open ? "ease-out" : "ease-in")};
  background-color: ${(props) => props.theme.brightGray2};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  outline: none;
  width: 100%;
`;

export const DetailsText = styled.div`
  font-size: 10px;
  font-family: Roboto;
  font-weight: 300;
  color: ${(props) => props.theme.brightGray3};
`;

export const DetailsCaption = styled.div`
  font-size: 13px;
  font-family: Roboto;
  color: ${(props) => props.theme.brightGray3};
`;

export const DetailsNumberCaption = styled.div`
  font-size: 16px;
  font-family: Roboto;
  font-weight: bold;
  color: ${(props) =>
    props.negative ? props.theme.brightTurquoise : props.theme.brightGray3};
`;

export const Table = styled.table`
  width: 98%;
  border-spacing: 0;
  background-color: ${(props) => props.theme.lightGray};
  padding-top: 25px;
`;

export const Tr = styled.tr`
  width: 100%;

  &.tr-white {
    background-color: ${(props) => props.theme.white};
  }

  &.tr-gallery {
    background-color: ${(props) => props.theme.gallery};
  }
`;

export const Th = styled.th`
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding: 25px;
  color: ${(props) => props.theme.tuatara};
  &.th-right {
    text-align: right;
  }
`;

export const ContentThText = styled.div`
  width: 99px;
  &.total-to-pay {
    width: 87px;
    margin-left: 58px;
  }
`;

export const Td = styled.td`
  text-align: left;
  line-height: 24px;
  font-size: 14px;

  &.martinique {
    color: ${(props) => props.theme.martinique};
  }

  &.ironside-gray {
    color: ${(props) => props.theme.ironsideGray};
  }

  &.td-first {
    padding: 25px 25px 0 25px;
    font-size: 20px;

    &.stratos {
      color: ${(props) => props.theme.stratos};
      font-weight: bold;
      border-top-left-radius: 15px;
    }
  }

  &.td-first-text {
    padding: 25px 25px 0 25px;
    font-size: 14px;
  }

  &.td-first-last {
    padding: 25px 25px 0 25px;
    font-size: 16px;
    text-align: right;
    text-weight: bold;
    border-top-right-radius: 15px;
  }

  &.td-second {
    padding: 10px 25px 0 25px;
  }

  &.td-second-last {
    padding: 10px 25px 0 25px;
    color: ${(props) => props.theme.ironsideGray};
    font-size: 16px;
    font-weight: bold;
    text-align: right;
  }

  &.td-third {
    padding: 10px 25px 25px 25px;
  }

  &.td-third-last {
    padding: 10px 25px 25px 25px;
    color: ${(props) => props.theme.brightTurquoise};
    font-size: 16px;
    font-weight: bold;
    text-align: right;
  }

  &.td-fourth {
    padding: 15px 25px 15px 25px;

    &.martinique {
      color: ${(props) => props.theme.martinique};
      border-bottom-left-radius: 15px;
    }
  }

  &.td-fourth-last {
    padding: 15px 25px 15px 25px;
    color: ${(props) => props.theme.stratos};
    font-size: 20px;
    font-weight: bold;
    text-align: right;
    border-bottom-right-radius: 15px;
  }
`;
