import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container, Image } from "./components";
import { Paragraph, Button } from "@bvcco/a2censo-component-lib";
import MoldalActions from "redux/reducers/modal.reducer";
import img from "assets/images/modal-pass.png";

type IProps = {
  image?: string | JSX.Element;
};

const ModalPasswordCertificate = ({ image }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    dispatch(MoldalActions.setConditionalModalState(false));
  };
  return (
    <Container data-testid="modal-password-container">
      <Image data-testid="modal-password-image" src={image || img} />
      <Paragraph className="title-modal-pass">
        <strong>{t("myCertificates.modalPassword.title")}</strong>
      </Paragraph>
      <Paragraph>
        {t("myCertificates.modalPassword.desc1")}
        <strong>{t("myCertificates.modalPassword.desc2")}</strong>
        {t("myCertificates.modalPassword.desc3")}
      </Paragraph>
      <Button
        rounded={true}
        onClick={handleButtonClick}
        dataTestid="close-modal-password"
      >
        <strong>{t("myCertificates.modalPassword.button")} </strong>
      </Button>
    </Container>
  );
};

export default ModalPasswordCertificate;
