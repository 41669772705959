import styled from "styled-components";

type Props = {
  size: string;
  fontWeight?: string;
  color: string;
  align?: string;
};

export const StyledParagraph = styled.p<Props>`
  font-family: ${({ theme }) => theme.font.family.primary};
  font-size: ${({ theme, size }) => theme.font.size[size]};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ theme, color }) => theme.colors[color].color};
  text-align: ${({ align }) => align};
`;
