import styled from "styled-components";

export const Description = styled.div`
  color: ${({ theme }) => theme.white};
  margin-left: 12px;
  a {
    color: ${({ theme }) => theme.white};
    margin-right: 5px;
  }
`;
