import { createReducer, createActions } from "reduxsauce";

export const { Types, Creators } = createActions({
  setBanksList: ["banksList"],
  setResponseValidation: ["responseValidation"],
  endInvestFlow: ["payload"],
  startInvestmentsListFlowMs: ["payload"],
  startInvestmentsFile: ["payload"],
  setInvestmentsListFlowMs: ["investmentsList"]
});

export const rechargeTypes = Types;
export default Creators;

const INITIAL_STATE = {
  banksList: [],
  invest: {},
  responseValidation: {},
  investmentsList: {
    data: [],
    paginationParams: {
      totalPages: 0
    },
    isDisabledMovements: false
  }
};

const setBanksList = (state, { banksList }) => ({
  ...state,
  banksList
});

const setResponseValidation = (state, { responseValidation }) => ({
  ...state,
  responseValidation
});

const startInvestmentsListFlowMs = (state) => ({
  ...state,
  isLoading: true
});

const startInvestmentsFile = (state) => ({
  ...state,
  isLoading: true
})

const setInvestmentsListFlowMs = (state, { investmentsList }) => ({
  ...state,
  investmentsList,
  isLoading: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_BANKS_LIST]: setBanksList,
  [Types.SET_RESPONSE_VALIDATION]: setResponseValidation,
  [Types.START_INVESTMENTS_LIST_FLOW_MS]: startInvestmentsListFlowMs,
  [Types.START_INVESTMENTS_FILE]: startInvestmentsFile,
  [Types.SET_INVESTMENTS_LIST_FLOW_MS]: setInvestmentsListFlowMs
});
