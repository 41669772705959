import styled from "styled-components";

const selectColorByState = (theme, state) => {
  switch (state) {
    case "paid":
      return theme.brightTurquoise;
    case "approved":
      return theme.brightTurquoise;
    case "rejected":
      return theme.red;
    case "approved_by_timeout":
      return theme.brightTurquoise;
    case "exchange":
      return theme.brightTurquoise;
    case "inactive":
      return theme.darkGray;
    case "issuer_approved":
      return theme.brightTurquoise;
    case "late_payment":
      return theme.orange;
    case "paid_by_collection_house":
      return theme.brightTurquoise;
    case "pending_approve":
      return theme.orange;
    case "pending_new_amount_request":
      return theme.orange;
    case "pending_reject_request":
      return theme.orange;
    default:
      return theme.darkGray;
  }
};

export const Container = styled.div`
  margin-top: 10px;
`;

export const DateContent = styled.p`
  margin: 0 0 16px 0;
  font-family: "Nunito";
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.masala};
  ${(props) => props.theme.tablet} {
    font-size: 10px;
  }
`;

export const CardContainer = styled.div`
  background-color: ${(props) => props.theme.white};
  width: 100%;
  margin: 0 auto;
  border-radius: 7.5px;
  font-family: "Roboto";
  box-shadow: 0px 0px 20px 0px rgba(160, 160, 160, 0.14),
    0px 0px 20px 0 rgba(160, 160, 160, 0.14);
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  & > div > p {
    font-size: 15px;
    line-height: 18px;
  }
`;

export const HeaderDueContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.brightTurquoise};
  width: 110px;
  height: 100%;
  border-top-left-radius: 7.5px;
  font-weight: 800;
  color: ${(props) => props.theme.white};
`;

export const HeaderStateContent = styled.div`
  display: flex;
  align-items: center;
  width: 110px;
  height: 100%;
  color: ${(props) => props.theme.brightTurquoise};
`;

export const ParagraphDue = styled.p`
  width: 60px;
  text-align: center;
  font-family: "Nunito";
`;

export const ParagraphState = styled.p`
  text-align: center;
  position: relative;
  color: ${({ theme, state }) => selectColorByState(theme, state)};
  &::before {
    content: "";
    width: 12px;
    height: 12px;
    background-color: ${({ theme, state }) => selectColorByState(theme, state)};
    border-radius: 10px;
    position: absolute;
    left: -22px;
    top: 3px;
  }
`;

export const PaymentContent = styled.div`
  display: flex;
  padding: 10px 43px 20px 24px;
  ${(props) => props.theme.tablet} {
    flex-direction: column;
    padding: 10px 22px 20px 14px;
  }
`;

export const PaymentAmountContainer = styled.div`
  color: ${(props) => props.theme.stratos};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-right: 25px;
  ${(props) => props.theme.tablet} {
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const HeadingContent = styled.div`
  ${(props) => props.theme.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

export const HeadingOne = styled.h1`
  font-size: 24px;
  font-weight: 800;
  line-height: 18px;
  ${(props) => props.theme.tablet} {
    margin: 0 0 11px 0;
    width: 94px;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const HeadingTwo = styled.h2`
  font-size: 18px;
  line-height: 22.5px;
  margin: 26px 0 28px 0;
  ${(props) => props.theme.tablet} {
    margin: 3px 0 13px 0;
    font-size: 12px;
    line-height: 15px;
  }
`;

export const HeadingThree = styled.h3`
  font-family: "Nunito";
  font-size: 45px;
  font-weight: 800;
  line-height: 22.5px;
  margin: 0px 0 22px 0;
  white-space: nowrap;
  ${(props) => props.theme.tablet} {
    font-size: 29px;
    line-height: 15px;
  }
`;

export const OtherPaymentsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const PaymentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
  & > p,
  & > div > p {
    font-size: 21px;
    line-height: 33px;
    ${(props) => props.theme.tablet} {
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
    }
  }
`;

export const PaymentRowString = styled.p`
  color: ${(props) => props.theme.tuatara};
  span {
    font-size: 10px;
    line-height: 10px;
    font-weight: normal;
    display: block;
    width: 100%;
  }
`;

export const PaymentRowNumber = styled.p`
  font-weight: 900;
  color: ${(props) => props.theme.ironsideGray};
  &.bright-turquoise {
    color: ${(props) => props.theme.brightTurquoise};
  }
`;

export const ReferenceContent = styled.div`
  width: 100%;
  ${(props) => props.theme.tablet} {
    padding: 12px;
    border-top: 1px solid ${(props) => props.theme.darkGray};
    display: flex;
    & > p:last-child {
      margin: 0 0 0 10px;
    }
  }
  & > p {
    color: ${(props) => props.theme.tuatara};
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    ${(props) => props.theme.tablet} {
      font-size: 12px;
      line-height: 14px;
      text-align: left;
    }
  }
`;

export const ReferenceString = styled.p``;
export const ReferenceNumber = styled.p``;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.darkGray};
  width: 100%;
  padding: 15px 43px 15px 24px;
  ${(props) => props.theme.tablet} {
    & > div:first-child {
      width: auto;
      padding: 5px 12px 12px 12px;
    }
    border: 0;
    padding: 0;
    flex-direction: column;
  }
`;
