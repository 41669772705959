import styled from "styled-components";

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & p {
    font-family: ${({ theme }) => theme.fontFamily.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: ${({ theme }) => theme.alabasterVariation};
    width: 100%;
  }

  ${({ theme }) => theme.smartphone} {
    & p {
      width: 100%;
    }
  }
`;
