import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  p {
    width: 230px;
    line-height: 24.14px;
  }
  p,
  h4,
  strong {
    color: ${({ theme }) => theme.tuataraLight};
  }
  h4 {
    margin-top: 29px;
    font-weight: 700;
    strong {
      font-weight: 900;
    }
    margin-bottom: -20px;
    font-size: 24px;
  }
  background-color: ${({ theme }) => theme.white};
  height: 100vh;
`;

export const Image = styled.img`
  width: 344px;
  margin-bottom: 13px;
`;
