import styled from "styled-components";
import { Paragraph } from "../../Paragraph";

export const Title = styled(Paragraph)`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  line-height: 20px;
`;
