import React, { useState, useEffect, Suspense } from "react";
import { Provider } from "react-redux";
import { Loader } from "@bvcco/a2censo-component-lib";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import { ConnectedRouter } from "connected-react-router";
import app from "lib/app";
import Light from "styles/LightTheme";
import GlobalStyle from "styles/Global";
import { Container } from "pages/App/Styles";
import { InternetAlertContainer } from "pages/InternetAlert";
import UserTimer from "components/UserTimer/UserTimer";
import LegacyApp from "pages/App/App";
import { Routes } from "routes";
import { InitialProcessHOC } from "components/InitialProcessHOC";

export const App = () => {
  const booter: any = app;
  const [booting, setBooting] = useState(true);
  const [failedBooting, setFailedBooting] = useState(false);

  const boot = async () => {
    try {
      await booter.boot();
    } catch (e) {
      setFailedBooting(true);
    }
    setBooting(false);
  };

  useEffect(() => {
    boot();
  }, []);

  if (booting) {
    return (
      <ThemeProvider theme={Light}>
        <Container>
          <Loader />
        </Container>
      </ThemeProvider>
    );
  }

  if (failedBooting) {
    return <p>Failed to load. Please try again</p>;
  }
  return (
    <Provider store={booter.redux.store}>
      <ThemeProvider theme={Light}>
        <PersistGate loading={<Loader />} persistor={booter.redux.persistor}>
          <InternetAlertContainer>
            <UserTimer>
              <ConnectedRouter history={booter.redux.history}>
                <Suspense fallback={<Loader />}>
                  <InitialProcessHOC>
                    <LegacyApp />
                    <Routes />
                    <GlobalStyle />
                  </InitialProcessHOC>
                </Suspense>
              </ConnectedRouter>
            </UserTimer>
          </InternetAlertContainer>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  );
};
