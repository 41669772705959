import { flatMap, catchError, mergeMap } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import { forkJoin } from "rxjs";
import App from "../../lib/app";
import { formatData } from "../../lib/serializers/investorDocument.serializer";
import { AmountValidationSerializer } from "../../lib/serializers/amountValidation.serializer";
import { AvailabilityValidationSerializer } from "../../lib/serializers/availabilityValidation.serializer";
import {
  InvestSerializer,
  canIInvestSerializer,
  getTransactionSerializer
} from "../../lib/serializers/invest.serializer";
import amountErrorActions from "../reducers/investAmountError.reducer";
import LoginActions from "../reducers/login.reducer";
import myBalanceActions from "../reducers/myBalance.reducer";
import UserActions from "../reducers/user.reducer";
import ModalActions from "../reducers/modal.reducer";
import RechargeActions from "../reducers/recharge.reducer";
import InvestmentActions from "../reducers/investment.reducer";
import User from "../../lib/models/user.model";
import AnalyticsActions from "../reducers/analytics.reducer";
import AnalyticsModel from "../../lib/models/analytics.model";
import PaymentError from "../reducers/payment-error.reducer";
import ErrorsPSE, { warning } from "../../lib/utils/constants.errors";
import { getErrorIndex } from "../../lib/utils";
import paramNames from "../../lib/models/parameter.model";
import AlertActions from "../reducers/alert.reducer";
import { InvestmentConnect } from "services/investment";
import { base64ToDownloadFile } from "../../lib/utils/downloadFile";

const { HASHED_CODE_PARAMS } = paramNames;
const { _PARAM_004, _PARAM_008, _PARAM_009, _PARAM_010 } = HASHED_CODE_PARAMS();

// first step: upload file qualified investor screen
export const startInvestFlow = (campaignID, showModal = true) => ({
  type: "START_INVEST_FLOW",
  payload: {
    campaignID,
    showModal
  }
});

// save qualified investor data
export const saveQualifiedInvestor = ({ investorData, campaingID }) => ({
  type: "SAVE_QUALIFIED_INVESTOR",
  payload: {
    investorData,
    campaingID
  }
});

export const startInvestmentsListFlow = () => ({
  type: "START_INVESTMENTS_LIST_FLOW"
});

// second step: amount screen
export const startInvestAmountFlow = (campaignID) => ({
  type: "START_INVEST_AMOUNT_FLOW",
  payload: { campaignID }
});

// validate amount
export const validateAmount = (
  amount,
  amountFormatted,
  campaign,
  transactionType,
  balance,
  pse,
  userId
) => ({
  type: "START_AMOUNT_VALIDATION",
  payload: {
    amount,
    amountFormatted,
    campaign,
    transactionType,
    balance,
    pse,
    userId
  }
});

// validate amount desde microservicio
export const validateAmountMS = (
  amount,
  amountFormatted,
  campaign,
  transactionType,
  balance,
  pse,
  userId
) => ({
  type: "START_AMOUNT_VALIDATION_MS",
  payload: {
    amount,
    amountFormatted,
    campaign,
    transactionType,
    balance,
    pse,
    userId
  }
});

// validate availability
export const validateAvailable = (value, campaignId, type) => ({
  type: "START_AVAILABILITY_VALIDATION",
  payload: { value, campaignId, type }
});

export const investmentSuccess = (transactionId, person_type) => ({
  type: "INVEST_SUCCESS_FLOW",
  payload: { transactionId, person_type }
});

// Invest fail
export const investDataFail = ({ error }) => ({
  type: "INVEST_DATA_FAIL",
  payload: {
    error
  }
});

// Validation can I invest
export const canIInvest = (campaignID, userData, showModal = true) => ({
  type: "START_CAN_I_INVEST",
  payload: { data: { campaignID, showModal }, user: { ...userData } }
});

export const investmentsListFlow = ($action) =>
  $action.pipe(
    ofType("START_INVESTMENTS_LIST_FLOW"),
    flatMap(() =>
      forkJoin([
        Observable.from(App.api.a2censo.getInvestmentsList()),
        Observable.from(
          App.api.a2censo.movements({
            url: {
              page: 1,
              size: 10,
              filters: ""
            }
          })
        )
      ]).pipe(
        flatMap((response) => {
          const reforgedResponse =
            response[0].length > 0 ? response[0] : "noData";
          return Observable.of(
            myBalanceActions.startBalanceFlow(),
            push("/my-balance", [reforgedResponse, response[1]])
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              ),
              Observable.of(investDataFail(e))
            );
          }
          return Observable.concat(
            Observable.of(investDataFail(e)),
            Observable.of(push("/oops"))
          );
        })
      )
    )
  );

export const getInvestmentsListFlowMs = (action$) =>
  action$.pipe(
    ofType("START_INVESTMENTS_LIST_FLOW_MS"),
    mergeMap(
      ({
        payload: {
          page,
          pageSize,
          name,
          businessLine,
          investmentState,
          campaignState,
          isFilter
        }
      }) => {
        const { user } = App.redux.store.getState();
        return forkJoin([
          Observable.from(
            new InvestmentConnect().getInvestmentsList({
              value: user.userInfo.user.id,
              page,
              pageSize,
              name,
              businessLine,
              investmentState,
              campaignState
            })
          ),
          Observable.from(
            App.api.a2censo.movements({
              url: {
                page: 1,
                size: 10,
                filters: ""
              }
            })
          )
        ]).pipe(
          mergeMap((response) => {
            const { data, paginationParams } = response[0];
            const { transactions } = response[1];
            if (isFilter) {
              return Observable.concat(
                Observable.of(
                  InvestmentActions.setInvestmentsListFlowMs({
                    data,
                    paginationParams,
                    isDisabledMovements: transactions.length === 0
                  })
                )
              );
            } else {
              return Observable.concat(
                Observable.of(
                  InvestmentActions.setInvestmentsListFlowMs({
                    data,
                    paginationParams,
                    isDisabledMovements: transactions.length === 0
                  })
                ),
                Observable.of(myBalanceActions.startBalanceFlow())
              );
            }
          }),
          catchError((e) => {
            if (e.statusCode === 401) {
              return Observable.of(LoginActions.loginReset());
            }
            const hasErrorIndex = getErrorIndex(e);
            if (hasErrorIndex > -1) {
              return Observable.concat(
                Observable.of(
                  ModalActions.setConditionalModalState(true, "ErrorModal", {
                    title: "errorModal.title",
                    content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                    linkRedirectsTo: "/",
                    buttonText: "errorModal.success"
                  })
                ),
                Observable.of(investDataFail(e))
              );
            }
            return Observable.concat(
              Observable.of(investDataFail(e)),
              Observable.of(push("/oops"))
            );
          })
        );
      }
    )
  );

export const getInvestmentsFile = (action$) =>
  action$.pipe(
    ofType("START_INVESTMENTS_FILE"),
    mergeMap(
      ({
        payload: {
          name,
          businessLine,
          investmentState,
          campaignState,
          userId,
          userEmail,
          userIp
        }
      }) => {
        const { user } = App.redux.store.getState();
        return forkJoin([
          Observable.from(
            new InvestmentConnect().getInvestmentsFile({
              value: user.userInfo.user.id,
              name,
              businessLine,
              investmentState,
              campaignState,
              userId,
              userEmail,
              userIp
            })
          )
        ]).pipe(
          mergeMap((response) => {
            base64ToDownloadFile("Mis inversiones.xlsx", response[0]);
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(
                  false,
                  "ModalLoadingCertificate"
                )
              )
            );
          }),
          catchError((e) => {
            if (e.statusCode === 401) {
              return Observable.of(LoginActions.loginReset());
            }
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(
                  false,
                  "ModalLoadingCertificate"
                )
              ),
              Observable.of(push("/oops"))
            );
          })
        );
      }
    )
  );

export const investFlow = ($action) =>
  $action.pipe(
    ofType("START_INVEST_FLOW"),
    flatMap((action) => {
      if (sessionStorage.getItem("idToken")) {
        const { user } = App.redux.store.getState();
        const isRecharge =
          user.investorType &&
          user.investorType !== User.investorType.unknown &&
          !action.payload.campaignID;

        const isInvestment =
          user.investorType &&
          user.investorType !== User.investorType.unknown &&
          action.payload.campaignID;

        return forkJoin(
          Observable.from(
            App.api.a2censo.getStaticParameters({
              url: {
                tables: `${_PARAM_008},${_PARAM_009},${_PARAM_004},${_PARAM_010}`
              }
            })
          ),
          Observable.from(App.api.a2censo.usersInfo())
        ).pipe(
          flatMap((response) => {
            const userEnrollmentPending =
              user.userState !== User.state.finished;

            if (userEnrollmentPending && action.payload.showModal) {
              return Observable.of(
                ModalActions.setConditionalModalState(
                  true,
                  "RegistrationProcessModal",
                  action.payload.campaignID
                    ? { campaign: { campaign_id: action.payload.campaignID } }
                    : {}
                )
              );
            }
            if (isInvestment) {
              sessionStorage.setItem("transaction_type", "investment");
              return Observable.concat(
                Observable.of(ModalActions.setConditionalModalState(false)),
                Observable.of(startInvestAmountFlow(action.payload.campaignID))
              );
            }
            if (isRecharge) {
              sessionStorage.setItem("transaction_type", "recharge");
              return Observable.concat(
                Observable.of(ModalActions.setConditionalModalState(false)),
                Observable.of(
                  // eslint-disable-next-line no-underscore-dangle
                  RechargeActions.setBanksList(response[0]._pse_bank)
                ),
                Observable.of(push("/recharge-summary"))
              );
            }
            const enrollmentCompleteRedirect =
              user.userState !== User.state.finished
                ? push("/recharge-summary")
                : push("/qualified-investor-validate", response[0]);
            return Observable.concat(
              Observable.of(ModalActions.setConditionalModalState(false)),
              Observable.of(
                // eslint-disable-next-line no-underscore-dangle
                RechargeActions.setBanksList(response[0]._pse_bank)
              ),
              Observable.of(
                action.payload.campaignID
                  ? push(
                      `/campaign/${action.payload.campaignID}/invest`,
                      response[0]
                    )
                  : enrollmentCompleteRedirect
              )
            );
          }),
          catchError((e) => {
            if (e.statusCode === 401) {
              return Observable.of(LoginActions.loginReset());
            }
            const hasErrorIndex = getErrorIndex(e);
            if (hasErrorIndex > -1) {
              return Observable.concat(
                Observable.of(
                  ModalActions.setConditionalModalState(true, "ErrorModal", {
                    title: "errorModal.title",
                    content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                    linkRedirectsTo: "/",
                    buttonText: "errorModal.goHome"
                  })
                ),
                Observable.of(investDataFail(e))
              );
            }

            const observable = isInvestment
              ? Observable.of(
                  push("/oops-message", {
                    epic: "startInvestFlow",
                    data: {
                      campaignID: action.payload.campaignID,
                      showModal: action.payload.showModal
                    }
                  })
                )
              : Observable.of(
                  AlertActions.setAlertState("error", "alert.tryAgain", {
                    icon: true
                  })
                );

            return Observable.concat(
              Observable.of(investDataFail(e)),
              observable
            );
          })
        );
      }
      return Observable.of(push("/login"));
    })
  );

export const sendQualifiedInvestor = ($action) =>
  $action.pipe(
    ofType("SAVE_QUALIFIED_INVESTOR"),
    flatMap((action) => {
      const formattedData = formatData(action.payload.investorData);
      return forkJoin([
        App.api.a2censo.getStaticParameters({
          url: {
            tables: `${_PARAM_010}`
          }
        }),
        Observable.from(
          App.api.a2censo.saveQualifiedInvestor({
            body: formattedData
          })
        )
      ]).pipe(
        flatMap((response) =>
          Observable.concat(
            // eslint-disable-next-line no-underscore-dangle
            Observable.of(RechargeActions.setBanksList(response[0]._pse_bank)),
            action.payload.campaingID
              ? Observable.of(startInvestAmountFlow(action.payload.campaingID))
              : Observable.of(push("/recharge-summary")),
            Observable.of(
              UserActions.setInvestorType({
                qualityTax: action.payload.investorData.quality_tax,
                hasInvestorDocument: false
              })
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              ),
              Observable.of(investDataFail(e))
            );
          }
          return Observable.concat(
            Observable.of(investDataFail(e)),
            Observable.of(push("/oops"))
          );
        })
      );
    })
  );

export const investAmount = (action$) =>
  action$.pipe(
    ofType("START_INVEST_AMOUNT_FLOW"),
    flatMap((action) =>
      forkJoin([
        Observable.from(
          App.api.a2censo.getInvestmentCampaign({
            url: { id: action.payload.campaignID }
          })
        ),
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: { tables: `${_PARAM_004}` }
          })
        )
      ]).pipe(
        flatMap((response) => {
          let urlRedirect =
            response[0].campaign.business_line === 1
              ? "investment-amount"
              : response[0].campaign.business_line === 2
              ? "investment-amount-shares"
              : "investment-amount-convertible-note";
          return Observable.concat(
            Observable.of(
              AnalyticsActions.trackEvent(AnalyticsModel.goToRecharge)
            ),
            Observable.of(amountErrorActions.setAmountError([])),
            Observable.of(myBalanceActions.startBalanceFlow()),
            Observable.of(
              push(`/${urlRedirect}/${action.payload.campaignID}`, {
                response,
                viewMode:
                  response[0].campaign.user_invested_amount === 0
                    ? "new"
                    : "update"
              })
            )
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              ),
              Observable.of(investDataFail(e))
            );
          }
          return Observable.concat(
            Observable.of(investDataFail(e)),
            Observable.of(
              push("/oops-message", {
                epic: "startInvestAmountFlow",
                data: {
                  campaignID: action.payload.campaignID
                }
              })
            )
          );
        })
      )
    )
  );

export const amountValidation = (action$) =>
  action$.pipe(
    ofType("START_AMOUNT_VALIDATION"),
    flatMap((action) =>
      forkJoin([
        Observable.from(
          new InvestmentConnect()
            .amountValidation(
              AmountValidationSerializer(
                action.payload.campaign.user_invested_amount === 0
                  ? "investment"
                  : "increase",
                action.payload.campaign.campaign_id,
                action.payload.balance,
                action.payload.pse,
                action.payload.userId
              )
            )
            .then((res) => res)
        ),
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: { tables: `${_PARAM_010}` }
          })
        )
      ]).pipe(
        flatMap((response) => {
          let lineBusinessRedirect =
            action.payload.campaign.business_line === 1;
          if (!lineBusinessRedirect) {
            return Observable.of(
              InvestmentActions.setBanksList(response[1]._pse_bank)
            );
          }
          return Observable.concat(
            Observable.of(
              push("/investment-summary", {
                campaign: action.payload.campaign,
                amount: action.payload.amount,
                amountFormatted: action.payload.amountFormatted,
                balance: action.payload.balance,
                pse: action.payload.pse
              })
            ),
            // eslint-disable-next-line no-underscore-dangle
            Observable.of(InvestmentActions.setBanksList(response[1]._pse_bank))
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              ),
              Observable.of(amountErrorActions.setAmountError(e.errors[0].code))
            );
          }
          return e.errors
            ? Observable.of(amountErrorActions.setAmountError(e.errors[0].code))
            : Observable.of(
                AlertActions.setAlertState("error", "alert.tryAgain", {
                  icon: true
                })
              );
        })
      )
    )
  );

export const amountValidationMS = (action$) =>
  action$.pipe(
    ofType("START_AMOUNT_VALIDATION_MS"),
    mergeMap((action) =>
      forkJoin([
        Observable.from(
          new InvestmentConnect()
            .amountValidation(
              AmountValidationSerializer(
                action.payload.campaign.user_invested_amount === 0
                  ? "investment"
                  : "increase",
                action.payload.campaign.campaign_id,
                action.payload.balance,
                action.payload.pse,
                action.payload.userId
              )
            )
            .then((res) => res)
        ),
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: { tables: `${_PARAM_010}` }
          })
        )
      ]).pipe(
        mergeMap((response) => {
          let lineBusinessRedirect =
            action.payload.campaign.business_line === 1;
          if (!lineBusinessRedirect) {
            return Observable.of(
              InvestmentActions.setBanksList(response[1]._pse_bank)
            );
          }
          return Observable.concat(
            Observable.of(
              push("/investment-summary", {
                campaign: action.payload.campaign,
                amount: action.payload.amount,
                amountFormatted: action.payload.amountFormatted,
                balance: action.payload.balance,
                pse: action.payload.pse
              })
            ),
            // eslint-disable-next-line no-underscore-dangle
            Observable.of(InvestmentActions.setBanksList(response[1]._pse_bank))
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              ),
              Observable.of(amountErrorActions.setAmountError(e.errors[0].code))
            );
          }
          return e.errors
            ? Observable.of(amountErrorActions.setAmountError(e.errors[0].code))
            : Observable.of(
                AlertActions.setAlertState("error", "alert.tryAgain", {
                  icon: true
                })
              );
        })
      )
    )
  );

export const availabilityValidation = (action$) =>
  action$.pipe(
    ofType("START_AVAILABILITY_VALIDATION"),
    mergeMap((action) =>
      forkJoin([
        Observable.from(
          new InvestmentConnect()
            .availabilityValidation(
              AvailabilityValidationSerializer(
                action.payload.value,
                action.payload.campaignId,
                action.payload.type
              )
            )
            .then((res) => res)
        )
      ]).pipe(
        mergeMap((response) =>
          Observable.concat(
            Observable.of(
              InvestmentActions.setResponseValidation({ valid: true })
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }

          return e.errors
            ? Observable.of(
                InvestmentActions.setResponseValidation({
                  valid: false,
                  value: e.errors[0].detail
                })
              )
            : Observable.of(
                AlertActions.setAlertState("error", "alert.tryAgain", {
                  icon: true
                })
              );
        })
      )
    )
  );

export const investSuccessRequest = (action$) =>
  action$.pipe(
    ofType("INVEST_SUCCESS_FLOW"),
    mergeMap((action) => {
      const { user } = App.redux.store.getState();
      let personTy = "natural";
      try {
        personTy = user.userInfo.user.person_type;
      } catch (er) {
        personTy = "natural";
      }
      return Observable.from(
        new InvestmentConnect().getTransaction(
          getTransactionSerializer({
            transaction: action.payload.transactionId,
            person_type: personTy
          })
        )
      ).pipe(
        flatMap((response) =>
          Observable.of(
            push(
              `/transaction-confirmation/${action.payload.transactionId}`,
              response
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              ),
              Observable.of(investDataFail(e))
            );
          }
          return Observable.concat(
            Observable.of(investDataFail(e)),
            Observable.of(push("/oops"))
          );
        })
      );
    })
  );
export const finishInvestFlow = ($action) =>
  $action.pipe(
    ofType("END_INVEST_FLOW"),
    flatMap((action) =>
      Observable.from(
        new InvestmentConnect().investment(
          InvestSerializer({
            campaignId: action.payload.campaignId,
            transactionType:
              action.payload.userInvestedAmount === 0
                ? "investment"
                : "increase",
            documentNumber: action.payload.documentNumber,
            documentType: action.payload.documentType,
            bankCode: action.payload.bankCode,
            available: action.payload.available,
            pse: action.payload.pse,
            userEmail: action.payload.userEmail,
            userIp: action.payload.userIp
          })
        )
      ).pipe(
        flatMap((response) => {
          const processType =
            action.payload.userInvestedAmount === 0 ? "investment" : "increase";
          if (action.payload.pse > 0) {
            window.location.assign(response.pse.bankUrl);
          } else {
            const { user } = App.redux.store.getState();
            let personTy = "natural";
            try {
              personTy = user.userInfo.user.person_type;
            } catch (er) {
              personTy = "natural";
            }
            return Observable.concat(
              Observable.of(
                investmentSuccess(response.available.transactionId, personTy)
              ),
              Observable.of(
                AnalyticsActions.trackEvent(
                  AnalyticsModel.investmentToAvailable
                )
              )
            );
          }
          return Observable.of(
            AnalyticsActions.trackEvent(AnalyticsModel.goToPSEInvestment)
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          if (
            e.errors &&
            e.errors.length > 0 &&
            ErrorsPSE.includes(e.errors[0].code)
          ) {
            return Observable.concat(
              Observable.of(investDataFail(e)),
              Observable.of(PaymentError.setError(e.errors[0].code)),
              Observable.of(push("/payment-error"))
            );
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              ),
              Observable.of(PaymentError.setError(warning))
            );
          }
          return Observable.concat(
            Observable.of(PaymentError.setError(warning)),
            Observable.of(push("/payment-error"))
          );
        })
      )
    )
  );

export const validationCanIInvest = (action$) =>
  action$.pipe(
    ofType("START_CAN_I_INVEST"),
    mergeMap((action) =>
      forkJoin([
        Observable.from(
          new InvestmentConnect()
            .canIInvest(
              canIInvestSerializer({
                document_type: action.payload.user.documentType,
                document_number: action.payload.user.documentNumber,
                dv: action.payload.user.dv,
                person_type: action.payload.user.personType,
                name: action.payload.user.name,
                last_name: action.payload.user.lastName,
                userId: action.payload.user.userId,
                userEmail: action.payload.user.userEmail,
                userIp: action.payload.user.userIp
              })
            )
            .then((res) => res)
        )
      ]).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(
              startInvestFlow(
                action.payload.data.campaignID,
                action.payload.data.showModal
              )
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          if (e.errors) {
            return Observable.concat(
              Observable.of(investDataFail(e)),
              Observable.of(
                ModalActions.setConditionalModalState(
                  true,
                  "RegisterErrorModal",
                  {
                    errorCodes: e.errors[0].code,
                    redirectUri: "/",
                    textOnButton: "oops.goHome"
                  }
                )
              )
            );
          }
          return Observable.concat(
            Observable.of(investDataFail(e)),
            Observable.of(
              push("/oops-message", {
                epic: "canIInvest",
                data: {
                  user: action.payload.user,
                  campaignID: action.payload.data.campaignID,
                  showModal: action.payload.data.showModal
                }
              })
            )
          );
        })
      )
    )
  );

export default combineEpics(
  investFlow,
  sendQualifiedInvestor,
  investAmount,
  amountValidation,
  amountValidationMS,
  finishInvestFlow,
  investmentsListFlow,
  getInvestmentsListFlowMs,
  getInvestmentsFile,
  investSuccessRequest,
  validationCanIInvest,
  availabilityValidation
);
