import { createReducer, createActions } from "reduxsauce";
import { TypeUserDataReducer } from "./types";

const { Types, Creators } = createActions({
  startFetchUpdateUserData: ["payload"],
  endFetchUpdateUserDataSuccess: [],
  endFetchUpdateUserDataError: ["error"],
  clearUpdateUserData: []
});

export const UpdateUserDataTypes = Types;
export default Creators;

const INITIAL_STATE: TypeUserDataReducer = {
  isFetching: false,
  error: "",
  success: false
};

const startFetchUserData = (state: TypeUserDataReducer) => ({
  ...state,
  error: "",
  isFetching: true
});

const endFetchUserDataSuccess = (state: TypeUserDataReducer) => ({
  ...state,
  isFetching: false,
  success: true
});

type ErrorType = {
  error: string;
};

const endFetchUserDataError = (
  state: TypeUserDataReducer,
  payload: ErrorType
) => ({
  ...state,
  isFetching: false,
  error: payload.error
});

const clearUpdateUserData = () => INITIAL_STATE;

export const updateUserDataReducer = createReducer(INITIAL_STATE, {
  [Types.START_FETCH_UPDATE_USER_DATA]: startFetchUserData,
  [Types.END_FETCH_UPDATE_USER_DATA_SUCCESS]: endFetchUserDataSuccess,
  [Types.END_FETCH_UPDATE_USER_DATA_ERROR]: endFetchUserDataError,
  [Types.CLEAR_UPDATE_USER_DATA]: clearUpdateUserData
});
