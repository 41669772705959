export enum EnvCode {
  Production = "production",
  Development = "development"
}

/**
 * Use this file to centralize the use of process.env.XXX
 */
export default {
  apiKey: process.env.REACT_APP_API_KEY || "GR4B1L1TY_4CC355",
  apiUrl: process.env.REACT_APP_API_URL || "http://localhost:8080",
  apiShowcaseUrl:
    process.env.REACT_APP_SERVICE_SHOWCASE_API_URL ||
    process.env.REACT_APP_API_URL ||
    "http://localhost:8080",
  apiCampaingDetailUrl:
    process.env.REACT_APP_SERVICE_CAMPAIGN_DETAIL_API_URL || "http://localhost:10003",
  apiInvestmentUrl:
    process.env.REACT_APP_SERVICE_INVESTMENT_API_URL || "http://localhost:10004",
  apiShowcasePrefix: process.env.REACT_APP_SERVICE_SHOWCASE_PREFIX || "showcase",

  apiSummaryReportPdfUrl:
    process.env.REACT_APP_SERVICE_SUMMARY_PDF_URL || "http://localhost:10006",
  apiSummaryReportEmailUrl:
    process.env.REACT_APP_SERVICE_SUMMARY_EMAIL_URL || "http://localhost:10007",
  gtmContainerId: process.env.REACT_APP_GTM_CONTAINER_ID || "GTM-KVNF9WQ",
  gtmContainerIdPre:
    process.env.REACT_APP_GTM_CONTAINER_ID_PREPROD || "GTM-TMWWVS5",
  gtmContainerIdTest:
    process.env.REACT_APP_GTM_CONTAINER_ID_TEST || "GTM-57F2Q9P",

  gtagId: process.env.REACT_APP_GT_ID_PROD || "G-RHYZN0H583",
  gtagIdPre: process.env.REACT_APP_GT_ID_PREPROD || "G-PZG54TH411",
  env: process.env.NODE_ENV,
  publicUrl: process.env.PUBLIC_URL,
  aws: {
    url: process.env.REACT_APP_AWS_URL
  },
  recaptcha: {
    siteKey: process.env.REACT_APP_RECAPTCHA_SITEKEY
  }
};
