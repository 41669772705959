import styled from "styled-components";

export const Container = styled.div`
  width: 648px;
  height: 432px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.whiteSmoke};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.smartphone} {
    width: 100%;
  }
`;
