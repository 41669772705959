import styled from "styled-components";

export const Container = styled.div`
  background: ${({ theme }) => theme.white};
  display: flex;
  justify-content: center;

  & div > .__react_component_tooltip {
    background-color: ${({ theme }) => theme.colors.dolphin.bgColor};
  }
  & div > .__react_component_tooltip.type-light.place-top:after {
    border-top-color: ${({ theme }) => theme.colors.dolphin.bgColor};
  }

  ${({ theme }) => theme.smartphone} {
    justify-content: flex-start;
    & div > .__react_component_tooltip {
      background-color: ${({ theme }) => theme.altoForest};

      & p {
        color: ${({ theme }) => theme.mako};
      }
    }
    
    & div > .__react_component_tooltip.type-light.place-bottom:after {
      border-bottom-color: ${({ theme }) => theme.altoForest};
      margin-left: -20px;
    }
  }
`;
