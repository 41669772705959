const { Serializer } = require("jsonapi-serializer");

module.exports = {
  ConfirmDataSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "name",
        "last_name",
        "second_last_name",
        "document_type",
        "document_number",
        "person_type",
        "user_type_id",
        "document_expedition_date",
        "expedition_place"
      ]
    })
};
