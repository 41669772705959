import { ICampaign } from "redux/store";
import { IResponseCampaign } from "./types";

export const getCampaignsNormalizer = (
  campaign: IResponseCampaign,
  serverTime: string
): ICampaign => {
  const { campaignDebtConditions, ...rest } = campaign;
  return {
    ...rest,
    serverTime,
    financialConditions: campaignDebtConditions //add logic when convertible note and shares exist
  };
};
