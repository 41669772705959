import environment from "environment";
import { Connect } from "./connect";

type ISendSummaryEmail = {
  userId: number;
  year: string;
  pdfType: string;
  fileName: string;
  userIp: string;
};
export class SendPdfEmailConnect extends Connect {
  constructor() {
    const { apiKey, apiSummaryReportEmailUrl } = environment;
    super(apiSummaryReportEmailUrl, "", { apiKey });
  }

  async sendEmailSummary({
    userId,
    year,
    pdfType,
    fileName,
    userIp
  }: ISendSummaryEmail): Promise<any> {
    const response: any = await this.request({
      method: "post",
      service: "",
      body: { body: JSON.stringify({ userId, year, pdfType, fileName, userIp }) }
    });

    return response.data;
  }
}
