import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100vw;
  padding: 10px 0 220px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("/assets/images/a2censo/bg-blue@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  top: -100px;
  margin: 0 0 -101px 0;
`;

export const VideoContent = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  top: -150px;
  ${(props) => props.theme.smartphone} {
    top: -76px;
  }
`;

export const VideoIframe = styled.iframe`
  width: 637px;
  height: 358px;

  ${({ theme }) => theme.smartphone} {
    width: 100%;
    height: 201px;
    margin: 0 29px;
  }
`;
export const TabsContent = styled.div`
  display: flex;
  justify-content: center;
  width: 715px;
  margin: 0 auto 196px auto;
  & > div > div:nth-child(2) {
    height: 227px;
    & > div {
      width: 293px;
    }
    & > div > div > p {
      text-align: left;
      font-family: "Nunito";
      font-size: 16px;
    }
    & > div > div > p.tab5 {
      width: 315px;
    }
  }
  ${(props) => props.theme.smartphone} {
    margin: 0 auto 60px auto;
    padding: 30px;
    width: inherit;
    & > div > div:nth-child(2) {
      height: 352px;
      & > div > div > p,
      & > div > div > p.tab5 {
        text-align: center;
        width: 268px;
        margin: auto;
      }
    }
  }
`;

export const HeadingContent = styled.div`
  margin: -77px auto 56px auto;

  ${(props) => props.theme.smartphone} {
    margin: 0px auto 10px auto;
    width: 90%;
  }
`;
