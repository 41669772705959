import styled, { css } from "styled-components";

type Props = {
  isActive: boolean;
};

export const Tab = styled.div<Props>`
  background: ${({ theme }) => theme.titanWhite};
  border: solid 1px ${({ theme }) => theme.titanWhiteVariation};

  cursor: pointer;
  width: 288px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-left: 15px;
  & p {
    margin-bottom: -3px;
    font-family: ${({ theme }) => theme.fontFamily.primary};
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: ${({ theme }) => theme.mako};
  }

  &:hover {
    box-shadow: 0px 19px 18px -17px rgba(0, 0, 0, 0.34);
    border: solid 1px ${({ theme }) => theme.lightPurple};
    background: ${({ theme }) => theme.portage};
    & div {
      background-image: url("/assets/images/icon-arrow-white.png");
    }
    & p {
      color: ${({ theme }) => theme.white};
    }
  }

  ${({ theme }) => theme.smartphone} {
    max-width: 228px;
    & p {
      font-size: 14px;
      line-height: 19px;
      text-align: center;
    }

    &:hover {
      background: ${({ theme }) => theme.lightPurple};
    }
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      & p {
        color: ${theme.white};
      }
      background: ${theme.lightPurple};
      & div {
        background-image: url("/assets/images/icon-arrow-white.png");
      }
    `};
`;
