import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InvestorItem from "../InvestorItem/InvestorItem";
import { Container, Title } from "./Styles";

const InvestorList = ({ items }) => {
  const { t } = useTranslation();
  const Investors = items.map((item, index) => {
    const Element = [];
    if (index === 0) {
      Element.push(<Title>{t("investor.title")}</Title>);
    }
    Element.push(<InvestorItem investor={item} key={item.id} />);
    return Element;
  });
  return <Container>{Investors}</Container>;
};

InvestorList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired
};

export default InvestorList;
