export const warning = "warning";
export const Errors = [
  "02",
  "04",
  "05",
  "06",
  "09",
  "10",
  "17",
  "20",
  "21",
  "22",
  "23",
  "25",
  "26",
  "27",
  "036",
  "037",
  "038",
  "041",
  "043",
  "050",
  "INVESTMENT-050",
  "051",
  "052",
  "053",
  "054",
  "055",
  "056",
  "057",
  "058",
  "059",
  "060",
  "416",
  "419",
  "417",
  "418",
  "420",
  "421",
  "1002",
  "1003",
  "EXPERIAN-001",
  "EXPERIAN-002",
  "EXPERIAN-003",
  "EXPERIAN-004",
  "PYME_SELF_APPRAISAL-004",
  "PYME_SELF_APPRAISAL-006"
];

export const ExperianPriorityCodes = ["02", "04", "05", "06", "09", "10", "17"];

export const ExperianUnAvailabilityCodes = ["EXPERIAN-000", "20"];

export default [
  "PSE-CREATE_TRANSACTION-001",
  "PSE-CREATE_TRANSACTION-002",
  "PSE-CREATE_TRANSACTION-003",
  "PSE-CREATE_TRANSACTION-004",
  "PSE-CREATE_TRANSACTION-005",
  "PSE-CREATE_TRANSACTION-006",
  "PSE-CREATE_TRANSACTION-007",
  "PSE-CREATE_TRANSACTION-008",
  "PSE-CREATE_TRANSACTION-009",
  "PSE-CREATE_TRANSACTION-010",
  "PSE-CREATE_TRANSACTION-011"
];

export const ErrorValidateCIIUInvalid = "055";
export const ExperianDeletedUserCode = "EXPERIAN-004";
export const ExperianBlockedUser = "EXPERIAN-002";
export const ExperianPendingRiskValidation = "EXPERIAN-001";
export const ExperianDoesNotPass = "EXPERIAN-003";
export const SHAREHOLDER_ERROR = ["1002", "1003"];
export const RRHH_ERROR_CODES = ["416"];

export const ErrorServices = ["016", "017", "018"];

export const attemptsValidationErrors = ["ATTEMPTS-01"];

export const errorCodebtorUserBlocked = "CODEBTORS_001";
