import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateSpacer = styled.div`
  font-family: "Roboto";
  font-size: 14px;
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 5px;
  color: ${(props) => props.theme.middleGray};
`;

export default {
  Container
};
