import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../styles/Styles";
import {
  Container,
  GoBack,
  BackButton,
  BackButtonImage,
  Destiny,
  PrepaidContent,
  ContainerOption,
  SubTitle,
  ConfirmFooter,
  ConfirmButton
} from "./Styles";
import RadioCard from "../../components/RadioCard/RadioCard";
import DownloadFile from "../../components/DownloadFile/DownloadFile";
import ModalActions from "../../redux/reducers/modal.reducer";

const PrepaidDestiny = () => {
  const { t } = useTranslation();
  const [optionSelected, setOptionSelected] = useState("");
  const isFetching = useSelector(({ payments }) => payments.isFetching);
  const otherAmount = useSelector(({ payments }) => payments.otherAmount);

  // eslint-disable-next-line no-unused-vars
  const issuerName = useSelector(({ user }) => user.userInfo.user.name);
  const campaignId = useSelector(({ payments }) => payments.campaignSelected);
  const issuerPayment = useSelector(({ payments }) =>
    payments.issuerPaymentList.find(
      (item) => item.campaign_id === Number(campaignId)
    )
  ).issuer_payment;
  const dispatch = useDispatch();
  const handleBack = () => {
    dispatch(push("/my-payments"));
  };
  const handleOptionSelected = (option) => {
    setOptionSelected(option);
  };
  const handleContinue = () => {
    dispatch(
      ModalActions.setConditionalModalState(true, "PrepaidDestinyModal", {
        issuerPaymentId: issuerPayment.id,
        issuerName,
        reduceType: optionSelected,
        newAmount: otherAmount
      })
    );
  };
  return (
    <PrepaidContent>
      <Container>
        <GoBack>
          <BackButton onClick={handleBack} id="prepaid-go-back">
            <BackButtonImage alt="previous" src="/assets/images/previous.png" />
          </BackButton>
        </GoBack>
        <Destiny>
          <PageTitle>{t("prepaidDestiny.title")}</PageTitle>
          <SubTitle>
            <strong>{issuerName}</strong>
            {t("prepaidDestiny.subTitle")}
          </SubTitle>
          <DownloadFile
            fileName={t("prepaidDestiny.fileName")}
            fileUrl={issuerPayment.equity_rights_url}
          />
          <ContainerOption>
            <RadioCard
              id="reduce-due"
              title={t("prepaidDestiny.reduceQuota.title")}
              description={t("prepaidDestiny.reduceQuota.description")}
              isSelected={optionSelected === "ReduceTime"}
              onSelected={() => handleOptionSelected("ReduceTime")}
            />
            <RadioCard
              id="reduce-time"
              title={t("prepaidDestiny.reduceTime.title")}
              description={t("prepaidDestiny.reduceTime.description")}
              isSelected={optionSelected === "ReduceDue"}
              onSelected={() => handleOptionSelected("ReduceDue")}
            />
          </ContainerOption>
        </Destiny>
      </Container>
      <ConfirmFooter>
        <ConfirmButton
          id="send_button"
          type="submit"
          data-testid="save-button-recharge"
          disabled={!optionSelected || isFetching}
          onClick={handleContinue}
        >
          {isFetching
            ? t("prepaidDestiny.loading")
            : t("prepaidDestiny.continue")}
        </ConfirmButton>
      </ConfirmFooter>
    </PrepaidContent>
  );
};

export default PrepaidDestiny;
