import React, { useState } from "react";
import "./Info.scss";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { push } from "connected-react-router";
import { startInvestFlow, canIInvest } from "../../../redux/epics/invest";
import User from "../../../lib/models/user.model";
import AnalyticsActions from "../../../redux/reducers/analytics.reducer";
import AnalyticsModel from "../../../lib/models/analytics.model";
import { CountDown } from "../../shared/CountDown/index";

enum ICampaingTypes {
  Debt = 1,
  Shares = 2,
  ConvertibleNote = 3
}

enum ICampaingState {
  Open = 1,
  Awarded = 2,
  Deserted = 3,
  Close = 6
}

const Info = ({
  info,
  type,
  state,
  secondsUntilPublish,
  showCountDown,
  showInvestButton
}: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const idToken = sessionStorage.getItem("idToken");
  const refreshToken = sessionStorage.getItem("refreshToken");
  const activeSession = !!(idToken && refreshToken);
  const appStore: any = useSelector((store) => store);
  let userData: any = false;
  let isPyme = false;
  if (activeSession) {
    userData = appStore.user.userInfo.user;
    const currentPersonType = userData ? userData.person_type || "" : "";
    isPyme = currentPersonType === User.type.pyme;
  }
  const userState = useSelector(() => {
    if (activeSession) {
      return { ...appStore.user };
    }
    return false;
  });
  let userPayload = {};
  if (userState) {
    const { user } = userState.userInfo;
    const Iuser: any = User;
    userPayload = {
      personType: user?.person_type,
      documentNumber: user?.document_number,
      documentType: Iuser.documentType[user?.document_type],
      name: user?.name,
      lastName: user?.last_name,
      userId: user?.id
    };
  }
  const animation = () => {
    let size = 0;
    if (screen.width < 1280) {
      size = 531;
    } else {
      size = 490;
    }
    if (document.documentElement.scrollTop > size) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  window.onscroll = () => animation();
  const onClickHandler = (campaignId: number) => {
    const action = !userState
      ? startInvestFlow(campaignId)
      : canIInvest(campaignId, userPayload);
    dispatch(action);
    dispatch(
      AnalyticsActions.trackEvent(AnalyticsModel.investFromCampaignDetail)
    );
  };
  const goTo = () => {
    if (!activeSession) {
      return dispatch(push("/login"));
    }
    return onClickHandler(info.campaignId);
  };
  return (
    <>
      <div
        className={`texto_float ${
          showCountDown
            ? "countdown_float"
            : showInvestButton
            ? "investbutton_float"
            : ""
        } ${visible ? "show" : "hide"}`}
      >
        <div
          className={`content_text ${
            showCountDown
              ? "countdown_text"
              : showInvestButton
              ? "investbutton_text"
              : ""
          }`}
        >
          <div className="texto_float__img">
            <img src={info.logo} />
          </div>
          <div className="texto_float__info">
            <div className="texto_float__info__company_index">Empresa</div>
            <figure id="company" title={info.company} tooltip-dir="left">
              <div className="texto_float__info__company">{info.company}</div>
            </figure>
          </div>
          <div
            className={`texto_float__description ${
              !showInvestButton && !showCountDown ? "withoutDes" : ""
            }${showCountDown ? "countdown_description" : ""}`}
          >
            <div className="texto_float__description__campaign_index">
              Campaña
            </div>
            <figure id="campaing" title={info.campaign} tooltip-dir="left">
              <div className="texto_float__description__campaign">
                {info.campaign}
              </div>
            </figure>
          </div>
          <div
            className={`texto_float__button ${
              !showInvestButton && !showCountDown
                ? "without"
                : showInvestButton
                ? ""
                : showCountDown
                ? "countdown"
                : ""
            }`}
          >
            {state != ICampaingState.Close &&
              state != ICampaingState.Awarded &&
              showInvestButton && (
                <button
                  onClick={() => goTo()}
                  className={`${
                    type === ICampaingTypes.Shares
                      ? "action"
                      : type === ICampaingTypes.ConvertibleNote
                      ? "note"
                      : type === ICampaingTypes.Debt
                      ? "debt"
                      : ""
                  }`}
                >
                  {t("showcase.invest")}
                </button>
              )}
            {showCountDown && (
              <CountDown remainingSeconds={secondsUntilPublish} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
