const { Serializer } = require("jsonapi-serializer");

module.exports = {
  InvestmentCancellationSerializerOTP: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["otp_code"]
    }),
  InvestmentCancellationSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["amount", "campaign_id", "transaction_type"]
    })
};
