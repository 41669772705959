import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { push } from "connected-react-router";
import Filters from "./Filters/Filters";
import PaymentsActions from "../../redux/reducers/payments.reducer";
import LoaderSVG from "../../components/LoaderSVG/LoaderSVG";
import PaymentHistoryCard from "../../components/PaymentHistoryCard/PaymentHistoryCard";
import InfiniteScroll from "../../components/paginator/InfiniteScroll/InfiniteScroll";
import {
  Container,
  LoadingContainer,
  LoadingText,
  PageTitle,
  PageContent,
  PaymentsList,
  NoPayments,
  BackButton,
  BackButtonImage,
  BackContainer
} from "./Styles";

const PaymentsHistory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoaded, setLoaded] = useState(false);
  const [params, setParams] = useState({
    campaignId: null,
    state: null,
    yearMonth: null,
    currentPage: 1,
    pageSize: 5
  });
  const [isClearFilter, setClearFilter] = useState(false);
  const paymentsList = useSelector(({ payments }) => payments);
  const campaigns = paymentsList.issuerPaymentList.map((item) => ({
    value: item.campaign_id,
    text: item.name
  }));
  const { listSize, paymentsList: list } = paymentsList;

  useEffect(() => {
    if (!isLoaded) {
      setLoaded(true);
      dispatch(
        PaymentsActions.getPayments({
          url: { ...params }
        })
      );
    }
  }, [isLoaded]);

  useEffect(() => {
    setLoaded(false);
  }, [list]);

  useEffect(() => {
    if (isClearFilter) {
      setClearFilter(false);
      dispatch(
        PaymentsActions.getPayments({
          url: params
        })
      );
    }
  }, [isClearFilter]);

  const handleScrollDown = () => {
    setParams({
      ...params,
      currentPage: params.currentPage++
    });
    if (!isLoaded && list.length < listSize) {
      setLoaded(true);
      dispatch(
        PaymentsActions.getPayments({
          url: params
        })
      );
    }
  };

  const onFilter = ({ campaignId, state, yearMonth }) => {
    setLoaded(true);
    setParams({
      ...params,
      campaignId: campaignId,
      state: state,
      yearMonth: yearMonth,
      currentPage: 1
    });
    dispatch(
      PaymentsActions.getPayments({
        url: { ...params, campaignId, state, yearMonth }
      })
    );
  };

  const cleanFilters = () => {
    setClearFilter(true);
    setLoaded(true);
    setParams({
      campaignId: null,
      state: null,
      yearMonth: null,
      currentPage: 1,
      pageSize: 5
    });
  };

  const renderPaymentsList = () => {
    const paymentsComponents = [];
    paymentsList.paymentsList.forEach((payment) => {
      paymentsComponents.push(
        <PaymentHistoryCard
          key={payment.id}
          dueNumber={payment.fee}
          payment={payment}
        />
      );
    });
    return paymentsComponents;
  };

  const handleBack = () => {
    dispatch(push("/my-payments"));
  };

  return (
    <Container className="container">
      <PageContent>
        <BackContainer>
          <BackButton data-testid="back-button" onClick={handleBack}>
            <BackButtonImage alt="previous" src="/assets/images/previous.png" />
          </BackButton>
        </BackContainer>
        <PageTitle>{t("paymentsHistory.pageTitle")}</PageTitle>
        <Filters
          campaigns={campaigns}
          onFilter={onFilter}
          cleanFilters={cleanFilters}
        />
        <InfiniteScroll
          id="infinite-scroll-my-movements"
          enable={true}
          delay={500}
          onScrollDown={handleScrollDown}
        >
          <PaymentsList>
            {typeof paymentsList.paymentsList === "object" &&
            paymentsList.paymentsList.length > 0 ? (
              <>{renderPaymentsList()}</>
            ) : (
              <NoPayments>{t("paymentsHistory.noPayments")}</NoPayments>
            )}
            {paymentsList.isFetching && (
              <LoadingContainer>
                <LoaderSVG />
                <LoadingText>{t("paymentsHistory.loading")}</LoadingText>
              </LoadingContainer>
            )}
          </PaymentsList>
        </InfiniteScroll>
      </PageContent>
    </Container>
  );
};

export default PaymentsHistory;
