import styled from "styled-components";
import { Button as btn } from "@bvcco/a2censo-component-lib";

export const Container = styled.div`
  width: 828px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px 0 20px;
  ${(props) => props.theme.smartphone} {
    padding: 10px 0 10px;
    margin: 0 5%;
  }
  border-radius: 10px;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => props.theme.smartphone} {
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-direction: column-reverse;
  }
`;

export const ParagraphContainer = styled.div`
  line-height: 32px;
  margin-bottom: 40px;
  margin-top: 30px;
  max-width: 60%;
  ${(props) => props.theme.smartphone} {
    max-width: 90%;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 40px;
    margin-top: 50px;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.brightTurquoise};
  margin-bottom: 14px;
`;

export const Button = styled(btn)`
  width: 215px;
  height: 49px;
  margin: 2%;
  font-weight: bold;
  cursor: pointer;
  ${(props) => props.theme.smartphone} {
    width: 80%;
  }
`;

export const Icon = styled.img`
  width: 30px;
  height: 30px;
  ${(props) => props.theme.smartphone} {
    width: 25px;
    height: 25px;
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  align-self: flex-end;
  margin-right: 30px;
  ${(props) => props.theme.smartphone} {
    margin-right: 10px;
  }
`;
