import * as Yup from "yup";
import UserModel from "../../lib/models/user.model";

export const internationalRegExpPJ = /^[.+_\-@./#&0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ]+$/;

const schema = {
  documentType: Yup.string()
    .trim()
    .required("confirmPayAmount.validations.documentType.required"),
  documentNumber: Yup.string("confirmPayAmount.validations.number")
    .matches(/^[1-9][0-9]{1,10}$/, "confirmPayAmount.validations.number")
    .required("confirmPayAmount.validations.documentNumber.required"),
  bank: Yup.string().required("confirmPayAmount.validations.bank.required")
};

export default (personType) => {
  let confirmSchema = { ...schema };
  if (personType !== UserModel.type.natural) {
    confirmSchema = {
      ...confirmSchema,
      dv: Yup.number("confirmPayAmount.validations.number")
        .typeError("confirmPayAmount.validations.number")
        .min(0, "confirmPayAmount.validations.dv.min")
        .max(9, "confirmPayAmount.validations.dv.max")
        .required("register.validations.dv.required")
    };
  }
  return Yup.object().shape(confirmSchema);
};
