import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setAvailables: ["availables"],
  fetchAvailables: [],
  setCertificates: ["payload", "isInvestor"],
  setCertificatesReport: ["payload"],
  fetchCertificates: ["payload"],
  searchCertificates: ["query"],
  searchCertificatesPyme: ["query"],
  onDownloadCertificate: ["certificateId"],
  cleanCertificates: [],
  onDownloadCertificatesByToken: ["token"],
  onDownloadReportPyme: ["certificateId", "campaignName"],
  onSendEmailReport: ["certificateId", "year"],
  sendEmailFinancialTax: ["year"],
  onSendEmailRetentionSingle: ["campaignCompanyId", "year", "campaignId"],
  setDownloadError: ["error"]
});

export type Certificates = {
  [key: string]: string | number | boolean | (() => void);
  campaignsIHaveInvested: number;
  certificateId: number | string;
  companyName: string;
  mostRecentInvested: string;
  campaignName: string;
  campaignInvestors: number;
  campaignId: number;
  campaignCompanyId: number;
};

export type Store = {
  userCertificates: {
    availables: {
      withholdingTaxesCerts: number;
      financialTaxesCerts: number;
      reportYearCerts: number;
    };
    certificates: Array<Certificates>;
    firstYear: number;
    certificatesFilter: Array<Certificates>;
    loading: boolean;
  };
  dashboardCampaigns: {
    activeCampaings: Array<any>;
    awardedCampaigns: Array<any>;
  };
  downloadCertificateError: string;
};

export enum TypeCertificate {
  Retention = 1,
  FinancialTax = 2,
  ReportAnual = 3
}

export const UserCertificatesTypes = Types;
export default Creators;

export type TypeUserCertificates = {
  availables: {
    withholdingTaxesCerts: number;
    financialTaxesCerts: number;
    reportYearCerts: number;
  };
  certificates: Array<any>;
  certificatesFilter: Array<any>;
  loading: boolean;
  firstYear: number;
  downloadCertificateError: string;
};

export const INITIAL_STATE: TypeUserCertificates = {
  availables: {
    withholdingTaxesCerts: 0,
    financialTaxesCerts: 0,
    reportYearCerts: 0
  },
  certificates: [],
  certificatesFilter: [],
  loading: false,
  firstYear: 0,
  downloadCertificateError: ""
};

const fetchAvailables = (state: TypeUserCertificates) => ({
  ...state,
  loading: true
});

export type TypeAvailables = {
  availables: {
    withholding_taxes_certs: number;
    financial_taxes_certs: number;
    annual_investor_reports: number;
  };
};

const setAvailables = (
  state: TypeUserCertificates,
  { availables }: TypeAvailables
) => ({
  ...state,
  availables: {
    withholdingTaxesCerts: availables.withholding_taxes_certs,
    financialTaxesCerts: availables.financial_taxes_certs,
    reportYearCerts: availables.annual_investor_reports
  },
  loading: false
});

const fetchCertificates = (state: TypeUserCertificates) => ({
  ...state,
  loading: true
});

const sortCertificatesBykey = (key: string) => (
  a: Certificates,
  b: Certificates
) => {
  if (a[key] > b[key]) {
    return 1;
  }
  if (a[key] < b[key]) {
    return -1;
  }
  return 0;
};

const setCertificates = (
  state: TypeUserCertificates,
  { payload, isInvestor }: any
) => {
  const sortBy = isInvestor ? "companyName" : "campaignName";
  const certificatesMap = payload.certificates
    .map(
      (cert: any): Certificates =>
        isInvestor
          ? {
              campaigns: cert.campaigns_i_have_invested,
              campaignsIHaveInvested: cert.campaigns_i_have_invested?.length,
              certificateId: cert.certificate_id,
              companyName: cert.company_name,
              mostRecentInvested: cert.most_recent_campaign_i_have_invested,
              campaignInvestors: 0,
              campaignName: "",
              campaignId: 0,
              campaignCompanyId: cert.campaign_company_id
            }
          : {
              certificateId: cert.certificate_id,
              companyName: cert.company_name,
              campaignName: cert.campaign,
              campaignId: cert.campaign_id,
              campaignInvestors: cert.campaign_investors,
              campaignsIHaveInvested: 0,
              campaignCompanyId: cert.campaign_company_id,
              mostRecentInvested: ""
            }
    )
    .sort(sortCertificatesBykey(sortBy));
  return {
    ...state,
    firstYear: payload.first_year || 0,
    certificates: certificatesMap,
    certificatesFilter: certificatesMap,
    loading: false
  };
};

const setCertificatesReport = (
  state: TypeUserCertificates,
  { payload }: any
) => {
  const certificatesMap = payload.certificates
    .map(
      (cert: any): Certificates => ({
        certificateId: cert.certificate_id,
        companyName: cert.company_name,
        campaignName: cert.campaign,
        campaignId: cert.campaign_id,
        campaignInvestors: cert.quantity_certificates,
        campaignsIHaveInvested: 0,
        mostRecentInvested: "",
        campaignCompanyId: 0
      })
    )
    .sort(sortCertificatesBykey("campaignName"));
  return {
    ...state,
    firstYear: payload.first_year || 0,
    certificates: certificatesMap,
    certificatesFilter: certificatesMap,
    loading: false
  };
};

const searchCertificatesByFilter = (
  state: TypeUserCertificates,
  { query }: any
) => ({
  ...state,
  certificatesFilter: state.certificates
    .filter(
      (certificate) =>
        certificate.companyName.toUpperCase().includes(query) ||
        certificate.mostRecentInvested.toUpperCase().includes(query) ||
        (Array.isArray(certificate.campaigns) &&
          certificate.campaigns.join("").includes(query))
    )
    .sort(sortCertificatesBykey("companyName"))
});

const searchCertificatesPymeByFilter = (
  state: TypeUserCertificates,
  { query }: any
) => ({
  ...state,
  certificatesFilter: state.certificates
    .filter((certificate) =>
      certificate.campaignName.toUpperCase().includes(query)
    )
    .sort(sortCertificatesBykey("campaignName"))
});

const cleanCertificates = (state: TypeUserCertificates) => ({
  ...state,
  certificates: [],
  certificatesFilter: []
});

const setDownloadError = (
  state: TypeUserCertificates,
  { error }: { error: string }
) => ({
  ...state,
  downloadCertificateError: error
});

const onDownloadCertificatesByToken = (state: TypeUserCertificates) => ({
  ...state,
  downloadCertificateError: ""
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_AVAILABLES]: fetchAvailables,
  [Types.SET_AVAILABLES]: setAvailables,
  [Types.SET_CERTIFICATES]: setCertificates,
  [Types.SET_CERTIFICATES_REPORT]: setCertificatesReport,
  [Types.FETCH_CERTIFICATES]: fetchCertificates,
  [Types.SEARCH_CERTIFICATES]: searchCertificatesByFilter,
  [Types.CLEAN_CERTIFICATES]: cleanCertificates,
  [Types.SEARCH_CERTIFICATES_PYME]: searchCertificatesPymeByFilter,
  [Types.SET_DOWNLOAD_ERROR]: setDownloadError,
  [Types.ON_DOWNLOAD_CERTIFICATES_BY_TOKEN]: onDownloadCertificatesByToken
});
