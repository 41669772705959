import styled from "styled-components";

export const Container = styled.div`
  padding-top: 24px;
  padding-left: 74px;
  padding-right: 71px;

  ${({ theme }) => theme.smartphone} {
    padding-top: 29px;
    padding-left: 25px;
    padding-right: 25px;
  }

  > h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${({ theme }) => theme.riverBed};
    margin-bottom: 8px;
  }
  > p {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 21px;
    color: ${({ theme }) => theme.stormGray};
    ${({ theme }) => theme.smartphone} {
      width: 325px;
    }
  }

  ${({ theme }) => theme.iPhoneX} {
    padding-top: 45px;
    padding-bottom: 22px;
    & > div {
      margin-top: 52px;
    }
  }

  ${({ theme }) => theme.iPhone8Plus} {
    padding-top: 29px;
    padding-bottom: inherit;
    & > div {
      margin-top: 20px;
    }
  }
`;
