import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  Container,
  Paragraph,
  Divider,
  Button,
  CloseButton,
  Icon,
  IconTitleContainer,
  IconTitle
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";

import errorIcon from "assets/images/ErrorIcon.png";

const LoginErrorModal = ({ errorCodes, textOnButton }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(ModalActions.setConditionalModalState(false));
  };

  const handleCloseClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(ModalActions.setConditionalModalState(false));
  };

  return (
    <Container id="container-login-error" data-testid="container-login-error">
      <CloseButton id="button-close" onClick={handleCloseClick}>
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <IconTitleContainer>
        {errorCodes !== "033" ? (
          <IconTitle alt="previous" src={errorIcon} />
        ) : (
          <IconTitle alt="previous" src="/assets/images/check_round.png" />
        )}
      </IconTitleContainer>
      <Paragraph>{t(`errorCodes.${errorCodes}`)}</Paragraph>
      <Divider />
      {errorCodes !== "033" ? (
        <Button id="button-ok" onClick={handleClick}>
          {textOnButton !== "" ? t(textOnButton) : t("login.button")}
        </Button>
      ) : (
        <Button id="button-ok" onClick={handleClick}>
          {textOnButton !== "" ? t(textOnButton) : t("login.buttonModal033")}
        </Button>
      )}
    </Container>
  );
};

LoginErrorModal.propTypes = {
  errorCodes: PropTypes.string.isRequired,
  textOnButton: PropTypes.string.isRequired
};

export default LoginErrorModal;
