import app from "../../app";

const analyticsMiddleware = (action) => {
  const analytics = app.analytics ? app.analytics : null;
  if (analytics && action.type === "TRACK_EVENT") {
    analytics.set({
      page: window.location.pathname
    });
    analytics.pageview(window.location.pathname + window.location.search);
    analytics.event({
      ...action.payload
    });
  }
  return action;
};

export default analyticsMiddleware;
