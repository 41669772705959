import React, { useState, useEffect } from "react";
import Certificate from "components/Certificates/Certificates/Certificates";
import {
  Heading,
  Paragraph,
  Loader
} from "@bvcco/a2censo-component-lib";
import { useTranslation } from "react-i18next";
import { ContainerTitle } from "./Styles";
import UserCertificates, {
  Store,
  Certificates as CertificatesT,
  TypeCertificate
} from "redux/reducers/user-certificates.reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  onRequestSendMassiveCertificates,
  CertificatesTypes
} from "redux/epics/user-certificates";
import theme from "styles/LightTheme";
import MoldalActions from "redux/reducers/modal.reducer";
import StateSendMail from "assets/images/state-sent.png";

type ICertificate = {
  quantity: number;
  description: string;
  onClickEmail?: () => void;
  onClickDownload?: () => void;
  disabledDownload?: boolean;
} & CertificatesT;

type IProps = {
  onClickGoBack: () => void;
  yearList: Array<{ id: number; value: number }>;
  yearInitial: number;
};

const Report = ({ onClickGoBack, yearList, yearInitial }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      UserCertificates.fetchCertificates({
        year: yearSelected,
        type: TypeCertificate.ReportAnual
      })
    );
  }, []);

  const { certificatesFilter, loading } = useSelector(
    (store: Store) => store.userCertificates
  );

  const onSendEmailSingleCertificate = (certificateId: string) => {
    dispatch(
      MoldalActions.setConditionalModalState(true, "ModalLoadingCertificate", {
        clearBlur: true,
        css: {
          backgroundColor: theme.transparentMartinique
        },
        description: t("myCertificates.modalLoading.descriptionEmail")
      })
    );
    dispatch(UserCertificates.onSendEmailReport(certificateId, yearSelected));
  };
  const certificatesData = certificatesFilter.map(
    (certificate): ICertificate => ({
      ...certificate,
      quantity: certificate.campaignInvestors || 0,
      description: certificate.campaignName || "",
      companyName: certificate.companyName,
      onClickDownload: () => onGetCertificate(certificate.certificateId, certificate.campaignName),
      onClickEmail: () =>
        onSendEmailSingleCertificate(certificate.certificateId.toString())
    })
  );

  const onGetCertificate = (
    certificateId: string | number,
    campaignName: string
  ) => {
    dispatch(
      MoldalActions.setConditionalModalState(true, "ModalLoadingCertificate", {
        clearBlur: true,
        css: {
          backgroundColor: theme.transparentMartinique
        },
        description: t("myCertificates.modalLoading.descriptionDownload")
      })
    );
    dispatch(
      UserCertificates.onDownloadReportPyme(certificateId, campaignName)
    );
  };

  const onSearch = (query: string) => {
    dispatch(UserCertificates.searchCertificatesPyme(query.toUpperCase()));
  };

  const [yearSelected, setYear] = useState(yearInitial);

  const onSearchYear = (year: number) => {
    setYear(year);
    dispatch(
      UserCertificates.fetchCertificates({
        year: year,
        type: TypeCertificate.ReportAnual
      })
    );
  };

  const onSendEmail = () => {
    dispatch(
      MoldalActions.setConditionalModalState(true, "ModalLoadingCertificate", {
        clearBlur: true,
        css: {
          backgroundColor: theme.transparentMartinique
        },
        image: StateSendMail,
        description: t("myCertificates.modalLoading.descriptionEmail")
      })
    );
    dispatch(
      onRequestSendMassiveCertificates(
        CertificatesTypes.AnualInvestorsCertficate,
        yearSelected
      )
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Certificate
      certificates={certificatesData}
      onClickGoBack={onClickGoBack}
      onSearch={onSearch}
      yearList={yearList}
      onSearchYear={onSearchYear}
      yearInitial={yearSelected}
      isInvestor={false}
      onClickSendEmail={onSendEmail}
      onClickDownloadSummary={() => {}}
      onClickSendSummary={() => {}}
    >
      <ContainerTitle data-testid={"report-container"}>
        <Heading>{t("myCertificates.detail.report.title")}</Heading>
        <Paragraph>
          {t("myCertificates.detail.report.description.part1")}
          <strong>{t("myCertificates.detail.report.description.bold")}</strong>
        </Paragraph>
      </ContainerTitle>
    </Certificate>
  );
};

export default Report;
