import React, { useEffect, createRef } from "react";
import {
  Heading,
  Paragraph,
  Button
} from "@bvcco/a2censo-component-lib";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Container, Left, Right, Image, Over } from "./components";
import A2censoUserDataImage from "../../assets/images/a2censo-user-data.png";
import { UserDataForm, FormUserDataValues, CIIUList } from "./UserDataForm";
import ParametersActions from "redux/reducers/parameters.reducer";
import UpdateInfoSuccessModal from "components/UpdateUserData/UpdateInfoSuccessModal";
import paramNames from "lib/models/parameter.model";
import { Alert } from "components/shared";

const { HASHED_CODE_PARAMS } = paramNames;
const { _PARAM_003 }: any = HASHED_CODE_PARAMS();
const allowedCIIUs = ["both", "natural"];

type Props = {
  haveAttempts?: boolean;
  loading?: boolean;
  names: string;
  errorMessage?: string;
  onSave: (values: FormUserDataValues) => void;
  onClose: () => void;
  ciiu?: string;
  lastName: string;
  secondLastName: string;
  isErrorAPI?: boolean;
  isSuccess?: boolean;
};

export const UserDataWrapper = ({
  isSuccess,
  haveAttempts = true,
  loading,
  errorMessage,
  names = "",
  onSave,
  onClose,
  lastName = "",
  secondLastName,
  ciiu = "",
  isErrorAPI = false
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const alertRef = createRef<HTMLDivElement>();

  const isOverMessage = !haveAttempts;

  const ciiuList: CIIUList[] = useSelector(
    ({
      parameters: {
        listParameters: { _ciiu_code = [] }
      }
    }: any) => _ciiu_code
  ).filter((item: CIIUList) =>
    allowedCIIUs.some((personType) => personType === item.person_type)
  );

  useEffect(() => {
    dispatch(ParametersActions.fetchListParameters([_PARAM_003]));
  }, []);

  useEffect(() => {
    if (errorMessage) alertRef.current?.scrollIntoView();
  }, [errorMessage]);

  const name = names.split(" ");
  let otherNames = "";
  let firstName = "";
  if (name && name.length > 0) {
    firstName = name[0];
    name.shift();
    otherNames = name.join(" ");
  }

  return (
    <>
      {isSuccess ? (
        <UpdateInfoSuccessModal />
      ) : (
        <Container>
          <Left>
            <Heading hieranchy="secondary" color="white" fontWeight="800">
              {t("updatePNData.updateData")}
            </Heading>
            <Paragraph color="white">
              {t("updatePNData.updateDescription")}{" "}
              <strong>{t("updatePNData.updateDescription2")}</strong>
            </Paragraph>
            <Image src={A2censoUserDataImage} />
          </Left>
          <Right>
            {isErrorAPI && (
              <Over data-testid="over-fail-error-api">
                <div>
                  <div className="over-fail-error-api">
                    {t("updatePNData.failDescription")}{" "}
                    <strong>{t("updatePNData.failDescription2")}</strong>
                  </div>
                  <Button
                    rounded={true}
                    color="cornFlowerBlueBg"
                    onClick={onClose}
                    dataTestid="over-fail-error-api-button"
                  >
                    {t("getIt")}
                  </Button>
                </div>
              </Over>
            )}
            {isOverMessage && (
              <Over data-testid="over-fail-attemps">
                <div>
                  <div className="over-fail-attemps">
                    {t("updatePNData.failAttemps")}{" "}
                    <strong>{t("updatePNData.failAttemps2")}</strong>
                  </div>
                  <Button
                    rounded={true}
                    color="cornFlowerBlueBg"
                    onClick={onClose}
                    dataTestid="over-fail-attemps-button"
                  >
                    {t("getIt")}
                  </Button>
                </div>
              </Over>
            )}
            {errorMessage && (
              <div data-testid="alert-error" ref={alertRef}>
                <Alert type="error">{errorMessage}</Alert>
                <br />
              </div>
            )}
            <Alert type="info">{t("updatePNData.addInfo")}</Alert>
            <UserDataForm
              ciiuList={ciiuList}
              onClose={onClose}
              onSave={onSave}
              knowLink="https://linea.ccb.org.co/descripcionciiu/"
              loading={loading}
              initialValues={{
                firstName: firstName,
                secondName: otherNames,
                ciiu: ciiu || "",
                lastName,
                secondLastName
              }}
            />
          </Right>
        </Container>
      )}
    </>
  );
};
