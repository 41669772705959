import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import LoginActions from "../reducers/login.reducer";
import DashboardActions, {
  dashboardTypes
} from "../reducers/dashboard-campaigns.reducer";
import ModalActions from "../reducers/modal.reducer";
import { getErrorIndex } from "../../lib/utils";
import paramNames from "../../lib/models/parameter.model";

const { HASHED_CODE_PARAMS } = paramNames;
const { _PARAM_004 } = HASHED_CODE_PARAMS();

export const dashBoardCampaigns = (action$) =>
  action$.pipe(
    ofType(dashboardTypes.GET_DASHBOARD_CAMPAIGNS),
    flatMap((action) => {
      const search = action.search || "";
      return Observable.from(
        App.api.a2censo.requestCampaigns({
          url: { name: search }
        })
      ).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(DashboardActions.setDashboardCampaigns(response)),
            Observable.of(DashboardActions.getPymeCampaignsDashboard())
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      );
    })
  );

export const getCampaignPYME = (action$) =>
  action$.pipe(
    ofType(dashboardTypes.GET_PYME_CAMPAIGNS_DASHBOARD),
    flatMap(() => {
      const {
        user: {
          userInfo: {
            user: { id }
          }
        }
      } = App.redux.store.getState();

      return Observable.from(
        App.api.a2censo.getCampaignByUser({
          url: { userId: id }
        })
      ).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(DashboardActions.setDashboardCampaignsPyme(response))
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      );
    })
  );

export const videoDashboard = (action$) =>
  action$.pipe(
    ofType(dashboardTypes.GET_DASHBOARD_VIDEO),
    flatMap(() =>
      Observable.from(
        App.api.a2censo.getStaticParameters({
          url: { tables: `${_PARAM_004}` }
        })
      ).pipe(
        flatMap(({ _parameter: data }) => {
          const videoGuide = data.find(
            (param) => param.name === "dashboard_video_guide"
          );
          const videoTips = data.find(
            (param) => param.name === "dashboard_video_tips"
          );
          return Observable.concat(
            Observable.of(
              DashboardActions.setDashboardVideo({
                videoGuide: videoGuide.value,
                videoTips: videoTips.value
              })
            )
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.of(
              ModalActions.setConditionalModalState(true, "ErrorModal", {
                title: "errorModal.title",
                content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                linkRedirectsTo: "/",
                buttonText: "errorModal.success"
              })
            );
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      )
    )
  );

export default combineEpics(
  dashBoardCampaigns,
  videoDashboard,
  getCampaignPYME
);
