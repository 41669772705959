export enum BusinessLines {
  Debt = "debt",
  Shares = "shares",
  ConvertibleNote = "convertibleNote",
  Equity = "equity"
}

export const BusinessLinesIds: Record<number, BusinessLines> = {
  1: BusinessLines.Debt,
  2: BusinessLines.Shares,
  3: BusinessLines.ConvertibleNote
};
