/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import axios from "axios";
import axiosRetry, { isRetryableError } from "axios-retry";
import environment from "environment";
import { Deserializer } from "jsonapi-serializer";
import paramNames from "../models/parameter.model";
import { DELAY_RETRIES, PARAMETERS_RETRIES } from "config/network";

const { HASHED_CODE_PARAMS } = paramNames;

export default async (delayRetries = DELAY_RETRIES) => {
  axiosRetry(axios, {
    retries: PARAMETERS_RETRIES,
    retryDelay: (retryCount) =>
      retryCount * !isNaN(Number(delayRetries)) ? delayRetries : DELAY_RETRIES,
    retryCondition: (error) => isRetryableError(error)
  });

  const { _PARAM_004 } = HASHED_CODE_PARAMS();

  const headers = { apiKey: environment.apiKey };
  const url = `${environment.apiUrl}/parameters?tables=${_PARAM_004}`;

  const parametersResponse = await axios.get(url, {
    headers
  });

  const deserializedParameters = await new Deserializer({
    keyForAttribute: "snake_case",
    action: {
      valueForRelationship: (relationship) => ({
        id: relationship.id
      })
    }
  }).deserialize(parametersResponse.data);

  const parametersObj = deserializedParameters._parameter.reduce(
    (obj, item) => ({ ...obj, [item.name]: item }),
    {}
  );

  return Promise.resolve({
    ...parametersObj
  });
};
