import styled from "styled-components";

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.rhino.bgColor};
  image {
    margin: -9px -20px;
  }
`;

export const QuestionButton = styled.img`
  position: fixed;
  z-index: 99;
  cursor: pointer;
  width: 68px;
  height: 68px;
  margin: 0px 28px 0px 30px;
  :hover,
  :focus {
    opacity: 0.9;
  }
  ${({ position }) => position};
  ${({ theme }) => theme.desktop} {
    width: 60px;
    height: 60px;
  }
`;

export default Container;
