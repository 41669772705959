import React, { useRef, useEffect, useCallback, useState } from "react";
import "./InformationalSlider.scss";
import { useDispatch, useSelector } from "react-redux";
import User from "../../lib/models/user.model";
import { push } from "connected-react-router";
import SliderDebtImg from "assets/images/informational-slider/slider-debt.png";
import SliderDebtImgMovil from "assets/images/informational-slider/slider-debt-M.png";
import SliderShareImg from "assets/images/informational-slider/slider-share.png";
import SliderShareImgMovil from "assets/images/informational-slider/slider-share-M.png";
import SliderConvertibleNoteImg from "assets/images/informational-slider/slider-convertibleNote.png";
import SliderConvertibleNoteImgMovil from "assets/images/informational-slider/slider-convertibleNote-M.png";
import homeSelectionsActions from "../../redux/reducers/home-selections.reducer";
import illustrationWhatConvertibleNote from "assets/images/informational-slider/illustration-what_convertibleNote.png";
import illustrationWhatDebt from "assets/images/informational-slider/illustration-what_debt.png";
import illustrationWhatShare from "assets/images/informational-slider/illustration-what_share.png";
import illustrationHowConvertibleNote from "assets/images/informational-slider/illustration-how_convertibleNote.png";
import illustrationHowDebt from "assets/images/informational-slider/illustration-how_debt.png";
import illustrationHowShare from "assets/images/informational-slider/illustration-how_share.png";
import illustrationRiskConvertibleNote from "assets/images/informational-slider/illustration-risk_convertibleNote.png";
import illustrationRiskDebt from "assets/images/informational-slider/illustration-risk_debt.png";
import illustrationRiskShare from "assets/images/informational-slider/illustration-risk_share.png";
import { useTranslation } from "react-i18next";

export type IProps = {
  speed?: any;
  interval?: any;
  campaingTypes: string;
};

const InformationalSlider = ({
  speed = "500",
  interval = "50000",
  campaingTypes
}: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [point, setPoint] = useState(0);
  const slideshow: any = useRef(null);
  const intervalSlideshow: any = useRef(null);
  const appStore: any = useSelector((store) => store);
  const { innerWidth: width, innerHeight: height } = window;

  const dataSlider = () => {
    switch (campaingTypes) {
      case "Debt":
        return {
          imgSlider: width >= 1024 ? SliderDebtImg : SliderDebtImgMovil,
          idTab: "beInvestment",
          url: "/?crowdfunding-lending-en-empresas",
          classButton: "debtColor",
          what: {
            title: "informationalSlider.debt.what.title",
            description: "informationalSlider.debt.what.description",
            illustration: illustrationWhatDebt
          },
          how: {
            title: "informationalSlider.debt.how.title",
            description: "informationalSlider.debt.how.description",
            illustration: illustrationHowDebt
          },
          risk: {
            title: "informationalSlider.debt.risk.title",
            description: "informationalSlider.debt.risk.description",
            illustration: illustrationRiskDebt
          }
        };
      case "Shares":
        return {
          imgSlider: width >= 1024 ? SliderShareImg : SliderShareImgMovil,
          idTab: "equity",
          url: "/?seccion=invierte/crowdfunding-compra-acciones-y-notas-convertibles-de-empresas",
          classButton: "shareColor",
          what: {
            title: "informationalSlider.share.what.title",
            description: "informationalSlider.share.what.description",
            illustration: illustrationWhatShare
          },
          how: {
            title: "informationalSlider.share.how.title",
            description: "informationalSlider.share.how.description",
            illustration: illustrationHowShare
          },
          risk: {
            title: "informationalSlider.share.risk.title",
            description: "informationalSlider.share.risk.description",
            illustration: illustrationRiskShare
          }
        };
      case "ConvertibleNote":
        return {
          imgSlider:
            width >= 1024
              ? SliderConvertibleNoteImg
              : SliderConvertibleNoteImgMovil,
          idTab: "equity",
          url: "/?seccion=invierte/crowdfunding-compra-acciones-y-notas-convertibles-de-empresas",
          classButton: "noteColor",
          what: {
            title: "informationalSlider.convertibleNote.what.title",
            description: "informationalSlider.convertibleNote.what.description",
            description2: "informationalSlider.convertibleNote.what.description2",
            illustration: illustrationWhatConvertibleNote
          },
          how: {
            title: "informationalSlider.convertibleNote.how.title",
            description: "informationalSlider.convertibleNote.how.description",
            blockI: {
              title: "informationalSlider.convertibleNote.how.blockI.title",
              description:
                "informationalSlider.convertibleNote.how.blockI.description",
              numberedList: [
                "informationalSlider.convertibleNote.how.blockI.numberedListI",
                "informationalSlider.convertibleNote.how.blockI.numberedListII"
              ]
            },
            blockII: {
              title: "informationalSlider.convertibleNote.how.blockII.title",
              paragraph:
                "informationalSlider.convertibleNote.how.blockII.paragraph"
            },
            blockIII: {
              title: "informationalSlider.convertibleNote.how.blockIII.title",
              paragraph:
                "informationalSlider.convertibleNote.how.blockIII.paragraph",
              description:
                "informationalSlider.convertibleNote.how.blockIII.description"
            },
            moreInformation:
              "informationalSlider.convertibleNote.how.moreInformation",
            illustration: illustrationHowConvertibleNote
          },
          risk: {
            title: "informationalSlider.share.risk.title",
            description: "informationalSlider.convertibleNote.risk.description",
            illustration: illustrationRiskConvertibleNote
          }
        };

      default:
        return {};
    }
  };

  const next = useCallback(() => {
    if (
      slideshow.current !== null &&
      slideshow.current !== null &&
      slideshow.current.children.length > 0
    ) {
      const firstElement = slideshow.current.children[0];
      slideshow.current.style.transition = `${speed}ms ease-out all`;
      const sizeSlide = slideshow.current.children[0].offsetWidth;
      slideshow.current.style.transform = `translateX(-${sizeSlide}px)`;

      const transition = () => {
        slideshow.current.style.transition = "none";
        slideshow.current.style.transform = "translateX(0)";
        slideshow.current.appendChild(firstElement);
        slideshow.current.removeEventListener("transitionend", transition);
      };
      slideshow.current.addEventListener("transitionend", transition);
      let indexElement = parseInt(firstElement.id.replace("slider_", ""));
      setPoint(
        indexElement == slideshow.current.children.length - 1
          ? 0
          : indexElement + 1
      );
    }
  }, [speed]);

  const previous = () => {
    if (slideshow.current.children.length > 0) {
      const index = slideshow.current.children.length - 1;
      const lastElement = slideshow.current.children[index];
      slideshow.current.insertBefore(lastElement, slideshow.current.firstChild);
      slideshow.current.style.transition = "none";
      const sizeSlide = slideshow.current.children[0].offsetWidth;
      slideshow.current.style.transform = `translateX(-${sizeSlide}px)`;

      setTimeout(() => {
        slideshow.current.style.transition = `${speed}ms ease-out all`;
        slideshow.current.style.transform = "translateX(0)";
      }, 30);
      let indexElement = parseInt(lastElement.id.replace("slider_", ""));
      setPoint(indexElement);
    }
  };

  const getPoints = (initClass: any) => {
    let margin1 = 0;
    let margin2 = 24;
    let margin3 = 48;
    let margin4 = 72;
    let sizeBox = 60;
    let lastPoint = campaingTypes === "ConvertibleNote" ? 3 : 2;
    return (
      <div className={`${initClass}__points`}>
        <div className="box" style={{ width: `${sizeBox}px` }}>
          {
            <div
              key={0}
              id={`point_${0}`}
              className={`${initClass}__points__point ${
                0 === point ? "active" : "inactive"
              }`}
              style={{ left: `${margin1}px` }}
            ></div>
          }
          {
            <div
              key={1}
              id={`point_${1}`}
              className={`${initClass}__points__point ${
                1 === point ? "active" : "inactive"
              }`}
              style={{ left: `${margin2}px` }}
            ></div>
          }
          {campaingTypes === "ConvertibleNote" ? (
            <div
              key={2}
              id={`point_${2}`}
              className={`${initClass}__points__point ${
                2 === point ? "active" : "inactive"
              }`}
              style={{ left: `${margin3}px` }}
            ></div>
          ) : null}
          {
            <div
              key={campaingTypes === "ConvertibleNote" ? 3 : 2}
              id={`point_${campaingTypes === "ConvertibleNote" ? 3 : 2}`}
              className={`${initClass}__points__point ${
                lastPoint === point ? "active" : "inactive"
              }`}
              style={{
                left: `${
                  campaingTypes === "ConvertibleNote" ? margin4 : margin3
                }px`
              }}
            ></div>
          }
        </div>
      </div>
    );
  };

  const getControls = (initClass: any) => {
    return (
      <div className={`${initClass}__controls`}>
        <div className={`${initClass}__controls__button left`}>
          <div
            className={`${initClass}__controls__button__icon left_icon`}
            onClick={previous}
            data-testid={"previous"}
          ></div>
        </div>
        <div className={`${initClass}__controls__button right`}>
          <div
            className={`${initClass}__controls__button__icon right_icon`}
            onClick={next}
            data-testid={"next"}
          ></div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    intervalSlideshow.current = setInterval(() => {
      next();
    }, interval);

    slideshow.current?.addEventListener("mouseenter", () => {
      clearInterval(intervalSlideshow.current);
    });

    slideshow.current?.addEventListener("mouseleave", () => {
      intervalSlideshow.current = setInterval(() => {
        next();
      }, interval);
    });
  }, [interval, next]);

  const onClickAction = () => {
    dispatch(homeSelectionsActions.setInvestSelection(dataSlider()?.idTab));
    dispatch(homeSelectionsActions.setInvestTabUrl(dataSlider()?.url));
    dispatch(push(`${dataSlider()?.url}`));
  };

  return (
    <>
      <div className="slider">
        <div className="slider__slides_how" ref={slideshow}>
          <div className="slider__slides_how__slide" id={`slider_${0}`} key={0}>
            <div className="slider__slides_how__slide__img">
              <img
                src={dataSlider()?.imgSlider}
                className="responsive"
                width={`${width}px`}
                height={"691px"}
              />
              <div className="slider-content">
                <img
                  src={dataSlider()?.what?.illustration}
                  className={`illustration-what ${
                    campaingTypes == "Shares"
                      ? "share_illustration-what"
                      : campaingTypes == "ConvertibleNote"
                      ? "noteConvertible_illustration-what"
                      : campaingTypes == "Debt"
                      ? "debt_illustration-what"
                      : null
                  }`}
                />
                <div
                  className={`slider-content-text ${
                    campaingTypes == "Shares"
                      ? "share_slider1"
                      : campaingTypes == "ConvertibleNote"
                      ? "noteConvertible_slider1"
                      : campaingTypes == "Debt"
                      ? "debt_slider1"
                      : null
                  }`}
                >
                  <h2>{t(`${dataSlider()?.what?.title}`)}</h2>
                  {campaingTypes == "ConvertibleNote" ? (
                    <>
                      {t(`${dataSlider()?.what?.description}`)
                        .split("%br")
                        .map((item, index) => (
                          <p id={`itemWhat_${index}`} key={item}>
                            {item}
                          </p>
                        ))}
                      <p id="itemNote">{t(`${dataSlider()?.what?.description2}`)}</p>
                    </>
                  ) : (
                    <>
                      {t(`${dataSlider()?.what?.description}`)
                        .split("%br")
                        .map((item, index) => (
                          <p id={`itemWhat_${index}`} key={item}>
                            {item}
                          </p>
                        ))}                      
                    </>
                  )}
                  <button
                    className={`${dataSlider()?.classButton}`}
                    data-testid={"btnI"}
                    onClick={onClickAction}
                  >
                    {t("informationalSlider.moreInformation")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="slider__slides_how__slide" id={`slider_${1}`} key={1}>
            <div className="slider__slides_how__slide__img">
              <img
                src={dataSlider()?.imgSlider}
                className="responsive"
                width={"1440px"}
                height={"691px"}
              />
              <div className="slider-content">
                <img
                  src={dataSlider()?.how?.illustration}
                  className={`illustration-how ${
                    campaingTypes == "Shares"
                      ? "share_illustration-how"
                      : campaingTypes == "ConvertibleNote"
                      ? "noteConvertible_illustration-how"
                      : campaingTypes == "Debt"
                      ? "debt_illustration-how"
                      : null
                  }`}
                />
                <div
                  id="idHow"
                  className={`slider-content-text ${
                    campaingTypes == "Shares"
                      ? "share_slider2"
                      : campaingTypes == "ConvertibleNote"
                      ? "noteConvertible_slider2"
                      : campaingTypes == "Debt"
                      ? "debt_slider2"
                      : null
                  }`}
                >
                  <h2 className="title_how_note">
                    {t(`${dataSlider()?.how?.title}`)}
                  </h2>
                  {campaingTypes === "ConvertibleNote" ? (
                    <>
                      <p className="description_how_note">
                        {t(`${dataSlider()?.how?.description}`)}
                      </p>
                      <div className="how_bullet-item_note">
                        <ul>
                          <li>{t(`${dataSlider()?.how?.blockI?.title}`)}</li>
                        </ul>
                        <ol>
                          {dataSlider()?.how?.blockI?.numberedList?.map(
                            (item, index) => (
                              <li id={`item_${index}`} key={item}>
                                {t(item)}
                              </li>
                            )
                          )}
                        </ol>
                      </div>
                    </>
                  ) : (
                    <p>{t(`${dataSlider()?.how?.description}`)}</p>
                  )}
                  {campaingTypes !== "ConvertibleNote" ? (
                    <button
                      className={`${dataSlider()?.classButton}`}
                      onClick={onClickAction}
                    >
                      {t("informationalSlider.moreInformation")}
                    </button>
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `${t(`${dataSlider()?.how?.moreInformation}`)}`
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {campaingTypes === "ConvertibleNote" ? (
            <div
              className="slider__slides_how__slide"
              id={`slider_${2}`}
              key={2}
            >
              <div className="slider__slides_how__slide__img">
                <img
                  src={dataSlider()?.imgSlider}
                  className="responsive"
                  width={"1440px"}
                  height={"691px"}
                />
                <div className="slider-content">
                  <img
                    src={dataSlider()?.how?.illustration}
                    className="illustration-how noteConvertible_illustration-how"
                  />
                  <div
                    id="idHow"
                    className="slider-content-text  noteConvertible_slider2"
                  >
                    <h2 className="title_how_note">
                      {t(`${dataSlider()?.how?.title}`)}
                    </h2>
                    <p className="description_how_note">
                      {t(`${dataSlider()?.how?.description}`)}
                    </p>
                    <div className="how_bullet-item_note">
                      <ul>
                        <li>
                          {t(`${dataSlider()?.how?.blockII?.title}`)}
                          <p id="block_1">
                            {t(`${dataSlider()?.how?.blockII?.paragraph}`)}
                          </p>
                        </li>
                        <li>
                          {t(`${dataSlider()?.how?.blockIII?.title}`)}
                          <p id="block_2">
                            {t(`${dataSlider()?.how?.blockIII?.paragraph}`)}
                            <br />
                            {t(`${dataSlider()?.how?.blockIII?.description}`)}
                          </p>
                        </li>
                      </ul>
                    </div>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `${t(`${dataSlider()?.how?.moreInformation}`)}`
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div
            className="slider__slides_how__slide"
            id={`slider_${campaingTypes === "ConvertibleNote" ? 3 : 2}`}
            key={campaingTypes === "ConvertibleNote" ? 3 : 2}
          >
            <div className="slider__slides_how__slide__img">
              <img
                src={dataSlider()?.imgSlider}
                className="responsive"
                width={"1440px"}
                height={"691px"}
              />
              <div
                id={`idSliderContent${campaingTypes != "Debt" ? "Risk" : ""}`}
                className="slider-content"
              >
                <img
                  src={dataSlider()?.risk?.illustration}
                  className={`illustration-risk ${
                    campaingTypes == "Shares"
                      ? "share_illustration-risk"
                      : campaingTypes == "ConvertibleNote"
                      ? "noteConvertible_illustration-risk"
                      : campaingTypes == "Debt"
                      ? "debt_illustration-risk"
                      : null
                  }`}
                />
                <div
                  id="idRisk"
                  className={`slider-content-text ${
                    campaingTypes == "Shares"
                      ? "share_slider3"
                      : campaingTypes == "ConvertibleNote"
                      ? "noteConvertible_slider3"
                      : campaingTypes == "Debt"
                      ? "debt_slider3"
                      : null
                  }`}
                >
                  <h2>{t(`${dataSlider()?.risk?.title}`)}</h2>
                  {campaingTypes !== "Debt" ? (
                    <>
                      {t(`${dataSlider()?.risk?.description}`)
                        .split("%br")
                        .map((item, index) => (
                          <p id={`itemRisk_${index}`} key={item}>
                            {item}
                          </p>
                        ))}
                    </>
                  ) : (
                    <p>{t(`${dataSlider()?.risk?.description}`)}</p>
                  )}
                  <button
                    className={`${dataSlider()?.classButton}`}
                    onClick={onClickAction}
                  >
                    {t("informationalSlider.moreInformation")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {getControls("slider")}
        {getPoints("slider")}
      </div>
    </>
  );
};

export default InformationalSlider;
