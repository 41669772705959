import React from "react";
import PropTypes from "prop-types";
import {
  OptionCard,
  TitleCard,
  DescriptionCard,
  TitleContainer,
  ContainerCheck
} from "./Styles";
import Checkbox from "../Checkbox/Checkbox";

const RadioCard = ({ title, description, isSelected, onSelected }) => (
  <OptionCard onClick={onSelected}>
    <TitleContainer>
      <TitleCard>{title}</TitleCard>
      <ContainerCheck>
        <Checkbox check={isSelected} />
      </ContainerCheck>
    </TitleContainer>

    <DescriptionCard>{description}</DescriptionCard>
  </OptionCard>
);

RadioCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onSelected: PropTypes.func.isRequired
};

RadioCard.defaultProps = {
  isSelected: false
};

export default RadioCard;
