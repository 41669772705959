import React from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import PropTypes from "prop-types";
import DatePicker from "../DatePicker/DatePickerInput";
import Transaction from "../../lib/models/transaction.model";
import {
  Container,
  Input,
  FormRow,
  ButtonContainer,
  Button,
  Select,
  Icon,
  RadioButton,
  Radio,
  RadioText,
  SelectContent,
  InputContainer,
  IconsContainer,
  IconText,
  CleanContainer,
  CleanLink
} from "./Styles";
import { isAfterToday, isBefore, isAfter } from "../../lib/utils/date";

const spyGlass = "/assets/images/spy-glass.png";
import icon from "assets/images/setting_icon.png";
import iconArrow from "assets/images/arrow_down_icon.png";

const MovementsFilters = ({ searchParams }) => {
  const { t } = useTranslation();
  const initialData = {
    filterBy: 0,
    showFiltersBy: false,
    keyWord: "",
    fromDate: "",
    toDate: ""
  };

  const getIsoDate = (date) => date.toISOString().split("T")[0];

  const validateFromDate = (value, toDate) =>
    !isAfterToday(value) && !isAfter(value, toDate);

  const validateToDate = (value, fromDate) =>
    !isAfterToday(value) && !isBefore(value, fromDate);

  return (
    <Formik initialValues={initialData}>
      {({ values, handleChange, setFieldValue, resetForm }) => (
        <Container>
          <FormRow>
            <Select>
              <IconsContainer
                data-testid="show_filters_by"
                onClick={() =>
                  setFieldValue("showFiltersBy", !values.showFiltersBy)
                }
              >
                <Icon src={icon} />
                <IconText>Filtrar por</IconText>
                <Icon width={20} src={iconArrow} />
              </IconsContainer>
              <SelectContent show={values.showFiltersBy}>
                <RadioButton
                  data-testid="radio_1"
                  onClick={() =>
                    setFieldValue("filterBy", Transaction.type.investment)
                  }
                >
                  <Radio
                    check={values.filterBy === Transaction.type.investment}
                  />
                  <RadioText>{t("myMovements.byCampaign")}</RadioText>
                </RadioButton>
                <RadioButton
                  data-testid="radio_2"
                  onClick={() =>
                    setFieldValue("filterBy", Transaction.type.retirement)
                  }
                >
                  <Radio
                    check={values.filterBy === Transaction.type.retirement}
                  />
                  <RadioText>{t("myMovements.byRetirement")}</RadioText>
                </RadioButton>
                <RadioButton
                  data-testid="radio_3"
                  onClick={() =>
                    setFieldValue("filterBy", Transaction.type.recharge)
                  }
                >
                  <Radio
                    check={values.filterBy === Transaction.type.recharge}
                  />
                  <RadioText>{t("myMovements.byRecharge")}</RadioText>
                </RadioButton>
                <RadioButton
                  data-testid="radio_4"
                  onClick={() =>
                    setFieldValue("filterBy", Transaction.type.capital)
                  }
                >
                  <Radio check={values.filterBy === Transaction.type.capital} />
                  <RadioText>{t("myMovements.byPayCapital")}</RadioText>
                </RadioButton>
                <RadioButton
                  data-testid="radio_5"
                  onClick={() =>
                    setFieldValue("filterBy", Transaction.type.earnings)
                  }
                >
                  <Radio
                    check={values.filterBy === Transaction.type.earnings}
                  />
                  <RadioText>{t("myMovements.byPayPerformance")}</RadioText>
                </RadioButton>
                <RadioButton
                  data-testid="radio_6"
                  onClick={() =>
                    setFieldValue("filterBy", Transaction.type.cancellation)
                  }
                >
                  <Radio
                    check={values.filterBy === Transaction.type.cancellation}
                  />
                  <RadioText>{t("myMovements.byCancellation")}</RadioText>
                </RadioButton>
                <RadioButton
                  data-testid="radio_7"
                  onClick={() =>
                    setFieldValue("filterBy", Transaction.type.cashback)
                  }
                >
                  <Radio
                    check={values.filterBy === Transaction.type.cashback}
                  />
                  <RadioText>{t("myMovements.byCashback")}</RadioText>
                </RadioButton>
                <RadioButton
                  data-testid="radio_8"
                  onClick={() =>
                    setFieldValue("filterBy", Transaction.type.increase)
                  }
                >
                  <Radio
                    check={values.filterBy === Transaction.type.increase}
                  />
                  <RadioText>{t("myMovements.byIncrease")}</RadioText>
                </RadioButton>
                <RadioButton
                  data-testid="radio_9"
                  onClick={() =>
                    setFieldValue("filterBy", Transaction.type.retention)
                  }
                >
                  <Radio
                    check={values.filterBy === Transaction.type.retention}
                  />
                  <RadioText>{t("myMovements.byRetention")}</RadioText>
                </RadioButton>
              </SelectContent>
            </Select>
            <Input
              data-testid="key_word"
              name="keyWord"
              value={values.keyWord}
              onChange={handleChange}
              placeholder={t("myMovements.keyWord")}
            />
          </FormRow>
          <FormRow>
            <InputContainer>
              <DatePicker
                data-testid="from_date"
                id="movements-date-picker-from"
                value={values.fromDate}
                onChange={(dateSelected) => {
                  const isoDate = getIsoDate(dateSelected);
                  const isValidFromDate = validateFromDate(
                    isoDate,
                    values.toDate
                  );

                  if (!isValidFromDate) {
                    return false;
                  }
                  setFieldValue("fromDate", isoDate);
                }}
                placeholder={t("myMovements.fromDate")}
                setTouchedIcon={() => { }}
                withPortal={true}
              />
            </InputContainer>
            <InputContainer>
              <DatePicker
                data-testid="to_date"
                id="movements-date-picker-to"
                value={values.toDate}
                onChange={(dateSelected) => {
                  const isoDate = getIsoDate(dateSelected);
                  const isValidToDate = validateToDate(
                    isoDate,
                    values.fromDate
                  );

                  if (!isValidToDate) {
                    return false;
                  }
                  setFieldValue("toDate", isoDate);
                }}
                placeholder={t("myMovements.toDate")}
                setTouchedIcon={() => { }}
                withPortal={true}
              />
            </InputContainer>
          </FormRow>
          <ButtonContainer>
            <Button id="search-button" onClick={() => searchParams(values)}>
              {t("myMovements.search")}
              <Icon src={spyGlass} width="35" customPadding="0 0 0 10px" />
            </Button>
          </ButtonContainer>
          <CleanContainer>
            <CleanLink id="clean-link" onClick={() => resetForm()}>
              {t("myMovements.clean")}
            </CleanLink>
          </CleanContainer>
        </Container>
      )}
    </Formik>
  );
};

MovementsFilters.propTypes = {
  searchParams: PropTypes.func.isRequired
};

export default MovementsFilters;
