import React, { useEffect, useState } from "react";
import {
  Heading,
  Dropdown,
  Label,
  Checkbox
} from "@bvcco/a2censo-component-lib";
import { useTranslation } from "react-i18next";
import {
  CompanyTaxFormContainer,
  CompanyTaxFormLabelSpace,
  CompanyTaxFormColumn,
  CompanyTaxFormRow,
  StyledParagraph,
  Description,
  ContainerSubtitle,
  Line,
  StyledSubLabel,
  ContentCheckLabel
} from "./Styles";

type ICity = {
  id: number | string;
  value: string;
};

type CompanyTaxFormProps = {
  listCityConsignment: ICity[];
  listCityRetention: ICity[];
  formik: {
    values: {
      cityConsignment: number | string;
      cityRetention: number | string;
    };
    setFieldValue: Function;
  };
  showHead?: boolean;
};

const CompanyTaxForm = ({
  listCityConsignment,
  listCityRetention,
  formik,
  showHead = true
}: CompanyTaxFormProps & React.HTMLProps<HTMLDivElement>) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const { values, setFieldValue } = formik;

  useEffect(() => {
    const { cityConsignment, cityRetention } = values;
    if (
      cityConsignment &&
      cityRetention &&
      values.cityConsignment === values.cityRetention
    ) {
      setIsChecked(true);
    }
  }, [values]);

  const handleChange = () => {
    setFieldValue("cityConsignment", values.cityRetention);
    setIsChecked(!isChecked);
  };

  return (
    <CompanyTaxFormContainer>
      {showHead && (
        <>
          <CompanyTaxFormLabelSpace />
          <ContainerSubtitle data-testid="heading-company-tax-form">
            <Heading fontWeight="bold" color="acentuar" hieranchy="tertiary">
              {t("RequestCampaignStepSix.companyTax")}
            </Heading>
            <Line />
          </ContainerSubtitle>
          <Description>
            <StyledParagraph>
              {t("RequestCampaignStepSix.companyTaxDescription")}
            </StyledParagraph>
          </Description>
        </>
      )}

      <CompanyTaxFormRow>
        <CompanyTaxFormColumn>
          <Label>{t("RequestCampaignStepSix.companyTaxDropOne")}</Label>
          <StyledSubLabel>
            {t("RequestCampaignStepSix.companyTaxCityRetention")}
          </StyledSubLabel>
          <CompanyTaxFormLabelSpace />
          <Dropdown
            id="cityRetention"
            name="cityRetention"
            dataTestId="cityRetention"
            options={listCityRetention}
            onChange={(e: { id: number }) => {
              setFieldValue("cityRetention", e.id);
              if (isChecked) {
                setFieldValue("cityConsignment", e.id);
              }
            }}
            valueSelected={values.cityRetention}
            className="city-retention-list"
          />
        </CompanyTaxFormColumn>
        <CompanyTaxFormColumn
          style={{
            opacity: isChecked ? "0.83" : "1",
            pointerEvents: isChecked ? "none" : "auto"
          }}
        >
          <Label>{t("RequestCampaignStepSix.companyTaxDropTwo")}</Label>
          <StyledSubLabel>
            {t("RequestCampaignStepSix.companyTaxCityConsignment")}
          </StyledSubLabel>
          <CompanyTaxFormLabelSpace />
          <Dropdown
            id="cityConsignment"
            name="cityConsignment"
            dataTestId="cityConsignment"
            options={listCityConsignment}
            onChange={(e: { id: number }) =>
              setFieldValue("cityConsignment", e.id)
            }
            valueSelected={
              isChecked ? values.cityRetention : values.cityConsignment
            }
            className="city-consignment-list"
          />
        </CompanyTaxFormColumn>
      </CompanyTaxFormRow>
      <ContentCheckLabel data-testid="content-check-company-tax">
        <Checkbox
          label={t("RequestCampaignStepSix.checkCompanyTax")}
          data-testid="check-company-tax"
          dataTestId="check-company-tax"
          id="check-company-tax"
          checked={isChecked}
          onChange={handleChange}
        />
      </ContentCheckLabel>
    </CompanyTaxFormContainer>
  );
};

export default CompanyTaxForm;
