import { catchError, flatMap, map } from "rxjs/operators";
import { push } from "connected-react-router";
import { ofType, combineEpics } from "redux-observable";
import { Observable } from "rxjs-compat";
import { forkJoin } from "rxjs";
import App from "../../lib/app";
import {
  InvestmentCancellationSerializer,
  InvestmentCancellationSerializerOTP
} from "../../lib/serializers/investmentCancellation.serializer";
import { getTransactionSerializer } from "../../lib/serializers/invest.serializer";
import { moneyWithdrawalSerializer } from "../../lib/serializers/moneyWithdrawal.serializer";
import InvestmentCancellationActions, {
  InvestmentCancellationTypes
} from "../reducers/investment-cancellation.reducer";
import ModalActions from "../reducers/modal.reducer";
import LoginActions from "../reducers/login.reducer";
import AnalyticsActions from "../reducers/analytics.reducer";
import AnalyticsModel from "../../lib/models/analytics.model";
import { getErrorIndex } from "../../lib/utils";
import paramNames from "../../lib/models/parameter.model";
import { InvestmentConnect } from "services/investment";

const { HASHED_CODE_PARAMS } = paramNames;
const { _PARAM_011, _PARAM_012 } = HASHED_CODE_PARAMS();

export const getTransactionInfo = (action$) =>
  action$.pipe(
    ofType(InvestmentCancellationTypes.GET_TRANSACTION_INFO),
    flatMap((action) =>
      Observable.from(
        new InvestmentConnect().getTransaction(
          getTransactionSerializer({
            transaction: action.payload.transactionId,
            person_type: action.payload.type
          })
        )
      ).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(
              push(
                `/confirm-return-process/${action.payload.transactionId}/${action.payload.type}`,
                response
              )
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              ),
              Observable.of(
                InvestmentCancellationActions.investmentCancellationDataFail(e)
              )
            );
          }
          return Observable.concat(
            Observable.of(ModalActions.setConditionalModalState(false)),
            Observable.of(
              InvestmentCancellationActions.investmentCancellationDataFail(e)
            ),
            Observable.of(push("/oops"))
          );
        })
      )
    )
  );

export const createCancellation = (action$) =>
  action$.pipe(
    ofType(InvestmentCancellationTypes.CREATE_CANCELLATION),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.cancelInvestment({
          body: InvestmentCancellationSerializer().serialize(action.payload)
        })
      ).pipe(
        flatMap(() =>
          Observable.of(
            ModalActions.setConditionalModalState(true, "CancellationSuccess")
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }

          return Observable.concat(
            Observable.of(ModalActions.setConditionalModalState(false)),
            Observable.of(
              InvestmentCancellationActions.investmentCancellationDataFail(e)
            ),
            e.errors && e.errors[0]
              ? Observable.of(
                  ModalActions.setConditionalModalState(true, "ErrorModal", {
                    title: "errorModal.title",
                    content: `errorCodes.${e.errors[0].code}`,
                    linkRedirectsTo: "/my-balance",
                    buttonText: "errorModal.success"
                  })
                )
              : Observable.of(push("/oops"))
          );
        })
      )
    )
  );

export const createMoneyWithdrawalTransaction = (action$) =>
  action$.pipe(
    ofType(InvestmentCancellationTypes.CREATE_WITHDRAWAL_TRANSACTION),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.moneyWithdrawal({
          body: moneyWithdrawalSerializer().serialize(action.payload)
        })
      ).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(
              InvestmentCancellationActions.confirmMoneyWithdrawal({
                ...action.payload,
                transactionId: response.transaction_id
              })
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.concat(
              Observable.of(LoginActions.loginReset()),
              Observable.of(
                InvestmentCancellationActions.investmentCancellationDataFail(e)
              )
            );
          }

          return Observable.concat(
            Observable.of(ModalActions.setConditionalModalState(false)),
            Observable.of(
              InvestmentCancellationActions.investmentCancellationDataFail(e)
            ),
            Observable.of(push("/oops"))
          );
        })
      )
    )
  );

export const confirmMoneyWithdrawalRequest = (action$) =>
  action$.pipe(
    ofType(InvestmentCancellationTypes.CONFIRM_MONEY_WITHDRAWAL),
    flatMap((action) =>
      Observable.from(
        new InvestmentConnect().getTransaction(
          getTransactionSerializer({
            transaction: action.payload.transactionId,
            person_type: action.payload.person_type
          })
        )
      ).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(
              push(
                `/money-withdrawal-confirmation/${action.payload.transactionId}`,
                [...response.data, action.payload]
              )
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      )
    )
  );
export const otpRequest = (action$) =>
  action$.pipe(
    ofType(InvestmentCancellationTypes.OTP_REQUEST),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.requestCancellationOtpCode({
          url: { userId: action.payload.user_id }
        })
      ).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(ModalActions.setConditionalModalState(false)),
            Observable.of(
              push("/identity-validation-otp", {
                investment: action.payload,
                countryCode: response.country_code,
                phoneNumber: response.phone_number,
                dateGenerationToken: new Date(),
                timerLimitMinutes: response.otp_timer_limit_minutes
              })
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.concat(
              Observable.of(ModalActions.setConditionalModalState(false)),
              Observable.of(LoginActions.loginReset()),
              Observable.of(push("/"))
            );
          }
          return Observable.concat(
            Observable.of(InvestmentCancellationActions.otpCodeError(e)),
            Observable.of(
              push("/identity-validation-otp", {
                countryCode: "",
                phoneNumber: "",
                dateGenerationToken: ""
              })
            )
          );
        })
      )
    )
  );

export const otpRequestRedirect = (action$) =>
  action$.pipe(
    ofType(InvestmentCancellationTypes.OTP_REQUEST_WITH_REDIRECT),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.requestCancellationOtpCode({
          url: { userId: action.userId }
        })
      ).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(ModalActions.setConditionalModalState(false)),
            Observable.of(
              push("/identity-validation-otp", {
                countryCode: response.country_code,
                phoneNumber: response.phone_number,
                dateGenerationToken: new Date(),
                timerLimitMinutes: response.otp_timer_limit_minutes
              })
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.concat(
              Observable.of(ModalActions.setConditionalModalState(false)),
              Observable.of(LoginActions.loginReset()),
              Observable.of(push("/"))
            );
          }
          return Observable.concat(
            Observable.of(InvestmentCancellationActions.otpCodeError(e)),
            Observable.of(
              push("/identity-validation-otp", {
                countryCode: "",
                phoneNumber: "",
                dateGenerationToken: ""
              })
            )
          );
        })
      )
    )
  );

export const identityValidationOtp = (action$) =>
  action$.pipe(
    ofType(InvestmentCancellationTypes.IDENTITY_VALIDATION_OTP),
    flatMap((action) =>
      forkJoin([
        App.api.a2censo.validateCancellationOtp({
          body: InvestmentCancellationSerializerOTP().serialize({
            otp_code: action.payload.code
          }),
          url: { userId: action.payload.userId }
        }),
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: { tables: [_PARAM_011, _PARAM_012].join(",") }
          })
        )
      ]).pipe(
        map((response) => {
          AnalyticsActions.trackEvent(AnalyticsModel.identityValidation);
          return push("/money-withdrawal", {
            isValidated: true,
            response
          });
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.of(InvestmentCancellationActions.otpCodeError(e));
        })
      )
    )
  );

export default combineEpics(
  otpRequest,
  otpRequestRedirect,
  identityValidationOtp,
  createMoneyWithdrawalTransaction,
  confirmMoneyWithdrawalRequest,
  createCancellation,
  getTransactionInfo
);
