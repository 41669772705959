import environment from "environment";
import { Connect } from "./connect";

type IGetSummaryPdf = {
  userId: number;
  year: number;
  typePdf: number;
};

type IResponseSummaryDownload = {
  status: number;
  signedUrl: string;
  fileName: string;
};
export class PdfGeneratorConnect extends Connect {
  constructor() {
    const { apiKey, apiSummaryReportPdfUrl } = environment;
    const login = { idToken: sessionStorage.getItem("idToken") };
    super(apiSummaryReportPdfUrl, "", {
      apiKey,
      token: login.idToken ? login.idToken : ""
    });
  }

  async getSignedUrl({
    userId,
    year,
    typePdf
  }: IGetSummaryPdf): Promise<IResponseSummaryDownload> {
    const response: any = await this.request({
      method: "get",
      service: `${year}`
    });

    return response.data;
  }
}
