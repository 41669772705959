import styled from "styled-components";
import { types } from "../types";

type Container = {
  type: types;
};
type CompanyLogoTypes = {
  src: string;
};

export const CompanyLogo = styled.div<CompanyLogoTypes>`
  background: url(${({ src }) => src})  no-repeat center /cover;
  height: 100%; 
    ${({ theme }) => theme.smartphoneMedium} {
        height: 100%;
    }`
  ;

export const LogoContainer = styled.div<Container>`
  width: 90px;
  height: 90px;
  border-radius: 45px;
  background-color: ${({ theme }) => theme.colors.white.bgColor};
  overflow: hidden;
  position: absolute;
  right: 30px;
  top: ${({ type }) => (type === "secondary" ? "-20px" : "30px")};
  box-shadow: 0 -1px 10px rgba(100, 100, 100, 0.1);
  z-index: 1;
  
  ${({ theme }) => theme.breakpoints.intervalDesktopMedium} {
    padding: 0px !important;
  }

  ${({ theme }) => theme.smartphoneMedium} {
    width: 90px;
    height: 90px;
  }
`;
