import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  trackEvent: ["payload"]
});

export const AnalitycsTypes = Types;
export default Creators;

const INITIAL_STATE = {
  payload: {
    action: "",
    category: "",
    label: ""
  }
};

const trackEvent = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  payload
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.TRACK_EVENT]: trackEvent
});
