/* eslint-disable no-underscore-dangle */
import { catchError, flatMap, map } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import InterviewActions, {
  InterviewTypes
} from "../reducers/interview.reducer";

export const getInterviewInfo = (action$) =>
  action$.pipe(
    ofType(InterviewTypes.GET_INTERVIEW),
    flatMap(({ requestCampaignId }) =>
      Observable.from(
        App.api.a2censo.stateCampaignInterview({
          url: { requestCampaignId }
        })
      ).pipe(
        map((response) => InterviewActions.setInterview(response)),
        catchError(() => Observable.of(InterviewActions.getInterviewError()))
      )
    )
  );

export default combineEpics(getInterviewInfo);
