/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import "./PresentationTab.styles.scss";
import environment from "environment";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import ImageTab from "./ImageTab/ImageTab.component";

enum ICampaingTypes {
  Debt = 1,
  Shares = 2,
  ConvertibleNote = 3
}

type IProps = {
  campaignDetail: Array<{
    id: number;
    title?: String;
    content?: String;
    second_content?: String;
    url_image?: String;
  }>;
  type: Number;
};

const PresentationTab = ({ campaignDetail, type }: IProps) => {
  const [displayMoreInfoItem, setdisplayMoreInfoItem] = useState<number>(0);
  const { t } = useTranslation();
  return (
    <div className="presentation-tab">
      <div className="presentation-tab__container">
        {campaignDetail.map((card) => (
          <div key={card.id} className="time">
            <div key={card.id} className="presentation-tab__card">
              <div className="presentation-tab__info">
                <ImageTab url_image={card.url_image} />
                <p className="presentation-tab__name">{card.title}</p>
                <p
                  className={`presentation-tab__position ${
                    type == ICampaingTypes.Shares
                      ? "shareColorTab"
                      : type == ICampaingTypes.ConvertibleNote
                      ? "noteColorTab"
                      : type == ICampaingTypes.Debt
                      ? "debtColorTab"
                      : null
                  }`}
                >
                  {card.content}
                </p>
                <div
                  className="info-button-container"
                  onClick={() => {
                    if (card.second_content) {
                      setdisplayMoreInfoItem(card.id);
                    }
                  }}
                  data-testid="testI"
                >
                  <p
                    className={`presentation-tab__view-info ${
                      card.second_content ? "active" : "inactive"
                    }`}
                  >
                    {t("campaignDetail.presentationInfo")}
                  </p>
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    className={`"presentation-tab__icon" ${
                      card.second_content ? "active" : "inactive"
                    }`}
                  />
                </div>
              </div>
            </div>
            {displayMoreInfoItem === card.id && (
              <div className="presentation-tab__card display-more-info ">
                <div
                  className="display-more-info__close-button"
                  onClick={() => setdisplayMoreInfoItem(0)}
                  data-testid="testII"
                ></div>
                <p className="presentation-tab__description">
                  {card.second_content}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PresentationTab;
