import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  align-items: center;
`;
export const Label = styled.label`
  border: 1px solid
    ${(props) =>
      props.readOnly || props.disabled
        ? props.theme.middleGray
        : props.theme.brightTurquoise};
  border-radius: 20px;
  height: 40px;
  width: 150px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.readOnly || props.disabled
      ? props.theme.middleGray
      : props.theme.brightTurquoise};
  font-family: "Roboto";
  font-size: 14px;
  text-align: center;
`;
export const Input = styled.input`
  display: none;
`;
export const TooltipErrorText = styled.p`
  color: #e11a2c;
  font-family: "Roboto";
  font-size: 12px !important;
  margin: 2px !important;
  text-align: left;
  white-space: nowrap;
`;
