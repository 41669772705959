const { Serializer } = require("jsonapi-serializer");

module.exports = {
  sendPublishConfirmationEmailSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["campaignId", "companyName"]
    })
};
