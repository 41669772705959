import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import AnalyticsActions from "../../redux/reducers/analytics.reducer";
import AnalyticsModel from "../../lib/models/analytics.model";

import {
  Container,
  Paragraph,
  Divider,
  Button,
  CloseButton,
  Icon
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";

const CancellationModal = ({ campaignId, amount }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const startCancellationFlow = () => {
    dispatch(push("investment-cancellation", { campaignId, amount }));
    dispatch(AnalyticsActions.trackEvent(AnalyticsModel.goToCancelSure));
  };

  return (
    <Container data-testid="cancellation-modal-container">
      <CloseButton
        data-testid="cancellation-back-button"
        onClick={() => dispatch(ModalActions.setConditionalModalState(false))}
      >
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <Paragraph>
        {`${t("cancellationModal.text")}`}
        <b>{t("cancellationModal.question")}</b>
      </Paragraph>

      <Divider />
      <Button
        data-testid="cancellation-confirm"
        onClick={() => startCancellationFlow()}
      >
        {t("cancellationModal.sure")}
      </Button>
    </Container>
  );
};

CancellationModal.propTypes = {
  campaignId: PropTypes.number.isRequired,
  amount: PropTypes.string.isRequired
};

export default CancellationModal;
