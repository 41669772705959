import { DETAIL_SELECTED } from "../models/campaign.model";

const requestcampaignPublishSerializer = (data, isSend) => {
  const reasons = data.reasons.reduce((result, item, index) => {
    result[`reason_${index}`] = item;
    return result;
  }, {});

  const getPicture = (picture) => {
    if (!picture) {
      return {};
    }
    return { image_profile: picture };
  };

  return {
    request_campaign: {
      continue: isSend,
      name: data.nameOfCampaign,
      video: data.videoPath,
      description: data.briefDescriptionOfTheCompany,
      what_will_we_do: data.whatWillWeDo,
      how_will_we_do_it: data.howWillWeMakeItHappen,
      risks: data.risks,
      impacts: {
        social_impact: data.impacts.socialImpact,
        innovation_impact: data.impacts.innovationImpact,
        financial_impact: data.impacts.financialImpact,
        env_impact: data.impacts.envImpact,
        another_impact: {
          title: data.impacts.otherImpactName,
          description: data.impacts.otherImpactDescription
        }
      },
      detail_selected: DETAIL_SELECTED[data.detailSelected],
      why_trust: reasons
    },
    company: {
      basic_info: {
        what_does_comp_do: data.whatDoYourCompanyDo,
        business_areas: data.whatAreYourBusinessLines.value,
        main_customers: data.whoAreYourMainClients.value,
        main_providers: data.whoAreYourMainSuppliers.value,
        competitor: data.whoIsYourCompetition,
        future_plans: data.tellUsYourFuturePlan.value,
        added_value: data.whatIsTheAddValueFromYourCompany.value,
        show_business_areas: data.whatAreYourBusinessLines.show ? 1 : 0,
        show_main_customers: data.whoAreYourMainClients.show ? 1 : 0,
        show_main_providers: data.whoAreYourMainSuppliers.show ? 1 : 0,
        show_future_plans: data.tellUsYourFuturePlan.show ? 1 : 0,
        show_added_value: data.whatIsTheAddValueFromYourCompany.show ? 1 : 0,
        instagram_link: data.instagram,
        facebook_link: data.facebook,
        twitter_link: data.twitter,
        linkedin_link: data.linkedIn,
        youtube_link: data.youtube,
        web_page_link: data.web
      },
      associates: data.partners.map((partner) => ({
        id: partner.id,
        ...getPicture(partner.picture),
        professional_profile: partner.partnerProfession || "",
        academic_profile: partner.partnerCareer || ""
      })),
      team: data.team.map((member) => ({
        id: member.id,
        name: member.name,
        cargo: member.rol,
        document_number: member.document_number,
        academic_profile: member.partnerCareer || "",
        professional_profile: member.partnerProfession || "",
        ...getPicture(member.picture),
        professional_card: member.professional_card
      }))
    }
  };
};

export default requestcampaignPublishSerializer;
