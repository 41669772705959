import styled from "styled-components";

export const Container = styled.div`
  width: 828px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px 0 20px;
  ${(props) => props.theme.smartphone} {
    padding: 10px 0 10px;
    margin: 0 5%;
  }
  border-radius: 10px;
`;

export const Title = styled.h2`
  margin-top: 48px;
  max-width: 60%;
  font-size: 36px;
  line-height: 40px;
  font-weight: bold;
  font-family: "Nunito", sans-serif;
  ${(props) => props.theme.smartphone} {
    max-width: 100%;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 16px;
    margin-top: 16px;
  }
`;

export const Paragraph = styled.p`
  font-size: 23px;
  line-height: 32px;
  margin-bottom: 67px;
  margin-top: 28px;
  max-width: 60%;
  font-family: "Roboto", sans-serif;
  color: ${(props) => props.theme.fuscousGray};
  ${(props) => props.theme.smartphone} {
    max-width: 80%;
    font-size: 14px;
    line-height: 18px;
    margin-top: 0px;
    margin-bottom: 18px;
  }
`;

export const Button = styled.button`
  color: ${(props) => props.theme.brightTurquoise};
  width: 172px;
  height: 49px;
  border: 2px solid ${(props) => props.theme.brightTurquoise};
  border-radius: 50px;
  font-weight: bold;
  background-color: ${(props) => props.theme.white};
  cursor: pointer;
  margin-bottom: 77px;
  font-size: 17px;
  ${(props) => props.theme.smartphone} {
    width: 150px;
    font-size: 14px;
    height: 35px;
    margin-bottom: 0px;
  }
`;

export const IconModal = styled.img`
  margin-top: 60px;
  width: 55px;
  height: 75px;
  ${(props) => props.theme.smartphone} {
    margin-top: 0px;
    width: 35px;
    height: 47px;
  }
`;

export const Icon = styled.img`
  width: 30px;
  height: 30px;
  ${(props) => props.theme.smartphone} {
    width: 25px;
    height: 25px;
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  align-self: flex-end;
  margin-right: 30px;
  ${(props) => props.theme.smartphone} {
    margin-right: 10px;
  }
`;
