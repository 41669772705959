/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useTranslation } from "react-i18next";
import { Tag } from "../Tag";
import { BusinessLines } from "lib/models";

export enum BusinessTagTypes {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Quarter = "quater"
}

type Props = {
  businessLine: BusinessLines;
  type: BusinessTagTypes;
  idTooltip: any;
};

type TagProps = {
  title: string;
  icon: boolean;
  background?: string;
  spacing?: boolean;
  forceCenter?: boolean;
};

export const BusinessTag = ({
  businessLine,
  type = BusinessTagTypes.Primary,
  idTooltip
}: Props) => {
  const { t } = useTranslation();

  const title = t(`businessLines.${businessLine}`);

  const props: Record<BusinessTagTypes, TagProps> = {
    [BusinessTagTypes.Primary]: {
      title,
      icon: true
    },
    [BusinessTagTypes.Secondary]: {
      title: title.toUpperCase(),
      icon: true,
      spacing: true
    },
    [BusinessTagTypes.Tertiary]: {
      title,
      icon: false,
      background: "gray"
    },
    [BusinessTagTypes.Quarter]: {
      title: title.toUpperCase(),
      icon: false,
      background: "gray",
      spacing: true
    }
  };

  const BusinessTags: Record<BusinessLines, JSX.Element> = {
    [BusinessLines.Debt]: (
      <Tag
        background="green"
        color="white"
        {...props[type]}
        tooltip={idTooltip}
        forceCenter={false}
        businessLine={businessLine}
      />
    ),
    [BusinessLines.Shares]: (
      <Tag
        background="purple"
        color="white"
        {...props[type]}
        tooltip={idTooltip}
        forceCenter={true}
        businessLine={businessLine}
      />
    ),
    [BusinessLines.ConvertibleNote]: (
      <Tag
        background="dark-blue"
        color="white"
        {...props[type]}
        tooltip={idTooltip}
        forceCenter={true}
        businessLine={businessLine}
      />
    ),
    [BusinessLines.Equity]: (
      <Tag background="white" color="black" {...props[type]} icon={false} />
    )
  };

  return BusinessTags[businessLine];
};
