import { flatMap, catchError, map } from "rxjs/operators";
import { push, go } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import moment from "moment";
import App from "../../lib/app";
import LoginActions, { LoginTypes } from "../reducers/login.reducer";
import { LoginSerializer } from "../../lib/serializers/login.serializer";
import { getUserInfo } from "./confirm-data";
import UserActions from "../reducers/user.reducer";
import myBalanceActions from "../reducers/myBalance.reducer";
import enrollmentAction from "../reducers/enrollment.reducer";
import ModalActions from "../reducers/modal.reducer";
import User from "../../lib/models/user.model";
import paramNames from "../../lib/models/parameter.model";
import AlertActions from "../reducers/alert.reducer";
import EquityActions from "../store/equity/reducer";
import AppParams from "../reducers/app-params.reducer";
import PlacesActions from "../reducers/places.reducer";
import UpdateUserDataActions from "../../redux/store/update-user-data/reducer";

import {
  startGetPoliticsTermsHC,
  userValidatePoliticsTermsHC
} from "./politics-terms-hc";
import { getErrorIndex } from "../../lib/utils";

const { HASHED_CODE_PARAMS } = paramNames;
const { _PARAM_004 } = HASHED_CODE_PARAMS();

export const startLogoutFlow = () => ({
  type: "START_LOGOUT_FLOW"
});

export const startLogoutFront = () => ({
  type: "START_LOGOUT_FLOW_FRONT"
});

export const logoutCleanFlow = () => ({
  type: "LOGOUT_CLEAN_FLOW"
});

export const validateWelcomeFlow = (payload) => ({
  type: "VALIDATE_WELCOME_FLOW",
  personType: payload.personType
});

const USER_LOGIN_DATA_FAIL_CODE = "004";
const USER_DOES_NOT_EXIST_CODE = "006";
const USER_IS_SUSPENDED = "010";
const USER_IS_BLOCK = "011";
const USER_HAS_NOT_VERIFIED_MAIL = "033";
const CAPTCHA_TOKEN_NOT_FOUND = "2003";

const saveToken = (idToken, refreshToken, tokenLife) => {
  sessionStorage.setItem("refreshToken", refreshToken);
  sessionStorage.setItem("idToken", idToken);
  const dateTokenLife = moment().add(
    ((Number(tokenLife) / 60) * 90) / 100,
    "m"
  );

  sessionStorage.setItem("idTokenTime", dateTokenLife);

  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("idToken", idToken);
  localStorage.setItem("idTokenTime", dateTokenLife);
};

export const loginSubmit = (action$) =>
  action$.pipe(
    ofType(LoginTypes.SUBMIT_LOGIN),
    flatMap(({ credentials: { email, password, tokenCaptcha } }) => {
      const { login } = App.redux.store.getState();
      const newLoginAttempts =
        login.lastEmailAttempted && login.lastEmailAttempted === email
          ? login.loginAttempts + 1
          : 1;
      const isUserSuspended = (error, statusCode) =>
        newLoginAttempts >= 6 ||
        error === USER_IS_SUSPENDED ||
        statusCode === 423;
      return Observable.from(
        App.api.a2censo.login({
          body: LoginSerializer().serialize({
            email,
            password,
            loginAttempts: newLoginAttempts
          }),
          headers: {
            tokencaptcha: tokenCaptcha
          }
        })
      ).pipe(
        flatMap(
          ({
            access_token: accessToken,
            id_token: idToken,
            state,
            refresh_token: refreshToken,
            last_sign_in: lastSignIn,
            client_ip: clientIp,
            session_timeout: tokenLife
          }) => {
            Observable.of(LoginActions.setLoadingTypeUserFinish());
            saveToken(idToken, refreshToken, tokenLife);     
            Observable.of(LoginActions.cleanLoginError(0));       
            return Observable.concat(
              Observable.of(UserActions.setUserState(state)),
              Observable.of(getUserInfo({ isLogin: true })),
              Observable.of(LoginActions.getParametersFlow()),
              Observable.of(
                LoginActions.setSessionTokenLife(Number(tokenLife))
              ),
              Observable.of(
                LoginActions.loginSuccess({
                  accessToken,
                  lastSignIn,
                  clientIp
                })
              ),
              Observable.of(myBalanceActions.startBalanceFlow()),
              Observable.of(LoginActions.onLoadingTypeUserFinish())             
            );
          }
        ),
        catchError((response) => {
          const error = response.errors
            ? response.errors[0].code
            : [{ code: "no-code" }];

          if (isUserSuspended(error, response.statusCode)) {
            return Observable.concat(
              Observable.of(LoginActions.onFetchFinish()),
              Observable.of(push("/")),
              Observable.of(
                ModalActions.setConditionalModalState(
                  true,
                  "SuspendedAccountModal"
                )
              )
            );
          }
          if (
            error === USER_IS_BLOCK ||
            error === USER_HAS_NOT_VERIFIED_MAIL ||
            response.statusCode === 400
          ) {
            return Observable.concat(
              Observable.of(
                LoginActions.loginFail({
                  error: response.statusCode,
                  loginAttempts: newLoginAttempts,
                  lastEmailAttempted: email
                })
              ),
              Observable.of(push("/")),
              Observable.of(
                ModalActions.setConditionalModalState(true, "LoginErrorModal", {
                  errorCodes: error,
                  textOnButton: ""
                })
              )
            );
          }
          if (
            error === USER_DOES_NOT_EXIST_CODE ||
            error === USER_LOGIN_DATA_FAIL_CODE ||
            error === CAPTCHA_TOKEN_NOT_FOUND
          ) {
            return Observable.of(
              LoginActions.loginFail({
                error,
                loginAttempts: newLoginAttempts,
                lastEmailAttempted: email
              })
            );
          }
          if (response.statusCode === 410) {
            return Observable.concat(
              Observable.of(LoginActions.onFetchFinish()),
              Observable.of(push("/vinculation-expired"))
            );
          }
          if (error > -1) {
            return Observable.concat(
              Observable.of(
                LoginActions.loginFail({
                  error: response.statusCode,
                  loginAttempts: newLoginAttempts,
                  lastEmailAttempted: email
                })
              ),
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${error}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              )
            );
          }
          return Observable.concat(
            Observable.of(
              AlertActions.setAlertState("error", "alert.tryAgain", {
                icon: true,
                hasClose: false
              })
            ),
            Observable.of(LoginActions.onFetchFinish())
          );
        })
      );
    })
  );

export const logoutFlow = (action$, state$) =>
  action$.pipe(
    ofType("START_LOGOUT_FLOW", "LOGIN_RESET"),
    flatMap(() =>
      Observable.from(
        App.api.a2censo.logout({
          body: {}
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(LoginActions.closeLogoutOverlay()),
            Observable.of(logoutCleanFlow()),
            Observable.of(
              EquityActions.setPopupCount(state$.value.equity.infoPopupCount)
            ),
            Observable.of(AppParams.setDeepLink(null)),
            Observable.of(UserActions.setUserState(null)),
            Observable.of(
              ModalActions.setConditionalModalState(false, "Loaderhands")
            ),
            Observable.of(PlacesActions.clearPlaces()),
            Observable.of(push("/"))
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.concat(
              Observable.of({ type: "RESET_STATE" }),
              Observable.of(go("/")),
              Observable.of(logoutCleanFlow())
            );
          }
          return Observable.of(
            push("/oops", {
              hideButton: true
            })
          );
        })
      )
    )
  );

export const logoutFront = (action$) =>
  action$.pipe(
    ofType("START_LOGOUT_FLOW_FRONT", "LOGIN_RESET"),
    flatMap(() =>
      Observable.concat(
        Observable.of(LoginActions.closeLogoutOverlay()),
        Observable.of({ type: "RESET_STATE" }),
        Observable.of(push("/")),
        Observable.of(logoutCleanFlow())
      )
    )
  );

export const logoutClean = (action$) =>
  action$.pipe(
    ofType("LOGOUT_CLEAN_FLOW"),
    flatMap(() => {
      window.scrollTo(0, 0);
      return Observable.concat(
        Observable.of(localStorage.clear()),
        Observable.of(sessionStorage.clear()),
        Observable.of(LoginActions.clearLoginData()),
        Observable.of(UpdateUserDataActions.clearUpdateUserData())
      );
    })
  );

export const getParameters = (action$) =>
  action$.pipe(
    ofType("GET_PARAMETERS_FLOW"),
    flatMap(() =>
      Observable.from(
        App.api.a2censo.getStaticParameters({
          url: { tables: `${_PARAM_004}` }
        })
      ).pipe(
        map(({ _parameter: data }) => {
          const sessionTimeout = data.find(
            (param) => param.name === "timeout_session"
          );
          return LoginActions.setSessionTimeout(Number(sessionTimeout.value));
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.of(
              ModalActions.setConditionalModalState(true, "ErrorModal", {
                title: "errorModal.title",
                content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                linkRedirectsTo: "/",
                buttonText: "errorModal.success"
              })
            );
          }
          return Observable.of(
            push("/oops", {
              hideButton: true
            })
          );
        })
      )
    )
  );

export const validateWelcomeFlowRequest = (action$) =>
  action$.pipe(
    ofType("VALIDATE_WELCOME_FLOW"),
    flatMap(({ personType }) =>
      Observable.from(
        App.api.a2censo.politicsTermsHCbyPersonType({
          url: {
            personType: User.typeIds[personType]
          }
        })
      ).pipe(
        flatMap((response) => {
          const validations = userValidatePoliticsTermsHC(response);
          if (validations.politics || validations.terms || validations.hc) {
            return Observable.concat(
              Observable.of(enrollmentAction.setShowWelcomeModal(true)),
              Observable.of(startGetPoliticsTermsHC())
            );
          }
          return Observable.empty();
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.of(
            push("/oops", {
              hideButton: true
            })
          );
        })
      )
    )
  );

export default combineEpics(
  loginSubmit,
  logoutFlow,
  getParameters,
  validateWelcomeFlowRequest,
  logoutClean,
  logoutFront
);
