import { catchError, mergeMap } from "rxjs/operators";
import { combineEpics, ofType, ActionsObservable } from "redux-observable";
import { Observable } from "rxjs-compat";
// eslint-disable-next-line import/named
import { AnyAction } from "redux";
import App from "lib/app";
import { CompanyInfoSerializer } from "lib/serializers/company.serializer";
import CompanyActions from "redux/reducers/company.reducer";
import AlertActions from "redux/reducers/alert.reducer";
import { DataUpdateFormValues } from "pages/DataUpdateCertificates/DataUpdateForm";

enum IGetCompanyInfoTypes {
  GetCompanyAllInfo = "GET_COMPANY_ALL_INFO",
  UpdateCompanyCities = "ON_UPDATE_COMPANY_CITIES"
}

export const getAllCompanyInfo = () => ({
  type: IGetCompanyInfoTypes.GetCompanyAllInfo
});

export const updateCompanyCities = (payload: DataUpdateFormValues) => ({
  type: IGetCompanyInfoTypes.UpdateCompanyCities,
  payload
});

const errorAction = () =>
  Observable.concat(
    Observable.of(
      AlertActions.setAlertState("error", "alert.tryAgain", {
        icon: true,
        hasClose: false
      })
    ),
    Observable.of(CompanyActions.fetchFinish()),
    Observable.of(CompanyActions.onGetInfoFinish())
  );

export const fetchCompanyAdditionalInfo = (
  action$: ActionsObservable<AnyAction>
) =>
  action$.pipe(
    ofType(IGetCompanyInfoTypes.GetCompanyAllInfo),
    mergeMap(() => {
      const {
        api: {
          a2censo: { getCompanyAllInfo }
        }
      }: any = App;
      return Observable.from(getCompanyAllInfo()).pipe(
        mergeMap((response: any) => {
          const {
            data: { attributes }
          } = CompanyInfoSerializer().serialize(response);
          return Observable.concat(
            Observable.of(CompanyActions.setCompanyInfo(attributes)),
            Observable.of(CompanyActions.onGetInfoFinish())
          );
        }),
        catchError(errorAction)
      );
    })
  );

export const onUpdateCompanyCities = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    ofType(IGetCompanyInfoTypes.UpdateCompanyCities),
    mergeMap(({ payload }: AnyAction) => {
      const {
        api: {
          a2censo: { updateCitiesCompany }
        }
      }: any = App;
      const {
        city,
        cityRetention,
        cityConsignment,
        commercialAddress
      }: DataUpdateFormValues = payload;
      return Observable.from(
        updateCitiesCompany({
          body: {
            city_id: city,
            city_retention_id: cityRetention,
            city_consignment: cityConsignment,
            commercial_address: commercialAddress
          }
        })
      )
        .delay(3000)
        .pipe(
          mergeMap(() =>
            Observable.concat(
              Observable.of(
                AlertActions.setAlertState("success", "successSave", {
                  icon: true,
                  hasClose: false
                })
              ),
              Observable.of(CompanyActions.fetchFinish())
            )
          ),
          catchError(errorAction)
        );
    })
  );

export default combineEpics(fetchCompanyAdditionalInfo, onUpdateCompanyCities);
