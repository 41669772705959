/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import User from "../../lib/models/user.model";
import { startGetPoliticsTermsHC } from "../../redux/epics/politics-terms-hc";
import AppParams from "../../redux/reducers/app-params.reducer";

export const enrollmentFinished = (enrollmentRequired, userState) =>
  enrollmentRequired
    ? typeof userState !== "undefined" && userState === 0
    : true;

export const disableIfIsPime = (disabledToPyme, userInfo) =>
  userInfo !== null && userInfo.user.person_type !== User.type.pyme
    ? false
    : disabledToPyme;

export const canRender = (
  canActive,
  sessionRequired,
  idToken,
  enrollmentRequired,
  userState,
  disabledToPyme,
  userInfo,
  isPymeRedirect
) =>
  canActive &&
  (sessionRequired ? !!idToken : true) &&
  enrollmentFinished(enrollmentRequired, userState) &&
  !disableIfIsPime(disabledToPyme, userInfo) &&
  !isPymeRedirect;

export const getPath = (
  enrollmentRequired,
  enrollmentIsFinish,
  redirectUri,
  userData
) =>
  enrollmentRequired && !enrollmentIsFinish
    ? User.getUriFromUserState(userData)
    : redirectUri;

const ProtectedRoute = ({
  component: Component,
  canActive,
  redirectUri,
  sessionRequired,
  enrollmentRequired,
  pymeUser,
  disabledToPyme,
  ...rest
}) => {
  const location = useSelector(({ router }) => router.location);
  const deepLink = useSelector(({ appParams }) => appParams.deepLink);

  if (location.pathname === rest.path) {
    const dispatch = useDispatch();
    dispatch(startGetPoliticsTermsHC());
  }

  const idToken = sessionStorage.getItem("idToken");
  const userState = useSelector(({ user }) => user.userState);
  const userInfo = useSelector(({ user }) => user.userInfo);
  const userData = useSelector(({ user }) =>
    user && user.userInfo
      ? user.userInfo.user
      : {
          enrollment_state: null,
          state: null,
          person_type: null
        }
  );
  const enrollmentIsFinish = enrollmentFinished(enrollmentRequired, userState);
  const personType = userInfo ? userInfo.user.person_type : null;

  const path = getPath(
    enrollmentRequired,
    enrollmentIsFinish,
    redirectUri,
    userData
  );

  if (
    location.pathname === rest.path &&
    !(sessionRequired ? !!idToken : true) &&
    deepLink
  ) {
    const dispatch = useDispatch();
    dispatch(
      AppParams.setDeepLink(location.pathname + (location.search || ""))
    );
  }

  const isPymeRedirect = pymeUser && personType !== User.type.pyme;

  return (
    <Route
      {...rest}
      render={(props) =>
        canRender(
          canActive,
          sessionRequired,
          idToken,
          enrollmentRequired,
          userState,
          disabledToPyme,
          userInfo,
          isPymeRedirect
        ) ? (
          <Component {...props} />
        ) : (
          <Redirect to={path} />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.element.isRequired,
  canActive: PropTypes.bool,
  sessionRequired: PropTypes.bool,
  redirectUri: PropTypes.string,
  enrollmentRequired: PropTypes.bool,
  pymeUser: PropTypes.bool,
  disabledToPyme: PropTypes.bool
};

ProtectedRoute.defaultProps = {
  redirectUri: "/",
  canActive: true,
  sessionRequired: true,
  enrollmentRequired: false,
  pymeUser: false,
  disabledToPyme: false
};

export default ProtectedRoute;
