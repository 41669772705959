import styled from "styled-components";
import { Button } from "@bvcco/a2censo-component-lib";

type Props = {
  isAvailable: boolean;
};

export const ButtonsRow = styled.div<Props>`
  display: flex;
  align-self: flex-start;
  justify-content: ${({ isAvailable }) =>
    isAvailable ? "space-between" : "flex-start"};
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 10px;
`;

export const ButtonContainer = styled.div`
  width: 134px;
  button {
    padding: 6px 24px;
  }
  &:nth-child(1) {
    margin-left: 0;
    margin-right: 18px;
    ${({ theme }) => theme.breakpoints.mobile} {
      margin-right: 8px;
    }
  }
`;

export const CustomButton = styled(Button)`
  background: inherit !important;
`;
