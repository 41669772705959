import { createReducer, createActions } from "reduxsauce";
import moment from "moment";

const { Types, Creators } = createActions({
  submitLogin: ["credentials"],
  submitLogout: [],
  loginSuccess: ["tokens"],
  loginFail: ["errorAttempt"],
  refreshTokens: ["tokens"],
  cleanLoginError: ["loginAttempts"],
  loginReset: [],
  toggleLogoutOverlay: [],
  setSessionTimeout: ["sessionTimeout"],
  setSessionTokenLife: ["tokenLife"],
  getParametersFlow: [],
  validateWelcomeFlow: ["payload"],
  closeLogoutOverlay: [],
  onFetchFinish: [],
  onLoadingTypeUserFinish: [],
  setLoadingTypeUserFinish: [],
  clearLoginData: []
});

export const LoginTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  error: "",
  loginAttempts: 0,
  lastEmailAttempted: "",
  accessToken: undefined,
  lastSignIn: null,
  clientIp: null,
  showLogoutOverlay: false,
  sessionTimeout: undefined,
  sessionTokenLife: undefined,
  isLoadingTypeUser: false
};

const submitLogin = (state) => ({
  ...state,
  isFetching: true,
  isLoadingTypeUser: true
});

const submitLogout = (state) => ({
  ...state,
  isFetching: true
});

const loginSuccess = (
  state,
  { tokens: { accessToken, lastSignIn, clientIp } }
) => ({
  ...state,
  isFetching: false,
  accessToken,
  error: "",
  clientIp,
  lastSignIn: lastSignIn
    ? moment(lastSignIn).format("DD/MM/YYYY HH:mm:ss")
    : null
});

const loginFail = (
  state,
  { errorAttempt: { error, loginAttempts, lastEmailAttempted } }
) => ({
  ...state,
  isFetching: false,
  isLoadingTypeUser: false,
  error,
  loginAttempts,
  lastEmailAttempted
});

const cleanLoginError = (state, { loginAttempts }) => ({
  ...state,
  loginAttempts: loginAttempts >= 0 ? loginAttempts : state.loginAttempts,
  error: "",
  isFetching: false,
  isLoadingTypeUser: false
});

const refreshTokens = (state, { tokens: { accessToken } }) => ({
  ...state,
  accessToken
});

const toggleLogoutOverlay = (state) => ({
  ...state,
  showLogoutOverlay: !state.showLogoutOverlay
});

const closeLogoutOverlay = (state) => ({ ...state, showLogoutOverlay: false });

const setSessionTimeout = (state, { sessionTimeout }) => ({
  ...state,
  sessionTimeout,
  isLoadingTypeUser: false
});

const setSessionTokenLife = (state, { tokenLife }) => ({
  ...state,
  isLoadingTypeUser: false,
  sessionTokenLife: Number(tokenLife) * 1000
});

const onFetchFinish = (state) => ({
  ...state,
  isFetching: false,
  isLoadingTypeUser: false
});

const onLoadingTypeUserFinish = (state) => ({
  ...state,
  isLoadingTypeUser: false
});

const setLoadingTypeUserFinish = (state) => ({
  ...state,
  isLoadingTypeUser: true
});

const clearLoginData = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_LOGIN]: submitLogin,
  [Types.SUBMIT_LOGOUT]: submitLogout,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAIL]: loginFail,
  [Types.REFRESH_TOKENS]: refreshTokens,
  [Types.CLEAN_LOGIN_ERROR]: cleanLoginError,
  [Types.TOGGLE_LOGOUT_OVERLAY]: toggleLogoutOverlay,
  [Types.SET_SESSION_TIMEOUT]: setSessionTimeout,
  [Types.SET_SESSION_TOKEN_LIFE]: setSessionTokenLife,
  [Types.CLOSE_LOGOUT_OVERLAY]: closeLogoutOverlay,
  [Types.ON_FETCH_FINISH]: onFetchFinish,
  [Types.ON_LOADING_TYPE_USER_FINISH]: onLoadingTypeUserFinish,
  [Types.SET_LOADING_TYPE_USER_FINISH]: setLoadingTypeUserFinish,
  [Types.CLEAR_LOGIN_DATA]: clearLoginData
});
