import { createReducer, createActions } from "reduxsauce";

type IRouterHistoryState = {
  history: string[];
};

type IRouterPayload = {
  path: string;
};

const { Types, Creators } = createActions({
  setRoute: ["path"]
});

export default Creators;

const setRoute = (
  state: IRouterHistoryState,
  { path }: IRouterPayload
): IRouterHistoryState => ({
  ...state,
  history: [path, ...state.history].splice(0, 5)
});

export const INITIAL_STATE: IRouterHistoryState = {
  history: ["/"]
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ROUTE]: setRoute
});
