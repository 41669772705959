import React from "react";
import {
  Container,
  ContainerDescription,
  DocumentIMG,
  ContainerOptions,
  ButtonOption,
  OptionIMG
} from "./components";
import {
  ContainerFilter,
  ImageEmpty,
  ContainerEmpty
} from "./Styles";
import { Paragraph } from "@bvcco/a2censo-component-lib";
import { Dropdown, DropdownIOption } from "components/shared";
import CardIMG from "assets/images/card-document.png";
import SearchEmptyIMG from "assets/images/search-no-results.png";
import CampaignMail from "assets/images/campaign-mail.png";
import DownloadCertificate from "assets/images/download-certificate.png";
import { useTranslation } from "react-i18next";

type IProps = {
  certificates?: Array<{
    quantity: number;
    description: string;
    companyName: string;
  }>;
  yearList: Array<{ id: number; value: number }>;
  description: string;
  onClickEmail?: () => void;
  onClickDownload?: () => void;
  id: string;
  yearInitial: number;
  initialLabel: number;
  onChangeYear: (value: DropdownIOption) => void;
};

export const FinancialTaxCard = ({
  certificates = [],
  yearList,
  description,
  onClickDownload,
  onClickEmail,
  id,
  yearInitial,
  onChangeYear
}: IProps) => {
  const { t } = useTranslation();
  return (
    <Container>
        <ContainerFilter>
        <ContainerDescription>
          <DocumentIMG src={CardIMG} />
          <Paragraph>{description}</Paragraph>
        </ContainerDescription>
          <ContainerOptions>
            <Dropdown
              name="drop-down-year"
              id="drop-down-year"
              options={yearList}
              valueSelected={yearInitial}
              onChange={onChangeYear}
            />
            <ButtonOption
              data-testid={`financialtax-card-email-${id}`}
              onClick={onClickEmail}
              disabled={!(certificates.length > 0)}
            >
              <OptionIMG src={CampaignMail} />
            </ButtonOption>
            <ButtonOption
              data-testid={`financialtax-card-download-${id}`}
              onClick={onClickDownload}
              disabled={!(certificates.length > 0)}
            >
              <OptionIMG src={DownloadCertificate} />
            </ButtonOption>
          </ContainerOptions>
        </ContainerFilter>
        {!(certificates.length > 0) &&
          <ContainerEmpty>
            <ImageEmpty src={SearchEmptyIMG} />
            <Paragraph>
              {t("myCertificates.detail.empty.descriptionPar1")}
              <strong>{yearInitial}</strong>
              {t("myCertificates.detail.empty.descriptionPar2")}
            </Paragraph>
          </ContainerEmpty>
        }
    </Container>
  );
};