import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import { Button, Heading, Paragraph } from "@bvcco/a2censo-component-lib";
import { Container, CloseButton, Icon } from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";
import { startLogoutFlow, startLogoutFront } from "../../redux/epics/login";
import {
  ErrorValidateCIIUInvalid,
  ExperianDeletedUserCode,
  SHAREHOLDER_ERROR,
  ExperianBlockedUser,
  errorCodebtorUserBlocked
} from "../../lib/utils/constants.errors";
import { getUserInfo } from "../../redux/epics/confirm-data";

const ValidationPymeModal = ({ code = "EXPERIAN-001", notRedirect }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleCloseClick = () => {
    if (!notRedirect) {
      if (
        code === ErrorValidateCIIUInvalid ||
        code === ExperianBlockedUser ||
        code === errorCodebtorUserBlocked ||
        SHAREHOLDER_ERROR.some((error) => error === code)
      ) {
        dispatch(startLogoutFlow());
      } else if (
        ExperianDeletedUserCode === code
      ) {
        dispatch(startLogoutFront());
      }
      dispatch(getUserInfo());
      dispatch(ModalActions.setConditionalModalState(false));
      dispatch(push("/"));
    } else {
      dispatch(ModalActions.setConditionalModalState(false));
    }
  };

  const title = code === "EXPERIAN-001" ? "title2" : "title";

  return (
    <Container>
      <CloseButton id="button-close" onClick={handleCloseClick}>
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <Heading fontWeight="bold">
        {t(`confirmDataPyme.validationsConfirm.${title}`)}
      </Heading>
      <Paragraph>
        {t(`confirmDataPyme.validationsConfirm.codes.${code}`)}
      </Paragraph>
      <Button
        id="return-button"
        dataTestid="return-button"
        onClick={handleCloseClick}
        fontWeight="bold"
        rounded={true}
      >
        {t("confirmDataPyme.validationsConfirm.back")}
      </Button>
    </Container>
  );
};

ValidationPymeModal.propTypes = {
  code: PropTypes.string.isRequired,
  notRedirect: PropTypes.bool
};

export default ValidationPymeModal;
