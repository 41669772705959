import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { SmsOtpValidation } from "../../components/SmsOtpValidation/SmsOtpValidation";
import AccountRecoveryAction from "../../redux/reducers/account-recovery.reducer";
import {
  Container,
  Title,
  Subtitle,
  InfoText,
  NewCodeLink,
  SentNewCodeMessage,
  Error
} from "./Styles";

import CodeInput from "../../components/CodeInput/CodeInput";

const AccountRecoveryOTPValidation = ({ location, history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const recoveryError = useSelector(
    ({ accountRecovery }) => accountRecovery.error
  );

  const [messageVisibility, setMessageVisibility] = useState(false);
  const inputCodeRef = useRef();

  if (typeof location.state === "undefined") {
    history.replace({ state: undefined });
    dispatch(push("/login"));
    return <div />;
  }

  const { userMail, method, countryCode, phoneNumber, dateGenerationToken, timerLimitMinutes } = location.state;

  useEffect(() => {
    dispatch(
      AccountRecoveryAction.recoveryAccountValidationOtpError({
        error: ""
      })
    );
  }, []);

  const codeOnChange = (value) => {
    if (value.length === 6) {
      dispatch(
        AccountRecoveryAction.startAccountRecoveryOtpValidation({
          userMail,
          method,
          otp: value
        })
      );
    }
  };

  const handleRequestNewCode = () => {
    inputCodeRef.current.cleanValues();
    setMessageVisibility(true);
    setTimeout(() => setMessageVisibility(false), 10000);
    dispatch(
      AccountRecoveryAction.startAccountRecoveryOtpRequest({
        email: userMail,
        method
      })
    );
  };

  const handleShowError = () => {
    inputCodeRef.current.cleanValues();
    const { errors } = recoveryError;
    const hasExceeded = errors[0].code === "1023";

    if (method === "email") {
      return (
        <Error>
          {t(
            hasExceeded
              ? "codeValidation.exceededLimitAttempts"
              : "codeValidation.noValidCode"
          )}
        </Error>
      );
    }
  };
  
  if (method === "sms") {
    return (
      <SmsOtpValidation
        onClick={codeOnChange}
        RequestNewCode={AccountRecoveryAction.startAccountRecoveryOtpRequest({
          email: userMail,
          method
        })}
        countryCode= {countryCode}
        phoneNumber= {phoneNumber}
        dateGenerationToken={dateGenerationToken}
        timerLimitMinutes={Number(timerLimitMinutes)}
        error={recoveryError}
      />
    );
  } else {
    return (
      <Container className="container">
        <Title>{t("identityValidationOtp.title")}</Title>

        <Subtitle>{t("identityValidationOtp.messageemail")}</Subtitle>

        <CodeInput
          parentRef={inputCodeRef}
          onChange={codeOnChange}
          extraInput={true}
          id="code-input-identity"
        />

        {messageVisibility && (
          <SentNewCodeMessage>
            {t("codeValidation.newCodeMessage")}
          </SentNewCodeMessage>
        )}

        {recoveryError && handleShowError()}

        <InfoText className="col-md-6">
          {t("identityValidationOtp.noReceived")}
          <NewCodeLink
            data-testid="request-new-code-button"
            onClick={handleRequestNewCode}
          >
            {t("identityValidationOtp.askAgain")}
          </NewCodeLink>
        </InfoText>
      </Container>
    );
  }
};

AccountRecoveryOTPValidation.propTypes = {
  location: PropTypes.shape({
    state: {
      userEmail: PropTypes.string
    }
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func
  }).isRequired
};

export default AccountRecoveryOTPValidation;
