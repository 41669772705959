import { createBrowserHistory } from "history";
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { createEpicMiddleware, combineEpics } from "redux-observable";
import { routerMiddleware, connectRouter } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import legacyReducers from "redux/reducers";
import epics from "redux/epics";
import appObservables from "redux/store/observables";
import middlewaresLoader from "./middlewares";
import {
  routerPreventMiddleware,
  resetTimeoutMiddleware
} from "redux/middlewares";
import appReducers from "redux/store/reducers";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "router",
    "conditions",
    "register",
    "codeValidation",
    "modal",
    "movements",
    "enrollment",
    "forgotPassword",
    "investmentCancellation",
    "request",
    "investmentSummary",
    "campaings",
    "investingCampaigns",
    "pseData",
    "myCompanyInfo",
    "fileUploader",
    "homeSelections",
    "investingCampaigns",
    "campaignDocumentManagment",
    "updateUserData",
    "parameters",
    "routeHistory",
    "internetAlert"
  ]
};
export const history = createBrowserHistory();

const mergeReducers = combineReducers({
  router: connectRouter(history),
  ...legacyReducers,
  ...appReducers
});

const persistedReducer = persistReducer(persistConfig, mergeReducers);

export default function loadRedux() {
  const epicMiddleware = createEpicMiddleware();
  const initialState = {};

  const composeEnhancers =
    (typeof window !== "undefined" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        routerPreventMiddleware,
        routerMiddleware(history),
        epicMiddleware,
        resetTimeoutMiddleware,
        middlewaresLoader
      )
    )
  );

  const mergeEpics = combineEpics(epics, appObservables);

  epicMiddleware.run(mergeEpics);
  const persistor = persistStore(store);

  return Promise.resolve({ store, persistor, history });
}
