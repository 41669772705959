import styled from "styled-components";
import IssuerPayment from "../../lib/models/issuerPayment.model";
import moneyBackground from "../../assets/images/money_icon_background.png";

const color = (state, theme) => {
  if (
    [
      IssuerPayment.state.pending_approve,
      IssuerPayment.state.exchange
    ].includes(state)
  ) {
    return `
      color: ${theme.pizazz}; 
      background-color: ${theme.pizazzRgba};
    `;
  }
  if (
    [
      IssuerPayment.state.paid,
      IssuerPayment.state.issuer_approved,
      IssuerPayment.state.approved_by_timeout,
      IssuerPayment.state.paid_by_collection_house
    ].includes(state)
  ) {
    return `
      color: ${theme.brightTurquoise2}; 
      background-color: ${theme.brightTurquoiseRgba};
    `;
  }
  if (
    [IssuerPayment.state.late_payment, IssuerPayment.state.rejected].includes(
      state
    )
  ) {
    return `
      color: ${theme.radicalRed}; 
      background-color: ${theme.radicalRedRgba};
    `;
  }
  if (
    [
      IssuerPayment.state.pending_new_amount_request,
      IssuerPayment.state.pending_reject_request
    ].includes(state)
  ) {
    return `
      color: ${theme.blueRibbon}; 
      background-color: ${theme.blueRibbonRgba};
    `;
  }
};

const show = (state, showValue) => {
  if (
    state !== IssuerPayment.state.pending_approve &&
    state !== IssuerPayment.state.inactive
  ) {
    return "display: flex;";
  }
  if (showValue && state === IssuerPayment.state.pending_approve) {
    return "display: flex;";
  }

  return "display: none;";
};

const backgroundImage = (state) => {
  if (
    [
      IssuerPayment.state.pending_approve,
      IssuerPayment.state.exchange
    ].includes(state)
  ) {
    return "background-image: url(/assets/images/info-pizazz.png);";
  }
  if (
    [
      IssuerPayment.state.paid,
      IssuerPayment.state.issuer_approved,
      IssuerPayment.state.approved_by_timeout
    ].includes(state)
  ) {
    return "background-image: url(/assets/images/info-bright-turquoise2.png);";
  }
  if (
    [IssuerPayment.state.late_payment, IssuerPayment.state.rejected].includes(
      state
    )
  ) {
    return "background-image: url(/assets/images/info-radical-red.png);";
  }
  if (
    [
      IssuerPayment.state.pending_new_amount_request,
      IssuerPayment.state.pending_reject_request
    ].includes(state)
  ) {
    return "background-image: url(/assets/images/info-blue-ribbon.png);";
  }
};

export const PageTitle = styled.h1`
  color: ${(props) => props.theme.tuatara};
  font-family: "Nunito";
  font-size: 30px;
  font-weight: 800;
  margin: 0;
  padding: 0 10px;
  border-left: 5px solid ${(props) => props.theme.brightTurquoise};
  ${(props) => props.theme.smartphone} {
    margin: 10px 0;
    font-size: 20px;
  }
`;
export const PageSubtitle = styled.p`
  color: ${(props) => props.theme.tuatara};
  font-family: "Roboto";
  font-size: 23px;
  margin: 28px 0;
  font-weight: normal;
  line-height: 30px;
  ${(props) => props.theme.smartphone} {
    margin: 10px 0;
    font-size: 14px;
    line-height: 20px;
  }
  text-align: justify;
`;

export const Name = styled.span`
  font-family: "Roboto";
  font-size: 21px;
  font-weight: bold;
  ${(props) => props.theme.smartphone} {
    margin: 10px 0;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.gray};

  @media (min-width: 1440px) and (max-width: 2559px) {
    padding: 40px 20%;
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    padding: 40px 7%;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 40px 5%;
    height: 100vh !important;
  }

  @media (max-width: 767px) {
    padding: 40px 5%;
    height: 100vh !important;
  }
`;

export const Payments = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  ${(props) => props.theme.mediumScreens} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const PaymentContainer = styled.div`
  padding: 10px 0 0 0;
  width: 100%;
  position: absolute;
  right: 0;

  @media (min-width: 769px) {
    display: none;
    padding: 10px 0 10px 0;
  }
`;

export const PaymentContainerDesktop = styled.div`
  width: 50%;
  margin-bottom: 30px;
  padding: 10px 25px 0 0;
  max-width: 543px;
  ${(props) => props.theme.tablet} {
    width: 100%;
  }

  @media (max-width: 768px) {
    display: none;
  }

  &.second {
    padding: 10px 0 0 25px;
  }
`;

export const PaymentTitle = styled.div`
  background-color: ${(props) => props.theme.brightTurquoise};
  padding: 25px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  color: ${(props) => props.theme.white};

  ${(props) => props.theme.tablet} {
    padding: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 50px;
  }
`;

export const PaymentBody = styled.div`
  background-color: ${(props) => props.theme.white};
  padding-bottom: 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  justify-content: center;
  min-height: 545px;
  height: auto;

  @media (min-width: 769px) {
    min-height: 635px;
  }
`;

export const PaymentBodyWarning = styled.div`
  background-color: ${(props) => props.theme.white};
  padding: 50px;
  text-align: center;
`;

export const CardTitle = styled.span`
  font-family: "Nunito";
  font-size: 14px;

  ${(props) => props.theme.mediumScreens} {
    font-size: 21px;
  }
  ${(props) => props.theme.tablet} {
    padding: 20px 0 20px 20px;
  }
`;

export const PaymentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 10px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ececec;
`;

export const Button = styled.button`
  width: 215px;
  height: 49px;
  background-color: ${(props) => props.theme.white};
  border-radius: 24px;
  border: 1px solid ${(props) => props.theme.brightTurquoise};
  color: ${(props) => props.theme.brightTurquoise};
  font-family: "Nunito";
  font-size: 17px;
  font-weight: 800;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  ${(props) => props.theme.smartphone} {
    width: 170px;
    height: 40px;
    border-radius: 18px;
    font-size: 13px;
  }
`;

export const PaymentListContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Placeholder = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
`;

export const PlaceholderImage = styled.img`
  height: 96px;
  width: 102px;
  ${(props) => props.theme.smartphone} {
    height: 58px;
    width: 62px;
  }
`;

export const PlaceholderMessage = styled.p`
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 16px;
  text-align: center;
  font-family: "Nunito";
  font-weight: bold;
  width: 50%;
  color: ${(props) => props.theme.masala};

  ${(props) => props.theme.smartphone} {
    font-size: 21px;
    line-height: 24px;
    width: 100%;
  }
`;

export const PlaceholderNotify = styled.p`
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 16px;
  text-align: center;
  font-family: "Nunito";
  color: ${(props) => props.theme.stratos};
  white-space: pre-wrap;
  ${(props) => props.theme.smartphone} {
    font-size: 18px;
    line-height: 18px;
  }
`;

export const PlaceholderLink = styled.span`
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  text-decoration: underline;
  font-family: "Roboto";
  font-size: 21px;
  color: ${(props) =>
    props.disabled ? props.theme.poloBlue : props.theme.azure};

  ${(props) => props.theme.smartphone} {
    font-size: 14px;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
`;

export const ArrowImage = styled.img`
  position: absolute;
  height: 19px;
  width: 11px;
  ${(props) => props.theme.smartphone} {
    height: 13px;
    width: 8px;
  }
  margin-top: 2px;
  margin-left: 15px;
  color: ${(props) => props.theme.brightTurquoise};
`;

export const PaymentButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PaymentContainerOptions = styled.div`
  width: 100%;
`;

export const PaymentContainerOption = styled.div`
  width: 100%;
`;

export const WarningIcon = styled.div`
  color: white;
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  font-size: 14px;
  line-height: 24px;
  border: 20px solid transparent;
  border-bottom-color: ${(props) => props.theme.timeLineRed};
  border-top: 0;
  border-left-width: 11px;
  border-right-width: 11px;
  font-weight: bold;

  &.warning-2 {
    border-bottom-color: ${(props) => props.theme.brightTurquoise};
  }
`;

export const SpanWarning = styled.span`
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 16px;
  text-align: center;
`;

export const SpanWarningTitle = styled.span`
  font-family: "Roboto";
  font-size: 28px;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
  color: ${(props) => props.theme.tuatara};
  padding: 6px 6px 6px 15px;

  ${(props) => props.theme.smartphone} {
    font-size: 20px;
    line-height: 12px;
  }
`;

export const WarningSubTitle = styled.div`
  color: ${(props) => props.theme.timeLineRed};
  font-family: "Nunito";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 15px;
  text-align: center;
  width: 100%;
  padding: 25px 5px 25px 5px;

  ${(props) => props.theme.smartphone} {
    font-size: 10px;
    line-height: 10px;
  }

  &.warning-2 {
    color: ${(props) => props.theme.brightTurquoise};
  }

  &.warning-3 {
    color: ${(props) => props.theme.tuatara};
    font-family: "Roboto";
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    padding: 15px 5px 15px 5px;
    font-weight: 500;

    ${(props) => props.theme.smartphone} {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 30px;
  padding-left: 0;
  padding-bottom: 10px;
`;

export const BackButtonImage = styled.img`
  height: 26px;
  width: 26px;
  object-fit: contain;
`;

export const BackContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  width: 100%;
`;

export const PaymentState = styled.div`
  display: flex;
  padding: 7px 25px;
  min-height: 40px;
  height: auto;
  ${(props) => color(props.state, props.theme)}
  ${(props) => show(props.state, props.show)}
  ${(props) => props.theme.smartphone} {
    padding: 9px 12px;
  }
`;

export const InfoText = styled.span`
  padding: 5px 10px;
  font-family: "Roboto";
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  font-weight: 600;
  ${(props) => props.theme.smartphone} {
    font-size: 14px;
    padding: 3px 10px;
  }
`;

export const InfoImage = styled.div`
  height: 26px;
  width: 26px;
  background-repeat: no-repeat;
  background-size: contain;
  ${(props) => backgroundImage(props.state)}
  ${(props) => props.theme.smartphone} {
    height: 22px;
    width: 22px;
  }
`;

export const BackgrountImage = styled.div`
  align-items: flex-end;
  background-image: url(${`${moneyBackground}`});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  height: 120px;
  padding-bottom: 5px;
  width: 142px;
  ${(props) => props.theme.smartphone} {
    height: 80px;
    width: 94px;
  }
`;
