import React, { useState } from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DatePicker from "../../../components/DatePicker/DatePickerInput";
import {
  Container,
  FilterFormRow,
  FilterInputContainer,
  LinkText,
  ButtonsContainer,
  Button,
  Icon,
  Select,
  FieldsContainer,
  Spacer
} from "./Styles";

const spyGlass = "/assets/images/spy-glass.png";

const Filters = ({
  values,
  setFieldValue,
  onFilter,
  campaigns,
  cleanFilters
}) => {
  const { t } = useTranslation();
  const [touchedIcon, setTouchedIcon] = useState(false);
  return (
    <Container>
      <FieldsContainer>
        <FilterFormRow background={true}>
          <FilterInputContainer widthSmartphone="100">
            <Select
              label={t("paymentsHistory.byCampaign")}
              name="campaignId"
              id="campaign-id"
              placeholder={t("paymentsHistory.byCampaign")}
              options={campaigns}
              onChange={(event) => {
                setFieldValue("campaignId", event.target.value);
              }}
              value={values.campaignId}
            />
          </FilterInputContainer>
        </FilterFormRow>
        <FilterFormRow width="65">
          <Spacer />
          <FilterInputContainer background={true} widthSmartphone="48">
            <DatePicker
              data-testid="from_date"
              id="payments-date-picker-from"
              value={values.yearMonth}
              onChange={(dateSelected) => {
                const yearMonth = `${moment(dateSelected).format(
                  "YYYY"
                )}-${moment(dateSelected).format("M")}`;
                setFieldValue("yearMonth", yearMonth);
              }}
              placeholder={t("paymentsHistory.byMonth")}
              withPortal={true}
              dateFormat="yyyy-MM"
              showMonthYearPicker={true}
              setTouchedIcon={setTouchedIcon}
              error={touchedIcon}
            />
          </FilterInputContainer>
          <Spacer />
          <FilterInputContainer background={true} widthSmartphone="48">
            <Select
              label={t("paymentsHistory.byState")}
              name="state"
              id="state"
              placeholder={t("paymentsHistory.byState")}
              options={[
                { value: "paid", text: t("paymentsHistory.paid") },
                { value: "rejected", text: t("paymentsHistory.rejected") },
                {
                  value: "approved_by_timeout",
                  text: t("paymentsHistory.approved_by_timeout")
                },
                { value: "exchange", text: t("paymentsHistory.exchange") },
                { value: "inactive", text: t("paymentsHistory.inactive") },
                {
                  value: "issuer_approved",
                  text: t("paymentsHistory.issuer_approved")
                },
                {
                  value: "late_payment",
                  text: t("paymentsHistory.late_payment")
                },
                {
                  value: "paid_by_collection_house",
                  text: t("paymentsHistory.paid_by_collection_house")
                },
                {
                  value: "pending_approve",
                  text: t("paymentsHistory.pending_approve")
                },
                {
                  value: "pending_new_amount_request",
                  text: t("paymentsHistory.pending_new_amount_request")
                },
                {
                  value: "pending_reject_request",
                  text: t("paymentsHistory.pending_reject_request")
                }
              ]}
              onChange={(event) => {
                setFieldValue("state", event.target.value);
              }}
              value={values.state}
            />
          </FilterInputContainer>
        </FilterFormRow>
      </FieldsContainer>
      <ButtonsContainer>
        <Button id="filter-button" onClick={() => onFilter(values)}>
          {t("paymentsHistory.searchButton")}
          <Icon src={spyGlass} />
        </Button>
        <LinkText
          id="clean-filters"
          onClick={() => {
            setFieldValue("campaignId", "");
            setFieldValue("yearMonth", "");
            setFieldValue("state", "");
            cleanFilters();
          }}
        >
          {t("paymentsHistory.cleanFilters")}
        </LinkText>
      </ButtonsContainer>
    </Container>
  );
};

Filters.propTypes = {
  values: PropTypes.shape({
    campaignId: PropTypes.string,
    yearMonth: PropTypes.string,
    state: PropTypes.string
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  cleanFilters: PropTypes.func.isRequired,
  campaigns: PropTypes.shape([]).isRequired
};

export default withFormik({
  mapPropsToValues: () => ({
    campaignId: "",
    yearMonth: "",
    state: ""
  })
})(Filters);
