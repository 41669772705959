const { Serializer } = require("jsonapi-serializer");

module.exports = {
  EmailSerializer: (meta = {}) =>
    new Serializer("email", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "formType",
        "full_name",
        "email",
        "message",
        "pymeName",
        "pymeContactName",
        "pymeEmail",
        "pymePhone",
        "pymeCountry",
        "pymeDescription",
        "reasonId"
      ]
    })
};
