import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  startFinantialSection: {},
  setFinantialSection: ["finantialSection"],
  setCommercialEmail: ["commercialEmail"]
});

export const FinanceYourselfTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  successfulCampaignGuide: {},
  paymentItems: [],
  testimonies: [],
  commercialEmail: ""
};

const setFinantialSection = (state, { finantialSection }) => ({
  ...state,
  ...finantialSection
});

const setCommercialEmail = (state, { commercialEmail }) => ({
  ...state,
  commercialEmail
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_FINANTIAL_SECTION]: setFinantialSection,
  [Types.SET_COMMERCIAL_EMAIL]: setCommercialEmail
});
