import styled, { css } from "styled-components";
import ReactTooltip from "react-tooltip";

export const Tooltip = styled(ReactTooltip)`
  ${({ theme }) => css`
    background: ${theme.colors.tooltipColor.bgColor} !important;
    color: ${theme.alabaster};
    font-family: ${theme.fontFamily.primary};
  `}
  font-size: 14px;
  line-height: 19px;
  padding: 12px;
  max-width: 360px !important;
  border-radius: 8px;
  text-align: center;

  &::after {
    border-top-color: #685e90 !important;
    border-bottom-color: #685e90 !important;
    ${(props) =>
      props.businessLine == 1
        ? "left: 57% !important;"
        : props.businessLine == 2
        ? "left: 58% !important;"
        : props.businessLine == 3
        ? "left: 65% !important;"
        : ""}
  }

  ${({ theme }) => theme.smartphone} {
    &:: after {
      ${(props) => (props.businessLine == 1 ? "left: 35% !important;" : "")}
    }  
    ${(props) =>
      props.businessLine == 1 ? "margin-left: 80px !important;" : ""}
`;
