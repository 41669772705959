import styled from "styled-components";
import CloseBtn from "assets/images/close-btn.png";

export const Close = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: url(${CloseBtn}) no-repeat;
  width: 31px;
  height: 31px;

  :hover {
    opacity: 0.5;
  }
`;
