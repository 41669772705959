import styled from "styled-components";

export const CloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
//
export const CloseButton = styled.img`
  cursor: pointer;
  margin-bottom: 24px;

  ${({ theme }) => theme.smartphoneMedium} {
    margin-bottom: 14px;
  }
`;
