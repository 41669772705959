import React, { useMemo } from "react";
import { Title, CountContainer, Item, Container, Words } from "./components";
import { Paragraph } from "components/shared";
import { useTranslation } from "react-i18next";

enum ThemeProps {
  Dark = "dark",
  Light = "light"
}

interface Props extends React.HTMLProps<HTMLDivElement> {
  remainingSeconds: number;
  dataTestId?: string;
  theme?: ThemeProps;
  onFinish?: () => void;
}

interface IRemainingType {
  days: number;
  hours: number;
  minutes: number;
}

function getDifference(difference: number): IRemainingType {
  if (difference < 0) {
    return { days: 0, hours: 0, minutes: 0 };
  }
  // Time calculations for days, hours, minutes and seconds
  let remainingTime = difference;
  const factorSecondToMinute = 60; // 1000 * 60
  const factorSecondToHour = 3600; // 1000 * 60 * 60;
  const factorSecondToDay = 86400; // (1000 * 60 * 60 * 24);

  const days = Math.floor(remainingTime / factorSecondToDay);
  remainingTime -= days * factorSecondToDay;

  const hours = Math.floor(remainingTime / factorSecondToHour);
  remainingTime -= hours * factorSecondToHour;

  const minutes = Math.floor(remainingTime / factorSecondToMinute);

  return { days, hours, minutes };
}

function formatNumber(value: number) {
  return `0${value}`.slice(-2);
}

export const CountDown = ({
  dataTestId = "count-down",
  remainingSeconds,
  theme = ThemeProps.Dark
}: Props) => {
  const { t } = useTranslation();

  const remainingTime = useMemo<IRemainingType>(
    () => getDifference(remainingSeconds),
    [remainingSeconds]
  );

  return (
    <Container data-testid={dataTestId} containerStyle={theme}>
      <Title>{t("campaign.countDown.itStartsAt")}</Title>

      <CountContainer>
        <Item>
          <Paragraph color="white" fontWeight="900" size="large">
            {formatNumber(remainingTime.days)}
          </Paragraph>
          <Words>
            <Paragraph color="white" fontWeight="600" size="less_small">
              {t("campaign.countDown.days")}
            </Paragraph>
          </Words>
        </Item>

        <Item>
          <Paragraph color="white" fontWeight="900" size="large">
            {formatNumber(remainingTime.hours)}
          </Paragraph>
          <Words>
            <Paragraph color="white" fontWeight="600" size="less_small">
              {t("campaign.countDown.hours")}
            </Paragraph>
          </Words>
        </Item>

        <Item>
          <Paragraph color="white" fontWeight="900" size="large">
            {formatNumber(remainingTime.minutes)}
          </Paragraph>
          <Words>
            <Paragraph color="white" fontWeight="600" size="less_small">
              {t("campaign.countDown.minutes")}
            </Paragraph>
          </Words>
        </Item>
      </CountContainer>
    </Container>
  );
};
