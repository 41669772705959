import styled from "styled-components";

export const DownloadOnlyContainer = styled.div`
  padding: 0 0 0 20px;
  max-width: 260px;
  & button {
    border: ${({ theme }) => `1px solid ${theme.fiord}`};
    font-size: 16px;
  }
`;
