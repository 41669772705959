import React, { useEffect, useState, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Container, InputContainer, Input } from "./Styles";

const CodeInput = ({ onChange, otpSize, parentRef }) => {
  const refs = [];
  const [inputValues, setInputValues] = useState([]);

  const handleRef = (ref) => {
    refs.push(ref);
  };

  const setNewInputValue = (i, newValue) => {
    const newInputValues = [...inputValues];
    newInputValues[i] = newValue;
    setInputValues(newInputValues);
  };

  useImperativeHandle(parentRef, () => ({
    cleanValues() {
      refs.forEach((_, i) => {
        if (refs[i]) {
          refs[i].value = "";
        }
      });

      setInputValues([]);
    }
  }));

  // onChange inputValues
  useEffect(() => {
    onChange(inputValues.join(""));
  }, [inputValues]);

  useEffect(() => {
    refs.forEach((_, i) => {
      refs[i].onfocus = () => {
        refs[i].value = "";
      };
      refs[i].onpaste = (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData("Text");
        refs[i].value = text.length > 1 ? text.charAt(0) : text;
      };
      refs[i].oninput = (e) => {
        e.preventDefault();
        if (i < refs.length - 1 && refs[i + 1]) {
          refs[i + 1].focus();
        } else {
          refs[i].blur();
        }

        setNewInputValue(i, e.target.value);
      };
      refs[i].onkeydown = (e) => {
        if (e.keyCode === 8) {
          refs[i].value = "";
          refs[i > 0 ? i - 1 : i].focus();

          setNewInputValue(i, e.target.value);
        }
      };
    });
    return () => {};
  }, [inputValues]);

  const renderInputs = () => {
    const inputs = [];
    for (let i = 0; i < otpSize; i++) {
      inputs.push(
        <InputContainer className="col-md-1 col-2">
          <Input
            data-testid={i}
            type="number"
            maxLength="1"
            ref={handleRef}
            className="code-otp-input"
          />
        </InputContainer>
      );
    }
    return inputs;
  };
  return (
    <Container className="row justify-content-center">
      {renderInputs()}
    </Container>
  );
};

CodeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  otpSize: PropTypes.number,
  parentRef: PropTypes.shape({ cleanValues: PropTypes.func })
};

CodeInput.defaultProps = {
  otpSize: 6,
  parentRef: {}
};

export default CodeInput;
