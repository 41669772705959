import React from "react";
import { AboutUs, AboutUsFromType } from "components/shared";
import StyledContainer from "./Styles";
import Allies from "../../components/Allies/Allies";
import SupportFor from "../../components/SupportFor/SupportFor";

const OurAllies = () => (
  <StyledContainer>
    <AboutUs from={AboutUsFromType.OurAllies} />
    <Allies />
    <SupportFor />
  </StyledContainer>
);

export default OurAllies;
