import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Creators } from "../../redux/reducers/conditions.reducer";
import ModalActions from "../../redux/reducers/modal.reducer";
import { startSaveHC } from "../../redux/epics/politics-terms-hc";
import {
  Container,
  CloseButton,
  CloseImage,
  TitleContainer,
  TitleSubContainer,
  Title,
  Scroll,
  Paragraph,
  ButtonContainer,
  Button
} from "./Styles";

const CreditHistory = ({ hcObject, requestFrom }) => {
  const [bottomReached, setBottomReached] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleScroll = (event) => {
    const node = event.target;
    const bottom = node.scrollHeight - node.scrollTop <= node.clientHeight + 20;
    if (bottom) {
      setBottomReached(true);
    }
  };
  const setListener = (node) => {
    if (node) {
      node.addEventListener("scroll", handleScroll);
    }
  };

  const acceptOnClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    if (requestFrom === "register") {
      dispatch(Creators.setHC({ hcAccepted: bottomReached }));
    } else {
      dispatch(startSaveHC(hcObject.id, hcObject.version));
    }
  };

  const close = () => {
    dispatch(ModalActions.setConditionalModalState(false));
  };

  return (
    <Container>
      {requestFrom === "register" && (
        <CloseButton
          id="close-button"
          onClick={close}
          data-testid="hc-close-btn"
        >
          <CloseImage alt="close" src="/assets/images/close_2.png" />
        </CloseButton>
      )}
      <TitleContainer>
        <TitleSubContainer>
          {requestFrom === "register" ? (
            <Title>{t("creditHistory.title")}</Title>
          ) : (
            <Title>{t("creditHistory.title2")}</Title>
          )}
          <Scroll
            ref={(ref) => {
              setListener(ref);
              if (ref) {
                const bottom =
                  ref.scrollHeight - ref.scrollTop <= ref.clientHeight + 20;
                if (bottom) {
                  setBottomReached(true);
                }
              }
            }}
          >
            <Paragraph>{hcObject.text}</Paragraph>
          </Scroll>
        </TitleSubContainer>
        <ButtonContainer>
          <Button
            id="continue-button"
            data-testid="hc-accept-btn"
            disabled={!bottomReached}
            onClick={acceptOnClick}
          >
            {t("creditHistory.acceptButton")}
          </Button>
        </ButtonContainer>
      </TitleContainer>
    </Container>
  );
};

CreditHistory.propTypes = {
  hcObject: PropTypes.shape({
    id: PropTypes.number,
    version: PropTypes.string,
    url: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  requestFrom: PropTypes.string.isRequired
};

export default CreditHistory;
