import { useEffect, useState } from "react";

export const useCountDown = (seconds: number) => {
  const [value, setValue] = useState(seconds);

  useEffect(() => {
    const timer = setInterval(() => {
      setValue((v) => {
        const newValue = Math.max(0, v - 1);
        if (!newValue) {
          clearTimeout(timer);
        }
        return newValue;
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [setValue]);

  return value;
};
