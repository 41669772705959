import { flatMap, catchError } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import { push } from "connected-react-router";
import App from "../../lib/app";
import MyCompanyActions from "../reducers/my-company.reducer";
import UserActions from "../reducers/user.reducer";
import {
  infoFetchSerializer,
  infoSerializer
} from "../../lib/serializers/my-company.serializer";
import LoginActions from "../reducers/login.reducer";

export const submitMyCompanyForm = (data, setSubmitting, isValid) => ({
  type: "SUBMIT_COMPANY_INFO_FORM",
  payload: {
    info: data,
    setSubmitting,
    isValid
  }
});

export const onFetchMyCompanyInfo = ($action) =>
  $action.pipe(
    ofType("FETCH_COMPANY_INFO"),
    flatMap(() =>
      Observable.from(App.api.a2censo.getMyCompanyInfo()).pipe(
        flatMap((response) => {
          const taxAuditorItem = response.team.filter(
            (member) => member.cargo === "Revisor Fiscal"
          );
          const taxAuditorPos =
            taxAuditorItem.length > 0
              ? response.team.findIndex(
                  (element) => element.id === taxAuditorItem[0].id
                )
              : null;
          const info = infoFetchSerializer(response, taxAuditorPos);
          return Observable.of(MyCompanyActions.setInfo(info));
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      )
    )
  );

export const onSubmitMyCompanyInfo = ($action, $state) =>
  $action.pipe(
    ofType("SUBMIT_COMPANY_INFO_FORM"),
    flatMap(({ payload: { info, setSubmitting, isValid } }) => {
      const {
        value: {
          myCompanyInfo: { info: storeInfo }
        }
      } = $state;

      const finalInfo = { ...info };
      // partial o complety save
      finalInfo.next = isValid;
      return Observable.from(
        App.api.a2censo.setCompanyInfo({
          body: infoSerializer(finalInfo, storeInfo)
        })
      ).pipe(
        flatMap(() => {
          setSubmitting(false);
          return Observable.concat(
            Observable.of(MyCompanyActions.setInfo(finalInfo)),
            Observable.of(MyCompanyActions.fetchCompanyInfo()),
            isValid
              ? Observable.of(UserActions.setFinishInformation())
              : Observable.empty()
          );
        }),
        catchError((e) => {
          setSubmitting(false);
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      );
    })
  );

export default combineEpics(onFetchMyCompanyInfo, onSubmitMyCompanyInfo);
