import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  forgotPasswordError: ["error"],
  forgotPasswordSuccess: []
});

export const ValidateCodeTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  error: false
};

const forgotPasswordError = (state, { error = true }) => ({ ...state, error });

const forgotPasswordSuccess = (state) => ({ ...state, error: false });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FORGOT_PASSWORD_ERROR]: forgotPasswordError,
  [Types.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess
});
