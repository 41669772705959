import styled from "styled-components";
import { PageTitle } from "../../styles/Styles";

export const BackArrowButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
`;

export const BackArrowImage = styled.img`
  height: 18px;
  width: 28.5px;
  object-fit: contain;
`;

export const CustomPageTitle = styled(PageTitle)`
  font-size: 36px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Container = styled.div`
  width: 100vw;
  padding-top: 10px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  & .ps__thumb-y {
    background-color: ${(props) => props.theme.turquoiseBlue} !important;
  }
`;

export const TittleContainer = styled.div`
  text-align: center;
  width: 100%;
  font-family: "Nunito";
  margin-top: 80px;
  margin-bottom: 50px;
  h1 {
    font-size: 36px;
    ${(props) => props.theme.tablet} {
      margin-top: 40px;
      margin-bottom: 18px;
      font-size: 20px;
    }
  }
`;

export const BoldName = styled.strong`
  ${(props) => props.theme.tablet} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const SubtitleContainer = styled.div`
  margin-top: 38px;
  margin-bottom: 15px;
  display: inline-block;
  line-height: 26px;
  text-align: left;
  color: #31302f;
  font-size: 23px;

  ${(props) => props.theme.tablet} {
    margin-top: 22px;
    line-height: 18px;
    font-size: 14px;
    color: ${(props) => props.theme.ironSideGray};
  }
`;

export const BusinessName = styled.span`
  font-weight: bold;
`;

export const BusinessNameText = styled.span``;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 26px;
  text-align: left;
  color: #31302f;
  font-size: 16px;
  padding-bottom: 11px;

  ${(props) => props.theme.tablet} {
    line-height: 14px;
    font-size: 10px;
    color: ${(props) => props.theme.ironSideGray};
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 520px;
  margin: 0 auto;
  ${(props) => props.theme.tablet} {
    padding: 0;
    flex-direction: column;
  }
`;

export const TotalPaymentContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;

  ${(props) => props.theme.tablet} {
    flex-direction: column;
    margin-bottom: 16px;
  }
`;

export const TotalPaymentLeftContainer = styled.div`
  margin-right: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props) => props.theme.tablet} {
    margin-right: 0px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const TotalPaymentText = styled.div`
  color: #00084b;
  font-size: 36px;
  font-weight: bold;
  line-height: 48px;
  margin-bottom: 15px;

  ${(props) => props.theme.tablet} {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
  }
`;

export const TotalPaymentValue = styled.div`
  color: #00084b;
  font-size: 60px;
  font-family: Nunito;
  font-weight: 900;
  line-height: 52px;

  ${(props) => props.theme.tablet} {
    font-size: 22px;
    line-height: 22px;
  }
`;

export const TotalPaymenContainerSeparator = styled.div`
  width: 3%;

  ${(props) => props.theme.tablet} {
    display: none;
  }
`;

export const TotalPaymenSeparator = styled.div`
  height: 100%;
  width: 6px;
  background-color: #1d2d49;
  opacity: 0.1;
`;

export const TotalPaymentRightContainer = styled.div`
  width: 47.5%;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.tablet} {
    width: 100%;
  }
`;

export const TotalPaymentList = styled.div`
  min-height: 155px;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;

  ${(props) => props.theme.tablet} {
    align-items: start;
  }
`;

export const TotalPaymentItem = styled.div`
  width: 40%;
  padding-top: 10px;
  padding-bottom: 10px;

  ${(props) => props.theme.tablet} {
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
`;

export const TotalPaymentItemText = styled.div`
  color: ${({ theme }) => theme.colors.tuatara.color};
  font-size: 18px;

  ${(props) => props.theme.tablet} {
    line-height: ${({ hasReference }) => (hasReference ? "11px" : "22px")};
    font-size: 14px;
    &:before {
      content: ${(props) => (props.operation === "plus" ? '"+ "' : '"- "')};
    }
  }
`;

export const TotalPaymentItemValue = styled.div`
  margin-top: 6px;
  color: ${(props) =>
    props.operation === "plus"
      ? props.theme.ironsideGray
      : props.theme.brightTurquoise};
  font-size: 20px;
  font-weight: bold;
  &:before {
    content: ${(props) => (props.operation === "plus" ? '"+ "' : '"- "')};
  }

  ${(props) => props.theme.tablet} {
    line-height: 22px;
    margin-top: 0px;
    font-size: 14px;
    &:before {
      content: "";
    }
  }
`;

export const FixedFooterContainer = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: ${(props) => props.theme.brightTurquoiseTransparent};

  ${(props) => props.theme.tablet} {
    height: 60px;
  }
`;

export const FooterButtonApprove = styled.button`
  width: 177px;
  height: 49px;
  background-color: ${(props) => props.theme.brightTurquoise};
  border-radius: 24px;
  border: none;
  color: ${(props) => props.theme.white};
  font-family: Nunito;
  font-size: 17px;
  font-weight: 800;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  ${(props) => props.theme.tablet} {
    width: 170px;
    height: 40px;
    border-radius: 18px;
    font-size: 13px;
  }
`;

export const FooterButtonReject = styled.button`
  width: 177px;
  height: 49px;
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.stratos};
  font-family: Nunito;
  font-size: 17px;
  cursor: pointer;
  padding: 1px 6px;
  line-height: 49px;
  text-align: center;
  text-decoration: underline;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  ${(props) => props.theme.tablet} {
    width: 170px;
    height: 40px;
    border-radius: 18px;
    font-size: 13px;
  }
`;

export const TotalPaymentReference = styled.span`
  font-size: 10px;
  display: block;
  margin-top: 2px;
`;
