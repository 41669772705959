import React from "react";
import { useTranslation } from "react-i18next";
import { Heading, Paragraph } from "@bvcco/a2censo-component-lib";
import {
  StyledContainer,
  ContentRows,
  RowContainer,
  ImageContainer,
  TextContainer,
  Img
} from "./KnowUsDescriptionStyles";
const somosDiferentes = "../../assets/images/a2censo/somosDiferentes@2x.png";
const propuestaValor = "../../assets/images/a2censo/propuestaValor@2x.png";

const KnowUsDescriptionContent = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <ContentRows>
        <RowContainer>
          <ImageContainer>
            <Img src={somosDiferentes} />
          </ImageContainer>
          <TextContainer className="text-container">
            <Heading
              fontWeight="bold"
              color="white"
              align="left"
              className="headings"
            >
              {t("a2censo.knowUs.firstRow.title")}
            </Heading>
            <Paragraph
              fontWeight="bold"
              color="white"
              align="left"
              className="paragraphs"
            >
              {t("a2censo.knowUs.firstRow.description")}
            </Paragraph>
          </TextContainer>
        </RowContainer>
        <RowContainer className="reverse">
          <ImageContainer>
            <Img src={propuestaValor} />
          </ImageContainer>
          <TextContainer className="reverse">
            <Heading
              fontWeight="bold"
              color="white"
              align="right"
              className="headings"
            >
              {t("a2censo.knowUs.secondRow.title")}
            </Heading>
            <Paragraph
              fontWeight="bold"
              color="white"
              align="right"
              className="paragraphs"
            >
              {t("a2censo.knowUs.secondRow.description")}
            </Paragraph>
          </TextContainer>
        </RowContainer>
      </ContentRows>
    </StyledContainer>
  );
};
export default KnowUsDescriptionContent;
