import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { push } from "connected-react-router";
import moment from "moment";
import PropTypes from "prop-types";
import validationSchema from "./investDocument.schema";
import { saveQualifiedInvestor } from "../../redux/epics/invest";
import SelectionList from "../../components/SelectionList/SelectionList";
import RadioQuestion from "../../components/RadioQuestions/RadioQuestions";
import DatePickerInput from "../../components/DatePicker/DatePickerInput";
import InputFile from "../../components/InputFile/InputFile";
import {
  BackButton,
  BackButtonImage,
  BackContainer,
  StrokeCircle,
  StrokeLine,
  StrokeLineContainer,
  FormRow,
  Lines,
  LineCircle,
  ShortLine,
  LongLine,
  InputContainer,
  FormFooterRow,
  FormHeaderRow,
  FormContainer,
  FormSubmitFooterButton
} from "../../components/Form/Styles";
import {
  Container,
  QuestionText,
  QuestionLabel,
  InvestorQuality,
  Form,
  ConfirmButton,
  QuestionIcon,
  Tooltip,
  TooltipContainer,
  TooltipContent,
  TooltipText
} from "./Styles";
import { PageSubtitle, PageTitle } from "../../styles/Styles";
import { toBase64, validateFile } from "../../lib/utils";
import User from "../../lib/models/user.model";
import Investor from "../../lib/models/investor.model";

import AnalyticsActions from "../../redux/reducers/analytics.reducer";
import AnalyticsModel from "../../lib/models/analytics.model";

const InvestDocument = ({ location, match }) => {
  const [isNotValidFile, setIsNotValidFile] = useState(
    "investment.validations.fileSize.required"
  );
  const [isQualifiedInvestor, setIsQualifiedInvestor] = useState(false);
  const [touchedIcon, setTouchedIcon] = useState(false)
  const campaingID = match.params.id;
  const userInfo = useSelector(({ user }) => user.userInfo.user);
  const name =
    userInfo.person_type === User.type.natural
      ? `${userInfo.name} ${userInfo.last_name}`
      : `${userInfo.name}`;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formData = {
    state: -1,
    certification_type: "",
    expiration_date: "",
    file: "",
    quality_tax: ""
  };
  const handleBack = () => {
    dispatch(push("/"));
  };

  const fileSizeLimit = Number(
    // eslint-disable-next-line no-underscore-dangle
    location.state._parameter.find(
      (param) => param.name === "limit_size_mb_file_upload"
    ).value
  );

  const renderQuestionText = () => (
    <QuestionText>
      <QuestionLabel>{name}</QuestionLabel>
      <InvestorQuality>
        {t("investment.areYouQualifiedInvestor")}
      </InvestorQuality>
    </QuestionText>
  );

  const formatSelectionListData = (dataList) =>
    dataList.map((item) => ({
      value: item.id,
      label: item.name,
      date: item.date ? item.date : 0
    }));

  const documentUploadTypeList = formatSelectionListData(
    // eslint-disable-next-line no-underscore-dangle
    location.state._investor_document
  );
  const taxQualityList = formatSelectionListData(
    // eslint-disable-next-line no-underscore-dangle
    location.state._quality_tax
  ).filter((taxQuality) => taxQuality.value !== Investor.qualityTax.noQuality);

  const requireExpirationDate = (value) => {
    // eslint-disable-next-line no-underscore-dangle
    const expirationDateField = location.state._investor_document.filter(
      (item) => item.id === value
    );
    if (expirationDateField.length > 0) {
      return expirationDateField[0].date === 1;
    }
    return false;
  };

  return (
    <Container>
      <Formik
        id="invest-document-form"
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(saveQualifiedInvestor({ investorData: values, campaingID }));
          dispatch(
            AnalyticsActions.trackEvent(AnalyticsModel.gotRechargeAmount)
          );
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          setFieldValue,
          validateForm,
          handleBlur,
          setFieldError
        }) => (
          <Form
            onSubmit={(event) => {
              event.preventDefault();
              validateForm(values).then(async (errorList) => {
                const qualifiedInvestor = values.state === 1;
                setIsQualifiedInvestor(qualifiedInvestor);
                if (qualifiedInvestor && isNotValidFile) {
                  setFieldError("file", isNotValidFile);
                  return false;
                }

                if (Object.keys(errorList).length === 0) {
                  handleSubmit();
                }
              });
            }}
          >
            <FormContainer>
              <BackContainer>
                <BackButton
                  onClick={handleBack}
                  data-testId="invest-document-back-button"
                >
                  <BackButtonImage
                    alt="previous"
                    src="/assets/images/previous.png"
                  />
                </BackButton>
              </BackContainer>
              {campaingID ? (
                <>
                  <PageTitle>{t("investment.readyToInvest")}</PageTitle>
                  <PageSubtitle>
                    {t("investment.readyToInvestSubtitle")}
                  </PageSubtitle>
                </>
              ) : (
                <>
                  <PageTitle>{t("investment.readyToRecharge")}</PageTitle>
                  <PageSubtitle>
                    {t("investment.readyToRechargeSubtitle")}
                  </PageSubtitle>
                </>
              )}
              <StrokeLineContainer>
                <StrokeCircle />
                <StrokeLine />
              </StrokeLineContainer>
              <RadioQuestion
                testId="document_upload_type"
                questionText={renderQuestionText()}
                setQuestion={(value) => {
                  setIsQualifiedInvestor(value === 1);
                  setFieldValue("state", value);
                  setFieldValue("expiration_date", "");
                  localStorage.setItem(`investor_type_${userInfo.id}`, value);
                }}
                questionValue={values.state}
                tooltipTitle={t("investment.tooltipToDoc.title")}
                tooltipText={`${t("investment.tooltipToDoc.message1")} %br ${t(
                  "investment.tooltipToDoc.message2"
                )} %br ${t("investment.tooltipToDoc.message3")} %br ${t(
                  "investment.tooltipToDoc.message4"
                )} %br ${t("investment.tooltipToDoc.message5")}`}
              />
              {values.state === 1 && (
                <>
                  <Lines>
                    <LongLine />
                    <LineCircle />
                    <ShortLine />
                  </Lines>
                  <FormRow
                    borders={{
                      bottom: true
                    }}
                  >
                    <SelectionList
                      value={values.certification_type}
                      questionLabel={t("investment.uploadDocumentTypeQuestion")}
                      items={documentUploadTypeList}
                      onChange={(value) => {
                        setFieldValue("expiration_date", "");
                        setFieldValue("certification_type", value);
                      }}
                      testId="document_upload_type_list"
                    />
                  </FormRow>
                  <FormFooterRow borders={{ top: true }}>
                    <InputContainer>
                      <InputFile
                        disabled={!values.certification_type}
                        accept="application/pdf,image/jpg"
                        error={isNotValidFile}
                        onBlur={handleBlur}
                        onChange={async (event) => {
                          const fileLoaded =
                            event.currentTarget.files[0] || null;
                          const fileError = validateFile(
                            fileLoaded,
                            fileSizeLimit
                          );
                          fileLoaded &&
                            setFieldValue("file", await toBase64(fileLoaded));
                          if (fileError) {
                            setFieldError("file", fileError);
                            setIsNotValidFile(fileError);
                          } else {
                            setIsNotValidFile(null);
                          }
                        }}
                      />
                    </InputContainer>
                    {requireExpirationDate(values.certification_type) && (
                      <InputContainer
                        fatBorder={true}
                        colorBorder="#979797"
                        borders={{ bottom: true }}
                      >
                        <DatePickerInput
                          label={t("investment.expirationDate")}
                          placeholder={t("investment.expirationDate")}
                          id="expirationDate"
                          onChange={(value) => {
                            const date = moment(value)
                              .format("YYYY-MM-DD")
                              .toString();
                            setFieldValue("expiration_date", date);
                          }}
                          name="document_expedition_date"
                          value={values.expiration_date}
                          setTouchedIcon={setTouchedIcon}
                          error={errors.expiration_date}
                          withPortal={true}
                        />
                      </InputContainer>
                    )}
                  </FormFooterRow>
                </>
              )}
              <StrokeLineContainer>
                <StrokeCircle />
                <StrokeLine />
              </StrokeLineContainer>
              <FormHeaderRow>
                <InputContainer>
                  <QuestionText>
                    <QuestionLabel>
                      {t("investment.taxQualityQuestion")}
                    </QuestionLabel>
                  </QuestionText>
                  <QuestionIcon
                    data-tip={true}
                    data-for="radio_question_tooltip_quality"
                    src="/assets/images/icono_pregunta.png"
                    alt="question"
                  />
                  <Tooltip
                    id="radio_question_tooltip_quality"
                    place="right"
                    type="light"
                    effect="solid"
                  >
                    <TooltipContainer>
                      <TooltipContent>
                        <TooltipText>
                          {t("investment.tooltipToDoc.quality")}
                        </TooltipText>
                      </TooltipContent>
                    </TooltipContainer>
                  </Tooltip>
                </InputContainer>
              </FormHeaderRow>
              <FormFooterRow
                borders={{
                  top: true
                }}
              >
                <InputContainer>
                  <SelectionList
                    value={values.quality_tax}
                    questionLabel={t("investment.select")}
                    items={taxQualityList}
                    onChange={(value) => setFieldValue("quality_tax", value)}
                    type="list"
                    testId="tax_quality"
                  />
                </InputContainer>
              </FormFooterRow>
            </FormContainer>
            <FormSubmitFooterButton>
              <ConfirmButton
                buttonStyle="white"
                testId="submit"
                type="submit"
                data-testid="submit_button"
                disabled={
                  values.quality_tax === "" ||
                  values.state === -1 ||
                  (!values.expiration_date &&
                    values.certification_type === 3 &&
                    values.state === 1) ||
                  (isQualifiedInvestor && isNotValidFile) ||
                  Object.keys(errors).length > 0
                }
              >
                {t("investment.continue")}
              </ConfirmButton>
            </FormSubmitFooterButton>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

InvestDocument.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      _investor_document: PropTypes.shape([]),
      _quality_tax: PropTypes.shape([]),
      _parameter: PropTypes.shape([]),
      type: PropTypes.shape([])
    })
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
};

InvestDocument.defaultProps = {
  match: {
    params: {
      id: undefined
    }
  }
};

export default InvestDocument;
