import { catchError, flatMap } from "rxjs/operators";
import { push } from "connected-react-router";
import { ofType, combineEpics } from "redux-observable";
import { Observable } from "rxjs-compat";
import ModalActions from "../reducers/modal.reducer";
import App from "../../lib/app";
import FinanceYourselfActions, {
  FinanceYourselfTypes
} from "../reducers/finance-yourself.reducer";
import { startGetPoliticsTermsHC } from "./politics-terms-hc";
import { getErrorIndex } from "../../lib/utils";
import paramNames from "../../lib/models/parameter.model";

const { HASHED_CODE_PARAMS } = paramNames;
const { _PARAM_004 } = HASHED_CODE_PARAMS();

export const startFinantialSection = () => ({
  type: "START_FINANTIAL_SECTION"
});

export const startCommercialEmail = () => ({
  type: "START_COMERCIAL_EMAIL"
});

export const setFinantialSection = (action$) =>
  action$.pipe(
    ofType(FinanceYourselfTypes.START_FINANTIAL_SECTION),
    flatMap(() =>
      Observable.from(App.api.a2censo.getHomeData()).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(startGetPoliticsTermsHC()),
            Observable.of(
              FinanceYourselfActions.setFinantialSection({
                successfulCampaignGuide:
                  response.finance_yourself.successful_campaign_guide,
                paymentItems: response.finance_yourself.how_payments_work,
                testimonies: response.finance_yourself.testimonies
              })
            )
          )
        ),
        catchError(() => Observable.of(push("/oops")))
      )
    )
  );

export const getComercialEmail = (action$) =>
  action$.pipe(
    ofType("START_COMERCIAL_EMAIL"),
    flatMap(() =>
      Observable.from(
        App.api.a2censo.getStaticParameters({
          url: { tables: `${_PARAM_004}` }
        })
      ).pipe(
        flatMap((response) => {
          // eslint-disable-next-line no-underscore-dangle
          const email = response._parameter.find(
            (item) => item.name === "commercial_email"
          );
          return Observable.concat(
            Observable.of(
              FinanceYourselfActions.setCommercialEmail(email.value)
            )
          );
        }),
        catchError((e) => {
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.of(
              ModalActions.setConditionalModalState(true, "ErrorModal", {
                title: "errorModal.title",
                content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                linkRedirectsTo: "/",
                buttonText: "errorModal.success"
              })
            );
          }
          return Observable.of(push("/oops"));
        })
      )
    )
  );

export default combineEpics(setFinantialSection, getComercialEmail);
