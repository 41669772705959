import { createReducer, createActions } from "reduxsauce";

export const { Types, Creators } = createActions({
  fetchInvestmentSummary: [],
  fetchInvestmentSummarySuccess: ["payload"]
});

export const investSummaryTypes = Types;
export default Creators;

const INITIAL_STATE = {
  investments: 0,
  succesfulCampaigns: 0,
  millionsFinanced: 0,
  investors: 0
};

const fetchInvestmentSummarySuccess = (state, { payload }) => ({
  ...state,
  ...payload
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_INVESTMENT_SUMMARY_SUCCESS]: fetchInvestmentSummarySuccess
});
