import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import environment from "environment";

/*
Cargamos la variable ambiente en false cuando el domainName contiene preprod,test,dev,localhost  de lo contrario (a2censo.com) True.
y acorde a ella se leen las claves de gtag y googleTagManager respectivamente.
*/
const domainName = window.location.hostname;
const ambiente = !(
  domainName.includes("preprod") ||
  domainName.includes("test") ||
  domainName.includes("localhost") ||
  domainName.includes("dev") ||
  domainName.includes("127")
);
const idGTM = ambiente
  ? environment.gtmContainerId
  : domainName.includes("preprod")
  ? environment.gtmContainerIdPre
  : environment.gtmContainerIdTest;

const GTM = () => {
  let type = <div data-testid="google-tag-general" />;

  const tags = [
    {
      async: true,
      innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${idGTM}');`
    }
  ];

  useEffect(() => {
    const newDiv = document.createElement("noscript");
    const newContent = document.createTextNode(
      '<iframe src="https://www.googletagmanager.com/ns.html?id=' +
        idGTM +
        '" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
    );
    newDiv.appendChild(newContent);
    document.body.appendChild(newDiv);
  }, []);

  return (
    <div data-testid="google-tag-manager">
      {type}
      <Helmet script={tags} />
    </div>
  );
};

export default GTM;
