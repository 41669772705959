import { mergeMap, catchError } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "lib/app";
import User from "lib/models/user.model";
import UpdateUserDataActions, { UpdateUserDataTypes } from "./reducer";
import UserActions from "redux/reducers/user.reducer";
import { UpdateUserDataSerializer } from "lib/serializers/updateUserData.serializer";

const maxAttempsPerDay = "2002";
const nameNoyCoincidence = "2000";
const allowedCodeCiiu = "2004";

export const fetchUpdateUserData = (action$: any) =>
  action$.pipe(
    ofType(UpdateUserDataTypes.START_FETCH_UPDATE_USER_DATA),
    mergeMap((action: any) => {
      const { api }: any = App;
      return Observable.from(
        api.a2censo.updateUserData({
          body: UpdateUserDataSerializer().serialize(action.payload)
        })
      ).pipe(
        mergeMap(() =>
          Observable.concat(
            Observable.of(
              UpdateUserDataActions.endFetchUpdateUserDataSuccess()
            ),
            Observable.of(UserActions.setUpdateDataUser(action.payload))
          )
        ),
        catchError((e) => {
          if (e?.errors?.length > 0) {
            const error = e.errors[0];
            if (error.code === maxAttempsPerDay) {
              return Observable.concat(
                Observable.of(
                  UpdateUserDataActions.endFetchUpdateUserDataError(
                    User.UpdateUserState.maxAttempsPerDay
                  )
                ),
                Observable.of(UserActions.setAttemptsUser(false))
              );
            } else if (error.code === nameNoyCoincidence) {
              return Observable.of(
                UpdateUserDataActions.endFetchUpdateUserDataError(
                  User.UpdateUserState.nameNotCoincidence
                )
              );
            } else if (error.code === allowedCodeCiiu) {
              return Observable.of(
                UpdateUserDataActions.endFetchUpdateUserDataError(
                  User.UpdateUserState.allowedCodeCiiu
                )
              );
            }
          }
          return Observable.of(
            UpdateUserDataActions.endFetchUpdateUserDataError(
              User.UpdateUserState.apiError
            )
          );
        })
      );
    })
  );

export default combineEpics(fetchUpdateUserData);
