import styled from "styled-components";

export const Container = styled.div`
  margin: 5% 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  display: flex;
  width: 80%;
  margin-left: 15px;
  height: 60px;
  border-radius: 10px;
  border: none;
  padding: 0 10px;
  font-size: 19px;
  font-family: "Roboto";
  padding-left: 40px;
  ${(props) => props.theme.smartphone} {
    width: 77%;
    font-size: 14px;
    padding-left: 20px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}%` : "47%")};
  border-radius: 10px;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 9px 0;
  position: relative;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
`;

export const Button = styled.button`
  border: none;
  background-color: ${(props) => props.theme.white};
  border-radius: 25px;
  height: 50px;
  width: 150px;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: bold;
  color: ${(props) => props.theme.brightTurquoise};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const CleanContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
`;

export const CleanLink = styled.p`
  align-items: center;
  width: 115px;
  height: 22px;
  color: ${(props) => props.theme.brightTurquoise};
  font-family: "Nunito";
  font-size: 14px;
  line-height: 20px;
  padding-left: 16px;
  text-decoration: underline;
  justify-content: flex-start;
  cursor: pointer;
  margin: 0;
`;

export const Select = styled.div`
  cursor: pointer;
  height: 60px;
  background-color: ${(props) => props.theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border-radius: 10px;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.img`
  width: ${(props) => (props.width ? `${props.width}px` : "30px")};
  padding: ${(props) => (props.customPadding ? props.customPadding : "")};
`;

export const IconText = styled.span`
  font-family: "Roboto";
  margin-left: 5px;
  margin-right: 40px;
  font-size: 16px;
  color: #7e7d7b;
  ${(props) => props.theme.smartphone} {
    display: none;
  }
`;

export const SelectContent = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  position: absolute;
  top: 55px;
  left: 25px;
  padding: 20px 30px;
  z-index: 9;
  flex-direction: column;
  background: ${(props) => props.theme.white};
  cursor: default;
`;

export const RadioButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const Radio = styled.div`
  border: 2px solid ${(props) => props.theme.brightTurquoise};
  border-radius: 20px;
  width: 20px;
  height: 20px;
  margin-right: 20px;
  ${(props) =>
    props.check
      ? `background: ${props.theme.brightTurquoise};`
      : `background: ${props.theme.white};`}
`;

export const RadioText = styled.span`
  font-size: 15px;
  color: ${(props) => props.theme.darkGray};
`;

export default {
  Container
};
