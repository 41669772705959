import styled from "styled-components";

type Props = {
  hasChildren: boolean;
  downloadOnly: boolean;
};

export const ContainerInformation = styled.div<Props>`
  ${({ hasChildren }) => hasChildren && "margin-right: 20px;"}
  & > p {
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    line-height: 21px;
  }
  ${({ downloadOnly }) => (downloadOnly ? "width: 70%;" : "width: 50%")}
`;
