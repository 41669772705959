/* eslint-disable no-console */
import React from "react";
import "./SimpleTextBullet.styles.scss";

type IProps = {
  campaignDetail: Array<{
    title?: string;
    content?: string;
  }>;
};

const SimpleTextBullet = ({ campaignDetail }: IProps) => (
  <div className="simple-text-bullet-container">
    <div className="simple-text-bullet-grid">
      {campaignDetail?.length > 0 &&
        campaignDetail?.map(({ content, title }, i) => (
          <div key={i} className="simple-text-bullet-content-container">
            <div className="simple-text-bullet-title-container">
              <p className="simple-text-bullet-title">{title}</p>
            </div>
            <p className="simple-text-bullet-content">{content}</p>
          </div>
        ))}
    </div>
  </div>
);

export default SimpleTextBullet;
