const { Serializer } = require("jsonapi-serializer");

module.exports = {
  SelfAppraisalSerializer: (meta = {}) =>
    new Serializer("self_appraisal", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["categoryQuestionId", "responseId", "answerText"]
    })
};
