import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 15px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 140px;
  padding: 10px 10px 30px;
  ${(props) => props.theme.tablet} {
    padding: 10px 10px;
    height: 110px;
  }
  ${(props) => props.theme.smartphone} {
    padding: 10px 10px;
    height: 110px;
  }
  cursor: pointer;
  width: 100%;
  background: ${(props) => props.theme.gray};
  border-radius: 8px;

  ${(props) =>
    !props.enableItem
      ? `
    opacity: 60%;
    pointer-events:none;
  `
      : ""};
`;

export const TextItem = styled.div`
  display: block;
  flex-direction: row;
  height: 100%;
  text-align: left;
  width: 45%;
  padding-right: 12px;
`;

export const AmountItem = styled.div`
  display: grid;
  flex-direction: row;
  height: 100%;
  width: 50%;
  text-align: right;
  padding-top: 10px;
  padding-right: 10px;
  ${(props) => props.theme.smartphone} {
    width: 60%;
    padding-top: 13px;
  }
`;

export const SubCaptionText = styled.label`
  font-family: "Roboto";
  color: ${(props) => props.theme.blueDark};
  height: 40px;
  width: 145px;
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 30px;
  ${(props) => props.theme.smartphone} {
    font-size: 16px;
    line-height: 20px;
  }
  font-weight: bold;
`;

export const CaptionText = styled.p`
  font-family: "Roboto";
  min-height: 28px;
  min-width: 115px;
  color: ${(props) => props.theme.grayBright};
  font-size: 13px;
  line-height: 18px;
  ${(props) => props.theme.smartphone} {
    font-size: 10px;
    line-height: 14px;
  }
`;

export const SubCaptionAmount = styled.label`
  font-family: "Roboto";
  color: ${(props) => props.theme.blueDark};
  height: 0px;
  align-items: right;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  ${(props) => props.theme.smartphone} {
    font-size: 10px;
    line-height: 16px;
  }
  @media screen and (min-width: 769px) and (max-width: 896px) {
    font-size: 11px;
  }
`;

export const CaptionAmount = styled.label`
  font-family: "Nunito";
  color: ${(props) => props.theme.blueDark};
  height: 22px;
  line-height: 30px;
  font-weight: bold;
  text-align: right;
  line-height: 22px;
  font-size: ${({ valueLength }) =>
    valueLength >= 12 ? `${35 - valueLength}px` : "27px"};
  ${(props) => props.theme.tablet} {
    font-size: ${({ valueLength }) =>
      valueLength >= 12 ? `${31 - valueLength}px` : "22px"};
  }
  ${(props) => props.theme.smartphone} {
    font-size: ${({ valueLength }) =>
      valueLength >= 12 ? `${30 - valueLength}px` : "22px"};
  }
  @media screen and (min-width: 769px) and (max-width: 915px) {
    font-size: ${({ valueLength }) =>
      valueLength >= 12 ? `${29 - valueLength}px` : "19px"};
  }
  @media screen and (max-width: 327px) {
    font-size: ${({ valueLength }) =>
      valueLength >= 12 ? `${29 - valueLength}px` : "16px"};
  }
`;

export const RadioButton = styled.div`
  background: ${(props) =>
    props.checked
      ? "url(/assets/images/radio-on.png)"
      : "url(/assets/images/radio-off.png)"};
  background-size: cover;
  width: 22px;
  height: 22px;

  ${(props) => props.theme.smartphone} {
    width: 18px;
    height: 18px;
  }
`;

export const InputContainer = styled.div`
  padding-top: 25px;
`;

export const RadioContainer = styled.div`
  padding-right: 5px;

  ${(props) => props.theme.desktop} {
    padding-right: 0px;
  }
`;

export const Date = styled.div`
  display: inline;
  flex-direction: column;
  width: 100%;
  padding: 30px 12px 20px 12px;
  font-family: "Roboto";
  font-size: 15px;
  line-height: 18px;

  ${(props) => props.theme.smartphone} {
    max-width: 465px;
    width: 100%;
    padding: 30px 12px 12px 12px;
    font-size: 10px;
    line-height: 12px;
  }
`;

export const SpanDate = styled.span`
  font-weight: bold;
`;
