import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setInfo: ["info"],
  fetchCompanyInfo: []
});

export default Creators;

export const INITIAL_STATE = {
  info: {
    whatDoYourCompanyDo: "",
    whatAreYourBusinessLines: "",
    whoAreYourMainClients: "",
    whoAreYourCompetition: "",
    whoAreYourMainSuppliers: "",
    whatIsTheAddValueFromYourCompany: "",
    tellUsHowYouFinanceYourCompany: "",
    tellUsYourFuturePlan: "",
    partners: [],
    ceo: {
      name: "",
      document: "",
      rol: "Gerente general",
      partnerProfession: "",
      partnerCareer: ""
    },
    commercialDirector: {
      name: "",
      document: "",
      rol: "Director comercial",
      partnerProfession: "",
      partnerCareer: ""
    },
    cfo: {
      name: "",
      document: "",
      rol: "Director financiero",
      partnerProfession: "",
      partnerCareer: ""
    },
    taxAuditor: {
      name: "",
      professional_card: "",
      rol: "Auditor fiscal",
      partnerProfession: "",
      partnerCareer: ""
    },
    others: []
  },
  isFetching: false
};

const setCompanyInfo = (state, { info }) => ({
  ...state,
  info,
  isFetching: false
});

const getCompanyInfo = (state) => ({
  ...state,
  isFetching: true
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_INFO]: setCompanyInfo,
  [Types.FETCH_COMPANY_INFO]: getCompanyInfo
});
