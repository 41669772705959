import { catchError, flatMap, map } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import newsActions, { newsTypes } from "../reducers/news.reducer";

export const getNews = (action$) =>
  action$.pipe(
    ofType(newsTypes.GET_NEWS),
    flatMap(() =>
      Observable.from(App.api.a2censo.getNewsList({})).pipe(
        map((response) => newsActions.setNews(response)),
        catchError((e) => Observable.concat(Observable.of(push("/oops", e))))
      )
    )
  );

export default combineEpics(getNews);
