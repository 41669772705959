import styled from "styled-components";

export const Container = styled.div`
  width: 828px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px 0 20px;
  ${(props) => props.theme.smartphone} {
    padding: 10px 0 10px;
    margin: 0 5%;
  }
  border-radius: 10px;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  align-self: flex-end;
  margin-right: 30px;
  ${(props) => props.theme.smartphone} {
    margin-right: 10px;
  }
`;

export const Icon = styled.img`
  width: 30px;
  height: 30px;
  ${(props) => props.theme.smartphone} {
    width: 25px;
    height: 25px;
  }
`;

export const IconTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  ${(props) => props.theme.smartphone} {
    margin-top: 15px;
  }
`;

export const IconTitle = styled.img`
  width: 100px;
  height: 100px;
  ${(props) => props.theme.smartphone} {
    width: 60px;
    height: 60px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 60%;
  font-size: 36px;
  line-height: 40px;
  ${(props) => props.theme.smartphone} {
    font-size: 20px;
    line-height: 22px;
  }
`;

export const Paragraph = styled.div`
  font-size: 23px;
  line-height: 32px;
  margin-bottom: 40px;
  max-width: 55%;
  height: 114px;
  ${(props) => props.theme.smartphone} {
    max-width: 80%;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 40px;
  }
`;

export const MyPayment = styled.span`
  text-decoration: underline;
  color: ${({ theme }) => theme.brightTurquoise};
  cursor: pointer;
`;

export const Company = styled.span`
  font-weight: bold;
`;
