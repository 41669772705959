import styled from "styled-components";
import { Paragraph } from "@bvcco/a2censo-component-lib";

export const CompanyTaxFormContainer = styled.form`
  p {
    font-family: ${(props) => props.theme.font.family.secondary};
  }
`;

export const CompanyTaxFormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  & > .bank-type > div > div {
    & > div {
      overflow-y: hidden;
    }
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    display: flex;
    grid-template-columns: none;
    flex-wrap: wrap;
    grid-gap: 0px;
  }
`;

export const CompanyTaxFormColumn = styled.div`
  display: block;

  ${({ theme }) => theme.breakpoints.mobile} {
    width: 100%;
    margin-bottom: 20px;
  }

  input {
    height: 22px;
    font-size: ${(props) => props.theme.font.size.less_small};
    font-family: ${(props) => props.theme.font.family.secondary};
  }

  label {
    color: ${(props) => props.theme.cornFlower};
    font-family: ${(props) => props.theme.font.family.secondary};
  }

  #bank {
    width: 100%;
  }

  #bankAccountType {
    width: 100%;
  }

  .input__tooltip-logo-container {
    display: inline-block;
    width: auto;
  }

  > div > div {
    width: 100%;
    position: relative;
    & li {
      width: 100%;
    }
  }
`;

export const CompanyTaxFormLabelSpace = styled.div`
  height: 10px;
`;

export const StyledParagraph = styled(Paragraph)`
  line-height: 25px;
  margin-top: 2px;
  margin-bottom: 20px;
`;

export const StyledSubLabel = styled(Paragraph)`
  line-height: 17.58px;
  font-weight: 400;
  font-size: 14px;
  margin-top: 8px;
  color: ${(props) => props.theme.blackLabelTax};
  font-family: ${(props) => props.theme.font.family.primary};
`;

export const Description = styled.div`
  p {
    color: ${(props) => props.theme.stormGray};
  }
`;

export const ContainerSubtitle = styled.div`
  display: flex;
  align-items: center;
  & > h3 {
    margin-right: 18px;
    width: 450px;
    font-size: 16px;
    font-weight: 700;
  }
`;
export const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.acentuar};
  height: 1px;
  width: 100%;
`;

export const ContentCheckLabel = styled.div`
  padding: 20px 0px;
  display: flex;
  align-items: center;
  .checkbox-icon > svg {
    width: 12px;
  }
`;

export const LabelCheck = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.riverBed};
  font-family: ${({ theme }) => theme.font.family.primary};
`;
