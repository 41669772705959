import styled from "styled-components";

import { Button as btn } from "@bvcco/a2censo-component-lib";

export const Wrapper = styled.div`
  width: 754px;
  max-width: 90%;
  ${(props) => props.theme.tablet} {
    width: 605px;
  }
  position: fixed;
`;

export const Card = styled.div`
  background-color: ${(props) => props.theme.lightGray};
  border-radius: 10px;
  position: relative;
`;

export const SuperHeader = styled.div`
  color: ${(props) => props.theme.white};
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
  ${(props) => props.theme.tablet} {
    margin-bottom: 34px;
    & > h2 {
      font-size: ${(props) => props.theme.font.size.large};
    }
  }
`;

export const CloseButton = styled.img`
  width: 29px;
  height: 29px;
  cursor: pointer;
`;

export const Body = styled.div`
  overflow-y: scroll;
  height: 400px;

  ${(props) => props.theme.desktopMinHeight800px} {
    height: 600px;
  }
`;

export const Padding = styled.div`
  margin: 0px 90px;
  ${(props) => props.theme.tablet} {
    margin: 0px 19px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InfoContainer = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${(props) => props.theme.tablet} {
    justify-content: space-between;
  }

  .mobile-hidden {
    ${(props) => props.theme.tablet} {
      display: none;
    }
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled(Row)`
  cursor: pointer;
  height: auto;
  box-shadow: 0 14px 21px -25px rgba(0, 0, 0, 0.5);

  ${(props) => props.theme.smartphone} {
    height: auto;
  }
`;

export const LeftHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 130px;
  border-radius: 8px 0 0 0;
  background-color: ${(props) => props.theme.colors.simulator.bgColor};
  z-index: 1;

  ${(props) => props.theme.smartphone} {
    height: initial;
    position: absolute;
    width: initial;
    padding: 2px 6px;
    top: -10px;
    line-height: initial;
    border-radius: 6px;
    left: 26px;
  }
`;

export const RightHeader = styled.div`
  background-color: white;
  width: 100%;
  border-radius: 8px;
  padding: 16px 95px 14px 25px;
  position: relative;

  ${(props) => props.theme.smartphone} {
    padding: 22px 26px 15px 26px;
  }
`;

export const RightHeaderDetailWrapper = styled.div`
  display: flex;
  margin-top: 4px;
`;

export const RightHeaderDetail = styled.div`
  margin-right: 52px;
`;

export const RightHeaderDetailLabel = styled.div`
  & > p {
    color: ${({ theme }) => theme.tuatara};
    opacity: 0.64;
  }

  ${(props) => props.theme.smartphone} {
    & > p {
      font-size: ${({ theme }) => theme.font.size.smaller};
    }
  }

  margin-top: 5px;
  margin-bottom: 2px;
`;

export const RightHeaderDetailValue = styled.div`
  & > p {
    color: ${({ theme }) => theme.tuatara};
    opacity: 0.64;
  }

  ${(props) => props.theme.smartphone} {
    & > p {
      font-size: ${({ theme }) => theme.font.size.less_small};
    }
  }
`;

export const CampaignTitle = styled.div`
  & > h3 {
    color: ${({ theme }) => theme.tuatara};
    max-width: 520px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${(props) => props.theme.smartphone} {
    & > h3 {
      font-size: ${({ theme }) => theme.font.size.less_small};
      white-space: initial;
      text-overflow: initial;
    }
  }
`;

export const InfoCol = styled(Col)`
  margin-top: 31px;
  width: 33.3%;
  ${({ theme }) => theme.smartphone} {
    width: 50%;
  }
`;

export const InfoColHalfWidth = styled(InfoCol)`
  width: 33.3%;

  ${(props) => props.theme.smartphone} {
    width: 50%;
  }
`;

export const InfoColHalfWidthRight = styled(InfoColHalfWidth)`
  ${(props) => props.theme.smartphone} {
    align-items: flex-start;
  }
`;

export const InfoColFullWidth = styled(InfoCol)`
  width: 33.3%;
  ${(props) => props.theme.smartphone} {
    width: 50%;
  }
`;

export const BackButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
`;

export const Icon = styled.img`
  width: 15px;
  height: 10px;
`;

export const Input = styled.input`
  height: 60px;
  outline: none;
  border: none;
  width: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-left: 10px;
  font-size: ${(props) => props.theme.font.size.less_small};
  color: ${(props) => props.theme.darkGray};
  font-family: ${(props) => props.theme.font.family.primary};

  ${(props) => props.theme.tablet} {
    font-size: ${(props) => props.theme.font.size.smaller};
  }
`;

export const InputContainer = styled(Col)`
  position: relative;

  ${(props) => props.theme.tablet} {
    margin-top: 12px;
    margin-bottom: 22px;
  }
`;

export const Currency = styled.div`
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  font-size: 23px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  &::after {
    content: "";
    height: 80%;
    width: 1px;
    display: block;
    background-color: ${(props) => props.theme.brightTurquoise};
    margin-left: 10px;
  }

  ${(props) => props.theme.tablet} {
    font-size: 16px;
  }
`;

export const ResultContainer = styled.div`
  margin: 0px 90px;
  margin-top: 21px;
  margin-bottom: 16px;
  background-color: #e1f4fe;
  border-radius: 9px;
  display: flex;
  flex-direction: row;
  padding: 19px 34px;
  ${(props) => props.theme.tablet} {
    margin: 0px 28px;
    margin-bottom: 20px;
    padding: 19px 16px;
    width: 90%;
    margin: 0 auto;
  }
`;

export const Label = styled.h5`
  margin: 0;
  margin-bottom: 10px;
  ${(props) => props.theme.smartphone} {
    & > h4 {
      font-size: ${(props) => props.theme.font.size.small};
    }
  }
`;

export const Amount = styled.div`
  & > p {
    color: ${(props) => props.theme.comet};
  }

  ${(props) => props.theme.smartphone} {
    & > p {
      font-size: ${(props) => props.theme.font.size.h2};
      color: ${(props) => props.theme.martinique};
    }
  }
`;

export const SmallLabel = styled.div`
  margin: 10px 0px;
  & > p {
    color: ${(props) => props.theme.colors.primary2.color};
  }
  ${(props) => props.theme.smartphone} {
    & > p {
      font-size: ${(props) => props.theme.font.size.smaller};
    }
  }
`;

export const SmallAmount = styled.div`
  & > p {
    font-family: ${(props) => props.theme.font.family.secondary};
    color: ${(props) => props.theme.colors.fiord.color};
    font-weight: 600;
  }

  ${(props) => props.theme.smartphone} {
    & > p {
      font-size: ${(props) => props.theme.font.size.less_small};
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 2px;
  background: rgba(186, 189, 202, 0.53);
  margin-bottom: 20px;
  margin-top: 10px;
`;

export const InfoValue = styled.div`
  & > p {
    color: ${(props) => props.theme.colors.fiord.color};
    line-height: 25px;
    font-weight: 800;
    font-style: normal;
  }

  margin-top: 4px;
  ${(props) => props.theme.smartphone} {
    & > p {
      font-size: ${(props) => props.theme.font.size.medium};
    }
  }
`;

export const InfoLabel = styled.div`
  & > p {
    color: ${(props) => props.theme.colors.primary2.color};
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
  }
  line-height: 19px;
  ${(props) => props.theme.smartphone} {
    & > p {
      font-size: ${(props) => props.theme.font.size.smaller};
    }
  }
  

  #interestGracePeriod > p {
    height: 35px;
    margin-right: 47px;
  }
`;

export const InputLabel = styled.label`
  & > h4 {
    font-family: ${(props) => props.theme.font.family.secondary};
  }
  margin-bottom: 10px;
  margin-top: 46px;
  ${(props) => props.theme.smartphone} {
    margin-bottom: 10px;
    margin-top: 16px;

    & > h4 {
      font-size: ${(props) => props.theme.font.size.less_small};
    }
  }
`;

export const ErrorIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 33px;
  width: 20px;
  height: 20px;
  ${(props) => props.theme.smartphone} {
    top: 15px;
  }
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 6px;
  align-items: flex-end;
  background: transparent;
`;

export const TooltipText = styled.p`
  color: ${(props) => props.theme.red} !important;
  font-family: Roboto;
  font-size: 11px !important;
  margin: 2px !important;
  text-align: right;
  ${(props) => props.theme.smartphone} {
    text-align: center;
  }
`;

export const DownArrow = styled.img`
  position: absolute;
  height: 11px;
  width: 17px;
  right: 38px;
  top: 45%;

  ${(props) => props.theme.smartphone} {
    right: 28px;
  }
`;

export const RecieveContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid rgba(186, 189, 202, 0.53);
  margin: 0 0 20px 0;
  ${(props) => props.theme.smartphone} {
    width: 97% !important;
    margin: 0 auto 20px auto;
  }
  & > div:last-child {
    border-left: 2px solid rgba(186, 189, 202, 0.53);
    padding-left: 16px;
  }
  ${(props) => props.theme.tablet} {
    flex-direction: column;
    border: 0px;
    & > div {
      padding: 0 0 10px 0;
      border-bottom: 1px solid ${(props) => props.theme.colors.darkGray.bgColor};
    }
    & > div:last-child {
      border-left: 0px;
      padding-left: 0px;
    }
  }
`;

export const RecieveCol = styled.div`
  width: 50%;
  padding: 0 0 9px 0;
  ${(props) => props.theme.tablet} {
    width: 100%;
  }
`;

export const ButtonPanel = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
  margin-bottom: 23px;
  /* border-bottom: 2px dashed rgba(186, 189, 202, 0.53); */
  border-radius: 14px;
  box-sizing: border-box;

  #invest_campaign {
    width: 164px;
    height: 49px;
    background: #02BAC6;
    mix-blend-mode: normal;
    border-radius: 48px;
    border: 2px solid #02BAC6;
  }

  #detail_campaign {
    width: 224px;
    margin-right: 20px;
    height: 49px;
  }

  & > button > div > div {
    width: 15px;
    height: 15px;
  }

  & > button > div > div > img {
    width: 100%;
  }

  ${(props) => props.theme.tablet} {
    margin-bottom: 29px;

    & > button {
      font-size: ${(props) => props.theme.font.size.less_small};
    }

    & #invest_campaign {
      width: 126px;
      height: 39px;
      background: #02BAC6;
      mix-blend-mode: normal;
      border-radius: 48px;
      border: 2px solid #02BAC6;
    }

    & #detail_campaign {
      height: 39px;
    }

    width: 100%;
  }
`;

export const SeeVideoPanel = styled.div`
  margin-bottom: 55px;
  display: flex;
  justify-content: flex-end;

  .see_video_text {
    color: ${(props) => props.theme.abbey};
  }

  ${(props) => props.theme.smartphone} {
    justify-content: flex-start;
    & > p {
      font-size: ${(props) => props.theme.font.size.medium};
    }
  }
  ${(props) => props.theme.smallSmartphone} {
    justify-content: center;

    > p {
      font-size: 13px;
    }
  }
`;

export const LinkSeeVideo = styled.a`
  text-decoration: none;
  cursor: pointer;

  & > p {
    ${(props) => props.theme.smallSmartphone} {
      font-size: ${(props) => props.theme.font.size.medium};
    }
    ${(props) => props.theme.smartphone} {
      font-size: ${(props) => props.theme.font.size.less_small};
    }
  }
`;

export const Hr = styled.hr`
  border: 0;
  border-top: 2px dotted #979797;
  margin: 20px 0 23px 0;
`;

export const Button = styled(btn)`
  ${(props) => props.theme.smartphone} {
    font-size: 12px !important;
    padding: 8px 6px;
  }
`;
