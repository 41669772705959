import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  DownloadFileContainer,
  IconFile,
  Text,
  Button,
  IconArrow
} from "./Styles";
import downloadFile from "../../lib/utils/downloadFile";

const DownloadFile = ({ fileName, fileUrl, id, testId }) => {
  const { t } = useTranslation();

  const onClick = () => {
    downloadFile(fileUrl, false);
  };

  return (
    <DownloadFileContainer>
      <IconFile src="/assets/images/excel-image@3x.png" alt="download file" />
      <Text>{fileName}</Text>
      <Button id={id} data-testId={testId} onClick={onClick}>
        {t("downloadFile.buttonName")}
        <IconArrow src="/assets/images/download.png" />
      </Button>
    </DownloadFileContainer>
  );
};

DownloadFile.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  id: PropTypes.string,
  testId: PropTypes.string
};

DownloadFile.defaultProps = {
  id: "button-dowload-file",
  testId: "button-dowload-file-test-id"
};
export default DownloadFile;
