import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Container,
  Title,
  Subtitle,
  BottomButtonContainer,
  Button
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";
import { startLogoutFlow } from "../../redux/epics/login";
import {
  SHAREHOLDER_ERROR,
  RRHH_ERROR_CODES,
  ErrorValidateCIIUInvalid
} from "../../lib/utils/constants.errors";

const OtherErrorModal = ({
  title,
  content,
  linkRedirectsTo,
  doReturn,
  buttonText,
  code
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const redirect = () => {
    if (!linkRedirectsTo) {
      return;
    }

    dispatch(push(linkRedirectsTo));
    doReturn(linkRedirectsTo);
  };

  const handleReturn = () => {
    dispatch(ModalActions.setConditionalModalState(false, "OtherErrorModal"));
    if (
      SHAREHOLDER_ERROR.includes(code) ||
      RRHH_ERROR_CODES.includes(code) ||
      ErrorValidateCIIUInvalid === code
    ) {
      dispatch(startLogoutFlow());
    } else {
      redirect();
    }
  };

  const getLabelBack = () => {
    if (SHAREHOLDER_ERROR.includes(code) || RRHH_ERROR_CODES.includes(code)) {
      return "errorModal.success";
    }
    return buttonText;
  };

  return (
    <Container>
      <Title>{t(title)}</Title>
      <Subtitle
        data-testid="generic-error-modal-content"
        id="generic-error-modal-content"
      >
        {t(content)}
      </Subtitle>
      <Subtitle
        data-testid="generic-error-modal-content-tryLater"
        id="generic-error-modal-content-tryLater"
      >
        <b>{t("errorModal.tryLater")}</b>
      </Subtitle>
      <BottomButtonContainer>
        <Button data-testid="error-modal-return" onClick={handleReturn}>
          {t(getLabelBack())}
        </Button>
      </BottomButtonContainer>
    </Container>
  );
};

OtherErrorModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  linkRedirectsTo: PropTypes.string.isRequired,
  doReturn: PropTypes.func,
  buttonText: PropTypes.string,
  code: PropTypes.string
};

OtherErrorModal.defaultProps = {
  buttonText: "legalRepresentative.return",
  code: "",
  doReturn: () => {}
};

export default OtherErrorModal;
