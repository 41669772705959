import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Heading, Paragraph } from "@bvcco/a2censo-component-lib";
import {
  CampaignItem,
  CampaignItemHeader,
  CampaignItemBody,
  CampaignItemCol,
  CampaignItemColHide,
  CampaignItemColName,
  CampaignItemColValue,
  RightArrow
} from "./Styles";
import campaignModel from "../../lib/models/campaign.model";

const SimulatorCampaignItem = ({ campaign, onSelect }) => {
  const { t } = useTranslation();

  const select = () => {
    onSelect(campaign);
  };

  return (
    <CampaignItem
      data-testid={`campaign-item${campaign.campaignId}`}
      onClick={select}
    >
      <CampaignItemHeader>
        <Heading hieranchy="quaternary" fontWeight="bold">
          {campaign.name}
        </Heading>
      </CampaignItemHeader>
      <CampaignItemBody>
        <CampaignItemCol>
          <CampaignItemColName>
            <Paragraph size="small">
              {t("simulator.listCampaign.rate")}
            </Paragraph>
          </CampaignItemColName>
          <CampaignItemColValue>
            <Paragraph size="medium" fontWeight="bold">
              {`${campaign.interestRate || 0}% ${t("simulator.ea")}`}
            </Paragraph>
          </CampaignItemColValue>
        </CampaignItemCol>
        <CampaignItemCol>
          <CampaignItemColName>
            <Paragraph size="small">
              {t("simulator.listCampaign.term")}
            </Paragraph>
          </CampaignItemColName>
          <CampaignItemColValue>
            <Paragraph size="medium" fontWeight="bold">
              {`${campaign.termInMonths || "-"} ${t("simulator.months")}`}
            </Paragraph>
          </CampaignItemColValue>
        </CampaignItemCol>
        <CampaignItemColHide>
          <CampaignItemColName>
            <Paragraph size="small">{t("simulator.interestPaymnet")}</Paragraph>
          </CampaignItemColName>
          <CampaignItemColValue>
            <Paragraph size="medium" fontWeight="bold">
              {campaignModel.paymentFrequency(campaign.interestPayment)}
            </Paragraph>
          </CampaignItemColValue>
        </CampaignItemColHide>
        <CampaignItemColHide>
          <CampaignItemColName>
            <Paragraph size="small">{t("simulator.capitalPayment")}</Paragraph>
          </CampaignItemColName>
          <CampaignItemColValue>
            <Paragraph size="medium" fontWeight="bold">
              {campaignModel.paymentFrequency(campaign.capitalPayment)}
            </Paragraph>
          </CampaignItemColValue>
        </CampaignItemColHide>
      </CampaignItemBody>
      <RightArrow src="/assets/images/chevron-right.png" />
    </CampaignItem>
  );
};

SimulatorCampaignItem.propTypes = {
  campaign: PropTypes.shape({
    campaignId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    termInMonths: PropTypes.number.isRequired,
    interestRate: PropTypes.number.isRequired,
    interestPayment: PropTypes.number.isRequired,
    capitalPayment: PropTypes.number.isRequired
  }).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default SimulatorCampaignItem;
