import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Container,
  Paragraph,
  Button,
  CloseButton,
  Icon,
  Title,
  IconModal
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";

const SimulatorNotAvailableModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Container>
      <CloseButton
        data-testid="simulator-back-button"
        onClick={() => dispatch(ModalActions.setConditionalModalState(false))}
      >
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <IconModal alt="shape" src="/assets/images/Shape.png" />
      <Title>{t("simulatorNotAvailable.title")}</Title>
      <Paragraph>{t("simulatorNotAvailable.subtitle")}</Paragraph>
      <Button
        data-testid="simulator-understood-button"
        onClick={() => dispatch(ModalActions.setConditionalModalState(false))}
      >
        {t("simulatorNotAvailable.buttom")}
      </Button>
    </Container>
  );
};

export default SimulatorNotAvailableModal;
