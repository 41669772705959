import React from "react";
import { Paragraph } from "../Paragraph";
import { useTranslation } from "react-i18next";
import {
  BottomContent,
  BottomItem,
  BottomItemRow,
  State,
  CompleteIcon,
  Icon
} from "./components";
import calendarIcon from "assets/images/calendar-icon.png";
import completeIcon from "assets/images/campaignComplete-icon.png";
import favIcon from "assets/images/fav-icon.png";
import { types } from "./types";
import { formatAmountMoney, daysToClose } from "lib/formatter";

interface Props {
  type: types;
  percentage: number;
  requestAmount: number;
  investorsNumber: number;
  closeDate: string;
  investmentAmount: number;
  state: number;
}

export const CampaignCardBottom = ({
  type = types.Primary,
  percentage,
  investmentAmount,
  investorsNumber,
  closeDate,
  requestAmount,
  state
}: Props) => {
  const { t } = useTranslation();
  const titles = {
    collected: t("sliderCampaings.titles.collected"),
    collectedAmount: t("sliderCampaings.titles.collectedAmount"),
    investments: t("sliderCampaings.titles.investments"),
    days: t("sliderCampaings.titles.days"),
    goal: t("sliderCampaings.titles.goal"),
    goalAccomplished: t("sliderCampaings.titles.goalAccomplished")
  };
  return (
    <BottomContent type={type}>
      {[types.Primary, types.Secondary].includes(type) && (
        <>
          <BottomItem gridArea="collected">
            <Paragraph size="small" color="white">
              {titles.collected}:
            </Paragraph>
            <Paragraph size="medium" color="white" fontWeight="bolder">
              {percentage?.toFixed(2).replace(".00", "")}%
            </Paragraph>
          </BottomItem>
          <BottomItem gridArea="goal">
            <Paragraph size="small" color="white" align="right">
              {titles.goal}:
            </Paragraph>
            <Paragraph
              size="medium"
              color="white"
              fontWeight="bolder"
              align="right"
            >
              {`COP ${formatAmountMoney(requestAmount)}M`}
            </Paragraph>
          </BottomItem>
        </>
      )}
      {type === types.Secondary && (
        <>
          <BottomItemRow gridArea="investments">
            <Icon src={favIcon} />
            <Paragraph size="small" color="white">
              {investorsNumber} {titles.investments}
            </Paragraph>
          </BottomItemRow>
          <BottomItemRow gridArea="days" align="end">
            <Icon src={calendarIcon} />
            <Paragraph size="small" color="white" align="right">
              {daysToClose(closeDate)} {titles.days}
            </Paragraph>
          </BottomItemRow>
          <BottomItem gridArea="collected_amount">
            <Paragraph size="small" color="white">
              {titles.collectedAmount}:
            </Paragraph>
            <Paragraph size="medium" color="white" fontWeight="bolder">
              {`COP ${formatAmountMoney(investmentAmount)}M`}
            </Paragraph>
          </BottomItem>
          <BottomItemRow gridArea="state" align="end">
            <Paragraph size="small" color="white" align="right">
              {t(`campaingStates.${state}`)}
            </Paragraph>
            <State />
          </BottomItemRow>
        </>
      )}
      {type === types.Tertiary && (
        <>
          <BottomItem gridArea="collected">
            <CompleteIcon src={completeIcon} />
          </BottomItem>
          <BottomItem gridArea="goal">
            <Paragraph size="small" color="white" align="right">
              {titles.goalAccomplished}
            </Paragraph>
            <Paragraph
              size="medium"
              color="white"
              fontWeight="bolder"
              align="right"
            >
              {`COP ${formatAmountMoney(investmentAmount)}M`}
            </Paragraph>
          </BottomItem>
        </>
      )}
    </BottomContent>
  );
};
