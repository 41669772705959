import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PageTitle = styled.h3`
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  font-size: 23px;
  color: ${({ theme }) => theme.tuatara};
  border-left: solid ${({ theme }) => theme.brightTurquoise} 10px;
  padding-left: 20px;
  margin-top: 134px;
  margin-bottom: 40px;
  ${({ theme }) => theme.smartphone} {
    font-size: 20px;
    margin-top: 70px;
  }
`;

export const Paragraph = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  p {
    font-family: "Roboto";
    color: ${({ theme }) => theme.tuatara};
    width: 70%;
    text-align: center;
    font-size: 17px;
    line-height: 24px;
  }
  ${(props) => props.theme.smartphone} {
    margin-bottom: 35px;
  }
`;

export const Anchor = styled.a`
  font-family: "Roboto";
  font-size: 14px;
  line-height: 25.2px;
  color: ${(props) => props.theme.brightTurquoise};
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  ${(props) => props.theme.smartphone} {
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 26px;
  margin-bottom: 56px;
  ${({ theme }) => theme.smartphone} {
    width: 100%;
    flex-direction: column;
    padding: 0 60px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  padding-right: 70px;
  padding-bottom: 16px;
  width: 350px;
  height: 104px;
  background-color: ${({ theme }) => theme.white};
  opacity: ${({ isLoading }) => (isLoading ? "0.4" : "1")};
  cursor: ${({ isLoading }) => (isLoading ? "normal" : "pointer")};
  border-radius: 10px;
  margin: 0px 15px;
  ${({ theme }) => theme.smartphone} {
    width: auto;
    margin: 10px 0;
    padding-left: 10px;
    padding-bottom: 16px;
  }
  span {
    font-family: "Nunito", sans-serif;
    font-weight: bold;
    font-size: 23px;
    color: ${({ theme }) => theme.tuatara};
    margin-left: 30px;
    margin-top: 20px;
    ${({ theme }) => theme.smartphone} {
      margin-left: 15px;
      font-size: 20px;
    }
  }
`;
