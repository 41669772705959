import { lazy } from "react";
import { IRoute } from "./types";

const Equity = lazy(() => import("pages/Equity/Equity"));

export const showcaseRoutes: IRoute[] = [
  {
    name: "Equity",
    description: "Vista informativa de que es equity",
    path: "/equity",
    component: Equity
  }
];
