import styled from "styled-components";

export const Item = styled.div`
  flex-direction: column;
  padding-left: 7px;
  padding-right: 7px;
  text-align: center;
  & p {
    line-height: 24px;
  }
`;
