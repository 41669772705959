import styled from "styled-components";

export const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  > div {
    margin-right: 15px;
    margin-bottom: 13px;
  }
`;
