import styled from "styled-components";

export const ContainerDescription = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > p {
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.midGray};
    font-weight: bold;
    margin-left: 25px;
    ${({ theme }) => theme.smartphone} {
      margin-left: 19px;
    }
  }
`;

export const DocumentIMG = styled.img`
  width: 32px;
  height: 32px;
`;
