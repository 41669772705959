module.exports = {
  formatLegalRepresentativeFormData: (legalRepresentative) => ({
    meta: {},
    data: {
      attributes: {
        document_number: legalRepresentative.document_number,
        document_type: Number(legalRepresentative.document_type),
        name: legalRepresentative.name,
        second_name: legalRepresentative.second_name,
        last_name: legalRepresentative.last_name,
        second_last_name: legalRepresentative.second_last_name,
        document_expedition_date: legalRepresentative.document_expedition_date,
        expedition_place: legalRepresentative.document_expedition_place,
        expedition_country: legalRepresentative.expedition_country,
        manage_public_resources: legalRepresentative.manage_public_resources,
        send_usa_resources: legalRepresentative.send_usa_resources,
        email: legalRepresentative.email,
        publicly_exposed_person: legalRepresentative.publicly_exposed_person,
        publicly_exposed_person_options: {
          selected_option: {
            public_figure_question_id: Number(
              legalRepresentative.public_figure_question_id
            )
          }
        },
        fatca: {
          selected_option: legalRepresentative.fatca_selected_option
        },
        public_figure_family_member:
          legalRepresentative.public_figure_family_member
      }
    }
  })
};
