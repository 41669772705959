import axios, { AxiosError, AxiosStatic, Method } from "axios";
import axiosRetry from "axios-retry";

type IRequest = {
  method: Method;
  service: string;
  headers?: Record<string, string>;
  params?: any;
  body?: any;
};

export class Connect {
  baseUrl: string;
  prefix: string;
  headers: object;
  axios: AxiosStatic;

  constructor(baseUrl: string, prefix: string, headers: object) {
    this.baseUrl = baseUrl;
    this.prefix = prefix;
    this.headers = headers;
    this.axios = axios;
    axiosRetry(axios, { retries: 3 });
  }

  async request({ method, service, headers, params, body }: IRequest) {
    try {
      return await this.axios({
        method: method,
        url: this.getUrl(service),
        headers: {
          ...this.headers,
          ...headers
        },
        params,
        data: body
      });
    } catch (error) {
      return Promise.reject({
        ...error.response.data,
        statusCode: error.response.status
      });
    }
  }

  getUrl(service: string) {
    return `${this.baseUrl}${this.prefix ? `/${this.prefix}` : ""}${
      service != "" ? `/${service}` : ""
    }`;
  }

  /**
   * Implementation required
   */
  handleError(error: Error | AxiosError) {
    throw error;
  }
}
