import styled from "styled-components";

type Props = {
  isOver: boolean;
};

export const MainContainer = styled.div<Props>`
  position: relative;
  max-height: 90vh;
  overflow-y: ${({ isOver }) => (isOver ? "hidden" : "auto")};
  &::-webkit-scrollbar {
    display: none;
  }
`;
