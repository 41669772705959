import * as Yup from "yup";

const schema = {
  password: Yup.string()
    .trim()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*/%.\-_()+])(?=.{8,})/,
      {
        message: "forgotPassword.validations.password.matches",
        excludeEmptyString: true
      }
    )
    .required("forgotPassword.validations.password.required"),
  confirmPassword: Yup.string()
    .trim()
    .min(2, "forgotPassword.validations.confirmPassword.min")
    .max(50, "forgotPassword.validations.confirmPassword.max")
    .oneOf(
      [Yup.ref("password"), null],
      "forgotPassword.validations.confirmPassword.oneOf"
    )
    .required("forgotPassword.validations.confirmPassword.required")
};
export default Yup.object().shape(schema);
