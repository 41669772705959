import { catchError, flatMap } from "rxjs/operators";
import { push } from "connected-react-router";
import { ofType, combineEpics } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import LoginActions from "../reducers/login.reducer";
import ModalActions from "../reducers/modal.reducer";
import { getTransactionSerializer } from "../../lib/serializers/invest.serializer";
import { InvestmentConnect } from "services/investment";
export const startTransactionConfirmation = (transactionId) => ({
  type: "START_TRANSACTION_CONFIRMATION",
  payload: { transactionId }
});

// Transaction fail
export const transactionDataFail = ({ error }) => ({
  type: "TRANSACTION_DATA_FAIL",
  payload: {
    error
  }
});

export const transactionConfirmation = (action$) =>
  action$.pipe(
    ofType("START_TRANSACTION_CONFIRMATION"),
    flatMap((action) => {
      const url = {
        id: action.payload.transactionId
      };
      const { user } = App.redux.store.getState();
      return Observable.from(
        new InvestmentConnect().getTransaction(
          getTransactionSerializer({
            transaction: action.payload.transactionId,
            person_type: user.userInfo.user.person_type
          })
        )
      ).pipe(
        flatMap((response) => {
          if (response && response.data.length) {
            return Observable.of(
              push(
                `/transaction-confirmation/${action.payload.transactionId}`,
                response
              )
            );
          }
          return Observable.of(push("/thank-you"));
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(
            Observable.of(
              ModalActions.setConditionalModalState(true, "ErrorModal", {
                title: "errorModal.title",
                content: `errorCodes.${e.errors[0].code}`,
                linkRedirectsTo: "/my-balance",
                buttonText: "errorModal.success"
              })
            ),
            Observable.of(transactionDataFail(e))
          );
        })
      );
    })
  );

export default combineEpics(transactionConfirmation);
