const analyticsEvents = {
  personTypeNatural: {
    action: "location change to /register/natural from /register",
    category: "LOCATION_CHANGE_REGISTER_NATURAL_FROM_REGISTER",
    label: "redirection"
  },
  personTypeLegal: {
    action: "location change to /register/legal from /register",
    category: "LOCATION_CHANGE_REGISTER_LEGAL_FROM_REGISTER",
    label: "redirection"
  },
  generalData: {
    action:
      "location change to /enrollment/general-information from /users/challenge-questionnaire",
    category:
      "LOCATION_CHANGE_ENROLLMENT_GENERAL_INFO_FROM_CHALLENGE_QUESTIONS",
    label: "redirection"
  },
  register: {
    action: "location change to /check-email from /register/natural",
    category: "LOCATION_CHANGE_CHECK_EMAIL_FROM_REGISTER_NATURAL",
    label: "redirection"
  },
  saveFinancialInfo: {
    action:
      "location change to /enrollment/purpose from /enrollment/financial-info",
    category:
      "LOCATION_CHANGE_ENROLLMENT_PURPOSE_FROM_ENROLLMENT_FINANCIAL_INFO",
    label: "redirection"
  },
  savePurpose: {
    action: "location change to /enrollment/summary from /enrollment/purpose",
    category: "LOCATION_CHANGE_ENROLLMENT_SUMMARY_FROM_ENROLLMENT_PURPOSE",
    label: "redirection"
  },
  saveSummary: {
    action: "location change to /enrollment/success from /enrollment/summary",
    category: "LOCATION_CHANGE_ENROLLMENT_SUCCESS_FROM_ENROLLMENT_SUMMARY",
    label: "redirection"
  },
  otpValidation: {
    action: "location change to /success-sign-up from /code-validation",
    category: "LOCATION_CHANGE_SUCCESS_SIGN_UP_FROM_CODE_VALIDATION",
    label: "redirection"
  },
  successSignUp: {
    action: "location change to /login from /success-sign-up",
    category: "LOCATION_CHANGE_LOGIN_FROM_SUCCESS_SIGN_UP",
    label: "redirection"
  },
  saveConfirmData: {
    action:
      "location change to /enrollment/general-information from /confirm-data",
    category: "LOCATION_CHANGE_ENROLLMENT_GENERAL_INFO_FROM_CONFIRM_DATA",
    label: "redirection"
  },
  saveGeneralInfo: {
    action:
      "location change to /enrollment/financial-info from /enrollment/general-information",
    category:
      "LOCATION_CHANGE_ENROLLMENT_FINANCIAL_INFO_FROM_ENROLLMENT_GENERAL_INFO",
    label: "redirection"
  },
  saveFinancialInfoPJ: {
    action:
      "location change to /enrollment/organizational-structure from /enrollment/financial-info",
    category:
      "LOCATION_CHANGE_ENROLLMENT_ORGANIZATIONAL_STRUCTURE_FROM_ENROLLMENT_FINANCIAL_INFO",
    label: "redirection"
  },
  goEnrollmentLegalRepresentative: {
    action:
      "location change to /enrollment/legal-representative from /enrollment/organizational-structure",
    category:
      "LOCATION_CHANGE_ENROLLMENT_LEGAL_REPRESENTATIVE_FROM_ENROLLMENT_ORGANIZATIONAL_STRUCTURE",
    label: "redirection"
  },
  saveLegalRepresentative: {
    action:
      "location change to /enrollment/organizational-structure from /enrollment/legal-representative",
    category:
      "LOCATION_CHANGE_ENROLLMENT_ORGANIZATIONAL_STRUCTURE_FROM_ENROLLMENT_LEGAL_REPRESENTATIVE",
    label: "redirection"
  },
  goEnrollmentAssociate: {
    action:
      "location change to /enrollment/associate from /enrollment/organizational-structure",
    category:
      "LOCATION_CHANGE_ENROLLMENT_ASSOCIATE_FROM_ENROLLMENT_ORGANIZATIONAL_STRUCTURE",
    label: "redirection"
  },
  saveAssociate: {
    action:
      "location change to /enrollment/organizational-structure from /enrollment/associate",
    category:
      "LOCATION_CHANGE_ENROLLMENT_ORGANIZATIONAL_STRUCTURE_FROM_ENROLLMENT_ASSOCIATE",
    label: "redirection"
  },
  investFromCampaignDetail: {
    action: "location change to /investment-amount from /campaing",
    category: "LOCATION_CHANGE_INVESTMENT_AMOUNT_FROM_CAMPAIGN",
    label: "redirection"
  },
  investFromShowcase: {
    action: "location change to /investment-amount from /showcase",
    category: "LOCATION_CHANGE_INVESTMENT_AMOUNT_FROM_SHOWCASE",
    label: "redirection"
  },
  summaryFromInvest: {
    action: "location change to /investment-summary from /investment-amount",
    category: "LOCATION_CHANGE_INVESTMENT_SUMMARY_FROM_INVESTMENT_AMOUNT",
    label: "redirection"
  },
  transfiriendoFromSummary: {
    action: "location change to /transfiriendo from /investment-summary",
    category: "LOCATION_CHANGE_TRANSFIRIENDO_FROM_INVESTMENT_SUMMARY",
    label: "redirection"
  },
  goToRecharge: {
    action: "location change to /investment-amount from /my-balance",
    category: "LOCATION_CHANGE_INVESTMENT_AMOUNT_FROM_MY_BALANCE",
    label: "redirection"
  },
  goToCancel: {
    action: "location change to /investment-cancelation-modal from /my-balance",
    category: "LOCATION_CHANGE_INVESTMENT_MODAL_FROM_MY_BALANCE",
    label: "redirection"
  },
  goToCancelSure: {
    action:
      "location change to /identity-validation-otp from /investment-cancelation-modal",
    category: "LOCATION_CHANGE_IDENTITY_VALIDATION_OTP_FROM_MY_BALANCE",
    label: "redirection"
  },
  identityValidation: {
    action:
      "location change to /money-withdrawal from /identity-validation-otp",
    category: "LOCATION_CHANGE_MONEY_WITHDRAWAL_FROM_IDENTITY_VALIDATION_OTP",
    label: "redirection"
  },
  confirmWithdrawal: {
    action: "location change to /success from /money-withdrawal",
    category: "LOCATION_CHANGE_SUCCESS_FROM_MONEY_WITHDRAWAL",
    label: "redirection"
  },
  registerNatural: {
    action: "location change to /register/natural from /register",
    category: "LOCATION_CHANGE_REGISTER_NATURAL_FROM_REGISTER",
    label: "redirection"
  },
  registerLegal: {
    action: "location change to /register/legal from /register",
    category: "LOCATION_CHANGE_REGISTER_LEGAL_FROM_REGISTER",
    label: "redirection"
  },
  saveConfirmDataLegal: {
    action: "location change to /register/legal from /register",
    category:
      "LOCATION_CHANGE_ENROLLMENT_GENERAL_INFO_FROM_CONFIRM_DATA_NATURAL",
    label: "redirection"
  },
  saveGralDataNatural: {
    action:
      "location change to /enrollment/general-information from /confirm-data",
    category: "LOCATION_CHANGE_ENROLLMENT_GENERAL_DATA_FROM_GENERAL_INFO",
    label: "redirection"
  },
  goShowCaseNatural: {
    action: "location change to / from /enrollment/success",
    category: "LOCATION_CHANGE_HOME_FROM_ENROLLMENT_SUMMARY",
    label: "redirection"
  },
  goShowCaseLegal: {
    action: "location change to / from /enrollment/success",
    category: "LOCATION_CHANGE_HOME_FROM_ENROLLMENT_SUMMARY",
    label: "redirection"
  },
  gotRechargeAmount: {
    action:
      "location change to /recharge-amount from /qualified-investor-validate",
    category: "LOCATION_CHANGE_RECHARGE_AMOUNT_FROM_QUALIFIED_INVESTOR",
    label: "redirection"
  },
  goToMyBalance: {
    action: "location change to /my-balance from /investment-confirmation",
    category: "LOCATION_CHANGE_MY_BALANCE_FROM_INVESTMENT_CONFIRMATION",
    label: "redirection"
  },
  saveLegalRepresentativeAndAssociate: {
    action:
      "location change to /enrollment/purpose from /enrollment/organizational-structure",
    category:
      "LOCATION_CHANGE_ENROLLMENT_PURPOSE_FROM_ENROLLMENT_ORGANIZATIONAL_STRUCTURE",
    label: "redirection"
  },
  savePurposeLegal: {
    action: "location change to /enrollment/summary from /enrollment/purpose",
    category:
      "LOCATION_CHANGE_ENROLLMENT_SUMMARY_FROM_ENROLLMENT_PURPOSE_LEGAL",
    label: "redirection"
  },
  goToInvest: {
    action: "location change to /investment-amount from /home",
    category: "LOCATION_CHANGE_INVESTMENT_AMOUNT_FROM_HOME",
    label: "redirection"
  },
  paymentByAvailable: {
    action: "location change to /investment-summary from /investment-amount",
    category:
      "LOCATION_CHANGE_INVESTMENT_SUMMARY_FROM_INVESTMENT_AMOUNT_AVAILABLE",
    label: "redirection"
  },
  paymentByPSE: {
    action: "location change to /investment-summary from /investment-amount",
    category: "LOCATION_CHANGE_INVESTMENT_SUMMARY_FROM_INVESTMENT_AMOUNT_PSE",
    label: "redirection"
  },
  goToPSEInvestment: {
    action: "location change to PSE from /investment-summary ",
    category: "LOCATION_CHANGE_PSE_FROM_INVESTMENT_SUMMARY",
    label: "redirection"
  },
  investmentToAvailable: {
    action:
      "location change to /transaction-confirmation from /investment-summary ",
    category: "LOCATION_CHANGE_PSE_FROM_INVESTMENT_SUMMARY",
    label: "redirection"
  },
  toLogin: {
    action: "location change to /login",
    category: "LOGIN_HOME",
    label: "redirection"
  },
  toSignUp: {
    action: "location change to /sign-up from / (home)",
    category: "CREATE_ACCOUNT_HOME",
    label: "redirection"
  },
  toCampaignDetail: {
    action: "location change to /campaign from / (home)",
    category: "VIEW_DETAIL_CARD_CAMPAIGN",
    label: "redirection"
  },
  homeToShowcase: {
    action: "view_showcase_home",
    category: "VIEW_SHOWCASE_HOME",
    label: "click"
  },
  toCampaignDetailFromShowcase: {
    action: "location change to /campaign from detail campaign card",
    category: "VIEW_DETAIL_CARD_CAMPAIGN_FROM_SHOWCASE",
    label: "redirection"
  },
  toSingUpFromShowcase: {
    action: "location change to /sign-up from campaign detail showcase",
    category: "CREATE_ACCOUNT_CAMPAIGN_DETAIL_FROM_SHOWCASE",
    label: "redirection"
  },
  toLoginFromShowCase: {
    action: "location change to /login from campaign detail showcase",
    category: "LOGIN_FROM_SHOWCASE",
    label: "redirection"
  },
  showCaseCampaign: (campaignId) => ({
    action: `view showcase campaign campaign-id: ${campaignId}`,
    category: "VIEW_FEATURED_CAMPAIGN_FROM_SHOWCASE",
    label: "click"
  }),
  simulatorFeatureShowcase: (campaignId) => ({
    action: `click on simulator campaign campaign-id: ${campaignId}`,
    category: "SIMULATOR_FEATURED_CAMPAIGN_FROM_SHOWCASE",
    label: "click"
  })
};

export default analyticsEvents;
