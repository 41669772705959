import styled from "styled-components";
import ReactTooltip from "react-tooltip";

export const Container = styled.div`
  margin-top: 120px;

  ${(props) => props.theme.smartphone} {
    margin-top: 20px;
  }
`;

export const Error = styled.p`
  color: ${(props) => props.theme.red};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 200;
  margin-top: 10px;
  ${(props) => props.theme.smartphone} {
    margin-top: 5px;
    font-size: 12px;
  }
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.tuatara};
  font-family: Nunito;
  font-size: 23px;
  margin-top: 20px;
  margin-bottom: 42px;
  position: relative;
  font-weight: 800;
  text-align: left;
  padding-left: 8px;
  border-left: 6px solid;
  border-left-color: ${(props) => props.theme.brightTurquoise};
  ${(props) => props.theme.smartphone} {
    margin-top: 10px;
    font-size: 19px;
  }
`;

export const TooltipImage = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 0 0 10px;
  position: absolute;
  top: 6px;
  right: 0;
  ${(props) => props.theme.desktop} {
    width: 15px;
    height: 15px;
  }
`;

export const Tooltip = styled(ReactTooltip)`
  opacity: 1;
`;

export const ToolTipText = styled.div`
  width: 170px;
  text-align: left;
`;

export const Form = styled.form`
  width: 100%;
  text-align: center;
`;

export const FormContent = styled.div`
  padding: 20px 0;
  .input {
    margin-bottom: 16px;
  }
`;

export const ContinueButton = styled.button`
  width: 215px;
  padding: 8px 0;
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  border-radius: 22.5px;
  font-size: 17px;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin-top: 24px;
  background-color: transparent;
  border: 2px solid ${(props) => props.theme.brightTurquoise};
  color: ${(props) => props.theme.brightTurquoise};
  &:focus {
    outline: 0;
  }
  &:hover {
    -webkit-box-shadow: 0px 6px 10px 0px rgba(50, 50, 50, 0.31);
    -moz-box-shadow: 0px 6px 10px 0px rgba(50, 50, 50, 0.31);
    box-shadow: 0px 6px 10px 0px rgba(50, 50, 50, 0.31);
    transform: scale(1.02);
  }
`;
