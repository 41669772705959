import styled from "styled-components";
import { AboutUsFromType } from "../AboutUs";

export const ParagraphContainer = styled.div<{ from: AboutUsFromType }>`
  width: ${({ from }) => (from === "ourAllies" ? "405px" : "659px")};
  margin: 30px 0;
  > p {
    font-size: 16px;
    font-weight: normal;
    line-height: ${({ from }) => (from === "ourAllies" ? "33px" : "26px")};
  }

  ${({ theme }) => theme.smartphone} {
    width: ${({ from }) => (from === "ourAllies" ? "293px" : "319px")};
    margin-top:12px;
  }
`;
