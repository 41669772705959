import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { isBrowser } from "react-device-detect";
import PreFooter from "../../components/Footer/PreFooter";
import Footer from "../../components/Footer/Footer.new";
import Container, { QuestionButton } from "./Styles";
import ImageQuestion from "../../assets/icons/Help.png";
import ImageQuestion2x from "../../assets/icons/Help.png";
import MoldalActions from "../../redux/reducers/modal.reducer";
import InformationalSlider from "components/InformationalSlider/InformationalSlider";

const ContentFooter = ({ showAllButtons, campaingTypes }) => {
  const dispatch = useDispatch();
  const openModal = () => {
    dispatch(MoldalActions.setConditionalModalState(true, "ContactForm"));
  };
  const { homeData } = useSelector(({ pageData }) => pageData.home);
  let buttonPosition = {
    bottom: "5%",
    right: "0px"
  };
  return (
    <Container>
      <PreFooter showAllButtons={showAllButtons} homeData={homeData} showCampaingTypes={campaingTypes ? true : false} />
      <QuestionButton
        position={buttonPosition}
        src={ImageQuestion}
        srcSet={`${ImageQuestion} 1x, ${ImageQuestion2x} 2x`}
        onClick={openModal}
        data-testId="home-floating-button"
      />
      {campaingTypes ? (
        <InformationalSlider campaingTypes={campaingTypes} />
      ) : (
        null
      )}
      <Footer homeData={homeData} />
    </Container>
  );
};

ContentFooter.propTypes = {
  showAllButtons: PropTypes.bool,
  campaingTypes: PropTypes.string
};

ContentFooter.defaultProps = {
  showAllButtons: false
};
export default ContentFooter;
