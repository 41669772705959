import styled from "styled-components";

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 20px;
  background-color: ${({ background, theme }) =>
    background ? theme[background] : theme.white};
  ${(props) => props.theme.mediumScreens} {
    padding: 40px 15%;
  }
`;

export const PageMovementsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 20px;
  background-color: ${({ background, theme }) =>
    background ? theme[background] : theme.white};
  ${({ theme }) => theme.mediumScreens} {
    padding: 40px 10%;
  }
`;

export const CheckBoxContainer = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const FormCheckboxesContainer = styled.div`
  margin-top: 50px;

  ${({ theme }) => theme.smartphone} {
    margin-top: 30px;
  }
`;

export const FormCheckboxText = styled.div`
  color: ${({ theme }) => theme.tuatara};
  font-family: Roboto;
  margin-left: 15px;

  ${({ theme }) => theme.smartphone} {
    font-size: 13px;
  }
`;

export const LinkSee = styled.a`
  padding: 0 0 0 0;
  color: ${({ theme }) => theme.brightTurquoise};
  border: none;
  background: none;
  font-size: 17px;
  cursor: pointer;
  text-decoration: underline;

  ${({ theme }) => theme.smartphone} {
    font-size: 13px;
  }
`;
export const FormPageContainer = styled.div`
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const PageTitle = styled.h1`
  color: ${({ theme }) => theme.riverBed};
  font-family: Nunito;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 33px;
  padding: 0px 10px;
  border-left: 5px solid ${({ theme }) => theme.brightTurquoise};
  ${({ theme }) => theme.smartphone} {
    margin: 0px 10px;
    font-size: 20px;
  }
`;
export const PageSubtitle = styled.p`
  color: ${({ theme }) => theme.riverBed};
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  margin: 12px 10px 24px;
  ${(props) => props.theme.smartphone} {
    font-size: 16px;
  }
`;
