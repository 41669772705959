import React from "react";
import { Wrapper, TagColors, Icon } from "./components";
import InfoIcon from "assets/images/info-tooltip-circle.png";

type Props = {
  icon?: JSX.Element | boolean;
  background: TagColors | string;
  title: string;
  color?: TagColors | string;
  spacing?: boolean;
  tooltip?: string;
  testId?: string;
  forceCenter?: boolean;
  onclick?: any;
  businessLine?: string;
};

export const Tag = ({
  icon,
  background,
  title,
  color,
  spacing,
  tooltip
}: Props) => {
  return (
    <Wrapper
      background={background}
      color={color}
      data-testid={tooltip}
      spacing={spacing}
      data-tip={`tag-${tooltip}`}
      data-for={`tag-${tooltip}`}
    >
      {title}
      {icon && (
        <Icon>
          {typeof icon === "boolean" ? (
            <img src={InfoIcon} data-testid="tag-default-icon" />
          ) : (
            icon
          )}
        </Icon>
      )}
    </Wrapper>
  );
};
