import axios from "axios";
import axiosRetry, { isRetryableError } from "axios-retry";
import App from "../app";
import convertFileToArrayBuffer from "../utils/fileToArrayBuffer";
import { DELAY_RETRIES, FILE_RETRIES } from "config/network";

axiosRetry(axios, {
  retries: FILE_RETRIES,
  retryDelay: (retryCount) => retryCount * DELAY_RETRIES,
  retryCondition: (error) => isRetryableError(error)
});
class UploadFile {
  constructor(apiEndpoint) {
    this.apiEndpoint = apiEndpoint;
  }

  upload(args) {
    return App.api.a2censo[this.apiEndpoint]({
      body: args,
      config: {
        onUploadProgress: (progress) => this.onUploadProgress(progress)
      }
    });
  }

  uploadBinaryPut(file) {
    return this.uploadBinary({ file, method: "put" });
  }

  uploadBinary({ file, method }) {
    return new Promise((resolve, reject) => {
      convertFileToArrayBuffer(file).then((fileBinary) => {
        axios[method](this.apiEndpoint, fileBinary, {
          headers: {
            "Content-Type": file.type
          },
          onUploadProgress: (progress) => this.onUploadProgress(progress)
        })
          .then(resolve)
          .catch(reject);
      });
    });
  }

  onUploadProgress(progress) {
    const percent = Math.round((progress.loaded * 100) / progress.total);
    this.progress = percent;
    this.onProgressUpdate();
  }

  /**
   * Implementation required
   */
  onProgressUpdate() {
    const { progress } = this;
    throw new Error(
      `You have to implement the method onProgressUpdate!, progress status ${progress}`
    );
  }
}

export default UploadFile;
