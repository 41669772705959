import styled from "styled-components";
import ReactTooltip from "react-tooltip";

export const Tooltip = styled(ReactTooltip)`
  background: ${({ theme }) => theme.lightPurple};
  margin-left: 10px !important;
  width: 288px;
  
  :after {
    border-bottom-color: ${({ theme }) => theme.colors.dolphin.bgColor} !important
  }
  
  ${({ theme }) => theme.smartphone} {
    width: 90%;
    margin-left: 6px !important;
    :after {
      border-bottom-color: ${({ theme }) => theme.altoForest} !important;
      left: 18% !important;
    }
    left: 16px !important;
    margin-left: 0 !important;
  }

  ${(props) => props.theme.smartphone375} {
    :after {
      left: 20% !important;
    }
    left: 16px !important;
    margin-left: 0 !important;
  }

  ${(props) => props.theme.smartphone425} {
    :after {
      left: 18% !important;
    }
    left: 28px !important;
    margin-left: 0 !important;
  }
`;
