import React, { useState, useEffect } from "react";
import { filter, remove } from "lodash";
import { useTranslation } from "react-i18next";
import {
  Container,
  Title,
  Content,
  Source,
  Target,
  CheckboxLayout,
  SearchInput,
  SearchIconImg,
  SearchInputContainer,
  ChipLayout,
  List,
  TooltipIcon,
  Tooltip
} from "./components";
import {
  Chip,
  Checkbox,
  CampaignPendingApprove
} from "@bvcco/a2censo-component-lib";
import SearchIcon from "assets/icons/search.svg";
import defaultImage from "assets/images/empty-states.png";
import InfoTooltip from "assets/icons/info-tooltip-2.svg";

interface DataSourceProps {
  key: string | number;
  title: string;
  description: string;
  disabled: boolean;
  tooltip?: string;
}

interface TransferProps {
  title: string;
  loading?: string;
  placeholderImage?: JSX.Element | string | React.ReactNode;
  placeholderTitle?: string;
  placeholderSubtitle?: string;
  selectedKeys: string[];
  onCheckItem?: (key: string) => void;
  onUncheckItem?: (key: string) => void;
  dataSource: DataSourceProps[];
}

export const Transfer = ({
  title,
  placeholderImage,
  placeholderTitle,
  placeholderSubtitle,
  dataSource: dataSourceProp,
  loading,
  onCheckItem = () => {},
  onUncheckItem = () => {},
  selectedKeys: selectedItems = []
}: TransferProps) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<DataSourceProps[]>([]);

  useEffect(() => {
    setDataSource(dataSourceProp);
  }, [dataSourceProp]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataSource(
      filter(dataSourceProp, (item) =>
        item.title.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleItemCheck = (
    item: DataSourceProps,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      onCheckItem(`${item.key}`);
    } else {
      const newSelectedItems = [...selectedItems];
      remove(newSelectedItems, (i) => i === item.key);
      onUncheckItem(`${item.key}`);
    }
  };

  return (
    <Container>
      <Title>{loading || title}</Title>
      <Content>
        <Source>
          <SearchInputContainer>
            <SearchIconImg src={SearchIcon} />
            <SearchInput
              onChange={handleSearch}
              placeholder={t("searchByName")}
              data-testId="search-transfer"
            />
          </SearchInputContainer>
          <List>
            {dataSource.map((item) => (
              <CheckboxLayout key={item.key} title={item.description}>
                <Checkbox
                  dataTestId={`test-${item.key}`}
                  size="small"
                  id={item.key}
                  fontSize="smallMedium"
                  labelColor="text"
                  label={item.title}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleItemCheck(item, e)
                  }
                  checked={selectedItems.includes(`${item.key}`)}
                />
                {item.tooltip && (
                  <>
                    <TooltipIcon
                      src={InfoTooltip}
                      data-for={`tooltip-${item.key}`}
                      data-tip={`tooltip-tip-${item.key}`}
                    />
                    <Tooltip
                      place="top"
                      effect="solid"
                      id={`tooltip-${item.key}`}
                    >
                      {item.tooltip}
                    </Tooltip>
                  </>
                )}
              </CheckboxLayout>
            ))}
          </List>
        </Source>
        <Target>
          {filter(dataSourceProp, (item) =>
            selectedItems.includes(`${item.key}`)
          ).map((item) => (
            <ChipLayout key={item.key}>
              <Chip
                title={item.description}
                text={item.title}
                onRemove={() => {
                  const newSelectedItems = [...selectedItems];
                  remove(newSelectedItems, (i) => i === item.key);
                  onUncheckItem(`${item.key}`);
                }}
                dataTestid={`transfer-remove-${item.key}`}
              />
            </ChipLayout>
          ))}
          {selectedItems.length === 0 && (
            <CampaignPendingApprove
              imageUrl={placeholderImage || defaultImage}
              title={placeholderTitle}
              subtitle={placeholderSubtitle}
              type="inline"
            />
          )}
        </Target>
      </Content>
    </Container>
  );
};
