import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Heading } from "@bvcco/a2censo-component-lib";
import { push } from "connected-react-router";
import {
  Container,
  Divider,
  Button,
  ParagraphDescription,
  ButtonsContainer
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";

const VinculationPymeSuccessModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pymeName = useSelector(({ user }) => user.userInfo.user);
  const handleClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(push("/dashboard-campaigns"));
  };

  return (
    <Container>
      <Heading hieranchy="primary" fontWeight="bold" color="brightTurquoise">
        {t("confirmDataPyme.PymeVinculationSuccess.title")}
      </Heading>
      <ParagraphDescription>
        {`${pymeName.name} ${t(
          "confirmDataPyme.PymeVinculationSuccess.message"
        )}`}
      </ParagraphDescription>
      <Divider />
      <ButtonsContainer>
        <Button
          rounded={true}
          id="continue-button"
          onClick={handleClick}
          primary="true"
        >
          {t("confirmDataPyme.PymeVinculationSuccess.sure")}
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export default VinculationPymeSuccessModal;
