import { catchError, flatMap } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { push } from "connected-react-router";
import { ofType, combineEpics } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import LoginActions from "../reducers/login.reducer";
import PaymentsActions, { PaymentsTypes } from "../reducers/payments.reducer";
import ModalActions from "../reducers/modal.reducer";
import { getErrorIndex } from "../../lib/utils";

export const myPaymentsFail = ({ error }) => ({
  type: "MY_PAYMENTS_DATA_FAIL",
  payload: {
    error
  }
});

export const getMyPayments = (action$) =>
  action$.pipe(
    ofType(PaymentsTypes.START_MY_PAYMENTS),
    flatMap(() => {
      const urlVars = "?page=1&size=5";
      return forkJoin([
        Observable.from(App.api.a2censo.getCampaignCompany()),
        Observable.from(
          App.api.a2censo.getPayments({
            url: { urlVars }
          })
        ),
        Observable.from(App.api.a2censo.getHolidays())
      ]).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(
              PaymentsActions.setIssuerPayments(
                response[0],
                response[1],
                response[2]
              )
            ),
            Observable.of(push("/my-payments", { response }))
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(push("/my-payments", {}));
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/"
                })
              ),
              Observable.of(myPaymentsFail(e))
            );
          }
          return Observable.concat(
            Observable.of(myPaymentsFail(e)),
            Observable.of(push("/oops"))
          );
        })
      );
    })
  );

export default combineEpics(getMyPayments);
