import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  > div {
    max-width: 308px;
    flex-grow: 1;
    margin-right: 23px;
    margin-bottom: 44px;

    ${({ theme }) => theme.breakpoints.smallSmartPhone} {
      margin-right: 0px;
    }
  }
`;
