import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  startAccountRecoveryOtpRequest: ["payload"],
  startAccountRecoveryOtpValidation: ["payload"],
  recoveryAccountValidationOtpError: ["payload"],
  recoveryAccountOtpCodeError: ["error"],
  recoveryAccountOtpSuccess: []
});

export const AccountRecoveryTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  error: ""
};

const setAccountRecoveryError = (state, { payload }) => ({
  ...state,
  error: payload.error
});

const recoveryAccountOtpCodeError = (state, { error }) => ({ ...state, error });

const recoveryAccountOtpSuccess = (state) => ({ ...state, error: false });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RECOVERY_ACCOUNT_VALIDATION_OTP_ERROR]: setAccountRecoveryError,
  [Types.RECOVERY_ACCOUNT_OTP_CODE_ERROR]: recoveryAccountOtpCodeError,
  [Types.RECOVERY_ACCOUNT_OTP_SUCCESS]: recoveryAccountOtpSuccess
});
