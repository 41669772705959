import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getAllParameters: ["search"],
  setAllParameters: ["parameters"],
  setParameter: ["parameter"],
  fetchListParameters: ["tables", "query"],
  setListParameters: ["parameters"]
});

export const ParametersTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  parameters: [],
  isLoading: false,
  parameter: [],
  listParameters: {
    _bank_account_type: [],
    _pse_bank: [],
    _ciiu_code: []
  }
};

const getAllParameters = (state) => ({ ...state, isLoading: true });

const setAllParameters = (state, { parameters }) => ({
  ...state,
  parameters,
  isLoading: false
});

const setParameter = (state, { parameter }) => ({
  ...state,
  parameter,
  isLoading: false
});

const fetchListParameters = (state) => ({ ...state, isLoading: true });

const setListParameters = (state, { parameters }) => {
  const newParameters = parameters || {};
  const listParameters = { ...state.listParameters, ...newParameters };
  return {
    ...state,
    listParameters,
    isLoading: false
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ALL_PARAMETERS]: setAllParameters,
  [Types.SET_PARAMETER]: setParameter,
  [Types.GET_ALL_PARAMETERS]: getAllParameters,
  [Types.FETCH_LIST_PARAMETERS]: fetchListParameters,
  [Types.SET_LIST_PARAMETERS]: setListParameters
});
