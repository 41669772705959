import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  startSendMessage: ["payload"]
});

export const messageTypes = Types;
export default Creators;

const INITIAL_STATE = {};

const sendMessage = (state: any, { payload }: any) => ({
  ...state,
  ...payload
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.START_SEND_MESSAGE]: sendMessage
});
