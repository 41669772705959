import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  Container,
  Paragraph,
  Divider,
  Button,
  CloseButton,
  Icon
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";
import PaymentsActions from "../../redux/reducers/payments.reducer";

const PrepaidDestinyModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { issuerPaymentId, issuerName, reduceType, newAmount } = props;

  let optionSelected = t("prepaidDestiny.reduceTime.title");
  if (reduceType === "ReduceDue") {
    optionSelected = t("prepaidDestiny.reduceQuota.title");
  }

  const handleClickPay = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(
      PaymentsActions.submitPrepaidDestiny({
        issuerPaymentId,
        issuerName,
        reduceType,
        newAmount
      })
    );
  };

  return (
    <Container>
      <CloseButton
        id="back-button"
        onClick={() => dispatch(ModalActions.setConditionalModalState(false))}
      >
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <Paragraph>
        {`${t("prepaidDestiny.modal.text1")}"${optionSelected}"${t(
          "prepaidDestiny.modal.text2"
        )}`}
      </Paragraph>
      <Divider />
      <Button id="continue-button" onClick={() => handleClickPay()}>
        {t("moneyWithdrawalModal.sure")}
      </Button>
    </Container>
  );
};

PrepaidDestinyModal.propTypes = {
  issuerPaymentId: PropTypes.string.isRequired,
  issuerName: PropTypes.string.isRequired,
  reduceType: PropTypes.string.isRequired,
  newAmount: PropTypes.string.isRequired
};

export default PrepaidDestinyModal;
