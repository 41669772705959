import React from "react";
import { Wrapper, Body, Header, Action, Close, Container } from "./components";

export enum ModalSize {
  Small = "small",
  Medium = "medium",
  Large = "large"
}

export interface ModalProps extends React.HTMLProps<HTMLDivElement> {
  fullContent?: boolean;
  children?: JSX.Element;
  onClose?: () => void;
  open: boolean;
  boxRadius?: number;
  modalTitle?: JSX.Element | string;
  modalSize: ModalSize;
  backgroundColor?: string;
}

export const Modal = ({
  fullContent = false,
  onClose = () => {},
  open = false,
  boxRadius = 0,
  title = "",
  modalSize = ModalSize.Small,
  backgroundColor = "",
  children,
  ...props
}: ModalProps) => {
  const eventOnClose = () => onClose();
  if (!open) {
    return null;
  }
  const divProps: any = props;

  return (
    <Wrapper {...divProps}>
      <Header className="modal-header" size={modalSize}>
        <div>{title}</div>
        <Action>
          <Close data-testid="close-modal" onClick={eventOnClose} />
        </Action>
      </Header>
      <Container
        isFullContent={fullContent}
        className="modal-container"
        size={modalSize}
        boxRadius={boxRadius}
        backgroundColor={backgroundColor}
      >
        <Body>{children ?? <></>}</Body>
      </Container>
    </Wrapper>
  );
};
