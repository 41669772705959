import styled from "styled-components";

export const Input = styled.input<{ error: String } & any>`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #D4D7D9;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  font-family: Nunito;
  color: #0d0dac;
  outline: none;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .input::placeholder {
    text-align: center;
    line-height: 100px;
    color: #0d0dac;
  }
`;