import environment from "environment";
import { Connect } from "./connect";

export class CampaingDetailConnect extends Connect {
  constructor() {
    const { apiKey, apiCampaingDetailUrl } = environment;
    const login = { idToken: sessionStorage.getItem("idToken") };
    super(apiCampaingDetailUrl, "", { apiKey, token: login.idToken ? login.idToken : "" });
  }

  async getCampaignDetail({id} : any): Promise<any> {
    const response = await this.request({
      method: "get",
      service: `campaign-detail/${id}`
    });

    return Promise.resolve(response?.data);
  }

  async getCampaignPublicDetail({id} : any): Promise<any> {
    const response = await this.request({
      method: "get",
      service: `campaign-detail/public/${id}`
    });

    return Promise.resolve(response?.data);
  }

  async getCampaignPublicDetailMoreInfo({id} : any): Promise<any> {
    const response = await this.request({
      method: "get",
      service: `campaign-detail/moreInformation/public/${id}`
    });

    return Promise.resolve(response?.data);
  }
}
