import React from "react";
import LoaderGIF from "../LoaderGIF/LoaderGIF";
import { Container } from "./Styles";

const LoaderHands = () => (
  <Container>
    <LoaderGIF />
  </Container>
);

export default LoaderHands;
