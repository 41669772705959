import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getNews: [],
  setNews: ["newsList"]
});

export const newsTypes = Types;
export default Creators;

const INITIAL_STATE = {
  newsList: []
};

const setNews = (state, { newsList }) => ({ ...state, newsList });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_NEWS]: setNews
});
