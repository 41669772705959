import styled from "styled-components";

export const OptionCard = styled.div`
  background-color: ${(props) => props.theme.white};
  border-radius: 10px;
  padding: 29px 15px 15px 37px;
  min-height: 176px;
  width: 521px;
  cursor: pointer;
  margin: 10px;
  ${(props) => props.theme.smartphone} {
    width: auto;
    margin: 10px 0px;
    min-height: auto;
    padding-top: 16px;
    padding-left: 17px;
    position: relative;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const TitleCard = styled.h2`
  color: ${({ theme }) => theme.brightTurquoise};
  font-size: 22px;
  line-height: 25px;
  font-family: "Nunito Extrabold", sans-serif;
  ${(props) => props.theme.smartphone} {
    font-family: "Roboto Extrabold", sans-serif;
    font-size: 14px;
    line-height: 16px;
  }
`;
export const ContainerCheck = styled.div`
  ${(props) => props.theme.smartphone} {
    position: absolute;
    right: 4%;
    top: 40%;
  }
`;

export const DescriptionCard = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-family: "Roboto Regular", sans-serif;
  margin-top: 16px;
  color: ${(props) => props.theme.nevada};
  ${(props) => props.theme.smartphone} {
    font-family: "Roboto Regular", sans-serif;
    font-size: 12px;
    line-height: 18px;
    width: 90%;
    color: ${(props) => props.theme.tuatara};
  }
`;
