import React from "react";
import { StyledParagraph } from "./components";

type Props = {
  className?: string;
  children?: any;
  color?: string;
  dataTestId?: string;
  size: string;
  fontWeight?: string;
  align?: string;
  dangerouslySetInnerHTML?: any;
};

export const Paragraph = ({
  className,
  children,
  color = "black",
  dataTestId,
  size,
  fontWeight,
  align,
  dangerouslySetInnerHTML
}: Props) => (
  <StyledParagraph
    className={className}
    size={size}
    data-testid={dataTestId}
    color={color}
    fontWeight={fontWeight}
    align={align}
    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
  >
    {children}
  </StyledParagraph>
);

Paragraph.defaultProps = {
  children: undefined,
  dataTestId: "paragraph-test",
  size: "medium",
  fontWeight: "normal",
  align: "inherit",
  dangerouslySetInnerHTML: undefined
};
