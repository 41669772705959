module.exports = {
  AmountValidationSerializer: (
    transactionType,
    campaignId,
    available,
    pse,
    userId
  ) => ({
    meta: {},
    data: {
      attributes: {
        campaign_id: campaignId,
        transaction_type: transactionType,
        origin: {
          available,
          pse
        },
        user_id: userId
      }
    }
  })
};
