import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import ForgotPasswordActions from "../reducers/forgot-password.reducer";
import {
  ForgotPasswordSerializer,
  ForgotPasswordOtpTypeSerializer,
  ForgotPasswordOtpValidationSerializer
} from "../../lib/serializers/forgotPassword.serializer";
import { ResetPasswordSerializer } from "../../lib/serializers/resetPassword.serializer";
import LoginActions from "../reducers/login.reducer";

export const startForgotPasswordFlow = ({ email }) => ({
  type: "START_FORGOT_PASSWORD_FLOW",
  payload: {
    email
  }
});

export const selectForgotPasswordOtpType = ({ token, otpType }) => ({
  type: "SELECT_FORGOT_PASSWORD_OTP_TYPE",
  payload: {
    token,
    otpType
  }
});

export const startForgotPasswordOtpValidation = ({
  token,
  otpCode,
  otpType
}) => ({
  type: "START_FORGOT_PASSWORD_OTP_VALIDATION",
  payload: {
    token,
    otpCode,
    otpType
  }
});
export const endResetPasswordFlow = ({ token, password }) => ({
  type: "END_RESET_PASSWORD_FLOW",
  payload: {
    token,
    password
  }
});

export const startRequestNewOtpCode = ({ email }) => ({
  type: "START_REQUEST_NEW_OTP_CODE",
  payload: {
    email
  }
});

export const forgotPasswordFlow = (action$) =>
  action$.pipe(
    ofType("START_FORGOT_PASSWORD_FLOW"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.forgotPassword({
          body: ForgotPasswordSerializer().serialize({
            email: action.payload.email
          })
        })
      ).pipe(
        flatMap(() => Observable.of(push("/check-email"))),
        catchError((e) =>
          Observable.of(ForgotPasswordActions.forgotPasswordError(e))
        )
      )
    )
  );

export const forgotPasswordOtpType = (action$) =>
  action$.pipe(
    ofType("SELECT_FORGOT_PASSWORD_OTP_TYPE"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.forgotPasswordOtpRequest({
          body: ForgotPasswordOtpTypeSerializer().serialize({
            token: action.payload.token,
            otpType: action.payload.otpType
          })
        })
      ).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(ForgotPasswordActions.forgotPasswordSuccess({})),
            Observable.of(
              push("/forgot-password/otp-validation", {
                token: action.payload.token,
                otpType: action.payload.otpType,
                countryCode: response.country_code,
                phoneNumber: response.phone_number,
                dateGenerationToken: new Date,
                timerLimitMinutes: response.otp_timer_limit_minutes
              })
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }

          if(action.payload.otpType == "sms"){
            return Observable.concat(
              Observable.of(ForgotPasswordActions.forgotPasswordError(e)),
              Observable.of(
                push("/forgot-password/otp-validation", {
                  token: action.payload.token,
                  otpType: action.payload.otpType,
                  countryCode: "",
                  phoneNumber: "",
                  dateGenerationToken: ""
                })
              )
            )
          }

          return Observable.of(ForgotPasswordActions.forgotPasswordError(e));
        })
      )
    )
  );

export const forgotPasswordOtpValidation = (action$) =>
  action$.pipe(
    ofType("START_FORGOT_PASSWORD_OTP_VALIDATION"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.forgotPasswordOtpValidation({
          body: ForgotPasswordOtpValidationSerializer().serialize({
            token: action.payload.token,
            otpCode: action.payload.otpCode,
            otpType: action.payload.otpType
          })
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(ForgotPasswordActions.forgotPasswordSuccess({})),
            Observable.of(
              push("/forgot-password/reset", { token: action.payload.token })
            )
          )
        ),
        catchError((e) =>
          Observable.of(ForgotPasswordActions.forgotPasswordError(e))
        )
      )
    )
  );

export const endResetPassword = (action$) =>
  action$.pipe(
    ofType("END_RESET_PASSWORD_FLOW"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.resetPassword({
          body: ResetPasswordSerializer().serialize({
            token: action.payload.token,
            password: action.payload.password
          })
        })
      ).pipe(
        flatMap(() => Observable.of(push("/forgot-password/success"))),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.of(ForgotPasswordActions.forgotPasswordError(e));
        })
      )
    )
  );

export const requestNewOtpCode = (action$) =>
  action$.pipe(
    ofType("START_REQUEST_NEW_OTP_CODE"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.requestNewCode({
          body: ForgotPasswordSerializer().serialize({
            token: action.payload.token,
            otpType: action.payload.otpType
          })
        })
      ).pipe(
        flatMap(() => Observable.empty()),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.of(ForgotPasswordActions.forgotPasswordError(e));
        })
      )
    )
  );

export default combineEpics(
  forgotPasswordFlow,
  forgotPasswordOtpType,
  endResetPassword,
  requestNewOtpCode,
  forgotPasswordOtpValidation
);
