const { Serializer } = require("jsonapi-serializer");

module.exports = {
  RequestCampaignSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "id",
        "name",
        "company_id",
        "description",
        "term_in_months",
        "close_date",
        "video_path",
        "requested_amount",
        "minimal_amount",
        "fng_percentage",
        "interest_rate",
        "capital_payment",
        "capital_grace_period",
        "interest_payment",
        "interest_grace_period",
        "release_date",
        "_state_request_campaign_id",
        "request_campaign",
        "financial_terms",
        "isFinancialTermsEdited"
      ]
    })
};
