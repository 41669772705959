import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../components/Input/Input";

import { startForgotPasswordFlow } from "../../redux/epics/forgot-password";

import {
  Container,
  Error,
  Title,
  Subtitle,
  Form,
  ContinueButton,
  FormContent,
  Cancel,
  CancelContainer
} from "./Styles";

import ForgotPasswordSchema from "./ForgotPassword.schema";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const ForgotPasswordError = useSelector(
    (state) => state.forgotPassword.error
  );
  const dispatch = useDispatch();

  const errorCode = ForgotPasswordError
    ? ForgotPasswordError.errors[0].code
    : null;

  return (
    <Container className="container">
      <Formik
        id="forgot-password-form"
        initialValues={{
          email: ""
        }}
        validationSchema={ForgotPasswordSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(startForgotPasswordFlow(values));
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => {
          const errorKeys = Object.keys(errors);
          return (
            <Form onSubmit={handleSubmit}>
              <FormContent className="col-md-6 offset-md-3">
                <Title>{t("forgotPassword.title")}</Title>
                <Subtitle>{t("forgotPassword.subtitle")}</Subtitle>
                <Input
                  label={t("forgotPassword.email")}
                  type="email"
                  name="email"
                  id="email"
                  showTooltip={false}
                  placeholder={t("forgotPassword.email")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && errors.email}
                />
                <Error>
                  {touched.email && t(errors.email)}
                  <br />
                  {ForgotPasswordError && t(`errorCodes.${errorCode}`)}
                </Error>
                <ContinueButton
                  id="send_button"
                  type="submit"
                  disabled={isSubmitting || errorKeys.length}
                  value={t("forgotPassword.continue")}
                >
                  {t("forgotPassword.continue")}
                </ContinueButton>
                <CancelContainer>
                  <Cancel href="/">{t("forgotPassword.cancel")}</Cancel>
                </CancelContainer>
              </FormContent>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ForgotPassword;
