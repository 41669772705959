const { Serializer } = require("jsonapi-serializer");

module.exports = {
  UpdateUserDataSerializer: (meta = {}) =>
    new Serializer("update-user-data", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "ciiu",
        "firstName",
        "lastName",
        "secondLastName",
        "secondName"
      ]
    })
};
