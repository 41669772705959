import styled, { css } from "styled-components";
import { Paragraph } from "@bvcco/a2censo-component-lib";
import { types } from "../types";

type Props = {
  type: types;
};

const getMildContent: Record<string, any> = {
  primary: css`
    width: 70%;
  `,
  secondary: css`
    width: 100%;
  `,
  tertiary: css`
    width: 70%;
  `
};

export const MidContent = styled.div<Props>`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-self: flex-start;
  z-index: 1;
  ${({ type }) => getMildContent[type]};
  margin-bottom: 8px;
`;

export const MidDescription = styled(Paragraph)`
  line-height: 20px;
  margin: 6px 0;
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
  opacity: 0;
`;
