import "./Checkbox.scss";
import React from "react";
import PropTypes from "prop-types";
import User from "../../lib/models/user.model";

const Checkbox = ({ check, onCheck, id, personType }) => {
  const isPyme = personType === User.type.pyme;

  const buttonClassName = isPyme
    ? "checkbox__container_pyme"
    : "checkbox__container";

  const checkIcon = isPyme
    ? "/assets/images/check-icon-purple.png"
    : "/assets/images/check.png";

  return (
    <button
      onClick={onCheck}
      className={buttonClassName}
      id={`checkbox-${id}`}
      data-testid={`checkbox-${id}`}
      type="button"
    >
      {check && (
        <img src={checkIcon} alt="checkbox" className="checkbox__center" />
      )}
    </button>
  );
};

Checkbox.propTypes = {
  check: PropTypes.bool,
  onCheck: PropTypes.func,
  id: PropTypes.string,
  personType: PropTypes.string.isRequired
};

Checkbox.defaultProps = {
  check: false,
  id: "",
  onCheck: null
};

export default Checkbox;
