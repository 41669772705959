import styled from "styled-components";
import "../../styles/colors.scss";

export const Alert = styled.div`
  display: flex;
  flex: 1;
  padding: 2px 15px;
  width: 100%;
  color: ${(props) => props.theme.gunPowder};
  font-size: 12px;
  line-height: 63px;
`;

export const ImgAlert = styled.img`
  padding-right: 5px;
`;

export const MessageAlert = styled.p`
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding: 12px 0;
`;