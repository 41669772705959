import crypto from "crypto";

const codeParams = {
  _PARAM_001: "_PARAM_001", // _document_type
  _PARAM_002: "_PARAM_002", // _country
  _PARAM_003: "_PARAM_003", // _ciiu_code
  _PARAM_004: "_PARAM_004", // _parameter
  _PARAM_005: "_PARAM_005", // _ciiu_code_legal
  _PARAM_006: "_PARAM_006", // _ciiu_code_natural
  _PARAM_007: "_PARAM_007", // _ciiu_productive_sector
  _PARAM_008: "_PARAM_008", // _quality_tax
  _PARAM_009: "_PARAM_009", // _investor_document
  _PARAM_010: "_PARAM_010", // _pse_bank
  _PARAM_011: "_PARAM_011", // _bank
  _PARAM_012: "_PARAM_012", // _bank_account_type
  _PARAM_013: "_PARAM_013", // _department
  _PARAM_014: "_PARAM_014", // _campaign_detail_type
  _PARAM_015: "_PARAM_015", // _campaign_request_terms
  _PARAM_016: "_PARAM_016", // _campaign_state
  _PARAM_017: "_PARAM_017", // _campaign_type
  _PARAM_018: "_PARAM_018", // _category_question
  _PARAM_019: "_PARAM_019", // _city
  _PARAM_020: "_PARAM_020", // _classification_person_type
  _PARAM_021: "_PARAM_021", // _company_classification
  _PARAM_022: "_PARAM_022", // _contact_reason
  _PARAM_023: "_PARAM_023", // _contract_periodicities
  _PARAM_024: "_PARAM_024", // _contract_time_terms
  _PARAM_025: "_PARAM_025", // _dispersion_state
  _PARAM_026: "_PARAM_026", // _dispersion_type
  _PARAM_027: "_PARAM_027", // _document
  _PARAM_028: "_PARAM_028", // _document_classification
  _PARAM_029: "_PARAM_029", // _document_origin
  _PARAM_030: "_PARAM_030", // _document_stage
  _PARAM_031: "_PARAM_031", // _document_state_request_campaign
  _PARAM_032: "_PARAM_032", // _enrollment_state
  _PARAM_033: "_PARAM_033", // _financial_terms_type
  _PARAM_034: "_PARAM_034", // _investment_state
  _PARAM_035: "_PARAM_035", // _investor_state
  _PARAM_036: "_PARAM_036", // _message
  _PARAM_037: "_PARAM_037", // _mobile_operator
  _PARAM_038: "_PARAM_038", // _origin_type
  _PARAM_039: "_PARAM_039", // _payment_channel
  _PARAM_040: "_PARAM_040", // _payment_method
  _PARAM_041: "_PARAM_041", // _person_type
  _PARAM_042: "_PARAM_042", // _pyme_detail
  _PARAM_043: "_PARAM_043", // _question
  _PARAM_044: "_PARAM_044", // _question_type
  _PARAM_045: "_PARAM_045", // _rate_type
  _PARAM_046: "_PARAM_046", // _request_title_type
  _PARAM_047: "_PARAM_047", // _resource_state
  _PARAM_048: "_PARAM_048", // _response
  _PARAM_049: "_PARAM_049", // _risk_classification
  _PARAM_050: "_PARAM_050", // _shareholder_type
  _PARAM_051: "_PARAM_051", // _stage
  _PARAM_052: "_PARAM_052", // _state_reported_user
  _PARAM_053: "_PARAM_053", // _state_request_campaign
  _PARAM_054: "_PARAM_054", // _status
  _PARAM_055: "_PARAM_055", // _transaction_state
  _PARAM_056: "_PARAM_056", // _transaction_type
  _PARAM_057: "_PARAM_057", // _type_document
  _PARAM_058: "_PARAM_058", // _type_reported_user
  _PARAM_059: "_PARAM_059", // _user_accepted_policies_type
  _PARAM_060: "_PARAM_060", // _user_hc
  _PARAM_061: "_PARAM_061", // _user_policies
  _PARAM_062: "_PARAM_062", // _user_state
  _PARAM_063: "_PARAM_063", // _user_terms
  _PARAM_064: "_PARAM_064", // _user_type
  _PARAM_066: "_PARAM_066", // _city_retention
  _PARAM_067: "_PARAM_067" // _city_consignment
};
const paramNames = {
  isCaptchaEnable: "is_captcha_enable",
  CODE_PARAMS: { ...codeParams },
  COUNTRY_ID: {
    COL: 47
  },
  QUERIES: {
    citiesByCountryId: "citiesByCountryId"
  },
  HASHED_CODE_PARAMS: () =>
    Object.keys(codeParams).reduce((customObj, filteredItem) => {
      const shaItem = crypto.createHash("sha256");
      shaItem.update(filteredItem);
      const shaFilteredItem = shaItem.digest("hex");

      // eslint-disable-next-line no-param-reassign
      customObj[filteredItem] = `_${shaFilteredItem}`;

      return customObj;
    }, {})
};

export default paramNames;
