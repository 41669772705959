import React from "react";
import { Paragraph } from "../Paragraph";
import { Container, FullDate, Capitalize } from "./components";
import moment from "moment";
import { useTranslation } from "react-i18next";

type Props = {
  date: Date;
  dataTestId?: string;
};

export const ReleaseDate = ({ date, dataTestId = "release-date" }: Props) => {
  const { t } = useTranslation();

  return (
    <Container data-testid={dataTestId}>
      <Paragraph color="white">{t("campaign.releaseDate.firstText")}</Paragraph>
      <Paragraph color="white">
        {t("campaign.releaseDate.secondText")}
      </Paragraph>

      <FullDate fontWeight="800" size="large" color="white">
        {moment(date).format("D [de] ")}
        <Capitalize>{moment(date).format("MMMM, hh:mm A")}</Capitalize>
      </FullDate>
    </Container>
  );
};
