import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setInvestingCampaigns: [
    "investingCampaignList",
    "isStart",
    "showLess",
    "auxLastFetchSize",
    "globalTime"
  ],
  setFiltersCampaings: ["filters"],
  setInvestingCampaignError: ["error"],
  setIsLoading: ["isLoading"],
  startInvestingCampaigns: ["payload"]
});

export const InvestingCampaignsTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  investingCampaignList: [],
  filters: [],
  lastFetchSize: [],
  investingCampaignError: "",
  globalTime: new Date().valueOf()
};

const setInvestingCampaigns = (
  state,
  { investingCampaignList, isStart, showLess, auxLastFetchSize }
) => {
  const lastFetchSizes = !state.lastFetchSize
    ? [investingCampaignList.length]
    : [...state.lastFetchSize, investingCampaignList.length];

  return {
    ...state,
    investingCampaignList: isStart
      ? investingCampaignList
      : [...state.investingCampaignList, ...investingCampaignList],
    lastFetchSize: showLess ? auxLastFetchSize : lastFetchSizes,
    investingCampaignError: "",
    isLoading: false
  };
};

const setInvestingCampaignError = (state, { error }) => ({
  ...state,
  investingCampaignError: error,
  isLoading: false
});

const setFiltersCampaings = (state, { filters }) => ({
  ...state,
  filters
});

const setIsLoading = (state, { isLoading }) => ({
  ...state,
  isLoading
});

const startInvestingCampaigns = (state) => ({
  ...state,
  isLoading: true,
  globalTime: new Date().valueOf()
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_INVESTING_CAMPAIGNS]: setInvestingCampaigns,
  [Types.SET_FILTERS_CAMPAINGS]: setFiltersCampaings,
  [Types.SET_INVESTING_CAMPAIGN_ERROR]: setInvestingCampaignError,
  [Types.SET_IS_LOADING]: setIsLoading,
  [Types.START_INVESTING_CAMPAIGNS]: startInvestingCampaigns
});
