import { catchError, mergeMap } from "rxjs/operators";
import { push } from "connected-react-router";
import { ofType, combineEpics } from "redux-observable";
import { Observable } from "rxjs-compat";
import InvestingCampaignsActions from "../reducers/investing-campaigns.reducer";
import { startGetPoliticsTermsHC } from "./politics-terms-hc";
import { ShowcaseConnect } from "services";

export const getFiltersInvestingCampaings = () => ({
  type: "GET_FILTERS_INVESTING_CAMPAIGNS"
});

export const getFiltersCampaings = (action$) =>
  action$.pipe(
    ofType("GET_FILTERS_INVESTING_CAMPAIGNS"),
    mergeMap(() =>
      Observable.from(new ShowcaseConnect().getFiltersCampaigns()).pipe(
        mergeMap((response) =>
          Observable.concat(
            Observable.of(
              InvestingCampaignsActions.setFiltersCampaings(response)
            )
          )
        ),
        catchError(() => Observable.of(push("/oops")))
      )
    )
  );

export const getInvestingCampaigns = (action$) =>
  action$.pipe(
    ofType("START_INVESTING_CAMPAIGNS"),
    mergeMap(({ payload: { page, limit, filters = null, isStart } }) =>
      Observable.from(
        new ShowcaseConnect().getCampaigns({
          page,
          limit,
          state: "active",
          filters
        })
      )
        .delay(2000)
        .pipe(
          mergeMap(({ campaigns, total, currentPage }) =>
            Observable.concat(
              Observable.of(startGetPoliticsTermsHC()),
              Observable.of(
                InvestingCampaignsActions.setInvestingCampaigns(
                  campaigns.map((campaign) => ({
                    ...campaign,
                    totalRows: total,
                    currentPage
                  })),
                  isStart
                )
              )
            )
          ),
          catchError((error) =>
            Observable.concat(
              Observable.of(
                InvestingCampaignsActions.setInvestingCampaignError(error)
              )
            )
          )
        )
    )
  );

export default combineEpics(getFiltersCampaings, getInvestingCampaigns);
