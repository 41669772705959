import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import { forkJoin } from "rxjs";
import App from "../../lib/app";
import RegisterActions, { RegisterTypes } from "../reducers/register.reducer";
import RegisterFormActions from "../reducers/register-form.reducer";
import { RegisterSerializer } from "../../lib/serializers/register.serializer";
import User from "../../lib/models/user.model";
import { Creators } from "../reducers/user.reducer";
import ModalActions from "../reducers/modal.reducer";
import AnalyticsActions from "../reducers/analytics.reducer";
import AnalyticsModel from "../../lib/models/analytics.model";

const Errors = ["037", "056"];

const RegisterErrors = [
  "416",
  "1002",
  "1003",
  "1012",
  "1013",
  "1014",
  "1015",
  "1016",
  "1017",
  "1018",
  "1019",
  "056"
];

const RegisterErrorEmail = "019";

const RegisterErrorsNoRedirect = ["1012", "1013", "1014", "1015"];

export const startRegisterFlow = ({ personType, tableNames }) => {
  personType =
    personType === undefined
      ? App.redux.store.getState().user.personType.personType
      : personType;
  return {
    type: "START_REGISTER_FLOW",
    payload: {
      personType,
      tableNames
    }
  };
};

const getErrorIndex = (e) => {
  let hasErrorIndex = -1;
  if (Array.isArray(e.errors)) {
    e.errors.map((error, indexError) => {
      const index = Errors.findIndex((value) => error.code === value);
      if (index >= 0) {
        hasErrorIndex = indexError;
      }
      return error;
    });
  }
  return hasErrorIndex;
};

export const registerFlow = (action$) =>
  action$.pipe(
    ofType("START_REGISTER_FLOW"),
    flatMap((action) => {
      const personType = action.payload.personType || "natural";
      return forkJoin([
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: { tables: action.payload.tableNames }
          })
        ),
        Observable.from(
          App.api.a2censo.politicsTermsHCbyPersonType({
            url: {
              personType: User.typeIds[personType]
            }
          })
        )
      ]).pipe(
        flatMap((response) => {
          if (action.payload.personType === User.type.natural) {
            return Observable.of(
              Creators.selectPersonType({
                personType: action.payload.personType,
                ...response[0]
              }),
              push("/register/natural", {
                personType: User.type.natural,
                politicsObject: response[1].last_policy,
                termsObject: response[1].last_term,
                hcObject: response[1].last_hc,
                ...response[0]
              })
            );
          }

          return Observable.of(
            Creators.selectPersonType({
              personType: action.payload.personType,
              ...response[0]
            }),
            push(`/register/${action.payload.personType}`, {
              personType: action.payload.personType,
              politicsObject: response[1].last_policy,
              termsObject: response[1].last_term,
              hcObject: response[1].last_hc,
              ...response[0]
            })
          );
        }),
        catchError((e) => {
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.goHome"
                })
              ),
              Observable.of(RegisterActions.registerFail(e)),
              Observable.of(RegisterFormActions.eraseRegisterData())
            );
          }
          return Observable.concat(
            Observable.of(RegisterActions.registerFail(e)),
            Observable.of(push("/oops")),
            Observable.of(RegisterFormActions.eraseRegisterData())
          );
        })
      );
    })
  );

export const registerSubmit = (action$) =>
  action$.pipe(
    ofType(RegisterTypes.SUBMIT_REGISTER),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.register({
          body: RegisterSerializer().serialize(action.user),
          headers: {
            tokencaptcha: action.user.tokencaptcha
          }
        })
      ).pipe(
        flatMap(() =>
          Observable.of(
            RegisterActions.registerSuccess(),
            AnalyticsActions.trackEvent(AnalyticsModel.register),
            push("/check-email", {
              title:
                action.user.personType === User.type.pyme
                  ? "checkEmail.checkYourEmailPyme"
                  : null,
              message:
                action.user.personType === User.type.pyme
                  ? "checkEmail.completedSuccessfullyPyme"
                  : null
            }),
            RegisterFormActions.eraseRegisterData()
          )
        ),
        catchError((e) => {
          let hasRegisterError = false;
          if (Array.isArray(e.errors)) {
            e.errors.map((error) => {
              const index = RegisterErrors.findIndex(
                (value) => error.code === value
              );
              if (index >= 0) {
                hasRegisterError = true;
              }
              return error;
            });
          }
          if (hasRegisterError) {
            let redirectVal = "/";
            let textOnButtonVal =
              action.user.personType === User.type.pyme
                ? ""
                : "errorModal.success";
            if (Array.isArray(e.errors)) {
              e.errors.map((error) => {
                const index = RegisterErrorsNoRedirect.findIndex(
                  (value) => error.code === value
                );
                if (index >= 0) {
                  textOnButtonVal = "register.userValidation.understand";
                  // eslint-disable-next-line default-case
                  switch (action.user.personType) {
                    case User.type.natural:
                      redirectVal = "/register/natural?personType=natural";
                      break;
                    case User.type.legal:
                      redirectVal = "/register/legal?personType=legal";
                      break;
                    case User.type.pyme:
                      redirectVal = "/register/pyme?personType=pyme";
                      break;
                  }
                }
                return error;
              });
            }

            return Observable.concat(
              Observable.of(RegisterActions.registerFail(e)),
              Observable.of(
                ModalActions.setConditionalModalState(
                  true,
                  "RegisterErrorModal",
                  {
                    errorCodes: e.errors[0].code,
                    redirectUri: redirectVal,
                    textOnButton: textOnButtonVal
                  }
                )
              )
            );
          }

          const hasIndexErrorSendEmail =
            e.errors &&
            e.errors.findIndex((error) => error.code === RegisterErrorEmail);
          if (hasIndexErrorSendEmail > -1) {
            return Observable.concat(
              Observable.of(RegisterActions.registerFail(e)),
              Observable.of(
                push("/oops", {
                  message: `register.userValidation.errorCodes.${RegisterErrorEmail}`
                })
              ),
              Observable.of(RegisterFormActions.eraseRegisterData())
            );
          }
          return Observable.concat(
            Observable.of(RegisterActions.registerFail(e)),
            Observable.of(push("/oops")),
            Observable.of(RegisterFormActions.eraseRegisterData())
          );
        })
      )
    )
  );
export default combineEpics(registerFlow, registerSubmit);
