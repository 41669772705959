import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setPersonType: ["personType"]
});

export const PersonType = Types;
export default Creators;

const INITIAL_STATE = {
  personType: ""
};

const setPersonType = (state = INITIAL_STATE, { personType }) => ({
  ...state,
  personType
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_PERSON_TYPE]: setPersonType
});
