module.exports = {
  formatData: (data) => ({
    data: {
      attributes: {
        state: data.state === 1 ? "qualified" : "no_qualified",
        certification_type: String(data.certification_type),
        expiration_date: data.expiration_date,
        file: data.file,
        quality_tax: data.quality_tax
      }
    }
  })
};
