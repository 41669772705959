import { ofType, combineEpics } from "redux-observable";
import { map } from "rxjs/operators";
import { push } from "connected-react-router";

export const startFaqSectionFlow = () => ({
  type: "START_FAQ_SECTION_FLOW"
});

export const faqSection = (action$) =>
  action$.pipe(
    ofType("START_FAQ_SECTION_FLOW"),
    map(() => push("/faq"))
  );

export default combineEpics(faqSection);
