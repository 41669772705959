import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getInterview: ["requestCampaignId"],
  getInterviewError: [],
  setInterview: ["info"]
});

export const InterviewTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  interviewData: null
};

const getInterview = (state, { requestCampaignId }) => ({
  ...state,
  isFetching: true,
  requestCampaignId
});

const setInterview = (state, { info }) => ({
  ...state,
  isFetching: false,
  info
});

const getInterviewError = (state) => ({
  ...state,
  isFetching: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_INTERVIEW]: getInterview,
  [Types.SET_INTERVIEW]: setInterview,
  [Types.GET_INTERVIEW_ERROR]: getInterviewError
});
