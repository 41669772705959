import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Heading, Button } from "@bvcco/a2censo-component-lib";
import { useTranslation } from "react-i18next";
import {
  Wrapper,
  SuperHeader,
  CloseButton,
  Container,
  ButtonContainer,
  ContainerTerms,
  Paragraph
} from "./Styles";
import closeIcon from "../../assets/images/close-btn@3x.png";
import ModalActions from "../../redux/reducers/modal.reducer";

export const handleScroll = (event, setBottomReached) => {
  const node = event.target;
  const bottom = node.scrollHeight - node.scrollTop <= node.clientHeight + 20;
  if (bottom) {
    setBottomReached(true);
  }
};
const RequestCampaignTermsModal = ({ handleAccept, text }) => {
  const [bottomReached, setBottomReached] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const setListener = (node) => {
    if (node) {
      node.addEventListener("scroll", (e) => handleScroll(e, setBottomReached));
    }
  };

  const closeModal = () => {
    dispatch(ModalActions.setConditionalModalState(false));
  };

  const handleAcceptTerms = () => {
    handleAccept();
    closeModal();
  };

  return (
    <Wrapper>
      <SuperHeader>
        <Heading hieranchy="large_secondary" fontWeight="bold" color="primary">
          {t("RequestCampaignTermsModal.title")}
        </Heading>
        <CloseButton
          data-testid="simulator-back-button"
          src={closeIcon}
          onClick={closeModal}
        />
      </SuperHeader>
      <Container>
        <ContainerTerms
          data-testid="terms-modal-content"
          id="terms-modal-content"
          ref={(ref) => {
            setListener(ref);
            if (ref) {
              const bottom =
                ref.scrollHeight - ref.scrollTop <= ref.clientHeight + 20;
              if (bottom) {
                setBottomReached(true);
              }
            }
          }}
        >
          <Paragraph>{text}</Paragraph>
        </ContainerTerms>
        <ButtonContainer>
          <Button
            dataTestid="accept-terms-request-campaign"
            id="accept-terms-request-campaign"
            disabled={!bottomReached}
            rounded={true}
            onClick={handleAcceptTerms}
            color="cornFlowerBlueBg"
          >
            {t("RequestCampaignTermsModal.accept")}
          </Button>
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
};

RequestCampaignTermsModal.propTypes = {
  handleAccept: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

export default RequestCampaignTermsModal;
