const { Serializer } = require("jsonapi-serializer");

module.exports = {
  CompanyInfoSerializer: () =>
    new Serializer("company-info-serializer", {
      pluralizeType: false,
      keyForAttribute: "camelCase",
      attributes: [
        "id",
        "user_id",
        "business_name",
        "constitution_date",
        "city_id",
        "address",
        "phone",
        "ciiu",
        "second_ciiu",
        "existence_certificate",
        "created_at",
        "updated_at",
        "send_usa_resources",
        "company_code",
        "company_id",
        "amount",
        "bank_id",
        "bank_account_type_id",
        "account_number",
        "city_retention",
        "city_consignment",
        "description",
        "what_does_comp_do",
        "business_areas",
        "instagram_link",
        "facebook_link",
        "twitter_link",
        "linkedin_link",
        "youtube_link",
        "web_page_link",
        "show_business_areas",
        "show_main_customers",
        "show_main_providers",
        "show_future_plans",
        "show_added_value",
        "current_financial_issues",
        "main_customers",
        "main_providers",
        "competitor",
        "future_plans",
        "added_value",
        "company_commercial_address"
      ]
    })
};
