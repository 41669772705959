import styled from "styled-components";

export const Container = styled.div`
  width: 1210px;
  height: 756px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-radius: 12px;

  background: ${({ theme }) =>
    `linear-gradient(137.48deg, ${theme.lightPurple} -27.08%, ${theme.colors.progressBar.color} 188.15%)`};

  ${(props) => props.theme.smartphone} {
    max-height: 100%;
    max-width: 100%;
  }
`;

export const Image = styled.img`
  height: 198px;
  width: 314px;
  margin-bottom: 50px;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.white};
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  width: 396px;
  text-align: center;
  font-size: 28px;
  line-height: 31px;
  margin-bottom: 13px;
  ${(props) => props.theme.smartphone} {
    width: 300px;
  }
`;

export const Subtitle = styled.h3`
  color: ${({ theme }) => theme.white};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 131%;
  span {
    font-weight: bold;
  }
`;

export const Button = styled.button`
  width: 215px;
  height: 49px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.brightTurquoise};
  font-family: Nunito;
  color: ${({ theme }) => theme.lightPurple};
  font-size: 16px;
  font-weight: 800;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  ${(props) => props.theme.smartphone} {
    width: 170px;
    height: 40px;
    border-radius: 18px;
    font-size: 13px;
  }
`;
