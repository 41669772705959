import React from "react";
import {
  ContainerEmpty,
  Header,
  ContainerTitle,
  Image,
  ContainerInfo,
  Paragraph
} from "./Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Heading, Button } from "@bvcco/a2censo-component-lib";

import { useTranslation } from "react-i18next";
import EmptyStateCampaigns from "assets/images/empty-state-campaigns.png";
import { TypeCertificate } from "redux/reducers/user-certificates.reducer";

export type EmptyProps = {
  onClickGoBack?: () => void;
  certificateType: TypeCertificate;
};

const Empty = ({ onClickGoBack, certificateType }: EmptyProps) => {
  const { t } = useTranslation();
  return (
    <ContainerEmpty data-testid={"empty-container"}>
      <Header>
        <Button
          dataTestid={"go-back-button"}
          onClick={onClickGoBack}
          color="whiteManatee"
          rounded={true}
          icon={{
            icon: <FontAwesomeIcon icon={faChevronLeft} />,
            position: "left"
          }}
        >
          {t("myCertificates.emptyCertificates.backButton")}
        </Button>
        <ContainerTitle>
          <Heading>
            {TypeCertificate.Retention === certificateType &&
              t("myCertificates.emptyCertificates.titleRetentionCertificates")}
            {TypeCertificate.FinancialTax === certificateType &&
              t("myCertificates.emptyCertificates.titleFinancialMovements")}
            {TypeCertificate.ReportAnual === certificateType &&
              t("myCertificates.emptyCertificates.titleReportAnnual")}
          </Heading>
        </ContainerTitle>
      </Header>
      <ContainerInfo>
        <Image src={EmptyStateCampaigns} />
        <Paragraph>
          {t("myCertificates.emptyCertificates.description.part1")}
          <strong>
            {t("myCertificates.emptyCertificates.description.bold")}
            <br />
            {t("myCertificates.emptyCertificates.description.bold2")}
          </strong>
        </Paragraph>
      </ContainerInfo>
    </ContainerEmpty>
  );
};

export default Empty;
