import React from "react";
import LoaderSVG from "../LoaderSVG/LoaderSVG";
import { Container } from "./Styles";

const Loader = () => (
  <Container>
    <LoaderSVG />
  </Container>
);

export default Loader;
