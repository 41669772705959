import React from "react";
import {
  Container,
  Card,
  ContainerCard,
  Footer,
  DottedSeparator,
  ContainerHelp,
  BoldClickHere,
  DescriptionBold,
  ContainerUpdateData,
  Icon
} from "./components";
import {
  Heading,
  Paragraph,
  Button
} from "@bvcco/a2censo-component-lib";
import { useTranslation } from "react-i18next";
import { TypeCertificate } from "../../redux/reducers/user-certificates.reducer";
import InfoIcon from "assets/images/info-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
export enum PersonTypeEnum {
  Pyme = "pyme",
  Investor = "investor"
}

export type IProps = {
  onClick: (type: number) => void;
  onClickEdit?: () => void;
  onClickHelp: () => void;
  count?: {
    certificate: number;
    retention: number;
    anualCertificates: number;
  };
  personType: PersonTypeEnum;
  disabledEdit?: boolean;
};

const MyCertificates = ({
  onClick,
  onClickHelp,
  count = { certificate: 0, retention: 0, anualCertificates: 0 },
  personType,
  onClickEdit,
  disabledEdit = true
}: IProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Heading>{t(`myCertificates.${personType}.title`)}</Heading>
        <Paragraph>
          {t(`myCertificates.${personType}.description.part1`)}
          <DescriptionBold>
            {t(`myCertificates.${personType}.description.part2`)}
          </DescriptionBold>
        </Paragraph>
        {personType === PersonTypeEnum.Pyme && (
          <ContainerUpdateData>
            <div>
              <Icon src={InfoIcon} />
              <Paragraph>
                {t("myCertificates.pyme.edit.part1")}
                <strong>{t("myCertificates.pyme.edit.part2")}</strong>
                {t("myCertificates.pyme.edit.part3")}
              </Paragraph>
            </div>

            <Button
              dataTestid={"go-edit-button"}
              onClick={onClickEdit}
              color="cornFlowerBlueBg"
              rounded={true}
              icon={{
                icon: <FontAwesomeIcon icon={faChevronRight} />,
                position: "right"
              }}
              disabled={disabledEdit}
            >
              {t("myCertificates.pyme.edit.button")}
            </Button>
          </ContainerUpdateData>
        )}
        <ContainerCard>
          <Card
            id="certificade"
            title={t(`myCertificates.${personType}.sourceRetentionCertificate`)}
            onClick={() => onClick(TypeCertificate.Retention)}
            count={count.certificate}
          />
          {personType === PersonTypeEnum.Investor && (
            <Card
              id="retencion"
              title={t("myCertificates.investor.retentionMovements")}
              onClick={() => onClick(TypeCertificate.FinancialTax)}
              count={count.retention}
            />
          )}
          {personType === PersonTypeEnum.Pyme && (
            <Card
              id="report-by-year"
              title={t("myCertificates.pyme.retentionMovements")}
              onClick={() => onClick(TypeCertificate.ReportAnual)}
              count={count.anualCertificates}
            />
          )}
        </ContainerCard>
      </Container>
      <Footer>
        <DottedSeparator />
        <ContainerHelp>
          <Paragraph color="regentGray" align="left" fontWeight="bold">
            {t("myCertificates.help.part1")}
            <BoldClickHere onClick={onClickHelp} data-testid="help-button">
              {t("myCertificates.help.boldPart")}
            </BoldClickHere>
            {t("myCertificates.help.part2")}
          </Paragraph>
        </ContainerHelp>
      </Footer>
    </>
  );
};

export default MyCertificates;
