const { Serializer } = require("jsonapi-serializer");

module.exports = {
  ConfirmDataPymeSerializer: (data, meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "name",
        "document_type",
        "document_number",
        "person_type",
        "user_type_id",
        "ciiu",
        "phone_number",
        "city_id",
        "address",
        "second_ciiu",
        "dv"
      ]
    })
};
