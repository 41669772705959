/* eslint-disable camelcase */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Paragraph } from "@bvcco/a2censo-component-lib";
import {
  Container,
  Divider,
  CloseButton,
  Icon,
  Button,
  ButtonsContainer,
  ParagraphContainer
} from "./Styles";

import ConfirmDataPymeActions from "../../redux/reducers/confirm-data-pyme";

import ModalActions from "../../redux/reducers/modal.reducer";

const ConfirmDataPymeModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSubmit, setSubmit] = useState(false);

  const handleClick = (data) => {
    setSubmit(true);
    dispatch(ConfirmDataPymeActions.setFormValues(data));
  };

  return (
    <Container>
      <CloseButton
        id="close-button"
        onClick={() => dispatch(ModalActions.setConditionalModalState(false))}
      >
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <ParagraphContainer>
        <Paragraph size="large" color="black">
          {t("confirmDataPyme.DataPymeModal.confirmationMessage")}
        </Paragraph>
      </ParagraphContainer>

      <Divider />
      <ButtonsContainer>
        <Button
          id="back-button"
          color="primary2"
          rounded={true}
          outlined={true}
          onClick={() => dispatch(ModalActions.setConditionalModalState(false))}
        >
          {t("confirmDataPyme.DataPymeModal.return")}
        </Button>
        <Button
          id="continue-button"
          rounded={true}
          onClick={() => handleClick(props)}
          disabled={isSubmit}
        >
          {t(
            isSubmit
              ? "confirmDataPyme.loading"
              : "confirmDataPyme.DataPymeModal.sure"
          )}
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export default ConfirmDataPymeModal;
