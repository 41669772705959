import styled from "styled-components";

export const ConfirmContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f5f6f8;
  flex-direction: column;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (min-width: 1024px) and (max-width: 1439px) {
    padding: 10px 8%;
  }

  padding: 10px 20%;

  ${(props) => props.theme.tablet} {
    padding: 10px 4%;
  }
`;

export const SubTitle = styled.p`
  font-size: 23px;
  line-height: 30px;
  padding: 20px 16px;
  font-family: "Roboto Regular", sans-serif;
  color: ${(props) => props.theme.tuatara};
  ${(props) => props.theme.smartphone} {
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => props.theme.ironSideGray};
  }
`;

export const GoBack = styled.div`
  display: flex;
  height: 116px;
  width: 100%;
  ${(props) => props.theme.tablet} {
    height: 60px;
  }
`;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 25px;
  width: 30px;
  ${(props) => props.theme.tablet} {
    margin-top: 20px;
  }
`;

export const BackButtonImage = styled.img`
  height: 26px;
  width: 26px;
  object-fit: contain;
`;

export const Destiny = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(props) => props.theme.tablet} {
    margin-bottom: 15px;
  }
`;

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  list-style: none;
  margin: 60px 0px 90px 0px;
  justify-content: space-between;
  ${(props) => props.theme.smartphone} {
    flex-direction: column;
    margin: 10px 0px 60px 0px;
  }
`;

export const ConfirmFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ade3f5;
  height: 74px;
  margin-top: 36px;
  bottom: 0;
  position: fixed;
  width: 100%;
  ${(props) => props.theme.smartphone} {
    height: 66px;
  }
`;

export const ConfirmButton = styled.button`
  width: 215px;
  height: 49px;
  background-color: ${(props) => props.theme.white};
  border-radius: 24px;
  border: none;
  color: ${(props) => props.theme.brightTurquoise};
  font-family: "Nunito";
  font-size: 17px;
  font-weight: 800;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const Form = styled.form`
  margin: 0 16px;
  max-width: 1110px;
  border-radius: 10.5px;
  background-color: #ffffff;
  overflow: hidden;
  width: 100%;
  margin-bottom: 76px;
  ${(props) => props.theme.smartphone} {
    width: 90%;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
`;

export const Description = styled.strong`
  color: ${({ theme }) => theme.brightTurquoise};
`;

export const FormHeader = styled.div`
  align-items: center;
  color: #31302f;
  display: flex;
  font-size: 23px;
  font-weight: bold;
  flex-direction: row;
  height: 72px;
  padding: 0 16px;
`;

export const TextHeader = styled.div`
  align-items: center;
  color: #31302f;
  display: flex;
  justify-content: space-between;
  font-size: 23px;
  font-weight: bold;
  flex-direction: row;
  height: 48px;
  padding: 0 16px;
  width: 100%;
  white-space: pre-line;
  ${(props) => props.theme.smartphone} {
    font-size: 14px;
  }
`;

export const FormRow = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: row;
  ${(props) => props.theme.smartphone} {
    flex-wrap: wrap;
    min-height: 48px;
    height: auto;
  }
  height: 65px;
  width: 100%;
`;

export const FormColum = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : "100%")};
  ${(props) =>
    props.borders
      ? `
    ${
      props.borders.right
        ? `border-right: 0.7px ${props.theme.backGray} solid;`
        : ""
    }
    ${
      props.borders.bottom
        ? `border-bottom: 0.7px ${props.theme.backGray} solid;`
        : ""
    }
    ${
      props.borders.top
        ? `border-top: 0.7px ${props.theme.backGray} solid;`
        : ""
    }
  `
      : ""}
    ${(props) => props.theme.smartphone} {
      ${(props) =>
        props.mobileBorders
          ? `
        ${
          props.mobileBorders.bottom
            ? `border-bottom: 0.7px ${props.theme.backGray} solid;`
            : ""
        }
      `
          : ""}
      width: ${(props) => (props.mobileWidth ? props.mobileWidth : "100%")};
      ${(props) => props.mobileWidth !== "100%"} {
        border-right-width: 0px;
        border-left-width: 0px;
      }
    }
`;

export const Draw = styled.div`
  padding: ${(props) => (props.customPadding ? props.customPadding : "0 24px")};
  ${(props) =>
    props.margins
      ? `
    ${props.margins.right ? `margin-right: ${props.margins.right};` : ""}
    ${props.margins.left ? `margin-left:  ${props.margins.left};` : ""}
    ${props.margins.bottom ? `margin-bottom:  ${props.margins.bottom};` : ""}
    ${props.margins.top ? `margin-top:  ${props.margins.top};` : ""}
  `
      : ""}
  max-width: 1140px;
  ${(props) => props.theme.smartphone} {
    margin-top: 0px;
    margin-left: 8px;
    padding: 0 16px;
  }
  width: 100%;
`;

export const Circle = styled.div`
  height: 15px;
  width: 15px;
  background: none;
  border-radius: 50%;
`;

export const Line = styled.div`
  height: 25px;
  width: 1px;
  margin-left: 7px;
  background: none;

  ${(props) => props.theme.smartphone} {
    height: 16px;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 15px;
  font-family: "Roboto";
  font-size: 19px;
  width: 100%;
  color: ${(props) => props.theme.tuatara};
  background-color: ${(props) => props.theme.white};

  ${(props) => props.theme.smartphone} {
    padding: 15px 10px;
    font-size: 13px;
  }
`;

export const ContainerPSE = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
  align-items: center;
  padding: 0px 16px;
  width: 100%;
`;

export const IconPSE = styled.img`
  width: 40px;
  object-fit: contain;
  margin-right: 15px;
`;

export const DescriptionPSE = styled.p`
  font-size: 23px;
  line-height: 26px;
  color: ${(props) => props.theme.dustyGray};
  ${(props) => props.theme.smartphone} {
    font-size: 12px;
    line-height: 15px;
  }
`;
