import styled from "styled-components";

export const PillContainer = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: space-evenly;
  padding: 25px 40px;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.brightTurquoise};
  & img:first-child {
    width: 132px;
    height: 27px;
  }
  ${({ theme }) => theme.desktop} {
    padding: 20px 50px;
  }
  ${({ theme }) => theme.smartphone} {
    padding: 27px 20px;
    width: 314px;
    background: ${({ theme }) => theme.curiousBlue};
  }
`;
