import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { push } from "connected-react-router";
import AccountRecoveryAction from "../../redux/reducers/account-recovery.reducer";
import {
  Container,
  Paragraph,
  ButtonsContainer,
  Button,
  PageTitle,
  Anchor
} from "./Styles";

import smartphoneIcon from "assets/images/smartphone_icon.png";
import emailIcon from "assets/images/email_icon.png";

const AccountRecoveryOTP = ({ location, history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setLoader] = useState(false);
  if (!location.state) {
    history.replace({ state: undefined });
    dispatch(push("/login"));
    return <div />;
  }
  const { email } = location.state;
  const requestOtp = (method) => {
    if (!isLoading) {
      dispatch(
        AccountRecoveryAction.startAccountRecoveryOtpRequest({
          email,
          method
        })
      );
    }
    return setLoader(true);
  };
  return (
    <Container>
      <PageTitle>{t("accountRecovery.title")}</PageTitle>
      <Paragraph>
        <p>{t("accountRecovery.subtitle")}</p>
      </Paragraph>
      <ButtonsContainer>
        <Button
          data-testid="sms-recovery-button"
          onClick={() => requestOtp("sms")}
          isLoading={isLoading}
        >
          <img src={smartphoneIcon} alt="smartphone_icon" />
          <span>{t("accountRecovery.sms")}</span>
        </Button>
        <Button
          data-testid="email-recovery-button"
          onClick={() => requestOtp("email")}
          isLoading={isLoading}
        >
          <img src={emailIcon} alt="email_icon" />
          <span>{t("accountRecovery.email")}</span>
        </Button>
      </ButtonsContainer>
      <Anchor
        data-testid="cancel-link"
        onClick={(e) => {
          e.preventDefault();
          dispatch(push("/login"));
        }}
      >
        {t("accountRecovery.cancelLink")}
      </Anchor>
    </Container>
  );
};

AccountRecoveryOTP.propTypes = {
  location: PropTypes.shape({
    state: {
      email: PropTypes.string
    }
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func
  }).isRequired
};

export default AccountRecoveryOTP;
