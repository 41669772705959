import styled from "styled-components";

export const ContainerOptions = styled.div`
  display: flex;

  > div {
    width: 139px;
    border-radius: 5px;
    margin-right: 43px;
    ${({ theme }) => theme.smartphone} {
      margin-right: 37px;
    }
  }
  div {
    background-color: ${({ theme }) => theme.wildSand};
    width: 200px;
  }
`;

export const OptionIMG = styled.img`
  width: 35px;
  height: 35px;
  cursor: pointer;
  object-fit: contain;
`;

export const ButtonOption = styled.button`
  border: 0px;
  outline: none;
  background: white;
  padding: 0px;
  margin-right: 26px;
`;
