import styled, { css } from "styled-components";
import ReactTooltip from "react-tooltip";

export const TooltipIcon = styled.img`
  width: 20px;
`;

export const Tooltip = styled(ReactTooltip)`
  ${({ theme }) => css`
    background: ${theme.colors.tooltipColor.bgColor} !important;
    color: ${theme.white};
    width: 360px;
    text-align: center;
  `}
`;
