import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setNewFileUpload: ["file"],
  endUploadFile: ["fileName", "key"],
  errorUploadFile: ["fileName", "error"]
});

export const fileUploaderTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  files: [],
  isLoading: false
};

export const endNewUpload = (state, { fileName, key }) => {
  const fileIndex = state.files.findIndex((file) => file.name === fileName);

  const files = [...state.files];
  files[fileIndex] = {
    ...files[fileIndex],
    loading: false,
    awsKey: key
  };

  return {
    ...state,
    files,
    isLoading: false
  };
};

export const errorInUpload = (state, { fileName, error }) => {
  const fileIndex = state.files.findIndex((file) => file.name === fileName);

  const files = [...state.files];
  files[fileIndex] = {
    ...files[fileIndex],
    loading: false,
    error
  };

  return {
    ...state,
    files,
    isLoading: false
  };
};

export const setNewUpload = (state, { file }) => ({
  ...state,
  files: [...state.files, { ...file, loading: true }],
  isLoading: true
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_NEW_FILE_UPLOAD]: setNewUpload,
  [Types.END_UPLOAD_FILE]: endNewUpload,
  [Types.ERROR_UPLOAD_FILE]: errorInUpload
});
