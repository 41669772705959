import moment from "moment";

// Example format: 2 mayo 2021
export const formatDdTextMmYear = (date) =>
  moment(date, "YYYY-MM-DD").format("DD MMMM YYYY");

export const formatToLocalDateTime = (date) =>
  moment(date)
    .format("yyyy-MM-DD HH:mm:ss")
    .toString();

export const isAfterToday = (date, dateFormat = "YYYY-MM-DD") =>
  moment(date).isAfter(moment().format(dateFormat));

export const getISODate = (date) => date.toISOString().split("T")[0];

export const isBefore = (date1, date2) => moment(date1).isBefore(date2);

export const isAfter = (date1, date2) => moment(date1).isAfter(date2);

export const toDate = (date) => moment(date).toDate();

export const calculateSecondsDiff = (dateServer, dateAvailable) =>
  moment(dateAvailable, "YYYY-MM-DD HH:mm:ss").diff(
    moment(dateServer, "YYYY-MM-DD HH:mm:ss"),
    "seconds"
  );

export const dateToMomentDate = (date) =>
  moment(date, "YYYY-MM-DD HH:mm:ss").subtract(5, "hours");

export const colombianDateToGlobalDate = (date) => {
  const colombiaDate = new Date(date);

  return new Date(colombiaDate.setHours(colombiaDate.getHours() + 5));
};

export const colombianDateToGlobalDates = (date) => {
  const colombiaDate = new Date(date);

  return new Date(colombiaDate.setHours(colombiaDate.getHours()));
};

export const globalDateToColombianDate = (date) => {
  const globalDate = new Date(date);

  return new Date(globalDate.setHours(globalDate.getHours()));
};
export const addSeconds = (date, ms) =>
  `${moment(date, "YYYY-MM-DD HH:mm:ss")
    .add(ms, "ms")
    .format("YYYY-MM-DDTHH:mm:ss")}.${date ? date.split(".")[1] : "000Z"}`;
