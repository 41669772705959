import styled from "styled-components";

type Props = {
  hasButton: boolean;
};

export const ContainerDescription = styled.div<Props>`
  & > button {
    width: 141px;
    height: 43px;
    min-width: 141px;
    margin-right: 12px;
    border: 1px solid ${({ theme }) => theme.fiord};
    padding: 8px 17px 8px 15px;
    font-size: 16px;
  }
  ${({ hasButton }) =>
    hasButton ? "margin-top: 15px; display: flex;" : "margin-top: 5px;"}

  & > p {
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    line-height: 130.69%;
  }
`;
