import { createReducer, createActions } from "reduxsauce";
import { IEquityReducer } from "./types";

const { Types, Creators } = createActions({
  setPopupCount: ["count"]
});

export const Equity = Types;
export default Creators;

const INITIAL_STATE: IEquityReducer = {
  infoPopupCount: 0
};

const setPopupCount = (
  state: IEquityReducer,
  { count }: { count: number }
) => ({
  ...state,
  infoPopupCount: count
});

export const equityReducer = createReducer(INITIAL_STATE, {
  [Types.SET_POPUP_COUNT]: setPopupCount
});
