import styled from "styled-components";

export const DownloadFileContainer = styled.div`
  display: flex;
  align-items: center;
  height: 49px;
  width: 100%;

  ${(props) => props.theme.tablet} {
    justify-content: space-between;
  }
`;

export const IconFile = styled.img`
  width: 24px;
  height: 24px;
`;

export const Text = styled.p`
  margin-left: 10px;
  margin-right: 10px;
  color: ${(props) => props.theme.martinique};
  font-size: 14px;

  ${(props) => props.theme.tablet} {
    font-size: 12px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: space-around;
  padding: 0 25px 0 25px;
  width: 160px;
  height: 49px;
  border: 1px solid ${(props) => props.theme.brightTurquoise};
  color: ${(props) => props.theme.brightTurquoise};
  background: transparent;
  border-radius: 27.5px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  align-items: center;

  ${(props) => props.theme.tablet} {
    font-size: 12px;
    width: 122px;
    padding: 0 15px 0 15px;
    height: 42px;
  }
`;

export const IconArrow = styled.img`
  width: 13px;
  height: 19px;

  ${(props) => props.theme.tablet} {
    width: 10px;
    height: 16px;
  }
`;
