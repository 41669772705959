module.exports = {
  AvailabilityValidationSerializer: (
    value,
    campaignId,
    type
  ) => ({
    meta: {},
    data: {
      attributes: {
        value,
        campaign_id: campaignId,
        type
      }
    }
  })
};
