import styled from "styled-components";

export const Over = styled.div`
  position: absolute;
  display: flex;

  font-family: ${({ theme }) => theme.fontFamily.primary};

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;

  color: ${({ theme }) => theme.acentuar};

  z-index: 9;

  background: rgba(255, 255, 255, 0.86);

  .over-fail-error-api {
    width: 450px;
    margin: auto;
  }

  .over-fail-attemps {
    width: 330px;
    margin: auto;
  }

  > div {
    text-align: center;
    width: 451px;
    > div {
      font-size: 20px;
    }

    button {
      margin-top: 15px;
      width: 141px;
    }
  }

  ${({ theme }) => theme.breakpoints.whenSmartphone} {
    > div {
      width: 291px;
      > div {
        font-size: 18px;
      }
    }

    .over-fail-error-api {
      width: 291px;
      margin: auto;
    }

    .over-fail-attemps {
      width: 258px;
      margin: auto;
    }

    position: fixed;
    align-items: flex-start;
    padding-top: 96px;
  }
`;
