import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setCountry: ["country"]
});

export const contactTypes = Types;
export default Creators;

const INITIAL_STATE = {
  country: []
};

const setCountry = (state, { country }) => ({ ...state, country });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_COUNTRY]: setCountry
});
