import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Container,
  RadioList,
  Item,
  RadioButton,
  Caption,
  SelectList,
  List,
  SelectButton,
  QuestionLabel
} from "./Styles";

const SelectionList = ({
  onChange,
  type,
  questionLabel,
  value,
  items,
  testId,
  lightFont,
  showTooltip,
  error,
  isSummary
}) => {
  const { t } = useTranslation();
  const [listToggle, setListToggle] = useState(false);
  const onChangeValue = (newValue) => {
    if (type === "list") {
      setListToggle(false);
    }
    onChange(newValue);
  };

  const toggleList = () => setListToggle(!listToggle);
  const renderTooltip = showTooltip && error;
  const renderRadioList = () => (
    <RadioList data-testid={`${testId}_selection_radio`}>
      <SelectButton type={type}>
        <QuestionLabel lightFont={lightFont}>{questionLabel}</QuestionLabel>
        {renderTooltip && (
          <>
            <div
              className="input__tooltip-logo-container"
              data-tip={true}
              data-for={`errorInfo-${testId}`}
            >
              <img
                className="input__tooltip_logo"
                src="/assets/images/error.png"
                alt="error"
              />
            </div>

            <ReactTooltip
              className="error-tooltip__back"
              id={`errorInfo-${testId}`}
              place="bottom"
              type="error"
              effect="solid"
            >
              <div className="error-tooltip__container">
                <p className="error-tooltip__text--title">{t(error)}</p>
              </div>
            </ReactTooltip>
          </>
        )}
      </SelectButton>
      {items.map((item) => (
        <Item
          onClick={() => onChangeValue(item.value)}
          key={item.value}
          data-testid={`${testId}_radio_button_${item.value}`}
        >
          <Caption value={item.value} currentValue={value} isSummary={isSummary}>
            {item.label}
          </Caption>
          <RadioButton value={item.value} currentValue={value} />
        </Item>
      ))}
    </RadioList>
  );

  const renderCurrentValue = (currentValue) =>
    currentValue === "" || currentValue == null
      ? questionLabel
      : items.filter((item) => item.value === currentValue)[0].label;

  const renderSelectionList = () => (
    <SelectList isOpen={listToggle} isSummary={isSummary}>
      <SelectButton
        type={type}
        isSummary={isSummary}
        onClick={toggleList}
        id="list-toggle"
        data-testid={`${testId}_selection_list`}
      >
        <QuestionLabel lightFont={lightFont} isSummary={isSummary}>
          {renderCurrentValue(value)}
        </QuestionLabel>
      </SelectButton>
      <List isOpen={listToggle}>
        {items.map((item) => (
          <Item
            isSummary={isSummary}
            isList={true}
            onClick={() => onChangeValue(item.value)}
            key={item.value}
            data-testid={`${testId}_option_${item.value}`}
          >
            <Caption value={item.value} currentValue={value} isSummary={isSummary}>
              {item.label}
            </Caption>
          </Item>
        ))}
      </List>
    </SelectList>
  );
  return (
    <Container>
      {type === "list" ? renderSelectionList() : renderRadioList()}
    </Container>
  );
};

SelectionList.propTypes = {
  type: PropTypes.oneOf(["list", "radio"]),
  items: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  questionLabel: PropTypes.string.isRequired,
  testId: PropTypes.string,
  lightFont: PropTypes.bool,
  showTooltip: PropTypes.bool,
  error: PropTypes.string,
  isSummary: PropTypes.bool
};

SelectionList.defaultProps = {
  type: "radio",
  items: [],
  testId: "",
  lightFont: false,
  showTooltip: false,
  error: "",
  isSummary: false
};

export default SelectionList;
