import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FilterSettings from "assets/icons/Filter-Settings.png";
import ChevronDown from "assets/icons/Chevron-down.png";
import Triangle from "assets/icons/Triangle.png";
import PathUp from "assets/icons/PathUp.png";
import filterInvest from "../../lib/models/filterInvest.mode";
import { isMobile } from "react-device-detect";
import "./FilterBy.scss";

const FilterBy = ({ filterBySearch, isDisabled }: any) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [activeBusinessLine, setActiveBusinessLine] = useState(true);
  const [activeInvestmentState, setActiveInvestmentState] = useState(true);
  const [activeCampaignState, setActiveCampaignState] = useState(true);
  const [filterByLine, setFilterByLine] = useState(
    filterInvest.businessLine.defaultValue
  );
  const [filterByInvestmentState, setFilterByInvestmentState] = useState(
    filterInvest.investmentState.defaultValue
  );
  const [filterByCampaignState, setFilterByCampaignState] = useState(
    filterInvest.campaignState.defaultValue
  );
  const filterByRef: any = useRef(null);

  const { t } = useTranslation();

  const onRestoreFilter = () => {
    setFilterByLine(filterInvest.businessLine.defaultValue);
    setFilterByInvestmentState(filterInvest.investmentState.defaultValue);
    setFilterByCampaignState(filterInvest.campaignState.defaultValue);
    filterBySearch(
      filterInvest.businessLine.defaultValue,
      filterInvest.investmentState.defaultValue,
      filterInvest.campaignState.defaultValue
    );
    setOptionsVisible(false);
    setActiveBusinessLine(true);
    setActiveInvestmentState(true);
    setActiveCampaignState(true);
  };

  const onfilter = () => {
    setOptionsVisible(false);
    filterBySearch(
      filterByLine,
      filterByInvestmentState,
      filterByCampaignState
    );
  };

  const toggleOptions = () => setOptionsVisible(!optionsVisible);

  const handleClickOutside = (event: any) => {
    if (
      filterByRef.current &&
      !filterByRef.current.contains(event.target) &&
      optionsVisible
    ) {
      toggleOptions();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  return (
    <div
      ref={filterByRef}
      className={`FilterBy-container ${isDisabled ? "disabled" : ""}`}
    >
      <div
        data-testid="option"
        id="FilterBy"
        className="Filter-label"
        onClick={toggleOptions}
      >
        <img src={FilterSettings} />
        <p>{t("searchInvestDetail.by")}</p>
        <img
          className={`${optionsVisible ? "rotate" : ""}`}
          src={ChevronDown}
        />
      </div>

      {optionsVisible ? (
        <div className="OptionFilter-container">
          <div className="OptionFilter__shadow">
            <div className="triangle">
              <img src={Triangle} />
            </div>
            <div className="OptionFilter__scroll">
              <div className="OptionFilter">
                <div className="filtersBox-title__container">
                  <p className="filtersBox-title">{`${t(
                    "searchInvestDetail.by"
                  )}:`}</p>
                </div>
                <div className="filtersBox">
                  <div className="acordionFilter">
                    <input
                      className="acordion-checkbox"
                      type="checkbox"
                      id="filter1"
                      data-testid="filter1"
                      onClick={() => setActiveBusinessLine(!activeBusinessLine)}
                      checked={activeBusinessLine}
                      disabled={!isMobile}
                    />
                    <label className="acordion-label borde" htmlFor="filter1">
                      <p>{t("searchInvestDetail.businessLine")}</p>
                      <img src={PathUp} />
                    </label>
                    <div className="acordion-content">
                      <div
                        className="acordion-option"
                        data-testid="optionLineAll"
                        onClick={() => {
                          setFilterByLine(filterInvest.businessLine.todos);
                        }}
                      >
                        <div
                          className={`check-option ${
                            filterByLine === filterInvest.businessLine.todos
                              ? "background__check"
                              : "background__no-check"
                          }`}
                        />
                        <p>{t("searchInvestDetail.businessLineOp.todos")}</p>
                      </div>
                      <div
                        className="acordion-option"
                        data-testid="optionLineDebt"
                        onClick={() => {
                          setFilterByLine(filterInvest.businessLine.debt);
                        }}
                      >
                        <div
                          className={`check-option ${
                            filterByLine === filterInvest.businessLine.debt
                              ? "background__check"
                              : "background__no-check"
                          }`}
                        />
                        <p>{t("searchInvestDetail.businessLineOp.deuda")}</p>
                      </div>
                      <div
                        className="acordion-option"
                        data-testid="optionLineShares"
                        onClick={() => {
                          setFilterByLine(filterInvest.businessLine.shares);
                        }}
                      >
                        <div
                          className={`check-option ${
                            filterByLine === filterInvest.businessLine.shares
                              ? "background__check"
                              : "background__no-check"
                          }`}
                        />
                        <p>{t("searchInvestDetail.businessLineOp.acciones")}</p>
                      </div>
                      <div
                        className="acordion-option margin"
                        data-testid="optionLineNote"
                        onClick={() => {
                          setFilterByLine(
                            filterInvest.businessLine.convertibleNote
                          );
                        }}
                      >
                        <div
                          className={`check-option ${
                            filterByLine ===
                            filterInvest.businessLine.convertibleNote
                              ? "background__check"
                              : "background__no-check"
                          }`}
                        />
                        <p>{t("searchInvestDetail.businessLineOp.nota")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="acordionFilter">
                    <input
                      className="acordion-checkbox"
                      type="checkbox"
                      data-testid="filter2"
                      id="filter2"
                      onClick={() =>
                        setActiveInvestmentState(!activeInvestmentState)
                      }
                      checked={activeInvestmentState}
                      disabled={!isMobile}
                    />
                    <label className="acordion-label borde" htmlFor="filter2">
                      <p>{t("searchInvestDetail.investmentState")}</p>
                      <img src={PathUp} />
                    </label>
                    <div className="acordion-content">
                      <div
                        className="acordion-option"
                        data-testid="optionInvExchange"
                        onClick={() => {
                          setFilterByInvestmentState(
                            filterByInvestmentState ===
                              filterInvest.investmentState.exchange
                              ? filterInvest.investmentState.defaultValue
                              : filterInvest.investmentState.exchange
                          );
                        }}
                      >
                        <div
                          className={`check-option ${
                            filterByInvestmentState ===
                            filterInvest.investmentState.exchange
                              ? "background__check"
                              : "background__no-check"
                          }`}
                        />
                        <p>
                          {t("searchInvestDetail.investmentStateOp.exchange")}
                        </p>
                      </div>
                      <div
                        className="acordion-option"
                        data-testid="optionInvActive"
                        onClick={() => {
                          setFilterByInvestmentState(
                            filterByInvestmentState ===
                              filterInvest.investmentState.active
                              ? filterInvest.investmentState.defaultValue
                              : filterInvest.investmentState.active
                          );
                        }}
                      >
                        <div
                          className={`check-option ${
                            filterByInvestmentState ===
                            filterInvest.investmentState.active
                              ? "background__check"
                              : "background__no-check"
                          }`}
                        />
                        <p>
                          {t("searchInvestDetail.investmentStateOp.active")}
                        </p>
                      </div>
                      <div
                        className="acordion-option  margin"
                        data-testid="optionInvCanceled"
                        onClick={() => {
                          setFilterByInvestmentState(
                            filterByInvestmentState ===
                              filterInvest.investmentState.canceled
                              ? filterInvest.investmentState.defaultValue
                              : filterInvest.investmentState.canceled
                          );
                        }}
                      >
                        <div
                          className={`check-option ${
                            filterByInvestmentState ===
                            filterInvest.investmentState.canceled
                              ? "background__check"
                              : "background__no-check"
                          }`}
                        />
                        <p>
                          {t("searchInvestDetail.investmentStateOp.canceled")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="acordionFilter border-right">
                    <input
                      className="acordion-checkbox"
                      type="checkbox"
                      data-testid="filter3"
                      id="filter3"
                      onClick={() =>
                        setActiveCampaignState(!activeCampaignState)
                      }
                      checked={activeCampaignState}
                      disabled={!isMobile}
                    />
                    <label className="acordion-label" htmlFor="filter3">
                      <p>{t("searchInvestDetail.campaignState")}</p>
                      <img src={PathUp} />
                    </label>
                    <div className="acordion-content">
                      <div
                        className="acordion-option"
                        data-testid="optionCamActive"
                        onClick={() => {
                          setFilterByCampaignState(
                            filterByCampaignState ===
                              filterInvest.campaignState.active
                              ? filterInvest.campaignState.defaultValue
                              : filterInvest.campaignState.active
                          );
                        }}
                      >
                        <div
                          className={`check-option ${
                            filterByCampaignState ===
                            filterInvest.campaignState.active
                              ? "background__check"
                              : "background__no-check"
                          }`}
                        />
                        <p>{t("searchInvestDetail.campaignStateOp.active")}</p>
                      </div>
                      <div
                        className="acordion-option"
                        data-testid="optionCamClose"
                        onClick={() => {
                          setFilterByCampaignState(
                            filterByCampaignState ===
                              filterInvest.campaignState.close
                              ? filterInvest.campaignState.defaultValue
                              : filterInvest.campaignState.close
                          );
                        }}
                      >
                        <div
                          className={`check-option ${
                            filterByCampaignState ===
                            filterInvest.campaignState.close
                              ? "background__check"
                              : "background__no-check"
                          }`}
                        />
                        <p>{t("searchInvestDetail.campaignStateOp.close")}</p>
                      </div>
                      <div
                        className="acordion-option  margin"
                        data-testid="optionCamAwarded"
                        onClick={() => {
                          setFilterByCampaignState(
                            filterByCampaignState ===
                              filterInvest.campaignState.awarded
                              ? filterInvest.campaignState.defaultValue
                              : filterInvest.campaignState.awarded
                          );
                        }}
                      >
                        <div
                          className={`check-option ${
                            filterByCampaignState ===
                            filterInvest.campaignState.awarded
                              ? "background__check"
                              : "background__no-check"
                          }`}
                        />
                        <p>{t("searchInvestDetail.campaignStateOp.awarded")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filtersBox-container__button">
                <button
                  id="bnt_restore"
                  data-testid="btnRestore"
                  className="restore"
                  onClick={onRestoreFilter}
                >
                  <p>{t("searchInvestDetail.Restore")}</p>
                </button>
                <button
                  data-testid="btnFilter"
                  id="btn_filter"
                  className="filter"
                  onClick={onfilter}
                >
                  <p>{t("searchInvestDetail.Filter")}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FilterBy;
