import { catchError, flatMap, map } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import CampaignStepperActions, {
  CampaignStepperTypes
} from "../reducers/create-campaign-stepper.reducer";
import { ChangeStageSerializer } from "../../lib/serializers/changeStage.serializer";
import { sendPublishConfirmationEmailSerializer } from "../../lib/serializers/sendPublishConfirmationEmail.serializer";
import RequestCampaignActions from "../reducers/request-campaign.reducer";
import AlertActions from "../reducers/alert.reducer";
import LoginActions from "../reducers/login.reducer";
import ModalActions from "../reducers/modal.reducer";

export const getStageList = (action$) =>
  action$.pipe(
    ofType(CampaignStepperTypes.FETCH_STAGE_LIST),
    flatMap(({ requestCampaignId }) =>
      Observable.from(
        App.api.a2censo.stateStageRequestCampaign({
          url: { requestCampaignId }
        })
      ).pipe(
        map((response) =>
          CampaignStepperActions.fetchStageListSuccess(response)
        ),
        catchError(() => Observable.of(CampaignStepperActions.requestError()))
      )
    )
  );

export const changeStateStage = (action$, state$) =>
  action$.pipe(
    ofType(CampaignStepperTypes.CHANGE_STATE_STAGE),
    flatMap(({ from }) => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = state$;
      const documentsToLoad =
        state$.value.campaignDocumentManagment?.documentsToLoad ?? [];

      const documentsToUpdate =
        state$.value.campaignDocumentManagment?.documentsToUpdate ?? [];

      return Observable.from(
        App.api.a2censo.changeRequestCampaignState({
          url: { requestCampaignId },
          body: ChangeStageSerializer().serialize({
            from,
            documentsToLoad,
            documentsToUpdate
          })
        })
      ).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(
              CampaignStepperActions.fetchStageListSuccess(response)
            ),
            Observable.of(
              RequestCampaignActions.fetchRequestCampaignInfo({
                id: requestCampaignId
              })
            )
          )
        ),
        catchError(() => Observable.of(CampaignStepperActions.requestError()))
      );
    })
  );

export const changeStateStageLogout = (action$, state$) =>
  action$.pipe(
    ofType(CampaignStepperTypes.CHANGE_STATE_STAGE_LOGOUT),
    flatMap(({ from }) => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = state$;
      const documentsToLoad =
        state$.value.campaignDocumentManagment?.documentsToLoad ?? [];

      const documentsToUpdate =
        state$.value.campaignDocumentManagment?.documentsToUpdate ?? [];

      return Observable.from(
        App.api.a2censo.changeRequestCampaignState({
          url: { requestCampaignId },
          body: ChangeStageSerializer().serialize({
            from,
            documentsToLoad,
            documentsToUpdate
          })
        })
      ).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(
              CampaignStepperActions.fetchStageListSuccess(response)
            ),
            Observable.of(
              ModalActions.setConditionalModalState(
                true,
                "ValidationPymeModal",
                {
                  code: "CODEBTORS_001"
                }
              )
            )
          )
        ),
        catchError(() => Observable.of(CampaignStepperActions.requestError()))
      );
    })
  );

export const sendPublishConfirmationEmail = (action$) =>
  action$.pipe(
    ofType(CampaignStepperTypes.SEND_PUBLISH_CONFIRMATION_EMAIL),
    flatMap(({ campaignId, companyName }) =>
      Observable.from(
        App.api.a2censo.sendPublishConfirmationEmail({
          body: sendPublishConfirmationEmailSerializer().serialize({
            campaignId,
            companyName
          })
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(CampaignStepperActions.changeStateStage()),
            Observable.of(RequestCampaignActions.finishRequestCampaignPublish())
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.concat(
              Observable.of(LoginActions.loginReset()),
              Observable.of(
                RequestCampaignActions.finishRequestCampaignPublish()
              )
            );
          }
          return Observable.concat(
            Observable.of(
              AlertActions.setAlertState("error", "alert.tryAgain")
            ),
            Observable.of(RequestCampaignActions.finishRequestCampaignPublish())
          );
        })
      )
    )
  );

export default combineEpics(
  getStageList,
  changeStateStage,
  changeStateStageLogout,
  sendPublishConfirmationEmail
);
