import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  submitRegister: ["user"],
  registerSuccess: [],
  registerFail: ["error"]
});

export const RegisterTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  error: ""
};

const submitRegister = (state) => ({ ...state, isFetching: true });

const registerSuccess = (state) => ({ ...state, isFetching: false });

const registerFail = (state) => ({
  ...state,
  isFetching: false,
  error: registerFail
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_REGISTER]: submitRegister,
  [Types.REGISTER_SUCCESS]: registerSuccess,
  [Types.REGISTER_FAIL]: registerFail
});
