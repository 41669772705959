import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { Container } from "./components";
import { Paragraph, Button } from "@bvcco/a2censo-component-lib";
import MoldalActions from "redux/reducers/modal.reducer";

export const AttemptsValidation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    dispatch(MoldalActions.setConditionalModalState(false));
    dispatch(push("/"));
  };
  return (
    <Container data-testid="modal-attempts-container">
      <Paragraph className="title-modal-pass">
        {t("attemptsValidationModal.text")}
      </Paragraph>
      <Button
        rounded={true}
        onClick={handleButtonClick}
        dataTestid="close-modal-attempts"
      >
        <strong>{t("attemptsValidationModal.button")} </strong>
      </Button>
    </Container>
  );
};
