import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100%;
  margin: 0 auto;
  ${({ theme }) => theme.smartphone} {
    width: 95%;
  }
`;

export const PageTitle = styled.h1`
  font-size: 36px;
  font-family: Nunito;
  font-weight: bold;
  padding-left: 10px;
  color: ${({ theme }) => theme.tuatara};
  border-left: 5px solid ${({ theme }) => theme.brightTurquoise};
  ${({ theme }) => theme.smartphone} {
    font-size: 30px;
  }
`;

export const PageContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 5%;
  padding-bottom: 10%;
  ${({ theme }) => theme.smartphone} {
    padding-top: 10%;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const LoadingText = styled.span`
  font-weight: bold;
  font-family: Roboto;
  font-size: 20px;
`;

export const PaymentsList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NoPayments = styled.h3`
  width: 100%;
  text-align: center;
  font-size: 28px;
  font-family: Roboto;
`;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 30px;
  padding-left: 0
  padding-bottom: 10px;
`;

export const BackButtonImage = styled.img`
  height: 26px;
  width: 26px;
  object-fit: contain;
`;

export const BackContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  width: 100%;
`;
