import styled, { css } from "styled-components";

const selectButtonType = (buttonStyle) => {
  switch (buttonStyle) {
    case "transparent":
      return css`
        background-color: transparent;
        border: 2px solid ${(props) => props.theme.white};
        color: ${(props) => props.theme.white};
      `;
    case "transparent-blue":
      return css`
        background-color: transparent;
        border: 2px solid ${(props) => props.theme.brightTurquoise};
        color: ${(props) => props.theme.brightTurquoise};
      `;
    case "white":
      return css`
        width: 215px;
        height: 49px;
        background-color: ${(props) => props.theme.white};
        border-radius: 24px;
        border: none;
        color: ${(props) => props.theme.brightTurquoise};
        font-family: "Nunito";
        font-size: 17px;
        font-weight: 800;
        outline: none;
        cursor: pointer;
      `;
    default:
      return css`
        background-color: transparent;
        border: 2px solid ${(props) => props.theme.white};
        color: ${(props) => props.theme.white};
      `;
  }
};

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  padding: 8px 10px;
  max-height: 50px;
  font-family: "Nunito", sans-serif;
  background-color: ${(props) => props.theme.white};
  border: none;
  border-radius: 22.5px;
  font-size: 17px;
  color: ${(props) => props.theme.brightTurquoise};
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  ${(props) => selectButtonType(props.buttonStyle)}
  ${(props) => (props.largeButton ? "padding: 8px 20px;" : "")}
  &:focus {
    outline: 0;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  ${(props) => props.theme.smartphone} {
    width: 150px;
    margin: 0 5px;
  }
`;

export const InputFileButton = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const ButtonLabel = styled.label`
  padding: 8px 10px;
  max-height: 50px;
  font-family: "Nunito", sans-serif;
  background-color: ${(props) => props.theme.white};
  border: none;
  border-radius: 22.5px;
  font-size: 17px;
  color: ${(props) => props.theme.brightTurquoise};
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  ${(props) => selectButtonType(props.buttonStyle)}
  ${(props) => (props.largeButton ? "padding: 8px 20px;" : "")}
  &:focus {
    outline: 0;
  }
`;
