import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { push } from "connected-react-router";

import {
  Container,
  Paragraph,
  Divider,
  Button,
  CloseButton,
  Icon,
  IconTitleContainer,
  IconTitle
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";

import errorIcon from "assets/images/ErrorIcon.png";

const IdentityValidationErrorModal = ({ errorCodes, redirectUri }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(push(redirectUri));
  };

  const handleCloseClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(push(redirectUri));
  };

  return (
    <Container>
      <CloseButton id="button-close" onClick={handleCloseClick}>
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <IconTitleContainer>
        <IconTitle alt="previous" src={errorIcon} />
      </IconTitleContainer>
      <Paragraph>
        {t(`enrollment.challengeQuestionnaireAnswers.errorCode.${errorCodes}`)}
      </Paragraph>
      <Divider />
      <Button id="button-ok" onClick={handleClick}>
        {t("enrollment.challengeQuestionnaireAnswers.button")}
      </Button>
    </Container>
  );
};

IdentityValidationErrorModal.propTypes = {
  errorCodes: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired
};

export default IdentityValidationErrorModal;
