const colors = {
  black: "#000",
  white: "#fff",
  gray: {
    light: "#D6D6D6",
    dark: "#3F3F3F"
  },
  red: "#cc2030",
  brightTurquoise: "#0bbbef",
  congressBlue: "#004997",
  cornflowerBlue: "#5A88F7",
  cornflowerBlueDark: "#6262F9",
  tulipTree: "#EBAA48",
  darkBlue: "#112444",
  darkestBlue: "#121b2a",
  tuatara: "#31302f",
  springGreen: "#39fbbf",
  shuttleGray:"#686A6F",
  progressBar: "#757687",
  riverBed: "#475061",
  Gallery: "#EBEBEB",
  Mercury:"#e3e3e3",
  GunPowder: "#464A60",
  dolphinGray: "#62618b",
  mercury: "#E9E9E9",
  spiderSilk: "#DBDBDB",
  curiousBlue: "#3895d3",
  paleGray: "#f1f7fb",
  purple: "#6F52FF",
  palePurple: "#55567F",
  cornflowerBlue2: "#6262f9",
  melrose: "#9C9CFF",
  cornflowerBlue3: "#6262F9",
  comet: "#535B77",
  violetRed: "#FB3D76",
  purpleGradient: "linear-gradient(90deg, #4583E0 0%, #455FE0 82.29%)",
  emperor: "#535353",
  silverChalice: "#A4A4A4",
  whisper: "#F5F4FA",
  alto: "#CECECE",
  fog: "#D9D9FF",
  stormGray: "#737582",
  fiord: "#4D566D",
  brightTurquoise2: "#0BBEF0",
  royalBlue: "#4568E0",
  midGray: "#5D5A6A",
  azalea: "#F9D5D5",
  springGreen2: "rgba(0, 255, 102, 0.24)"
};

export default colors;
