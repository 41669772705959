import styled from "styled-components";

export const StyledContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100vw;
  padding: 10px 0 240px 0;
  background-color: ${({ theme }) => theme.eastBay};

  :after {
    content: "";
    position: absolute;
    z-index: -1;
    background-color: ${({ theme }) => theme.eastBay};
    width: 100vw;
    height: 60px;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 50%;
    transform: translatey(-50%);
  }
  > h1 {
    margin-bottom: 20px;
  }
  > .dots {
    width: 80%;
    height: 12px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.tablet} {
    > .dots {
      display: none;
    }
  }
`;

export const StyledListContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.desktop} {
    width: 80%;
  }
  ${({ theme }) => theme.tablet} {
    width: 90%;
  }
`;

export const StyledAllie = styled.div`
  padding: 30px 20px;
  width: calc(100% / 5);
  text-align: center;
  > p {
    font-size: 14px;
    line-height: 19px;
  }
  ${({ theme }) => theme.tablet} {
    width: 100%;
    text-align: inherit;

    > p {
      display: none;
    }
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  max-width: 170px;
`;
