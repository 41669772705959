import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import {
  Container,
  MainContainer,
  SubtitleContainer,
  TextContainer,
  CustomPageTitle,
  BackArrowButton,
  BackArrowImage,
  TotalPaymentContainer,
  TotalPaymentText,
  TotalPaymentLeftContainer,
  TotalPaymentValue,
  TotalPaymenSeparator,
  TotalPaymentRightContainer,
  TotalPaymentList,
  TotalPaymentItem,
  TotalPaymentItemText,
  TotalPaymentItemValue,
  TotalPaymenContainerSeparator,
  FixedFooterContainer,
  FooterButtonApprove,
  FooterButtonReject,
  TotalPaymentReference,
  BusinessNameText,
  BusinessName
} from "./Styles";
import { numberToMoney } from "../../lib/formatter";
import DownloadFile from "../../components/DownloadFile/DownloadFile";
import InvestorList from "../../components/InvestorList/InvestorList";
import IssuerPaymentActions from "../../redux/reducers/issuer-payment.reducer";
import ConditionalList from "../../components/paginator/ConditionalList/ConditionalList";
import IssuerPaymentModel from "../../lib/models/issuerPayment.model";
import ModalActions from "../../redux/reducers/modal.reducer";
import PaymentsActions from "../../redux/reducers/payments.reducer";

const PaymentDetail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dataIssuerPayment = useSelector((store) => store.issuerPayment || {});
  const dataPayments = useSelector((store) => store.payments || {});
  const sizeIfiniteScroll = 3;
  const patrimonialRights = dataIssuerPayment.patrimonialRightsReport || {};
  const paginationParams = patrimonialRights.pagination_params || {};
  const patrimonialRightsReport = dataIssuerPayment.patrimonialRightsReport
    ? dataIssuerPayment.patrimonialRightsReport.patrimonial_list
    : [];

  const getCampaignPayment = () => {
    const issuerPaymentList = dataPayments.issuerPaymentList || [];

    return issuerPaymentList.find(
      (campaign) => campaign.campaign_id === dataPayments.campaignSelected
    );
  };

  const campaignPayment = getCampaignPayment() || {};
  const { state } = IssuerPaymentModel;

  const isValidIssuerPaymentStates = [
    state.pending_new_amount_request,
    state.pending_reject_request,
    state.pending_approve,
    state.rejected
  ].includes(campaignPayment.issuer_payment.state);

  if (!isValidIssuerPaymentStates) {
    dispatch(push("/my-payments"));
  }

  const redirectToMyPayments = () => {
    dispatch(push("/my-payments"));
  };

  const isTypeCommisionBvc = (issuerPaymentType) =>
    issuerPaymentType === "bvc_commission";

  const onRejectPayment = () => {
    dispatch(push("/patrimonial-rights/update"));
  };

  const issuerName = useSelector(({ user }) => user.userInfo.user.name);
  const issuerPayment = campaignPayment.issuer_payment || {};
  const issuerPaymentDetails = issuerPayment.detail || [];
  useEffect(() => {
    dispatch(PaymentsActions.setShowState(true));
    dispatch(IssuerPaymentActions.resetPatrimonialRightsReport());
    dispatch(
      IssuerPaymentActions.startGetPatrimonialRightsReport({
        issuerPaymentId: issuerPayment.id,
        page: 1,
        size: sizeIfiniteScroll
      })
    );
  }, []);

  const onApprovePayment = () => {
    dispatch(
      ModalActions.setConditionalModalState(true, "PaymentDetailModal", {
        issuerPaymentId: issuerPayment.id,
        issuerPaymentTotal: issuerPayment.total
      })
    );
  };

  const onUpdateList = (nextPage) => {
    if (nextPage <= paginationParams.total_pages) {
      dispatch(
        IssuerPaymentActions.startGetPatrimonialRightsReport({
          issuerPaymentId: issuerPayment.id,
          page: nextPage,
          size: sizeIfiniteScroll
        })
      );
    }
  };

  return (
    <MainContainer>
      <Container className="container">
        <BackArrowButton
          data-testid="go-back-payments"
          onClick={redirectToMyPayments}
        >
          <BackArrowImage src="/assets/images/previous.png" alt="previous" />
        </BackArrowButton>
        <CustomPageTitle>{t("paymentDetail.title")}</CustomPageTitle>
        <SubtitleContainer>
          <BusinessName>{issuerName}</BusinessName>
          <BusinessNameText>{t("paymentDetail.subtitle")}</BusinessNameText>
        </SubtitleContainer>
        <TextContainer>{t("paymentDetail.text")}</TextContainer>
        <TotalPaymentContainer>
          <TotalPaymentLeftContainer>
            <TotalPaymentText>
              {t("paymentDetail.totalPaymentText")}
            </TotalPaymentText>
            <TotalPaymentValue>
              {numberToMoney(Number.parseFloat(issuerPayment.total).toFixed(2))}
            </TotalPaymentValue>
          </TotalPaymentLeftContainer>
          <TotalPaymenContainerSeparator>
            <TotalPaymenSeparator />
          </TotalPaymenContainerSeparator>
          <TotalPaymentRightContainer>
            <TotalPaymentList>
              {issuerPaymentDetails.map((issuerPaymentDetail) => (
                <TotalPaymentItem>
                  <TotalPaymentItemText
                    operation={issuerPaymentDetail.issuer_payment_operation}
                    hasReference={isTypeCommisionBvc(
                      issuerPaymentDetail.issuer_payment_type
                    )}
                  >
                    {t(
                      `paymentDetail.types.${issuerPaymentDetail.issuer_payment_type}`
                    )}
                    {isTypeCommisionBvc(
                      issuerPaymentDetail.issuer_payment_type
                    ) && (
                      <TotalPaymentReference>
                        {t("paymentDetail.numReference", {
                          numReference: issuerPaymentDetail.num_ref
                        })}
                      </TotalPaymentReference>
                    )}
                  </TotalPaymentItemText>

                  <TotalPaymentItemValue
                    operation={issuerPaymentDetail.issuer_payment_operation}
                    value={Number(issuerPaymentDetail.issuer_payment_amount)}
                  >
                    {numberToMoney(issuerPaymentDetail.issuer_payment_amount)
                      .toString()
                      .replace("-", "")}
                  </TotalPaymentItemValue>
                </TotalPaymentItem>
              ))}
            </TotalPaymentList>
          </TotalPaymentRightContainer>
        </TotalPaymentContainer>
        {issuerPayment.equity_rights_url && (
          <DownloadFile
            fileName={t("paymentDetail.fileName")}
            fileUrl={issuerPayment.equity_rights_url}
          />
        )}
        <br />
        <br />
        <ConditionalList
          id="infinite-scroll"
          listType="infinite-scroll"
          maxHeight="50vh"
          delay={500}
          currentPage={paginationParams.current_page}
          totalPages={paginationParams.total_pages}
          onUpdateList={onUpdateList}
        >
          <InvestorList items={patrimonialRightsReport} />
        </ConditionalList>
        <FixedFooterContainer>
          <FooterButtonReject
            data-testid="button-reject-payment"
            onClick={onRejectPayment}
            disabled={
              campaignPayment.issuer_payment.state ===
              state.pending_reject_request
            }
          >
            {t("paymentDetail.buttonReject")}
          </FooterButtonReject>
          <FooterButtonApprove
            data-testid="button-approve-payment"
            onClick={onApprovePayment}
          >
            {t("paymentDetail.buttonApprove")}
          </FooterButtonApprove>
        </FixedFooterContainer>
      </Container>
    </MainContainer>
  );
};

export default PaymentDetail;
