import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Heading, Paragraph } from "@bvcco/a2censo-component-lib";
import PymeDesktopNeededImage from "../../assets/images/needed-desktop.png";
import { Container, Image } from "./Styles";

const PymeDesktopNeeded = ({ isMyCompany }) => {
  const { t } = useTranslation();

  if (isMyCompany) {
    return (
      <Container>
        <Heading hieranchy="quaternary" fontWeight="900">
          {t("header.my")}
          <strong>{t("header.onlyCompany")}</strong>
        </Heading>
        <Image src={PymeDesktopNeededImage} alt="a2censo" />
        <Paragraph align="center">
          {t("inviteToDekstopCompany")}
          <br />
          <strong>{t("inviteToDekstopStrong")}</strong>
        </Paragraph>
      </Container>
    );
  }

  return (
    <Container data-testid="pyme-desktop-needed">
      <Heading hieranchy="quaternary" fontWeight="900">
        {t("dashboardCampaings.title")}
        <strong>{t("dashboardCampaings.titleBold")}</strong>
      </Heading>
      <Image src={PymeDesktopNeededImage} alt="a2censo" />
      <Paragraph align="center">
        {t("inviteToDekstopCampaign")}
        <br />
        <strong>{t("inviteToDekstopStrong")}</strong>
      </Paragraph>
    </Container>
  );
};

PymeDesktopNeeded.propTypes = {
  isMyCompany: PropTypes.bool
};

PymeDesktopNeeded.defaultProps = {
  isMyCompany: false
};

export default PymeDesktopNeeded;
