import { catchError, flatMap, map } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import selfAppraisalActions, {
  selfAppraisalTypes
} from "../reducers/self-appraisal.reducer";
import { SelfAppraisalSerializer } from "../../lib/serializers/self-appraisal.serializer";
import ModalActions from "../reducers/modal.reducer";
import UserActions from "../reducers/user.reducer";
import User from "../../lib/models/user.model";
import { getErrorIndex } from "../../lib/utils";

export const getQuestionsPYME = (action$) =>
  action$.pipe(
    ofType(selfAppraisalTypes.GET_QUESTIONS_PYME),
    flatMap(() =>
      Observable.from(App.api.a2censo.getPymeSelfAppraisal()).pipe(
        map((response) => selfAppraisalActions.setQuestionsPyme(response)),
        catchError((e) => Observable.concat(Observable.of(push("/oops", e))))
      )
    )
  );

export const validationSelfAppraisal = (action$) =>
  action$.pipe(
    ofType(selfAppraisalTypes.VALIDATE_SELF_APPRAISAL),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.validateSelfAppraisal({
          body: SelfAppraisalSerializer().serialize(action.payload)
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(selfAppraisalActions.validateSelfAppraisalEnd()),
            Observable.of(
              UserActions.setUserState(User.state.generalInformation)
            ),
            Observable.of(push("/enrollment/general-information"))
          )
        ),
        catchError((e) => {
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(
                  true,
                  "SelfAppraisalModal"
                )
              )
            );
          }
          return Observable.concat(Observable.of(push("/oops", e)));
        })
      )
    )
  );

export default combineEpics(getQuestionsPYME, validationSelfAppraisal);
