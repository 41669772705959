import moment from "moment";
import i18n from "../../translations/i18n";

const paymentFrequencyInMonths = {
  0: i18n.t("campaign.toExpiration"),
  1: i18n.t("campaign.monthly"),
  3: i18n.t("campaign.quarterly"),
  6: i18n.t("campaign.biannual"),
  12: i18n.t("campaign.annual")
};

const daysToEnd = (data) => {
  const now = moment().startOf("day");
  const endDate = moment(data.close_date).startOf("day");
  return endDate.diff(now, "days");
};

const campaign = {
  paymentFrequency: (months) => {
    if (!paymentFrequencyInMonths[months]) {
      return "No aplica";
    }
    return paymentFrequencyInMonths[months];
  },
  isActive: (data) => {
    const investmentInProgress =
      Number(data.requestedAmount) > Number(data.investedAmount);
    const isActive =
      data.state === campaign.state.active && daysToEnd(data) >= 0;
    return investmentInProgress && isActive;
  },
  state: {
    active: 1,
    awarded: 2,
    deserted: 3,
    cancelled: 4,
    inactive: 5,
    closed: 6,
    award_in_process: 7,
    annotation_in_proces: 8,
    annotated: 9,
    failed_annotation: 10,
    draft: 11,
    pending: 12
  },
  stateName: {
    1: "pending_campaign_request",
    2: "pending_financial_documentation",
    3: "pending_initial_filters",
    4: "pending_additional_documents_first_documents",
    5: "reject_without_notifying",
    6: "rejected",
    7: "pending_correction_financial_documentation",
    8: "pending_schedule_interview",
    9: "pending_interview_results",
    10: "pending_additional_information",
    11: "pending_codebtor_validation",
    12: "pending_new_codebtor_validation",
    13: "pending_additional_information_validation",
    14: "pending_additional_documents_additional_information",
    15: "pending_correction_additional_information",
    16: "pending_committee_analysis",
    17: "pending_additional_requests_committee",
    18: "pending_correction_committee_documentation",
    19: "pending_confirmation_financial_conditions",
    20: "pending_final_analysis_information",
    21: "pending_pre_approval",
    22: "pending_correction_pre_approval_documentation",
    23: "pending_additional_pre_aproval_documents",
    24: "pending_release",
    25: "pending_load_new_codebtor_release",
    26: "pending_commercial_approval",
    27: "release_rejected",
    28: "published_campaign",
    29: "pending_lists_validation",
    30: "pending_correction_publish_your_campaign_documentation"
  },
  stateRequestCampaign: {
    rejected: "rejected",
    pendingRelease: "pending_release",
    pendingNewCodebtorValidation: "pending_new_codebtor_validation",
    pendingAdditionalInformation: "pending_additional_information",
    pendingCorrectionPublishYourCampaignDocumentation:
      "pending_correction_publish_your_campaign_documentation",
    releaseRejected: "release_rejected",
    pendingLoadNewCodebtorRelease: "pending_load_new_codebtor_release",
    pendingAdditionalPreAprovalDocuments:
      "pending_additional_pre_aproval_documents",
    pendingCorrectionPreApprovalDocumentation:
      "pending_correction_pre_approval_documentation",
    pendingCorrectionCommitteeDocumentation:
      "pending_correction_committee_documentation",
    pendingAdditionalRequestsCommittee: "pending_additional_requests_committee",
    pendingCorrectionAdditionalInformation:
      "pending_correction_additional_information",
    pendingAdditionalDocumentsAdditionalInformation:
      "pending_additional_documents_additional_information",
    pendingCorrectionFinancialDocumentation:
      "pending_correction_financial_documentation",
    pendingAdditionalDocumentsFirstDocuments:
      "pending_additional_documents_first_documents"
  }
};

export const isPendingCampaign = (state) => {
  const pendingStates = [
    campaign.stateRequestCampaign.pendingNewCodebtorValidation,
    campaign.stateRequestCampaign
      .pendingCorrectionPublishYourCampaignDocumentation,
    campaign.stateRequestCampaign.releaseRejected,
    campaign.stateRequestCampaign.pendingLoadNewCodebtorRelease,
    campaign.stateRequestCampaign.pendingAdditionalPreAprovalDocuments,
    campaign.stateRequestCampaign.pendingCorrectionPreApprovalDocumentation,
    campaign.stateRequestCampaign.pendingCorrectionCommitteeDocumentation,
    campaign.stateRequestCampaign.pendingAdditionalRequestsCommittee,
    campaign.stateRequestCampaign.pendingCorrectionAdditionalInformation,
    campaign.stateRequestCampaign
      .pendingAdditionalDocumentsAdditionalInformation,
    campaign.stateRequestCampaign.pendingCorrectionFinancialDocumentation,
    campaign.stateRequestCampaign.pendingAdditionalDocumentsFirstDocuments
  ];

  return pendingStates.includes(state);
};

export const STEPPER_ROUTES = {
  1: "/create-campaign/information",
  2: "/create-campaign/documents",
  3: "/create-campaign/interview",
  4: "/create-campaign/additional-info",
  5: "/create-campaign/commite-analysis",
  6: "/create-campaign/pre-approval",
  7: "/create-campaign/release"
};

export const StatesRequestDocuments = {
  docNotLoaded: "doc_not_loaded",
  docLoaded: "doc_loaded",
  docPending: "doc_pending",
  docApproved: "doc_approved",
  docRejected: "doc_rejected"
};

export const OriginDocuments = {
  pyme: 1
};
export const AVAILABLE_STEPS = {
  CAMPAIGN_INFORMATION: 1,
  FIRST_DOCUMENTS: 2,
  INTERVIEW: 3,
  ADDITIONAL_INFORMATION: 4,
  COMMITEE_ANALYSIS: 5,
  PRE_APPROVAL: 6,
  RELEASE: 7
};

export const STAGE_REQUEST_CAMPAIGN_STATUS = {
  UNFILLED: "unfilled",
  ACTIVE: "active",
  PENDING_APPROVE: "pending_approve",
  CHANGE_REQUEST: "change_request",
  COMPLETED: "completed",
  REJECTED: "rejected"
};

export const STAGE_STATUS = {
  ACTIVE: 1,
  PENDING_APPROVE: 2,
  CHANGE_REQUEST: 3,
  COMPLETED: 4,
  REJECTED: 5,
  UNFILLED: 6
};

export const DOCUMENT_CLASSIFICATION_TYPE = {
  codebtorPJ: 1,
  codebtorPN: 2,
  associatePJ: 3,
  associatePN: 4,
  PYME: 5
};

export const TypeBlockFile = {
  download: "download",
  upload: "upload",
  downloadUpload: "downloadUpload"
};

export const TypeDocumentId = {
  require: 1,
  adicional: 2
};

export const StateFileUploader = {
  default: "default",
  loading: "loading",
  changeRequest: "changeRequest",
  uploaded: "uploaded",
  error: "error"
};

export const GetStateFileUploaderByFile = (pathFile, stateFile) => {
  if (StatesRequestDocuments.docRejected === stateFile && pathFile) {
    return StateFileUploader.changeRequest;
  }
  if (pathFile) {
    return StateFileUploader.uploaded;
  }
  return StateFileUploader.default;
};

export const FlowUploadFile = {
  default: "default",
  codebtor: "codebtor",
  associate: "associate"
};

export const GetTypeBlockFile = (isDownload, templateURL) => {
  if (isDownload && templateURL) {
    return TypeBlockFile.download;
  }
  if (!isDownload && !templateURL) {
    return TypeBlockFile.upload;
  }
  if (!isDownload && templateURL) {
    return TypeBlockFile.downloadUpload;
  }
};

export const statusDocumentStage = ({
  campaignStepper: { stageList },
  campaignStepper: { currentTabId }
}) => {
  const currentStep = Object.keys(AVAILABLE_STEPS).find((key) =>
    AVAILABLE_STEPS[key] === currentTabId ? key : ""
  );
  const state = stageList.find(
    (item) => item.stage_id === AVAILABLE_STEPS[currentStep]
  );
  return state.status;
};

export const statusFiveStage = ({ campaignStepper: { stageList } }) => {
  const state = stageList.find(
    (item) => item.stage_id === AVAILABLE_STEPS.COMMITEE_ANALYSIS
  );

  return state.status;
};

export const statusFirstStage = ({ campaignStepper: { stageList } }) => {
  const state = stageList.find(
    (item) => item.stage_id === AVAILABLE_STEPS.CAMPAIGN_INFORMATION
  );

  return state ? state.status : null;
};

export const getFinancialTermObservation = ({
  requestCampaign: { financialTermsApprovedForBvc }
}) => financialTermsApprovedForBvc.observation;

export const observationInformationStage = ({
  campaignStepper: { stageList = [] } = { stageList: [] }
}) => {
  const stageInterview = stageList.find(
    (item) => item.stage_id === AVAILABLE_STEPS.CAMPAIGN_INFORMATION
  );

  const stageAdditionalInformation = stageList.find(
    (item) => item.stage_id === AVAILABLE_STEPS.ADDITIONAL_INFORMATION
  );
  if (!stageInterview || !stageAdditionalInformation) {
    return "";
  }
  if (
    stageAdditionalInformation.status === STAGE_REQUEST_CAMPAIGN_STATUS.ACTIVE
  ) {
    return stageInterview.observation;
  }
  return "";
};

export const observationReleaseStage = ({
  campaignStepper: { stageList = [] } = { stageList: [] }
}) => {
  const stageInterview = stageList.find(
    (item) => item.stage_id === AVAILABLE_STEPS.RELEASE
  );
  return stageInterview.observation;
};

export const getIsAnyFileLoading = ({
  campaignDocumentManagment: { stageFileUploadeds }
}) => stageFileUploadeds.some((file) => file.isLoading);

export const isHiddenHeadInStepper = ({
  campaignStepper: { stageList = [] } = { stageList: [] }
}) => {
  let isHidden = false;

  const stageSeven = stageList.find(
    (item) => item.stage_id === AVAILABLE_STEPS.RELEASE
  );

  if (stageSeven) {
    isHidden = stageSeven.status === STAGE_REQUEST_CAMPAIGN_STATUS.COMPLETED;
  }

  return isHidden;
};

export const STATE_SAVE = {
  save: "save",
  saving: "saving",
  saved: "saved"
};

export const FINANCIAL_TERMS_STATE = {
  PENDING_APPROVE: "pending_approve",
  APPROVE_BY_COMMITE: "approve_by_commite",
  APPROVE_BY_PYME: "approve_by_pyme",
  APPROVED: "approved"
};

export const FINANCIAL_TERMS_TYPES = {
  SUGGESTION: 1,
  PYME: 2,
  ACCEPTED_BY_PYME: 3,
  APPROVED: 4
};

export const DEFAULT_ALLOWED_FILE_TYPES =
  ".pdf,.xlsx,.xls,.jpg,.zip,.rar,.pptx,.pptm,.ppt,.docx,.doc,.csv,.otp,application/x-rar";

export const DETAIL_SELECTED = {
  video: 1,
  image: 2
};

export const DETAIL_SELECTED_OPTIONS = {
  1: "video",
  2: "image"
};

export default campaign;
