const AlertFormType = {
    SUCCESS: {
        background: "#C2FFFB"
    },
    WARNING: {
        background: "#FEF5C2"
    },
    ERROR: {
        background: "#FFC2D8"
    }
};
  
export default AlertFormType;
  