import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import MovementItem from "../MovementItem/MovementItem";
import { Container, DateSpacer } from "./Styles";

const Movements = ({ items }) => {
  const { t } = useTranslation();

  const MovementItems = items.transactions.map((item, index) => {
    const Element = [];
    if (
      index === 0 ||
      !moment(item.created_at).isSame(
        items.transactions[index - 1].created_at,
        "day"
      )
    ) {
      Element.push(
        <DateSpacer>
          {`${t("myMovements.date")}${moment(item.created_at).format(
            "MMMM D [de] YYYY"
          )}`}
        </DateSpacer>
      );
    }
    Element.push(<MovementItem movement={item} key={item.transaction_id} />);
    return Element;
  });
  return (
    <Container data-testid="container-my-movements">{MovementItems}</Container>
  );
};

Movements.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired
};

export default Movements;
