import { createReducer, createActions } from "reduxsauce";
import User from "../../lib/models/user.model";
import Investor from "../../lib/models/investor.model";

export const { Types, Creators } = createActions({
  resetTimer: [],
  setUserInfo: ["userInfo"],
  selectPersonType: ["personType"],
  setUserState: ["userState"],
  setUserStatus: ["status"],
  setInvestorType: ["payload"],
  setDataPyme: ["payload"],
  setFinishInformation: [],
  setAttemptsUser: ["hasAttempts"],
  setUpdateDataUser: ["user"],
  setValidateSuspension: ["error"],
  clearValidateSuspension: []
});

export const UserTypes = Types;
export default Creators;

const INITIAL_STATE = {
  personType: null,
  userInfo: null,
  userState: null,
  investorType: 0,
  errorValidateSuspension: ""
};

const selectPersonType = (state, { personType }) => ({ ...state, personType });

const setUserInfo = (state, { userInfo }) => ({ ...state, userInfo });

const setUserState = (state, { userState }) => ({ ...state, userState });

const setDataPyme = (state, { payload }) => ({
  ...state,
  userInfo: {
    ...state.userInfo,
    user: {
      ...state.userInfo.user,
      ...payload
    }
  }
});

const setUserStatus = (state, { status }) => ({
  ...state,
  userInfo: {
    ...state.userInfo,
    user: {
      ...state.userInfo.user,
      state: status
    }
  }
});

const setInvestorType = (state, { payload }) => {
  let investorType = User.investorType.unknown;
  if (
    payload.qualityTax !== Investor.qualityTax.noQuality &&
    payload.hasInvestorDocument
  ) {
    investorType = User.investorType.qualified;
  } else if (
    payload.qualityTax !== Investor.qualityTax.noQuality &&
    !payload.hasInvestorDocument
  ) {
    investorType = User.investorType.noQualified;
  }
  return { ...state, investorType };
};

const updateFinishInformation = (state) => ({
  ...state,
  userInfo: {
    ...state.userInfo,
    user: {
      ...state.userInfo.user,
      finish_information: 1
    }
  }
});

const updateAttemptsUpdateInfo = (state, { hasAttempts }) => ({
  ...state,
  userInfo: {
    ...state.userInfo,
    have_attempts: hasAttempts
  }
});

const updateUserData = (state, { user }) => ({
  ...state,
  userInfo: {
    ...state.userInfo,
    user: {
      ...state.userInfo.user,
      name: user.firstName,
      last_name: user.lastName,
      second_last_name: user.secondLastName,
      second_name: user.secondName,
      data_update_completed: 1
    },
    natural_person: {
      ...state.userInfo.natural_person,
      ciiu: user.ciiu
    }
  }
});

const setValidateSuspension = (state, {error}) => ({
  ...state,
  errorValidateSuspension: error
});

const clearValidateSuspension = (state) => ({
  ...state,
  errorValidateSuspension: ""
});


export const reducer = createReducer(INITIAL_STATE, {
  [Types.SELECT_PERSON_TYPE]: selectPersonType,
  [Types.SET_USER_INFO]: setUserInfo,
  [Types.SET_USER_STATE]: setUserState,
  [Types.SET_USER_STATUS]: setUserStatus,
  [Types.SET_INVESTOR_TYPE]: setInvestorType,
  [Types.SET_DATA_PYME]: setDataPyme,
  [Types.SET_FINISH_INFORMATION]: updateFinishInformation,
  [Types.SET_ATTEMPTS_USER]: updateAttemptsUpdateInfo,
  [Types.SET_UPDATE_DATA_USER]: updateUserData,
  [Types.SET_VALIDATE_SUSPENSION]: setValidateSuspension,
  [Types.CLEAR_VALIDATE_SUSPENSION]: clearValidateSuspension
});
