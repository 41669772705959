import React from "react";
import {
  Label,
  Dropdown,
  FormBottomButtons
} from "@bvcco/a2censo-component-lib";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/named
import { FormikProps, withFormik } from "formik";
import CompanyTaxForm from "components/CompanyTaxForm/CompanyTaxForm";
import { Row, Column, SubLabel, Input, CustomSpace, Dasheds } from "./Styles";
import check from "assets/images/Vector.png";

type ICity = {
  id: number;
  value: string;
};

export type DataUpdateFormValues = {
  cityRetention: number;
  cityConsignment: number;
  commercialAddress: string;
  city: number;
};

export type Props = {
  onSave: (values: DataUpdateFormValues) => void;
  initialValues?: DataUpdateFormValues;
  listCityConsignment: ICity[];
  listCityRetention: ICity[];
  cities: ICity[];
  loading: boolean;
};

const DataUpdateForm = ({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  listCityConsignment,
  listCityRetention,
  cities,
  handleSubmit,
  loading,
  dirty,
  resetForm
}: Props & FormikProps<DataUpdateFormValues>) => {
  const { t } = useTranslation();
  const formik = {
    handleChange,
    handleBlur,
    values,
    setFieldValue,
    resetForm
  };

  return (
    <div>
      <CompanyTaxForm
        formik={formik}
        showHead={false}
        listCityConsignment={listCityConsignment}
        listCityRetention={listCityRetention}
      />
      <Dasheds />
      <Row>
        <Column>
          <Label>{t("myCertificates.pyme.edit.commercialAddress")}</Label>
          <SubLabel>
            {t("myCertificates.pyme.edit.commercialAddressDescription")}
          </SubLabel>
          <Input
            name="commercialAddress"
            id="commercialAddress"
            data-testid="commercialAddress"
            value={values.commercialAddress}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Column>
        <Column>
          <Label>{t("generalInformation.city")}</Label>
          <SubLabel>{t("myCertificates.pyme.edit.selectCity")}</SubLabel>
          <CustomSpace />
          <Dropdown
            id="city"
            name="city"
            dataTestId="city"
            options={cities}
            onChange={(e: { id: number }) => formik.setFieldValue("city", e.id)}
            valueSelected={values.city}
            className="city-list"
          />
        </Column>
      </Row>
      <FormBottomButtons
        backgroundColor="purpleGradient"
        buttons={[
          {
            children: loading ? t("saving") : t("company.save"),
            onlyText: true,
            rounded: true,
            disabled: loading || !dirty,
            id: "save-button",
            dataTestid: "save-button",
            color: "white",
            fontWeight: 800,
            onClick: () => {
              handleSubmit();
            },
            iconSrc: check
          }
        ]}
      />
    </div>
  );
};

export default withFormik<Props, DataUpdateFormValues>({
  mapPropsToValues: (props) =>
    props.initialValues || {
      cityRetention: 0,
      cityConsignment: 0,
      commercialAddress: "",
      city: 0
    },
  handleSubmit: (values, { props: { onSave }, resetForm }) => {
    onSave(values);
    resetForm();
  }
})(DataUpdateForm);
