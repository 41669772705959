import styled from "styled-components";

type Props = {
  isInvestor: boolean;
  disabledDownload?: boolean;
};

export const ListWrapper = styled.div<Props>`
  display: grid;
  grid-template-columns: ${({ isInvestor, disabledDownload }) =>
    isInvestor
      ? "20% 58% 13% 7%"
      : disabledDownload
      ? "80.5% 13% 6.5%"
      : "75.5% 13% 10%"};
  column-gap: 0.5%;
  width: 100%;
  min-height: 60px;
  padding-left: 19px;
  padding-right: 19px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 14px 28px -28px rgba(0, 0, 0, 0.25);
  font-family: ${({ theme }) =>
    theme ? theme.font.family.primary : "initial"};
  ${({ theme }) => theme.breakpoints.mobile} {
    max-width: 100%;
  }
  ${({ theme }) => theme.breakpoints.desktopSmall} {
    grid-template-columns: ${({ isInvestor, disabledDownload }) =>
      isInvestor
        ? "12% 61.5% 15% 10%"
        : disabledDownload
        ? "75.5% 15% 6.5%"
        : "75.5% 13% 10%"};
  }
  ${({ theme }) => theme.breakpoints.intervalMobileMedium} {
    grid-template-columns: ${({ isInvestor }) =>
      isInvestor ? "15% 52.5% 21% 11%" : "67.5% 24% 12%"};
  }
`;

export const ListNameCompanyWrapper = styled.div<Props>`
  display: grid;
  grid-template-columns: ${({ isInvestor }) =>
    isInvestor ? "20% 80%" : "46px auto"};
  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => theme.borderCertificate} 10%,
    rgba(255 255 255) 0%
  );
  background-position: right;
  background-size: 1px 6px;
  background-repeat: repeat-y;
  margin-top: 14px;
  margin-bottom: 14px;
`;

export const ListInfoWrapper = styled.div`
  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => theme.borderCertificate} 10%,
    rgba(255 255 255) 0%
  );
  background-position: right;
  background-size: 1px 6px;
  background-repeat: repeat-y;
  padding-left: 24px;
  margin-top: 14px;
  margin-bottom: 14px;
`;

export const ListQuantityWrapper = styled.div`
  display: grid;
  justify-content: center;
  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => theme.borderCertificate} 10%,
    rgba(255 255 255) 0%
  );
  background-position: right;
  background-size: 1px 6px;
  background-repeat: repeat-y;
  margin-top: 14px;
  margin-bottom: 14px;
  text-align: end;
`;

export const ListbuttonsWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 14px;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  text-align: center;
  > a {
    cursor: pointer;
    img {
      width: 35px;
      height: 35px;
      object-fit: contain;
    }
  }
  > img {
    width: 100%;
  }
`;

export const Label = styled.div`
  font-family: ${({ theme }) =>
    theme ? theme.font.family.primary : "initial"};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.acentuar};
`;
