import styled from "styled-components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Input from "../Input/Input";

import "../../styles/colors.scss";

const borderRadius = (borderSide) => {
  if (borderSide === "right") {
    return "border-left: 1px solid #babdca;";
  }
};

export const AcceptsLinkContainer = styled.p`
  font-size: 22px;
  ${(props) => props.theme.smartphone} {
    font-size: 12px;
    line-height: 16px;
  }
`;
export const CustomInput = styled(Input)`
  resize: none;
  padding: 20px 30px 50px 30px;
  color: #31302f;
  font-family: Roboto;
  font-size: 19px;
  outline: none;
  width: 100%;
  border-color: white;
  ${(props) => props.theme.smartphone} {
    padding: 15px 30px 35px 12px;
    font-size: 13px;
  }
`;
export const ContentTitle = styled.div`
  margin-top: 20px;
  text-align: center;
`;
export const TabTitle = styled.div`
  margin-bottom: 10px;
  color: #5d5b59;
  p {
    font-size: 24px;
  }
  p:first-of-type {
    font-weight: bold;
  }
  .tab2-subtitle {
    font-size: 16px;
    margin-top: 10px;
  }
  ${(props) => props.theme.smartphone} {
    width: 90%;
    p {
      font-size: 12px;
    }
    .tab2-subtitle {
      font-size: 9px;
      margin-top: 5px;
    }
  }
`;
export const CustomTab = styled(Tab)`
  align-items: center;
  display: flex;
  height: 66px;
  justify-content: center;
  width: 50%;
  cursor: pointer;
`;

export const CustomTabs = styled(Tabs)`
  margin-top: 18px;
  text-align: left;
`;

export const CustomTabList = styled(TabList)`
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  .react-tabs__tab--selected {
    border-bottom: 6px solid #0bbef0 !important;
  }
`;
export const CustomTabPanel = styled(TabPanel)`
  margin-top: 30px;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(220, 220, 220, 0.7);
  justify-content: center;
  overflow-y: scroll;
  backdrop-filter: blur(8px);
`;

export const InnerContainer = styled.div`
  text-align: right;
  position: absolute;
  top: 5%;
  width: 70%;
  margin-bottom: 60px;

  ${(props) => props.theme.smartphone} {
    width: 90%;
  }
`;

export const Button = styled.button`
  max-width: 5%;
  background: none;
  border: none;
  position: fixed;
  right: 30px;
  cursor: pointer;
  transition: all 0.5s;
  :hover {
    max-width: 5.5%;
  }
  @media screen and (max-width: 768px) {
    max-width: 18%;
    right: 6px;
  }
  @media screen and (min-width: 450px) and (max-width: 768px) {
    max-width: 10%;
    right: 56px;
  }
`;
export const Image = styled.img`
  width: 100%;
`;
export const Content = styled.form`
  max-width: 60%;
  margin: auto;
  display: block;
  @media screen and (max-width: 768px) {
    max-width: 90%;
  }
`;
export const Title = styled.p`
  font-size: 36px;
  line-height: 54px;
  font-family: "Nunito", sans-serif;
  color: #403e3d;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    margin-bottom: 14px;
    font-size: 23px;
    line-height: 27px;
  }
`;
export const Subtitle = styled.p`
  font-size: 24px;
  line-height: 34px;
  font-family: Roboto;
  color: #403e3d;
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 17px;
  }
  ${(props) => props.theme.smartphone} {
    margin: 0 auto;
    width: 80%;
  }
`;
export const Submit = styled.input`
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  background-color: #0bbef0;
  border: 0;
  height: 76px;
  width: 266px;
  border-radius: 42px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
    height: 36px;
    width: 133px;
    border-radius: 21px;
  }
`;
export const Accepts = styled.p`
  font-size: 22px;
  line-height: 31px;
  font-family: Roboto;
  text-align: left;
  color: #31302f;
  padding: 11px 0;
  display: flex;
  flex-direction: row;
  ${(props) => props.theme.smartphone} {
    font-size: 12px;
    line-height: 16px;
  }
`;
export const ContentAcepts = styled.div`
  padding: 20px 0;
  ${(props) => props.theme.tablet} {
    padding: 0 0 14px 0;
  }
`;
export const Img = styled.img`
  object-fit: contain;
  margin-right: 10px;
  max-width: 27px;
  ${(props) => props.theme.smartphone} {
    max-width: 20px;
  }
`;
export const ContentImage = styled.div`
  max-width: 10%;
  margin: auto;
`;
export const Title2 = styled(Title)`
  color: #31302f;
  text-align: center;
  padding: 20px 0 10px 0;
`;
export const Subtitle2 = styled(Subtitle)`
  text-align: center;
  max-width: 80%;
  margin: auto;
`;
export const ContentMessage = styled(Content)`
  text-align: center;
`;
export const SendButton = styled.button`
  height: 70px;
  width: 240px;
  background-color: ${(props) => props.theme.brightTurquoise};
  font-family: "Nunito", sans-serif;
  color: white;
  border: none;
  outline: none;
  font-size: 24px;
  line-height: 40px;
  font-weight: bold;
  border-radius: 40px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  ${(props) => props.theme.smartphone} {
    width: 133px;
    height: 40px;
    padding-top: 0px !important;
    font-size: 14px;
  }
`;

export const Icon = styled.img`
  width: 40px;
  height: 40px;
  ${(props) => props.theme.smartphone} {
    width: 25px;
    height: 25px;
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  align-self: flex-end;
`;

export const InputContainer = styled.div`
  width: 100%;
  height: auto;
  margin: 0px;
  .input {
    margin: 0px;
  }
  ${(props) => props.theme.smartphone} {
    margin-bottom: 24px;
    background-color: white;
    border-radius: 5px;
  }
`;

export const TextareaContainer = styled(InputContainer)`
  margin-top: 6px;
  .input {
    margin-top: -10px;
    padding: 10px 0px;
  }
`;

export const CheckBtn = styled.div`
  outline: none;
  border: none;
  background-color: transparent;
  display: flex;
`;

export const CustomLink = styled.a`
  color: #0bbef0;
`;

export const CustomInputContainer = styled(InputContainer)`
  display: flex;
  ${(props) => borderRadius(props.borderSide)}
`;

export const CustomRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  background-color: white;
  border-radius: 5px;
  ${(props) => props.theme.smartphone} {
    flex-direction: column;
    background-color: transparent;
    margin-bottom: 0px;
  }
`;

export const ContainerCountry = styled.div`
  width: 30%;
`;

export const ContainerPhone = styled.div`
  width: 70%;
`;
