import App from "lib/app";
// eslint-disable-next-line import/named
import { AnyAction } from "redux";

export const resetTimeoutMiddleware = () => (next: any) => (
  action: AnyAction = { type: "" }
) => {
  const app: any = App;
  if (app.sessionTimeoutManager) {
    app.sessionTimeoutManager.setTimer();
  }
  next(action);
};
