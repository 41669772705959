/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import flag from "country-code-emoji";
import ModalActions from "../../redux/reducers/modal.reducer";
import {
  startContactFlow,
  loadContactForm
} from "../../redux/epics/contact-form";
import Input from "../Input/Input";
import Select from "../Select/Select";
import CheckOn from "../../assets/images/Politicas.png";
import CheckOff from "../../assets/images/radio-off.png";
import validationSchema from "./ContactForm.schema";
import {
  Container,
  InnerContainer,
  Title,
  Subtitle,
  Accepts,
  ContentAcepts,
  Img,
  SendButton,
  CloseButton,
  Icon,
  InputContainer,
  CheckBtn,
  CustomLink,
  CustomTab,
  CustomTabs,
  CustomTabList,
  CustomTabPanel,
  TabTitle,
  ContentTitle,
  CustomInput,
  TextareaContainer,
  CustomInputContainer,
  AcceptsLinkContainer,
  CustomRowContainer,
  ContainerCountry,
  ContainerPhone
} from "./Styles";
import environment from "environment";

const AcceptsConditions = ({
  setPolitics,
  setTerms,
  userAcceptedPolitics,
  userAcceptedTerms,
  dataTestId,
  t
}) => (
  <ContentAcepts>
    <Accepts>
      <CheckBtn
        data-testid={`politics-button-${dataTestId}`}
        onClick={() => setPolitics(!userAcceptedPolitics)}
      >
        <Img src={userAcceptedPolitics ? CheckOn : CheckOff} />
      </CheckBtn>
      <AcceptsLinkContainer>
        {t("questions.acceptpolitic")}
        <CustomLink
          rel="noopener noreferrer"
          target="_blank"
          href={`${environment.aws.url}/politics.pdf`}
        >
          {t("questions.acceptpoliticLink")}
        </CustomLink>
      </AcceptsLinkContainer>
    </Accepts>
    <Accepts>
      <CheckBtn
        data-testid={`terms-button-${dataTestId}`}
        onClick={() => setTerms(!userAcceptedTerms)}
      >
        <Img src={userAcceptedTerms ? CheckOn : CheckOff} />
      </CheckBtn>
      <AcceptsLinkContainer>
        {t("questions.acceptterms")}
        <CustomLink
          rel="noopener noreferrer"
          target="_blank"
          href={`${environment.aws.url}/terms.pdf`}
        >
          {t("questions.accepttermsLink")}
        </CustomLink>
      </AcceptsLinkContainer>
    </Accepts>
  </ContentAcepts>
);

const ContactForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const countries = useSelector(({ contact }) => contact.country);
  useEffect(() => {
    dispatch(loadContactForm());
  }, []);

  const [userAcceptedTerms, setTerms] = useState(false);
  const [userAcceptedPolitics, setPolitics] = useState(false);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    defaultIndex
  } = props;
  const errorKeys = Object.keys(errors);

  const errorPymeKeys = () => {
    const hasErrors = [];
    errorKeys.forEach((value) => {
      if (
        value === "pymeName" ||
        value === "pymeContactName" ||
        value === "pymeEmail" ||
        value === "pymePhone" ||
        value === "pymeDescription"
      ) {
        hasErrors.push(value);
      }
    });
    return hasErrors.length === 0;
  };
  const errorNaturalKeys = () => {
    const hasErrors = [];
    errorKeys.forEach((value) => {
      if (value === "name" || value === "email" || value === "description") {
        hasErrors.push(value);
      }
    });
    return hasErrors.length === 0;
  };

  const tabs = [
    {
      title: t("contactForm.tab1Title"),
      id: 1
    },
    {
      title: t("contactForm.tab2Title"),
      subtitle: t("contactForm.tab2Subtitle"),
      id: 2
    }
  ];

  const sendForm = (type, params) => {
    // eslint-disable-next-line no-param-reassign
    params.formType = type;
    dispatch(startContactFlow(params));
  };
  // eslint-disable-next-line no-underscore-dangle
  const countryPhoneCodes = countries.map((country) => ({
    value: country.phone_code,
    text: `${flag(country.code)} +${country.phone_code}`
  }));

  const isDisabledButtonPyme = Boolean(
    !errorPymeKeys() ||
      !values.pymeName ||
      !values.pymeContactName ||
      !values.pymeEmail ||
      !values.pymePhone ||
      !values.pymeDescription ||
      !userAcceptedPolitics ||
      !userAcceptedTerms
  );

  const isDisabledButtonMoreInfo =
    !errorNaturalKeys() ||
    !values.name ||
    !values.email ||
    !values.description ||
    !userAcceptedPolitics ||
    !userAcceptedTerms;

  return (
    <Container>
      <InnerContainer>
        <CloseButton
          id="simulator-back-button"
          onClick={() => {
            dispatch(ModalActions.setConditionalModalState(false));
          }}
        >
          <Icon alt="previous" src="/assets/images/close_2.png" />
        </CloseButton>
        <ContentTitle>
          <Title>{t("questions.title")}</Title>
          <Subtitle>{t("questions.subtitle")}</Subtitle>
        </ContentTitle>
        <CustomTabs id="contactForm" defaultIndex={defaultIndex}>
          <CustomTabList id="tabs">
            {tabs.map((tab, index) => (
              <CustomTab key={tab.key}>
                <TabTitle>
                  <p>{tab.title}</p>
                  {index === 1 && (
                    <p className="tab2-subtitle">
                      {t("contactForm.tab2Subtitle")}
                    </p>
                  )}
                </TabTitle>
              </CustomTab>
            ))}
          </CustomTabList>
          {/* *** Tab Panel 1 *** */}
          <CustomTabPanel>
            <CustomRowContainer>
              <InputContainer>
                <Input
                  placeholder={t("questions.formname")}
                  type="text"
                  extraClassname="input-form"
                  name="name"
                  id="contact-name"
                  data-testid="contact-name"
                  onChange={handleChange}
                  value={values.name}
                  onBlur={handleBlur}
                  error={!!errorKeys.length && touched.name && errors.name}
                />
              </InputContainer>
            </CustomRowContainer>
            <CustomRowContainer>
              <InputContainer>
                <Input
                  placeholder={t("questions.formmail")}
                  type="email"
                  extraClassname="input-form"
                  name="email"
                  id="contact-email"
                  data-testid="contact-email"
                  onChange={handleChange}
                  value={values.email}
                  onBlur={handleBlur}
                  error={!!errorKeys.length && touched.email && errors.email}
                />
              </InputContainer>
            </CustomRowContainer>
            <CustomRowContainer>
              <TextareaContainer>
                <CustomInput
                  inputType="textarea"
                  placeholder={t("questions.formdescription")}
                  extraClassname="input-form"
                  name="description"
                  id="contact-description"
                  data-testid="contact-description"
                  onChange={handleChange}
                  error={
                    !!errorKeys.length &&
                    touched.description &&
                    errors.description
                  }
                  value={values.description}
                  onBlur={handleBlur}
                />
              </TextareaContainer>
            </CustomRowContainer>
            <AcceptsConditions
              dataTestId="more-info"
              setPolitics={setPolitics}
              setTerms={setTerms}
              t={t}
              userAcceptedTerms={userAcceptedTerms}
              userAcceptedPolitics={userAcceptedPolitics}
            />
            <SendButton
              data-testid="send-button-more-info"
              id="send-button-more-info"
              disabled={isDisabledButtonMoreInfo}
              onClick={() => {
                sendForm("natural", values);
              }}
            >
              {t("contactForm.send")}
            </SendButton>
          </CustomTabPanel>
          {/* *** Tab Panel 2 *** */}
          <CustomTabPanel>
            <CustomRowContainer>
              <CustomInputContainer borderSide="left">
                <Input
                  placeholder={t("questions.pymeFormName")}
                  type="text"
                  extraClassname="input-form"
                  name="pymeName"
                  id="pyme-name"
                  data-testid="pyme-name"
                  onChange={handleChange}
                  value={values.pymeName}
                  onBlur={handleBlur}
                  error={
                    !!errorKeys.length && touched.pymeName && errors.pymeName
                  }
                />
              </CustomInputContainer>
              <CustomInputContainer borderSide="right">
                <Input
                  placeholder={t("questions.pymeFormContactName")}
                  type="text"
                  extraClassname="input-form"
                  name="pymeContactName"
                  id="pyme-contact-name"
                  data-testid="pyme-contact-name"
                  onChange={handleChange}
                  value={values.pymeContactName}
                  onBlur={handleBlur}
                  error={
                    !!errorKeys.length &&
                    touched.pymeContactName &&
                    errors.pymeContactName
                  }
                />
              </CustomInputContainer>
            </CustomRowContainer>
            <CustomRowContainer>
              <CustomInputContainer borderSide="left">
                <Input
                  placeholder={t("questions.formmail")}
                  type="email"
                  extraClassname="input-form"
                  name="pymeEmail"
                  id="pyme-contact-email"
                  data-testid="pyme-contact-email"
                  onChange={handleChange}
                  value={values.pymeEmail}
                  onBlur={handleBlur}
                  error={
                    !!errorKeys.length && touched.pymeEmail && errors.pymeEmail
                  }
                />
              </CustomInputContainer>
              <CustomInputContainer borderSide="right">
                <ContainerCountry>
                  <Select
                    label={t("register.countryCode")}
                    type="pymeCountry"
                    name="pymeCountry"
                    id="pymeCountry"
                    data-testid="pymeCountry"
                    placeholder={t("register.countryCode")}
                    options={countryPhoneCodes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.pymeCountry}
                    error={touched.pymeCountry && errors.pymeCountry}
                  />
                </ContainerCountry>
                <ContainerPhone>
                  <Input
                    placeholder={t("questions.pymeFormContactPhone")}
                    type="text"
                    extraClassname="input-form"
                    name="pymePhone"
                    id="pyme-contact-phone"
                    data-testid="pyme-contact-phone"
                    onChange={handleChange}
                    value={values.pymePhone}
                    onBlur={handleBlur}
                    error={
                      !!errorKeys.length &&
                      touched.pymePhone &&
                      errors.pymePhone
                    }
                  />
                </ContainerPhone>
              </CustomInputContainer>
            </CustomRowContainer>
            <CustomRowContainer>
              <TextareaContainer>
                <CustomInput
                  inputType="textarea"
                  placeholder={t("questions.formdescription")}
                  extraClassname="input-form"
                  name="pymeDescription"
                  id="pyme-contact-description"
                  data-testid="pyme-contact-description"
                  onChange={handleChange}
                  error={
                    !!errorKeys.length &&
                    touched.pymeDescription &&
                    errors.pymeDescription
                  }
                  value={values.pymeDescription}
                  onBlur={handleBlur}
                />
              </TextareaContainer>
            </CustomRowContainer>
            <AcceptsConditions
              dataTestId="pyme"
              userAcceptedTerms={userAcceptedTerms}
              userAcceptedPolitics={userAcceptedPolitics}
              t={t}
              setPolitics={setPolitics}
              setTerms={setTerms}
            />
            <SendButton
              data-testid="send-button-pyme"
              id="send-button-pyme"
              disabled={isDisabledButtonPyme}
              onClick={() => {
                sendForm("pyme", values);
              }}
            >
              {t("contactForm.send")}
            </SendButton>
          </CustomTabPanel>
        </CustomTabs>
      </InnerContainer>
    </Container>
  );
};

ContactForm.propTypes = {
  values: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    pymeName: PropTypes.string,
    pymeContactName: PropTypes.string,
    pymeCountry: PropTypes.number,
    pymeEmail: PropTypes.string,
    pymePhone: PropTypes.string,
    pymeDescription: PropTypes.string,
    formType: PropTypes.string
  }).isRequired,
  errors: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    pymeName: PropTypes.string,
    pymeContactName: PropTypes.string,
    pymeCountry: PropTypes.number,
    pymeEmail: PropTypes.string,
    pymePhone: PropTypes.string,
    pymeDescription: PropTypes.string
  }).isRequired,
  touched: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    pymeName: PropTypes.string,
    pymeCountry: PropTypes.number,
    pymeContactName: PropTypes.string,
    pymeEmail: PropTypes.string,
    pymePhone: PropTypes.string,
    pymeDescription: PropTypes.string
  }).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  defaultIndex: PropTypes.number
};

ContactForm.defaultProps = { defaultIndex: 1 };

export default withFormik({
  mapPropsToValues: () => ({
    name: "",
    email: "",
    description: "",
    pymeName: "",
    pymeContactName: "",
    pymeCountry: 57,
    pymeEmail: "",
    pymePhone: "",
    pymeDescription: "",
    formType: ""
  }),
  validationSchema
})(ContactForm);
