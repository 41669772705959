import styled from "styled-components";

export const TabListContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
  ${({ theme }) => theme.smartphone} {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    & > div:first-child {
      margin-left: 0px;
    }
    #whyUs-tooltip {
      :after {
        margin-left: 52%;
      }
      ${({ theme }) => theme.smartphone375} {
        :after {
          margin-left: 52% !important;
        }
      }
      ${({ theme }) => theme.smartphone425} {
        :after {
          margin-left: 59% !important;
        }
      }
    },
    #equity-tooltip {
      :after {
        margin-left: 0 !important;
      }
    }
    #beInvestment-tooltip {
      :after {
        margin-left: 0 !important;
      }
    }
    #showCase-tooltip {
      :after {
        margin-left: 0 !important;
      }
    }
  }
`;
