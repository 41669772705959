import styled, { css } from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.rhino.bgColor};
  color: ${({ theme }) => theme.colors.rhino.color};
  padding: 40px 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  top: -95px;
  margin-bottom: -95px;

  ${({ theme }) => theme.smartphone} {
    width: 100%;
    padding: 40px 27px;
  }
`;

export const CompaniesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: auto;
  ${({ theme }) => theme.breakpoints.desktopMinWidth1048px} {
    flex-direction: row;
  }
`;

export const DotBVC = styled.div`
  height: 8px;
  width: 8px;
  background-color: #ffffff;
  border-radius: 50%;
`;

export const AllianceContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white.bgColor};
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
  & a > img {
    margin: -6px 0;
  }
  margin: 40px 0 0 0;
  ${({ theme }) => theme.breakpoints.smartphone} {
    border-radius: 34px;
  }
  ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    margin: 0 0 0 0;
    & a > img {
      margin: 5px;
    }
  }
`;

export const SliderWrapper = styled.div`
  align-self: center;
  display: flex;
  justify-content: center;
  margin: 35px 0 0 0;
  border-radius: 40px;
  width: 100%;
  height: 64px;
  padding: 12px 0;
  background-color: ${({ theme }) => theme.colors.CloudBurst.bgColor};
  ${({ theme }) => theme.breakpoints.desktopMinWidth1048px} {
    width: 80%;
    padding: 5px 30px;
  }
  @media screen and (min-width: 480px) {
    height: 88px;
    padding: 12px 25px;
  }
`;

export const SliderContainer = styled.div`
  text-align: center;
  width: 100%;
  div {
    font-size: 22px;
  }
  div:nth-child(2) {
    top: 28px;
    left: -5px;
  }
  div:nth-child(3) {
    top: 28px;
    right: -5px;
  }
  svg {
    margin: 0px 10px;
  }
  ${() => css`
    .sc-fzqKVi {
      padding: 0;
    }
  `}
  ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
    border-radius: 40px;
    align-self: center;
  }
  & img {
    margin: 0 10px 0 0;
    ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
      margin: 0;
    }
  }
`;

export const ContentColumns = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
    padding: 0px 170px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 277px;

  & h2 {
    margin: 15px;
  }

  ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
    width: 100%;
    flex-direction: row;
    align-items: start;
    justify-content: space-around;
  }
`;

export const FirstColumn = styled.div`
  & p {
    line-height: 35px;
  }
  ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
    & h2 {
      font-size: 22px;
    }
    & p {
      line-height: 42px;
      font-size: 18px;
    }
  }
`;

export const TextConntainer = styled.div`
  padding: 0 0 0 13px;
`;

export const SecondColumn = styled.div`
  margin: 34px 0;
  ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
    margin: 0;
    max-width: 338px;
    & h2 {
      font-size: 22px;
    }
    & p {
      line-height: 35px;
      font-size: 18px;
    }
    & > div {
      margin: 0 0 30px 0;
    }
  }
`;

export const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 13px;
  margin: 0 0 10px 0;
  & img {
    margin: 0 19px 0 0;
  }
  & p {
    line-height: 26px;
  }
`;

export const ThirdColumn = styled.div``;

export const SocialContainer = styled.div`
  display: flex;
  padding: 0 0 0 13px;
  & img {
    margin: 0 34px 0 0;
  }
`;

export const ContentLogoSuper = styled.div`
  & img {
    margin: 15px 10px 0 13px;
    height: 242px;
    width: 32px;
  }

  ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
    & img {
      margin: 10px 10px 0 0;
    }
  }
`;

export const ContentDots = styled.div`
  background-image: url("/assets/images/footer/dot-space.png");
  background-repeat: repeat-x;
  height: 1vh;
  width: 95%;
  margin: 36px auto 18px;
  ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
    width: 75%;
    margin: 33px auto 20px auto;
  }
`;

export const ContentSliderItems = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  &.ccppr {
    justify-content: start;
  }
  ${({ theme }) => theme.smartphone} {
    & img {
    }
    &.content-slider-items {
      margin-top: -7px;
      display: flex !important;
    }
  }
  &.mt-10 {
    margin-top: -10px;
  }
  ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
    &.content-slide {
      padding: 0 40px;
    }
    &.ccppr {
      justify-content: space-around;
    }
    & img {
      transform: scale(1);
    }
  }
`;

export const PrefooterContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white.bgColor};
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  margin: 0px 0 0 25px;
  padding: 60px 24px;
  position: relative;
  top: ${(props) => props.showCampaingTypes ? "845px" : "-200px" } ;
  right: 0%;
  z-index: 2;
  height: auto;
  ${({ theme }) => theme.desktopMin} {
    padding: 75px 0 60px 60px;
    margin: 0px 0 0 107px;
    height: 389px;
    flex-direction: row;
    top: ${(props) => props.showCampaingTypes ? "500px" : "-200px" };
  }
`;
//

export const LeftContainer = styled.div`
  margin: 0;
`;

export const RightContainer = styled.div`
  width: 296px;
 
  text-align: center;
  ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
    text-align: left;
    margin: 0 0 0 92px;
    width: 579px;
  }
`;

export const TextContainer = styled.div`
  margin: 0 0 48px 0;
  & > p > span.bolder {
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
  }
  & > p {
    line-height: 36px;
    font-weight: 400;
    ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
      line-height: 40px;
      font-size: 32px;
    }
    ${({ theme }) => theme.breakpoints.mobile} {
      display: flex;
      > span {
        font-size: 24px !important;
        padding: 33px 0px 0px;
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  & > button {
    width: 208px;
    height: 40px;
    border-radius: 48px;
    padding: 8px 24px 8px 24px;
    line-height: 22px;
    font-size: 16px;
    font-weight: 700;
    ${({ showAllButtons }) =>
      showAllButtons &&
      css`
        ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
          width: 196px;
          height: 60px;
        }
        font-size: ${({ theme }) => theme.font.size.medium};
        padding: 10px 16px;
      `}
  }
  & > button:first-child {
    margin: 0 0 11px 0;
    ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
      margin: 0 15px 0 0;
    }
  }
`;

export const Image = styled.img`
  width: 216px;
  height: 216px;
  ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
    width: 349px;
    height: 349px;
    padding: 20px 0px 20px;
  }
`;


export const Img = styled.img``;

export const LogoFooter = styled.img`
  width: 120px;
  object-fit: contain;
  ${({ theme }) => theme.breakpoints.mobile} {
    width: 90px;
  }
`;
export const LogoFooterA2censo = styled.img`
  width: 140px;
  object-fit: contain;
`;
export const LogoFooterBVC = styled.img`
  width: 60px;
  object-fit: contain;
`;
export const LogoFooterAllies = styled.img`
  width: 110px;
  object-fit: contain;
  ${({ theme }) => theme.breakpoints.mobile} {
    width: 100px;
  }
`;

export const A2censoBVC = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0px;
  div:first-of-type {
    margin: 0px 40px;
  }
  ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
    margin-right: 45px;
  }
`;

export const Anchor = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const ContentBranding = styled.div`
  margin: 42px 0 0 0;
  text-align: center;
  & p {
    line-height: 35px;
  }
  ${({ theme }) => theme.breakpoints.tabletMinWidth769px} {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 42px 127px 0 0;
    & > a > p {
      margin: 0 20px 0 0;
    }
    position: relative;
    & > a:first-child > p:after {
      content: "|";
      position: absolute;
      margin-left: 10px;
    }
  }
`;
export const ContentGrabLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & p {
    margin: 0 10px 0 0;
  }
`;
export const MobileSpan = styled.span`
  font-size: 23px;

  span {
    font-weight: 400;
    
  }
`;
