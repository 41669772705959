import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { push } from "connected-react-router";

import {
  Container,
  Paragraph,
  Title,
  Button,
  IconTitleContainer,
  IconTitle,
  Anchor
} from "./Styles";

const AccountRecoverySuccess = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const continueToLogin = () => dispatch(push("/login"));
  return (
    <Container>
      <IconTitleContainer>
        <IconTitle alt="check-round" src="/assets/images/check_round.png" />
      </IconTitleContainer>
      <Title>{`${t("accountRecovery.recoverySuccessTitle")}`}</Title>
      <Paragraph>{`${t("accountRecovery.recoverySuccessText")}`}</Paragraph>
      <Button id="login-button" onClick={continueToLogin}>
        {t("accountRecovery.loginButton")}
      </Button>
      <Anchor
        id="recovery-link"
        onClick={(e) => {
          e.preventDefault();
          dispatch(push("/forgot-password"));
        }}
      >
        {t("accountRecovery.passwordRecovery")}
      </Anchor>
    </Container>
  );
};

export default AccountRecoverySuccess;
