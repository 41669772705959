import { createReducer, createActions } from "reduxsauce";
import { ICampaign, CampaignStore } from "./types";

const { Types, Creators } = createActions({
  setCampaigns: ["campaigns"],
  setLoading: ["loading"],
  setTotalPages: ["totalPages"],
  setCurrentPage: ["currentPage"],
  setError: ["error"]
});

export const Campaigns = Types;
export default Creators;

const INITIAL_STATE: CampaignStore = {
  campaigns: [],
  error: "",
  isLoading: false,
  totalCampaigns: 0,
  totalPages: 0,
  currentPage: 1
};

const setCampaigns = (
  state: CampaignStore,
  { campaigns }: { campaigns: ICampaign[] }
) => ({
  ...state,
  campaigns,
  isLoading: false
});

const setTotalPages = (
  state: CampaignStore,
  { totalPages }: { totalPages: number }
) => ({
  ...state,
  totalPages,
  isLoading: false
});

const setCurrentPage = (
  state: CampaignStore,
  { currentPage }: { currentPage: number }
) => ({
  ...state,
  currentPage,
  isLoading: false
});

const setLoading = (
  state: CampaignStore,
  { loading }: { loading: boolean }
) => ({
  ...state,
  isLoading: loading || true
});

const setError = (state: CampaignStore, { error }: { error: string }) => ({
  ...state,
  isLoading: false,
  error
});

export const campaignsReducer = createReducer(INITIAL_STATE, {
  [Types.SET_CAMPAIGNS]: setCampaigns,
  [Types.SET_LOADING]: setLoading,
  [Types.SET_TOTAL_PAGES]: setTotalPages,
  [Types.SET_CURRENT_PAGE]: setCurrentPage,
  [Types.SET_ERROR]: setError
});
