import styled from "styled-components";

export const Footer = styled.div`
  background-color: ${({ theme }) => theme.lightGray};
  position: fixed;
  justify-content: center;
  align-items: center;
  height: 83px;
  bottom: 0;
  width: 100%;
  z-index: 10;
  padding: 0px 71px;
  ${({ theme }) => theme.smartphone} {
    padding: 0px 10px;
    height: 80px;
    position: inherit;
  }
`;

export const DottedSeparator = styled.p`
  color: ${({ theme }) => theme.dustyGray};
  overflow: hidden;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;
  &:after {
    content: "••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••";
    letter-spacing: 10px;
  }
`;
