import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import Anchor from "./Styles";
import { useSelector } from "react-redux";
import "./Oops.scss";
import logoImg from "assets/base64/logoImg";
import oopsImg from "assets/base64/oopsImg";

const Oops = ({ location }) => {
  const { t } = useTranslation();
  const buttonText =
    location.state != null && location.state.buttonText
      ? location.state.buttonText
      : "oops.goHome";
  const subtitle =
    location.state != null && location.state.subtitle
      ? location.state.subtitle
      : false;
  const message =
    location.state != null && location.state.message
      ? location.state.message
      : "oops.tryAgain";
  const link =
    location.state != null && location.state.link ? location.state.link : "/";
  let hideButton =
    location.state != null && location.state.hideButton
      ? location.state.hideButton
      : false;

  const {
    internetAlert: { showInternetAlert }
  } = useSelector((state) => state);

  if (showInternetAlert) {
    hideButton = true;
  }
  return (
    <>
      <div className="container oops">
        <div className="oops__logo">
          <img className="oops__logo-image" src={logoImg} alt="a2censo" />
        </div>

        <img
          className="oops__image col-md-6 offset-md-3 offset-0"
          src={oopsImg}
          alt="oops"
        />
        {!showInternetAlert ? (
          <div className="oops__message " data-testid="intetnet-on">
            {subtitle ? (
              <p className="description-roboto-tuatara-17 {">
                <strong>{t(subtitle)}</strong>
              </p>
            ) : (
              <p className="description-roboto-tuatara-17 {">
                {t("oops.sorry")}
                <b>{t("oops.itsUs")}</b>
              </p>
            )}

            <p className="description-roboto-tuatara-17 {">{t(message)}</p>
          </div>
        ) : (
          <div className="oops__message" data-testid="intetnet-off">
            <p className="description-roboto-tuatara-17 ">
              {t("oops.conectionLost")}
            </p>
          </div>
        )}
        {!hideButton && (
          <div className="oops__button-container">
            <Anchor
              data-testid="go-back-link"
              className="oops__link"
              href={link}
            >
              <Button
                className="oops__button"
                buttonStyle="transparent-blue"
                value={t(buttonText)}
              />
            </Anchor>
          </div>
        )}
      </div>
    </>
  );
};

Oops.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      subtitle: PropTypes.string,
      message: PropTypes.string,
      buttonText: PropTypes.string,
      link: PropTypes.string,
      hideButton: PropTypes.bool
    })
  })
};

Oops.defaultProps = {
  location: {
    state: null
  }
};

export default Oops;
