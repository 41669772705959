import styled from "styled-components";
import { ModalSize } from "../Modal";

type Props = {
  size: ModalSize;
};

export const Header = styled.div<Props>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: ${({ size, theme }) => theme.modal.sizes.desktop[size]};
  padding-bottom: 27px;
  align-items: center;

  > div {
    flex-basis: 50%;
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    width: ${({ size, theme }) => theme.modal.sizes.mobile[size]};
    margin-top: 0px;
  }
`;
