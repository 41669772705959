import React from "react";
import EquityModalAvatar from "assets/images/equity/modal-avatar.png";
import EquityModalCloseButton from "assets/images/equity/modal-close-button.svg";
import {
  Background,
  AvatarContainer,
  Avatar,
  Dots,
  Text,
  Title,
  Container,
  ButtonContainer,
  UnderstoodButton,
  KnowUsButton,
  CloseButtonContainer,
  CloseButton,
  Wrapper
} from "./components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ModalActions from "../../redux/reducers/modal.reducer";
import { push } from "connected-react-router";
import homeSelectionsActions from "redux/reducers/home-selections.reducer";
import sectionTabsActions from "redux/reducers/section-tabs.reducer";

enum TabOptions {
  Id = "equity",
  Margin = "330",
  Position = "2"
}

export const EquityModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeButton = () => {
    dispatch(ModalActions.setConditionalModalState(false));
  };

  const scrollToTop = () => {
    const element = document.getElementById("tabs-container-hero");
    const topPosition = Number(element?.offsetTop) - 70;
    window.scroll({ top: topPosition, behavior: "smooth" });
  };

  const goToEquity = () => {
    dispatch(homeSelectionsActions.setInvestSelection(TabOptions.Id));
    dispatch(
      push("/?seccion=invierte/crowdfunding-compra-acciones-y-notas-convertibles-de-empresas")
    );
    dispatch(sectionTabsActions.setPositionTab(+TabOptions.Position));
    dispatch(sectionTabsActions.setMarginTab(TabOptions.Margin));

    scrollToTop();
    closeButton();
  };
  return (
    <Wrapper id="EquityModal">
      <CloseButtonContainer>
        <CloseButton src={EquityModalCloseButton} onClick={closeButton} />
      </CloseButtonContainer>
      <Container>
        <Dots top={0} left={0} />
        <Dots
          type="secondary"
          right={2}
          bottom={1}
          height={109}
          width={75}
          mobileWidth={0}
        />

        <Background>
          <AvatarContainer>
            <Avatar src={EquityModalAvatar} alt="avatar-equity" />
          </AvatarContainer>
          <Title>{t("equity.modal.title")}</Title>
          <Text>{t("equity.modal.text")}</Text>
          <ButtonContainer>
            <UnderstoodButton
              onClick={closeButton}
              data-testid="ok-button-modal-equity"
            >
              {t("equity.modal.understoodButton")}
            </UnderstoodButton>
            <KnowUsButton
              onClick={goToEquity}
              data-testid="ok-button-modal-know-us"
            >
              {t("equity.modal.knowUsButton")}
            </KnowUsButton>
          </ButtonContainer>
        </Background>
      </Container>
    </Wrapper>
  );
};
