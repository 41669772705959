import { map, flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import LoginActions from "../reducers/login.reducer";
import { RejectRequestRejectionSerializer } from "../../lib/serializers/rejectRequestRejection.serializer";

export const sendRejectRequestRejection = (issuerPaymentId, token) => ({
  type: "REJECT_REQUEST_REJECTION",
  payload: {
    issuerPaymentId,
    token
  }
});

export const rejectRequestRejectionFail = ({ error }) => ({
  type: "REJECT_REQUEST_REJECTION_FAIL",
  payload: {
    error
  }
});

export const sendRejectRequestRejectionData = (action$) =>
  action$.pipe(
    ofType("REJECT_REQUEST_REJECTION"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.sendRejectRequestRejection({
          body: RejectRequestRejectionSerializer().serialize(action.payload)
        })
      ).pipe(
        map(() => push("/reject-request-rejection")),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(
            Observable.of(rejectRequestRejectionFail(e)),
            Observable.of(push("/oops"))
          );
        })
      )
    )
  );

export default sendRejectRequestRejectionData;
