import React, { useState, useEffect } from "react";
import {
  ContainerFinancialTax,
  Header,
  ContainerTitle,
  ContainerOptions
} from "./Styles";
import { Heading, Button, Paragraph } from "@bvcco/a2censo-component-lib";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FinancialTaxCard } from "components/shared";
import { onDownloadCertificateTaxFinancial } from "redux/epics/user-certificates";
import { DropdownIOption } from "components/shared";
import MoldalActions from "redux/reducers/modal.reducer";
import UserCertificates, {
  Store,
  Certificates as CertificatesT,
  TypeCertificate
} from "redux/reducers/user-certificates.reducer";
import theme from "styles/LightTheme";
import StateSendMail from "assets/images/state-sent.png";
import UserModel from "lib/models/user.model";

const modalGeneral = {
  clearBlur: true,
  css: {
    backgroundColor: theme.transparentMartinique
  }
};

type ICertificate = {
  quantity: number;
  description: string;
} & CertificatesT;

export type IProps = {
  onClickGoBack?: () => void;
  yearList: Array<{ id: number; value: number }>;
  yearInitial: number;
  yearSelected?: number;
};

const FinancialTax = ({ onClickGoBack, yearList, yearInitial }: IProps) => {
  const { t } = useTranslation();
  const [yearSelected, setYear] = useState(yearInitial);

  const dispatch = useDispatch();
  const isInvestor = useSelector(UserModel.getPersonType) !== UserModel.type.pyme;
  useEffect(() => {
    dispatch(
      UserCertificates.fetchCertificates({
        year: yearSelected,
        type: TypeCertificate.FinancialTax,
        isInvestor
      })
    );
  }, []);

  const { certificatesFilter, loading } = useSelector(
    (store: Store) => store.userCertificates
  );

  const certificatesData = certificatesFilter?.map(
    (certificate): ICertificate => ({
      ...certificate,
      quantity: certificate.certificateId ? 1:0,
      description: "",
      companyName: certificate.companyName
    })
  );

  const onSearchYear = (value: DropdownIOption) => {
    setYear(Number(value.id));
    dispatch(
      UserCertificates.fetchCertificates({
        year: Number(value.id),
        type: TypeCertificate.FinancialTax,
        isInvestor
      })
    );
  };

  const handleSendEmail = () => {
    dispatch(
      MoldalActions.setConditionalModalState(true, "ModalLoadingCertificate", {
        ...modalGeneral,
        image: StateSendMail,
        description: t("myCertificates.modalLoading.descriptionEmail")
      })
    );
    dispatch(UserCertificates.sendEmailFinancialTax(yearSelected));
  };
  const onDownload = () => {
    dispatch(
      MoldalActions.setConditionalModalState(true, "ModalLoadingCertificate", {
        ...modalGeneral,
        description: t("myCertificates.modalLoading.descriptionDownload")
      })
    );

    dispatch(onDownloadCertificateTaxFinancial(yearSelected));
  };

  return (
    <ContainerFinancialTax data-testid={"financialtax-container"}>
      <Header>
        <Button
          dataTestid={"go-back-button"}
          onClick={onClickGoBack}
          color="whiteManatee"
          rounded={true}
          icon={{
            icon: <FontAwesomeIcon icon={faChevronLeft} />,
            position: "left"
          }}
        >
          {t("myCertificates.detail.backButton")}
        </Button>
        <ContainerTitle>
          <Heading>{t("myCertificates.detail.financialtax.title")}</Heading>
          <Paragraph>
            {t("myCertificates.detail.financialtax.description.part1")}
            <strong>
              {t("myCertificates.detail.financialtax.description.bold")}
            </strong>
          </Paragraph>
        </ContainerTitle>
      </Header>
      <ContainerOptions>
        <FinancialTaxCard
          id="year"
          certificates={certificatesData}
          yearList={yearList}
          initialLabel={yearInitial}
          yearInitial={yearSelected}
          description={t("myCertificates.detail.financialtax.card")}
          onChangeYear={onSearchYear}
          onClickEmail={handleSendEmail}
          onClickDownload={onDownload}
        />
      </ContainerOptions>
    </ContainerFinancialTax>
  );
};

export default FinancialTax;
