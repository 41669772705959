import axiosRetry, { isRetryableError } from "axios-retry";
import { Deserializer } from "jsonapi-serializer";
import interceptor from "./interceptor";
import { API_RETRIES, DELAY_RETRIES } from "config/network";
import App from "./app";
import { startLogoutFront } from "redux/epics/login";
import unauthorized401Whitelist from "./unauthorized401-whitelist";

/* eslint prefer-promise-reject-errors: 0 */
class RequestContext {
  constructor(axios, delayRetries = DELAY_RETRIES) {
    this.axios = axios;
    axiosRetry(this.axios, {
      retries: API_RETRIES,
      retryDelay: (retryCount) => retryCount * delayRetries,
      retryCondition: (error) => isRetryableError(error)
    });
  }

  requestInterceptor() {
    this.axios.interceptors.request.use(interceptor, (error) =>
      Promise.reject(error)
    );
  }

  responseInterceptor() {
    this.axios.interceptors.response.use(
      (response) => {
        if (response.data) {
          const dataDeserialized = new Deserializer({
            keyForAttribute: "snake_case",
            action: {
              valueForRelationship: (relationship) => ({
                id: relationship.id
              })
            }
          }).deserialize(response.data);

          return { data: dataDeserialized };
        }
        return response;
      },
      (error) => {
        if (
          error?.response.status === 401 &&
          !unauthorized401Whitelist.includes(window.location.pathname)
        ) {
          return this.handleError401();
        }

        return Promise.reject({
          ...error.response.data,
          statusCode: error.response.status
        });
      }
    );
  }

  handleError401() {
    window.location.href = window.location.origin;
    return App.redux.store.dispatch(startLogoutFront());
  }
}

export default RequestContext;
