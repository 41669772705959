/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import TimeLine from "../../components/TimeLine/TimeLine";
import Payment from "../../components/Payment/Payment";
import PaymentsActions from "../../redux/reducers/payments.reducer";
import Select from "../../components/Select/Select";
import {
  PageTitle,
  PageSubtitle,
  Name,
  SubtitleContainer,
  PaymentListContainer,
  MainContainer,
  Button,
  Placeholder,
  PlaceholderMessage,
  PlaceholderImage,
  PlaceholderLink,
  ImgContainer,
  Container,
  ArrowImage,
  PaymentRow,
  CardTitle,
  PaymentTitle,
  PaymentBody,
  PaymentContainer,
  PaymentContainerDesktop,
  Payments,
  PaymentButtonContainer,
  PaymentContainerOptions,
  PaymentContainerOption,
  PaymentBodyWarning,
  WarningIcon,
  SpanWarning,
  SpanWarningTitle,
  WarningSubTitle,
  BackButton,
  BackButtonImage,
  BackContainer,
  BackgrountImage,
  PaymentState,
  InfoImage,
  InfoText
} from "./Styles";

import MyPaymentsActions from "../../redux/reducers/payments.reducer";
import IssuerPaymentModel from "../../lib/models/issuerPayment.model";
import { differenceForPayments } from "../../lib/utils.js";
import { Loader } from "@bvcco/a2censo-component-lib";

const MyPayments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(MyPaymentsActions.startMyPayments());
    return () => {};
  }, []);

  const paymentsSelector = useSelector(({ payments }) => payments);
  const name = useSelector((state) => state.user.userInfo.user.name);
  const otherAmount = useSelector((state) => state.payments.otherAmount);
  const showState = useSelector((state) => state.payments.showState);
  const pendingPayments = paymentsSelector.issuerPaymentList || [];
  const payments = paymentsSelector.paymentsList || [];
  const holidays = paymentsSelector.holidays || [];

  const [campaignValue, setCampaignValue] =
    pendingPayments.length > 0
      ? useState(pendingPayments[0].campaign_id)
      : useState("");

  const [optionValue, setOptionValue] =
    // eslint-disable-next-line no-nested-ternary
    pendingPayments.length > 0
      ? pendingPayments[0].issuer_payment.state ===
        IssuerPaymentModel.state.pending_new_amount_request
        ? useState(pendingPayments[0].id * -1)
        : useState(pendingPayments[0].id)
      : useState("");

  const campaigns = pendingPayments.map((item) => ({
    value: item.campaign_id,
    text: `${item.name}`
  }));

  const handleClickLink = () => {
    if (pendingPayments.length > 0 || payments.length > 0) {
      return dispatch(push("/payments"));
    }
  };

  const { state: stateFromModel } = IssuerPaymentModel;
  const handleClickPay = (campaignSelected, issuerPayment) => {
    dispatch(
      PaymentsActions.setCampaignSelected(
        campaignSelected,
        issuerPayment,
        optionValue
      )
    );

    const goToConfirm = [
      stateFromModel.approved_by_timeout,
      stateFromModel.issuer_approved,
      stateFromModel.rejected
    ].includes(issuerPayment.state);

    if (issuerPayment.state === stateFromModel.pending_approve) {
      if (optionValue > 0) {
        return dispatch(push("/payment-detail"));
      }

      return dispatch(push("/prepaid-destiny"));
    }

    if (goToConfirm) {
      return dispatch(push("/confirm-pay-amount"));
    }
  };

  const handleChangeCampaign = (campaignId) => {
    const campaignFiltered = pendingPayments.filter(
      (item) => item.campaign_id === Number(campaignId)
    );
    setCampaignValue(Number(campaignId));
    setOptionValue(campaignFiltered[0].id);
  };

  const handleBack = () => {
    dispatch(push("/"));
  };

  const validateIssuerPaymentState = (issuerPaymentState) =>
    [stateFromModel.pending_approve].includes(issuerPaymentState);
  const isOtherAmountAvailable = (issuerPayment) => {
    const daysLeft = differenceForPayments(
      issuerPayment.payment_expiration_date.split(" ")[0],
      holidays
    );
    return validateIssuerPaymentState(issuerPayment.state) && daysLeft.days > 2;
  };

  const isButtonDisabled = (state, total) => {
    if (optionValue > 0) {
      return [
        IssuerPaymentModel.state.paid,
        IssuerPaymentModel.state.exchange,
        IssuerPaymentModel.state.paid_by_collection_house,
        IssuerPaymentModel.state.pending_new_amount_request,
        IssuerPaymentModel.state.pending_reject_request
      ].includes(state);
    }

    if (IssuerPaymentModel.state.pending_new_amount_request === state) {
      return true;
    }

    return otherAmount <= total;
  };

  const INIT_DAYS = 9;

  if (paymentsSelector.isLoading) {
    return <Loader />;
  }

  return (
    <MainContainer>
      <Container margins={{ bottom: "64px" }}>
        <BackContainer>
          <BackButton data-testid="back-button" onClick={handleBack}>
            <BackButtonImage alt="previous" src="/assets/images/previous.png" />
          </BackButton>
        </BackContainer>
        <PageTitle>{t("myPayments.title")}</PageTitle>
        <SubtitleContainer>
          <PageSubtitle>
            <Name>{name}</Name>
            {t("myPayments.subtitle")}
            <PlaceholderLink
              data-testid="history-link"
              disabled={pendingPayments.length === 0 && payments.length === 0}
              onClick={handleClickLink}
            >
              {t("myPayments.link")}
            </PlaceholderLink>
          </PageSubtitle>
        </SubtitleContainer>
        {pendingPayments.length > 0 && (
          <Payments>
            <PaymentContainer>
              <PaymentTitle>
                <PaymentRow>
                  <CardTitle>{t("myPayments.campaign2")}</CardTitle>
                  <Select
                    className="campaigns"
                    name="campaigns"
                    data-testid="campaigns"
                    options={campaigns}
                    value={campaignValue}
                    onChange={(val) => handleChangeCampaign(val.target.value)}
                  />
                </PaymentRow>
              </PaymentTitle>
              {pendingPayments.map((item) => {
                const {
                  id,
                  campaign_id: campaignId,
                  issuer_payment: issuerPayment
                } = item;
                const daysLeft = issuerPayment.payment_expiration_date
                  ? differenceForPayments(
                      issuerPayment.payment_expiration_date.split(" ")[0],
                      holidays
                    )
                  : -1;
                const daysToPay = issuerPayment.payment_expiration_date
                  ? daysLeft.days
                  : -1;
                const businessDaysToPay = issuerPayment.payment_expiration_date
                  ? daysLeft.businessDays
                  : -1;
                return (
                  // eslint-disable-next-line react/jsx-key
                  <PaymentBody hidden={campaignId !== campaignValue}>
                    {issuerPayment.state && (
                      <PaymentState
                        state={issuerPayment.state}
                        show={showState}
                      >
                        <InfoImage state={issuerPayment.state} />

                        <InfoText>
                          {t(
                            `myPayments.issuerPayment.${issuerPayment.state}`,
                            {
                              name
                            }
                          )}
                        </InfoText>
                      </PaymentState>
                    )}
                    {issuerPayment.payment_expiration_date &&
                      daysToPay <= INIT_DAYS &&
                      businessDaysToPay >= 0 && (
                        <TimeLine dayToPay={daysToPay} />
                      )}
                    {!issuerPayment.payment_expiration_date && (
                      <PaymentBodyWarning>
                        <WarningIcon className="warning-2">
                          <SpanWarning>
                            {t("myPayments.warningIcon")}
                          </SpanWarning>
                        </WarningIcon>
                        <SpanWarningTitle>
                          {t("myPayments.warning4")}
                        </SpanWarningTitle>
                        <WarningSubTitle className="warning-2" />
                        <WarningSubTitle className="warning-3">
                          {t("myPayments.warning6")}
                        </WarningSubTitle>
                      </PaymentBodyWarning>
                    )}
                    {issuerPayment.payment_expiration_date &&
                      businessDaysToPay >= 0 && (
                        <PaymentContainerOptions>
                          <PaymentContainerOption
                            data-testid={`minimum-amount-button-mobile-${id}`}
                            onClick={() =>
                              issuerPayment.state ===
                              IssuerPaymentModel.state
                                .pending_new_amount_request
                                ? ""
                                : setOptionValue(id)
                            }
                          >
                            <Payment
                              daysToPay={daysToPay}
                              available={
                                issuerPayment.state !==
                                IssuerPaymentModel.state
                                  .pending_new_amount_request
                              }
                              amountValue={issuerPayment.total}
                              selectedOption={optionValue === id}
                              dateLimit={
                                issuerPayment.payment_expiration_date.split(
                                  " "
                                )[0]
                              }
                            />
                          </PaymentContainerOption>
                          <PaymentContainerOption
                            data-testid={`other-amount-button-mobile-${id}`}
                            onClick={() =>
                              issuerPayment.state ===
                                IssuerPaymentModel.state.pending_approve &&
                              businessDaysToPay > 2
                                ? setOptionValue(id * -1)
                                : ""
                            }
                          >
                            <Payment
                              daysToPay={daysToPay}
                              displayInput={true}
                              available={
                                issuerPayment.state ===
                                IssuerPaymentModel.state
                                  .pending_new_amount_request
                                  ? true
                                  : isOtherAmountAvailable(item.issuer_payment)
                              }
                              amountValue=""
                              minimumAmountValue={issuerPayment.total}
                              selectedOption={optionValue === id * -1}
                              issuerPaymentState={issuerPayment.state}
                              amountRequestedValue={
                                issuerPayment.amount_requested
                              }
                            />
                          </PaymentContainerOption>
                        </PaymentContainerOptions>
                      )}
                    {issuerPayment.payment_expiration_date &&
                      businessDaysToPay < 0 && (
                        <PaymentBodyWarning>
                          <WarningIcon>
                            <SpanWarning>
                              {t("myPayments.warningIcon")}
                            </SpanWarning>
                          </WarningIcon>
                          <SpanWarningTitle>
                            {t("myPayments.warning")}
                          </SpanWarningTitle>
                          <WarningSubTitle>
                            {t("myPayments.warning2")}
                          </WarningSubTitle>
                          <WarningSubTitle className="warning-3">
                            {t("myPayments.warning3")}
                          </WarningSubTitle>
                        </PaymentBodyWarning>
                      )}
                    {issuerPayment.payment_expiration_date &&
                      businessDaysToPay >= 0 && (
                        <PaymentButtonContainer>
                          <Button
                            disabled={isButtonDisabled(
                              issuerPayment.state,
                              issuerPayment.total
                            )}
                            border={true}
                            label={t("myPayments.button")}
                            value={t("myPayments.button")}
                            data-testid={`pay-button-mobile-${id}`}
                            onClick={() =>
                              handleClickPay(campaignId, issuerPayment)
                            }
                          >
                            {t("myPayments.button")}
                            <ArrowImage
                              alt="arrow"
                              src="/assets/images/arrow-right.png"
                            />
                          </Button>
                        </PaymentButtonContainer>
                      )}
                  </PaymentBody>
                );
              })}
            </PaymentContainer>
            {pendingPayments.map((item, index) => {
              const {
                id,
                campaign_id: campaignId,
                issuer_payment: issuerPayment,
                name: campaignName
              } = item;
              const daysLeft = issuerPayment.payment_expiration_date
                ? differenceForPayments(
                    issuerPayment.payment_expiration_date.split(" ")[0],
                    holidays
                  )
                : -1;
              const daysToPay = issuerPayment.payment_expiration_date
                ? daysLeft.days
                : -1;
              const businessDaysToPay = issuerPayment.payment_expiration_date
                ? daysLeft.businessDays
                : -1;
              const isSecondCard = !!(index % 2);
              return (
                <PaymentContainerDesktop
                  key={String(id)}
                  className={isSecondCard ? "second" : ""}
                >
                  <PaymentTitle>
                    <PaymentRow>
                      <CardTitle>
                        {t("myPayments.campaign")}
                        <strong>{campaignName}</strong>
                      </CardTitle>
                    </PaymentRow>
                  </PaymentTitle>
                  <PaymentBody>
                    {issuerPayment.state && (
                      <PaymentState
                        state={issuerPayment.state}
                        show={showState}
                      >
                        <InfoImage state={issuerPayment.state} />

                        <InfoText>
                          {t(
                            `myPayments.issuerPayment.${issuerPayment.state}`,
                            {
                              name
                            }
                          )}
                        </InfoText>
                      </PaymentState>
                    )}
                    {issuerPayment.payment_expiration_date &&
                      daysToPay <= INIT_DAYS &&
                      businessDaysToPay >= 0 && (
                        <TimeLine
                          dayToPay={daysToPay}
                          extended={daysLeft.extended}
                        />
                      )}
                    {!issuerPayment.payment_expiration_date && (
                      <PaymentBodyWarning>
                        <WarningIcon className="warning-2">
                          <SpanWarning>
                            {t("myPayments.warningIcon")}
                          </SpanWarning>
                        </WarningIcon>
                        <SpanWarningTitle>
                          {t("myPayments.warning4")}
                        </SpanWarningTitle>
                        <WarningSubTitle className="warning-2" />
                        <WarningSubTitle className="warning-3">
                          {t("myPayments.warning6")}
                        </WarningSubTitle>
                      </PaymentBodyWarning>
                    )}
                    {issuerPayment.payment_expiration_date &&
                      businessDaysToPay >= 0 && (
                        <PaymentContainerOptions>
                          <PaymentContainerOption
                            data-testid={`minimum-amount-button-${id}`}
                            onClick={() =>
                              issuerPayment.state ===
                              IssuerPaymentModel.state
                                .pending_new_amount_request
                                ? ""
                                : setOptionValue(id)
                            }
                          >
                            <Payment
                              daysToPay={daysToPay}
                              available={
                                issuerPayment.state !==
                                IssuerPaymentModel.state
                                  .pending_new_amount_request
                              }
                              amountValue={issuerPayment.total}
                              selectedOption={optionValue === id}
                              dateLimit={
                                differenceForPayments(
                                  issuerPayment.payment_expiration_date.split(
                                    " "
                                  )[0],
                                  holidays
                                ).limitDate
                              }
                            />
                          </PaymentContainerOption>
                          <PaymentContainerOption
                            data-testid={`other-amount-button-${id}`}
                            onClick={() =>
                              validateIssuerPaymentState(issuerPayment.state) &&
                              businessDaysToPay > 2
                                ? setOptionValue(id * -1)
                                : ""
                            }
                          >
                            <Payment
                              daysToPay={daysToPay}
                              displayInput={true}
                              available={
                                issuerPayment.state ===
                                IssuerPaymentModel.state
                                  .pending_new_amount_request
                                  ? true
                                  : isOtherAmountAvailable(item.issuer_payment)
                              }
                              amountValue=""
                              minimumAmountValue={issuerPayment.total}
                              selectedOption={optionValue === id * -1}
                              issuerPaymentState={issuerPayment.state}
                              amountRequestedValue={
                                issuerPayment.amount_requested
                              }
                            />
                          </PaymentContainerOption>
                        </PaymentContainerOptions>
                      )}
                    {issuerPayment.payment_expiration_date &&
                      businessDaysToPay < 0 && (
                        <PaymentBodyWarning>
                          <WarningIcon>
                            <SpanWarning>
                              {t("myPayments.warningIcon")}
                            </SpanWarning>
                          </WarningIcon>
                          <SpanWarningTitle>
                            {t("myPayments.warning")}
                          </SpanWarningTitle>
                          <WarningSubTitle>
                            {t("myPayments.warning2")}
                          </WarningSubTitle>
                          <WarningSubTitle className="warning-3">
                            {t("myPayments.warning3")}
                          </WarningSubTitle>
                        </PaymentBodyWarning>
                      )}
                    {issuerPayment.payment_expiration_date &&
                      businessDaysToPay >= 0 && (
                        <PaymentButtonContainer>
                          <Button
                            disabled={isButtonDisabled(
                              issuerPayment.state,
                              issuerPayment.total
                            )}
                            border={true}
                            label={t("myPayments.button")}
                            value={t("myPayments.button")}
                            data-testid={`pay-button-${id}`}
                            onClick={() =>
                              handleClickPay(campaignId, issuerPayment)
                            }
                          >
                            {t("myPayments.button")}
                            <ArrowImage
                              alt="arrow"
                              src="/assets/images/arrow-right.png"
                            />
                          </Button>
                        </PaymentButtonContainer>
                      )}
                  </PaymentBody>
                </PaymentContainerDesktop>
              );
            })}
          </Payments>
        )}
        {pendingPayments.length === 0 && (
          <PaymentListContainer>
            <Placeholder>
              <ImgContainer>
                <BackgrountImage>
                  <PlaceholderImage
                    alt="money"
                    src="/assets/images/icono_dinero.png"
                  />
                </BackgrountImage>
              </ImgContainer>
              <PlaceholderMessage>
                {t("myPayments.noPendingPayments")}
              </PlaceholderMessage>
            </Placeholder>
          </PaymentListContainer>
        )}
      </Container>
    </MainContainer>
  );
};

MyPayments.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      response: PropTypes.shape({})
    })
  }).isRequired
};

export default MyPayments;
