import { map, flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import { purposeSerializer } from "../../lib/serializers/purpose.serializer";
import UserActions from "../reducers/user.reducer";
import User from "../../lib/models/user.model";
import App from "../../lib/app";
import LoginActions from "../reducers/login.reducer";
import EnrollmentActions from "../reducers/enrollment.reducer";
import AnalyticsActions from "../reducers/analytics.reducer";
import AnalyticsModel from "../../lib/models/analytics.model";

export const purposeList = () => ({
  type: "LOAD_PURPOSE_LIST"
});

export const savepurpose = ({ purposeId }) => ({
  type: "SAVE_PURPOSE",
  payload: {
    purposeId
  }
});

export const purposeListFail = ({ error }) => ({
  type: "PURPOSE_LIST_FAIL",
  payload: {
    error
  }
});

export const loadpurposeList = (action$) =>
  action$.pipe(
    ofType("LOAD_PURPOSE_LIST"),
    flatMap(() => {
      const { user } = App.redux.store.getState();
      const personTypeValue = user.userInfo.user.person_type;
      return Observable.from(
        App.api.a2censo.purposeList({
          url: { personType: personTypeValue }
        })
      ).pipe(
        map((response) =>
          push("/enrollment/purpose", {
            response
          })
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(
            Observable.of(purposeListFail(e)),
            Observable.of(push("/oops"))
          );
        })
      );
    })
  );

export const savepurposeItem = (action$) =>
  action$.pipe(
    ofType("SAVE_PURPOSE"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.savepurpose({
          body: purposeSerializer().serialize({
            purposeId: action.payload.purposeId
          })
        })
      ).pipe(
        flatMap(() => {
          const { user } = App.redux.store.getState();
          const personType = user.userInfo.user.person_type;
          const returnTrack =
            User.type.natural === personType
              ? AnalyticsActions.trackEvent(AnalyticsModel.savePurpose)
              : AnalyticsActions.trackEvent(AnalyticsModel.savePurposeLegal);
          return Observable.concat(
            Observable.of(returnTrack),
            Observable.of(EnrollmentActions.enrollmentSuccess()),
            Observable.of(
              UserActions.setUserState(
                User.type.natural === personType
                  ? User.state.Summary
                  : User.state.pendingDocumentValidation
              )
            ),
            Observable.of(
              User.type.natural === personType
                ? push("/enrollment/summary")
                : push("/enrollment/success")
            )
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(
            Observable.of(purposeListFail(e)),
            Observable.of(push("/oops"))
          );
        })
      )
    )
  );

export default combineEpics(loadpurposeList, savepurposeItem);
