import styled from "styled-components";

type Props = {
  backgroundColor?: string;
  isInvestor: boolean;
  companyNameLength: number;
};

enum LengthLimit {
  Mobile = 35,
  Desktop = 29
}

const calculateRows = (
  isInvestor: boolean,
  companyNameLength: number,
  lengthLimit: number
) => {
  if (isInvestor) {
    if (companyNameLength > lengthLimit) {
      return "32px 20px auto 30px";
    }
    return "16px 20px auto 30px";
  } else {
    if (companyNameLength > lengthLimit) {
      return "32px 16px auto";
    }
    return "16px 16px auto";
  }
};

export const CardRightWrapper = styled.div<Props>`
  display: grid;
  grid-template-rows: ${({ isInvestor, companyNameLength }) =>
    calculateRows(isInvestor, companyNameLength, LengthLimit.Desktop)};
  font-family: ${({ theme }) =>
    theme ? theme.font.family.primary : "initial"};
  ${({ theme }) => theme.breakpoints.mobile} {
    max-width: 100%;
    grid-template-rows: ${({ isInvestor, companyNameLength }) =>
      calculateRows(isInvestor, companyNameLength, LengthLimit.Mobile)};
  }
`;
