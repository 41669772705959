import { resettableReducer } from "reduxsauce";
import { reducer as modal } from "./modal.reducer";
import { reducer as codeValidation } from "./code-validations.reducer";
import { reducer as register } from "./register.reducer";
import { reducer as enrollment } from "./enrollment.reducer";
import { reducer as registerData } from "./register-form.reducer";
import { reducer as forgotPassword } from "./forgot-password.reducer";
import { reducer as login } from "./login.reducer";
import { reducer as user } from "./user.reducer";
import { reducer as places } from "./places.reducer";
import { reducer as investmentCancellation } from "./investment-cancellation.reducer";
import { reducer as investmentAmountError } from "./investAmountError.reducer";
import { reducer as request } from "./request.reducer";
import { reducer as recharge } from "./recharge.reducer";
import conditions from "./conditions.reducer";
import { reducer as balance } from "./myBalance.reducer";
import { reducer as movements } from "./movements.reducer";
import { reducer as newsList } from "./news.reducer";
import { reducer as accountRecovery } from "./account-recovery.reducer";
import { reducer as issuerPayment } from "./issuer-payment.reducer";
import { reducer as payments } from "./payments.reducer";
import { reducer as appParams } from "./app-params.reducer";
import { reducer as analytics } from "./analytics.reducer";
import { reducer as welcomePYME } from "./welcome-pyme.reducer";
import { reducer as contactForm } from "./contact-form.reducer";
import { reducer as investingCampaigns } from "./investing-campaigns.reducer";
import { reducer as campaings } from "./campaings.reducer";
import { reducer as simulatorCampaign } from "./simulator-campaign.reducer";
import { reducer as pageData } from "./page-data.reducer";
import { reducer as investmentSummary } from "./investment-summary.reducer";
import { reducer as homeSelections } from "./home-selections.reducer";
import { reducer as FinanceYourself } from "./finance-yourself.reducer";
import { reducer as investment } from "./investment.reducer";
import { reducer as PaymentError } from "./payment-error.reducer";
import { reducer as SelfAppraisal } from "./self-appraisal.reducer";
import { reducer as confirmDataPyme } from "./confirm-data-pyme";
import { reducer as dashboardCampaigns } from "./dashboard-campaigns.reducer";
import { reducer as PSEData } from "./pse-data.reducer";
import { reducer as requestCampaign } from "./request-campaign.reducer";
import { reducer as campaignStepper } from "./create-campaign-stepper.reducer";
import { reducer as FilesUploader } from "./file-uploader.reducer";
import { reducer as MyCompanyInfo } from "./my-company.reducer";
import { reducer as associate } from "./associate.reducer";
import { reducer as interview } from "./interview.reducer";
import { reducer as parameters } from "./parameters.reducer";
import { reducer as campaignDocumentManagment } from "./campaign-document-managment.reducer";
import { reducer as personType } from "./person-type.reducer";
import { reducer as codebtor } from "./codebtor.reducer";
import { reducer as alert } from "./alert.reducer";
import { reducer as userCertificates } from "./user-certificates.reducer";
import { reducer as sendHelpMessage } from "./send-help.reducer";
import { reducer as company } from "./company.reducer";
import { reducer as routeHistory } from "./router-history.reducer";
import { reducer as internetAlert } from "./internet-alert.reducer";
import { reducer as sectionTabs } from "./section-tabs.reducer";
import { reducer as campaingDetail } from "./campaing-detail.reducer";

// listen for the action type of 'RESET', you can change this.
const resettable = resettableReducer("RESET_STATE");

export default {
  routeHistory: resettable(routeHistory),
  modal: resettable(modal),
  codeValidation: resettable(codeValidation),
  user: resettable(user),
  register: resettable(register),
  conditions: resettable(conditions),
  enrollment: resettable(enrollment),
  registerData: resettable(registerData),
  forgotPassword: resettable(forgotPassword),
  login: resettable(login),
  places: resettable(places),
  investmentCancellation: resettable(investmentCancellation),
  investmentAmountError: resettable(investmentAmountError),
  request: resettable(request),
  balance: resettable(balance),
  recharge: resettable(recharge),
  investment: resettable(investment),
  movements: resettable(movements),
  newsList: resettable(newsList),
  accountRecovery: resettable(accountRecovery),
  issuerPayment: resettable(issuerPayment),
  payments: resettable(payments),
  appParams: resettable(appParams),
  analytics: resettable(analytics),
  welcomePYME: resettable(welcomePYME),
  contact: resettable(contactForm),
  investingCampaigns: resettable(investingCampaigns),
  campaings: resettable(campaings),
  simulatorCampaign: resettable(simulatorCampaign),
  pageData: resettable(pageData),
  investmentSummary: resettable(investmentSummary),
  homeSelections: resettable(homeSelections),
  finantialSection: resettable(FinanceYourself),
  paymentError: resettable(PaymentError),
  selfAppraisal: resettable(SelfAppraisal),
  confirmDataPyme: resettable(confirmDataPyme),
  pseData: resettable(PSEData),
  campaignStepper: resettable(campaignStepper),
  dashboardCampaigns: resettable(dashboardCampaigns),
  requestCampaign: resettable(requestCampaign),
  filesUploader: resettable(FilesUploader),
  myCompanyInfo: resettable(MyCompanyInfo),
  associate: resettable(associate),
  parameters: resettable(parameters),
  campaignDocumentManagment: resettable(campaignDocumentManagment),
  personType: resettable(personType),
  interview: resettable(interview),
  codebtor: resettable(codebtor),
  alert: resettable(alert),
  userCertificates: resettable(userCertificates),
  sendHelpMessage: resettable(sendHelpMessage),
  company: resettable(company),
  internetAlert: resettable(internetAlert),
  sectionTabs: resettable(sectionTabs),
  campaingDetail: resettable(campaingDetail)
};
