import { catchError, flatMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import { messageTypes } from "../reducers/send-help.reducer";
import ModalActions from "../reducers/modal.reducer";
import { EmailSerializer } from "../../lib/serializers/email.serializer";
import AlertActions from "../reducers/alert.reducer";

export const sendHelpMessage = (action$: any) =>
  action$.pipe(
    ofType(messageTypes.START_SEND_MESSAGE),
    flatMap((action: any) => {
      const { api }: any = App;
      return Observable.from(
        api.a2censo.sendHelpMessage({
          body: EmailSerializer().serialize({
            message: action.payload.message
          })
        })
      ).pipe(
        flatMap(() =>
          Observable.of(
            ModalActions.setConditionalModalState(false, "HelpForm")
          )
        ),
        catchError(() =>
          Observable.of(AlertActions.setAlertState("error", "alert.tryItLater"))
        )
      );
    })
  );

export default combineEpics(sendHelpMessage);
