import { mergeMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import * as R from "ramda";
import User from "../../lib/models/user.model";
import { startLogoutFlow } from "./login";
import AnalyticsActions from "../reducers/analytics.reducer";
import AnalyticsModel from "../../lib/models/analytics.model";
import RouterHistoryActions from "../reducers/router-history.reducer";

export const validateUserState = () => ({
  type: "VALIDATE_USER_STATE"
});

export const routerAnalitycs = (action, routeHistory) => {
  const emptyEvent = { type: "ANALYTICS/NOTHING" };
  let trackEvent = emptyEvent;
  const {
    payload: {
      location: { pathname },
      action: actionType
    }
  } = action;

  if (actionType === "PUSH") {
    const dictionaryPath = {
      "/ -> /login": AnalyticsModel.toLogin,
      "/ -> /sign-up": AnalyticsModel.toSignUp,
      "/ -> /campaign": AnalyticsModel.toCampaignDetail,
      "/campaign -> /sign-up": AnalyticsModel.toSingUpFromShowcase,
      "/campaign -> /login": AnalyticsModel.toLoginFromShowCase
    };
    const previusPath = routeHistory[0];
    const nextPath = `/${previusPath
      .split("/")[1]
      .replace(" ", "")} -> /${pathname.split("/")[1].replace(" ", "")}`;

    if (dictionaryPath[nextPath]) {
      trackEvent = AnalyticsActions.trackEvent(dictionaryPath[nextPath]);
    }
  }
  return trackEvent;
};

export const routerMiddleware = (action$, state$) =>
  action$.pipe(
    ofType("@@router/LOCATION_CHANGE"),
    mergeMap((reduxAction) => {
      const {
        payload: {
          action,
          location: { pathname }
        }
      } = reduxAction;

      const {
        value: {
          routeHistory: { history },
          homeSelections: { avoidScrollToTop }
        }
      } = state$;

      const lastHistoryRoute = history[0];

      const investSection = "seccion=invierte";
      const financeSection = "seccion=deuda";
      const avoidInvestScroll = new RegExp(investSection).test(location);
      const avoidFinanceScroll = new RegExp(financeSection).test(location);

      const saveRoute =
        lastHistoryRoute !== pathname
          ? Observable.of(RouterHistoryActions.setRoute(pathname))
          : Observable.of();

      if (action === "PUSH") {
        if (!avoidInvestScroll && !avoidFinanceScroll && !avoidScrollToTop) {
          window.scrollTo(0, 0);
        }
        return Observable.concat(
          saveRoute,
          Observable.of(routerAnalitycs(reduxAction, history)),
          Observable.of({ type: "Scroll to top" }),
          Observable.of(validateUserState())
        );
      }
      return Observable.concat(
        saveRoute,
        Observable.of({ type: "No scroll" }),
        Observable.of(validateUserState())
      );
    })
  );

export const routerUserState = (action$, $state) =>
  action$.pipe(
    ofType("VALIDATE_USER_STATE"),
    mergeMap(() => {
      if (User.activeSession()) {
        const StateUser = R.pathOr(
          "",
          ["value", "user", "userInfo", "user", "state"],
          $state
        );
        if (StateUser === User.status.blocked) {
          return Observable.of(startLogoutFlow());
        }
      }
      return Observable.empty();
    })
  );

export default combineEpics(routerMiddleware, routerUserState);
