/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import UserCertificatesActions from "redux/reducers/user-certificates.reducer";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import AppParams from "redux/reducers/app-params.reducer";
import moment from "moment";
import BlockedImg from "../../assets/images/blocked.png";
import { useTranslation } from "react-i18next";
import User from "../../lib/models/user.model";
import "./DownloadCertificates.scss";
import {
  Container,
  ContainerImage,
  Title,
  Text,
  SubButtonContainer,
  ButtonsContainer
} from "./Styled";
import { Button } from "@bvcco/a2censo-component-lib";
import { startLogoutFlow } from "../../redux/epics/login";
import { useSelector } from "react-redux";
import CertificatesDownloadImg from "assets/images/certificates-download.png";

const DownloadCertificates = () => {
  const dispatch = useDispatch();
  const { downloadCertificateError } = useSelector(
    ({ userCertificates }: any) => userCertificates
  );

  const subtitle = "downloadCertificates.subtitle";
  const message = "downloadCertificates.message";

  const validateSessionDate = () => {
    const token = localStorage.getItem("idToken");
    const date = localStorage.getItem("idTokenTime");
    const refresh = localStorage.getItem("refreshToken");

    if (!token) {
      return false;
    }
    const isValid = moment().isBefore(moment(date));
    if (isValid && refresh && date) {
      sessionStorage.setItem("refreshToken", refresh);
      sessionStorage.setItem("idToken", token);
      sessionStorage.setItem("idTokenTime", date);
      sessionStorage.setItem("session_timeout", date);
    }

    return isValid;
  };

  useEffect(() => {
    const deepLink = sessionStorage.getItem("deepLink")
      ? sessionStorage.getItem("deepLink")
      : null;
    const isNewSession = deepLink ? true : false;
    sessionStorage.removeItem("deepLink");
    if (validateSessionDate()) {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      dispatch(UserCertificatesActions.onDownloadCertificatesByToken(token));
      if (!isNewSession) {
        setTimeout(() => {
          window.close();
        }, 4000);
      }
    } else {
      dispatch(
        AppParams.setDeepLink(location.pathname + (location.search || ""))
      );
      sessionStorage.setItem(
        "deepLink",
        location.pathname + (location.search || "")
      );
      dispatch(push("/login"));
    }
  }, []);

  const { t } = useTranslation();
  const errorToken = "435";
  return (
    <Container data-testid="container-download-certificates">
      {downloadCertificateError ? (
        <div>
          <ContainerImage>
            <img src={BlockedImg} style={{ justifySelf: "center" }} />
          </ContainerImage>

          <Title>{t("downloadCertificates.sorry")}</Title>
          <br />

          <Text
            dangerouslySetInnerHTML={{
              __html: t(
                `downloadCertificates.errors.${downloadCertificateError}`
              )
            }}
          ></Text>
          <br />
          <br />

          <ButtonsContainer>
            <SubButtonContainer>
              <Button
                rounded={true}
                fontWeight="bold"
                dataTestid="on-restore-button"
                outlined={true}
                color="blue"
                onClick={() => {
                  dispatch(push("/"));
                }}
              >
                {t("downloadCertificates.homeButton")}
              </Button>
            </SubButtonContainer>
            <SubButtonContainer>
              {errorToken === downloadCertificateError ? (
                <Button
                  rounded={true}
                  fontWeight="bold"
                  dataTestid="on-errorToken"
                  onClick={() => {
                    dispatch(push("/my-certificates"));
                  }}
                >
                  {t("downloadCertificates.myCertificates")}
                </Button>
              ) : (
                <Button
                  rounded={true}
                  fontWeight="bold"
                  onClick={() => {
                    dispatch(startLogoutFlow());
                  }}
                >
                  {t("downloadCertificates.logoutButton")}
                </Button>
              )}
            </SubButtonContainer>
          </ButtonsContainer>
        </div>
      ) : (
        <div className="container download-certificate">
          <img
            className="download-certificate__image col-md-6 offset-md-3 offset-0"
            src={CertificatesDownloadImg}
            alt="certificate_download"
          />
          <div
            className="download-certificate__message "
            data-testid="intetnet-on"
          >
            <p className="description-roboto-tuatara-17 {">
              <strong>{t(subtitle)}</strong>
            </p>
            <p className="description-roboto-tuatara-17 {">{t(message)}</p>
          </div>
        </div>
      )}
    </Container>
  );
};

export default DownloadCertificates;
