import styled from "styled-components";
import Transaction from "../../lib/models/transaction.model";

const captionColor = (type, state, theme) => {
  if (
    [
      Transaction.type.investment,
      Transaction.type.increase,
      Transaction.type.due_payment,
      Transaction.type.recharge
    ].includes(type) &&
    state === Transaction.state.failed
  ) {
    return `
      color: ${theme.red};
    `;
  }
  if (
    (type === Transaction.type.recharge &&
      state !== Transaction.state.rejected) ||
    (type === Transaction.type.investment &&
      state !== Transaction.state.rejected) ||
    (type === Transaction.type.increase &&
      state !== Transaction.state.rejected) ||
    (type === Transaction.type.retirement &&
      state === Transaction.state.rejected) ||
    (type === Transaction.type.earnings &&
      state !== Transaction.state.rejected) ||
    (type === Transaction.type.cashback &&
      state !== Transaction.state.rejected) ||
    (type === Transaction.type.cancellation &&
      state !== Transaction.state.rejected) ||
    (type === Transaction.type.capital &&
      state !== Transaction.state.rejected) ||
    (type === Transaction.type.retention &&
      state === Transaction.state.rejected)
  ) {
    return `color: ${theme.springGreen};`;
  }
  return `
				color: ${theme.red};
			`;
};
const getMargin = (props) => {
  if (
    [
      Transaction.type.investment,
      Transaction.type.increase,
      Transaction.type.due_payment,
      Transaction.type.recharge
    ].includes(props.type) &&
    props.state === Transaction.state.failed
  ) {
    return "margin-left: 10%;";
  }
  return (props.type === Transaction.type.recharge &&
    props.state !== Transaction.state.rejected) ||
    (props.type === Transaction.type.investment &&
      props.state !== Transaction.state.rejected) ||
    (props.type === Transaction.type.increase &&
      props.state !== Transaction.state.rejected) ||
    (props.type === Transaction.type.retirement &&
      props.state === Transaction.state.rejected) ||
    (props.type === Transaction.type.earnings &&
      props.state !== Transaction.state.rejected) ||
    (props.type === Transaction.type.cashback &&
      props.state !== Transaction.state.rejected) ||
    (props.type === Transaction.type.cancellation &&
      props.state !== Transaction.state.rejected) ||
    (props.type === Transaction.type.capital &&
      props.state !== Transaction.state.rejected) ||
    (props.type === Transaction.type.retention &&
      props.state === Transaction.state.rejected)
    ? "margin-right: 10%;"
    : "margin-left: 10%;";
};
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
  ${(props) => props.theme.smartphone} {
    .content-text {
      width: 69%;
    }
  }
`;
export const Container = styled.div`
  background-color: ${(props) => props.theme.white};
  margin-top: 15px;
  ${(props) => getMargin(props)};
  padding: 10px 20px;
  border-radius: 10px;
`;
export const HeadText = styled.div`
  font-size: 14px;
  font-family: "Roboto";
`;
export const SpanText = styled.span`
  font-size: 13px;
  font-family: "Roboto";
  font-weight: bold;
`;
export const Caption = styled.div`
  font-size: 14px;
  font-family: "Roboto";
  ${(props) => captionColor(props.type, props.state, props.theme)}
`;
export const NumberCaption = styled.div`
  font-size: 14px;
  font-family: "Roboto";
  font-weight: bold;
  ${(props) => captionColor(props.type, props.state, props.theme)}
`;
