/* eslint-disable no-underscore-dangle */
import { catchError, flatMap, map } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import myBalanceActions, {
  myBalanceTypes
} from "../reducers/myBalance.reducer";

export const getMyBalance = (action$) =>
  action$.pipe(
    ofType(myBalanceTypes.START_BALANCE_FLOW),
    flatMap(() =>
      Observable.from(App.api.a2censo.balance({})).pipe(
        map((response) =>
          myBalanceActions.endBalanceFlow({
            availableMoney: response.available.balance,
            availableMoneyExchange: response.available.exchange,
            investments: response.investments.balance,
            investmentsExchange: response.investments.exchange,
            generalBalance: response.general_balance,
            equity: response.equity,
            debt: response.debt
          })
        ),
        catchError((e) => Observable.concat(Observable.of(push("/oops", e))))
      )
    )
  );

export default combineEpics(getMyBalance);
