import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setSuccessFull: ["campaings", "isStart"],
  setFeatured: ["campaings", "isStart"],
  getCampaignSuccessfull: ["payload"],
  getCampaignFeatured: ["payload"],
  startCampaignDetail: ["campaignId", "emailView"],
  endCampaignDetail: [],
  setSuccessFullCampaignError: ["error"],
  setFeaturedCampaignError: ["error"]
});

export const CampaingsTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  successfull: [],
  featured: [],
  isLoadingSuccessFull: false,
  isLoadingFeatured: false,
  isLoadingCampaignDetail: false,
  successfullError: "",
  featuredError: ""
};

const setLoadingSucessFull = (state) => ({
  ...state,
  isLoadingSuccessFull: true
});

const setLoadingFeatured = (state) => ({
  ...state,
  isLoadingFeatured: true
});

const setSuccessFull = (state, { campaings, isStart }) => ({
  ...state,
  successfull: isStart ? campaings : [...state.successfull, ...campaings],
  isLoadingSuccessFull: false,
  successfullError: ""
});
const setFeatured = (state, { campaings, isStart }) => ({
  ...state,
  featured: isStart ? campaings : [...state.featured, ...campaings],
  isLoadingFeatured: false,
  featuredError: ""
});
const startCampaignDetail = (state) => ({
  ...state,
  isLoadingCampaignDetail: true
});
const endCampaignDetail = (state) => ({
  ...state,
  isLoadingCampaignDetail: false
});

const setSuccessFullCampaignError = (state, { error }) => ({
  ...state,
  successfullError: error,
  isLoadingSuccessFull: false
});

const setFeaturedCampaignError = (state, { error }) => ({
  ...state,
  featuredError: error,
  isLoadingFeatured: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SUCCESS_FULL]: setSuccessFull,
  [Types.SET_FEATURED]: setFeatured,
  [Types.GET_CAMPAIGN_SUCCESSFULL]: setLoadingSucessFull,
  [Types.GET_CAMPAIGN_FEATURED]: setLoadingFeatured,
  [Types.START_CAMPAIGN_DETAIL]: startCampaignDetail,
  [Types.END_CAMPAIGN_DETAIL]: endCampaignDetail,
  [Types.SET_SUCCESS_FULL_CAMPAIGN_ERROR]: setSuccessFullCampaignError,
  [Types.SET_FEATURED_CAMPAIGN_ERROR]: setFeaturedCampaignError
});
