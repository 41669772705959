import React from "react";
import "./ImageDescription.Styles.scss";
import environment from "environment";
import ImageTab from "./ImageTab/ImageTab.component";

enum ICampaingTypes {
  Debt = 1,
  Shares = 2,
  ConvertibleNote = 3
}

type IProps = {
  campaignDetail: Array<{
    id: number;
    title?: string;
    content?: string;
    url_image?: string;
  }>;
  type: Number;
};
const ImageDescription = ({ campaignDetail, type }: IProps) => {
  return (
    <div className="text__image__container">
      <div className="image__container">
        {campaignDetail &&
          campaignDetail.map(({ id, title, content, url_image }) => (
            <div key={id} className="image__content__container">
              <ImageTab url_image={url_image} />
              <div className="intern__container">
                <div className="investor_name">{title}</div>
                  <div
                    className={`description ${
                      type == ICampaingTypes.Shares
                        ? "shares"
                        : type == ICampaingTypes.Debt
                        ? "debt"
                        : type == ICampaingTypes.ConvertibleNote
                        ? "noteConvertible"
                        : null
                    }`}
                  >
                    {content}
                    </div>
                </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ImageDescription;
