const { Serializer } = require("jsonapi-serializer");

module.exports = {
  SimulatorFileSerializer: (meta = {}) =>
    new Serializer("simulator_file", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "campaignId",
        "investmentAmount",
        "rate",
        "paymentTime",
        "capitalGracePeriod",
        "guaranteeFgn",
        "capitalPayment",
        "interestingPayment",
        "successComision",
        "administrationComision"
      ]
    })
};
