import React, { useRef, useEffect, useCallback, useState } from "react";
import Modal from "./Modal/Modal";
import { useTranslation } from "react-i18next";
import "./Carousel.scss";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import User from "../../lib/models/user.model";
import { startInvestFlow, canIInvest } from "../../redux/epics/invest";
import AnalyticsActions from "../../redux/reducers/analytics.reducer";
import AnalyticsModel from "../../lib/models/analytics.model";
import { CountDown } from "../shared/CountDown/index";
import ReactTooltip from "react-tooltip";

export type IProps = {
  info: any;
  type: any;
  state: any;
  speed?: any;
  interval?: any;
  secondsUntilPublish: any;
  showCountDown: any;
  showInvestButton: any;
  automaticMovement?: any;
};

enum ICampaingTypes {
  Debt = 1,
  Shares = 2,
  ConvertibleNote = 3
}

enum ICampaingState {
  Open = 1,
  Awarded = 2,
  Deserted = 3,
  Close = 6
}

const Carousel = ({
  info,
  type,
  state,
  speed = "500",
  interval = "50000",
  secondsUntilPublish,
  showCountDown,
  showInvestButton,
  automaticMovement = false
}: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [point, setPoint] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const slideshow: any = useRef(null);
  const intervalSlideshow: any = useRef(null);
  const appStore: any = useSelector((store) => store);

  const next = useCallback(() => {
    if (
      slideshow.current !== null &&
      slideshow.current !== null &&
      slideshow.current.children.length > 0
    ) {
      const firstElement = slideshow.current.children[0];
      slideshow.current.style.transition = `${speed}ms ease-out all`;
      const sizeSlide = slideshow.current.children[0].offsetWidth;
      slideshow.current.style.transform = `translateX(-${sizeSlide}px)`;

      const transition = () => {
        slideshow.current.style.transition = "none";
        slideshow.current.style.transform = "translateX(0)";
        slideshow.current.appendChild(firstElement);
        slideshow.current.removeEventListener("transitionend", transition);
      };
      slideshow.current.addEventListener("transitionend", transition);
      let indexElement = parseInt(firstElement.id.replace("slider_", ""));
      setPoint(
        indexElement == slideshow.current.children.length - 1
          ? 0
          : indexElement + 1
      );
    }
  }, [speed]);

  const previous = () => {
    if (slideshow.current.children.length > 0) {
      const index = slideshow.current.children.length - 1;
      const lastElement = slideshow.current.children[index];
      slideshow.current.insertBefore(lastElement, slideshow.current.firstChild);
      slideshow.current.style.transition = "none";
      const sizeSlide = slideshow.current.children[0].offsetWidth;
      slideshow.current.style.transform = `translateX(-${sizeSlide}px)`;

      setTimeout(() => {
        slideshow.current.style.transition = `${speed}ms ease-out all`;
        slideshow.current.style.transform = "translateX(0)";
      }, 30);
      let indexElement = parseInt(lastElement.id.replace("slider_", ""));
      setPoint(indexElement);
    }
  };

  const getPoints = (initClass: any) => {
    let margin1 = 0;
    let margin2 = 24;
    let margin3 = info.items.length > 2 ? 48 : 24;
    let sizeBox = info.items.length > 2 ? 60 : 36;
    return (
      <div className={`${initClass}__points`}>
        <div className="box" style={{ width: `${sizeBox}px` }}>
          {info.items.length > 1 ? (
            <div
              key={0}
              id={`point_${0}`}
              className={`${initClass}__points__point ${
                0 === point ? "active" : "inactive"
              }`}
              style={{ left: `${margin1}px` }}
            ></div>
          ) : (
            ""
          )}
          {info.items.length > 2 ? (
            <div
              key={1}
              id={`point_${1}`}
              className={`${initClass}__points__point ${
                0 !== point && info.items.length - 1 !== point
                  ? "active"
                  : "inactive"
              }`}
              style={{ left: `${margin2}px` }}
            ></div>
          ) : (
            ""
          )}
          {info.items.length > 1 ? (
            <div
              key={2}
              id={`point_${2}`}
              className={`${initClass}__points__point ${
                info.items.length - 1 === point ? "active" : "inactive"
              }`}
              style={{ left: `${margin3}px` }}
            ></div>
          ) : (
            ""
          )}
          {info.items.length > 2 &&
          0 !== point &&
          info.items.length - 1 !== point ? (
            <div
              key={0}
              id="point_large"
              className={`${initClass}__points__point_large ${
                point > info.items.length / 2 ? "large_right" : "large_left"
              }`}
            ></div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const getControls = (initClass: any) => {
    if (info.items.length > 1) {
      return (
        <div className={`${initClass}__controls`}>
          <div className={`${initClass}__controls__button left`}>
            <div
              className={`${initClass}__controls__button__icon left_icon`}
              onClick={previous}
              data-testid="previous"
            ></div>
          </div>
          <div className={`${initClass}__controls__button right`}>
            <div
              className={`${initClass}__controls__button__icon right_icon`}
              onClick={next}
              data-testid="next"
            ></div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const validateUriVideo = (uri: any, autoplay: any = false) => {
    const vimeoRegexp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
    const youtubeRegexp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const valideVimeoVideo = uri.match(vimeoRegexp);
    const valideYoutubeVideo = uri.match(youtubeRegexp);
    const autoplayText = autoplay ? "?autoplay=1&showinfo=1" : "";
    if (valideVimeoVideo) {
      return `https://player.vimeo.com/video/${valideVimeoVideo[2]}`;
    }
    if (valideYoutubeVideo) {
      return `https://www.youtube.com/embed/${valideYoutubeVideo[7]}${autoplayText}`;
    }
  };

  useEffect(() => {
    if(automaticMovement) {
      if (info.items.length > 1) {
        intervalSlideshow.current = setInterval(() => {
          next();
        }, interval);

        slideshow.current.addEventListener("mouseenter", () => {
          clearInterval(intervalSlideshow.current);
        });

        slideshow.current.addEventListener("mouseleave", () => {
          intervalSlideshow.current = setInterval(() => {
            next();
          }, interval);
        });
      }
    }
  }, [interval, next]);

  const idToken = sessionStorage.getItem("idToken");
  const refreshToken = sessionStorage.getItem("refreshToken");
  const activeSession = !!(idToken && refreshToken);

  let userData: any = false;
  let isPyme = false;
  if (activeSession) {
    userData = appStore.user.userInfo.user;
    const currentPersonType = userData ? userData.person_type || "" : "";
    isPyme = currentPersonType === User.type.pyme;
  }

  const userState = useSelector(() => {
    if (activeSession) {
      return { ...appStore.user };
    }
    return false;
  });

  let userPayload = {};
  if (userState) {
    const { user } = userState.userInfo;
    const Iuser: any = User;
    userPayload = {
      personType: user.person_type,
      documentNumber: user.document_number,
      documentType: Iuser.documentType[user.document_type],
      name: user.name,
      lastName: user.last_name,
      dv: user.dv,
      user_ip: user.ip,
      userId: user.id
    };
  }

  const onClickHandler = (campaignId: number) => {
    const action = !userState
      ? startInvestFlow(campaignId)
      : canIInvest(campaignId, userPayload);
    dispatch(action);
    dispatch(
      AnalyticsActions.trackEvent(AnalyticsModel.investFromCampaignDetail)
    );
  };

  const goTo = () => {
    if (!activeSession) {
      return dispatch(push("/login"));
    }
    return onClickHandler(info.campaignId);
  };

  return (
    <>
      <div className="center_carousel">
        <div className="carousel">
          <div className="carousel__slides_how" ref={slideshow}>
            {info.items.map((item: any, i: any) => (
              <div
                className="carousel__slides_how__slide"
                id={`slider_${i}`}
                key={i}
              >
                <a data-testid={`modal-carousel-${i}`} onClick={() => setShowModal(true)}>
                  <div className="carousel__slides_how__slide__img">
                    {item.type === "video" ? (
                      <iframe
                        className="size"
                        src={validateUriVideo(item.src)}
                        frameBorder="0"
                        data-testid="preview-video"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        title="campaign"
                        allowFullScreen={true}
                      />
                    ) : (
                      <img className="size" src={item.src} alt="" />
                    )}
                  </div>
                </a>
              </div>
            ))}
          </div>
          {getControls("carousel")}
          {getPoints("carousel")}
          <div className="carousel__texto">
            <div className="carousel__texto__img">
              <img src={info.logo} />
            </div>
            <div className="carousel__texto__info">
              <div className="carousel__texto__info__company_index">
                Empresa
              </div>
                <div data-tip={info.company} className="carousel__texto__info__company">
                  {info.company}
                </div>
                <ReactTooltip place="bottom" effect="solid" className="tooltip_text_carousel"/>
            </div>
            <div className="carousel__texto__description">
              <div className="carousel__texto__description__campaign_index">
                Campaña
              </div>
                <div data-tip={info.campaign} className="carousel__texto__description__campaign">
                  {info.campaign}
                </div>
                <ReactTooltip place="bottom" effect="solid" className="tooltip_text_carousel"/>
            </div>
            <div
              className={`carousel__texto__button ${
                !showInvestButton && !showCountDown
                  ? "without"
                  : showInvestButton
                  ? ""
                  : showCountDown
                  ? "countdown"
                  : ""
              }`}
            >
              {state != ICampaingState.Close &&
                state != ICampaingState.Awarded &&
                showInvestButton && (
                  <button
                    onClick={() => goTo()}
                    className={`${
                      type === ICampaingTypes.Shares
                        ? "action"
                        : type === ICampaingTypes.ConvertibleNote
                        ? "note"
                        : type === ICampaingTypes.Debt
                        ? "debt"
                        : ""
                    }`}
                  >
                    {t("showcase.invest")}
                  </button>
                )}
              {showCountDown && (
                <CountDown remainingSeconds={secondsUntilPublish} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        showModal={showModal}
        campaign={info.campaign}
        item={info.items[point]}
        setShowModal={setShowModal}
        getPoints={getPoints}
        getControls={getControls}
        validateUriVideo={validateUriVideo}
      />
    </>
  );
};

export default Carousel;
