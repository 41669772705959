import { flatMap, catchError } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import RequestCampaignAction from "../reducers/request-campaign.reducer";
import AlertActions from "../reducers/alert.reducer";
import App from "../../lib/app";

export const startFetchTermsRequestCampaign = () => ({
  type: "START_FETCH_TERMS_REQUEST_CAMPAIGN"
});

export const startFetchTermsAcceptRequestCampaign = () => ({
  type: "START_FETCH_ACCEPT_TERMS_REQUEST_CAMPAIGN"
});

export const fetchTermsRequestCampaign = (action$) =>
  action$.pipe(
    ofType("START_FETCH_TERMS_REQUEST_CAMPAIGN"),
    flatMap(() =>
      Observable.from(App.api.a2censo.requestCampaignLastTerms()).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(RequestCampaignAction.setTermsToAccept(response))
          )
        ),
        catchError(() =>
          Observable.concat(
            Observable.of(AlertActions.setAlertState("error", "alert.tryAgain"))
          )
        )
      )
    )
  );

export const fetchAcceptTermsRequestCampaign = (action$, $state) =>
  action$.pipe(
    ofType("START_FETCH_ACCEPT_TERMS_REQUEST_CAMPAIGN"),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            },
            terms: { id: termsID }
          }
        }
      } = $state;

      return Observable.from(
        App.api.a2censo.requestCampaignAcceptTerms({
          body: {
            request_campaign_id: requestCampaignId,
            accepted_terms_id: termsID
          }
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(RequestCampaignAction.setTermsAccepted())
          )
        ),
        catchError(() =>
          Observable.concat(
            Observable.of(AlertActions.setAlertState("error", "alert.tryAgain"))
          )
        )
      );
    })
  );

export default combineEpics(
  fetchTermsRequestCampaign,
  fetchAcceptTermsRequestCampaign
);
