import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setFinanceSelection: ["setFinanceSelection"],
  setInvestSelection: ["investSelection"],
  setA2censoSelection: ["a2censoSelection"],
  setMainTabSelection: ["mainTabSelection"],
  setAvoidScrollToTop: ["avoidScrollToTop"],
  setInvestTabUrl: ["investTabUrl"],
  setFinanceTabUrl: ["financeTabUrl"]
});

export const homeSelectionsTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  financeSelection: "howFinancial",
  investSelection: "showCase",
  a2censoSelection: "knowUs",
  mainTabSelection: "invest",
  avoidScrollToTop: false,
  investTabUrl: "/",
  financeTabUrl:
    "/?seccion=financiate/crowdfunding-como-financiarte-pymes-y-empresas"
};

const setFinanceSelection = (
  state,
  { setFinanceSelection: financeSelection }
) => ({
  ...state,
  financeSelection
});

const setMainTabSelection = (state, { mainTabSelection }) => ({
  ...state,
  mainTabSelection
});

const setInvestSelection = (state, { investSelection }) => ({
  ...state,
  investSelection
});

const setA2censoSelection = (state, { a2censoSelection }) => ({
  ...state,
  a2censoSelection
});

const setAvoidScrollToTop = (state, { avoidScrollToTop }) => ({
  ...state,
  avoidScrollToTop
});

const setInvestTabUrl = (state, { investTabUrl }) => ({
  ...state,
  investTabUrl
});

const setFinanceTabUrl = (state, { financeTabUrl }) => ({
  ...state,
  financeTabUrl
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_FINANCE_SELECTION]: setFinanceSelection,
  [Types.SET_INVEST_SELECTION]: setInvestSelection,
  [Types.SET_A2CENSO_SELECTION]: setA2censoSelection,
  [Types.SET_AVOID_SCROLL_TO_TOP]: setAvoidScrollToTop,
  [Types.SET_MAIN_TAB_SELECTION]: setMainTabSelection,
  [Types.SET_INVEST_TAB_URL]: setInvestTabUrl,
  [Types.SET_FINANCE_TAB_URL]: setFinanceTabUrl
});
