import React from "react";
import PropTypes from "prop-types";
import {
  ButtonContainer,
  Button,
  InputFileButton,
  ButtonLabel
} from "./Styles";

const Buttons = ({
  className,
  label,
  onClick,
  type,
  value,
  buttonStyle,
  disabled,
  largeButton,
  testId
}) => {
  const renderButton = () => (
    <ButtonContainer>
      <Button
        onClick={onClick}
        className={className}
        buttonStyle={buttonStyle}
        type={type}
        disabled={disabled}
        largeButton={largeButton}
        data-testid={`${testId}_button`}
      >
        {label !== "" ? label : value}
      </Button>
    </ButtonContainer>
  );

  const renderInputFile = () => (
    <ButtonContainer>
      <ButtonLabel for={`${testId}_button`} buttonStyle={buttonStyle}>
        {label}
      </ButtonLabel>
      <InputFileButton
        id={`${testId}_button`}
        type={type}
        className={className}
        disabled={disabled}
        largeButton={largeButton}
        accept="application/pdf,image/jpg"
      />
    </ButtonContainer>
  );

  return type === "file" ? renderInputFile() : renderButton();
};

Buttons.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(["file", "button", "submit"]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  largeButton: PropTypes.bool,
  testId: PropTypes.string
};

Buttons.defaultProps = {
  className: "",
  disabled: false,
  label: "",
  largeButton: false,
  onClick: () => {},
  testId: "",
  type: "button",
  value: ""
};

export default Buttons;
