import React from "react";
import { isMobile, isBrowser } from "react-device-detect";
import { useTranslation } from "react-i18next";
import SliderSlick from "react-slick";
import * as R from "ramda";

import { Heading, Paragraph, Slider } from "@bvcco/a2censo-component-lib";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import LogoA2censoOnly from "../../assets/images/a2censo-logo-only.png";
import LogoBvc from "../../assets/images/bvc-logo.png";

import {
  Container,
  CompaniesContainer,
  SliderContainer,
  InfoContainer,
  AllianceContainer,
  FirstColumn,
  SecondColumn,
  ThirdColumn,
  IconTextContainer,
  SocialContainer,
  TextConntainer,
  ContentColumns,
  ContentLogoSuper,
  ContentSliderItems,
  Anchor,
  Img,
  ContentBranding,
  ContentGrabLogo,
  ContentDots,
  SliderWrapper,
  DotBVC,
  A2censoBVC,
  LogoFooter,
  LogoFooterAllies,
  LogoFooterA2censo,
  LogoFooterBVC
} from "./Styles";
import MoldalActions from "../../redux/reducers/modal.reducer";
import environment from "environment";

const Footer = ({ homeData }) => {
  const assets = `${environment.aws.url}/`;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openModal = () => {
    dispatch(MoldalActions.setConditionalModalState(true, "ContactForm"));
  };

  const createSlideItem = (data) => {
    const currentSlides = [];
    let temporalSlides = [];

    data.forEach((item, index) => {
      const whenDesktop = (index + 1) % 7 === 0 && isBrowser; // number of slides for desktop
      const whenMobile = (index + 1) % 3 === 0 && isMobile; // number of slides for mobile

      temporalSlides.push(
        <Anchor target="_blank" rel="noopener noreferrer" href={item.link}>
          <LogoFooter src={assets + item.logo} alt={item.alt} />
        </Anchor>
      );
      if (whenDesktop) {
        currentSlides.push(
          <ContentSliderItems className="content-slide">
            {temporalSlides}
          </ContentSliderItems>
        );
        temporalSlides = [];
      }
      if (whenMobile) {
        currentSlides.push(
          <ContentSliderItems className="content-slider-items ">
            {temporalSlides}
          </ContentSliderItems>
        );
        temporalSlides = [];
      }

      if (index === 24) {
        currentSlides.push(
          <ContentSliderItems className="ccppr">
            {temporalSlides}
          </ContentSliderItems>
        );
        temporalSlides = [];
      }
    });
    return currentSlides;
  };
  const contentFooter = homeData.footer;
  const createAllies = (data) => {
    const logos = [];
    data.forEach((item) => {
      if (item.is_footer === true) {
        logos.push(
          <Anchor target="_blank" rel="noopener noreferrer" href={item.link}>
            <LogoFooterAllies src={assets + item.logo} alt="bvc" />
          </Anchor>
        );
      }
    });
    return logos;
  };

  const byMain = R.groupBy((allie) => {
    const isSupportFor = allie.is_support_for;
    return isSupportFor === true;
  });

  const secondAllies = byMain(contentFooter.allies).true || [];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    width: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrows: false
  };

  return (
    <Container>
      <CompaniesContainer>
        <A2censoBVC>
          <Anchor rel="noopener noreferrer">
            <LogoFooterA2censo src={LogoA2censoOnly} alt="a2censo-logo" />
          </Anchor>
          <DotBVC />
          <Anchor
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.bvc.com.co/pps/tibco/portalbvc/Home/Mercados/enlinea/acciones?action=dummy"
          >
            <LogoFooterBVC src={LogoBvc} alt="bvc-logo" />
          </Anchor>
        </A2censoBVC>
        <AllianceContainer>
          {createAllies(contentFooter.allies)}
        </AllianceContainer>
      </CompaniesContainer>
      <SliderWrapper>
        <SliderContainer id="content-footer-slider">
          {isBrowser && (
            <Slider
              parentId="content-footer-slider"
              timer={3000}
              showDots={false}
              showArrows={isBrowser && secondAllies.length > 7}
            >
              {createSlideItem(secondAllies)}
            </Slider>
          )}
          {isMobile && (
            <SliderSlick {...settings}>
              {createSlideItem(secondAllies)}
            </SliderSlick>
          )}
        </SliderContainer>
      </SliderWrapper>
      <ContentDots />
      <ContentColumns>
        <ContentLogoSuper>
          <Img src="/assets/images/footer/vigilado.png" alt="bvc" />
        </ContentLogoSuper>
        <InfoContainer>
          <FirstColumn>
            <Heading color="white" fontWeight="bold" hieranchy="secondary">
              {t("footer.browse")}
            </Heading>
            <TextConntainer>
              <Anchor
                href={contentFooter.frequest_questions}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Paragraph size="medium" fontWeight="normal" color="white">
                  {t("footer.faq")}
                </Paragraph>
              </Anchor>

              <Anchor data-testid="open-modal" onClick={openModal}>
                <Paragraph size="medium" fontWeight="normal" color="white">
                  {t("footer.contact")}
                </Paragraph>
              </Anchor>
              <Anchor
                href={contentFooter.resource}
                download={contentFooter.resource}
              >
                <Paragraph size="medium" fontWeight="normal" color="white">
                  {t("footer.resources")}
                </Paragraph>
              </Anchor>

              <Anchor
                href={contentFooter.legal}
                rel="noopener noreferrer"
                download={contentFooter.legal}
              >
                <Paragraph size="medium" fontWeight="normal" color="white">
                  {t("footer.legal")}
                </Paragraph>
              </Anchor>
            </TextConntainer>
          </FirstColumn>
          <SecondColumn>
            <Heading color="white" fontWeight="bold" hieranchy="secondary">
              {t("footer.findUs")}
            </Heading>
            <IconTextContainer>
              <Img
                width="24"
                src="/assets/images/footer/iconos/place-icon@2x.png"
                alt="bvc"
              />
              <Paragraph size="medium" fontWeight="normal" color="white">
                {t("footer.address")}
              </Paragraph>
            </IconTextContainer>
            <IconTextContainer>
              <Img
                width="24"
                src="/assets/images/footer/iconos/phone-icon@2x.png"
                alt="bvc"
              />
              <Paragraph size="medium" fontWeight="normal" color="white">
                {t("footer.phone")}
              </Paragraph>
            </IconTextContainer>
            <IconTextContainer>
              <Img
                width="24"
                src="/assets/images/footer/iconos/mail-icon@2x.png"
                alt="bvc"
              />
              <Paragraph size="medium" fontWeight="normal" color="white">
                {t("footer.email")}
              </Paragraph>
            </IconTextContainer>
          </SecondColumn>
          <ThirdColumn>
            <Heading color="white" fontWeight="bold" hieranchy="secondary">
              {t("footer.followUs")}
            </Heading>
            <SocialContainer>
              <Anchor
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/a2censo/"
                target="_blank"
              >
                <Img
                  width="35"
                  src="/assets/images/footer/redes/linkedin@2x.png"
                  alt="bvc"
                />
              </Anchor>

              <Anchor
                rel="noopener noreferrer"
                href="https://www.facebook.com/a2censo/"
                target="_blank"
              >
                <Img
                  width="15"
                  src="/assets/images/footer/redes/facebook@2x.png"
                  alt="bvc"
                />
              </Anchor>
              <Anchor
                rel="noopener noreferrer"
                href="https://twitter.com/a2censo/"
                target="_blank"
              >
                <Img
                  width="37"
                  src="/assets/images/footer/redes/twitter@2x.png"
                  alt="bvc"
                />
              </Anchor>
              <Anchor
                rel="noopener noreferrer"
                href="https://www.instagram.com/a2censo/"
                target="_blank"
              >
                <Img
                  width="37"
                  src="/assets/images/footer/redes/logotipo-de-instagram.png"
                  alt="bvc"
                />
              </Anchor>
            </SocialContainer>
          </ThirdColumn>
        </InfoContainer>
      </ContentColumns>
      <ContentBranding>
        <Anchor
          rel="noopener noreferrer"
          href={contentFooter.terms}
          target="_blank"
        >
          <Paragraph size="medium" fontWeight="normal" color="white">
            {t("footer.termAndConditions")}
          </Paragraph>
        </Anchor>
        <Anchor
          rel="noopener noreferrer"
          href={contentFooter.data_policy}
          target="_blank"
        >
          <Paragraph size="medium" fontWeight="normal" color="white">
            {t("footer.dataProcessingPolicy")}
          </Paragraph>
        </Anchor>
      </ContentBranding>
    </Container>
  );
};

Footer.propTypes = {
  homeData: PropTypes.shape({
    footer: PropTypes.shape({
      frequest_questions: PropTypes.string.isRequired,
      resource: PropTypes.string.isRequired,
      legal: PropTypes.string.isRequired,
      terms: PropTypes.string.isRequired,
      data_policy: PropTypes.string.isRequired,
      allies: PropTypes.arrayOf(
        PropTypes.shape({
          link: PropTypes.string,
          name: PropTypes.string,
          logo: PropTypes.string
        })
      ).isRequired
    })
  }).isRequired
};

export default Footer;
