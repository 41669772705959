import React from "react";
import { ListWrapper } from "./components";

type Props = {
  items: JSX.Element[];
};

export const CardList = ({ items }: Props) => (
  <ListWrapper data-testId="card-list">
    {items.map((item, index) => (
      <div key={`card-list-item-${index}`}>{item}</div>
    ))}
  </ListWrapper>
);
