import styled from "styled-components";
import ReactTooltip from "react-tooltip";

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 365px;
`;

export const QuestionText = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Roboto";
`;

export const QuestionLabel = styled.h4`
  font-family: "Roboto";
  font-size: 20px;
  text-transform: Capitalize;
  font-weight: bold;
`;

export const InvestorQuality = styled.div`
  font-size: 18px;
  font-weight: normal;
  margin-top: 10px;
`;

export const Form = styled.form`
  width: 100%;
  & .bLJnia {
    align-items: flex-start;
  }
`;

export const ConfirmButton = styled.button`
  width: 215px;
  height: 49px;
  background-color: ${(props) => props.theme.white};
  border-radius: 24px;
  border: none;
  color: ${(props) => props.theme.brightTurquoise};
  font-family: "Nunito";
  font-size: 17px;
  font-weight: 800;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  ${(props) => props.theme.smartphone} {
    width: 170px;
    height: 40px;
    border-radius: 18px;
    font-size: 13px;
  }
`;

export const QuestionIcon = styled.img`
  right: 30px;
  top: 20px;
  width: 25px;
  height: 25px;
  margin-left: 32px;
`;

export const Tooltip = styled(ReactTooltip)`
  box-shadow: 0 6px 12px 0 rgba(44, 50, 65, 0.1);
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 372px;
`;

export const TooltipText = styled.p`
  font-family: "Roboto";
  font-size: 11px !important;
  margin: 2px !important;
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 6px;
  align-items: flex-end;
  background: transparent;
  opacity: 1;
`;
