import { createReducer, createActions } from "reduxsauce";

export const { Types, Creators } = createActions({
  setAmountError: ["errors"]
});

export const amountErrorTypes = Types;
export default Creators;

const INITIAL_STATE = {
  errors: undefined
};

const setAmountError = (state, { errors }) => ({ ...state, errors });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_AMOUNT_ERROR]: setAmountError
});
