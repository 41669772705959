import styled from "styled-components";

export const Left = styled.div`
  width: 389px;
  padding-top: 163px;
  padding-left: 68px;
  padding-right: 68px;
  border-radius: 12px;

  ${({ theme }) => theme.breakpoints.whenSmartphone} {
    width: 100%;
    padding-bottom: 37px;
    padding-top: 61px;
    padding-left: 19px;
    padding-right: 19px;
    text-align: center;
  }
`;
