import styled from "styled-components";
import { CertificateCardFromType } from "components/shared";

export const ContainerPagination = styled.div``;

type IPropsContainer = {
  typeCard: CertificateCardFromType;
};
export const ContainerCertificates = styled.div<IPropsContainer>`
  margin-top: 20px;
  display: grid;
  grid-template-columns: ${({ typeCard }) =>
    typeCard === CertificateCardFromType.Card ? "1fr 1fr 1fr 1fr" : "1fr"};
  row-gap: 20px;
  column-gap: 10px;

  > div {
    width: 100%;
    min-width: 293.25px;
  }
  ${({ theme }) => theme.breakpoints.desktopSmall} {
    grid-template-columns: ${({ typeCard }) =>
      typeCard === CertificateCardFromType.Card ? "1fr 1fr 1fr" : "1fr"};
  }
  ${({ theme }) => theme.breakpoints.intervalMobileMedium} {
    grid-template-columns: ${({ typeCard }) =>
      typeCard === CertificateCardFromType.Card ? "1fr 1fr" : "1fr"};
  }
  ${({ theme }) => theme.breakpoints.whenSmartphone} {
    grid-template-columns: 1fr;
  }
`;

export const ContainerPages = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export type PropsOption = {
  selected?: boolean;
  theme: any;
};

const getBackgroundColorOption = ({ selected, theme }: PropsOption) =>
  selected ? theme.cornflowerBlue : theme.athensGray2;

const getColorOption = ({ selected, theme }: PropsOption) =>
  selected ? theme.white : theme.acentuar;

export const Option = styled.button<PropsOption>`
  width: 40px;
  height: 40px;
  margin-right: 17px;
  border: 0px;
  border-radius: 4px;
  outline: none;

  cursor: pointer;
  padding: 0;
  background-color: ${getBackgroundColorOption};
  img {
    width: 40px;
    height: 40px;
  }
  svg {
    color: #5d5a6a;
  }
  span {
    color: ${getColorOption};
    font-size: 14px;
    font-family: Nunito, sans-serif;
    font-weight: 600;
  }
`;

export const ContainerLoadMore = styled.div`
  margin-top: 40px;
  margin-bottom: 54px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > p {
    font-family: Nunito, sans-serif;
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  > button {
    font-weight: bold;
    width: 150px;
    border-radius: 100px;
  }
`;
