import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@bvcco/a2censo-component-lib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import ContentFooter from "../ContentFooter/ContentFooter";
import { SectionTabs } from "../../components/shared/SectionTabs";
import homeSelectionsActions from "../../redux/reducers/home-selections.reducer";
import {
  StyledContainer,
  StyledLinearTabsContainer,
  BackButtonContainer,
  SectionTabsContainer
} from "./Styles";

import KnowUs from "../KnowUs/KnowUs.js";
import OurAllies from "../OurAllies/OurAllies.js";

export const A2censoInformation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tabs = [
    {
      id: "knowUs",
      title: t("a2censo.knowUs.title"),
      url: "/nosotros/?seccion=conocenos",
      disableTooltip: true
    },
    {
      id: "ourAllies",
      title: t("a2censo.ourAllies.title"),
      url: "/nosotros/?seccion=aliados",
      disableTooltip: true
    }
  ];

  const onChangeA2censoTab = useCallback((tabId) => {
    dispatch(homeSelectionsActions.setA2censoSelection(tabId));
  }, []);

  const { a2censoSelection } = useSelector(
    ({ homeSelections }) => homeSelections
  );

  return (
    <StyledContainer>
      <StyledLinearTabsContainer>
        <BackButtonContainer>
          <Button
            dataTestid="back-buttom-hero"
            color="cornFlowerBlueAndWaterloo"
            icon={{
              icon: <FontAwesomeIcon icon={faChevronLeft} />,
              position: "left"
            }}
            onClick={() => dispatch(push("/"))}
            fontWeight="bold"
            rounded={true}
          >
            {t("campaign.return")}
          </Button>
        </BackButtonContainer>
        <SectionTabsContainer>
          <SectionTabs
            tabs={tabs}
            onChangeTab={onChangeA2censoTab}
            activeTab={a2censoSelection}
          />
        </SectionTabsContainer>
      </StyledLinearTabsContainer>
      {a2censoSelection === "knowUs" ? <KnowUs /> : <OurAllies />}
      <ContentFooter />
    </StyledContainer>
  );
};
