import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { SmsOtpValidation } from "../../components/SmsOtpValidation/SmsOtpValidation";
import {
  Container,
  Title,
  Error,
  Subtitle,
  InfoText,
  NewCodeLink,
  SentNewCodeMessage
} from "./Styles";

import {
  startForgotPasswordOtpValidation,
  selectForgotPasswordOtpType
} from "../../redux/epics/forgot-password";

import CodeInput from "../../components/CodeInput/CodeInput";

const ForgotPasswordOtp = ({ location }) => {
  const { token, otpType, countryCode, phoneNumber, dateGenerationToken, timerLimitMinutes } = location.state;
  const [messageVisibility, setMessageVisibility] = useState(false);
  const { t } = useTranslation();
  const ForgotPasswordError = useSelector(
    (state) => state.forgotPassword.error
  );
  const dispatch = useDispatch();
  const inputCodeRef = useRef();

  useEffect(() => {
    if(otpType == "sms" && !ForgotPasswordError && !dateGenerationToken){
      dispatch(push(`/forgot-password/otp-request/${token}`));
    }
  }, []);

  const codeOnChange = (value) => {
    if (value.length === 6) {
      if (otpType == "email") {
        setMessageVisibility(false);
      }
      dispatch(
        startForgotPasswordOtpValidation({ token, otpCode: value, otpType })
      );
    }
  };

  const handleRequestNewCode = () => {
    inputCodeRef.current.cleanValues();
    setMessageVisibility(true);
    setTimeout(() => setMessageVisibility(false), 10000);
    return dispatch(selectForgotPasswordOtpType({ token, otpType }));
  };

  const handleShowError = () => {
    inputCodeRef.current?.cleanValues();
    const { errors } = ForgotPasswordError;
    const hasExceeded = errors[0].code === "1023";
    if (otpType === "email") {
      return (
        <Error>
          {t(
            hasExceeded
              ? "codeValidation.exceededLimitAttempts"
              : "codeValidation.noValidCode"
          )}
        </Error>
      );
    }
  };

  if (otpType === "sms") {
    return (
      <SmsOtpValidation
        onClick={codeOnChange}
        RequestNewCode={selectForgotPasswordOtpType({ token, otpType })}
        countryCode= {countryCode}
        phoneNumber= {phoneNumber}
        dateGenerationToken={dateGenerationToken}
        timerLimitMinutes={Number(timerLimitMinutes)}
        error={ForgotPasswordError}
      />
    );
  } else {
    return (
      <Container className="container">
        <Title>{t("forgotPasswordOtp.title")}</Title>
        <Subtitle>{t("forgotPasswordOtp.subtitle")}</Subtitle>
        <CodeInput
          parentRef={inputCodeRef}
          onChange={codeOnChange}
          id="code-input"
        />
        {messageVisibility && (
          <SentNewCodeMessage>
            {t("codeValidation.newCodeMessage")}
          </SentNewCodeMessage>
        )}
        <Error>
          <br />
          {ForgotPasswordError && !messageVisibility && handleShowError()}
        </Error>
        <InfoText className="col-md-6">
          {t("forgotPasswordOtp.noReceived")}
          <NewCodeLink
            data-testid="request-new-code-button"
            onClick={handleRequestNewCode}
          >
            {t("forgotPasswordOtp.askAgain")}
          </NewCodeLink>
        </InfoText>
      </Container>
    );
  }
};

ForgotPasswordOtp.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      token: PropTypes.string,
      otpType: PropTypes.string
    })
  }).isRequired
};

export default ForgotPasswordOtp;
