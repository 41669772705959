import styled from "styled-components";

const getBackgroundByType = (
  theme: { azalea: any; springGreen2: any; mercury: any },
  type: string
) => {
  switch (type) {
    case "error":
      return theme.azalea;
    case "success":
      return "#ADFDD2";

    default:
      return theme.mercury;
  }
};

type ContainerProps = {
  theme: any;
  type: "info" | "error" | "success";
  isCenter: boolean;
  hasIcon: boolean;
  hasClose: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: 100%;
  min-height: 54px;
  background-color: ${({ theme, type }: ContainerProps) =>
    getBackgroundByType(theme, type)};
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-family: Nunito;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  padding: 10px 0;
  position: relative;
  ${({ isCenter }) =>
    isCenter &&
    `justify-content: center;
  text-align: center;`};

  ${({ hasIcon }) => !hasIcon && "padding-left: 10px;"};
  ${({ hasClose }) => !hasClose && "padding-right: 15px;"};

  color: ${({ theme }) => theme.GunPowder};
`;
