import styled from "styled-components";

export const VerticalDivider = styled.div`
  background-color: ${({ theme }) => theme.white};
  height: auto;
  width: 2px;
  ${({ theme }) => theme.smartphone} {
    display: none;
  }
`;
