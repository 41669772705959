import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { push } from "connected-react-router";
import {
  Container,
  Paragraph,
  Button,
  CloseButton,
  Icon,
  ModalTitle
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";

const SuspendedAccountModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(ModalActions.setConditionalModalState(false));
  };

  const handleContinue = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(push("/account-recovery"));
  };

  return (
    <Container>
      <CloseButton id="close-button" onClick={handleClose}>
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <ModalTitle>{t("accountRecovery.suspendedModalTitle")}</ModalTitle>
      <Paragraph>{`${t("accountRecovery.suspendedModalText")}`}</Paragraph>
      <Button id="continue-button" onClick={handleContinue}>
        {t("accountRecovery.continueButton")}
      </Button>
    </Container>
  );
};

export default SuspendedAccountModal;
