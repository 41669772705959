import React from "react";
import Accordion from "../Accordion/Accordion";
import "./CampaignDetailSections.scss";
import SimpleText from "components/CampaignDetail/SimpleText/SimpleText.component";
import Bullet from "components/CampaignDetail/Bullet/Bullet.component";
import SimpleTextBullet from "components/CampaignDetail/SimpleTextBullet/SimpleTextBullet.component";
import SocialNetwork from "components/CampaignDetail/SocialNetwork/SocialNetwork.component";
import BulletDetailTitle from "components/CampaignDetail/BulletDetailTitle/BulletDetailTitle.component";
import TextImage from "components/CampaignDetail/TextImage/TextImage.component";
import BulletPresentation from "components/CampaignDetail/BulletPresentation/BulletPresentation.component";
import PresentationTab from "components/CampaignDetail/PresentationTab/PresentationTab.component";
import Graphics from "components/CampaignDetail/Graphics/Graphics.component";
import ImageDescription from "components/CampaignDetail/ImageDescription/ImageDescription.component";

enum ICampaignDetailTypes {
  SimpleText = 1,
  Bullet = 2,
  SimpleTextBullet = 3,
  SocialNetwork = 4,
  PresentationTab = 5,
  Graphics = 6,
  Alliance = 7,
  TextImage = 8,
  BulletDetailTitle = 9,
  BulletPresentation = 10,
  ImageDescription = 11,
  MultimediaDetailCampaign = 12,
  Documents = 13
}

enum ICampaingTypes {
  Debt = 1,
  Shares = 2,
  ConvertibleNote = 3
}

enum ICampaignSection {
  Carrusel = 1,
  Alianza = 2,
  Documentos = 3,
  ConoceNuestraCampaña = 4,
  ConoceA = 5,
  PorqueHacerteSocio = 6,
  PorqueInvertir = 7
}

export type IProps = {
  sections: Array<{
    id: number;
    name: string;
    campaignSubsectionsDetails: Array<{
      id: number;
      name: string;
      detail_type: number;
      campaignDetail: Array<{
        id: number;
        title?: string;
        content?: string;
        second_content?: string;
        url?: string;
        url_image?: string;
      }>;
    }>;
  }>;
  type: Number;
  campaignCompanyName: string;
  requestCampaignId: number;
};

const CampaignDetailSections = ({
  sections,
  type,
  campaignCompanyName,
  requestCampaignId
}: IProps) => (
  <div className="accordion-sections">
    {sections.map((section) => {
      let subsectionItems;
      let detailComponent;
      return (
        section.campaignSubsectionsDetails.length > 0 && (
          <>
            <div className="accordion-sections__title title_detail">
              {section.name}
              {section.id === ICampaignSection.ConoceA ? (
                <b style={{ fontWeight: 900 }}>{` ${campaignCompanyName}`}</b>
              ) : (
                ""
              )}
            </div>
            <div
              className={`accordion-sections__element ${
                type == ICampaingTypes.Shares
                  ? "action"
                  : type == ICampaingTypes.ConvertibleNote
                  ? "noteConvertible"
                  : type == ICampaingTypes.Debt
                  ? "Debt"
                  : null
              }`}
            >
              {section.campaignSubsectionsDetails.map((subsection, index) => {
                const detailContent = subsection.campaignDetail;
                detailComponent = (
                  <div> El componente no ha sido asignado </div>
                );
                switch (subsection.detail_type) {
                  case ICampaignDetailTypes.SimpleText:
                    detailComponent = detailContent && (
                      <SimpleText campaignDetail={detailContent[0]} />
                    );
                    break;
                  case ICampaignDetailTypes.Bullet:
                    detailComponent = detailContent && (
                      <Bullet campaignDetail={detailContent} />
                    );
                    break;
                  case ICampaignDetailTypes.SimpleTextBullet:
                    detailComponent = detailContent && (
                      <SimpleTextBullet campaignDetail={detailContent} />
                    );
                    break;
                  case ICampaignDetailTypes.SocialNetwork:
                    detailComponent = detailContent && (
                      <SocialNetwork campaignDetail={detailContent} />
                    );
                    break;
                  case ICampaignDetailTypes.PresentationTab:
                    detailComponent = detailContent && (
                      <PresentationTab
                        campaignDetail={detailContent}
                        type={type}
                      />
                    );
                    break;
                  case ICampaignDetailTypes.BulletDetailTitle:
                    detailComponent = detailContent && (
                      <BulletDetailTitle campaignDetail={detailContent} />
                    );
                    break;
                  case ICampaignDetailTypes.TextImage:
                    detailComponent = detailContent && (
                      <TextImage campaignDetail={detailContent} />
                    );
                    break;
                  case ICampaignDetailTypes.BulletPresentation:
                    detailComponent = detailContent && (
                      <BulletPresentation campaignDetail={detailContent} />
                    );
                    break;
                  case ICampaignDetailTypes.Graphics:
                    detailComponent = detailContent && (
                      <Graphics campaignDetail={detailContent} hasBucked={!!requestCampaignId} />
                    );
                    break;
                  case ICampaignDetailTypes.ImageDescription:
                    detailComponent = detailContent && (
                      <ImageDescription
                        campaignDetail={detailContent}
                        type={type}
                      />
                    );
                    break;
                  default:
                    detailComponent = (
                      <div> El componente no ha sido creado </div>
                    );
                    break;
                }
                subsectionItems = [
                  {
                    id: subsection.id,
                    title: subsection.name,
                    content: detailComponent,
                    firstElement: index == 0 ? true : false
                  }
                ];
                return (
                  detailComponent !== undefined ? <Accordion key={subsection.id} items={subsectionItems} /> : <></>
                );
              })}
            </div>
            <div className="accordion-sections__line" />
          </>
        )
      );
    })}
  </div>
);

export default CampaignDetailSections;
