import styled from "styled-components";

type Props = {
  containerStyle: string;
};

export const Container = styled.div<Props>`
  display: table-row;
  & p {
    color: ${({ containerStyle }) =>
      containerStyle === "dark" ? "white" : "black"};
  }
`;
