import React from "react";
import { Container, Icon, Close } from "./components";
import InfoIcon from "../../../assets/images/info-icon.png";
import SuccessIcon from "../../../assets/images/success.png";

type AlterProps = {
  children: any;
  type: "info" | "error" | "success";
  hasClose?: boolean;
  hasIcon?: boolean;
  isCenter?: boolean;
  handleClose?: any;
};

export const Alert = ({
  children,
  type = "info",
  hasClose,
  hasIcon = true,
  isCenter,
  handleClose = () => {}
}: AlterProps) => {
  const IconSrc = type === "success" ? SuccessIcon : InfoIcon;

  return (
    <Container
      hasIcon={hasIcon || false}
      type={type}
      hasClose={hasClose || false}
      isCenter={isCenter || false}
    >
      {hasIcon && <Icon src={IconSrc} />}
      <span>{children}</span>
      {hasClose && (
        <Close id="alert-close" data-testid="alert-close" onClick={handleClose}>
          x
        </Close>
      )}
    </Container>
  );
};
