import { flatMap, catchError } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import CampaignStepperActions from "../reducers/create-campaign-stepper.reducer";
import CodebtorActions from "../reducers/codebtor.reducer";
import ModalActions from "../reducers/modal.reducer";
import {
  ExperianPriorityCodes,
  ExperianUnAvailabilityCodes
} from "../../lib/utils/constants.errors";
import AlertActions from "../reducers/alert.reducer";

export const startValidationStepFour = () => ({
  type: "START_VALIDATION_STEP_FOUR"
});

const errorCodebtorUserBlocked = ["CODEBTORS_001"];

const errorCodebtorExternal = [
  "CODEBTORS_005",
  "CODEBTORS_007",
  "CODEBTORS_008"
];

const errorName = ["CODEBTORS_006", "CODEBTORS_002"];
const errorAssociate = ["CODEBTORS_003", "CODEBTORS_004"];

export const validationStepFour = (action$, $state) =>
  action$.pipe(
    ofType("START_VALIDATION_STEP_FOUR"),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;
      return Observable.from(
        App.api.a2censo.codebtorValidate({
          body: { request_campaign_id: requestCampaignId.toString() }
        })
      ).pipe(
        flatMap((response) => {
          const {
            validation_response: { code, observer },
            codes
          } = response;

          if (errorCodebtorUserBlocked.includes(code)) {
            return Observable.concat(
              Observable.of(CampaignStepperActions.changeStateStageLogout())
            );
          }

          if (codes) {
            const validatePriority = ExperianPriorityCodes.find((item) =>
              codes.includes(item)
            );
            let linkRedirectsTo = "/";
            const unAvailability = ExperianUnAvailabilityCodes.some((item) =>
              codes.includes(item)
            );

            if (unAvailability) {
              linkRedirectsTo = "";
            }

            if (unAvailability || validatePriority) {
              App.redux.store.getState().campaignStepper.isSubmitting = false;
              return Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${codes[0]}`,
                  linkRedirectsTo,
                  buttonText: "errorModal.success"
                })
              );
            }
          }
          if (code) {
            if (errorCodebtorExternal.includes(code)) {
              App.redux.store.getState().campaignStepper.isSubmitting = false;
              return Observable.concat(
                Observable.of(CodebtorActions.getListCodebtor()),
                Observable.of(CodebtorActions.setFailNames(observer))
              );
            }
            if (errorName.includes(code)) {
              App.redux.store.getState().campaignStepper.isSubmitting = false;
              return Observable.from(
                App.api.a2censo.stateStageRequestCampaign({
                  url: { requestCampaignId }
                })
              ).pipe(
                flatMap((steps) =>
                  Observable.of(
                    CampaignStepperActions.fetchStageListSuccess(steps)
                  )
                ),
                catchError(() =>
                  Observable.of(CampaignStepperActions.requestError())
                )
              );
            }

            if (errorAssociate.includes(code)) {
              App.redux.store.getState().campaignStepper.isSubmitting = false;
              return Observable.concat(
                Observable.of(
                  ModalActions.setConditionalModalState(
                    true,
                    "ErrorModalStepper",
                    {
                      title: "errorModal.title",
                      content: `codebtorErrorCodes.${code}`,
                      linkRedirectsTo: "/",
                      buttonText: "errorModal.continue",
                      css: { backgroundColor: "rgba(56, 60, 93, 0.90)" }
                    }
                  )
                ),
                Observable.of(CodebtorActions.setFailNames(observer))
              );
            }
          }
          return Observable.concat(
            Observable.of(CampaignStepperActions.changeStateStage())
          );
        }),
        catchError(() => {
          App.redux.store.getState().campaignStepper.isSubmitting = false;
          return Observable.concat(
            Observable.of(AlertActions.setAlertState("error", "alert.tryAgain"))
          );
        })
      );
    })
  );

export default combineEpics(validationStepFour);
