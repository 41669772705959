import styled from "styled-components";

export const Container = styled.div`
  background: ${({ theme }) => theme.cyan};
  position: absolute;
  left: -10px;
  top: 30px;
  display: flex;
  padding: 12px 20px;
  border-radius: 0 8px 8px 0;
  z-index: 1;
  width: 190px;
  &::after {
    content: "";
    border-bottom: 5px solid transparent;
    border-top: 5px solid ${({ theme }) => theme.robinsEggBlue};
    border-left: 5px solid transparent;
    border-right: 5px solid ${({ theme }) => theme.robinsEggBlue};
    position: absolute;
    left: 0;
    bottom: -10px;
  }
`;
