import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  callOpenSimulatorCampaignsModal: ["payload"],
  callGetSimulatorCampaigns: ["payload"],
  setSimulatorCampaigns: ["payload"],
  resetSimulatorCampaigns: ["payload"],
  callGetFileSimulator: ["payload"],
  callGetFileSimulatorEnd: [""],
  callGetFileSimulatorPYME: ["payload"],
  callGetFileSimulatorPYMEEnd: [""]
});

export const newsTypes = Types;
export default Creators;

const INITIAL_STATE = {
  campaigns: [],
  loadingFile: false
};

const resetSimulatorCampaigns = (state) => ({ ...state, campaigns: [] });

const callGetSimulatorCampaigns = (state) => ({
  ...state,
  loading: true
});

const setSimulatorCampaigns = (state, { payload }) => {
  const currentCampaigns = state.campaigns || [];
  return {
    ...state,
    loading: false,
    campaigns: [...currentCampaigns, ...payload]
  };
};

const callGetFileSimulator = (state) => ({
  ...state,
  loadingFile: true
});

const callGetFileSimulatorEnd = (state) => ({
  ...state,
  loadingFile: false
});

const callGetFileSimulatorPYME = (state) => ({
  ...state,
  loadingFile: true
});

const callGetFileSimulatorPYMEEnd = (state) => ({
  ...state,
  loadingFile: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SIMULATOR_CAMPAIGNS]: setSimulatorCampaigns,
  [Types.RESET_SIMULATOR_CAMPAIGNS]: resetSimulatorCampaigns,
  [Types.CALL_GET_SIMULATOR_CAMPAIGNS]: callGetSimulatorCampaigns,
  [Types.CALL_GET_FILE_SIMULATOR]: callGetFileSimulator,
  [Types.CALL_GET_FILE_SIMULATOR_END]: callGetFileSimulatorEnd,
  [Types.CALL_GET_FILE_SIMULATOR_PYME]: callGetFileSimulatorPYME,
  [Types.CALL_GET_FILE_SIMULATOR_PYME_END]: callGetFileSimulatorPYMEEnd
});
