import { ofType, combineEpics } from "redux-observable";
import { flatMap, map, catchError } from "rxjs/operators";
import { Observable } from "rxjs-compat";
import { push } from "connected-react-router";
import App from "../../lib/app";
import IssuerPaymentActions, {
  IssuerPaymentTypes
} from "../reducers/issuer-payment.reducer";

export const getPatrimonialRightsReport = ($action) =>
  $action.pipe(
    ofType(IssuerPaymentTypes.START_GET_PATRIMONIAL_RIGHTS_REPORT),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.getPatrimonialRightsReport({
          url: {
            issuer_payment_id: action.payload.issuerPaymentId,
            page: action.payload.page,
            size: action.payload.size
          }
        })
      ).pipe(
        map((response) =>
          IssuerPaymentActions.endGetPatrimonialRightsReport({
            ...response
          })
        ),
        catchError(() => Observable.of(push("/oops")))
      )
    )
  );

export default combineEpics(getPatrimonialRightsReport);
