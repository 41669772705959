// eslint-disable-next-line import/named
import { AnyAction } from "redux";
import { mergeMap } from "rxjs/operators";
import moment from "moment";
import { combineEpics, ofType } from "redux-observable";
import { State } from "redux/store/types";
import { Observable } from "rxjs-compat";
import EquityActions from "./reducer";
import ModalActions from "redux/reducers/modal.reducer";
import LighTheme from "styles/LightTheme";
import App from "lib/app";

export const callEquityPopup = () => ({
  type: "CALL_EQUITY_POPUP"
});

export const equityPopup = (
  action$: AnyAction,
  state$: State<{
    equity: {
      infoPopupCount: number;
    };
    routeHistory: {
      history: String[];
    };
  }>
) =>
  action$.pipe(
    ofType("CALL_EQUITY_POPUP"),
    mergeMap(() => {
      const {
        value: {
          equity: { infoPopupCount },
          routeHistory: { history }
        }
      } = state$;

      const {
        parameters: {
          popup_equity_start_date: { value: popUpEquityStart },
          popup_equity_close_date: { value: popUpEquityClose }
        }
      }: any = App;

      const isDataInRange =
        moment().isSameOrAfter(popUpEquityStart, "day") &&
        moment().isSameOrBefore(popUpEquityClose, "day");

      const historyIsFirstAndHome =
        history.length === 1 &&
        (history[0] === "/" ||
          history[0].startsWith("/?") ||
          history[0].startsWith("/home"));

      if (infoPopupCount < 3 && isDataInRange && historyIsFirstAndHome) {
        return Observable.concat(
          Observable.of(EquityActions.setPopupCount(infoPopupCount + 1)),
          Observable.of(
            ModalActions.setConditionalModalState(true, "EquityModal", {
              clearBlur: true,
              css: {
                backgroundColor: LighTheme.transparentMartinique
              }
            })
          )
        );
      }

      return Observable.of({ type: "NO-SHOW-EQUITY-MODAL" });
    })
  );

export default combineEpics(equityPopup);
