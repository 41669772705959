/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, Switch } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import * as AppRoutes from "./routes";
import { IRoute, IWrapperRouteType } from "./types";
import { ProtectedModalRoute } from "components/ProtectedModalRoute";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";

const renderRoute = (route: IRoute) => {
  const protectedRoute: any = route;
  const routeWrapper: Record<IWrapperRouteType, JSX.Element> = {
    ProtectedRoute: <ProtectedRoute {...protectedRoute} />,
    Route: <Route {...route} />,
    ModalRoute: <ModalRoute {...route} />,
    ProtectedModalRoute: <ProtectedModalRoute {...route} />
  };

  if (route.wrapperType) {
    return routeWrapper[route.wrapperType];
  }
  return routeWrapper["Route"];
};

export const getRoutes = (): IRoute[] => {
  let routes: IRoute[] = [];
  Object.values(AppRoutes).forEach((item: IRoute[]) => {
    routes = [...routes, ...item];
  });
  return routes;
};

export const Routes = ({ routes }: { routes?: IRoute[] }) => {
  const baseRoutes: IRoute[] = routes || getRoutes();
  return <Switch>{baseRoutes.map((route) => renderRoute(route))}</Switch>;
};
