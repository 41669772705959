import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getParams: [],
  setParamsWelcomePyme: ["params"]
});

export const welcomeTypes = Types;
export default Creators;

const INITIAL_STATE = {
  params: {}
};

const setParamsWelcomePyme = (state, { params }) => ({ ...state, params });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_PARAMS_WELCOME_PYME]: setParamsWelcomePyme
});
