import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ChangePageToRight = styled.div`
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: center;
  margin-left: 24px;
  width: 36px;
`;

export const ChangePageToLeft = styled.div`
  align-items: center;
  height: 36px;
  justify-content: center;
  display: flex;
  width: 36px;
`;

export const Icon = styled.img`
  cursor: pointer;
  display: flex;
  height: 16px;
  transform: ${(props) => props.rotate && `rotate(${props.rotate}deg)`};
  width: 16px;
`;

export const PageList = styled.div`
  display: flex;
  justify-content: center;
  min-width: 300px;
`;

export const Page = styled.div`
  align-items: center;
  background: ${(props) => props.isSelected && props.theme.brightTurquoise};
  border-radius: 50%;
  cursor: pointer;
  color: ${(props) =>
    props.isSelected ? props.theme.white : props.theme.darkGray};
  display: flex;
  font-weight: ${(props) => props.isSelected && "bold"};
  height: 36px;
  justify-content: center;
  margin-left: 24px;
  width: 36px;
`;

export const Label = styled.label`
  align-items: center;
  color: ${(props) => props.theme.darkGray};
  display: flex;
  font-size: 14px;
  height: 36px;
  justify-content: center;
`;
