import environment from "environment";
import { Connect } from "./connect";

type IGetInvestment = {
  value: number;
  page: number;
  pageSize: number;
  name?: string;
  businessLine?: number;
  investmentState?: string;
  campaignState?: string;
  userId?: string,
  userEmail?: string,
  userIp?: string
};

export class InvestmentConnect extends Connect {
  constructor() {
    const { apiKey, apiInvestmentUrl } = environment;
    const login = { idToken: sessionStorage.getItem("idToken") };
    super(apiInvestmentUrl, "", {
      apiKey,
      token: login.idToken ? login.idToken : ""
    });
  }

  async availabilityValidation(body: any): Promise<any> {
    const response = await this.request({
      method: "post",
      service: "invest/validate-availability",
      body
    });

    return Promise.resolve(response?.data);
  }

  async canIInvest(body: any): Promise<any> {
    const response = await this.request({
      method: "post",
      service: "invest/validate-user/can-i-invest",
      body
    });
    return Promise.resolve(response?.data);
  }

  async investment(body: any): Promise<any> {
    const response = await this.request({
      method: "post",
      service: "invest/investments",
      body
    });
    return Promise.resolve(response?.data);
  }

  async getTransaction(body: any): Promise<any> {
    const response = await this.request({
      method: "get",
      service: "invest/transaction",
      params: body
    });
    return Promise.resolve(response?.data);
  }


  async amountValidation(body: any): Promise<any> {
    const response = await this.request({
      method: "post",
      service: "invest/validate-amount",
      body
    });

    return Promise.resolve(response?.data);
  }

  async getInvestmentsList({
    value,
    page,
    pageSize,
    name,
    businessLine,
    investmentState,
    campaignState
  }: IGetInvestment): Promise<any> {
    const paramsInitial: any = { value, page, pageSize, businessLine };
    if (name !== "" && name !== null && name !== undefined) {
      paramsInitial.name = name;
    }

    if (businessLine === null && businessLine === undefined) {
      paramsInitial.businessLine = 999;
    }

    if (
      investmentState !== "" &&
      investmentState !== null &&
      investmentState !== undefined
    ) {
      paramsInitial.investmentState = investmentState;
    }

    if (
      campaignState !== "" &&
      campaignState !== null &&
      campaignState !== undefined
    ) {
      paramsInitial.campaignState = campaignState;
    }

    const response: any = await this.request({
      method: "get",
      service: "invest/investments",
      params: paramsInitial
    });

    return response.data;
  }

  async getInvestmentsFile({
    value,
    name,
    businessLine,
    investmentState,
    campaignState,
    userId = "",
    userEmail = "",
    userIp = ""
  }: IGetInvestment): Promise<any> {
    const paramsInitial: any = { value, businessLine };
    if (name !== "" && name !== null && name !== undefined) {
      paramsInitial.name = name;
    }

    if (businessLine === null && businessLine === undefined) {
      paramsInitial.businessLine = 999;
    }

    if (
      investmentState !== "" &&
      investmentState !== null &&
      investmentState !== undefined
    ) {
      paramsInitial.investmentState = investmentState;
    }

    if (
      campaignState !== "" &&
      campaignState !== null &&
      campaignState !== undefined
    ) {
      paramsInitial.campaignState = campaignState;
    }

    paramsInitial.userId = userId;
    paramsInitial.userEmail = userEmail;
    paramsInitial.userIp = userIp;

    const response: any = await this.request({
      method: "get",
      service: "invest/investments/file",
      params: paramsInitial
    });

    return response.data;
  }
}
