import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { push } from "connected-react-router";

import {
  Container,
  Paragraph,
  Divider,
  Button,
  CloseButton,
  Icon,
  IconTitleContainer,
  IconTitle
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";

import errorIcon from "assets/images/ErrorIcon.png";
import { ExperianDocumentErrors } from "redux/epics/confirm-data";
import { startLogoutFront } from "redux/epics/login";
import User from "../../lib/models/user.model";

const ExperianErrorModal = ({ errorCodes = "", redirectUri }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const personType = useSelector((state) => state.personType.personType);

  const handleClick = () => {
    dispatch(push(redirectUri));
    if (personType != User.type.natural) {
      dispatch(startLogoutFront());
    }

    if (ExperianDocumentErrors.includes(errorCodes)) {
      dispatch(ModalActions.setConditionalModalState(true, "ContactForm"));
    } else {
      dispatch(ModalActions.setConditionalModalState(false));
    }
  };

  const handleCloseClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(push(redirectUri));
  };

  let errorMessage = t(`confirmData.errorCode.${errorCodes}`);
  let buttonText = t("confirmData.experianErrorButton");

  if (ExperianDocumentErrors.includes(errorCodes)) {
    errorMessage = t("confirmData.errorCode.PROBLEM_WITH_DOCUMENT");
    buttonText = t("customerService");
  }

  return (
    <Container>
      <CloseButton id="simulator-back-button" onClick={handleCloseClick}>
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <IconTitleContainer>
        <IconTitle alt="previous" src={errorIcon} />
      </IconTitleContainer>
      <Paragraph>{errorMessage}</Paragraph>
      <Divider />
      <Button id="simulator-invest-flow" onClick={handleClick}>
        {buttonText}
      </Button>
    </Container>
  );
};

ExperianErrorModal.propTypes = {
  errorCodes: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired
};

export default ExperianErrorModal;
