import styled from "styled-components";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import CalendarIcon from "../../assets/images/calendar-icon.png";
import CalendarSummary from "../../assets/images/CalendarSummary.png";

export const Picker = styled(DatePicker)`
  flex: 1 1;
  border: none;
  padding: ${(props) => (props.isSummary ? "0 30px !important;" : "20px 30px" )};
  color: #31302f;
  font-family: ${(props) => (props.isSummary ? "Nunito" : "Roboto" )};
  font-size: ${(props) => (props.isSummary ? "18px" : "19px" )};
  outline: none;
  width: 100%;
  border-radius: 8px;
  ${(props) => (props.isSummary ? "height: 56px;" : "" )}
  :disabled {
    background-color: #ffffff;
    ${(props) => (props.isSummary ? "color: #C1C6C8;" :
    `color: ${({ theme }) => theme.silverChalice}`)};
  }
  .readOnly {
    input {
      ${(props) => (props.isSummary ? "color: #C1C6C8;" :
      `color: ${({ theme }) => theme.silverChalice}`)};
    }
  }  
  ${(props) => props.theme.smartphone} {
    padding: 0 20px !important;
    font-size: 12px !important;
    height: 40px;
    
  }
`;

export const Container = styled.div`
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${(props) => (props.isSummary ? "background: 0 0 no-repeat": "")};

  & > div:first-child {
    width: 100%;
  }
  .react-datepicker-year-header {
    min-height: 44px;
    line-height: 1.8;
  }
`;

export const ImgContainer = styled.div`
  padding-right: 30px;
   ${(props) => (props.isDisabled ? "opacity: .5;" : "opacity: 1;" )};
  ${(props) => props.theme.smartphone} {
    padding-right: 12px;
  }
`;

export const Icon = styled.img`
  width: ${(props) => (props.isSummary ? "24px" : "36px" )};
  height: ${(props) => (props.isSummary ? "24px" : "36px" )};
  ${(props) => (props.isDisabled ? "opacity: .5;" : "opacity: 1;" )};
  ${(props) => props.theme.smartphone} {
    width: 16px;
    height: 16px;
  }
`;

export const ReactToolTip = styled(ReactTooltip)`
  display: flex;
  flex-direction: column;
  padding: 6px;
  align-items: flex-end;

  ${({ theme }) => theme.smartphone} {
    width: 170px;
  }

  &__text {
    &--title {
      color: ${(props) => props.theme.white} !important;
      font-family: "Roboto";
      font-size: 11px !important;
      margin: 2px !important;
      text-align: right;
    }
  }
`;

export const ToolTipImg = styled.img`
  width: 20px;
  height: 20px;

  ${(props) => props.theme.smartphone} {
    width: 15px;
    height: 15px;
  }
  &__tooltip_logo_select {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    @media screen and (max-width: $desktop) {
      width: 15px;
      height: 15px;
    }
  }
`;

export const ToolTipImgContainer = styled.div`
  padding: 0 15px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $desktop) {
    padding: 0 10px 0 10px;
  }
`;
