module.exports = {
  formatFormData: (generalInfo) => ({
    data: {
      type: "user-general-info",
      attributes:
        generalInfo.person_type === "natural"
          ? {
              birth_city: Number(generalInfo.birth_city),
              city: Number(generalInfo.city),
              manage_public_resources: generalInfo.manage_public_resources,
              send_usa_resources: generalInfo.send_usa_resources,
              publicly_exposed_person: generalInfo.publicly_exposed_person,
              person_type: generalInfo.person_type,
              landline_country_code: generalInfo.landline_country_code,
              landline_city_code: generalInfo.landline_city_code,
              landline: generalInfo.landline,
              ciiu: generalInfo.ciiu_code,
              address: generalInfo.address,
              birth_date: generalInfo.birth_date
            }
          : {
              city: Number(generalInfo.birth_city),
              send_usa_resources: generalInfo.send_usa_resources,
              person_type: generalInfo.person_type,
              constitution_date: generalInfo.birth_date,
              second_ciiu: generalInfo.ciiu_code,
              address: generalInfo.address
            },
      relationships:
        generalInfo.person_type === "natural"
          ? {
              publicly_exposed_person_options: {
                data: {
                  type: "publicly_exposed_person_options",
                  attributes: {
                    selected_option: {
                      public_figure_question_id:
                        generalInfo.public_figure_question_id
                    }
                  }
                }
              },
              fatca: {
                data: {
                  type: "fatca",
                  attributes: {
                    selected_option: generalInfo.fatca_selected_option
                  }
                }
              },
              public_figure_family_member: {
                data: {
                  type: "public_figure_family_member",
                  attributes: {
                    selected_option: generalInfo.public_figure_family_member
                  }
                }
              }
            }
          : {
              fatca: {
                data: {
                  type: "fatca",
                  attributes: {
                    selected_option: generalInfo.fatca_selected_option
                  }
                }
              }
            }
    }
  }),
  formFormatPymeData: (generalInfo) => {
    const {
      commercialAddress,
      description,
      socialNetworks: { instagram, facebook, twitter, linkein, youtube, web },
      fatcaAnswers,
      hasUsaQuestion
    } = generalInfo;
    return {
      data: {
        type: "user-general-info",
        attributes: {
          commercial_address: commercialAddress,
          social_networks: {
            instagram,
            facebook,
            twitter,
            linkedin: linkein,
            youtube,
            web
          },
          description,
          fatca_answers: fatcaAnswers,
          send_usa_resources: hasUsaQuestion
        }
      }
    };
  }
};
