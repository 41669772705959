import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import ModalActions from "../../redux/reducers/modal.reducer";
import { Wrapper, CloseButton, Icon, VideoContainer, Iframe } from "./Styles";

const Video = ({ url }) => {
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <VideoContainer>
        <CloseButton
          data-testid="video-back-button"
          onClick={() => dispatch(ModalActions.setConditionalModalState(false))}
        >
          <Icon alt="close" src="/assets/images/close_2.png" />
        </CloseButton>
        <Iframe
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
          title="Video"
        />
      </VideoContainer>
    </Wrapper>
  );
};

Video.propTypes = {
  url: PropTypes.string.isRequired
};

export default Video;
