import { createReducer, createActions } from "reduxsauce";

export const { Types, Creators } = createActions({
  getMovements: ["payload"],
  getMovementsSuccess: ["list", "isInfiniteScroll"],
  getMovementsFail: ["error"],
  getFilteredMovements: ["payload"],
  getFilteredMovementsSuccess: ["list", "isInfiniteScroll"],
  getFilteredMovementsFail: ["error"],
  setMovements: ["list"]
});

export const MovementsTypes = Types;
export default Creators;

const INITIAL_STATE = {
  list: {
    transactions: []
  },
  isLoading: false,
  isLoadingFilters: false
};

const getMovements = (state) => ({ ...state, isLoading: true });

const getMovementsSuccess = (state, { list, isInfiniteScroll = false }) => ({
  ...state,
  isLoading: false,
  list: isInfiniteScroll
    ? {
        ...list,
        transactions: state.list.transactions.concat(list.transactions)
      }
    : list
});

const getFilteredMovements = (state) => ({
  ...state,
  isLoadingFilters: true
});

const getFilteredMovementsSuccess = (
  state,
  { list, isInfiniteScroll = false }
) => ({
  ...state,
  isLoadingFilters: false,
  list: isInfiniteScroll
    ? {
        ...list,
        transactions: state.list.transactions.concat(list.transactions)
      }
    : list
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_MOVEMENTS]: getMovements,
  [Types.GET_MOVEMENTS_SUCCESS]: getMovementsSuccess,
  [Types.GET_FILTERED_MOVEMENTS]: getFilteredMovements,
  [Types.GET_FILTERED_MOVEMENTS_SUCCESS]: getFilteredMovementsSuccess
});
