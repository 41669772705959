import React from "react";
import { AboutUs, AboutUsFromType } from "components/shared";
import StyledContainer from "./Styles";
import KnowUsDescriptionContent from "./KnowUsDescriptionContent";
import KnowUsTabsContent from "./KnowUsTabsContent";

const KnowUs = () => (
  <StyledContainer>
    <AboutUs from={AboutUsFromType.KnowUs} />
    <KnowUsDescriptionContent />
    <KnowUsTabsContent />
  </StyledContainer>
);

export default KnowUs;
