import styled from "styled-components";
import User from "../../lib/models/user.model";

const getColorByPersonType = ({ personType, theme }) => {
  if (personType === User.type.pyme) {
    return theme.purple;
  }

  return theme.brightTurquoise;
};

export const Container = styled.div`
  position: fixed;
  top: 50%;
  ${(props) => props.theme.smartphone} {
    position: fixed !important;
    height: auto;
    top: 78%;
    -webkit-transform: translate(-50%, -85%);
  }
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.white};
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  margin-top: 25px;
  ${(props) => props.theme.smartphone} {
    margin-top: 20px;
  }
`;

export const CloseImage = styled.img`
  height: 22px;
  width: 22px;
  object-fit: contain;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  height: 700px;
  max-height: 90vh;
  min-width: 50vw;
  width: 900px;
  max-width: 95vw;
`;

export const TitleSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 20px 0 20px;
  overflow: auto;
  ${(props) => props.theme.smartphone} {
    padding: 40px 10px 0 10px;
  }
`;

export const Title = styled.h1`
  margin-top: 10px;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 6px solid ${(props) => getColorByPersonType(props)};
  color: ${(props) => props.theme.tuatara};
  font-family: Nunito;
  font-size: 24px;
  line-height: 24px;
  font-weight: 800;
  ${(props) => props.theme.smartphone} {
    padding-left: 8px;
    font-size: 21px;
  }
`;

export const Scroll = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
`;

export const Paragraph = styled.p`
  margin: 20px 0;
  padding: 0 40px;
  font-family: Roboto;
  text-align: justify;
  white-space: pre-line;
  font-size: 16px;
  line-height: 18px;
  ${(props) => props.theme.smartphone} {
    padding: 0 15px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 70px;
  border-top: 1px solid ${(props) => getColorByPersonType(props)};
`;

export const Button = styled.button`
  width: 140px;
  height: 40px;
  border: 1px solid ${(props) => getColorByPersonType(props)};
  border-radius: 20px;
  outline: none;
  color: ${(props) => getColorByPersonType(props)};
  font-family: Nunito;
  font-size: 14px;
  font-weight: 800;
  background-color: ${(props) => props.theme.white};
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;
