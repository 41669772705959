import React, { useState, useRef , useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { CodeInput } from "../../components/OTPCode/CodeInput";
import {
  calculateSecondsDiff,
  globalDateToColombianDate
} from "lib/utils/date";
import { formatPhoneNumber, initialTimer } from "../../lib/utils";
import moment from "moment";
import "./SmsOtpValidation.scss";
import alertImg from "assets/icons/alert.png";

type Props = {
  onClick?: (text: string) => void;
  RequestNewCode?: (text: string) => void;
  countryCode?: string;
  phoneNumber?: string;
  dateGenerationToken?: string;
  timerLimitMinutes?: number;
  error?: any;
};

export const SmsOtpValidation = ({
  onClick = () => {},
  RequestNewCode = () => {},
  countryCode,
  phoneNumber,
  dateGenerationToken,
  timerLimitMinutes,
  error
}: Props) => {
  let timerState: any = {time: {}, seconds: 0};
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const inputCodeRef: any = useRef();
  const [currentAttempts, setCurrentAttempts] = useState(1);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [timerString, setTimerString] = useState(
    initialTimer(new Date,moment(dateGenerationToken).add(timerLimitMinutes, "minutes"))
  );
  const [codeInputDisabled, setCodeInputDisabled] = useState(false);
  const [timeUp, setTimeUp] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const timerRef:any = useRef();

  useEffect(() => {
    if(error){
      inputCodeRef.current?.cleanValues();
      const hasExceeded = error.errors[0].code === "1023";
      setErrorCode(error.errors[0].code);
      setCurrentAttempts(hasExceeded ? 5 : error.errors[0].detail.attemps+1);
    }
  }, [error]);

  const secondsToTime = (secs: number) => {
    const hours = Math.floor(secs / (60 * 60));

    const divisor_for_minutes = secs % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);

    const divisor_for_seconds = divisor_for_minutes % 60;
    const seconds = Math.ceil(divisor_for_seconds);

    setTimerString(
      (hours < 10 ? "0"+hours:hours.toString())+":"+
      (minutes < 10 ? "0"+minutes:minutes.toString())+":"+
      (seconds < 10 ? "0"+seconds:seconds.toString())
    );

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  const countDown = () =>  {
    // Remove one second, set state so a re-render happens.
    let seconds = Math.trunc(timerState.seconds - 1);
    timerState = {
      time: secondsToTime(seconds),
      seconds: seconds
    };
    // Check if we're at zero.
    if (seconds == 0) { 
      clearInterval(timerRef.current);
      setTimeUp(true);
      setCurrentAttempts(0);
      setCodeInputDisabled(false);
      setSendButtonDisabled(true);
    }
  }

  const codeOnChange = (value: any) => {
    if (value.length === 6) {
      setOtpCode(value);
      if(!timeUp){
        setSendButtonDisabled(false);
      }
      else{
        setSendButtonDisabled(true);
      }
    }
    if(value.length < 6) {
      setSendButtonDisabled(true);
    }
  };

  const startTimer = () => {
    timerState.seconds = calculateSecondsDiff(
      globalDateToColombianDate(new Date),
      globalDateToColombianDate(moment(dateGenerationToken).add(timerLimitMinutes, "minutes"))
    );

    if (timerState.seconds > 0) {
      setTimeUp(false);
      timerRef.current = setInterval(countDown, 1000);
    } else {
      setTimerString("00:00:00")
      setTimeUp(true);
      setCurrentAttempts(0);
      setCodeInputDisabled(false);
      setSendButtonDisabled(true);
    }
  }

  useEffect(() => {
    setTimerString(initialTimer(new Date,moment(dateGenerationToken).add(timerLimitMinutes, "minutes")));
    clearInterval(timerRef.current);
    setCodeInputDisabled(false);
    startTimer();
  }, [dateGenerationToken]);

  const sendBtnOnClick = () => {
    setSendButtonDisabled(true);
    onClick(otpCode);
  }

  const oopsBtnOnClick = () => {
    dispatch(push("/"));
  }
  
  const failedAttemptsBtnOnClick = () => {
    dispatch(RequestNewCode);
    setCodeInputDisabled(false);
    setCurrentAttempts(1);
    setErrorCode("");
  }

  const handleRequestNewCode = () => {
    inputCodeRef.current.cleanValues();
    dispatch(RequestNewCode);
    setCodeInputDisabled(false);
    setCurrentAttempts(1);
  };

  if(errorCode == "1023"){
    return (
      <div className="failedAttempts" data-testid="failedttempts">
        <div className="failedAttempts__info">
          <img
            className="failedAttempts__icon"
            src={alertImg}
            alt="failedAttempts_icon"
          />
          <div className="failedAttempts__title">
          {t("smsOtpValidation.failedAttempts.title")}
          </div>
        </div>
        <button 
            className="failedAttempts__btn"
            data-testid="failed-attempts-button"
            onClick={failedAttemptsBtnOnClick}>
          {t("smsOtpValidation.failedAttempts.buttom")}
        </button>
      </div>
    );
  } else if(errorCode && errorCode != "1024"){
    return (
      <div className="otpOops">
        <div className="otpOops__info">
          <div className="otpOops__title">
            {t("smsOtpValidation.oops.title")}
          </div>
          <div className="otpOops__copy-info">
            <b>{t("smsOtpValidation.oops.info1")}</b>
            {t("smsOtpValidation.oops.info2")}
          </div>
        </div>
        <button 
            className="otpOops__btn"
            data-testid="otp-oops-button"
            onClick={oopsBtnOnClick}>
          {t("smsOtpValidation.oops.buttom")}
        </button>
      </div>
    );
  }

  return (
    <div className="otp">
      <div className="otp__InfoContainer">
        <div className="otp__Title">{t("forgotPasswordOtp.title")}</div>
        <div className="otp__Subtitle">
          {`${t("forgotPasswordOtp.subtitleSMS1")} +${countryCode} ${formatPhoneNumber(phoneNumber)} ${t("forgotPasswordOtp.subtitleSMS2")}`}
        </div>
          <div className="otp__InfoSMS">
            {t("forgotPasswordOtp.infoSMS")}
          </div>
      </div>
      <div className={`otp__InputInfo${currentAttempts == 1 ? " shorText" : ""}`}>
        <CodeInput
          data-testid="code-input"
          parentRef={inputCodeRef}
          onChange={codeOnChange}
          attempt={currentAttempts}
          disabled={codeInputDisabled}     
          timeUp={timeUp}
        />
        <div className={`otp__InfoTimer infoCode-${currentAttempts} ${timeUp ? " timeUp" : ""}`}>
          <span>{t(`forgotPasswordOtp.codeActive${currentAttempts}`)}&nbsp;<b>{timerString}{"."}</b></span>
        </div>
      </div>
      <div className="otp__cta">
        <div className="otp__InfoText">
          {t("forgotPasswordOtp.noReceived")}
          <div className={`otp__NewCodeLink ${timeUp ? "" : "disabled"}`}
            data-testid="request-new-code-button"
            onClick={handleRequestNewCode}
          >
            {t("forgotPasswordOtp.askAgain")}
          </div>
        </div>
        <button className="otp__SendButton"
            data-testid="request-otp-validation"
            disabled={sendButtonDisabled}
            onClick={sendBtnOnClick}>
          Ingresar
        </button>
      </div>
    </div>
  );
};