import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { SmsOtpValidation } from "../../components/SmsOtpValidation/SmsOtpValidation";
import deepLinkWrapper from "../../lib/deepLinkWrapper";
import CodeValidationActions from "../../redux/reducers/code-validations.reducer";

export const getUriParam = (field) => {
  const path = window.location.href.split("/");
  const routeIndex = path.findIndex((value) => value === "code-validation");
  switch (field) {
    case "userId":
      return path[routeIndex + 1];
    case "token":
      return path[routeIndex + 2];
    default:
      return "";
  }
};

const CodeValidation = ({ location }) => {
  const dispatch = useDispatch();
  const { token, userId, otpSize, countryCode, phoneNumber, dateGenerationToken, timerLimitMinutes } = location.state;
  const error = useSelector((state) => state.codeValidation.error);

  const codeOnChange = (value) => {
    if (value.length === Number(otpSize)) {
      dispatch(CodeValidationActions.validateCode(userId, token, value));
    }
  };

  return (
    <SmsOtpValidation
      onClick={codeOnChange}
      RequestNewCode={CodeValidationActions.requestNewCode(userId, token)}
      countryCode= {countryCode}
      phoneNumber= {phoneNumber}
      dateGenerationToken={dateGenerationToken}
      timerLimitMinutes={Number(timerLimitMinutes)}
      error={error}
    />
  );
};

CodeValidation.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      token: PropTypes.string,
      userId: PropTypes.string,
      otpSize: PropTypes.string,
      countryCode: PropTypes.string,
      phoneNumber: PropTypes.string
    })
  }).isRequired
};

export default deepLinkWrapper({
  withData: {
    render: CodeValidation
  },
  withoutData: {
    createActionUsing: () =>
      CodeValidationActions.validateToken(
        getUriParam("userId"),
        getUriParam("token")
      ),
    withParamsFromUrl: [""]
  }
});
