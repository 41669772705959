import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.smartphone} {
    background-color: ${({ theme }) => theme.white};
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 4%;
`;

export const IconHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const IconHeader = styled.img`
  width: 85px;
  height: 85px;
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  ${(props) => props.theme.smartphone} {
    margin-top: 20px;
  }
  img {
    height: 30px;
    width: 30px;
    object-fit: contain;
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageTitle = styled.h1`
  font-size: 36px;
  color: ${({ theme }) => theme.masala};
  font-family: "Nunito";
  margin: 5px 0;
  text-align: center;
  ${(props) => props.theme.smartphone} {
    font-family: "Roboto";
    line-height: 26px;
    font-weight: bolder;
    font-size: 25px;
    padding: 0 20%;
    margin: 5% 0;
  }
`;

export const PageSubtitle = styled.p`
  font-size: 23px;
  color: ${({ theme }) => theme.masala};
  font-family: "Roboto";
  text-align: center;
  padding: 0 29%;
  line-height: 25px;
  ${(props) => props.theme.smartphone} {
    font-size: 15px;
    padding: 0 10%;
    line-height: 16px;
  }
`;

export const DownloadFileContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 2% 0;
  div {
    width: 40%;
    justify-content: center;
    ${(props) => props.theme.smartphone} {
      margin: 5% 0;
    }
  }
`;

export const TextAreaField = styled.textarea`
  height: 150px;
  width: 100%;
  font-size: 18px;
  color: ${({ theme }) => theme.masala};
  font-family: "Roboto";
  padding: 2% 1%;
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  resize: none;
  margin: 0;
  border-bottom: 1px solid ${({ theme }) => theme.transparentGray};
  &:focus {
    outline: none;
  }
  ${(props) => props.theme.smartphone} {
    background-color: ${({ theme }) => theme.gray};
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 2px solid ${({ theme }) => theme.transparentGray};
    border-right: 2px solid ${({ theme }) => theme.transparentGray};
    padding: 5%;
    width: 70%;
  }
`;

export const UploadFilesIconsContainer = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme.smartphone} {
    display: flex;
    justify-content: space-between;
  }
`;

export const UploadFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  ${(props) => props.theme.smartphone} {
    flex-direction: row;
    width: 100%;
  }
`;

export const FilesIconsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.white};
  padding: 1% 2%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  ${(props) => props.theme.smartphone} {
    background-color: ${({ theme }) => theme.gray};
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 30%;
    align-items: center;
    border-bottom: 2px solid ${({ theme }) => theme.transparentGray};
  }
`;

export const UploadFileIcon = styled.img`
  width: 37px;
  height: 37px;
  margin: 0 10px;
  cursor: pointer;
  ${(props) => props.theme.smartphone} {
    width: 30px;
    height: 30px;
  }
`;

export const UploadFileLabel = styled.label``;

export const Input = styled.input`
  display: none;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.brightTurquoiseTransparent};
  margin-top: 3%;
  ${(props) => props.theme.smartphone} {
    height: 60px;
    position: relative;
    bottom: 0;
  }
`;

export const SubmitButton = styled.button`
  width: 215px;
  height: 49px;
  background-color: ${(props) => props.theme.white};
  border-radius: 24px;
  border: none;
  color: ${(props) => props.theme.brightTurquoise};
  font-family: "Nunito";
  font-size: 17px;
  font-weight: 800;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  ${(props) => props.theme.smartphone} {
    width: 170px;
    height: 40px;
    border-radius: 18px;
    font-size: 13px;
  }
`;

export const IconFile = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 15px;
`;

export const UploadedFile = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FileContent = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.gray};
  align-items: center;
  min-width: 20%;
  padding-right: 3%;
  border-radius: 5px;
  position: relative;
  ${(props) => props.theme.smartphone} {
    width: 80%;
    height: 45px;
    background-color: ${({ theme }) => theme.white};
    margin: 1% 2%;
    padding: 0 15px;
  }
`;

export const FileBottom = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.gray};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 0 auto;
  ${(props) => props.theme.smartphone} {
    width: 93%;
    height: 60px;
    justify-content: center;
    align-items: center;
  }
`;

export const DeleteFile = styled.img`
  width: 17px;
  height: 17px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

export const UploadedFileName = styled.p`
  font-family: "Nunito";
  color: ${({ theme }) => theme.black};
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  span {
    color: ${({ theme }) => theme.ironSideGray};
    display: block;
    width: 100%;
    font-size: 10px;
  }
`;

export const Error = styled.p`
  align-self: center;
  font-family: "Nunito";
  color: ${({ theme }) => theme.red};
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${({ mobile }) => (mobile ? "10px" : "0px")};
  width: 80%;
  text-align: center;
`;
