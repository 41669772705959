import React, { Fragment } from "react";
import PropTypes from "prop-types";
import SimulatorCampaignItem from "./SimulatorCampaignItem";
import { CampaignItemSeparator, CampaignList } from "./Styles";

const SimulatorCampaignList = ({ campaigns, onSelect }) => {
  const renderCampaigns = () =>
    campaigns.map((campaign) => (
      <>
        <SimulatorCampaignItem campaign={campaign} onSelect={onSelect} />
        <CampaignItemSeparator />
      </>
    ));

  return <CampaignList>{renderCampaigns()}</CampaignList>;
};

SimulatorCampaignList.propTypes = {
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      campaignId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      termInMonths: PropTypes.number.isRequired,
      interestRate: PropTypes.number.isRequired,
      interestPayment: PropTypes.number.isRequired,
      capitalPayment: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default SimulatorCampaignList;
