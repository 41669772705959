import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { ModalContainer } from "./Styles";

const ConditionalModal = ({ components }) => {
  const [
    conditionalModalState,
    componentToDisplay,
    params
  ] = useSelector((state) => [
    state.modal.conditionalModalState,
    state.modal.componentToDisplay,
    state.modal.params
  ]);

  if (conditionalModalState) {
    document.body && document.body.classList.add("no-overflow");
  } else {
    document.body && document.body.classList.remove("no-overflow");
  }

  const Component = components[componentToDisplay];

  return conditionalModalState ? (
    <ModalContainer style={{ ...params.css }}>
      <Component {...params} />
    </ModalContainer>
  ) : null;
};

ConditionalModal.propTypes = {
  components: PropTypes.objectOf(PropTypes.func).isRequired
};

export default ConditionalModal;
