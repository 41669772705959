import React from "react";
import {
  Retention,
  Empty,
  FinancialTax,
  Report
} from "components/Certificates";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "redux/reducers/user-certificates.reducer";
import yearsAvailable from "lib/utils/yearsAvailable";
import App from "../../lib/app";
import moment from "moment";
import { TypeCertificate } from "../../redux/reducers/user-certificates.reducer";

export const CertificatesContainer = ({ match }: any) => {
  const yearInitialFilter = Number(moment().format("YYYY")) - 1;
  const dispatch = useDispatch();

  const typeSelected: TypeCertificate = Number(match.params.type);

  const { availables, firstYear } = useSelector(
    (store: Store) => store.userCertificates
  );

  const onClickgoBack = () => {
    dispatch(push("/my-certificates"));
  };
  const onClickgoBackEmpty = () => {
    dispatch(push("/my-certificates"));
  };
  const { parameters }: any = App;

  const { value: yearLimit } = parameters.year_limit_filter;
  const selectedYear =
    Number(yearLimit) > Number(firstYear || 0) ? yearLimit : firstYear;

  const yearList = yearsAvailable(selectedYear, 5).map((year) => ({
    id: year,
    value: year
  }));

  const isRetention = TypeCertificate.Retention === typeSelected;
  const isFinantialTax = TypeCertificate.FinancialTax === typeSelected;
  const isReportAnual = TypeCertificate.ReportAnual === typeSelected;
  if (
    (isRetention && availables.withholdingTaxesCerts === 0) ||
    (isFinantialTax && availables.financialTaxesCerts === 0) ||
    (isReportAnual && availables.reportYearCerts === 0)
  ) {
    return (
      <Empty
        onClickGoBack={onClickgoBackEmpty}
        certificateType={typeSelected}
      />
    );
  }
  return (
    <>
      {isRetention && (
        <Retention
          onClickGoBack={onClickgoBack}
          yearList={yearList}
          yearInitial={yearInitialFilter}
        />
      )}

      {isFinantialTax && (
        <FinancialTax
          onClickGoBack={onClickgoBack}
          yearList={yearList}
          yearInitial={yearInitialFilter}
        />
      )}

      {isReportAnual && (
        <Report
          onClickGoBack={onClickgoBack}
          yearList={yearList}
          yearInitial={yearInitialFilter}
        />
      )}
    </>
  );
};
