import { createReducer, createActions } from "reduxsauce";
import * as R from "ramda";
import {
  FINANCIAL_TERMS_STATE,
  FINANCIAL_TERMS_TYPES
} from "../../lib/models/campaign.model";

const { Types, Creators } = createActions({
  setRequestCampaignInfo: ["info"],
  fetchRequestCampaignInfo: ["info"],
  fetchMinimalAmount: [],
  setMinimalAmount: ["minimalAmount"],
  submitRequestCampaignInfoForm: ["payload"],
  setFetchingEnd: [],
  setFinancialTermsSuggestions: ["info"],
  setEditedFinancialTerms: ["isEdited"],
  setFinancialTermsState: ["newState"],
  startRequestCampaignOtpFlow: [""],
  startValidateCampaignOtpFlow: ["code"],
  setOtpDateSent: [""],
  setOtpFailRequest: [""],
  setOtpValidateSuccess: [""],
  setTermsAccepted: [""],
  setOtpValidateInvalid: ["errorState"],
  setTermsToAccept: ["payload"],
  setBankInfoRequest: ["bankInfo", "isSendRequest"],
  setPreApprovalInfo: ["payload"],
  setPreApprovalInfoFailed: ["error"],
  startRequestCampaignPreApprovalFlow: [],
  setResetRequestCampaign: [],
  cleanOtp: [],
  cleanAll: [],
  fetchRequestCampaignPublishInfo: [],
  setRequestCampaignPublishInfo: ["info"],
  requestCampaignPublish: ["values", "isSend"],
  finishRequestCampaignPublish: [],
  setRequestCampaignFiles: ["releaseImage", "pathImage"]
});

export const RequestCampaignTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  info: {
    id: null,
    company_id: "",
    description: "",
    term_in_months: "",
    close_date: "",
    video_path: "",
    requested_amount: "",
    minimal_amount: "",
    fng_percentage: "",
    interest_rate: "",
    capital_payment: "",
    capital_grace_period: "",
    interest_payment: "",
    interest_grace_period: "",
    fng_fee_percentage: "",
    financial_term: "",
    has_codebtor: true,
    validated_otp: 0,
    fng_coverage_percentage: ""
  },
  publishInfo: {
    id: null,
    name: null,
    description: null,
    close_date: null,
    video_path: null,
    image: null,
    detail_image: null,
    why_trust: {
      reason_0: null,
      reason_1: null,
      reason_2: null,
      reason_3: null
    },
    impacts: {
      social_impact: null,
      innovation_impact: null,
      financial_impact: null,
      env_impact: null,
      another_impact: {
        title: null,
        content: null
      }
    },
    company: {
      id: null,
      business_name: null,
      description: null
    },
    company_basic_info: {
      what_does_comp_do: null,
      business_areas: null,
      main_customers: null,
      main_providers: null,
      current_financial_issues: null,
      future_plans: null,
      added_value: null,
      what_will_we_do: null,
      how_will_we_do_it: null,
      risks: null,
      description: null,
      project_classification: null,
      company_classification: null,
      instagram_link: null,
      facebook_link: null,
      twitter_link: null,
      linkedin_link: null,
      youtube_link: null,
      web_page_link: null,
      show_added_value: null,
      show_business_areas: null,
      show_future_plans: null,
      show_main_customers: null,
      show_main_providers: null
    },
    company_team: {
      partners: [],
      team: []
    }
  },
  financialTermsApprovedForBvc: {
    requested_amount: "",
    minimal_amount: "",
    fng_percentage: "",
    interest_rate: "",
    capital_payment: "",
    capital_grace_period: "",
    interest_payment: "",
    interest_grace_period: "",
    fng_fee_percentage: "",
    financial_term: "",
    observation: "",
    fng_coverage_percentage: ""
  },
  preapproval: {
    otp: false,
    terms: false,
    bank_info: {
      bank_id: undefined,
      bank_account_type_id: 1,
      account_number: ""
    },
    error: undefined
  },
  isFetching: false,
  isSaving: false,
  isFetchingPublish: false,
  isSend: false,
  minimalAmount: 0,
  companyClassification: "",
  projectClassification: "",
  codebtors: [],
  isEditedFinancialTerms: false,
  financialTermsState: FINANCIAL_TERMS_STATE.PENDING_APPROVE,
  otp: {
    fetchDate: null,
    isInvalid: false,
    errorSendOtp: false,
    isSendingOTP: false
  },
  terms: {
    id: null,
    text: ""
  }
};

const startRequestCampaignOtpFlow = (state) => ({
  ...state,
  otp: {
    ...state.otp,
    isSendingOTP: true
  }
});

const startValidateCampaignOtpFlow = (state) => ({
  ...state,
  otp: {
    ...state.otp,
    isSendingOTP: true
  }
});

const setOtpValidateSuccess = (state) => ({
  ...state,
  preapproval: {
    ...state.preapproval,
    otp: true
  },
  otp: {
    fetchDate: null,
    isInvalid: false,
    errorSendOtp: false,
    isSendingOTP: false
  }
});

const setTermsAccepted = (state) => ({
  ...state,
  preapproval: {
    ...state.preapproval,
    terms: true
  }
});

const setOtpValidateInvalid = (state, { errorState }) => ({
  ...state,
  otp: {
    isInvalid: typeof errorState !== "undefined" ? errorState : true,
    errorSendOtp: false,
    isSendingOTP: typeof errorState !== "undefined" ? true : false
  }
});

const setOtpDateSent = (state) => ({
  ...state,
  otp: {
    fetchDate: Date.now(),
    isInvalid: false,
    errorSendOtp: false,
    isSendingOTP: false
  }
});

const setTermsToAccept = (state, { payload }) => ({
  ...state,
  terms: { ...payload }
});

const setOtpFailRequest = (state) => ({
  ...state,
  otp: {
    fetchDate: null,
    isInvalid: false,
    errorSendOtp: true
  }
});

const setRequestCampaignInfo = (state, { info }) => ({
  ...state,
  info: { ...info, has_codebtor: true },
  isFetching: false
});

const setRequestCampaignPublishInfo = (state, { info }) => {
  const byIsPartner = R.groupBy((teamMamber) =>
    teamMamber.is_partner ? "partners" : "team"
  );
  const companyTeam = byIsPartner(info.company_team);
  return {
    ...state,
    publishInfo: { ...info, company_team: companyTeam },
    isFetching: false
  };
};

const fetchRequestCampaignInfo = (state) => ({
  ...state,
  isFetching: true
});

const getRequestCampaignInfoPublish = (state) => ({
  ...state,
  isFetching: true
});

const requestCampaignPublish = (state) => ({
  ...state,
  isFetchingPublish: true
});
const finishRequestCampaignPublish = (state) => ({
  ...state,
  isFetchingPublish: false
});

const setMinimalAmount = (state, { minimalAmount }) => ({
  ...state,
  minimalAmount
});

const submitRequestCampaign = (state) => ({
  ...state,
  isFetching: true
});

const setFetchingEnd = (state) => ({
  ...state,
  isFetching: false,
  isSaving: false,
  isSend: false
});

const startRequestCampaignPreApprovalFlow = (state) => ({
  ...state,
  isFetching: true
});

const setEditedFinancialTerms = (state, { isEdited }) => ({
  ...state,
  isEditedFinancialTerms: isEdited
});

const setFinancialTermsState = (state, { newState }) => ({
  ...state,
  financialTermsState: newState
});

const setFinancialTermsSuggestions = (
  state,
  {
    info: {
      pyme,
      suggestion,
      accepted_by_pyme: acceptedByPyme,
      approved,
      company_classification: companyClassification = "-",
      project_classification: projectClassification = "-",
      codebtors
    }
  }
) => {
  let financialTermsState = FINANCIAL_TERMS_STATE.PENDING_APPROVE;

  const pymeTerms = acceptedByPyme || pyme;
  if (
    suggestion &&
    (pymeTerms.financial_terms_type_id ===
      FINANCIAL_TERMS_TYPES.ACCEPTED_BY_PYME ||
      pymeTerms.financial_terms_type_id === FINANCIAL_TERMS_TYPES.PYME)
  ) {
    financialTermsState = FINANCIAL_TERMS_STATE.APPROVE_BY_COMMITE;
  }

  if (approved) {
    financialTermsState = FINANCIAL_TERMS_STATE.APPROVE_BY_PYME;
  }

  return {
    ...state,
    isFetching: false,
    info: {
      ...state.info,
      financial_terms: {
        ...(pymeTerms && {
          ...pymeTerms,
          requested_amount: pymeTerms.requested_amount,
          minimal_amount: pymeTerms.minimal_amount,
          fng_percentage: pymeTerms.fng_fee_percentage,
          interest_rate: pymeTerms.funding_rate,
          fng_warranty_line: pymeTerms.fng_warranty_line || 0,
          fng_warranty_line_name: pymeTerms.fng_warranty_line_name || "",
          capital_payment:
            pymeTerms.capital_payment_peridicity &&
            pymeTerms.capital_payment_peridicity > 0
              ? pymeTerms.capital_payment_peridicity
              : 0,
          capital_grace_period:
            pymeTerms.capital_grace_period && pymeTerms.capital_grace_period > 0
              ? pymeTerms.capital_grace_period
              : 0,
          interest_payment: pymeTerms.interest_payment_periodicity,
          interest_grace_period:
            pymeTerms.interest_grace_period &&
            pymeTerms.interest_grace_period > 0
              ? pymeTerms.interest_grace_period
              : 0,
          fng_fee_percentage: pymeTerms.fng_fee_percentage,
          financial_term: pymeTerms.term_in_months,
          fng_coverage_percentage: pymeTerms.fng_coverage_percentage,
          observation: pymeTerms.observation
        })
      }
    },
    companyClassification,
    projectClassification,
    codebtors,
    financialTermsApprovedForBvc: {
      ...state.financialTermsApprovedForBvc,
      ...(suggestion && {
        requested_amount: suggestion.requested_amount,
        minimal_amount: suggestion.minimal_amount,
        fng_percentage: suggestion.fng_fee_percentage,
        fng_warranty_line: suggestion.fng_warranty_line || 0,
        fng_warranty_line_name: suggestion.fng_warranty_line_name || "",
        interest_rate: suggestion.funding_rate,
        capital_payment:
          suggestion.capital_payment_peridicity &&
          suggestion.capital_payment_peridicity > 0
            ? suggestion.capital_payment_peridicity
            : 0,
        capital_grace_period:
          suggestion.capital_grace_period && suggestion.capital_grace_period > 0
            ? suggestion.capital_grace_period
            : 0,
        interest_payment: suggestion.interest_payment_periodicity,
        interest_grace_period:
          suggestion.interest_grace_period &&
          suggestion.interest_grace_period > 0
            ? suggestion.interest_grace_period
            : 0,
        fng_fee_percentage: suggestion.fng_fee_percentage,
        financial_term: suggestion.term_in_months,
        observation: suggestion.observation,
        fng_coverage_percentage: suggestion.fng_coverage_percentage
      })
    },
    financialTermsState
  };
};
const setPreApprovalInfo = (state, { payload }) => ({
  ...state,
  preapproval: payload,
  isFetching: false
});

const setPreApprovalInfoFailed = (state, { error }) => ({
  ...state,
  preapproval: { error },
  isFetching: false
});

const setFetchingStepSix = (state, { isSendRequest }) => {
  const sendData = {};
  if (isSendRequest) {
    sendData.isSend = true;
  } else {
    sendData.isFetching = true;
    sendData.isSaving = true;
  }
  return {
    ...state,
    ...sendData
  };
};

const setResetState = () => ({
  ...INITIAL_STATE
});

const cleanOtp = (state) => ({
  ...state,
  otp: {
    ...state.otp,
    errorSendOtp: false
  }
});

const cleanAll = () => ({ ...INITIAL_STATE });

const setRequestCampaignFiles = (state, { releaseImage, pathImage }) => ({
  ...state,
  publishInfo: {
    ...state.publishInfo,
    video_path: releaseImage === "detail_image" ? "" : state.video_path,
    [releaseImage]: pathImage
  }
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_REQUEST_CAMPAIGN_INFO]: setRequestCampaignInfo,
  [Types.FETCH_REQUEST_CAMPAIGN_INFO]: fetchRequestCampaignInfo,
  [Types.SET_MINIMAL_AMOUNT]: setMinimalAmount,
  [Types.SUBMIT_REQUEST_CAMPAIGN_INFO_FORM]: submitRequestCampaign,
  [Types.SET_FETCHING_END]: setFetchingEnd,
  [Types.SET_FINANCIAL_TERMS_SUGGESTIONS]: setFinancialTermsSuggestions,
  [Types.SET_EDITED_FINANCIAL_TERMS]: setEditedFinancialTerms,
  [Types.SET_FINANCIAL_TERMS_STATE]: setFinancialTermsState,
  [Types.SET_OTP_DATE_SENT]: setOtpDateSent,
  [Types.SET_OTP_FAIL_REQUEST]: setOtpFailRequest,
  [Types.SET_TERMS_TO_ACCEPT]: setTermsToAccept,
  [Types.SET_OTP_VALIDATE_SUCCESS]: setOtpValidateSuccess,
  [Types.SET_TERMS_ACCEPTED]: setTermsAccepted,
  [Types.SET_OTP_VALIDATE_INVALID]: setOtpValidateInvalid,
  [Types.START_REQUEST_CAMPAIGN_PRE_APPROVAL_FLOW]: startRequestCampaignPreApprovalFlow,
  [Types.SET_PRE_APPROVAL_INFO]: setPreApprovalInfo,
  [Types.SET_PRE_APPROVAL_INFO_FAILED]: setPreApprovalInfoFailed,
  [Types.SET_BANK_INFO_REQUEST]: setFetchingStepSix,
  [Types.START_REQUEST_CAMPAIGN_OTP_FLOW]: startRequestCampaignOtpFlow,
  [Types.START_VALIDATE_CAMPAIGN_OTP_FLOW]: startValidateCampaignOtpFlow,
  [Types.SET_RESET_REQUEST_CAMPAIGN]: setResetState,
  [Types.CLEAN_OTP]: cleanOtp,
  [Types.CLEAN_ALL]: cleanAll,
  [Types.SET_REQUEST_CAMPAIGN_PUBLISH_INFO]: setRequestCampaignPublishInfo,
  [Types.FETCH_REQUEST_CAMPAIGN_PUBLISH_INFO]: getRequestCampaignInfoPublish,
  [Types.CLEAN_OTP]: cleanOtp,
  [Types.FINISH_REQUEST_CAMPAIGN_PUBLISH]: finishRequestCampaignPublish,
  [Types.REQUEST_CAMPAIGN_PUBLISH]: requestCampaignPublish,
  [Types.SET_REQUEST_CAMPAIGN_FILES]: setRequestCampaignFiles
});
