import React, { useEffect, useState } from "react";
import { useDispatch, RootStateOrAny, useSelector } from "react-redux";
import User from "../../lib/models/user.model";
import ModalActions from "../../redux/reducers/modal.reducer";
import ModalType from "../../lib/models/modal.model";
import { useTranslation } from "react-i18next";

import {
  MyProfile as Profile
} from "components/MyProfile";

export const MyProfile = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const user = useSelector(
    ({ user }: RootStateOrAny) => user?.userInfo?.user ?? user
  );

  const userEnrollmentPending = (user.state !== User.status.active) || (user.enrollment_state !== User.state.finished);

  if (userEnrollmentPending) {
    dispatch(ModalActions.setConditionalModalState(
      true,
      "RegistrationProcessModal",
      {config: {
        copy: `${t("myProfile.registrationNeeded")}`,
        closeButton: ModalType.handleCloseButton.BACK
      }}
    ))
    return (<></>);
  } else {
    return (
      <>
        <Profile
          disabledEdit={false}
        />
      </>
    );
  }
};
