import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 4px;
  width: 300px;
  height: 300px;
  padding: 60px 41px 37px 39px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.5px;
    color: #475061;
    text-align: center;
    width: 220px;
  }

  > p > strong {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19.5px;
    color: #475061;
    display: block;
    width: 100%;
    letter-spacing: -0.1px;
  }
`;

