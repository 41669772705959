import styled from "styled-components";

type IProps = {
  disabledDownload: boolean;
};

export const ButtonsWrapper = styled.div<IProps>`
  display: grid;
  ${({ disabledDownload }) =>
    disabledDownload && "grid-template-columns: 50% 50%;"}
  column-gap: 5%;
  justify-self: end;
  align-items: end;
  width: 40%;
  text-align: center;
  > a {
    ${({ disabledDownload }) => disabledDownload && "margin-left: auto;"}
    cursor: pointer;
    img {
      width: 35px;
      height: 35px;
      object-fit: contain;
    }
  }
  font-family: ${({ theme }) =>
    theme ? theme.font.family.primary : "initial"};
  ${({ theme }) => theme.breakpoints.mobile} {
    max-width: 100%;
  }
`;
