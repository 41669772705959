import { createReducer, createActions } from "reduxsauce";

export const { Types, Creators } = createActions({
  setBanksList: ["banksList"],
  setRechargeAmountError: ["errors"],
  endRechargeFlow: ["payload"],
  setRechargeEmpty: []
});

export const rechargeTypes = Types;
export default Creators;

const INITIAL_STATE = {
  banksList: [],
  errors: undefined
};

const setBanksList = (state, { banksList }) => ({
  ...state,
  banksList
});

const setRechargeAmountError = (state, { errors }) => ({
  ...state,
  errors
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_BANKS_LIST]: setBanksList,
  [Types.SET_RECHARGE_AMOUNT_ERROR]: setRechargeAmountError
});
