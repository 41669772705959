import moment from "moment";

export default (limitYear, yearsNumber) => {
  let currentYear = parseInt(moment().format("YYYY"));
  let years = [];

  if (Number.isNaN(+limitYear)) {
    throw new Error("Limit year is not a number");
  }

  if (Number.isNaN(+yearsNumber) || +yearsNumber < 0) {
    throw new Error("Years number is not a number or is less than zero");
  }

  if (currentYear < +limitYear) {
    return [currentYear - 1];
  }

  for (let index = 0; index < yearsNumber; index++) {
    currentYear--;

    if (currentYear >= +limitYear) {
      years.push(currentYear);
    } else {
      break;
    }
  }

  return years;
};
