import React, { useEffect } from "react";
import { Alert as Notification } from "components/shared";
import { useSelector, useDispatch } from "react-redux";
import { timer } from "rxjs";
import { useTranslation } from "react-i18next";
import { Container, ContainerCenter } from "./Styles";
import AlertAction from "../../redux/reducers/alert.reducer";

const Alert = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    show,
    type,
    comment,
    time,
    icon,
    hasClose,
    additionalText
  } = useSelector(({ alert }) => alert);

  const source = timer(time);
  let timerSaving;

  const handleClose = () => {
    timerSaving.unsubscribe();
    dispatch(AlertAction.setAlertClose());
  };

  useEffect(() => {
    if (show) {
      timerSaving = source.subscribe(() => {
        handleClose();
      });
    }
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <Container>
      <ContainerCenter>
        <Notification
          type={type}
          hasIcon={icon}
          isCenter={true}
          hasClose={hasClose}
          handleClose={handleClose}
        >
          {t(comment)}
          {additionalText && ` ${additionalText}`}
        </Notification>
      </ContainerCenter>
    </Container>
  );
};

export default Alert;
