import { createReducer, createActions } from "reduxsauce";
import { TypeNoveltiesReducer } from "./types";

const { Types, Creators } = createActions({
  startFetchNovelties: ["payload"],
  endFetchNovelties: ["novelties"],
  endFetchNoveltiesError: ["error"],
  clearNovelties: []
});

export const GetNoveltiesDataTypes = Types;
export default Creators;

const INITIAL_STATE: TypeNoveltiesReducer = {
  isFetching: false,
  error: "",
  success: false,
  novelties: {
    data: [],
    paginationOptions: {},
    links: {
      totalPages: 0
    }
  }
};

const startFetchNovelties = (state: TypeNoveltiesReducer, { payload }: any) => {
  if (payload.clear) {
    return {
      ...state,
      ...INITIAL_STATE,
      isFetching: true
    };
  }

  return {
    ...state,
    error: "",
    isFetching: true
  };
};

const endFetchNovelties = (
  state: TypeNoveltiesReducer,
  { novelties }: { novelties: any }
) => ({
  ...state,
  isFetching: false,
  success: true,
  novelties: {
    ...novelties,
    data: [...state.novelties.data, ...novelties.data]
  }
});

const endFetchNoveltiesError = (state: TypeNoveltiesReducer, payload: any) => ({
  ...state,
  isFetching: false,
  error: payload.error
});

export const noveltiesReducer = createReducer(INITIAL_STATE, {
  [Types.START_FETCH_NOVELTIES]: startFetchNovelties,
  [Types.END_FETCH_NOVELTIES]: endFetchNovelties,
  [Types.END_FETCH_NOVELTIES_ERROR]: endFetchNoveltiesError
});
