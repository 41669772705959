import styled from "styled-components";

export const StyledContainer = styled.div`
  background: ${(props) => props.theme.white};
  h1:last-child {
    font-weight: 650;
    strong {
      font-weight: 850;
    }
  }
 
`;

export const StyledLinearTabsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 61px;
  padding-bottom: 100px;
  padding-right: 45px;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.smartphone} {
    align-items: center;
    flex-direction: column;
    padding-top: 31px;
    padding-bottom: 48px;
    margin: 0px 0 0px 0;
    padding-right: 0px;
  }
  ul {
    ${(props) => props.theme.tabletMinWidth} {
      padding: 0;
    }
  }
`;

export const StyledContent = styled.div`
  min-height: 400px;
`;

export const BackButtonContainer = styled.div`
  left: 0;
  width: 120px;
  ${({ theme }) => theme.smartphone} {
    position: inherit;
    margin-bottom: 32px;
    margin-right: auto;
    margin-left: 38px;
  }
`;

export const SectionTabsContainer = styled.div`
  width: 570px;
  margin-left: 28px;
  ${({ theme }) => theme.smartphone} {
    width: auto;
    margin-right: 40px;
  
    & > div > div > div:first-child > div:first-child{     
      max-width: 152px;
    
    }
    & > div > div:first-child > div > #ourAllies{     
      max-width: 152px;
    }
  }
  }
`;
