const { Serializer } = require("jsonapi-serializer");

module.exports = {
  RegisterSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "name",
        "lastName",
        "secondLastName",
        "email",
        "phoneNumber",
        "countryCode",
        "documentType",
        "documentNumber",
        "password",
        "userTypeId",
        "personType",
        "dv",
        "contactPerson"
      ]
    })
};
