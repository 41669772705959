import styled from "styled-components";

export const CampaignList = styled.div``;

export const CampaignItem = styled.div`
  background-color: ${(props) => props.theme.white};
  padding: 16px 31px;
  border-radius: 11px;
  font-family: Nunito;
  width: 98%;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  ${(props) => props.theme.tablet} {
    padding: 12px 20px;
  }
`;

export const CampaignItemSeparator = styled.div`
  margin-bottom: 12px;
`;

export const CampaignItemHeader = styled.div`
  width: 80%;
  & > h4 {
    color: ${(props) => props.theme.tuatara};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${(props) => props.theme.tablet} {
    & > h4 {
      font-size: 14px;
    }
  }
`;

export const CampaignItemBody = styled.div`
  position: relative;
  display: flex;
  margin-top: 10px;
`;

export const CampaignItemCol = styled.div`
  display: blok;
  margin-right: 50px;
`;

export const CampaignItemColHide = styled.div`
  display: blok;
  margin-right: 50px;

  ${(props) => props.theme.tablet} {
    display: none;
  }
`;

export const CampaignItemColName = styled.div`
  & > p {
    color: ${(props) => props.theme.tuatara};
    opacity: 0.64;
  }
`;

export const CampaignItemColValue = styled.div`
  margin-top: 2px;
  width: 110px;
  & > p {
    color: ${(props) => props.theme.tuatara};
    opacity: 0.64;
  }

  ${(props) => props.theme.tablet} {
    & > p {
      font-size: 14px;
    }
    width: 85px;
  }
`;

export const RightArrow = styled.img`
  position: absolute;
  top: 45%;
  right: 31px;
  width: 11px;
  height: 17px;
`;
