import { createReducer, createActions } from "reduxsauce";

export type ICompany = {
  id: string;
  accountNumber: string;
  addedValue: string;
  address: string | number | null;
  amount: string | number | null;
  bankAccountTypeId: number;
  bankId: number;
  businessAreas: string;
  businessName: string;
  ciiu: string;
  cityConsignment: string | number | null;
  cityId: number;
  cityRetention: string | number | null;
  companyCode: string | number | null;
  companyCommercialAddress: string;
  companyId: number;
  competitor: string;
  constitutionDate: string | number | null;
  createdAt: string;
  currentFinancialIssues: string;
  description: string;
  existence_certificate: string | number | null;
  facebookLink: string;
  futurePlans: string;
  instagramLink: string;
  linkedinLink: string;
  mainCustomers: string;
  mainProviders: string;
  phone: string | number | null;
  secondCiiu: string;
  sendUsaResources: string | number | null;
  showAddedValue: number;
  showBusinessAreas: number;
  showFuturePlans: number;
  showMainCustomers: number;
  showMainProviders: number;
  twitterLink: string;
  updatedAt: string;
  userId: number;
  webPageLink: string;
  whatDoesCompDo: string;
  youtubeLink: string;
  loading: boolean;
  isGettingInfo: boolean;
};

type Payload = {
  data: ICompany;
};

const { Types, Creators } = createActions({
  setCompanyInfo: ["data"],
  onFetch: [],
  fetchFinish: [],
  onGetInfo: [],
  onGetInfoFinish: []
});

export const companyTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  id: "",
  accountNumber: "",
  addedValue: "",
  address: null,
  amount: null,
  bankAccountTypeId: 0,
  bankId: 0,
  businessAreas: "",
  businessName: "",
  ciiu: "",
  cityConsignment: null,
  cityId: 0,
  cityRetention: null,
  companyCode: null,
  companyCommercialAddress: "",
  companyId: 0,
  competitor: "",
  constitutionDate: null,
  createdAt: "",
  currentFinancialIssues: "",
  description: "",
  existence_certificate: null,
  facebookLink: "",
  futurePlans: "",
  instagramLink: "",
  linkedinLink: "",
  mainCustomers: "",
  mainProviders: "",
  phone: null,
  secondCiiu: "",
  sendUsaResources: null,
  showAddedValue: 0,
  showBusinessAreas: 0,
  showFuturePlans: 0,
  showMainCustomers: 0,
  showMainProviders: 0,
  twitterLink: "",
  updatedAt: "",
  userId: 0,
  webPageLink: "",
  whatDoesCompDo: "",
  youtubeLink: "",
  loading: false,
  isGettingInfo: false
};

const setData = (state: ICompany, { data }: Payload) => ({
  ...state,
  ...data,
  loading: false
});

const onFetch = (state: ICompany) => ({
  ...state,
  loading: true
});

const onGetInfo = (state: ICompany) => ({
  ...state,
  isGettingInfo: true
});

const fetchFinish = (state: ICompany) => ({
  ...state,
  loading: false
});

const onGetInfoFinish = (state: ICompany) => ({
  ...state,
  isGettingInfo: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_COMPANY_INFO]: setData,
  [Types.ON_FETCH]: onFetch,
  [Types.ON_GET_INFO]: onGetInfo,
  [Types.FETCH_FINISH]: fetchFinish,
  [Types.ON_GET_INFO_FINISH]: onGetInfoFinish
});
