import React from "react";
import "./BulletDetailTitle.styles.scss";

type IProps = {
  campaignDetail: Array<{
    content?: string;
    title?: string;
  }>;
};

const BulletDetailTitle = ({ campaignDetail }: IProps) => (
  <div className="bullet-detail-title-container">
    <div className="bullet-detail-title-grid">
      {campaignDetail.map((bulletDetailTitle, i) => (
        <div key={i} className="bullet-detail-title-item">
          {/*<div className="bullet-number-container"></div>*/}
          <div className="bullet-detail-title-content-container">
            <div className="bullet-detail-title-number-container">
              <p className="bullet-detail-title-number">{bulletDetailTitle.title}</p>
            </div>
            <p className="bullet-detail-title-content">
              {bulletDetailTitle.content}
            </p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default BulletDetailTitle;
