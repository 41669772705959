import styled from "styled-components";

export const Right = styled.div`
  width: 821px;
  padding-top: 68px;
  padding-left: 59px;
  padding-right: 59px;
  position: relative;
  padding-bottom: 37px;
  border-radius: 0px 12px 12px 0px;
  background: ${({ theme }) => theme.whiteSmoke};

  ${({ theme }) => theme.breakpoints.whenSmartphone} {
    width: 100%;

    padding-left: 19px;
    padding-right: 19px;
    padding-top: 25px;

    border-radius: 12px;
  }
`;
