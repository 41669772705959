/* eslint-disable no-underscore-dangle */
import { flatMap, map, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import welcomeActions, { welcomeTypes } from "../reducers/welcome-pyme.reducer";
import ModalActions from "../reducers/modal.reducer";
import { getErrorIndex } from "../../lib/utils";
import paramNames from "../../lib/models/parameter.model";

const { HASHED_CODE_PARAMS } = paramNames;
const { _PARAM_004 } = HASHED_CODE_PARAMS();

export const getParams = (action$) =>
  action$.pipe(
    ofType(welcomeTypes.GET_PARAMS),
    flatMap(() =>
      Observable.from(
        App.api.a2censo.getStaticParameters({
          url: { tables: `${_PARAM_004}` }
        })
      ).pipe(
        map((response) => {
          const params = {
            phone: response._parameter.find(
              (item) => item.name === "bvc_phone_number"
            ),
            email: response._parameter.find(
              (item) => item.name === "bvc_email_help"
            ),
            url: response._parameter.find(
              (item) => item.name === "bvc_guide_url"
            )
          };
          return welcomeActions.setParamsWelcomePyme(params);
        }),
        catchError((e) => {
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.of(
              ModalActions.setConditionalModalState(true, "ErrorModal", {
                title: "errorModal.title",
                content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                linkRedirectsTo: "/",
                buttonText: "errorModal.success"
              })
            );
          }
          return Observable.of(push("/oops"));
        })
      )
    )
  );

export default combineEpics(getParams);
