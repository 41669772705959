import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  Container,
  Paragraph,
  Divider,
  Button,
  CloseButton,
  Icon
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";
import { startGetPoliticsTermsHC } from "../../redux/epics/politics-terms-hc";

const ThanksPoliticsTermsHCModal = ({ message }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(startGetPoliticsTermsHC());
  };

  const handleCloseClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
  };

  return (
    <Container>
      <CloseButton id="close-button" onClick={handleCloseClick}>
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <Paragraph>{`${t(message)}`}</Paragraph>
      <Divider />
      <Button id="continue-button" onClick={handleClick}>
        {t("politics.continue")}
      </Button>
    </Container>
  );
};

ThanksPoliticsTermsHCModal.propTypes = {
  message: PropTypes.string.isRequired
};

export default ThanksPoliticsTermsHCModal;
