import styled, { css } from "styled-components";
import { types } from "../types";

type Props = {
  type: types;
};

type BottomItemProps = {
  gridArea: string;
};

type BottomItemRowProps = {
  gridArea: string;
  align?: string;
};

export const BottomContent = styled.div<Props>`
  margin: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  row-gap: 20px;
  z-index: 1;
  ${({ type }) =>
    type === "secondary"
      ? css`
          grid-template-areas:
            "collected_amount goal"
            "investments days"
            "collected state";
        `
      : css`
          grid-template-areas: "collected goal";
        `}
`;

export const BottomItem = styled.div<BottomItemProps>`
  display: flex;
  flex-direction: column;
  grid-area: ${({ gridArea }) => gridArea};
`;

export const BottomItemRow = styled.div<BottomItemRowProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-area: ${({ gridArea }) => gridArea};
  justify-content: ${({ align }) => `flex-${align}`};
`;
