import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const RadioList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background: ${(props) => props.theme.white};
  
`;

export const RadioButton = styled.div`
  background: ${(props) =>
    props.currentValue === props.value
      ? "url(/assets/images/radio-on.png)"
      : "url(/assets/images/radio-off.png)"};
  background-size: cover;
  width: 30px;
  height: 30px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: ${(props) => (props.isSummary ? "40px" : "50px")};
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  border-top: ${(props) =>
    !props.isList ? `${props.theme.transparentGray} solid 1px` : "none"};
  &:hover {
    background-color: ${(props) => props.theme.brightTurquoiseTransparent};
  }
  ${(props) => props.theme.smartphone} {
    padding: 5px 10px;
  }
`;

export const QuestionLabel = styled.label`
  font-family: ${(props) => (props.isSummary ? "Nunito" : "Roboto")};
  min-height:  ${(props) => (props.isSummary ? "38px;" : "50px")};
  display: flex;
  align-items: center;
  font-weight: ${(props) => (props.isSummary ? (props.lightFont) ? "400" : "normal" : "bold")};
  color: ${(props) => props.theme.tuatara};
  font-size:  ${(props) => (props.isSummary ? "18px;" : "20px")}; 
  ${(props) => props.theme.smartphone} {
    font-weight: normal;
    font-size: 15px;
  }
`;

export const Caption = styled.label`
  font-family: ${(props) => (props.isSummary ? "Nunito" : "Roboto")}; 
  min-height: ${(props) => (props.isSummary ? "48px" : "50px")};
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.tuatara};
  font-size: ${(props) => (props.isSummary ? "18px" : "19px")}; 
  flex: 1;
  font-weight: ${(props) =>
    props.currentValue === props.value ? "bold" : "normal"};
  ${(props) => props.theme.smartphone} {
    font-weight: normal;
    font-size: 15px;
  }
`;

export const SelectList = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  ${(props) => (props.isSummary ? "border: 1px solid #A2AAAD; border-radius: 8px; height: 48px; top: 10px" : "")}; 
  ${(props) => (props.isSummary ? "height: 40px;" : "")};
  ${(props) => props.theme.smartphone} {
   right: 10px;
  }
`;

export const SelectButton = styled.div`
  background: ${(props) => props.theme.white};
  border-radius: 8px;
  padding: ${(props) => (props.isSummary ? "0px 20px" : "10px 20px")};  
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  &::after {
    ${(props) =>
      props.type === "list"
        ? `
        content: "";
        background: ${(props.isSummary ? "url(/assets/images/imageChevron.png);" : "url(/assets/images/arrow-down.png);")};
        background-size: ${(props.isSummary ? "16px" : "cover")};
        background-repeat: no-repeat;
        position: absolute;
        width: ${(props.isSummary ? "24px" : "27px")}; 
        height: ${(props.isSummary ? "14px" : "16px")};
        right: 15px;
        top: ${(props.isSummary ? "15px" : "25px")};;
        `
        : ""}
  }
`;

export const List = styled.ul`
  position: absolute;
  top: 40px;
  right: 0;
  background: ${(props) => props.theme.white};
  display: ${(props) => (props.isOpen ? "block" : "none")};
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100%;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: scroll;
`;
