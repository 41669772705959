import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { Heading } from "@bvcco/a2censo-component-lib";
import User from "../../lib/models/user.model";

import {
  Container,
  Paragraph,
  Divider,
  Button,
  CloseButton,
  Icon,
  IconTitleContainer,
  IconTitle
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";

import errorIcon from "assets/images/ErrorIcon.png";

const RegisterErrorModal = ({ errorCodes, redirectUri, textOnButton }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);

  const errorsPyme = ["416", "1002"];

  const handleClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(push(redirectUri));
  };

  const handleCloseClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(push(redirectUri));
  };

  const getErrorMessage = () => {
    const isPyme =
      user && user.personType && user.personType.personType === User.type.pyme;

    if (isPyme && errorsPyme.includes(errorCodes)) {
      return t(`register.userValidation.pyme.errorCodes.${errorCodes}`);
    }
    return t(`register.userValidation.errorCodes.${errorCodes}`);
  };

  return (
    <Container>
      <CloseButton id="button-close" onClick={handleCloseClick}>
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <IconTitleContainer>
        <IconTitle alt="previous" src={errorIcon} />
      </IconTitleContainer>
      <Heading color="black" fontWeight="bold">
        {t("register.userValidation.title")}
      </Heading>
      <Paragraph
        data-testid="register-error-modal-content"
        id="register-error-modal-content"
      >
        {getErrorMessage()}
      </Paragraph>
      <Divider />
      <Button id="button-ok" onClick={handleClick}>
        {textOnButton !== ""
          ? t(textOnButton)
          : t("register.userValidation.button")}
      </Button>
    </Container>
  );
};

RegisterErrorModal.propTypes = {
  errorCodes: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired,
  textOnButton: PropTypes.string.isRequired
};

export default RegisterErrorModal;
