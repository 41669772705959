import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Paragraph, Heading } from "@bvcco/a2censo-component-lib";
import PropTypes from "prop-types";
import {
  Container,
  Divider,
  Button,
  ButtonsContainer,
  ParagraphContainer,
  Image
} from "./Styles";

import ModalActions from "../../redux/reducers/modal.reducer";
import { getUserInfo } from "../../redux/epics/confirm-data";

const ValidateLegalRepModal = (props) => {
  const { fail } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const FailTextValidation = {
    1: t(
      "enrollment.organizationalStructure.listValidationsLegal.accionistListText"
    ),
    2: t(
      "enrollment.organizationalStructure.listValidationsLegal.rrhhListText"
    ),
    3: t(
      "enrollment.organizationalStructure.listValidationsLegal.experianListText"
    ),
    4: t("enrollment.organizationalStructure.partiallyDoesNotPassModal.text")
  };

  const handleCloseClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(getUserInfo());
  };

  return (
    <Container>
      <Image src="/assets/images/close.png" />
      <Heading fontWeight="bold" hieranchy="primary" color="brightTurquoise">
        {fail !== 4
          ? t("enrollment.organizationalStructure.listValidationsLegal.sorry")
          : t(
              "enrollment.organizationalStructure.partiallyDoesNotPassModal.title"
            )}
      </Heading>
      <ParagraphContainer>
        <Paragraph size="large" color="black">
          {FailTextValidation[fail]}
        </Paragraph>
      </ParagraphContainer>
      <Divider />
      <ButtonsContainer>
        <Button
          id="back-button"
          color="primary2"
          rounded={true}
          outlined={true}
          onClick={handleCloseClick}
        >
          {t(
            "enrollment.organizationalStructure.listValidationsLegal.continueButton"
          )}
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

ValidateLegalRepModal.propTypes = {
  fail: PropTypes.string.isRequired
};

export default ValidateLegalRepModal;
