import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import ConfirmDataPymeActions from "../reducers/confirm-data-pyme";
import LoginActions from "../reducers/login.reducer";
import ModalActions from "../reducers/modal.reducer";
import { ConfirmDataPymeSerializer } from "../../lib/serializers/confirmDataPyme.serializer";
import UserActions from "../reducers/user.reducer";
import User from "../../lib/models/user.model";
import { getErrorIndex } from "../../lib/utils";
import { ExperianPriorityCodes } from "../../lib/utils/constants.errors";
import paramNames from "../../lib/models/parameter.model";

const { HASHED_CODE_PARAMS } = paramNames;
const { _PARAM_002, _PARAM_003 } = HASHED_CODE_PARAMS();

export const paramsConfirmPyme = (action$) =>
  action$.pipe(
    ofType("GET_PARAMS_CONFIRM_PYME"),
    flatMap(() =>
      Observable.from(
        App.api.a2censo.getStaticParameters({
          url: { tables: `${_PARAM_003},${_PARAM_002}` }
        })
      ).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(ConfirmDataPymeActions.setParams(response))
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.of(
              ModalActions.setConditionalModalState(true, "ErrorModal", {
                title: "errorModal.title",
                content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                linkRedirectsTo: "/",
                buttonText: "errorModal.success"
              })
            );
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      )
    )
  );

export const confirmDataPyme = (action$) =>
  action$.pipe(
    ofType("SET_FORM_VALUES"),
    flatMap((values) =>
      Observable.from(
        App.api.a2censo.updateUsers({
          url: { userId: values.submitValues.id },
          body: ConfirmDataPymeSerializer().serialize(values.submitValues)
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(ConfirmDataPymeActions.setLoadingEnd()),
            Observable.of(ModalActions.setConditionalModalState(false)),
            Observable.of(
              ModalActions.setConditionalModalState(
                true,
                "ConfirmDataPymeSuccessModal",
                values.submitValues
              )
            ),
            Observable.of(UserActions.setUserState(User.state.selfAppraisal)),
            Observable.of(
              UserActions.setDataPyme({
                name: values.submitValues.name,
                ciiu: values.submitValues.ciiuMain,
                city: values.submitValues.city,
                department: values.submitValues.department,
                second_ciiu: values.submitValues.secondCiiu,
                address: values.submitValues.address
              })
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.concat(
              Observable.of(ConfirmDataPymeActions.setLoadingEnd()),
              Observable.of(ModalActions.setConditionalModalState(false)),
              Observable.of(LoginActions.loginReset())
            );
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1 && e.errors[hasErrorIndex].detail) {
            if (
              ExperianPriorityCodes.includes(e.errors[hasErrorIndex].detail)
            ) {
              return Observable.concat(
                Observable.of(ConfirmDataPymeActions.setLoadingEnd()),
                Observable.of(
                  ModalActions.setConditionalModalState(true, "ErrorModal", {
                    title: "errorModal.title",
                    content: `errorCodes.${e.errors[hasErrorIndex].detail}`,
                    linkRedirectsTo: "/",
                    buttonText: "errorModal.success"
                  })
                )
              );
            }
          }
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(ConfirmDataPymeActions.setLoadingEnd()),
              Observable.of(
                ModalActions.setConditionalModalState(
                  true,
                  "ValidationPymeModal",
                  {
                    code: e.errors[hasErrorIndex].code
                  }
                )
              )
            );
          }
          return Observable.concat(
            Observable.of(ConfirmDataPymeActions.setLoadingEnd()),
            Observable.of(ModalActions.setConditionalModalState(false)),
            Observable.of(
              ModalActions.setConditionalModalState(
                true,
                "ValidationPymeModal",
                {
                  code: "generic"
                }
              )
            )
          );
        })
      )
    )
  );

export default combineEpics(paramsConfirmPyme, confirmDataPyme);
