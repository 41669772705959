import React from "react";
import PropTypes from "prop-types";
import { Paragraph, Button } from "@bvcco/a2censo-component-lib";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import RoutesModel from "../../lib/models/routes.model";

import {
  Wrapper,
  ButtonsContainer,
  ButtonBackContainer,
  ButtonRightContainer,
  BackArrow,
  ImageContainer,
  TextContainer,
  BottomButtonsContainer
} from "./Styles";
import image from "../../assets/images/campaign-approved-img.png";
import arrowImg from "../../assets/images/arrow.svg";

const CampaignApproved = ({ campaignName, dataTestid }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const goToShowCase = RoutesModel.showCase;
  const goToDashboardCampaigns = RoutesModel.dashboardCampaigns;

  const onClickHandler = (goTo) => {
    dispatch(push(goTo));
  };

  return (
    <Wrapper data-testid={dataTestid}>
      <ButtonsContainer>
        <ButtonBackContainer>
          <Button
            rounded="true"
            onClick={() => {
              onClickHandler(goToDashboardCampaigns);
            }}
            id="back-button-campaign-approved"
          >
            <BackArrow src={arrowImg} />
            {t("companyCampaignInfo.backButton")}
          </Button>
        </ButtonBackContainer>
        <ButtonRightContainer>
          <Paragraph>{campaignName}</Paragraph>
        </ButtonRightContainer>
      </ButtonsContainer>
      <ImageContainer src={image} />
      <TextContainer>
        <Paragraph>{t("campaignApproved.congratulations")}</Paragraph>
        <Paragraph>{t("campaignApproved.infoText")}</Paragraph>
        <BottomButtonsContainer>
          <Button
            rounded="true"
            outlined={true}
            color="white"
            onClick={() => {
              onClickHandler(goToShowCase);
            }}
            id="show-case-campaign-approved"
          >
            {t("campaignApproved.showCaseButton")}
          </Button>
          <Button
            rounded="true"
            color="white"
            onClick={() => {
              onClickHandler(goToDashboardCampaigns);
            }}
            id="dashboard-campaigns-campaign-approved"
          >
            {t("campaignApproved.dashboardCampaignsButton")}
          </Button>
        </BottomButtonsContainer>
      </TextContainer>
    </Wrapper>
  );
};

CampaignApproved.propTypes = {
  campaignName: PropTypes.string,
  dataTestid: PropTypes.string.isRequired
};

CampaignApproved.defaultProps = {
  campaignName: "Nombre de la campaña"
};

export default CampaignApproved;
