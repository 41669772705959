import styled from "styled-components";

export const Text = styled.p`
  margin: 0px 109px;
  text-align: left;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily.primary};
  color: ${({ theme }) => theme.white};
  font-weight: 700;
  line-height: 24px;

  ${({ theme }) => theme.smartphoneMedium} {
    margin: 0px 24px;
  }
`;
