import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  startGetPatrimonialRightsReport: ["payload"],
  endGetPatrimonialRightsReport: ["payload"],
  resetPatrimonialRightsReport: ["payload"]
});

export const IssuerPaymentTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  payload: []
};

const endGetPatrimonialRightsReport = (state, { payload }) => {
  const patrimonialRightsReport = state.patrimonialRightsReport || {};
  const patrimonialList = patrimonialRightsReport.patrimonial_list || [];
  const newPayload = {
    ...payload,
    patrimonial_list: [...patrimonialList, ...payload.patrimonial_list]
  };
  return { ...state, patrimonialRightsReport: newPayload };
};

const resetPatrimonialRightsReport = (state) => ({
  ...state,
  patrimonialRightsReport: { patrimonial_list: [] }
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.END_GET_PATRIMONIAL_RIGHTS_REPORT]: endGetPatrimonialRightsReport,
  [Types.RESET_PATRIMONIAL_RIGHTS_REPORT]: resetPatrimonialRightsReport
});
