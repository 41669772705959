import React from "react";
import { ItemWrapper, Avatar, Description, Number } from "./components";

export type StepItemProps = {
  avatar: JSX.Element;
  step: number;
  description: JSX.Element | string | number;
  stepColor: string;
};

export const StepItem = ({
  avatar,
  step,
  description,
  stepColor
}: StepItemProps) => (
  <ItemWrapper data-testid={`step-${step}`}>
    <Avatar>{avatar}</Avatar>
    <Number color={stepColor}>{step}</Number>
    <Description>{description}</Description>
  </ItemWrapper>
);
