import styled from "styled-components";

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) =>
    theme ? theme.font.family.primary : "initial"};
  > div,
  h3 {
    padding: 2px;
  }
  h3 {
    margin-right: 13px;
  }
  ${({ theme }) => theme.breakpoints.smallSmartphone} {
    h3 {
      margin-right: 6px;
    }
  }
`;
