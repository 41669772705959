import styled from "styled-components";

export const Description = styled.div`
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 120.4%;
  color: ${({ theme }) => theme.acentuar};
`;
