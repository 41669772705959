import styled from "styled-components";

export const ContainerEmpty = styled.div`
  padding: 16px 71px 0px 74px;

  ${({ theme }) => theme.smartphone} {
    padding: 29px 25px 0px 27px;
  }
`;

export const Header = styled.div`
  display: flex;
  ${({ theme }) => theme.smartphone} {
    flex-direction: column;
  }

  button {
    width: 156px;
    height: 40px;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 800;
  }
  h1 {
    font-size: 18px;
    font-weight: 800;
    color: ${({ theme }) => theme.riverBed};
    line-height: 25px;
  }
`;

export const ContainerTitle = styled.div`
  margin-left: 16px;
  align-self: center;
  ${({ theme }) => theme.smartphone} {
    margin-top: 19px;
    margin-left: 0px;
  }
`;

export const Image = styled.img`
  width: 337px;
`;

export const ContainerInfo = styled.div`
  width: 100%;
  height: 80vh;
  display: grid;
  justify-content: center;
  align-content: center;
  ${({ theme }) => theme.smartphone} {
    height: 65vh;
  }
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.riverBed};
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  font-family: ${({ theme }) =>
    theme ? theme.font.family.primary : "initial"};
  line-height: 24.55px;
  > strong {
    font-weight: 800;
  }
`;
