import { result } from "lodash";
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getCampaingData: ["campaignId", "hasSession"],
  setCampaingData: ["campaingData", "moreDetail"],
  clearCampaingData: []
});

export const CampaingsTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  campaingData: [],
  moreDetail: [],
  isLoading: false  
};

const getCampaingData = (state: any) => ({
  ...state,
  isLoading: true
});
const setCampaingData = (state: any, { campaingData, moreDetail }: any) => ({
  ...state,
  isLoading: false,
  campaingData: campaingData,
  moreDetail: moreDetail
});

const clearCampaingData = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CAMPAING_DATA]: getCampaingData,
  [Types.SET_CAMPAING_DATA]: setCampaingData,
  [Types.CLEAR_CAMPAING_DATA]: clearCampaingData
});
