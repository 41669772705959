/* eslint-disable no-underscore-dangle */
import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setParams: ["payload"],
  getParamsConfirmPyme: [],
  setFormValues: ["submitValues"],
  setLoadingEnd: []
});

export const contactTypes = Types;
export default Creators;

const INITIAL_STATE = {
  ciiu: [],
  country: [],
  isLoading: false,
  submitValues: []
};

const setParams = (state, { payload }) => ({
  ...state,
  ciiu: payload._ciiu_code,
  country: payload._country
});

const setSubmitValues = (state, { submitValues }) => ({
  ...state,
  submitValues,
  isLoading: true
});

const setLoadingEnd = (state) => ({ ...state, isLoading: false });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_PARAMS]: setParams,
  [Types.SET_FORM_VALUES]: setSubmitValues,
  [Types.SET_LOADING_END]: setLoadingEnd
});
