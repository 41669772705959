import React from "react";
import { useTranslation } from "react-i18next";
import "./KeywordFilter.scss";

const KeywordFilter = ({ isDisabled, keywordFilterSearch }: any) => {
  const { t } = useTranslation();

  return (
    <div className="keywordFilter-container" >
      <input
        className={`${isDisabled ? "inactive_input_filter" : ""}`}
        disabled={isDisabled}
        id="invest-filter-search"
        type="text"
        placeholder={t("searchInvestDetail.keyWord")}
        data-testid="test-input-invest"
        onChange={keywordFilterSearch}
        maxLength={100}
      />
      <div className="input-icon">
        <div
          className={`image_icon ${isDisabled ? "image_icon_disabled" : ""}`}
        ></div>
      </div>
    </div>
  );
};

export default KeywordFilter;
