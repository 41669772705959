import { createReducer, createActions } from "reduxsauce";

export const { Types, Creators } = createActions({
  setData: ["payload"]
});

export const rechargeTypes = Types;
export default Creators;

const INITIAL_STATE = {
  data: {}
};

const setData = (state, { payload }) => ({
  ...state,
  data: { ...payload }
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_DATA]: setData
});
