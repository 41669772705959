/* eslint-disable prettier/prettier */
import React from "react";
import "./WithoutInvestments.scss";
import pig from "../../assets/images/pig.png";
import glass from "../../assets/images/glass.png";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { dispatch } from "rxjs/internal/observable/pairs";
import { Button } from "components/UserNotExistsModal/Styles";
// eslint-disable-next-line arrow-body-style
const WithoutInvestments = ({ isPig }) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="Container-holder">
        <div className="Place-holder">
          <div className="Img-Container">
            <img className="Place-holder-Image" src={isPig ? pig : glass} />
          </div>
          <div
            className={`${isPig ? "Place-holder-Message-Pig" : "Place-holder-Message-Glass"
              }`}
          >
            {isPig
              ? "Aún no has realizado ninguna inversión"
              : "No se encontraron inversiones que concidan en tu búsqueda,"}
          </div>
          <div
            className={`${isPig
                ? "Place-holder-Message2-Pig"
                : "Place-holder-Message2-Glass"
              }`}
          >
            {"te invitamos a ir a nuestra vitrina."}
          </div>
          <button
            data-testid="go-to-home"
            id="go-to-home"
            className="Place-holder-Link-Button"
            onClick={() => {
              dispatch(push("/"));
            }}
          >
            <p>Ir a vitrina</p>
          </button>
        </div>
      </div>
    </>
  );
};

export default WithoutInvestments;
