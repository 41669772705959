/* eslint-disable no-underscore-dangle */
import { Observable } from "rxjs-compat";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { flatMap, catchError, map } from "rxjs/operators";

import App from "../../lib/app";
import { EmailSerializer } from "../../lib/serializers/email.serializer";
import ModalActions from "../reducers/modal.reducer";
import contactFormActions from "../reducers/contact-form.reducer";
import { getErrorIndex } from "../../lib/utils";
import User from "../../lib/models/user.model";
import paramNames from "../../lib/models/parameter.model";

const { HASHED_CODE_PARAMS } = paramNames;
const { _PARAM_002 } = HASHED_CODE_PARAMS();

export const startContactFlow = ({
  name,
  email,
  description,
  pymeName,
  pymeContactName,
  pymeEmail,
  pymePhone,
  pymeCountry,
  pymeDescription,
  formType
}) => ({
  type: "START_CONTACT_FLOW",
  payload: {
    name,
    email,
    description,
    pymeName,
    pymeContactName,
    pymeEmail,
    pymePhone,
    pymeCountry,
    pymeDescription,
    formType
  }
});

export const loadContactForm = () => ({
  type: "LOAD_CONTACT_FORM"
});

export const loadContactFormRequest = (action$) =>
  action$.pipe(
    ofType("LOAD_CONTACT_FORM"),
    flatMap(() =>
      Observable.from(
        App.api.a2censo.getStaticParameters({
          url: { tables: `${_PARAM_002}` }
        })
      ).pipe(
        map((response) => contactFormActions.setCountry(response._country)),
        catchError((e) => {
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.of(
              ModalActions.setConditionalModalState(true, "ErrorModal", {
                title: "errorModal.title",
                content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                linkRedirectsTo: "/",
                buttonText: "errorModal.success"
              })
            );
          }
          return Observable.of(
            push("/"),
            ModalActions.setConditionalModalState(false)
          );
        })
      )
    )
  );

export const startContactFormFlow = (action$) =>
  action$.pipe(
    ofType("START_CONTACT_FLOW"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.sendEmail({
          body: EmailSerializer().serialize({
            full_name: action.payload.name,
            email: action.payload.email,
            message: action.payload.description,
            pymeName: action.payload.pymeName,
            pymeContactName: action.payload.pymeContactName,
            pymeEmail: action.payload.pymeEmail,
            pymeCountry: action.payload.pymeCountry,
            pymePhone: action.payload.pymePhone,
            pymeDescription: action.payload.pymeDescription,
            formType: action.payload.formType,
            reasonId:
              action.payload.formType === User.type.pyme
                ? User.reasonType.financing
                : User.reasonType.invest
          })
        })
      ).pipe(
        flatMap(() =>
          Observable.of(
            ModalActions.setConditionalModalState(true, "ContactFormSuccess")
          )
        ),
        catchError(() =>
          Observable.of(push("/"), ModalActions.setConditionalModalState(false))
        )
      )
    )
  );

export default combineEpics(startContactFormFlow, loadContactFormRequest);
