/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { push } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import User from "../../lib/models/user.model";
import {
  Container,
  Paragraph,
  Title,
  CloseButton,
  Icon,
  Anchor
} from "../CancellationModal/Styles";
import ModalActions from "../../redux/reducers/modal.reducer";

const CancellationModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const personType = useSelector(
    (state) => state.user.userInfo.user.person_type
  );
  const name =
    personType === User.type.natural
      ? useSelector(
          (state) =>
            `${state.user.userInfo.user.name} ${state.user.userInfo.user.last_name}`
        )
      : useSelector((state) => state.user.userInfo.user.name);
  return (
    <Container>
      <CloseButton
        id="back-button"
        onClick={() => {
          dispatch(ModalActions.setConditionalModalState(false));
          dispatch(push("/my-balance"));
        }}
      >
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <Title>{`${name}${t("cancellationModalSuccess.title")}`}</Title>
      <Paragraph>
        {`${t("cancellationModalSuccess.text")}`}
        <Anchor
          id="continue-button"
          onClick={() => {
            dispatch(ModalActions.setConditionalModalState(false));
            dispatch(push("/my-balance"));
          }}
        >
          {t("cancellationModalSuccess.link")}
        </Anchor>
      </Paragraph>
    </Container>
  );
};

export default CancellationModal;
