import React from "react";
import {
  Container,
  ContainerInformation,
  ContainerDescription,
  ImgSave,
  ContainerUpload,
  DownloadOnlyContainer
} from "./components";
import { Paragraph, Button } from "@bvcco/a2censo-component-lib";
import IconSave from "assets/images/save.png";

type Props = {
  title?: string;
  description?: string;
  onClickDownload?: () => void;
  labelDownload?: string;
  downloadId?: string;
  children?: JSX.Element;
  downloadOnly?: boolean;
  borderBottom?: boolean;
  className?: string;
  dataTestId?: string;
};

export const BlockFile = ({
  title,
  description = "",
  onClickDownload,
  labelDownload = "",
  downloadId = "download-document",
  children,
  downloadOnly,
  borderBottom = true,
  className,
  dataTestId
}: Props & React.HTMLProps<HTMLDivElement>) => (
  <Container
    borderBottom={borderBottom}
    className={className}
    data-testid={dataTestId}
  >
    <ContainerInformation
      hasChildren={!!children}
      downloadOnly={!!downloadOnly}
    >
      <Paragraph
        color="cornflowerBlue2"
        size="medium"
        dataTestId={`${dataTestId}-title`}
      >
        {title}
      </Paragraph>
      <ContainerDescription hasButton={!!onClickDownload && !downloadOnly}>
        {!!onClickDownload && !downloadOnly && (
          <Button
            id={downloadId}
            color="fiord"
            outlined={true}
            onClick={onClickDownload}
            icon={{
              icon: <ImgSave src={IconSave} alt="save" />,
              position: "right"
            }}
          >
            {labelDownload}
          </Button>
        )}
        <Paragraph
          color="stormGray"
          size="medium"
          dataTestId={`${dataTestId}-description`}
        >
          {description}
        </Paragraph>
      </ContainerDescription>
    </ContainerInformation>
    {downloadOnly ? (
      <DownloadOnlyContainer>
        <Button
          id={downloadId}
          color="fiord"
          outlined={true}
          onClick={onClickDownload}
          dataTestId={`${dataTestId}-button`}
          icon={{
            icon: <ImgSave src={IconSave} alt="save" />,
            position: "right"
          }}
        >
          {labelDownload}
        </Button>
      </DownloadOnlyContainer>
    ) : (
      <ContainerUpload>{children}</ContainerUpload>
    )}
  </Container>
);
