import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { Heading, Paragraph, Button } from "@bvcco/a2censo-component-lib";
import { Container, CloseButton, Icon } from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";

const PartiallyDoesNotPassModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(push("/"));
  };

  return (
    <Container>
      <CloseButton id="close-button" onClick={handleClick}>
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <Heading align="center">
        {t(
          "enrollment.organizationalStructure.partiallyDoesNotPassModal.title"
        )}
      </Heading>
      <Paragraph align="center">
        {t("enrollment.organizationalStructure.partiallyDoesNotPassModal.text")}
      </Paragraph>

      <Button
        id="continue-button"
        dataTestid="continue-button"
        onClick={handleClick}
        fontWeight="bold"
        rounded={true}
      >
        {t(
          "enrollment.organizationalStructure.partiallyDoesNotPassModal.continueButton"
        )}
      </Button>
    </Container>
  );
};

export default PartiallyDoesNotPassModal;
