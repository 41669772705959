import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setQuestionsPyme: ["questions"],
  getQuestionsPyme: [],
  validateSelfAppraisal: ["payload"],
  validateSelfAppraisalEnd: []
});

export const selfAppraisalTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  questions: [],
  isLoading: false
};

const setQuestionsPYME = (state, { questions }) => ({ ...state, questions });

const setLoading = (state) => ({ ...state, isLoading: true });

const setLoadingEnd = (state) => ({ ...state, isLoading: false });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_QUESTIONS_PYME]: setQuestionsPYME,
  [Types.VALIDATE_SELF_APPRAISAL]: setLoading,
  [Types.VALIDATE_SELF_APPRAISAL_END]: setLoadingEnd
});
