import styled from "styled-components";

export const AvatarContainer = styled.div`
  width: 234px;
  height: 189px;
  display: flex;
  margin-left: auto; 
  margin: 0 auto;
  justify-content: center;

`;

export const Avatar = styled.img`
  width: 234px;
  height: 189px;
  ${({ theme }) => theme.smartphoneMedium} {
    width: 214px;
    height: 159px;
    margin-top: 3px;
  }
`;
