import { catchError, mergeMap } from "rxjs/operators";
import { push } from "connected-react-router";
import { ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import LoginActions from "../reducers/login.reducer";
import { startGetPoliticsTermsHC } from "./politics-terms-hc";
import ModalActions from "../reducers/modal.reducer";
import CampaignsActions from "../reducers/campaings.reducer";
import theme from "../../styles/LightTheme";
import RequestActions from "../reducers/request.reducer";
import AnalyticsActions from "../reducers/analytics.reducer";
import AnalyticsModel from "../../lib/models/analytics.model";
import InvestingCampaignsActions from "../reducers/investing-campaigns.reducer";
import { getFiltersInvestingCampaings } from "./investingCampaigns";
import { isMobile } from "react-device-detect";
import { convertSnakeToCamel, ShowcaseConnect } from "services";

const Errors = ["036"];

export const startCampaignDetail = (campaignId) => ({
  type: "START_CAMPAIGN_DETAIL",
  payload: { campaignId }
});

export const startCampaignShowcase = () => ({
  type: "START_CAMPAIGN_SHOWCASE"
});

export const startFeaturedCampaignList = () => ({
  type: "START_FEATURED_CAMPAIGN_LIST"
});

export const campaignDataFail = ({ error }) => ({
  type: "CAMPAIGN_DATA_FAIL",
  payload: {
    error
  }
});

export const startSimulatorModal = ({ campaign }) => ({
  type: "START_CAMPAIGN_SIMULATOR",
  payload: {
    campaign
  }
});

export const startAllCampaignsBySections = () => ({
  type: "START_ALL_CAMPAIGNS_BY_SECTIONS",
  payload: {}
});

const getErrorIndex = (e) => {
  let hasErrorIndex = -1;
  if (Array.isArray(e.errors)) {
    e.errors.map((error, indexError) => {
      const index = Errors.findIndex((value) => error.code === value);
      if (index >= 0) {
        hasErrorIndex = indexError;
      }
      return error;
    });
  }
  return hasErrorIndex;
};

export const campaignShowcase = (action$) =>
  action$.pipe(
    ofType("START_CAMPAIGN_SHOWCASE"),
    mergeMap(() =>
      Observable.from(App.api.a2censo.getCampaignList()).pipe(
        mergeMap((response) =>
          Observable.concat(
            Observable.of(startGetPoliticsTermsHC()),
            Observable.of(
              push("/vitrina-invertir-dinero-en-colombia-crowdfunding", {
                response
              })
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(campaignDataFail(e)));
        })
      )
    )
  );

export const campaignDetail = (action$) =>
  action$.pipe(
    ofType("START_CAMPAIGN_DETAIL"),
    mergeMap((action) => {
      const url = {
        id: action.campaignId,
        emailView: action.emailView || false
      };
      return Observable.from(
        App.api.a2censo
          .getCampaign({
            url
          })
          .then((r) => {
            const camel = convertSnakeToCamel(r);
            return Object.assign(
              r,
              {
                id: camel.campaignId,
                name: camel.campaignName
              },
              camel
            );
          })
      ).pipe(
        mergeMap((response) =>
          Observable.concat(
            Observable.of(startGetPoliticsTermsHC()),
            Observable.of(CampaignsActions.endCampaignDetail()),
            Observable.of(RequestActions.setRequestStatus(2)),
            Observable.of(
              push(`/campaign/${action.campaignId}`, {
                response
              })
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/"
                })
              ),
              Observable.of(campaignDataFail(e))
            );
          }
          return Observable.concat(
            Observable.of(campaignDataFail(e)),
            Observable.of(CampaignsActions.endCampaignDetail()),
            Observable.of(
              push(`/campaign/${action.campaignId}`, {
                response: {},
                error: e,
                campaignId: action.campaignId
              })
            )
          );
        })
      );
    })
  );

export const featuredCampaignList = (action$) =>
  action$.pipe(
    ofType("START_FEATURED_CAMPAIGN_LIST"),
    mergeMap(() =>
      Observable.from(App.api.a2censo.getFeaturedCampaignList()).pipe(
        mergeMap((response) =>
          Observable.concat(
            Observable.of(startGetPoliticsTermsHC()),
            Observable.of(
              push("/", {
                campaigns: response
              })
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(
            Observable.of(campaignDataFail(e)),
            Observable.of(push("/oops"))
          );
        })
      )
    )
  );

export const campaignSimulatorFlow = (action$) =>
  action$.pipe(
    ofType("START_CAMPAIGN_SIMULATOR"),
    mergeMap((action) => {
      const { campaign } = action.payload;
      const simultorTracker = Observable.of(
        AnalyticsActions.trackEvent(
          AnalyticsModel.simulatorFeatureShowcase(campaign.campaign_id)
        )
      );
      if (
        campaign.campaign_simulator === null ||
        campaign.campaign_simulator === 0
      ) {
        return Observable.concat(
          Observable.of(
            ModalActions.setConditionalModalState(
              true,
              "SimulatorNotAvailableModal",
              {
                campaign
              }
            )
          ),
          simultorTracker
        );
      }
      return Observable.concat(
        Observable.of(
          ModalActions.setConditionalModalState(true, "Simulator", {
            campaign,
            isFromSimulatorCampaignList: true,
            clearBlur: true,
            css: {
              backgroundColor: theme.transparentMartinique
            }
          })
        ),
        simultorTracker
      );
    }),
    catchError(() =>
      Observable.of(
        ModalActions.setConditionalModalState(
          true,
          "SimulatorNotAvailableModal"
        )
      )
    )
  );

export const getCampaignsSuccessfull = (action$) =>
  action$.pipe(
    ofType("GET_CAMPAIGN_SUCCESSFULL"),
    mergeMap((action) =>
      Observable.from(new ShowcaseConnect().getAwardedCampaigns())
        .delay(2000)
        .pipe(
          mergeMap((response) =>
            Observable.concat(
              Observable.of(
                CampaignsActions.setSuccessFull(
                  Array.isArray(response) ? response : [],
                  action.payload.isStart
                )
              )
            )
          ),
          catchError((e) => {
            if (e.statusCode === 401) {
              return Observable.concat(
                Observable.of(CampaignsActions.setSuccessFullCampaignError(e)),
                Observable.of(LoginActions.loginReset())
              );
            }
            return Observable.concat(
              Observable.of(campaignDataFail(e)),
              Observable.of(CampaignsActions.setSuccessFullCampaignError(e))
            );
          })
        )
    )
  );

export const getCampaignsFeatured = (action$) =>
  action$.pipe(
    ofType("GET_CAMPAIGN_FEATURED"),
    mergeMap((action) => {
      const payload = {
        page: action.payload && action.payload.page ? action.payload.page : 1,
        limit: action.payload && action.payload.size ? action.payload.limit : 5
      };
      return Observable.from(
        new ShowcaseConnect().getCampaigns({
          page: payload.page,
          limit: payload.limit,
          filters: "filter=featured"
        })
      )
        .delay(2000)
        .pipe(
          mergeMap(({ campaigns, total, currentPage }) =>
            Observable.concat(
              Observable.of(
                CampaignsActions.setFeatured(
                  campaigns.map((campaign) => ({
                    ...campaign,
                    total,
                    currentPage
                  })),
                  action.payload.isStart
                )
              )
            )
          ),
          catchError((e) => {
            if (e?.statusCode === 401) {
              return Observable.concat(
                Observable.of(LoginActions.loginReset()),
                Observable.of(CampaignsActions.setFeaturedCampaignError(e))
              );
            }
            return Observable.of(CampaignsActions.setFeaturedCampaignError(e));
          })
        );
    })
  );

const clearFilters = (filters) => {
  filters.forEach((item) => {
    item.options.forEach((option) => {
      const filter = option;
      delete filter.checked;
    });
  });
  return filters;
};

export const getAllCampaignsBySections = (action$, state) =>
  action$.pipe(
    ofType("START_ALL_CAMPAIGNS_BY_SECTIONS"),
    mergeMap(() => {
      const itemsPerPage = isMobile ? 3 : 9;
      const payloadCampaings = {
        page: 1,
        limit: itemsPerPage,
        filters: ""
      };

      return Observable.concat(
        Observable.of(
          InvestingCampaignsActions.setFiltersCampaings(
            clearFilters(state.value.investingCampaigns.filters)
          )
        ),
        Observable.of(
          InvestingCampaignsActions.startInvestingCampaigns({
            ...payloadCampaings,
            filters: "",
            isStart: true
          })
        ),
        Observable.of(getFiltersInvestingCampaings()),
        Observable.of(
          CampaignsActions.getCampaignSuccessfull({ page: 1, isStart: true })
        ),
        Observable.of(
          CampaignsActions.getCampaignFeatured({ page: 1, isStart: true })
        )
      );
    })
  );

export default campaignDetail;
