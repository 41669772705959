import styled from "styled-components";

type Props = {
  backgroundColor?: string;
};

export const CardWrapper = styled.div<Props>`
  display: grid;
  grid-template-columns: 14% 86%;
  min-width: 293.25px;
  height: 175px;
  padding: 20px 25px;
  border-radius: 8px;
  background: url("/assets/images/bg-campaign-card.png"),
    ${({ theme }) => theme.white};
  background-size: 180px;
  background-repeat: no-repeat;
  background-position: -9px 113px;
  box-shadow: 0px 14px 47px -28px rgba(0, 0, 0, 0.25);
  font-family: ${({ theme }) =>
    theme ? theme.font.family.primary : "initial"};
  ${({ theme }) => theme.breakpoints.mobile} {
    max-width: 100%;
    width: 100%;
  }
`;
