import styled from "styled-components";

export const Wrapper = styled.div`
  width: 754px;
  max-width: 90%;
  ${(props) => props.theme.tablet} {
    width: 605px;
  }
  position: fixed;
`;

export const Paragraph = styled.p`
  margin: 20px 0;
  padding: 0 40px;
  font-family: Roboto;
  text-align: justify;
  white-space: pre-line;
  font-size: 16px;
  line-height: 18px;
  ${(props) => props.theme.smartphone} {
    padding: 0 15px;
  }
`;

export const SuperHeader = styled.div`
  color: ${(props) => props.theme.white};
  margin-bottom: 19px;
  display: flex;
  justify-content: space-between;
  & > h2 {
    font-size: 20px;
  }
`;

export const CloseButton = styled.img`
  width: 29px;
  height: 29px;
  cursor: pointer;
`;

export const Container = styled.div`
  background-color: ${(props) => props.theme.lightGray};
  border-radius: 10px;
  position: relative;
  padding: 30px 36px;
  height: 541px;
`;

export const ButtonContainer = styled.div`
  background-color: ${(props) => props.theme.lightGray};
  border-radius: 0px 0px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 70px;
  border-top: 1px solid ${(props) => props.theme.alto};
  position: absolute;
  bottom: 0;
  left: 0;
  button {
    width: 124px;
    height: 40px;
    font-weight: 800;
  }
`;

export const ContainerTerms = styled.div`
  border-radius: 10px;
  position: relative;
  height: 491px;
  padding-bottom: 80px;
  overflow-y: auto;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #475061;
`;
