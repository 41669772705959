import environment from "environment";
import { Connect } from "./connect";

type IGetNovelties = {
  page: number;
  pageSize: number;
};

export class NoveltyConnect extends Connect {
  constructor() {
    const { apiKey, apiShowcaseUrl, apiShowcasePrefix } = environment;
    super(apiShowcaseUrl, apiShowcasePrefix, { apiKey });
  }

  async getAll({ page, pageSize }: IGetNovelties): Promise<any> {
    const response: any = await this.request({
      method: "get",
      service: "novelties",
      params: { page, pageSize }
    });

    return response.data;
  }
}
