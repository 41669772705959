import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import ModalActions from "../reducers/modal.reducer";
import ParametersActions, {
  ParametersTypes
} from "../reducers/parameters.reducer";
import { getErrorIndex } from "../../lib/utils";
import paramNames from "../../lib/models/parameter.model";

const { HASHED_CODE_PARAMS } = paramNames;
const { _PARAM_004 } = HASHED_CODE_PARAMS();

export const getAllParameters = (action$) =>
  action$.pipe(
    ofType(ParametersTypes.GET_ALL_PARAMETERS),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.getStaticParameters({
          url: { tables: `${_PARAM_004}` }
        })
      ).pipe(
        flatMap(({ _parameter: data }) => {
          if (action.search) {
            const searchByParamName = data.find(
              (param) => param.name === action.search
            );
            return Observable.of(
              ParametersActions.setParameter(searchByParamName)
            );
          }
          return Observable.of(ParametersActions.setAllParameters(data));
        }),
        catchError((e) => {
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.of(
              ModalActions.setConditionalModalState(true, "ErrorModal", {
                title: "errorModal.title",
                content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                linkRedirectsTo: "/",
                buttonText: "errorModal.success"
              })
            );
          }
          return Observable.of(
            push("/oops", {
              hideButton: true
            })
          );
        })
      )
    )
  );

export const getListParameters = (action$) =>
  action$.pipe(
    ofType(ParametersTypes.FETCH_LIST_PARAMETERS),
    flatMap((action) => {
      const { tables = [], query = "" } = action;

      return Observable.from(
        App.api.a2censo.getStaticParameters({
          url: { tables: `${tables.join(",")}${query}` }
        })
      ).pipe(
        flatMap((response) =>
          Observable.of(ParametersActions.setListParameters(response))
        ),
        catchError((e) => {
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.of(
              ModalActions.setConditionalModalState(true, "ErrorModal", {
                title: "errorModal.title",
                content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                linkRedirectsTo: "/",
                buttonText: "errorModal.success"
              })
            );
          }
          return Observable.of(
            push("/oops", {
              hideButton: true
            })
          );
        })
      );
    })
  );

export default combineEpics(getAllParameters, getListParameters);
