import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ContainerEmpty,
  HeaderContainer,
  Header,
  ContainerTitle,
  ContainerInfo
} from "./Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Heading,
  Button,
  Paragraph,
  Loader
} from "@bvcco/a2censo-component-lib";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import DataUpdateForm, { DataUpdateFormValues } from "./DataUpdateForm";
import ParameterActions from "redux/reducers/parameters.reducer";
import paramNames from "lib/models/parameter.model";
import { getAllCompanyInfo, updateCompanyCities } from "redux/epics/company";
import CompanyActions from "redux/reducers/company.reducer";

const {
  HASHED_CODE_PARAMS,
  COUNTRY_ID: { COL }
} = paramNames;

const { _PARAM_066, _PARAM_067, _PARAM_019 }: any = HASHED_CODE_PARAMS();

const mapParameterDropdown = (parameters: any = []) =>
  parameters.map((parameter: any) => ({
    ...parameter,
    value: `${parameter.name}${
      parameter.department_name ? ` (${parameter.department_name})` : ""
    }`
  }));

export const DataUpdateContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    listParameters: {
      _city_consignment: citiesConsignment,
      _city_retention: citiesRetention,
      _city: cities
    } = {
      _city_consignment: [],
      _city_retention: [],
      _city: []
    }
  } = useSelector(({ parameters }: any) => parameters);
  const {
    companyCommercialAddress,
    cityConsignment,
    cityId,
    cityRetention,
    loading,
    isGettingInfo
  } = useSelector(({ company }: any) => company);

  const listCityConsignment = mapParameterDropdown(citiesConsignment);
  const listCityRetention = mapParameterDropdown(citiesRetention);
  const listCities = mapParameterDropdown(cities);

  useEffect(() => {
    dispatch(
      ParameterActions.fetchListParameters(
        [_PARAM_019],
        `&citiesByCountryId=${COL}`
      )
    );
    dispatch(ParameterActions.fetchListParameters([_PARAM_066, _PARAM_067]));
    dispatch(getAllCompanyInfo());
    dispatch(CompanyActions.onGetInfo());
  }, []);

  const onSave = (values: DataUpdateFormValues) => {
    dispatch(updateCompanyCities(values));
    dispatch(CompanyActions.onFetch());
  };

  const onBack = () => {
    dispatch(push("/my-certificates"));
  };

  if (isGettingInfo) {
    return <Loader />;
  }

  return (
    <ContainerEmpty data-testid={"empty-container"}>
      <HeaderContainer>
        <Header>
          <Button
            dataTestid={"go-back-button"}
            onClick={onBack}
            color="whiteManatee"
            rounded={true}
            icon={{
              icon: <FontAwesomeIcon icon={faChevronLeft} />,
              position: "left"
            }}
          >
            {t("myCertificates.emptyCertificates.backButton")}
          </Button>
          <ContainerTitle>
            <Heading>{t("myCertificates.pyme.edit.updateData")}</Heading>
            <Paragraph>
              {t("myCertificates.pyme.edit.updateDataDescription")}
            </Paragraph>
          </ContainerTitle>
        </Header>
      </HeaderContainer>
      <ContainerInfo>
        <DataUpdateForm
          onSave={onSave}
          listCityConsignment={listCityConsignment}
          listCityRetention={listCityRetention}
          cities={listCities}
          initialValues={{
            city: cityId,
            cityConsignment,
            cityRetention,
            commercialAddress: companyCommercialAddress
          }}
          loading={loading}
        />
      </ContainerInfo>
    </ContainerEmpty>
  );
};
