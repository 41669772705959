import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  background: ${props => props.theme.white};
  padding-bottom: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
