import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100vw;
  padding: 50px 0;
  background-color: ${({ theme }) => theme.whisper};
  > .dots {
    width: 70%;
    height: 12px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.tablet} {
    > .dots {
      display: none;
    }
  }
`;

export const StyledListContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.desktop} {
    width: 80%;
  }
  ${({ theme }) => theme.tablet} {
    display: block;
    width: 90%;
  }
`;

export const StyledAllie = styled.div`
  padding: 30px 20px;
  width: calc(100% / 3);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > p {
    &.iadb {
      width: 232px;
    }
    &.mincit {
      width: 282px;
    }
    &.caf {
      width: 274px;
    }
    &.fng {
      width: 186px;
    }
    &.bancoldex {
      width: 216px;
    }

    font-size: 14px;
    line-height: 19px;
  }
  ${({ theme }) => theme.tablet} {
    width: 100%;
    > p {
      display: none;
    }
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  max-width: 150px;
`;
