import styled, { css } from "styled-components";

type IProps = {
  isOpen: boolean;
  value: any;
  name: any;
  disabled?: boolean;
};

export const DropDownHeader = styled.div<IProps>`
  display: flex;
  position: relative;
  cursor: pointer;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding: 3px;
  height: 41px;
  padding-left: 16px;
  font-style: normal;
  font-weight: 500;
  align-items: center;
  font-size: 14px;
  line-height: 147%;
  ${({ isOpen, theme }) => css`
    background: ${theme.white};
    border-radius: ${!isOpen ? "5px" : "5px 5px 0 0"};
    font-family: ${theme.fontFamily.primary};
    color: ${theme.GunPowder};
  `};
  background-color: ${({
    theme: {
      colors: { concrete },
      white
    },
    disabled
  }) => (disabled ? concrete.color : white)};

  pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};

  & > img {
    opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  }
`;
