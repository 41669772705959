import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Container, Image, Title, Subtitle, Button } from "./Styles";
import { push } from "connected-react-router";

const UpdateInfoSuccessModal = () => {
  const { t: translate } = useTranslation();
  const t = (text: string) => translate(`UpdateInfoSuccessModal.${text}`);
  const dispatch = useDispatch();

  const handleReturn = () => {
    dispatch(push("/my-balance"));
  };

  return (
    <Container>
      <Title>{t("title")}</Title>
      <Subtitle
        data-testid="generic-success-modal-content"
        id="generic-success-modal-content"
      >
        {t("subtitle")}
        <span>{t("subtitleBold")}</span>
      </Subtitle>
      <Image src="/assets/images/approved.png" />

      <Button
        data-testid="info-success-modal-return"
        id="info-success-modal-return"
        onClick={handleReturn}
      >
        {t("buttonText")}
      </Button>
    </Container>
  );
};

export default UpdateInfoSuccessModal;
