import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setLoadingAssociateDataForm: ["isLoading"]
});

export const AccountRecoveryTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  isLoading: false
};

const setLoadingAssociateDataForm = (state, { isLoading }) => ({
  ...state,
  isLoading
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_LOADING_ASSOCIATE_DATA_FORM]: setLoadingAssociateDataForm
});
