import styled from "styled-components";
import ReactTooltip from "react-tooltip";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const OptionsContainer = styled.div`
  background: ${(props) => props.theme.white};
  ${(props) => (props.isPep ? (props.questionVal) ? "" : "border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;" :  "border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;")};
`;

export const Question = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: ${(props) => props.theme.white};
  border-bottom: ${(props) => `1px solid ${props.theme.lineGray}`};
`;

export const QuestionText = styled.div`
  font-weight: ${(props) => (props.isSummary ? "700" : "bold" )};
  font-family: ${(props) => (props.isSummary ? "Nunito;" : "Roboto" )};
  color:${(props) => (props.isSummary ? props.theme.mako : props.theme.tuatara )};
  font-size: 20px;
  margin: ${(props) => (props.isSummary ? "14px 15px;" : "20px 30px" )};

  ${({ theme }) => theme.smartphone} {
    margin: 10px 15px;
    font-size: 13px;
    max-width: 90%;
  }
`;

export const QuestionOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: ${(props) => (props.isSummary ? "15px;" : "15px;" )};
  width: ${(props) => (props.isSummary ? "190px" : "20%" )};
  padding-top: ${(props) => (props.isSummary ? "16px;" : "10px;" )};
  padding-bottom: ${(props) => (props.isSummary ? "8px;" : "15px;" )}; 
   ${(props) => (props.isSummary ? "" : "padding-left: 15px;" )}; 
  ${({ theme }) => theme.smartphone} {
    width: ${(props) => (props.isSummary ? "60%;" : "20%;" )};
  }
`;

export const SubQuestions = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const Lines = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => (props.isSummary ? "24px;" : "75px" )};
  margin-left: ${(props) => (props.isSummary ? "0px;" : "40px" )};
`;

export const ShortLine = styled.div`
  height: 25px;
  width: 1px;
  border: 1px dashed ${(props) => props.theme.brightTurquoiseTransparent};
`;

export const LongLine = styled.div`
  height: 70%;
  width: 1px;
  border: 1px dashed ${(props) => props.theme.brightTurquoiseTransparent};
`;

export const LineCircle = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: ${(props) => props.theme.brightTurquoiseTransparent};
`;

export const SubQuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background: ${(props) => (props.isSummary ? props.isPep ? "background: 0 0 no-repeat;" : props.theme.white : props.theme.gray )};
 
   ${(props) => (props.isSummary ? `border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-bottom: 4px;
  margin-bottom: -4px;` : "")};
`;

export const SubQuestion = styled.div`
  width: 100%;
`;

export const SubQuestionText = styled.span`
  display: block;
  font-family: ${(props) => (props.isSummary ? "Nunito;" : "Roboto" )};
  color:${(props) => (props.isSummary ? props.theme.mako : props.theme.tuatara )};
  font-size: 18px;
  margin: ${(props) => (props.isSummary ? "20px 24px 0px 24px;" : "20px 30px;" )}; 
  ${({ theme }) => theme.smartphone} {
    font-size: 13px;
  }
`;

export const AddNewFamilyMemberButton = styled.div`
  background-color: ${(props) => props.theme.gray};
  color: ${(props) => props.theme.brightTurquoise};
  border: none;
  font-family: "Nunito";
  font-size: 20px;
  outline: none;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.tooltipWidth || "372px"};
`;

export const TooltipTitle = styled.div`
  color: ${(props) => props.theme.brightTurquoise};
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
`;

export const Tooltip = styled(ReactTooltip)`
  box-shadow: 0 6px 12px 0 rgba(44, 50, 65, 0.1);
`;
