import styled from "styled-components";

type Props = {
  borderBottom: boolean;
};

export const Container = styled.div<Props>`
  padding: 16px 0px;
  width: auto;
  display: flex;
  border-bottom: ${({ borderBottom, theme }) =>
    borderBottom ? `1px solid ${theme.alto}` : ""};
  justify-content: space-between;
  align-items: center;
`;
