import styled from "styled-components";

export const Footer = styled.div`
  background-color: ${(props) => props.theme.brightTurquoise};
  bottom: 0;
  height: 48px;
  width: 100%;
`;

export const FooterContent = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  color: white;
`;

export const PaginationContainer = styled.div`
  display: flex;
  margin-top: 36px;
  width: 100%;
`;
