import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  width: 790px;
  max-width: 90%;
  ${(props) => props.theme.tablet} {
    width: 605px;
  }
`;

export const Card = styled.div`
  background-color: ${(props) => props.theme.lightGray};
  border-radius: 10px;
  position: relative;
`;

export const SuperHeader = styled.div`
  color: ${(props) => props.theme.white};
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
  ${(props) => props.theme.tablet} {
    margin-bottom: 34px;
    & > h2 {
      font-size: ${(props) => props.theme.font.size.large};
    }
  }
`;

export const CloseButton = styled.img`
  width: 29px;
  height: 29px;
  cursor: pointer;
`;

export const HeaderContainer = styled.div`
  position: relative;
  ${(props) => props.theme.tablet} {
    height: 1px;
  }
`;

export const Header = styled.div`
  height: 64px;
  width: 100%;
  background-color: ${(props) => props.theme.brightTurquoise};
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  padding: 31px;

  ${(props) => props.theme.tablet} {
    display: none;
  }
`;

export const HeaderMobile = styled.div`
  display: none;
  font-family: Nunito;
  background-color: ${(props) => props.theme.brightTurquoise};
  align-items: center;
  color: white;
  font-weight: bold;

  ${(props) => props.theme.tablet} {
    height: auto;
    display: flex;
    border-radius: 5px;
    width: auto;
    padding: 5px 10px 5px 5px;
    position: absolute;
    margin-top: -10px;
    margin-left: 16px;
    & > h4 {
      font-size: 14px;
    }
  }
`;

export const Body = styled.div`
  height: 400px;
  display: block;
  overflow: auto;
  margin: 31px;
  padding-bottom: 25px;
  ${(props) => props.theme.tablet} {
    margin: 31px 16px;
  }

  ${(props) => props.theme.desktopMinHeight800px} {
    height: 600px;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EmptyImage = styled.img`
  width: 400px;
  ${({ theme }) => theme.smartphone} {
    width: 380px;
  }
`;
