import React from "react";
import { useTranslation } from "react-i18next";
import "./InfoBanner.scss";
import InfoBannerIcon from "assets/images/icon-info-banner.png";

const InfoBanner = () => {
  const { t } = useTranslation();
  return (
    <div className="info_banner__container">
      <div className="info_banner">
        <div className= "info_banner__content">
          <img  className= "info_banner__icon" src={InfoBannerIcon}/>
          <span className= "info_banner__text_content">
            <p className= "info_banner__question">
              {t("campaignDetailInfoBanner.question")}
            </p>
            <p className= "info_banner__text">
              {t("campaignDetailInfoBanner.text")}
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};
  
export default InfoBanner;