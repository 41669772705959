/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import {
  Investments,
  HelpIcon,
  InvestmentsPageTitle,
  SubtitleContainer,
  PageSubtitle,
  TooltipText,
  MainContainer,
  Footer,
  FooterContent,
  ButtonFooter,
  Container,
  StyledTooltips,
  SearchContainer,
  PreFooter
} from "./Styles";
import MoldalActions from "../../redux/reducers/modal.reducer";
import MyBalance from "../../components/MyBalance/MyBalance";
import InvestmentActions from "../../redux/reducers/investment.reducer";
import InvestListAccordion from "../../components/InvestListAccordion/InvestListAccordion";
import InvestFilterDetail from "../../components/InvestFilterDetail/InvestFilterDetail";
import InvestmentsPaginator from "../../components/InvestmentsPaginator/InvestmentsPaginator";
import WithoutInvestments from "components/WithoutInvestments/WithoutInvestments";
import theme from "styles/LightTheme";
import "./InvestmentList.scss";

export const InvestmentList = () => {
  const { t } = useTranslation();
  const myBalance = useSelector(({ balance }) => balance);
  const [numberPage, setNumberPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [valueSelected, setValueSelected] = useState(1);
  const [nameFilter, setNameFilter] = useState("");
  const [businessLineFilter, setBusinessLineFilter] = useState(999);
  const [investmentStateFilter, setInvestmentStateFilter] = useState("");
  const [campaignStateFilter, setcampaignStateFilter] = useState("");
  const [isFilterBy, setisFilterBy] = useState(false);
  const [userIp, setUserIp] = useState("");
  const { investmentsList } = useSelector(({ investment }) => investment);
  const userInfo = useSelector(({ user }) => user.userInfo.user);
  const dispatch = useDispatch();
  const goToMovements = () => {
    dispatch(push("/my-movements"));
  };

  const modalGeneral = {
    clearBlur: true,
    css: {
      backgroundColor: theme.transparentMartinique
    }
  };

  useEffect(() => {
    const fetchIp = async () => {
      let res = await fetch("https://api.ipify.org?format=json", { method: "GET", headers: {} })
      let ip = await res.json();
      setUserIp(ip.ip);
    }
    fetchIp()
  });

  useEffect(() => {
    dispatch(
      InvestmentActions.startInvestmentsListFlowMs({
        page: numberPage,
        pageSize: pageSize,
        name: nameFilter,
        businessLine: businessLineFilter,
        investmentState: investmentStateFilter,
        campaignState: campaignStateFilter,
        isFilter: nameFilter !== "" || isFilterBy
      })
    );
  }, [numberPage, pageSize, nameFilter, businessLineFilter, investmentStateFilter, campaignStateFilter]);

  const downloadFile = () => {
    dispatch(
      MoldalActions.setConditionalModalState(true, "ModalLoadingCertificate", {
        ...modalGeneral,
        description: t("searchInvestDetail.download")
      })
    );
    dispatch(
      InvestmentActions.startInvestmentsFile({
        name: nameFilter,
        businessLine: businessLineFilter,
        investmentState: investmentStateFilter,
        campaignState: campaignStateFilter,
        isFilter: nameFilter !== "" || isFilterBy,
        userId: userInfo.id,
        userEmail: userInfo.email,
        userIp
      })
    );
  };

  return (
    <MainContainer>
      <MyBalance
        availableMoney={myBalance.availableMoney}
        availableMoneyExchange={myBalance.availableMoneyExchange}
        investments={myBalance.investments}
        investmentsExchange={myBalance.investmentsExchange}
        generalBalance={myBalance.generalBalance}
        equity={myBalance.equity}
        debt={myBalance.debt}
      />
      <Container margins={{ bottom: "64px" }}>
        <InvestmentsPageTitle>
          {t("investment.investmentsList.title")}
        </InvestmentsPageTitle>
        <SubtitleContainer>
          <div className="download_component_desk">
            <div className="container_download_component">
              <PageSubtitle>
                {t("investment.investmentsList.subtitle")}
              </PageSubtitle>
              <HelpIcon
                data-tip={true}
                data-for="subtitle_invest_tooltip"
                className="financial-info__tooltip_logo"
                src="/assets/images/icono_pregunta.png"
                alt="a2censo"
              />
              <StyledTooltips
                id="subtitle_invest_tooltip"
                effect="solid"
                place="top"
                placement="topCenter"
                clickable={true}
              >
                <TooltipText>
                  {t("investment.investmentsList.tooltipText")}
                </TooltipText>
              </StyledTooltips>
            </div>
            <div className="download_component">
              <div className="download_component__container">
                <button onClick={downloadFile} id="button_download_investment" disabled={investmentsList.data.length == 0}>
                  {t("downloadFile.buttonName")}
                  <span className="download_component__container__icon"></span>
                </button>
              </div>
            </div>
          </div>
        </SubtitleContainer>
        <SearchContainer >
          <InvestFilterDetail
            setNameFilter={setNameFilter}
            setNumberPage={setNumberPage}
            setValueSelected={setValueSelected}
            setBusinessLineFilter={setBusinessLineFilter}
            setInvestmentStateFilter={setInvestmentStateFilter}
            setcampaignStateFilter={setcampaignStateFilter}
            setisFilterBy={setisFilterBy}
            isDisabled={investmentsList.data.length == 0 && (nameFilter == "" && !isFilterBy)}
          />
        </SearchContainer>
        {investmentsList.data.length === 0 && (nameFilter === "" && !isFilterBy) ? (
          <WithoutInvestments isPig={true} />
        ) : investmentsList.data.length === 0 && (nameFilter !== "" || isFilterBy) ? (
          <WithoutInvestments isPig={false} />
        ) : (
          <Investments>
            {investmentsList.data.length > 0 &&
              investmentsList.data.map((item, index) => {
                item.firstElement = index === 0 ? true : false;
                return (
                  <>
                    {
                      <InvestListAccordion
                        key={String(item.id)}
                        investmentData={item}
                      />
                    }
                  </>
                );
              })}
          </Investments>
        )}
        <InvestmentsPaginator
          paginatorData={investmentsList.paginationParams}
          setNumberPage={setNumberPage}
          setPageSize={setPageSize}
          valueSelected={valueSelected}
          setValueSelected={setValueSelected}
          isDisabled={investmentsList.data.length == 0 && (nameFilter == "" || isFilterBy)} />
        <PreFooter />
      </Container>
      <Footer>
        <FooterContent>
          <ButtonFooter
            onClick={goToMovements}
            id="go-to-movements"
            disabled={investmentsList.isDisabledMovements}
          >
            {t("investment.investmentsList.movements")}
          </ButtonFooter>
        </FooterContent>
      </Footer>
    </MainContainer>
  );
};
