import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { isMobile, isBrowser } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import { push } from "connected-react-router";
import DownloadFile from "../../components/DownloadFile/DownloadFile";
import {
  toBase64,
  validateFile,
  findIconByType,
  findIconTextByType
} from "../../lib/utils";
import {
  Container,
  Header,
  Button,
  PageContent,
  PageTitle,
  PageSubtitle,
  DownloadFileContainer,
  TextAreaField,
  UploadFilesContainer,
  FilesIconsContainer,
  UploadFileIcon,
  UploadFileLabel,
  Input,
  ButtonContainer,
  SubmitButton,
  IconFile,
  FileContent,
  UploadedFile,
  UploadedFileName,
  DeleteFile,
  IconHeader,
  IconHeaderContainer,
  FileBottom,
  UploadFilesIconsContainer,
  Error
} from "./Styles";
import PaymentsActions from "../../redux/reducers/payments.reducer";

import clipIcon from "assets/images/clip-icon.png";
import clipIconSelected from "assets/images/clip-icon-selected.png";
import imageIcon from "assets/images/image-icon.png";
import imageIconSelected from "assets/images/image-icon-selected.png";
import toolsIcon from "assets/images/tools-icon.png";

const UpdatePatrimonialRights = ({
  values,
  setFieldValue,
  setFieldError,
  errors
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isFile, setIsFile] = useState(false);
  const { issuerPaymentList, campaignSelected } = useSelector(
    ({ payments }) => payments
  );
  const issuerPayment = issuerPaymentList.filter(
    (item) => item.campaign_id === campaignSelected
  )[0];

  const sendReject = () => {
    if (!values.comment || values.comment.trim() === "") {
      return;
    }

    setIsLoading(true);
    dispatch(
      PaymentsActions.rejectPayment({
        issuerPaymentId: issuerPayment.issuer_payment.id,
        comment: values.comment,
        file: values.file,
        fileName: values.fileName
      })
    );
  };

  const handleBack = () => {
    dispatch(push("/payment-detail"));
  };

  const deleteFile = () => {
    setIsFile(false);
    setFieldValue("file", "");
    setFieldValue("fileName", "");
    setFieldValue("fileType", "");
  };

  const handleError = (error) => {
    if (error.includes("format")) {
      return "patrimonialRights.validations.invalid";
    }
    return error;
  };

  return (
    <Container>
      <Header className="container">
        <Button data-testid="back-button" onClick={handleBack} type="button">
          <img alt="previous" src="/assets/images/previous.png" />
        </Button>
      </Header>
      <PageContent>
        <IconHeaderContainer>
          <IconHeader src={toolsIcon} />
        </IconHeaderContainer>
        <PageTitle>{t("patrimonialRights.title")}</PageTitle>
        <PageSubtitle>{t("patrimonialRights.subtitle")}</PageSubtitle>
        <DownloadFileContainer>
          {issuerPayment.issuer_payment.equity_rights_url && (
            <DownloadFile
              fileName={t("patrimonialRights.patrimonialRight")}
              fileUrl={issuerPayment.issuer_payment.equity_rights_url}
            />
          )}
        </DownloadFileContainer>
        <UploadFilesContainer className="container">
          <TextAreaField
            id="comment-field"
            data-testid="comment-field"
            cols={10}
            autoComplete={false}
            onFocus={() => {
              if (!values.comment || values.comment.trim() === "") {
                setFieldValue("commentPlaceholder", "");
              }
            }}
            onBlur={() => {
              if (!values.comment || values.comment.trim() === "") {
                setFieldValue(
                  "commentPlaceholder",
                  t("patrimonialRights.fieldPlaceholder")
                );
              }
            }}
            value={
              values.comment !== "" ? values.comment : values.commentPlaceholder
            }
            onChange={(event) => setFieldValue("comment", event.target.value)}
          />
          <FilesIconsContainer>
            {values.file !== "" && !isMobile ? (
              <FileContent>
                <DeleteFile
                  data-testid="delete-file-browser"
                  src="/assets/images/delete_icon.png"
                  alt="delete icon"
                  onClick={deleteFile}
                />
                <UploadedFile>
                  <IconFile
                    src={findIconByType(values.fileType)}
                    alt="download file"
                  />
                  <UploadedFileName>
                    {values.fileName}
                    <span>{t(findIconTextByType(values.fileType))}</span>
                  </UploadedFileName>
                </UploadedFile>
              </FileContent>
            ) : (
              <div />
            )}
            <UploadFilesIconsContainer>
              {isBrowser && errors.file && (
                <Error mobile={isMobile}>{t(handleError(errors.file))}</Error>
              )}
              <UploadFileLabel for="file-upload-pdf">
                <UploadFileIcon
                  src={isFile ? clipIconSelected : clipIcon}
                  style={{ padding: 3 }}
                />
                <Input
                  id="file-upload-pdf"
                  data-testid="file-upload-pdf"
                  type="file"
                  name="file"
                  onChange={async (event) => {
                    setFieldError("file", null);
                    setIsLoading(true);
                    const fileLoaded = event.currentTarget.files[0] || null;
                    const fileError = validateFile(fileLoaded, 5242880, [
                      "xlsx",
                      "xls",
                      "pdf",
                      "doc",
                      "docx"
                    ]);
                    if (fileError) {
                      setIsLoading(false);
                      return setFieldError("file", fileError);
                    }
                    fileLoaded &&
                      setFieldValue("file", await toBase64(fileLoaded));
                    setIsFile(true);
                    setFieldValue("fileName", fileLoaded.name);
                    setFieldValue("fileType", fileLoaded.type);
                    setIsLoading(false);
                  }}
                />
              </UploadFileLabel>
              <UploadFileLabel for="file-upload-image">
                <UploadFileIcon
                  src={
                    values.fileType === "image" ? imageIconSelected : imageIcon
                  }
                />
                <Input
                  id="file-upload-image"
                  data-testid="file-upload-image"
                  type="file"
                  name="file"
                  onChange={async (event) => {
                    setFieldError("file", null);
                    setIsLoading(true);
                    const fileLoaded = event.currentTarget.files[0] || null;
                    const fileError = validateFile(fileLoaded, 5242880, [
                      "jpg",
                      "png",
                      "jpeg"
                    ]);
                    if (fileError) {
                      setIsLoading(false);
                      return setFieldError("file", fileError);
                    }
                    if (fileLoaded) {
                      setFieldValue("fileType", "image");
                      setFieldValue("fileName", fileLoaded.name);
                      const base64 = await toBase64(fileLoaded);
                      await setFieldValue("file", base64);
                      setIsFile(false);
                      setIsLoading(false);
                    }
                  }}
                />
              </UploadFileLabel>
            </UploadFilesIconsContainer>
          </FilesIconsContainer>
        </UploadFilesContainer>
        {values.file !== "" && isMobile ? (
          <FileBottom>
            <FileContent>
              <DeleteFile
                data-testid="delete-file-mobile"
                src="/assets/images/delete_icon.png"
                alt="delete icon"
                onClick={deleteFile}
              />
              <UploadedFile>
                <IconFile
                  src={findIconByType(values.fileType)}
                  alt="download file"
                />
                <UploadedFileName>
                  {values.fileName}
                  <span>{t(findIconTextByType(values.fileType))}</span>
                </UploadedFileName>
              </UploadedFile>
            </FileContent>
          </FileBottom>
        ) : (
          <div />
        )}
        {isMobile && errors.file && (
          <Error mobile={isMobile}>{t(handleError(errors.file))}</Error>
        )}
        <ButtonContainer>
          <SubmitButton
            data-testid="send-button"
            type="submit"
            disabled={
              !values.comment || values.comment.trim() === "" || isLoading
            }
            onClick={sendReject}
          >
            {isLoading
              ? t("patrimonialRights.loading")
              : t("patrimonialRights.send")}
          </SubmitButton>
        </ButtonContainer>
      </PageContent>
    </Container>
  );
};

UpdatePatrimonialRights.propTypes = {
  values: PropTypes.shape({
    comment: PropTypes.string,
    commentPlaceholder: PropTypes.string,
    fileName: PropTypes.string,
    fileType: PropTypes.string,
    file: PropTypes.string
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    file: PropTypes.string
  }).isRequired
};

export default withFormik({
  mapPropsToValues: () => ({
    comment: "",
    commentPlaceholder: "Escribe aquí tu comentario...",
    fileName: "",
    file: "",
    fileType: ""
  })
})(UpdatePatrimonialRights);
