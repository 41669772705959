import styled from "styled-components";

export const Container = styled.div`
  max-height: calc(100vh - 110px);
  overflow-y: scroll;
`;

export const Footer = styled.div`
  background-color: ${(props) => props.theme.brightTurquoise};
  bottom: 0;
  height: 48px;
  position: fixed;
  width: 100%;
`;

export const FooterContent = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  color: white;
`;

export const DesktopView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PaginationContainer = styled.div`
  display: flex;
  margin-top: 36px;
  width: 100%;
`;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 25px;
  width: 30px;
  ${(props) => props.theme.smartphone} {
    margin-top: 20px;
  }
`;

export const BackButtonImage = styled.img`
  height: 26px;
  width: 26px;
  object-fit: contain;
`;

export const BackContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  max-width: 1140px;
  width: 100%;
  ${(props) => props.theme.smartphone} {
    margin-top: 20px;
  }
`;

export const Title = styled.h1`
  border-left: 7px solid #0bbef0;
  color: #31302f;
  font-family: "Nunito";
  font-size: 36px;
  font-weight: 800;
  line-height: 39px;
  margin-bottom: 30px;
  padding: 0 16px;
  ${(props) => props.theme.smartphone} {
    font-size: 24px;
    line-height: 46px;
    margin-bottom: 16px;
  }
`;

export const MovementsListContainer = styled.div`
  display: flex;
`;

export const Placeholder = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 336px;
  flex-wrap: wrap;
  width: 100%;
`;

export const PlaceholderImage = styled.img`
  height: 150px;
  width: 126px;
`;

export const PlaceholderTitle = styled.p`
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 16px;
  text-align: center;
  ${(props) => props.theme.smartphone} {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const PlaceholderSubtitle = styled.a`
  cursor: pointer;
  font-size: 24px;
  line-height: 30px;
  ${(props) => props.theme.smartphone} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  width: 100%;
`;
