const { Serializer } = require("jsonapi-serializer");

module.exports = {
  ForgotPasswordSerializer: (meta = {}) =>
    new Serializer("forgot_password", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["email"]
    }),
  ForgotPasswordOtpTypeSerializer: (meta = {}) =>
    new Serializer("forgot_password_otp_type", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["token", "otpType"]
    }),
  ForgotPasswordOtpValidationSerializer: (meta = {}) =>
    new Serializer("forgot_password_otp_validation", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["token", "otpCode", "otpType"]
    })
};
