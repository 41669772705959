import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { isMobile } from "react-device-detect";
import {
  Heading,
  Button,
  Paragraph,
  RequestCampaignCard
} from "@bvcco/a2censo-component-lib";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  Header,
  Footer,
  DottedSeparator,
  ContainerHelp,
  BoldClickHere,
  ContainerInfoGuide,
  ButtonPlay,
  Icon,
  ContainerPending,
  LogoEmpty,
  ContainerCampaings,
  ButtonIcon
} from "./Styles";
import iconPlay from "../../assets/images/playButton.png";
import emptyState from "../../assets/images/empty-states.png";
import emptyStateCampaigns from "../../assets/images/empty-state-campaigns.png";
import DashboardActions from "../../redux/reducers/dashboard-campaigns.reducer";
import { openFile } from "../../lib/utils/downloadFile";
import successIcon from "../../assets/images/success.svg";
import infoIcon from "../../assets/images/info-icon.svg";
import editIcon from "../../assets/images/edit.svg";
import bellIcon from "../../assets/images/bell.svg";
import constructionImage from "../../assets/images/construction.svg";
import RequestCampaignActions from "../../redux/reducers/request-campaign.reducer";
import CampaignStepper from "../../redux/reducers/create-campaign-stepper.reducer";
import MoldalActions from "../../redux/reducers/modal.reducer";
import CampaignModel, {
  isPendingCampaign
} from "../../lib/models/campaign.model";
import PymeDesktopNeeded from "../PymeDesktopNeeded/PymeDesktopNeeded";
import CampaignsDetailActions from "../../redux/reducers/campaing-detail.reducer";
import { getHasSession } from "../../pages/CampaingNewDetail/CampaingNewDetail";

const DashboardCampaigns = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openModal = () => {
    dispatch(MoldalActions.setConditionalModalState(true, "ContactForm"));
  };
  useEffect(() => {
    dispatch(DashboardActions.getDashboardCampaigns());
    dispatch(DashboardActions.getDashboardVideo());
    dispatch(CampaignStepper.cleanAll());
    dispatch(RequestCampaignActions.cleanAll());
    dispatch(RequestCampaignActions.fetchMinimalAmount());
    dispatch(RequestCampaignActions.setResetRequestCampaign());
    return () => {
      dispatch(DashboardActions.clearCampaigns());
    };
  }, []);
  const { userInfo } = useSelector(({ user }) => user);

  const isPending = userInfo.user.finish_information !== 1;
  const { campaigns, video, activeCampaings = [] } = useSelector(
    ({ dashboardCampaigns }) => dashboardCampaigns
  );
  let type = "dashboard";
  if (isPending) {
    type = "pending";
  } else if (campaigns.length === 0 && activeCampaings.length === 0) {
    type = "empty";
  }

  const openVideo = () => {
    const urlVideo = isPending ? video.videoTips : video.videoGuide;
    openFile(urlVideo);
  };

  const createCampaignsRoute = "/create-campaign/information";
  const goToCreateCampaigns = () => {
    dispatch(
      RequestCampaignActions.setRequestCampaignInfo({
        financial_terms: "",
        request_campaign: ""
      })
    );

    dispatch(push(createCampaignsRoute));
  };

  const goToContinue = (id) => {
    dispatch(RequestCampaignActions.fetchRequestCampaignInfo({ id }));
  };

  const goToFillInformation = () => {
    dispatch(push("/my-company"));
  };

  const getButtons = (campaign, typeCard, isActiveCampaigns) => {
    if (typeCard === "secondary") {
      return [
        {
          id: `detail_campaign_${campaign.id}`,
          children: t("dashboardCampaings.continue"),
          onClick: () => {
            goToContinue(campaign.id);
          },
          rounded: true,
          icon: {
            icon: <ButtonIcon src={editIcon} />,
            position: "right"
          },
          color: "quinary",
          disabled:
            campaign.state_request_campaign_id ===
            CampaignModel.stateRequestCampaign.rejected
        }
      ];
    }
    return [
      {
        id: `detail_see_${campaign.id}`,
        children: t("dashboardCampaings.seeCampaign"),
        onClick: () => {
          if (isActiveCampaigns) {
            dispatch(
              CampaignsDetailActions.getCampaingData(campaign.campaign_id, getHasSession())
            );
          } else {
            goToContinue(campaign.id);
          }
        },
        rounded: true,
        color: "primary"
      }
    ];
  };

  const getColorAndIconTop = (state, typeCard) => {
    let result = {
      icon: successIcon
    };
    const { pendingRelease, rejected } = CampaignModel.stateRequestCampaign;

    if (typeCard !== "primary") {
      if (state === pendingRelease) {
        result = {
          icon: infoIcon,
          color: "white"
        };
      } else if (state === rejected) {
        result = {
          icon: infoIcon,
          color: "azalea"
        };
      } else if (isPendingCampaign(state)) {
        result = {
          icon: infoIcon,
          color: "fog"
        };
      } else {
        result = {
          icon: bellIcon,
          color: "alabasterTonalyty"
        };
      }
    }
    return result;
  };

  if (isMobile) {
    return <PymeDesktopNeeded />;
  }

  return (
    <Container>
      <Header>
        <Heading color="riverBed" hieranchy="secondary">
          {t("dashboardCampaings.title")}
          <strong>{t("dashboardCampaings.titleBold")}</strong>
        </Heading>

        <Button
          disabled={isPending}
          rounded={true}
          id="go-create-campaigns"
          onClick={goToCreateCampaigns}
          icon={{
            icon: <FontAwesomeIcon icon={faChevronRight} />,
            position: "right"
          }}
        >
          {t("dashboardCampaings.createCampaign")}
        </Button>
      </Header>
      {type === "dashboard" && (
        <>
          {activeCampaings.length > 0 && (
            <>
              <ContainerCampaings>
                {activeCampaings.map((campaign) => {
                  const typeCard = "primary";
                  const draftActive =
                    campaign.state === CampaignModel.stateName[1];
                  return (
                    <RequestCampaignCard
                      campaign={{
                        ...campaign,
                        image: campaign.image
                      }}
                      type={typeCard}
                      top={{
                        ...getColorAndIconTop(campaign.state, typeCard),
                        textColor: draftActive ? "white" : "text",
                        fontWeight: draftActive ? 600 : 400,
                        text: t(
                          `dashboardCampaings.states.${
                            draftActive ? "draftActive" : campaign.state
                          }`
                        )
                      }}
                      buttons={getButtons(campaign, typeCard, true)}
                    />
                  );
                })}
              </ContainerCampaings>
              <Header>
                <Heading color="riverBed" hieranchy="secondary">
                  {t("dashboardCampaings.title")}
                  <strong>{t("dashboardCampaings.titleRequestBold")}</strong>
                </Heading>
              </Header>
            </>
          )}
          <ContainerCampaings borderBottom={true}>
            {campaigns.map((campaign) => {
              const state = campaign.state_request_campaign_id;
              const typeCard =
                state === "published_campaign" ? "primary" : "secondary";
              const isActiveCampaigns =
                state === "published_campaign" ? true : false;
              return (
                <RequestCampaignCard
                  campaign={{
                    ...campaign,
                    image: campaign.image || constructionImage
                  }}
                  type={typeCard}
                  top={{
                    ...getColorAndIconTop(state, typeCard),
                    textColor: "text",
                    text: t(`dashboardCampaings.states.${state}`)
                  }}
                  buttons={getButtons(campaign, typeCard, isActiveCampaigns)}
                />
              );
            })}
          </ContainerCampaings>
        </>
      )}
      {!isPending && campaigns.length === 0 && (
        <ContainerPending>
          <LogoEmpty src={emptyStateCampaigns} />
          <Paragraph color="riverBed" align="center">
            {t("dashboardCampaings.emptyCampaigns")}
          </Paragraph>
          <Paragraph color="riverBed" align="center" fontWeight="bold">
            {t("dashboardCampaings.emptyCampaignsBold")}
          </Paragraph>
          <Button
            color="primary2"
            outlined={true}
            onClick={goToCreateCampaigns}
            rounded={true}
            icon={{
              icon: <FontAwesomeIcon icon={faChevronRight} />,
              position: "right"
            }}
          >
            {t("dashboardCampaings.createCampaign")}
          </Button>
        </ContainerPending>
      )}
      {isPending && (
        <ContainerPending>
          <LogoEmpty src={emptyState} />
          <Paragraph color="riverBed" align="center">
            {t("dashboardCampaings.pendingDescription")}
            <br />
            <strong>{t("dashboardCampaings.pendingDescription2")}</strong>
          </Paragraph>
          <Button
            outlined={true}
            rounded={true}
            id="go-fill-information"
            color="primary2"
            onClick={goToFillInformation}
            icon={{
              icon: <FontAwesomeIcon icon={faChevronRight} />,
              position: "right"
            }}
          >
            {t("dashboardCampaings.myCompany")}
          </Button>
        </ContainerPending>
      )}
      <Footer>
        <DottedSeparator />
        <ContainerHelp>
          <Paragraph color="regentGray" align="left" fontWeight="bold">
            {t(`dashboardCampaings.${type}.description1`)}
            <BoldClickHere onClick={openModal} id="open-modal-button">
              {t(`dashboardCampaings.${type}.clickHere1`)}
            </BoldClickHere>
          </Paragraph>
          <ContainerInfoGuide>
            <ButtonPlay>
              <Icon src={iconPlay} />
            </ButtonPlay>
            <Paragraph color="white" align="left">
              {t(`dashboardCampaings.${type}.description2`)}
              <BoldClickHere id="open_video" onClick={openVideo}>
                {t(`dashboardCampaings.${type}.clickHere2`)}
              </BoldClickHere>
            </Paragraph>
          </ContainerInfoGuide>
        </ContainerHelp>
      </Footer>
    </Container>
  );
};

export default DashboardCampaigns;
