import React, { useState } from "react";
import {
  Container,
  Header,
  Search,
  ContainerInput,
  SearchIMG,
  ContainerOptions,
  ChangeView,
  SendEmail,
  ButtonsIMG,
  ImageEmpty,
  ContainerEmpty,
  Tooltip,
  SummaryReport,
  ListNameCompanyWrapper,
  Image,
  Label,
  TitleReportSummary,
  SummaryInfoIcon,
  SendEmailSummary,
  SummaryDownloadIcon
} from "./Styles";
import Pagination from "./Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Paragraph, Button, Input } from "@bvcco/a2censo-component-lib";
import { Dropdown, DropdownIOption } from "components/shared";
import SearchIcon from "assets/images/search.png";
import ListIcon from "assets/images/icon-list.png";
import CardIcon from "assets/images/icon-card.png";
import SearchEmptyIMG from "assets/images/search-no-results.png";

import EmailIcon from "assets/images/icon-email-white.png";

import debounce from "../../../lib/utils/debounce";
import { CertificateCardFromType } from "components/shared";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import CardIMG from "assets/images/card-document.png";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import User from "../../../lib/models/user.model";

export type IProps = {
  certificates?: Array<{
    quantity: number;
    description: string;
    companyName: string;
    onClickEmail?: () => void;
    onClickDownload?: () => void;
    disabledDownload?: boolean;
  }>;
  onClickSendEmail?: () => void;
  onClickSendSummary: (
    userId: number,
    year: number | string,
    typePfd: number | string
  ) => void;
  onClickDownloadSummary: (
    userId: number,
    year: number | string,
    typePfd: number | string
  ) => void;
  onClickGoBack: () => void;
  onSearch: (query: string) => void;
  yearList: Array<DropdownIOption>;
  onSearchYear: (year: number) => void;
  isInvestor: boolean;
  yearInitial: number;
  children?: React.ReactNode | JSX.Element;
};

const Certificates = ({
  certificates = [],
  onClickSendEmail,
  onClickSendSummary,
  onClickDownloadSummary,
  onClickGoBack,
  onSearch,
  yearList,
  onSearchYear,
  yearInitial,
  children,
  isInvestor
}: IProps) => {
  const [typeCard, setTypeCard] = useState(CertificateCardFromType.Card);

  const appStore: any = useSelector((store) => store);
  const userState = useSelector(() => ({ ...appStore.user }));
  let userId = 0;
  if (userState.userInfo) {
    const { user } = userState.userInfo;
    userId = user.id;
  }

  const certificatesPerPage = 8;
  const pages = Math.ceil(certificates.length / certificatesPerPage);
  const searchDelay = 600;
  const debounceSearch = debounce(false, searchDelay, onSearch)();
  const onSearchDebounced = (e: any) => {
    debounceSearch(e.target.value);
  };

  const onChangeYear = (item: DropdownIOption) => {
    onSearchYear(Number(item.id));
  };

  const onChangeTypeView = () => {
    if (typeCard === CertificateCardFromType.Card) {
      setTypeCard(CertificateCardFromType.List);
    } else {
      setTypeCard(CertificateCardFromType.Card);
    }
  };

  const { t } = useTranslation();
  const DisableControls = certificates.length < 1;
  return (
    <Container>
      <Header>
        <Button
          dataTestid={"go-back-button"}
          onClick={onClickGoBack}
          color="whiteManatee"
          rounded={true}
          icon={{
            icon: <FontAwesomeIcon icon={faChevronLeft} />,
            position: "left"
          }}
        >
          {t("myCertificates.detail.backButton")}
        </Button>
        {children}
      </Header>
      <Search>
        <ContainerInput>
          <SearchIMG src={SearchIcon} />
          <Input
            id="input_search"
            type="text"
            dataTestId="input-search"
            placeholder="Buscar"
            onChange={onSearchDebounced}
            enabledStates={[]}
          />
        </ContainerInput>
        <ContainerOptions>
          <Dropdown
            name="drop-down-year"
            id="drop-down-year"
            options={yearList}
            valueSelected={yearInitial}
            onChange={onChangeYear}
          />
          <ChangeView
            onClick={onChangeTypeView}
            data-testid="change-view-buttom"
          >
            <ButtonsIMG
              src={
                typeCard === CertificateCardFromType.Card ? ListIcon : CardIcon
              }
            />
          </ChangeView>
          <SendEmail
            data-tip="send-email-massive-tip"
            data-for="send-email-massive"
            data-testid="send-email-massive-button"
            onClick={onClickSendEmail}
            disabled={DisableControls}
          >
            <ButtonsIMG src={EmailIcon} />
          </SendEmail>
          <Tooltip place="top" effect="solid" id="send-email-massive">
            {t("sendMassiveEmails")}
          </Tooltip>
        </ContainerOptions>
      </Search>
     {isInvestor ? 
      <SummaryReport>
        <ListNameCompanyWrapper isInvestor={isInvestor}>
          <Image src={CardIMG} />
          <div>

        <TitleReportSummary>
              {t("myCertificates.investor.summaryRetentionReport")}
              <SummaryInfoIcon
                data-tip="tooltipSummaryRep"
                data-for="tooltipSummaryRep">
                <ReactTooltip
                  className="tooltip_summary_report"
                  id="tooltipSummaryRep"
                  place="top"
                  effect="solid"
                  placement="topCenter"
                >
                  <div className="tooltipSummaryRep">
                    {t("myCertificates.investor.tooltipSummaryRep")}
                  </div>
                </ReactTooltip>
              </SummaryInfoIcon>
            </TitleReportSummary>
          </div>
        </ListNameCompanyWrapper>
        <div className="summary-controls">
          <SendEmailSummary
            data-testid="send-email-summary-button"
            onClick={() => {
              onClickSendSummary(userId, yearInitial, 1);
            }}
            disabled={DisableControls}
          ></SendEmailSummary>
          <SummaryDownloadIcon
            data-testid="download-summary-button"
            onClick={() => {
              onClickDownloadSummary(userId, yearInitial, 1);
            }}
            disabled={DisableControls}
          ></SummaryDownloadIcon>
        </div>
      </SummaryReport>
      : ""
      }
      {certificates.length > 0 ? (
        <Pagination
          isMobile={isMobile}
          certificates={certificates}
          certificatesPerPage={certificatesPerPage}
          pages={pages}
          typeCard={typeCard}
          isInvestor={isInvestor}
        />
      ) : (
        <ContainerEmpty>
          <ImageEmpty src={SearchEmptyIMG} />
          <Paragraph>
            {t("myCertificates.detail.empty.descriptionPar1")}
            <strong>{yearInitial}</strong>
            {t("myCertificates.detail.empty.descriptionPar2")}
          </Paragraph>
        </ContainerEmpty>
      )}
    </Container>
  );
};

export default Certificates;
