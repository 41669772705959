import styled from "styled-components";

export const Target = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 15px;
  & > div > img {
    margin-top: 39px;
    width: 167px;
  }
  & > div > p {
    width: 224px;
    font-size: 14px;
    line-height: 19px;
  }
`;
