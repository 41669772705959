import { InputHTMLAttributes } from "react";
import styled from "styled-components";

type Props = {
  error: string;
};

export type InputProps = Props & InputHTMLAttributes<HTMLInputElement>;

export const Input = styled.input<InputProps>`
  background: ${({ theme }) => theme.colors.white.bgColor};
  font-family: ${({ theme }) => theme.fontFamily.primary};
  padding: 10px 16px;
  width: 100%;
  border: 1px solid
    ${({
      theme: {
        colors: { alabaster },
        violetRed
      },
      error
    }) => (error ? violetRed : alabaster.bgColor)};
  height: 51px;
  border-radius: 5px;
  color: ${(props) => props.theme.gunPowder};
  transition: all 0.2s ease-out;
  &:disabled {
    background: ${({ theme }) => theme.colors.alabaster.bgColor};
    transition: all 0.5s ease-in-out;
    color: ${({ theme }) => theme.colors.alabaster.bgColor};
  }
  &:focus {
    border: 1px solid ${({ theme: { cornflowerBlue } }) => cornflowerBlue};
    outline: none !important;
  }
  &.ea-input {
    text-align: right;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 16px;
`;
