import { resettableReducer } from "reduxsauce";
import { updateUserDataReducer } from "./update-user-data";
import { campaignsReducer } from "./campaigns";
import { equityReducer } from "./equity";
import { filtersReducer } from "./home-filters";
import { noveltiesReducer } from "./novelties";

const resettable = resettableReducer("RESET_STATE");

export default {
  updateUserData: resettable(updateUserDataReducer),
  campaigns: resettable(campaignsReducer),
  homeFilters: resettable(filtersReducer),
  equity: resettable(equityReducer),
  novelties: resettable(noveltiesReducer)
};
