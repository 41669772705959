import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setFinancialInfo: ["financialInfo"],
  enrollmentSuccess: [],
  enrollmentError: ["error"],
  getChallengeQuestionnaire: [],
  setChallengeQuestionnaire: ["challengeQuestions"],
  challengeQuestionnaireAnswers: ["answers"],
  organizationalStructure: [],
  purpose: [],
  validations: [],
  setShowWelcomeModal: ["showWelcomeModal"],
  setDealSectors: ["dealSectors"],
  setFatcaQuestions: ["fatcaQuestions"]
});

export const EnrollmentTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  error: "",
  financialInfo: {
    assets: "",
    monthlyIncome: "",
    equity: "",
    monthlyExpenses: "",
    profit: ""
  },
  showWelcomeModal: false,
  dealSectors: {},
  challengeQuestions: {
    questions: [],
    id: "",
    reg: ""
  },
  fatcaQuestions: []
};

const setShowWelcomeModal = (state, { showWelcomeModal }) => ({
  ...state,
  showWelcomeModal
});

const setFinancialInfo = (state, { financialInfo }) => ({
  ...state,
  financialInfo,
  isFetching: true
});

const enrollmentSuccess = (state) => ({
  ...state,
  isFetching: false,
  error: ""
});

const enrollmentError = (state, { error }) => ({
  ...state,
  error,
  isFetching: false
});

const setDealSectors = (state, { dealSectors }) => ({ ...state, dealSectors });

const setChallengeQuestions = (state, { challengeQuestions }) => ({
  ...state,
  challengeQuestions
});

const setFatcaQuestions = (state, { fatcaQuestions }) => ({
  ...state,
  fatcaQuestions
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_FINANCIAL_INFO]: setFinancialInfo,
  [Types.ENROLLMENT_SUCCESS]: enrollmentSuccess,
  [Types.ENROLLMENT_ERROR]: enrollmentError,
  [Types.SET_SHOW_WELCOME_MODAL]: setShowWelcomeModal,
  [Types.SET_DEAL_SECTORS]: setDealSectors,
  [Types.SET_CHALLENGE_QUESTIONNAIRE]: setChallengeQuestions,
  [Types.SET_FATCA_QUESTIONS]: setFatcaQuestions
});
