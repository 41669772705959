// eslint-disable-next-line import/named
import { AnyAction } from "redux";
import { mergeMap, catchError } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import { CampaignsPagination } from "./types";
import { Action } from "redux/store";
import { ShowcaseConnect } from "services";
import CampaignsActions from "./reducer";

export const fetchSimulatorsCampaigns = ({
  limit,
  page
}: CampaignsPagination): Action<CampaignsPagination> => ({
  type: "FETCH_SIMULATOR_CAMPAIGNS",
  payload: {
    limit,
    page
  }
});

export const getSimulatorCampaigns = (action$: AnyAction, state$: any) =>
  action$.pipe(
    ofType("FETCH_SIMULATOR_CAMPAIGNS"),
    mergeMap(({ payload: { page, limit } }: Action<CampaignsPagination>) => {
      const {
        value: {
          campaigns: { campaigns: oldCampaigns }
        }
      } = state$;

      return Observable.from(
        new ShowcaseConnect().getCampaigns({
          page,
          limit,
          state: "active"
        })
      ).pipe(
        mergeMap(({ campaigns, totalPages, currentPage }) => {
          const newCampaigns = [...oldCampaigns, ...campaigns];
          return Observable.concat(
            Observable.of(CampaignsActions.setCampaigns(newCampaigns)),
            Observable.of(CampaignsActions.setCurrentPage(currentPage)),
            Observable.of(CampaignsActions.setTotalPages(totalPages))
          );
        }),
        catchError(() => Observable.concat(Observable.empty()))
      );
    })
  );

export default combineEpics(getSimulatorCampaigns);
