import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import {
  Container,
  CloseButton,
  Icon,
  IconTitleContainer,
  IconTitle,
  Title,
  Paragraph,
  MyPayment,
  Company
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";

const RequestSendModal = ({ issuerName }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleCloseClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
  };
  const goToMyPayment = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(push("/my-payments"));
  };
  return (
    <Container>
      <CloseButton id="button-close" onClick={handleCloseClick}>
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <IconTitleContainer>
        <IconTitle alt="previous" src="/assets/images/check_round.png" />
      </IconTitleContainer>
      <Title>{t("requestSendModal.title")}</Title>
      <Paragraph>
        <Company>{issuerName}</Company>
        {t("requestSendModal.description")}
        <MyPayment onClick={goToMyPayment} id="my-payment">
          {t("requestSendModal.myPayments")}
        </MyPayment>
      </Paragraph>
    </Container>
  );
};

RequestSendModal.propTypes = {
  issuerName: PropTypes.string.isRequired
};

export default RequestSendModal;
