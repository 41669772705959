import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  MyCertificates as Certificates,
  PersonTypeEnum
} from "components/MyCertificates";
import MoldalActions from "../../redux/reducers/modal.reducer";
import DashboardActions from "../../redux/reducers/dashboard-campaigns.reducer";
import UserCertificates, {
  Store
} from "../../redux/reducers/user-certificates.reducer";
import { push } from "connected-react-router";
import theme from "../../styles/LightTheme";
import UserModel from "lib/models/user.model";

export const MyCertificates = () => {
  const dispatch = useDispatch();
  const { availables } = useSelector((store: Store) => store.userCertificates);
  const { awardedCampaigns = [] } = useSelector(
    ({ dashboardCampaigns }: Store) => dashboardCampaigns
  );

  const personType =
    useSelector(UserModel.getPersonType) === UserModel.type.pyme
      ? PersonTypeEnum.Pyme
      : PersonTypeEnum.Investor;

  const count = {
    certificate: availables.withholdingTaxesCerts,
    retention: availables.financialTaxesCerts,
    anualCertificates: availables.reportYearCerts
  };

  useEffect(() => {
    dispatch(UserCertificates.fetchAvailables());
    dispatch(UserCertificates.cleanCertificates());

    dispatch(DashboardActions.getDashboardCampaigns());

    return () => {
      dispatch(DashboardActions.clearCampaigns());
    };
  }, []);

  const onClickCard = (type: number) => {
    dispatch(push(`my-certificates/detail/${type}`));
  };

  const onClickEdit = () => {
    dispatch(push("my-certificates/data-update"));
  };

  const onClickHelpHandler = () => {
    dispatch(
      MoldalActions.setConditionalModalState(true, "HelpForm", {
        clearBlur: true,
        css: {
          backgroundColor: theme.transparentMartinique
        }
      })
    );
  };

  return (
    <Certificates
      onClick={onClickCard}
      onClickHelp={onClickHelpHandler}
      onClickEdit={onClickEdit}
      count={count}
      personType={personType}
      disabledEdit={awardedCampaigns.length === 0}
    />
  );
};
