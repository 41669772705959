import styled from "styled-components";

export const Tags = styled.div`
  margin-bottom: 12px;
  display: flex;
  gap: 12px;
  & div {
    font-size: 12px !important;
  }
`;
