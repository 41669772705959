import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Container,
  ProgressBar,
  ProgressBarBackground,
  State,
  ProgressBarFill,
  ProgressBarPointer,
  ContainerFill,
  ProgressBarDescription,
  Day,
  Line
} from "./Styles.js";

const TimeLine = (props) => {
  const {
    dayToPay,
    initLine,
    firstLine,
    mediumLine,
    deadLine,
    extended
  } = props;
  const dayToPayFixed = dayToPay >= 0 ? dayToPay : 0;
  const { t } = useTranslation();
  const isDeadLine = dayToPayFixed === deadLine || dayToPayFixed === 0;
  const [stateTimeLine, setStateTimeLine] = useState({
    state: "to",
    tooltipPosition: null,
    tooltipRight: isDeadLine ? "90%" : "0%"
  });
  const states = {
    isOk: 1,
    isDanger: 2,
    Default: 3,
    firstCase: 4
  };

  const positionMediumLine = 90 - (mediumLine / initLine) * 80;
  const positionStateTooltip = {
    medium: 1,
    end: 2,
    start: 3
  };
  let positionDescription = positionStateTooltip.medium;
  if (isDeadLine) {
    positionDescription = positionStateTooltip.end;
  } else if (dayToPayFixed >= initLine) {
    positionDescription = positionStateTooltip.start;
  }

  let message = isDeadLine ? "timeLine.deadLine" : "timeLine.timeToPay";
  if (isDeadLine && extended) {
    message = "timeLine.deadLineExtended";
  }
  if (isDeadLine && extended && dayToPay < 0) {
    message = "timeLine.deadLine";
  }
  const days = dayToPayFixed > 1 ? "timeLine.daysText" : "timeLine.dayText";
  let statePointer = states.firstCase;
  if (isDeadLine) {
    statePointer = states.isDanger;
  } else if (dayToPayFixed <= initLine / 2) {
    statePointer = states.isOk;
  }

  let stateProgressBar = states.firstCase;
  if (dayToPayFixed < mediumLine) {
    stateProgressBar = states.isDanger;
  } else if (dayToPayFixed <= initLine / 2) {
    stateProgressBar = states.isOk;
  }

  const getWidthLineTime = () => {
    let widthDay = 90 - (dayToPayFixed / initLine) * 80;
    if (dayToPayFixed >= initLine) {
      widthDay = 10;
    } else if (isDeadLine) {
      widthDay = 100;
    }
    return widthDay;
  };

  const onPoseComplete = () => {
    if (isDeadLine) {
      setStateTimeLine({
        ...stateTimeLine,
        state: "end",
        tooltipPosition: "absolute"
      });
      if (stateTimeLine.state === "end") {
        setStateTimeLine({
          ...stateTimeLine,
          tooltipTransition: "visible"
        });
      }
    } else {
      setStateTimeLine({
        ...stateTimeLine,
        tooltipTransition: "visible"
      });
    }
  };
  return (
    <Container>
      <ProgressBar>
        <State
          position="10"
          state={dayToPayFixed <= initLine ? states.firstCase : null}
        >
          <Day>{`${t("timeLine.day")} ${initLine}`}</Day>
          <Line>|</Line>
        </State>
        <State
          position="20"
          state={dayToPayFixed <= firstLine ? states.firstCase : states.Default}
        >
          <Day>{`${t("timeLine.day")} ${firstLine}`}</Day>
          <Line>|</Line>
        </State>
        <State
          position={positionMediumLine}
          state={dayToPayFixed <= mediumLine ? states.isOk : states.Default}
        >
          <Day>{`${t("timeLine.day")} ${mediumLine}`}</Day>
          <Line>|</Line>
        </State>
        <State
          position="90"
          state={isDeadLine ? states.isDanger : states.Default}
        >
          <Day>{`${t("timeLine.day")} 0`}</Day>
          <Line>|</Line>
        </State>
        <ProgressBarBackground />
        <div style={{ display: "flex" }}>
          <ProgressBarFill
            id="progress-bar"
            {...props}
            widthSize={getWidthLineTime()}
            state={stateProgressBar}
            percentaje={isDeadLine ? 67 : 90}
            pose={stateTimeLine.state}
            initialPose="from"
            onPoseComplete={onPoseComplete}
          />
          <ContainerFill
            style={{
              left: stateTimeLine.tooltipRight,
              position: stateTimeLine.tooltipPosition
            }}
          >
            <ProgressBarPointer state={statePointer} />
            <ProgressBarDescription
              {...props}
              initialPose="from"
              pose={stateTimeLine.tooltipTransition}
              state={statePointer}
              position={positionDescription}
              style={{
                position: stateTimeLine.tooltipPosition
              }}
            >
              {`${t(message, {
                day: dayToPayFixed,
                days: t(days)
              })}`}
            </ProgressBarDescription>
          </ContainerFill>
        </div>
      </ProgressBar>
    </Container>
  );
};

TimeLine.propTypes = {
  dayToPay: PropTypes.number,
  initLine: PropTypes.number,
  firstLine: PropTypes.number,
  mediumLine: PropTypes.number,
  deadLine: PropTypes.number,
  extended: PropTypes.bool
};

TimeLine.defaultProps = {
  dayToPay: 8,
  initLine: 9,
  firstLine: 8,
  mediumLine: 3,
  deadLine: 0,
  extended: false
};

export default TimeLine;
