import styled from "styled-components";

export const Container = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${(props) => props.theme.smartphone} {
    margin-top: 20px;
  }
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.tuatara};
  font-family: Nunito;
  font-size: 23px;
  margin-top: 20px;
  font-weight: 800;
  text-align: center;
  ${(props) => props.theme.smartphone} {
    margin-top: 10px;
    font-size: 19px;
  }
`;
export const Subtitle = styled.p`
  color: ${(props) => props.theme.tuatara};
  font-family: Roboto;
  font-size: 17px;
  text-align: center;
  max-width: 285px;
  margin-top: 20px;
  font-weight: 200;
  margin-bottom: 30px;
  ${(props) => props.theme.smartphone} {
    margin-top: 10px;
    font-size: 14px;
  }
`;
