import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Container,
  SubContainer,
  SubContainer2,
  Icon,
  Span,
  Row,
  Row2,
  Row3,
  Row4,
  HeadText,
  Caption,
  NumberCaption,
  Details,
  DetailsText,
  DetailsCaption,
  DetailsNumberCaption,
  Table,
  Tr,
  Th,
  Td,
  ContentThText
} from "./Styles";
import { formatAmount, formatAmount2 } from "../../lib/utils.js";
import User from "../../lib/models/user.model";

const InvestorItem = ({ investor }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (open) {
      setOpen(false);
      return;
    }
    setOpen(true);
  };

  const fullName = `${investor.user_name} ${investor.user_last_name ||
    ""} ${investor.user_second_last_name || ""}`;

  const total = investor.capital + investor.earnings - investor.retention;

  return (
    <Container>
      <SubContainer id={`investor-${investor.id}`} onClick={handleClick}>
        <Icon open={open}>
          <Span open={open}>
            {open ? t("investor.restSymbol") : t("investor.plusSymbol")}
          </Span>
        </Icon>
        <Row>
          <HeadText>{t("investor.investorTitle")}</HeadText>
          <HeadText>{t("investor.operation")}</HeadText>
        </Row>
        <Row2>
          <Caption>{fullName}</Caption>
          <NumberCaption>{formatAmount(total)}</NumberCaption>
        </Row2>
        <Details open={open}>
          <Row3>
            <DetailsText>{t("investor.qualityTax")}</DetailsText>
            <DetailsText>{t("investor.paymentByCapital")}</DetailsText>
          </Row3>
          <Row4>
            <DetailsCaption>
              {t(`investor.qualityTaxType.${investor.quality_tax}`)}
            </DetailsCaption>
            <DetailsNumberCaption>
              {`${t("investor.plusSymbol")} ${formatAmount(investor.capital)}`}
            </DetailsNumberCaption>
          </Row4>
          <Row3>
            <DetailsText>
              {`${User.documentType[investor.document_type]}.`}
            </DetailsText>
            <DetailsText>{t("investor.paymentByInterest")}</DetailsText>
          </Row3>
          <Row4>
            <DetailsCaption>
              {investor.dv === "" || investor.dv === null
                ? `${investor.user_document_number}`
                : `${investor.user_document_number}-${investor.dv}`}
            </DetailsCaption>
            <DetailsNumberCaption>
              {`${t("investor.plusSymbol")} ${formatAmount(investor.earnings)}`}
            </DetailsNumberCaption>
          </Row4>
          <Row3>
            <DetailsText>{t("investor.accountNumber")}</DetailsText>
            <DetailsText>{t("investor.retentionValue")}</DetailsText>
          </Row3>
          <Row4>
            <DetailsCaption>{investor.code_siidj}</DetailsCaption>
            <DetailsNumberCaption negative={true}>
              {`${t("investor.restSymbol")} ${formatAmount(
                investor.retention
              )}`}
            </DetailsNumberCaption>
          </Row4>
          <Row3>
            <DetailsText>{t("investor.retentionPercentage")}</DetailsText>
          </Row3>
          <Row4>
            <DetailsCaption>
              {`${t("investor.percentageSymbol")} ${
                investor.retention_percentage
              }`}
            </DetailsCaption>
          </Row4>
          <Row3>
            <DetailsText>{t("investor.retentionBase")}</DetailsText>
          </Row3>
          <Row4>
            <DetailsCaption>{formatAmount2(investor.earnings)}</DetailsCaption>
          </Row4>
        </Details>
      </SubContainer>
      <SubContainer2>
        <Table>
          <Tr>
            <Th>
              <ContentThText>{t("investor.investorTitle")}</ContentThText>
            </Th>
            <Th>
              <ContentThText>{t("investor.accountNumber")}</ContentThText>
            </Th>
            <Th>
              <ContentThText>{t("investor.retentionPercentage")}</ContentThText>
            </Th>
            <Th>
              <ContentThText>{t("investor.retentionBase")}</ContentThText>
            </Th>
            <Th />
            <Th className="th-right">
              <ContentThText className="total-to-pay">
                {t("investor.valueToPay")}
              </ContentThText>
            </Th>
          </Tr>
          <Tr className="tr-white">
            <Td className="td-first stratos">{fullName}</Td>
            <Td className="td-first ironside-gray">{investor.code_siidj}</Td>
            <Td className="td-first ironside-gray">
              {`${investor.retention_percentage} ${t(
                "investor.percentageSymbol"
              )} `}
            </Td>
            <Td className="td-first ironside-gray">
              {formatAmount2(investor.earnings)}
            </Td>
            <Td className="td-first-text ironside-gray">
              {t("investor.paymentByCapital")}
            </Td>
            <Td className="td-first-last">
              {`${t("investor.plusSymbol")} ${formatAmount(investor.capital)}`}
            </Td>
          </Tr>
          <Tr className="tr-white">
            <Td className="td-second martinique">
              {t(`investor.qualityTaxType.${investor.quality_tax}`)}
            </Td>
            <Td />
            <Td />
            <Td />
            <Td className="td-second ironside-gray">
              {t("investor.paymentByInterest")}
            </Td>
            <Td className="td-second-last">
              {`${t("investor.plusSymbol")} ${formatAmount(investor.earnings)}`}
            </Td>
          </Tr>
          <Tr className="tr-white">
            <Td className="td-third martinique">
              {`${User.documentType[investor.document_type]}. `}
              {investor.dv === "" || investor.dv === null
                ? `${investor.user_document_number}`
                : `${investor.user_document_number}-${investor.dv}`}
            </Td>
            <Td className="td-third" />
            <Td className="td-third" />
            <Td className="td-third" />
            <Td className="td-third ironside-gray">
              {t("investor.retentionValue")}
            </Td>
            <Td className="td-third-last">
              {`${t("investor.restSymbol")} ${formatAmount(
                investor.retention
              )}`}
            </Td>
          </Tr>
          <Tr className="tr-gallery">
            <Td className="td-fourth martinique" />
            <Td className="td-fourth" />
            <Td className="td-fourth" />
            <Td className="td-fourth" />
            <Td className="td-fourth martinique">{t("investor.total")}</Td>
            <Td className="td-fourth-last">{formatAmount(total)}</Td>
          </Tr>
        </Table>
      </SubContainer2>
    </Container>
  );
};

InvestorItem.propTypes = {
  investor: PropTypes.shape({
    id: PropTypes.number,
    user_document_number: PropTypes.string,
    document_type: PropTypes.number,
    user_name: PropTypes.string,
    user_last_name: PropTypes.string,
    user_second_last_name: PropTypes.string,
    balance: PropTypes.number,
    earnings: PropTypes.number,
    capital: PropTypes.number,
    retention: PropTypes.number,
    quality_tax: PropTypes.string,
    retention_percentage: PropTypes.number,
    dv: PropTypes.number,
    code_siidj: PropTypes.string
  }).isRequired
};

export default InvestorItem;
