import styled from "styled-components";

const getBorder = (hasBorder) => (hasBorder ? "1px" : "0");

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const Label = styled.label`
  display: flex;
  padding: 8px;
  width: 100%;
`;

export const List = styled.ul`
  background: white;
  box-shadow: 0 6px 12px 0 rgba(44, 50, 65, 0.1);
  display: ${(props) => (props.hidden ? "none" : "block")};
  flex-direction: column;
  margin-top: 4px;
  max-height: 200px;
  overflow-y: scroll;
  position: absolute;
  top: 62px;
  width: 100%;
  z-index: 1;
`;

export const Suggestion = styled.li`
  border-style: solid;
  border-color: ${(props) => props.theme.darkGray};
  border-width: ${(props) =>
    `${getBorder(props.borders.top)} ${getBorder(
      props.borders.right
    )} ${getBorder(props.borders.bottom)} ${getBorder(props.borders.left)}`};
  display: flex;
  flex-direction: row;
  width: 100%;
`;
