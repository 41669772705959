const { Serializer } = require("jsonapi-serializer");

module.exports = {
  PaymentsSerializer: (meta = {}) =>
    new Serializer("payments", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "issuerPaymentId",
        "documentNumber",
        "documentType",
        "bankCode",
        "clientIp",
        "campaignId"
      ]
    }),
  RequestNewAmount: (meta = {}) =>
    new Serializer("payments", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["id", "reduceType", "newAmount"]
    })
};
