import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { Container, SelectContainer, ErrorInfo, Image } from "./Styles";

const Select = ({
  options,
  placeholder,
  error,
  tooltipPlace,
  disabled,
  positionImage,
  className,
  id,
  testId,
  disableArrowSelect,
  isSummary,
  isPourpose,
  hasborders,
  ...restProps
}) => {
  const { t } = useTranslation();
  return (
    <Container
      isDisabled={disabled}
      disableArrowSelect={disableArrowSelect}
      className={className}
      hasborders={hasborders}
      isSummary={isSummary}
      isPourpose={isPourpose}
    >
      <SelectContainer
        disabled={disabled}
        className={className}
        data-testId={testId}
        hasborders={hasborders}
        isSummary={isSummary}
        isPourpose={isPourpose}
        {...restProps}
      >
        {placeholder ? <option hidden={true}>{placeholder}</option> : null}
        {options.map((option) => {
          const optionText = `${option.text
            .charAt(0)
            .toUpperCase()}${option.text.slice(1)}`;
          return (
            <option key={option.id} value={option.value}>
              {optionText}
            </option>
          );
        })}
      </SelectContainer>
      {error && (
        <>
          <ErrorInfo data-tip={true} data-for={`errorInfo_${id}`}>
            <Image
              position={positionImage}
              src="/assets/images/error.png"
              alt="error"
            />
          </ErrorInfo>
          <ReactTooltip
            className="error-tooltip__back"
            id={`errorInfo_${id}`}
            place={tooltipPlace}
            type="error"
            effect="solid"
          >
            <div className="error-tooltip__container">
              <p className="error-tooltip__text--title">{t(error)}</p>
            </div>
          </ReactTooltip>
        </>
      )}
    </Container>
  );
};

Select.propTypes = {
  error: PropTypes.string,
  isPourpose: PropTypes.bool,
  isSummary: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  disableArrowSelect: PropTypes.bool,
  options: PropTypes.arrayOf({
    value: PropTypes.number,
    text: PropTypes.string
  }),
  tooltipPlace: PropTypes.string,
  id: PropTypes.string,
  positionImage: PropTypes.string,
  hasborders: PropTypes.bool
};

Select.defaultProps = {
  className: "",
  disableArrowSelect: false,
  disabled: false,
  error: "",
  id: "",
  options: [],
  placeholder: "",
  positionImage: "",
  testId: "",
  tooltipPlace: "left",
  isSummary: false,
  hasborders: false,
  isPourpose: false
};

export default Select;
