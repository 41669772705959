import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setMarginTab: ["marginTab"],
  setPositionTab: ["positionTab"],
  setActiveTab: ["activeTab"]
});

export const SectionTabsTypes = Types;
export default Creators;

const INITIAL_STATE = {
  marginTab: 0,
  positionTab: 0,
  activeTab: "showCase"
};

const setMarginTab = (state = INITIAL_STATE, { marginTab }) => ({
  ...state,
  marginTab
});

const setPositionTab = (state = INITIAL_STATE, { positionTab }) => ({
  ...state,
  positionTab
});

const setActiveTab = (state = INITIAL_STATE, { activeTab }) => ({
  ...state,
  activeTab
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_MARGIN_TAB]: setMarginTab,
  [Types.SET_POSITION_TAB]: setPositionTab,
  [Types.SET_ACTIVE_TAB]: setActiveTab
});
