import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Heading, Button } from "@bvcco/a2censo-component-lib";
import {
  Container,
  Separator,
  CompanyLogo,
  ProgressBar,
  Progress,
  MidContent,
  MidDescription,
  ButtonsRow,
  ButtonContainer,
  LogoContainer,
  BackgroundContainer,
  Gradient,
  HoverGradient,
  Background,
  ContentWraper
} from "./components";
import { types } from "./types";
import { BusinessLines, BusinessLinesIds } from "lib/models";
import { CampaignCardTags } from "./CampaignCardTags";
import { CampaignCardBottom } from "./CampaignCardBottom";
import { ComingSoon } from "../ComingSoon";
import { CountDown } from "../CountDown";
import { calculateSecondsDiff } from "lib/utils/date";
import { ReleaseDate } from "../ReleaseDate";
import { dateToMomentDate, colombianDateToGlobalDate } from "lib/utils/date";
import { useCountDown } from "hooks";
import { isMobile, isChrome } from "react-device-detect";
import { v4 } from "uuid";
import { Tooltip, Alb } from "../Tags/Tag/components";
import { useTranslation } from "react-i18next";
interface CampaignProps {
  name: string;
  collected: string;
  goal: string;
  image: string;
  logoPath: string;
  description: string;
  percentageOfInvestedAmount: number;
  requestedAmount: number;
  investorsNumber: number;
  state: number;
  closeDate: string;
  investedAmount: number;
  serverTime?: string;
  availableInvestmentDate?: string;
  businessLine: number;
}
interface Props {
  campaign: CampaignProps;
  buttons: any[];
  type: types;
}

export const CampaignCard = ({ campaign, buttons = [], type }: Props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const globalTime: number = useSelector(
    ({ investingCampaigns }: { investingCampaigns: { globalTime: number } }) =>
      investingCampaigns?.globalTime
  );
  const {
    name: campaignName,
    description,
    percentageOfInvestedAmount: percentage,
    logoPath,
    closeDate,
    investedAmount: investmentAmount,
    requestedAmount: requestAmount,
    state,
    investorsNumber: investorNumber,
    serverTime,
    availableInvestmentDate
  } = campaign;
  let secondsUntilPublish = useCountDown(
    calculateSecondsDiff(
      serverTime ? dateToMomentDate(serverTime) : new Date(),
      availableInvestmentDate ? availableInvestmentDate : new Date()
    )
  );
  
  const isPublished = secondsUntilPublish === 0;

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, [show]);

  const renderCommingSoon = () => <ComingSoon />;
  const idTooltip = v4();

  return (
    <Container
      type={type}
      businessLine={
        BusinessLinesIds[campaign.businessLine] || BusinessLines.Debt
      }
    >
      {!isPublished && show && renderCommingSoon()}
      <LogoContainer type={type}>
        <CompanyLogo src={logoPath} />
      </LogoContainer>
      <ContentWraper type={type}>
        <BackgroundContainer type={type}>
          <Gradient type={type} isPrePublish={!isPublished} />
          <HoverGradient />
          <Background img={campaign.image} />
        </BackgroundContainer>
        <MidContent type={type}>
          <CampaignCardTags
            businessLine={BusinessLinesIds[campaign.businessLine]}
            idTooltip={idTooltip}
          />
          <Heading
            color="white"
            hieranchy={type === "secondary" ? "tertiary" : "secondary"}
            fontWeight="bold"
          >
            {campaignName}
          </Heading>
          <MidDescription size="small" color="white" fontWeight="normal">
            {description}
          </MidDescription>
          <ButtonsRow isAvailable={!isPublished}>
            {buttons.map((button, i) =>
              (button.requirePublish === true && isPublished) ||
                !button.requirePublish ? (
                <ButtonContainer key={`featured-card-${i}`}>
                  <Button fontWeight="bold" {...button}>
                    {button.children}
                  </Button>
                </ButtonContainer>
              ) : null
            )}

            {!isPublished && (
              <CountDown remainingSeconds={secondsUntilPublish} />
            )}
          </ButtonsRow>
        </MidContent>
        <Separator />
        {!isPublished ? (
          <ReleaseDate
            date={colombianDateToGlobalDate(availableInvestmentDate)}
          />
        ) : (
          <CampaignCardBottom
            type={type}
            percentage={percentage}
            closeDate={closeDate}
            investmentAmount={investmentAmount}
            requestAmount={requestAmount}
            state={state}
            investorsNumber={investorNumber}
            businessLine={
              BusinessLinesIds[campaign.businessLine] || BusinessLines.Debt
            }
          />
        )}
        <ProgressBar>
          <Progress percentage={percentage} />
        </ProgressBar>
      </ContentWraper>
      <Alb forceCenter={true} isChrome={isChrome} background={"none"}>
          <Tooltip businessLine={campaign.businessLine}
          place={isMobile ? "bottom" : "top"}
          scrollHide={false}
          effect="solid"
          id={`tag-${idTooltip}`}
        >
          {t(`businessLines.descriptions.${BusinessLinesIds[campaign.businessLine]}`)}
        </Tooltip>
      </Alb>
    </Container>
  );
};
