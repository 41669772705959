import styled from "styled-components";

export const ContainerCard = styled.div`
  display: flex;
  margin-top: 22px;
  > div {
    margin-right: 19px;
  }
  ${({ theme }) => theme.smartphone} {
    > div {
      margin-right: 0px;
      margin-bottom: 16px;
    }
    flex-direction: column;
  }
`;
