/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import React, { useState } from "react";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PaginationContainer, Footer, FooterContent, Label } from "./Styles";
import InfiniteScroll from "../InfiniteScroll/InfiniteScroll";
import Pagination from "../pagination/pagination";
import ScrollBar from "../perfect-scrollbar/scrollbar";

const INFINITE_SCROLL = "infinite-scroll";

export const validateShouldFetch = (
  requestedPage,
  fromInfinite,
  stopRender,
  setStopRender,
  totalPages,
  currentPage,
  page,
  setPage,
  onUpdateList
) => {
  if (stopRender) {
    return;
  }

  if (fromInfinite && !stopRender) {
    setStopRender(true);
  }

  if (currentPage < totalPages || requestedPage < currentPage) {
    let nextPage = requestedPage;
    if (!nextPage) {
      nextPage = page + 1;
    }
    nextPage = nextPage > 0 ? nextPage : 1;
    setPage(nextPage);
    onUpdateList(nextPage);
  }
};

const ConditionalList = ({
  listType,
  children,
  maxHeight,
  onScrollTop,
  delay,
  loading,
  onUpdateList,
  itemsPerPage,
  pagesPerView,
  currentPage,
  totalPages,
  totalItems
}) => {
  const { t } = useTranslation();
  const isInfinite = listType === INFINITE_SCROLL;
  const [page, setPage] = useState(1);
  const [stopRender, setStopRender] = useState(false);

  const fetchNewItems = (requestedPage = 0, fromInfinite = false) => {
    validateShouldFetch(
      requestedPage,
      fromInfinite,
      stopRender,
      setStopRender,
      totalPages,
      currentPage,
      page,
      setPage,
      onUpdateList
    );
  };

  const cleanState = () => {
    if (stopRender) {
      setStopRender(false);
    }
  };

  return (
    <>
      {isInfinite ? (
        <ScrollBar
          id="infinite-scroll-bar-test"
          onYReachEnd={() => fetchNewItems(0, true)}
          onScrollDown={cleanState}
        >
          <InfiniteScroll
            id="infinite-scroll-conditional-list"
            onScrollDown={fetchNewItems}
            onScrollTop={onScrollTop}
            delay={delay}
            enable={isInfinite}
            maxHeight={maxHeight}
            conditionalList={true}
          >
            {children}
          </InfiniteScroll>
        </ScrollBar>
      ) : (
        <>
          {children}
          <PaginationContainer>
            <Pagination
              id="pagination"
              onClick={fetchNewItems}
              totalItems={totalItems}
              pageSelected={currentPage}
              itemsPerPage={itemsPerPage}
              pagesPerView={pagesPerView}
            />
          </PaginationContainer>
        </>
      )}
      {loading && (
        <Footer>
          <FooterContent>
            <Label>{t("movements.loading")}</Label>
          </FooterContent>
        </Footer>
      )}
    </>
  );
};

ConditionalList.propTypes = {
  listType: propTypes.string.isRequired,
  maxHeight: propTypes.number,
  children: propTypes.node.isRequired,
  delay: propTypes.number,
  onScrollTop: propTypes.func,
  loading: propTypes.bool,
  totalItems: propTypes.number,
  currentPage: propTypes.number.isRequired,
  itemsPerPage: propTypes.number,
  pagesPerView: propTypes.number,
  totalPages: propTypes.number.isRequired,
  onUpdateList: propTypes.func
};

ConditionalList.defaultProps = {
  maxHeight: "100vh",
  delay: 500,
  onScrollTop: () => {},
  onUpdateList: () => {},
  loading: false,
  totalItems: 0,
  itemsPerPage: 10,
  pagesPerView: 5
};

export default ConditionalList;
