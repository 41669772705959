import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import {
  Container,
  IconHeader,
  Paragraph,
  IconHeaderContainer,
  ButtonContainer,
  SubmitButton,
  Title
} from "./Styles";

import checkIcon from "assets/images/check-icon.png";

const UpdatePatrimonialRightsSuccess = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Container>
      <IconHeaderContainer>
        <IconHeader src={checkIcon} />
      </IconHeaderContainer>
      <Title>{t("patrimonialRights.receivedDocument")}</Title>
      <Paragraph>{t("patrimonialRights.receivedDocumentText1")}</Paragraph>
      <ButtonContainer>
        <SubmitButton
          id="payments-button"
          onClick={() => dispatch(push("/my-payments"))}
        >
          {t("patrimonialRights.myPayments")}
        </SubmitButton>
      </ButtonContainer>
    </Container>
  );
};

export default UpdatePatrimonialRightsSuccess;
