import styled from "styled-components";
import { types } from "../types";

type Container = {
  type: types;
};

export const CompanyLogo = styled.img`
  max-height: 100%;
`;

export const LogoContainer = styled.div<Container>`
  width: 70px;
  height: 70px;
  border-radius: 45px;
  background-color: ${({ theme }) => theme.colors.white.bgColor};
  overflow: hidden;
  position: absolute;
  right: 16px;
  top: 16px;
  box-shadow: 0 -1px 10px rgba(100, 100, 100, 0.1);
  z-index: 1;
  border: inherit !important;
  ${({ theme }) => theme.smartphone} {
    width: 50px;
    height: 50px;
  }
`;
