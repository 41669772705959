import * as Yup from "yup";
export const valuesSchema = (t) =>
  Yup.object().shape({
    phoneNumber: Yup.string()
      .required(t("myProfile.validations.required"))
      .test("phoneNumber", t("myProfile.validations.phoneNumber.invalidFormat"), (phoneNumber) => {
        if (phoneNumber && phoneNumber.length > 0) {
            const phone = phoneNumber.replace(/\D/g, "");
            return phone.match(/^[3][0-9]{7,9}$/);
            }
            return true;
        }
      ),
    email: Yup.string()
        .required(t("myProfile.validations.required"))
        .test("email", t("myProfile.validations.email.invalidFormat"), (email) => {
            if (email && email.length > 0) {
                return email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
            }
            return true;
        })
  });

