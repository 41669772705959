import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  saveRegisterData: ["formData"],
  eraseRegisterData: []
});

export const RegisterTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  formData: {
    name: "",
    lastname: "",
    secondLastname: "",
    documentType: "",
    documentNumber: "",
    dv: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    confirmDocument: "",
    firstNameContactPerson: "",
    secondNameContactPerson: "",
    lastnameContactPerson: "",
    secondLastnameContactPerson: "",
    emailContactPerson: "",
    phoneNumberContactPerson: ""
  }
};

const saveRegisterData = (state, { formData }) => ({ ...state, formData });

const eraseRegisterData = (state) => ({
  ...state,
  formData: INITIAL_STATE.formData
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_REGISTER_DATA]: saveRegisterData,
  [Types.ERASE_REGISTER_DATA]: eraseRegisterData
});
