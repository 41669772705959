import React from "react";
import {
  Container,
  Line,
  DocumentIMG,
  ContainerBody,
  ContainerIcons,
  ContainerArrow,
  ArrowIMG,
  BackgroundIMG
} from "./Styles";
import CardIMG from "assets/images/card-document.png";
import ArrowIMM from "assets/images/card-arrow.png";
import CardBackgroundIMG from "assets/images/card-document-background.png";
import { useTranslation } from "react-i18next";

import { Heading, Paragraph } from "@bvcco/a2censo-component-lib";

export type IProps = {
  title: string;
  count: number;
  onClick: () => void;
  id: string;
};

const Card = ({ title, count, onClick, id }: IProps) => {
  const { t } = useTranslation();
  const showTextByCount = (counter: Number) => {
    if (counter === 1) {
      return t("myCertificates.card.availableSingular");
    }
    return t("myCertificates.card.availablePlural");
  };

  return (
    <Container onClick={onClick} data-testid={`card-${id}`}>
      <ContainerBody>
        <ContainerIcons>
          <DocumentIMG src={CardIMG} />
          <ContainerArrow>
            <ArrowIMG src={ArrowIMM} />
          </ContainerArrow>
        </ContainerIcons>
        <Heading>{title}</Heading>
        <Paragraph dataTestId={`card-available-${id}`}>
          {`${count} ${showTextByCount(count)}`}
        </Paragraph>
      </ContainerBody>
      <BackgroundIMG src={CardBackgroundIMG} />
      <Line />
    </Container>
  );
};

Card.defaultProps = {
  count: 0
};

export default Card;
