const { Serializer } = require("jsonapi-serializer");

module.exports = {
  CodeValidationSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["token", "otp_code"]
    })
};
