import { createReducer, createActions } from "reduxsauce";
import {
  DOCUMENT_CLASSIFICATION_TYPE,
  GetTypeBlockFile,
  TypeBlockFile,
  StatesRequestDocuments,
  TypeDocumentId
} from "../../lib/models/campaign.model";
import User from "../../lib/models/user.model";

const { Types, Creators } = createActions({
  setUploadFileCampaign: ["file", "key"],
  removeUploadedFile: ["key"],
  updateProgressFile: ["fileKey", "progress"],
  setUploadFileError: ["fileKey", "error"],
  uploadFileSuccess: ["fileKey"],
  setRequirementDocuments: ["files"],
  setRequirementDocumentsAndAssociates: ["files"],
  removeRequirementDocument: ["documentRequestId", "associateId"],
  removeRequestDocument: ["documentRequestId"],
  addRequirementDocument: [
    "documentRequestId",
    "path",
    "documentStageId",
    "associateId"
  ],
  addRequestDocument: ["key", "path"],
  setRequestDocuments: ["files", "count"],
  onFetching: [],
  resetFileState: [],
  onModified: [],
  setValidateFilesComplete: [],
  removeRequiredDocument: ["documentRequestId"]
});

export const CampaignDocumentManagmentTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  stageFileUploadeds: [],
  requirementDocuments: [],
  documentRequestCampaign: [],
  requirementDocumentsAssociate: [],
  requirementDocumentsCodebtor: [],
  filesUploaded: [],
  count: {
    totalFiles: 0,
    pendingFiles: 0
  },
  isFetching: false,
  filesModified: false,
  filesCompleted: false
};

const validateRequestChangeAreValid = (files) => {
  const filesUploaded = files.filter(
    (file) =>
      file.pathFile &&
      file.type !== TypeBlockFile.download &&
      file.state !== StatesRequestDocuments.docRejected
  );

  const filesToUploaded = files.filter(
    (file) => file.type !== TypeBlockFile.download
  );

  return filesUploaded.length === filesToUploaded.length;
};

const setUploadFile = (state, { file, key }) => ({
  ...state,
  stageFileUploadeds: [
    ...(state.stageFileUploadeds || []),
    { file, key, error: null, progress: 0, isSuccess: false, isLoading: true }
  ]
});

const removeUploadedFile = (state, { key }) => {
  const { stageFileUploadeds } = state;

  const newFilesUploadeds = stageFileUploadeds.filter((f) => f.key !== key);
  return {
    ...state,
    stageFileUploadeds: newFilesUploadeds
  };
};

const updateProgressFile = (state, { fileKey, progress }) => {
  const { stageFileUploadeds } = state;
  const newState = stageFileUploadeds.map((item) => {
    if (item.key === fileKey) {
      return {
        ...item,
        progress,
        error: null
      };
    }
    return item;
  });
  return {
    ...state,
    stageFileUploadeds: newState
  };
};

const setFileError = (state, { fileKey, error = "error" }) => {
  const { stageFileUploadeds } = state;
  const newState = stageFileUploadeds.map((item) => {
    if (item.key === fileKey) {
      return {
        ...item,
        error,
        isLoading: false
      };
    }
    return item;
  });
  return {
    ...state,
    stageFileUploadeds: newState
  };
};

const uploadFileSuccess = (state, { fileKey }) => {
  const { stageFileUploadeds } = state;
  const newState = stageFileUploadeds.map((item) => {
    if (item.key === fileKey) {
      return {
        ...item,
        isSuccess: true,
        isLoading: false
      };
    }
    return item;
  });
  const docsUploaded = state.requirementDocuments.filter(
    (file) => file.state === StatesRequestDocuments.docLoaded
  );
  const filesCompleted =
    docsUploaded.length === state.requirementDocuments.length;
  return {
    ...state,
    stageFileUploadeds: newState,
    filesCompleted
  };
};

const setRequirementDocuments = (state, { files }) => {
  const [filesRequired, filesUploaded] = files;

  const newFilesRequired = [...filesRequired];

  newFilesRequired.forEach((file) => {
    file.type = GetTypeBlockFile(file.is_download, file.template_url);
  });
  if (filesUploaded[0]) {
    filesUploaded[0].documents.pyme.forEach((file) => {
      const fileUploaded = filesRequired.find(
        (f) => f.document_stage_id === file.document_stage_id
      );
      const docRequerimentIdx = newFilesRequired.findIndex(
        (f) => f.document_stage_id === file.document_stage_id
      );
      if (fileUploaded) {
        newFilesRequired[docRequerimentIdx] = {
          ...file,
          pathFile: file.path_file,
          documentRequestId: file.document_request_campaign_id,
          title: file.name
        };
      }
      return file;
    });
  }
  const documentsToLoad = files[0].filter(
    (file) => file.type_document_id === TypeDocumentId.adicional
  );
  const filesCompleted = validateRequestChangeAreValid(newFilesRequired);
  return {
    ...state,
    requirementDocuments: newFilesRequired,
    isFetching: false,
    filesCompleted,
    documentsToLoad
  };
};

const validateAssociateComplete = (associates) =>
  associates.every((associate) => {
    const filesUploaded = associate.documents.filter(
      (file) => !file.pathFile && file.type !== TypeBlockFile.download
    );

    return filesUploaded.length === 0;
  });

const setRequirementDocumentsAndAssociates = (state, { files }) => {
  const [filesRequired, associates = [], filesUploaded] = files;

  const classificationForAssociatesAndCodebtors = [
    DOCUMENT_CLASSIFICATION_TYPE.associatePN,
    DOCUMENT_CLASSIFICATION_TYPE.associatePJ,
    DOCUMENT_CLASSIFICATION_TYPE.codebtorPJ,
    DOCUMENT_CLASSIFICATION_TYPE.codebtorPN
  ];

  filesRequired.forEach((file) => {
    file.type = GetTypeBlockFile(file.is_download, file.template_url);
    return file;
  });

  let filesForPYME = filesRequired.filter(
    (file) =>
      !classificationForAssociatesAndCodebtors.includes(
        file.classification_person_type_id
      )
  );
  const codebtorFiles = filesRequired.filter((file) =>
    [
      DOCUMENT_CLASSIFICATION_TYPE.codebtorPN,
      DOCUMENT_CLASSIFICATION_TYPE.codebtorPJ
    ].includes(file.classification_person_type_id)
  );

  const documentsToLoad = files[0].filter(
    (file) => file.type_document_id === TypeDocumentId.adicional
  );

  const invalidDocumentTypes = [
    User.documentTypeName.NITE,
    User.documentTypeName.PA
  ];
  const associtatesWithDocuments = associates
    .filter(
      (associate) => !invalidDocumentTypes.includes(associate.document_type)
    )
    .map((associate) => {
      const newAssociate = associate;
      const isNaturalPerson = associate.person_type === User.type.natural;

      const classification = isNaturalPerson
        ? [DOCUMENT_CLASSIFICATION_TYPE.associatePN]
        : [DOCUMENT_CLASSIFICATION_TYPE.associatePJ];

      const filesForAssociates = filesRequired.filter((file) =>
        classification.includes(file.classification_person_type_id)
      );
      return {
        ...newAssociate,
        documents: filesForAssociates,
        fullName: `${associate.name} ${
          associate.last_name ? associate.last_name : ""
        }`
      };
    });

  let finalAssociateDocuments = [...associtatesWithDocuments];

  if (filesUploaded[0]) {
    const {
      documents: { associates: associatesUploadeds = [], pyme = [] }
    } = filesUploaded[0];
    filesForPYME = filesForPYME.map((pymeFile) => {
      const fileUploaded = pyme.find(
        (f) => f.document_stage_id === pymeFile.document_stage_id
      );
      if (fileUploaded) {
        return {
          ...pymeFile,
          pathFile: fileUploaded.path_file,
          documentRequestId: fileUploaded.document_request_campaign_id
        };
      }
      return pymeFile;
    });

    finalAssociateDocuments = associtatesWithDocuments.map((associateFile) => {
      const associateFiles = associateFile.documents.map((subDocument) => {
        const fileUploaded = associatesUploadeds.find(
          (f) =>
            f.document_stage_id === subDocument.document_stage_id &&
            f.associate_id === Number(associateFile.id)
        );

        if (fileUploaded) {
          return {
            ...subDocument,
            pathFile: fileUploaded.path_file,
            documentRequestId: fileUploaded.document_request_campaign_id
          };
        }
        return subDocument;
      });
      return {
        ...associateFile,
        documents: associateFiles
      };
    });
  }

  const filesAssociateCompleted = validateAssociateComplete(
    finalAssociateDocuments
  );

  const associateWithDocuments = finalAssociateDocuments.filter(
    (associate) => associate.documents.length > 0
  );

  const filesCompleted = validateRequestChangeAreValid(filesForPYME);

  return {
    ...state,
    filesUploaded,
    filesCompleted,
    requirementDocuments: filesForPYME,
    requirementDocumentsCodebtor: codebtorFiles,
    requirementDocumentsAssociate: associateWithDocuments,
    isFetching: false,
    filesAssociateCompleted,
    documentsToLoad
  };
};

const removeRequirementDocument = (
  state,
  { documentRequestId, associateId }
) => {
  const { requirementDocuments, requirementDocumentsAssociate } = state;
  const filesRequired = [...requirementDocuments];
  const associateDocuments = [...requirementDocumentsAssociate];

  let newAssociateDocuments = [...associateDocuments];
  if (associateId) {
    newAssociateDocuments = associateDocuments.map((associateFile) => {
      const documents = associateFile.documents.map((subDocument) => {
        if (
          subDocument.documentRequestId === documentRequestId &&
          Number(associateId) === Number(associateFile.id)
        ) {
          return {
            ...subDocument,
            pathFile: "",
            documentRequestId: ""
          };
        }
        return subDocument;
      });
      return {
        ...associateFile,
        documents
      };
    });
  }

  const newFilesRequireds = filesRequired.map((item) => {
    if (item.documentRequestId === documentRequestId) {
      return {
        ...item,
        pathFile: "",
        documentRequestId: ""
      };
    }
    return item;
  });

  const filesCompleted =
    newFilesRequireds.filter((item) => item.pathFile).length ===
    state.requirementDocuments.length;

  const filesAssociateCompleted = validateAssociateComplete(
    newAssociateDocuments
  );

  return {
    ...state,
    requirementDocuments: newFilesRequireds,
    requirementDocumentsAssociate: newAssociateDocuments,
    filesModified: true,
    filesCompleted,
    filesAssociateCompleted
  };
};

const removeRequiredDocument = (state, { documentRequestId }) => {
  const { requirementDocuments } = state;
  const filesRequired = [...requirementDocuments];

  const newFilesRequireds = filesRequired.map((item) => {
    if (item.documentRequestId === documentRequestId) {
      return { ...item, pathFile: "" };
    }
    return item;
  });

  return {
    ...state,
    requirementDocuments: newFilesRequireds,
    filesModified: true,
    filesCompleted: false
  };
};

const removeRequestDocument = (state, { documentRequestId }) => {
  const { documentRequestCampaign } = state;
  const newDocumentRequestCampaign = documentRequestCampaign.map((f) => {
    if (f.id === documentRequestId) {
      return {
        ...f,
        pathFile: ""
      };
    }
    return f;
  });

  return {
    ...state,
    documentRequestCampaign: newDocumentRequestCampaign,
    filesModified: true,
    filesCompleted: false
  };
};

const addRequirementDocument = (
  state,
  { documentRequestId, path, documentStageId, associateId }
) => {
  const { requirementDocuments, requirementDocumentsAssociate } = state;
  const filesRequired = [...requirementDocuments];

  let newAssociateDocuments = [...requirementDocumentsAssociate];
  if (associateId) {
    newAssociateDocuments = requirementDocumentsAssociate.map(
      (associateFile) => {
        const documents = associateFile.documents.map((subDocument) => {
          if (
            subDocument.document_stage_id === documentStageId &&
            associateFile.id === associateId
          ) {
            return {
              ...subDocument,
              pathFile: path,
              documentRequestId
            };
          }
          return subDocument;
        });
        return {
          ...associateFile,
          documents
        };
      }
    );
  }

  const newFilesRequireds = filesRequired.map((f) => {
    if (f.document_stage_id === documentStageId) {
      return {
        ...f,
        pathFile: path,
        documentRequestId
      };
    }
    return f;
  });

  const newFilesRequiredFiltered = newFilesRequireds.filter(
    (file) => file.type !== TypeBlockFile.download
  );

  const filesCompleted =
    newFilesRequireds.filter(
      (file) => !!file.pathFile && file.type !== TypeBlockFile.download
    ).length === newFilesRequiredFiltered.length;

  const filesAssociateCompleted = validateAssociateComplete(
    newAssociateDocuments
  );

  return {
    ...state,
    requirementDocuments: newFilesRequireds,
    requirementDocumentsAssociate: newAssociateDocuments,
    isFetching: false,
    filesModified: true,
    filesCompleted,
    filesAssociateCompleted
  };
};

const addRequestDocument = (state, { key, path }) => {
  const { documentRequestCampaign } = state;

  const nDocumentRequestCampaign = documentRequestCampaign.map((f) => {
    if (f.id === key) {
      return {
        ...f,
        pathFile: path,
        state: StatesRequestDocuments.docUploaded
      };
    }
    return f;
  });

  const filesCompleted = validateRequestChangeAreValid(
    nDocumentRequestCampaign
  );

  return {
    ...state,
    isFetching: false,
    filesModified: true,
    filesCompleted,
    documentRequestCampaign: nDocumentRequestCampaign
  };
};
const setRequestDocuments = (state, { files, count = {} }) => {
  const filesCompleted = validateRequestChangeAreValid(files);

  const documentsToUpdate = files.filter(
    (file) => file.state === StatesRequestDocuments.docRejected
  );

  const documentsToLoad = files.filter(
    (file) => file.state !== StatesRequestDocuments.docRejected
  );
  return {
    ...state,
    documentRequestCampaign: files,
    isFetching: false,
    count,
    filesCompleted,
    documentsToUpdate,
    documentsToLoad
  };
};

const setValidateFilesComplete = (state) => {
  const filesCompleted = validateRequestChangeAreValid(
    state.requirementDocuments
  );

  return {
    ...state,
    filesCompleted
  };
};

const onFetching = (state) => ({
  ...state,
  isFetching: true
});

const onModified = (state) => ({
  ...state,
  filesModified: false
});

const resetFileState = () => ({ ...INITIAL_STATE });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_UPLOAD_FILE_CAMPAIGN]: setUploadFile,
  [Types.REMOVE_UPLOADED_FILE]: removeUploadedFile,
  [Types.UPDATE_PROGRESS_FILE]: updateProgressFile,
  [Types.SET_UPLOAD_FILE_ERROR]: setFileError,
  [Types.UPLOAD_FILE_SUCCESS]: uploadFileSuccess,
  [Types.SET_REQUIREMENT_DOCUMENTS]: setRequirementDocuments,
  [Types.SET_REQUIREMENT_DOCUMENTS_AND_ASSOCIATES]: setRequirementDocumentsAndAssociates,
  [Types.REMOVE_REQUIREMENT_DOCUMENT]: removeRequirementDocument,
  [Types.REMOVE_REQUEST_DOCUMENT]: removeRequestDocument,
  [Types.ADD_REQUIREMENT_DOCUMENT]: addRequirementDocument,
  [Types.ADD_REQUEST_DOCUMENT]: addRequestDocument,
  [Types.SET_REQUEST_DOCUMENTS]: setRequestDocuments,
  [Types.ON_FETCHING]: onFetching,
  [Types.RESET_FILE_STATE]: resetFileState,
  [Types.ON_MODIFIED]: onModified,
  [Types.SET_VALIDATE_FILES_COMPLETE]: setValidateFilesComplete,
  [Types.REMOVE_REQUIRED_DOCUMENT]: removeRequiredDocument
});
