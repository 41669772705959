import styled from "styled-components";

export const Title = styled.h2`
  height: 52px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.mercury.bgColor};
  color: ${({ theme }) => theme.midGray};
`;
