import styled from "styled-components";

export const Container = styled.div`
  margin-top: 8px;

  button:disabled {
    opacity: 0.15;
  }
`;

export const Header = styled.div`
  margin: 21px 71px;
  display: flex;
  align-items: center;
  button {
    width: 192px;
    font-weight: 800;
    margin-left: auto;
  }
  ${({ theme }) => theme.smartphone} {
    margin: 21px 10px;
    flex-direction: column;
    button {
      width: 192px;
      font-weight: 800;
      margin-left: 0px;
    }
  }
`;

export const DottedSeparator = styled.p`
  color: ${({ theme }) => theme.dustyGray};
  overflow: hidden;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;
  &:after {
    content: "••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••";
    letter-spacing: 10px;
  }
`;

export const Footer = styled.div`
  background-color: ${({ theme }) => theme.lightGray};
  position: fixed;
  justify-content: center;
  align-items: center;
  height: 83px;
  bottom: 0;
  width: 100%;
  z-index: 10;
  padding: 0px 71px;
  ${({ theme }) => theme.smartphone} {
    padding: 0px 10px;
    height: 105px;
  }
`;

export const ContainerHelp = styled.div`
  & > p {
    width: 215px;
    font-size: 14px;
    line-height: 15px;
  }
  margin-top: 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.smartphone} {
    flex-direction: column;
  }
`;

export const BoldClickHere = styled.strong`
  text-decoration: underline;
  cursor: pointer;
`;

export const ContainerInfoGuide = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.royalBlue.bgColor};
  border-radius: 5px;
  width: 586px;
  height: 50px;
  border: 0px;
  & p {
    margin-left: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
  ${({ theme }) => theme.smartphone} {
    width: auto;
  }
`;

export const ButtonPlay = styled.button`
  background-color: ${({ theme }) => theme.brightTurquoise};
  border-radius: 5px;
  width: 54px;
  height: 50px;
  border: 0px;
  outline: none;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const ContainerPending = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 104px;
  margin-bottom: 120px;
  ${({ theme }) => theme.smartphone} {
    margin-top: 60px;
  }
  & > p:first-child {
    margin-top: 15px;
    font-weight: 600;
  }

  & > p {
    font-size: 18px;
    line-height: 25px;
    width: 100%;
  }

  & > button {
    margin-top: 15px;
    width: 166px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const LogoEmpty = styled.img`
  width: 287px;
  height: 181px;
`;

export const ContainerCampaings = styled.div`
  padding: 24px 61px;
  display: grid;
  row-gap: 20px;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  ${({ borderBottom }) => (borderBottom ? "margin-bottom: 100px;" : "")}

  & > div {
    margin-left: 10px;
    margin-top: 10px;
  }

  & button {
    max-width: 161px;
  }
`;

export const ButtonIcon = styled.img`
  height: 13px;
  width: 13px;
  object-fit: contain;
`;
