import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { PageTitle } from "../../styles/Styles";

const getInvestmentTagColor = (state, theme) => {
  switch (state) {
    case 1:
      return theme.robinsEggBlue;
    case 2:
      return theme.cornflowerBluee;
    case 3:
      return theme.dodgerBlue;
    default:
      return theme.transparentGray;
  }
};

const getInvestmentTagFontColor = (state, theme) => {
  if (state) {
    return theme.white;
  }
  else {
    return theme.riverBed;
  }
};

const getInvestmentStatusColor = (state, theme) => {
  switch (state) {
    case "pending":
      return theme.orange;
    case "active":
      return theme.brightTurquoise;
    case "canceled":
      return theme.violetRed;
    case "exchange":
      return theme.cornflowerBlue;
    default:
      return theme.orange;
  }
};

const getCampaignStateColor = (state, theme) => {
  switch (state) {
    case 1:
      return `
        color: ${theme.congressBlue};
        background-color: ${theme.congressBlueTransparent};
      `;
    case 2:
      return `
        color: ${theme.brightTurquoise};
        background-color: ${theme.brightTurquoiseTransparent};
      `;
    case 4:
      return `
        color: ${theme.red};
        background-color: ${theme.transparentRed};
      `;
    case 6:
      return `
        color: ${theme.violetRed};
        background-color: ${theme.violetRedTrans15};
      `;
    case 9:
      return `
        color: ${theme.violetRed};
        background-color: ${theme.violetRedTrans15};
      `;
    default:
      return `
        color: ${theme.tuatara};
        background-color: ${theme.transparentGray};
      `;
  }
};

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #F2F2F2;
  border-radius: 0 100px 0 0;
  padding: 40px 0px;
  ${(props) => props.theme.tablet} {
    border-radius: 0 50px 0 0;
    padding: 40px 27px;
  }
`;

export const Investments = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 91%;
  max-width: 1440px;
  padding: 0;

  ${(props) => props.theme.mediumScreens} {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  > div {
    > div {
      > div {
        *:checked ~ div {
          padding: 0;
          max-height: max-content;
          border-top: 1px dashed #cdcdcd;
        }
      }
    }
  }

  ${(props) => props.theme.desktopMin} {
    width: 100%;
  }
`;

export const Investment = styled.li`
  width: 100%;
  list-style: none;
  margin-bottom: 30px;
  ${(props) => props.theme.mediumScreens} {
    width: 49%;
  }
`;

export const InvestmentPaper = styled.div`
  background-color: ${(props) => props.theme.white};
  border-radius: 10px;
  padding: 15px;
`;

export const InvestmentDate = styled.p`
  font-family: "Roboto";
  font-size: 10px;
  padding: 10px 12px;
  ${(props) => props.theme.mediumScreens} {
    font-size: 14px;
  }
`;

export const InvestmentDestiny = styled.h3`
  font-size: 14px;
  ${(props) => props.theme.mediumScreens} {
    font-size: 17px;
  }
`;

export const InvestmentDestinyStrong = styled.span`
  color: ${(props) => props.theme.brightTurquoise};
  text-decoration: underline;
  margin-left: 3px;
  cursor: pointer;
`;

export const InvestmentCardRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InvestmentTags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;
  height: 32px;
  border-radius: 4px;
  font-size: 12px;
  font-family: Nunito;
  margin-right: 12px;
  ${(props) => `
    background: ${getInvestmentTagColor(props.state, props.theme)};
    color: ${getInvestmentTagFontColor(props.state, props.theme)};
  `} 
`;

export const InfoIconContainer = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  border-radius: 8px;
  ${(props) => `
    background-color: ${props.theme.white};
  `}
`;
export const InfoIcon = styled.div`
  width: 16px;
  height: 16px;
  padding: 3px;
  ${(props) => `
    background-color: ${getInvestmentTagColor(props.state, props.theme)};
  `}
  mask: url(/assets/images/info-2.svg) no-repeat center / contain;
  -webkit-mask: url(/assets/images/info-2.svg) no-repeat center / contain;
`;

export const InvestmentStatus = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Nunito;
  margin-left: auto;
  flex: 1;
  justify-content: flex-end;
  ${(props) => `
    color: ${getInvestmentStatusColor(props.state, props.theme)};
    &::before {
      height: 7px;
      width: 7px;
      background-color: ${getInvestmentStatusColor(props.state, props.theme)};
      content: " ";
      margin-right: 2%;
      border-radius: 10px;
    }
  `}
`;

export const CampaignStatus = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 12px;
  border-radius: 10px;
  padding: 5px 25px;
  margin-left: auto;
  ${(props) => getCampaignStateColor(props.state, props.theme)}
  ${(props) => props.theme.smartphone} {
    font-weight: normal;
    font-size: 10px;
    padding: 3px 10px;
  }
`;

export const Symbol = styled.span`
  margin-right: 10px;
`;

export const InvestmentContent = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.graySuit};
  padding: 10px 0;
`;

export const InvestmentInfoContent = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.graySuit};
  padding: 10px 0;
  display: flex;
`;

export const InvestmentInfoCol = styled.div`
  margin-right: 16px;
`;

export const InvestmentInfoColEquity = styled.div`
  margin-right: 16px;
  margin-left: auto;
  width: 115px;
`;

export const BigNumber = styled.h4`
  font-family: "Nunito";
  font-size: 24px;
  margin: 10px 0;
`;

export const InvestCardText = styled.span`
  font-family: "Roboto";
  font-size: 12px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  color: ${(props) => (props.color ? props.theme[props.color] : "inherit")};
  ${(props) => props.theme.mediumScreens} {
    font-size: 15px;
  }
`;

export const BarContainer = styled.div`
  margin-top: 10px;
`;

export const Bar = styled.div`
  width: 100%;
  height: 15px;
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.transparentGray};
`;

export const BarProgress = styled.div`
  width: ${(props) => props.progress}%;
  height: 15px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.springGreen};
`;

export const InvestButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;

export const CancelButton = styled.div`
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  color: ${(props) => props.theme.darkGray};
  text-decoration: underline;
  margin-top: 15px;
  font-size: 10px;
`;

export const HelpIcon = styled.img`
  height: 20px;
  width: 20px;
  margin: 26px 18px 26px 5px;
  ${(props) => props.theme.desktopMin} {
    margin: 26px 0 26px 18px;
  }
`;

export const InvestmentsPageTitle = styled(PageTitle)`
  padding-left: 12px;
  margin: 0 auto;
  width: 91%;
  max-width: 1110px;
  border-left: 4px solid ${({ theme }) => theme.brightTurquoise};
`;

export const SubtitleContainer = styled.div`  
  margin: 16px auto 24px;
  gap: 10px;
  width: 91%;
  max-width: 1110px;
  background: #FFFFFF;
  border-radius: 8px;
`;

export const PageSubtitle = styled.p`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475061;
  margin: 24px 0 24px 16px;
  ${(props) => props.theme.desktopMin} {
    width: 697px;
    font-size: 20px;
    margin: 24px 0 24px 32px;
  }
`;

export const TooltipContainer = styled.div`
  display: flex;
  padding: 2% 5%;
  margin: 0 auto;
  flex-direction: column;
  padding: 0 6px;
`;

export const TooltipText = styled.p`
  font-family: "Nunito";
  font-style: normal;
  text-align: center;
  width: 192px;
  height: auto;
  aling-items: center;
  display: flex;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 12px !important;
  white-space: pre-line;
  line-height: 16px;
  ${(props) => props.theme.smartphone} {
    font-size: 9px;
    width: 100%;
    font-weight: 600;
  }
`;

export const TooltipTitle = styled.div`
  color: ${(props) => props.theme.brightTurquoise};
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(to bottom, #ececec, #ececec 150px, #ffffff 150px);
`;

export const ButtonFooter = styled.button`
  width: 200px;
  height: 40px;
  background-color: ${(props) => props.theme.white};
  border-radius: 100px;
  border: none;
  color: ${(props) => props.theme.brightTurquoise};
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

`;

export const Footer = styled.div`
  background-color: #ade3f5;
  bottom: 0;
  height: 76px;
  position: fixed;
  width: 100%;
`;

export const FooterContent = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const InvestmentListContainer = styled.div`
  display: flex;
`;

export const Placeholder = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 436px;
  flex-wrap: wrap;
  width: 100%;

  ${({ theme }) => theme.smartphone} {
    height: 236px;
  }
`;

export const PlaceholderImage = styled.img`
  height: 150px;
  width: 126px;

  ${({ theme }) => theme.smartphone} {
    height: 60px;
    width: 60px;
  }
`;

export const PlaceholderMessage = styled.p`
  font-size: 36px;
  line-height: 40px;
  margin: 16px auto;
  text-align: center;
  width: 360px;

  color: ${({ theme }) => theme.riverBed};
  font-weight: bold;

  ${({ theme }) => theme.smartphone} {
    font-size: 16px;
    line-height: 23px;
    margin: 8px auto;
    width: 180px;
    text-align: center;
  }
`;

export const PlaceholderLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  font-size: 26px;

  margin-top: 16px;

  ${({ theme }) => theme.smartphone} {
    font-size: 14px;
    margin-top: 4px;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  width: 100%;
`;

export const StyledTooltips = styled(ReactTooltip)`
    background-color: #685e90 !important;
    border-radius: 8px;
    padding: 12px !important;
    width: 216px !important;
    height: auto !important;
    
    ${(props) => props.theme.smartphone} {
      left: 115px !important;
    }

    ${(props) => props.theme.min768Max990} {
      left: 519px !important
    }

    &::after {
      border-top-color: #685e90 !important;

      ${(props) => props.theme.smartphone} {
        left: 190px !important;
      }

      ${(props) => props.theme.min768Max990} {
        left: 160px !important
      }

    }
`;


export const SearchContainer = styled.div`
  width: 90%;
  margin: 0px auto 24px;
  max-width: 1110px;

  ${(props) => props.theme.tablet} {
    width: 91%;
    margin-bottom: 24px;
  }
`;

export const PreFooter = styled.div`
  width: 100%;
  height: 35px;
`;