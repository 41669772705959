import moment from "moment";
import User from "./models/user.model";
import { Errors } from "./utils/constants.errors";
import {
  calculateSecondsDiff,
  globalDateToColombianDate
} from "lib/utils/date";

export const formatToDotSeparated = (number) => {
  const unformatedValue = parseInt(number.replace(/\./g, ""), 10);
  const limitedValue = parseInt(unformatedValue.toString().slice(0, 12), 10);
  return limitedValue.toLocaleString("es-CO").replace(/,/g, ".");
};

export const formatNumber = (num) =>
  num.toString().replace(/(\d)(?=(\d{3}){1,}(?!\d))/g, "$1.");

export const emailValidate = (value) => {
  try {
    const [first, second] = value.split("@");
    const emailRegEx = /^[^@ ]+@[^@ ]+.[^@ .]+$/;
    return (
      emailRegEx.test(value) &&
      first &&
      second &&
      first.length >= 1 &&
      first.length <= 64 &&
      second.length >= 1 &&
      second.length <= 254
    );
  } catch (e) {
    return false;
  }
};

export const validateCiiu = (ciiu, personType, ciiuCodes) => {
  let filterCode;
  if (personType === User.type.natural) {
    // eslint-disable-next-line no-underscore-dangle
    filterCode = ciiuCodes._ciiu_code_natural.filter(
      (item) => item.id === ciiu
    );
    const ciiuCode = filterCode[0];
    if (ciiuCode?.allowed_ciiu) {
      const allowedCiiu = JSON.parse(ciiuCode.allowed_ciiu);
      return allowedCiiu?.natural;
    }

    return filterCode.length > 0;
  }

  if (personType === User.type.legal) {
    // eslint-disable-next-line no-underscore-dangle
    filterCode = ciiuCodes._ciiu_code_legal.filter((item) => item.id === ciiu);

    const ciiuCode = filterCode[0];
    if (ciiuCode?.allowed_ciiu) {
      const allowedCiiu = JSON.parse(ciiuCode.allowed_ciiu);
      return allowedCiiu?.legal;
    }

    return !ciiu || ciiu === "" ? true : filterCode.length > 0;
  }
};

export const validateFile = (
  file,
  limitFileSize,
  allowedFormats = ["pdf", "jpg", "jpeg"]
) => {
  if (!file) {
    return "investment.validations.fileSize.required";
  }
  let isValidFormat = false;
  if (file.name) {
    isValidFormat = allowedFormats.includes(
      /[.]/.exec(file.name)
        ? `${/[^.]+$/.exec(file.name)}`.toLowerCase()
        : undefined
    );
  }

  if (!isValidFormat) {
    return "investment.validations.format.invalid";
  }

  if (file.size > limitFileSize) {
    return "investment.validations.fileSize.invalid";
  }

  return false;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const formatResult = (result) =>
  result.toString()[0] === "0"
    ? result
    : formatNumber(
        Number(result)
          .toFixed(2)
          .toString()
          .replace(".", ",")
      );

export const formatAmount = (val) => {
  const amount = Number(val)
    .toFixed(2)
    .replace(".", ",");
  return `$${
    amount.toString() !== "NaN"
      ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      : ""
  }`;
};

export const formatAmount2 = (val) => {
  const amount = Number(val)
    .toFixed(2)
    .replace(".", ",");
  return `${
    amount.toString() !== "NaN"
      ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      : ""
  }`;
};

// GitHub: https://github.com/gabrielizalo/calculo-digito-de-verificacion-dian
export const calculateVerificationCode = (myNit) => {
  let formatNit = String(myNit);
  formatNit = formatNit.replace(/\s/g, ""); // Espacios
  formatNit = formatNit.replace(/,/g, ""); // Comas
  formatNit = formatNit.replace(/\./g, ""); // Puntos
  formatNit = formatNit.replace(/-/g, ""); // Guiones
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(formatNit)) {
    return false;
  }

  const vpri = new Array(16);
  const z = myNit.length;

  vpri[1] = 3;
  vpri[2] = 7;
  vpri[3] = 13;
  vpri[4] = 17;
  vpri[5] = 19;
  vpri[6] = 23;
  vpri[7] = 29;
  vpri[8] = 37;
  vpri[9] = 41;
  vpri[10] = 43;
  vpri[11] = 47;
  vpri[12] = 53;
  vpri[13] = 59;
  vpri[14] = 67;
  vpri[15] = 71;

  let x = 0;
  let y = 0;
  for (let i = 0; i < z; i++) {
    y = myNit.substr(i, 1);
    x += y * vpri[z - i];
  }

  y = x % 11;
  return y > 1 ? 11 - y : y;
};

export const getNitVerificationCode = (nit) => {
  const isNitValid = Number.isInteger(parseInt(nit, 0));
  if (isNitValid) {
    return calculateVerificationCode(nit);
  }
  return false;
};

export const slidesToShow = (breakpoint, newsLength) => {
  if (breakpoint === 1024 && newsLength >= 3) {
    return 3;
  }

  if (
    (breakpoint === 768 && newsLength >= 2) ||
    (breakpoint === 1024 && newsLength === 2)
  ) {
    return 2;
  }

  return 1;
};

export const formatPhoneNumber = (phoneNumber) => {
  const phoneNumberFormatted = [];
  const phoneNumberLength = Number(phoneNumber.length);
  const getLastTwoNumbers = phoneNumberLength - 2;
  const firstPhoneNumbers = phoneNumber.substring(0, getLastTwoNumbers);
  const lastPhoneNumbers = phoneNumber.substring(getLastTwoNumbers);

  phoneNumberFormatted.push(
    firstPhoneNumbers.replace(/[0-9]/gi, "X"),
    lastPhoneNumbers
  );
  return phoneNumberFormatted.join("");
};

const WEEKEND_DAYS = {
  SATURDAY: 6,
  SUNDAY: 0
};

export const getAvailableBusinessDay = (currentDate, holidays) => {
  const currentYear = `${Number(moment(currentDate).year())}`;
  const currentMonth = `${Number(moment(currentDate).month()) + 1}`;
  const currentDay = Number(moment(currentDate).format("DD"));

  let isHoliday = false;

  if (holidays[currentYear] && holidays[currentYear][currentMonth]) {
    const month = holidays[currentYear][currentMonth];
    const holidayMonth = Array.isArray(month)
      ? month
      : month?.split(",").map((value) => value?.trim());

    isHoliday = holidayMonth?.includes(`${currentDay}`);
  }

  const dayIndex = Number(moment(currentDate).day());
  const isWeekend =
    dayIndex === WEEKEND_DAYS.SATURDAY || dayIndex === WEEKEND_DAYS.SUNDAY;
  if (isHoliday || isWeekend) {
    const nextDay = moment(currentDate)
      .add(1, "days")
      .format("YYYY-MM-DD");
    return getAvailableBusinessDay(nextDay, holidays);
  }
  return currentDate;
};

export const formatHolidays = (holidaysList) => {
  const holidaysItem = {};

  holidaysList.forEach((item) => {
    holidaysItem[item.year] = {
      ...(holidaysItem[item.year] ? holidaysItem[item.year] : {}),
      [item.month]: item.days
    };
  });
  return holidaysItem;
};

export const differenceForPayments = (date, holidays) => {
  const holidayListFormatted = formatHolidays(holidays);
  const businessDay = getAvailableBusinessDay(date, holidayListFormatted);
  const days = Math.ceil(
    moment(date)
      .startOf("day")
      .diff(moment().startOf("day"), "days", true)
  );
  const businessDays = Math.ceil(
    moment(businessDay)
      .startOf("day")
      .diff(moment().startOf("day"), "days", true)
  );
  return {
    days,
    businessDays,
    extended: !moment(businessDay).isSame(date),
    limitDate: businessDay
  };
};

export const findIconByType = (fileType) => {
  switch (fileType) {
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "/assets/images/wordIcon.png";
    case "application/pdf":
      return "/assets/images/pdf.png";
    case "image":
      return "/assets/images/imageIcon.png";
    default:
      return "/assets/images/excel-image@3x.png";
  }
};

export const findIconTextByType = (fileType) => {
  switch (fileType) {
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "patrimonialRights.wordFile";
    case "application/pdf":
      return "patrimonialRights.pdfFile";
    case "image":
      return "patrimonialRights.imageFile";
    default:
      return "patrimonialRights.excelFile";
  }
};

export const initialTimer = (dateStart,dateEnd) => {
  const secs = calculateSecondsDiff(
    globalDateToColombianDate(dateStart),
    globalDateToColombianDate(dateEnd)
  );

  const hours = Math.floor(secs / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  return (
    (hours < 10 ? "0"+hours:hours.toString())+":"+
    (minutes < 10 ? "0"+minutes:minutes.toString())+":"+
    (seconds < 10 ? "0"+seconds:seconds.toString())
  );
};

export const capitalizeWord = (word, lower = true) =>
  (lower ? word.toLowerCase() : word).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  );

export const getErrorIndex = (e) => {
  let hasErrorIndex = -1;
  if (Array.isArray(e.errors)) {
    e.errors.map((error, indexError) => {
      const index = Errors.findIndex((value) => error.code === value);
      if (index >= 0) {
        hasErrorIndex = indexError;
      }
      return error;
    });
  }
  return hasErrorIndex;
};
export default {
  formatToDotSeparated,
  emailValidate,
  toBase64,
  formatResult,
  validateFile,
  formatAmount,
  formatAmount2,
  getNitVerificationCode,
  calculateVerificationCode,
  slidesToShow,
  formatPhoneNumber,
  differenceForPayments,
  findIconByType,
  findIconTextByType,
  initialTimer
};
