import * as Yup from "yup";
import UserModel from "../../lib/models/user.model";
import { getNitVerificationCode } from "../../lib/utils";
import { numericValue } from "../../lib/formatter";

export default (personType, availableMoney) => {
  let registerSchema = {
    amount: Yup.string()
      .trim()
      .min(1, "moneyWithdrawal.validations.amount.required")
      .test(
        "amountValidation",
        "moneyWithdrawal.validations.amount.invalidDecimal",
        (val) => {
          return (!(val.endsWith(",") && !val.match(/,\d+$/)));
        }
      )
      .test(
        "amountValidation",
        "moneyWithdrawal.validations.amount.required",
        (val) => {
          const amount = Number(numericValue(val));
          return amount > 0;
        }
      )
      .test(
        "amountValidation",
        "moneyWithdrawal.validations.amount.invalidAmount",
        (val) => {
          const amount = Number(numericValue(val));
          return amount <= availableMoney;
        }
      )
      .required("investment.validations.amount.required"),
    personType: Yup.string().required(),
    bank: Yup.string().required("moneyWithdrawal.validations.bank.required"),
    bankAccountType: Yup.string().required(
      "moneyWithdrawal.validations.bankAccountType.required"
    ),
    bankNumber: Yup.string("moneyWithdrawal.validations.number")
      .matches(/^[0-9]{6,30}$/, "moneyWithdrawal.validations.bankNumber.minMax")
      .required("moneyWithdrawal.validations.bankNumber.required"),
    confirmBankNumber: Yup.string("moneyWithdrawal.confirmBankNumber")
      .matches(/^[0-9]{6,30}$/, "moneyWithdrawal.validations.bankNumber.minMax")
      .trim()
      .oneOf(
        [Yup.ref("bankNumber")],
        "moneyWithdrawal.validations.confirmBankNumber.oneOf"
      )
      .required("moneyWithdrawal.validations.confirmBankNumber.required"),

    documentType: Yup.string("moneyWithdrawal.documentType").required(
      "moneyWithdrawal.validations.documentType.required"
    ),
    documentNumber: Yup.string("moneyWithdrawal.documentNumber")
      .matches(/[0-9.,$]{6,30}$/, "moneyWithdrawal.validations.number")
      .required("moneyWithdrawal.validations.documentNumber.required")
  };
  if (personType === UserModel.type.natural) {
    registerSchema = {
      ...registerSchema,
      names: Yup.string("moneyWithdrawal.names").required(
        "moneyWithdrawal.validations.names.required"
      ),
      firstLastName: Yup.string("moneyWithdrawal.firstLastName").required(
        "moneyWithdrawal.validations.firstLastName.required"
      ),
      secondLastName: Yup.string("moneyWithdrawal.secondLastName")
    };
  }

  if (personType !== UserModel.type.natural) {
    registerSchema = {
      ...registerSchema,
      companyName: Yup.string("moneyWithdrawal.names").required(
        "moneyWithdrawal.validations.names.required"
      ),
      dv2: Yup.number("register.validations.number")
        .typeError("register.validations.number")
        .min(0, "register.validations.dv.min")
        .max(9, "register.validations.dv.max")
        .test("length", "register.validations.dv.invalid", function test(
          value
        ) {
          const validVerificationCode = getNitVerificationCode(
            this.parent.documentNumber
          );
          if (validVerificationCode === value) {
            return true;
          }
          return false;
        })
        .required("register.validations.dv.required")
    };
  }

  return Yup.object().shape(registerSchema);
};
