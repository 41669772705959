import { createReducer, createActions } from "reduxsauce";

export const { Types, Creators } = createActions({
  startBalanceFlow: [],
  endBalanceFlow: ["balance"],
  errorBalanceFlow: ["error"]
});

export const myBalanceTypes = Types;
export default Creators;

const INITIAL_STATE = {
  availableMoney: 0,
  investments: 0,
  availableMoneyExchange: 0,
  investmentsExchange: 0,
  generalBalance: 0,
  debt: 0,
  equity: 0
};

const setBalance = (state, { balance }) => {
  const {
    availableMoney,
    investments,
    availableMoneyExchange,
    investmentsExchange,
    generalBalance,
    debt,
    equity
  } = balance;
  return {
    ...state,
    availableMoney,
    investments,
    availableMoneyExchange,
    investmentsExchange,
    generalBalance,
    debt,
    equity
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.END_BALANCE_FLOW]: setBalance
});
