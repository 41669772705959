import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "./components";
import { TabsList } from "./TabsList";
import sectionTabsActions from "redux/reducers/section-tabs.reducer";
import { push } from "connected-react-router";
import homeSelectionsActions from "redux/reducers/home-selections.reducer";

enum MobileTabProperties {
  ButtonWidth = 228,
  ButtonMargin = 15
}

type ITabsProps = {
  id: string;
  title: string;
  tooltip?: string;
  url: string;
  disableTooltip?: boolean;
};

type ITabs = {
  tabs: Array<ITabsProps>;
  onChangeTab?: Function;
  activeTab?: string;
};

const getScrollTab = (leftPosition: number) => {
  const element = document.getElementById("tabs-list-container");
  element?.scroll({ left: leftPosition, behavior: "smooth" });
};

export const SectionTabs = ({ tabs, onChangeTab, activeTab }: ITabs) => {
  const dispatch = useDispatch();

  const { marginTab, positionTab } = useSelector(
    ({ sectionTabs }: any) => sectionTabs
  );

  const currentSelection: string = useSelector(
    ({ homeSelections: { mainTabSelection } }: any) => mainTabSelection
  );

  useEffect(() => {
    getScrollTab(marginTab);
  }, [marginTab, currentSelection]);

  const selectTab = (tabId: string, tabsList: Array<ITabsProps>) => {
    const hasUrl = tabsList.find(({ id }: ITabsProps) => id === tabId)?.url;
    const shouldAvoidScroll = tabId === "showCase";
    dispatch(homeSelectionsActions.setAvoidScrollToTop(shouldAvoidScroll));
    if (hasUrl) {
      dispatch(push(hasUrl));
    }

    if (onChangeTab) {
      onChangeTab(tabId);
    }
  };

  const moveTab = (tabId: string, tabsList: Array<ITabsProps>) => {
    const tabSelected = tabsList.findIndex(
      ({ id }: ITabsProps) => id === tabId
    );

    if (!isMobile || positionTab === tabSelected) {
      return;
    }

    const buttonWidth = MobileTabProperties.ButtonWidth * tabSelected;
    const buttonMargin = MobileTabProperties.ButtonMargin * tabSelected;
    const leftPosition = buttonWidth + buttonMargin;

    getScrollTab(leftPosition);
    dispatch(sectionTabsActions.setMarginTab(leftPosition));
    dispatch(sectionTabsActions.setPositionTab(+tabSelected));
  };

  const onClickTab = (element: any) => {
    const tabId = element.currentTarget.getAttribute("id");
    selectTab(tabId, tabs);
    if (tabs.length > 2) {
      moveTab(tabId, tabs);
    }
  };

  return (
    <Container id="tabs-container">
      <TabsList tabs={tabs} OnClickTab={onClickTab} activeId={activeTab} />
    </Container>
  );
};
