import { createReducer, createActions } from "reduxsauce";
import { IFilters, FilterStore } from "./types";

const { Types, Creators } = createActions({
  setFiltersCampaigns: ["homeFilters"],
  setSubFiltersCampaigns: ["homeSubFilters"]
});

export const HomeFilters = Types;
export default Creators;

const INITIAL_STATE: FilterStore = {
  homeFilters: []
};

const setFiltersCampaigns = (
  state: FilterStore,
  { homeFilters }: { homeFilters: IFilters[] }
) => ({
  ...state,
  homeFilters
});
export const filtersReducer = createReducer(INITIAL_STATE, {
  [Types.SET_FILTERS_CAMPAIGNS]: setFiltersCampaigns
});
