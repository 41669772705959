import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { forkJoin } from "rxjs";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import PlacesActions from "../reducers/places.reducer";
import EnrollmentActions from "../reducers/enrollment.reducer";
import ModalActions from "../reducers/modal.reducer";
import LoginActions from "../reducers/login.reducer";
import AssociateActions from "../reducers/associate.reducer";
import AnalyticsActions from "../reducers/analytics.reducer";
import AnalyticsModel from "../../lib/models/analytics.model";
import { getErrorIndex } from "../../lib/utils";
import paramNames from "../../lib/models/parameter.model";
import AlertActions from "../reducers/alert.reducer";

const {
  QUERIES: { citiesByCountryId },
  COUNTRY_ID: { COL },
  HASHED_CODE_PARAMS
} = paramNames;

const { _PARAM_002 } = HASHED_CODE_PARAMS();

export const initialAssociateDataForm = ({ tableNames }) => ({
  type: "INITIAL_ASSOCIATE_DATA",
  payload: {
    tableNames
  }
});

export const saveAssociateDataForm = ({ ...associateInfo }) => ({
  type: "SAVE_ASSOCIATE_DATA",
  payload: {
    associateInfo
  }
});

export const associateDataFail = ({ error }) => ({
  type: "ASSOCIATE_DATA_FAIL",
  payload: {
    error
  }
});

export const startDeleteAssociate = ({ id }) => ({
  type: "START_DELETE_ASSOCIATE",
  payload: {
    id
  }
});

export const initialAssociateData = (action$) =>
  action$.pipe(
    ofType("INITIAL_ASSOCIATE_DATA"),
    flatMap((action) =>
      forkJoin([
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: { tables: action.payload.tableNames }
          })
        ),
        Observable.from(App.api.a2censo.getUSInformationQuestions()),
        Observable.from(App.api.a2censo.getPEPInformationQuestions()),
        Observable.from(App.api.a2censo.getAssociates()),
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: { tables: `${_PARAM_002}&${citiesByCountryId}=${COL}` }
          })
        )
      ]).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(PlacesActions.setDepartmentsInfo([])),
            Observable.of(PlacesActions.setCitiesInfo([])),
            Observable.of(
              push("/enrollment/associate", {
                response
              })
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/"
                })
              ),
              Observable.of(associateDataFail(e))
            );
          }
          return Observable.concat(
            Observable.of(associateDataFail(e)),
            Observable.of(push("/oops"))
          );
        })
      )
    )
  );

export const saveAssociateData = (action$) =>
  action$.pipe(
    ofType("SAVE_ASSOCIATE_DATA"),
    flatMap((action) =>
      forkJoin([
        Observable.of(AssociateActions.setLoadingAssociateDataForm(true)),
        Observable.from(
          App.api.a2censo.saveAssociate({
            body: {
              meta: {},
              data: {
                attributes: {
                  document_number: action.payload.associateInfo.document_number,
                  dv: action.payload.associateInfo.dv,
                  document_type: action.payload.associateInfo.document_type,
                  name: action.payload.associateInfo.name,
                  second_name: action.payload.associateInfo.second_name,
                  last_name: action.payload.associateInfo.last_name,
                  second_last_name:
                    action.payload.associateInfo.second_last_name,
                  document_expedition_date:
                    action.payload.associateInfo.document_expedition_date,
                  expedition_place:
                    action.payload.associateInfo.expedition_place,
                  expedition_country:
                    action.payload.associateInfo.expedition_country,
                  manage_public_resources:
                    action.payload.associateInfo.manage_public_resources,
                  send_usa_resources:
                    action.payload.associateInfo.send_usa_resources,
                  publicly_exposed_person:
                    action.payload.associateInfo.publicly_exposed_person,
                  publicly_exposed_person_options: {
                    selected_option: {
                      public_figure_question_id:
                        action.payload.associateInfo.public_figure_question_id
                    }
                  },
                  fatca: {
                    selected_option: action.payload.associateInfo
                      .fatca_selected_option
                      ? action.payload.associateInfo.fatca_selected_option.map(
                          (item) => ({
                            ...item,
                            answer: Number(item.answer)
                          })
                        )
                      : []
                  },
                  public_figure_family_member:
                    action.payload.associateInfo.public_figure_family_member,
                  person_type: action.payload.associateInfo.personType,
                  percentage_shareholding:
                    action.payload.associateInfo.share_holding,
                  city_id: action.payload.associateInfo.city,
                  ciuu_code: action.payload.associateInfo.ciiu
                }
              }
            }
          })
        )
      ]).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(
              AnalyticsActions.trackEvent(AnalyticsModel.saveAssociate)
            ),
            Observable.of(AssociateActions.setLoadingAssociateDataForm(false)),
            Observable.of(push("/enrollment/organizational-structure"))
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.concat(
              Observable.of(
                AssociateActions.setLoadingAssociateDataForm(false)
              ),
              Observable.of(LoginActions.loginReset())
            );
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                AssociateActions.setLoadingAssociateDataForm(false)
              ),
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "associate.errorTitle",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/enrollment/organizational-structure"
                })
              ),
              Observable.of(associateDataFail(e))
            );
          }
          return Observable.concat(
            Observable.of(associateDataFail(e)),
            Observable.of(AlertActions.setAlertState("error", "alert.tryAgain"))
          );
        })
      )
    )
  );

export const deleteAssociate = (action$) =>
  action$
    .pipe(
      ofType("START_DELETE_ASSOCIATE"),
      flatMap((action) => {
        const params = {
          url: {
            id: action.payload.id
          }
        };
        return Observable.concat(
          Observable.of(
            ModalActions.setConditionalModalState(true, "LoaderPage")
          ),
          Observable.from(App.api.a2censo.deleteAssociate(params))
        );
      })
    )
    .pipe(
      flatMap(() => Observable.of(EnrollmentActions.organizationalStructure())),
      catchError((e) => {
        if (e.statusCode === 401) {
          return Observable.concat(
            Observable.of(LoginActions.loginReset()),
            Observable.of(push("/"))
          );
        }
        const hasErrorIndex = getErrorIndex(e);
        if (hasErrorIndex > -1) {
          return Observable.of(
            ModalActions.setConditionalModalState(true, "ErrorModal", {
              title: "errorModal.title",
              content: `errorCodes.${e.errors[hasErrorIndex].code}`,
              buttonText: "errorModal.success"
            })
          );
        }
        return Observable.of(
          push("/oops", {
            subtitle: "associate.oopsSubtitle",
            message: "associate.oopsMessage",
            hideButton: false
          })
        );
      })
    );

export default combineEpics(
  initialAssociateData,
  saveAssociateData,
  deleteAssociate
);
