import styled, { css } from "styled-components";

export const ImageEmpty = styled.img`
  width: 182px;
`;

export const ContainerEmpty = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.smartphone} {
    height: 40vh;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.mako};
    width: 296px;
    text-align: center;
    margin-top: 10px;
  }
`;

export const ContainerFilter = styled.div`
  width: 90%;
  margin: auto;
  max-width: 1221px;
  background: ${({ theme }) => theme.white};
  align-items: center;
  justify-content: space-between;
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 14px 47px -28px rgba(0, 0, 0, 0.25);
  position: absolute;

  ${({ theme }) => theme.smartphone} {
    height: 169px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 19px 27px 20px;
  }
`;