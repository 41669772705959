/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import React from "react";
import propTypes from "prop-types";
import { timer } from "rxjs";
import { InfiniteScrollContainer } from "./Styles";

const PIXEL_ERROR = -16; // mobile scroll pixel error
export const handleScroll = ({
  event,
  containerEl,
  elOffsetHeight,
  wInnerHeight,
  onScrollDown,
  onScrollTop,
  timerProps,
  delay,
  orientation,
  elOffsetWidth,
  wInnerWidth
}) => {
  if (!containerEl || !event) {
    return;
  }

  let currentScroll;
  let elOffset;
  let wInner;
  let containerOffset;
  let scrollSize;
  if (orientation === "x") {
    containerOffset = containerEl.offsetWidth;
    currentScroll = event.target.scrollLeft;
    elOffset = elOffsetWidth;
    scrollSize = event.target.scrollWidth;
    wInner = wInnerWidth;
  } else {
    containerOffset = containerEl.offsetHeight;
    currentScroll = event.target.scrollTop;
    scrollSize = event.target.scrollHeight;
    elOffset = elOffsetHeight;
    wInner = wInnerHeight;
  }

  if (elOffset === wInner + currentScroll) {
    timerProps.scrollTimer = timer(delay);
    if (timerProps.scrollTimerSubscription) {
      timerProps.scrollTimerSubscription.unsubscribe();
    }
    if (delay) {
      timerProps.scrollTimerSubscription = timerProps.scrollTimer.subscribe(
        onScrollTop
      );
    } else {
      onScrollTop();
    }
  }
  if (Math.floor(containerOffset + currentScroll) - scrollSize > PIXEL_ERROR) {
    timerProps.scrollTimer = timer(delay);
    if (timerProps.scrollTimerSubscription) {
      timerProps.scrollTimerSubscription.unsubscribe();
    }
    if (delay) {
      timerProps.scrollTimerSubscription = timerProps.scrollTimer.subscribe(
        onScrollDown
      );
    } else {
      onScrollDown();
    }
  }
};

const InfiniteScroll = ({
  children,
  maxHeight,
  maxWidth,
  onScrollDown,
  onScrollTop,
  delay,
  enable,
  conditionalList,
  orientation,
  className,
  onScroll,
  customRef
}) => {
  const timerProps = {
    scrollTimer: timer(delay),
    scrollTimerSubscription: null
  };

  if (enable) {
    return (
      <InfiniteScrollContainer
        className={className}
        orientation={orientation}
        id="infinite-scroll-container"
        data-testid="infinite-scroll-container"
        ref={customRef}
        onScroll={(event) => {
          onScroll(event);
          handleScroll({
            containerEl: document.getElementById("infinite-scroll-container"),
            elOffsetHeight: document.documentElement.offsetHeight,
            wInnerHeight: window.innerHeight,
            event,
            onScrollDown,
            onScrollTop,
            timerProps,
            orientation,
            elOffsetWidth: document.documentElement.offsetWidth,
            wInnerWidth: window.innerWidth
          });
        }}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        conditionalList={conditionalList}
      >
        {children}
      </InfiniteScrollContainer>
    );
  }
  return <>{children}</>;
};

InfiniteScroll.propTypes = {
  maxHeight: propTypes.string,
  children: propTypes.node.isRequired,
  delay: propTypes.number,
  onScrollDown: propTypes.func,
  onScrollTop: propTypes.func,
  onScroll: propTypes.func,
  enable: propTypes.bool,
  conditionalList: propTypes.bool,
  orientation: propTypes.string,
  maxWidth: propTypes.string,
  className: propTypes.string,
  customRef: propTypes.ref
};

InfiniteScroll.defaultProps = {
  enable: true,
  delay: 500,
  onScrollDown: () => {},
  onScrollTop: () => {},
  onScroll: () => {},
  conditionalList: false,
  orientation: "y",
  maxWidth: "100vw",
  maxHeight: "100vh",
  className: "",
  customRef: null
};

export default InfiniteScroll;
