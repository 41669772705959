export const getOtherPicture = (other, storeInfo) => {
  const found = storeInfo.others.find((item) => item.name === other.name);

  if (found) {
    return found.picture;
  }
  return "";
};

export const infoFetchSerializer = (data, taxAuditorPos) => {
  data.filterTeam = data.team.filter((member) => !member.other && member.cargo !== "Revisor Fiscal");
  return ({
    id: data.info.id,
    whatDoYourCompanyDo: data.info.what_do || "",
    whatAreYourBusinessLines: data.info.business_lines || "",
    whoAreYourMainClients: data.info.main_clients || "",
    whoAreYourCompetition: data.info.competition || "",
    whoAreYourMainSuppliers: data.info.main_suppliers || "",
    whatIsTheAddValueFromYourCompany: data.info.added_value || "",
    tellUsHowYouFinanceYourCompany: data.info.finnancing_form_today || "",
    tellUsYourFuturePlan: data.info.plan || "",
    partners: data.associates.map((associate) => ({
      id: associate.id,
      name: `${associate.name} ${associate.second_name ||
        ""} ${associate.last_name || ""} ${associate.second_last_name || ""}`,
      rol: "partner",
      personType: associate.person_type || "",
      document: associate.document_number || "",
      partnerProfession: associate.professional_profile || "",
      partnerCareer: associate.academic_profile || "",
      picture: associate.image_profile || "",
      tempURIPicture: associate.temp_url || ""
    })),
    ceo: {
      id: data.filterTeam[0] ? data.filterTeam[0].id : 0,
      name: data.filterTeam[0] ? data.filterTeam[0].name : "",
      document: data.filterTeam[0] ? data.filterTeam[0].document_number : "",
      rol: data.filterTeam[0] ? data.filterTeam[0].cargo : "Gerente general",
      partnerProfession: data.filterTeam[0] ? data.filterTeam[0].professional_profile : "",
      partnerCareer: data.filterTeam[0] ? data.filterTeam[0].academic_profile : "",
      picture: data.filterTeam[0] ? data.filterTeam[0].image_profile : "",
      tempURIPicture: data.filterTeam[0] ? data.filterTeam[0].temp_url : ""
    },
    commercialDirector: {
      id: data.filterTeam[1] ? data.filterTeam[1].id : 0,
      name: data.filterTeam[1] ? data.filterTeam[1].name : "",
      document: data.filterTeam[1] ? data.filterTeam[1].document_number : "",
      rol: data.filterTeam[1] ? data.filterTeam[1].cargo : "Director comercial",
      partnerProfession: data.filterTeam[1] ? data.filterTeam[1].professional_profile : "",
      partnerCareer: data.filterTeam[1] ? data.filterTeam[1].academic_profile : "",
      picture: data.filterTeam[1] ? data.filterTeam[1].image_profile : "",
      tempURIPicture: data.filterTeam[1] ? data.filterTeam[1].temp_url : ""
    },
    cfo: {
      id: data.filterTeam[2] ? data.filterTeam[2].id : 0,
      name: data.filterTeam[2] ? data.filterTeam[2].name : "",
      document: data.filterTeam[2] ? data.filterTeam[2].document_number : "",
      rol: data.filterTeam[2] ? data.filterTeam[2].cargo : "Director financiero",
      partnerProfession: data.filterTeam[2] ? data.filterTeam[2].professional_profile : "",
      partnerCareer: data.filterTeam[2] ? data.filterTeam[2].academic_profile : "",
      picture: data.filterTeam[2] ? data.filterTeam[2].image_profile : "",
      tempURIPicture: data.filterTeam[2] ? data.filterTeam[2].temp_url : ""
    },
    taxAuditor: {
      id:
        taxAuditorPos && data.team[taxAuditorPos]
          ? data.team[taxAuditorPos].id
          : 0,
      name:
        taxAuditorPos && data.team[taxAuditorPos]
          ? data.team[taxAuditorPos].name || ""
          : "",
      professional_card:
        taxAuditorPos && data.team[taxAuditorPos]
          ? data.team[taxAuditorPos].professional_card || ""
          : "",
      rol:
        taxAuditorPos && data.team[taxAuditorPos]
          ? data.team[taxAuditorPos].cargo
          : "Revisor Fiscal",
      partnerProfession:
        taxAuditorPos && data.team[taxAuditorPos]
          ? data.team[taxAuditorPos].professional_profile
          : "",
      partnerCareer:
        taxAuditorPos && data.team[taxAuditorPos]
          ? data.team[taxAuditorPos].academic_profile
          : "",
      picture:
        taxAuditorPos && data.team[taxAuditorPos]
          ? data.team[taxAuditorPos].image_profile
          : ""
    },
    others: data.team
      .filter((member) => member.other === true)
      .map((item) => ({
        id: item.id,
        name: item.name,
        document: item.document_number,
        rol: item.cargo,
        partnerProfession: item.professional_profile,
        partnerCareer: item.academic_profile,
        picture: item.image_profile,
        tempURIPicture: item.temp_url
      })),
    next: data.next || data.enrollment_state === 0
  })
};

export const infoSerializer = (info, storeInfo) => {
  const team = [];

  ["ceo", "commercialDirector", "cfo", "taxAuditor"].forEach((rol) => {
    if (!info[rol].name) {
      return;
    }
    team.push({
      id: info[rol].id || 0,
      name: info[rol].name,
      cargo: info[rol].rol,
      document_number: info[rol].document,
      professional_card: info[rol].professional_card,
      image_profile:
        info[rol].picture === "N/A"
          ? ""
          : info[rol].picture || storeInfo[rol].picture || "",
      professional_profile: info[rol].partnerProfession,
      academic_profile: info[rol].partnerCareer,
      other: 0
    });
  });

  return {
    data: {
      type: "PymeInfo",
      attributes: {
        next: info.next,
        info: {
          id: info.id || 0,
          what_do: info.whatDoYourCompanyDo,
          business_lines: info.whatAreYourBusinessLines,
          main_clients: info.whoAreYourMainClients,
          main_suppliers: info.whoAreYourMainSuppliers,
          added_value: info.whatIsTheAddValueFromYourCompany,
          competition: info.whoAreYourCompetition,
          finnancing_form_today: info.tellUsHowYouFinanceYourCompany,
          plan: info.tellUsYourFuturePlan
        },
        associates: info.partners.map((partner) => {
          const foundPartnerStore = storeInfo.partners.find(
            (partnerStore) => partnerStore.id === partner.id
          );

          return {
            id: partner.id,
            name: partner.name,
            last_name: "",
            document_number: partner.document,
            professional_card: partner.professional_card,
            image_profile:
              partner.picture === "N/A"
                ? ""
                : partner.picture || foundPartnerStore.picture || "",
            professional_profile: partner.partnerProfession,
            academic_profile: partner.partnerCareer
          };
        }),
        team: [
          ...team,
          ...info.others.map((other) => ({
            id: other.id || 0,
            name: other.name,
            cargo: other.rol,
            document_number: other.document,
            professional_card: other.professional_card,
            image_profile:
              other.picture === "N/A"
                ? ""
                : other.picture || getOtherPicture(other, storeInfo),
            professional_profile: other.partnerProfession,
            academic_profile: other.partnerCareer,
            other: 1
          }))
        ]
      }
    }
  };
};
