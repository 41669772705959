import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBrowser } from "react-device-detect";
import { Heading, Paragraph, Dots } from "@bvcco/a2censo-component-lib";
import {
  StyledContainer,
  StyledListContainer,
  StyledAllie,
  StyledImage
} from "./Styles";
import environment from "environment";

const SupportFor = () => {
  const assets = `${environment.aws.url}/`;
  const { t } = useTranslation();
  const { footer } = useSelector(({ pageData }) => pageData.home.homeData);
  const { allies } = footer;
  const alliesList = allies.filter((allie) => allie.is_support_for);
  const renderAllies = () => {
    const numberOfColumns = isBrowser ? 5 : 2;
    return alliesList.map((a, i) => {
      if (i % numberOfColumns === 0) {
        const isLast =
          alliesList.length < numberOfColumns
            ? true
            : i + numberOfColumns >= alliesList.length;
        return (
          <React.Fragment key={`container-support-${i}`}>
            <StyledListContainer>
              {alliesList.map((allie, index) => {
                if (index >= i && index < i + numberOfColumns) {
                  return (
                    <StyledAllie
                      key={`support-${index}`}
                      className={allie.name}
                    >
                      <StyledImage src={assets + allie.logo} />
                      <Paragraph color="white" align="center">
                        {allie.description}
                      </Paragraph>
                    </StyledAllie>
                  );
                }
                return "";
              })}
            </StyledListContainer>
            {!isLast && (
              <Dots
                className="dots"
                color="white"
                spacing={{
                  mobile: "10px",
                  desktop: "10px"
                }}
                size={{
                  mobile: "11%",
                  desktop: "11%"
                }}
              />
            )}
          </React.Fragment>
        );
      }
      return "";
    });
  };
  return (
    <StyledContainer>
      <Heading fontWeight="bold" color="white" align="center">
        {t("a2censo.supportFor")}
      </Heading>
      {renderAllies()}
    </StyledContainer>
  );
};

export default SupportFor;
