import styled from "styled-components";
import EquityModalDots from "assets/images/equity/modal-dots.png";
import EquityModalDotsMobile from "assets/images/equity/modal-dots.svg";
import EquityModalDotsSecondary from "assets/images/equity/modal-dots-secondary.svg";

interface Props {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  width?: number;
  height?: number;
  mobileWidth?: number;
  type?: "primary" | "secondary";
}

export const Dots = styled.div<Props>`
  position: absolute;
  top: ${({ top }) => (top === undefined ? "inherit" : `${top}px`)};
  left: ${({ left }) => (left === undefined ? "inherit" : `${left}px`)};
  right: ${({ right }) => (right === undefined ? "inherit" : `${right}px`)};
  bottom: ${({ bottom }) => (bottom === undefined ? "inherit" : `${bottom}px`)};
  background-image: ${({ type }) =>
    type === "secondary"
      ? `url(${EquityModalDotsSecondary})`
      : `url(${EquityModalDots})`};
  background-repeat: no-repeat;
  background-size: contain;
  width: ${({ width }) => (width ? `${width}px` : "90px")};
  height: ${({ height }) => (height ? `${height}px` : "90px")};
  ${({ theme }) => theme.smartphone} {
    background-image: url(${EquityModalDotsMobile});
    width: ${({ mobileWidth }) => (mobileWidth ? `${mobileWidth}px` : "55px")};
    top: 1px;
    left: 1px;
  }
`;
