import styled from "styled-components";

export const TabContainer = styled.div``;

export const ExtraElement = styled.div`
  min-width: 20px !important;

  ${({ theme }) => theme.iPhone8Plus} {
    min-width: 235px;
  }

  ${({ theme }) => theme.verySmallDevices} {
    min-width: 175px;
  }
`;
