import styled from "styled-components";
import posed from "react-pose";

const getTimeLineStatusColor = (state, theme) => {
  switch (state) {
    case 2:
      return theme.timeLineRed;
    case 3:
      return theme.timeLineGrey;
    case 4:
      return theme.timeLinePictonBlue;
    default:
      return theme.brightTurquoise;
  }
};

const getTimeLineBackgroundColor = (state, theme, percentaje = 70) => {
  switch (state) {
    case 2:
      return `background-image: linear-gradient(to right, ${theme.timeLinePictonBlue} 10%, ${theme.brightTurquoise} ${percentaje}%, ${theme.timeLineRed} 100%)`;
    case 4:
      return `background-color: ${theme.timeLinePictonBlue}`;
    default:
      return `background-image: linear-gradient(to right, ${theme.timeLinePictonBlue} 10%, ${theme.brightTurquoise})`;
  }
};

const getTimeLineDescriptionBackgroundColor = (state, theme) => {
  switch (state) {
    case 2:
      return theme.timeLineTransparentRed;
    case 4:
      return theme.timeLineTransparentPictonBlue;
    default:
      return theme.timeLineTransparentBrightTurquoise;
  }
};

const getTimeLineDescriptionPosition = (state) => {
  switch (state) {
    case 2:
      return "-145px";
    case 3:
      return "-10px";
    default:
      return "-50px";
  }
};

const getTimeLineDescriptionColor = (state, theme) => {
  switch (state) {
    case 1:
      return theme.brightTurquoise;
    case 2:
      return theme.timeLineRed;
    default:
      return theme.timeLinePictonBlue;
  }
};

const getTimeLineDescriptionPositionMobile = (state) => {
  switch (state) {
    case 2:
      return "-85px";
    case 3:
      return "-10px";
    default:
      return "-50px";
  }
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  height: 100px;
  margin: 0 0 10px;
`;

export const ProgressBar = styled.div`
  width: 100%;
  position: relative;
`;

export const ProgressBarBackground = styled.div`
  height: 2px;
  top: 8px;
  background-color: #c7cdcd;
  z-index: 0;
  width: 100%;
  position: absolute;
`;

export const State = styled.div`
  font-family: "Nunito ExtraBold", sans-serif;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: ${({ position }) => `${position}%`};
  font-size: 13px;
  line-height: 20px;
  margin-top: -13px;
  color: ${({ state, theme }) => getTimeLineStatusColor(state, theme)};
  letter-spacing: 2.5px;
`;

export const ProgressBarFill = posed(styled.div`
  width: ${({ widthSize }) => widthSize}%;
  max-width: ${({ widthSize }) => widthSize}%;
  z-index: 3;
  margin-top: 8px;
  ${({ state, theme, percentaje }) =>
    getTimeLineBackgroundColor(state, theme, percentaje)};
  height: 2px;
  display: flex;
  justify-content: flex-end;
`)({
  from: {
    width: "0%"
  },
  to: {
    width: ({ widthSize }) => `${widthSize > 90 ? 90 : widthSize}%`,
    transition: {
      width: { ease: "easeOut", duration: 3000 }
    }
  },
  end: {
    width: "100%",
    transition: {
      width: { ease: "easeOut", duration: 1000 }
    }
  }
});

export const ContainerFill = styled.div`
  margin-top: 8px;
  z-index: 3;
`;

export const ProgressBarPointer = styled.div`
  border-radius: 50%;
  height: 15px;
  width: 15px;
  margin-top: -6px;
  background-color: white;
  border: 2px solid
    ${({ state, theme }) => getTimeLineStatusColor(state, theme)};
`;

export const ProgressBarDescription = posed(styled.div`
  height: auto;
  margin-left: ${({ position }) => getTimeLineDescriptionPosition(position)};
  width: 180px;
  margin-top: 5px;
  background-color: ${({ state, theme }) =>
    getTimeLineDescriptionBackgroundColor(state, theme)};
  border-radius: 50px;
  font-size: 13px;
  text-align: center;
  line-height: 15px;
  padding: 13px;
  font-weight: bold;
  color: ${({ state, theme }) => getTimeLineDescriptionColor(state, theme)};
  letter-spacing: 1px;
  font-family: "Nunito", sans-serif;

  ${(props) => props.theme.tablet} {
    font-size: 10px;
    line-height: 10px;
    width: 120px;
    padding: 12px;
    margin-left: ${({ position }) =>
      getTimeLineDescriptionPositionMobile(position)};
  }
`)({
  from: {
    opacity: "0"
  },
  visible: {
    opacity: "1",
    transition: {
      width: { ease: "linear", duration: 300 }
    }
  }
});

export const Day = styled.span`
  margin-left: -13px;
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;

  ${(props) => props.theme.tablet} {
    font-size: 11px;
    line-height: 14px;
  }
`;

export const Line = styled.span`
  margin-top: -6px;
  margin-left: 2px;
`;
