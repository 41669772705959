import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setRequestStatus: ["status"]
});

export const LoginTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  status: 1
};

const setRequestStatus = (state, { status }) => ({ ...state, status });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_REQUEST_STATUS]: setRequestStatus
});
