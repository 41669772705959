import { catchError, flatMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import { Observable } from "rxjs-compat";
import { v4 as uuidv4 } from "uuid";
import App from "../../lib/app";
import LoginActions from "../reducers/login.reducer";
import { startBulkUpload } from "./campaign-document-managment";
import CodebtorActions, { CodebtorTypes } from "../reducers/codebtor.reducer";

export const addNewCodebtor = () => ({
  type: "ADD_NEW_EXTERNAL_CODEBTOR"
});

export const fetchCreateCodebtor = (action$, $state) =>
  action$.pipe(
    ofType(CodebtorTypes.CREATE_CODEBTOR),
    flatMap((action) => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          },
          campaignDocumentManagment: { requirementDocumentsCodebtor }
        }
      } = $state;

      const associateId =
        action.payload && action.payload.associateID
          ? action.payload.associateID
          : null;

      const payload = {
        request_campaign_id: requestCampaignId
      };

      if (associateId) {
        payload.associate_id = associateId;
      }

      return Observable.from(
        App.api.a2censo.createCodebtor({
          body: payload
        })
      ).pipe(
        flatMap((response) => {
          if (action.payload && action.payload.associateID) {
            return Observable.concat(
              Observable.of(
                CodebtorActions.setCheckAssociate(action.payload.associateID)
              )
            );
          }

          return Observable.concat(
            Observable.of(
              CodebtorActions.setAddCodebtor(
                response,
                requirementDocumentsCodebtor
              )
            )
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops", e)));
        })
      );
    })
  );

export const addNewExternalCodebtor = (action$, $state) =>
  action$.pipe(
    ofType("ADD_NEW_EXTERNAL_CODEBTOR"),
    flatMap(() => {
      const {
        value: {
          campaignDocumentManagment: { requirementDocumentsCodebtor }
        }
      } = $state;

      return Observable.of(
        CodebtorActions.setAddCodebtor(
          {
            codebtor: {
              codebtor_id: uuidv4()
            }
          },
          requirementDocumentsCodebtor
        )
      );
    })
  );

export const fetchEditCodebtor = (action$, $state) =>
  action$.pipe(
    ofType(CodebtorTypes.EDIT_CODEBTOR),
    flatMap((action) => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          },
          codebtor: { codebtorList }
        }
      } = $state;

      const payload = {
        name: action.payload.name,
        last_name: action.payload.lastname,
        document_type: action.payload.documentType.toString(),
        dv: action.payload.dv,
        document_number: action.payload.documentNumber,
        second_last_name: action.payload.secondLastname,
        codebtor_id: action.payload.id.toString(),
        is_new: action.payload.isNew || false,
        second_name: action.payload.secondName,
        expedition_date: action.payload.expeditionDate,
        expedition_country: action.payload.expeditionCountry,
        expedition_place: action.payload.expeditionPlace
      };

      return Observable.from(
        App.api.a2censo.editCodebtor({
          body: {
            ...payload,
            request_campaign_id: requestCampaignId
          }
        })
      ).pipe(
        flatMap(({ codebtor: { codebtor_id: codebtorId } }) => {
          const codebtor = codebtorList.find((c) => c.id === action.payload.id);
          const filesToUpload = codebtor.files.filter((file) => !!file.key);

          if (filesToUpload.length > 0) {
            return Observable.concat(
              Observable.of(
                CodebtorActions.updateIdCodebtor(action.payload.id, codebtorId)
              ),
              Observable.of(
                startBulkUpload({
                  files: filesToUpload,
                  codebtor: {
                    ...action.payload,
                    id: codebtorId
                  }
                })
              )
            );
          }

          return Observable.concat(
            Observable.of(
              CodebtorActions.updateIdCodebtor(action.payload.id, codebtorId)
            ),
            Observable.of(
              CodebtorActions.setEditCodebtorSuccess(
                filesToUpload,
                action.payload,
                []
              )
            )
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops", e)));
        })
      );
    })
  );

export const fetchDeleteCodebtor = (action$, $state) =>
  action$.pipe(
    ofType(CodebtorTypes.DELETE_CODEBTOR),
    flatMap((action) => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;

      const payload = { requestCampaignId, associateId: "", codebtorId: "" };

      if (action.payload && action.payload.associateId) {
        payload.associateId = action.payload.associateId;
      } else {
        payload.codebtorId = action.payload.id;
      }

      return Observable.from(
        App.api.a2censo.deleteCodebtor({
          url: { ...payload }
        })
      ).pipe(
        flatMap(() => {
          if (action.payload && action.payload.associateId) {
            return Observable.concat(
              Observable.of(
                CodebtorActions.setUnCheckAssociate(action.payload.associateId)
              )
            );
          }

          return Observable.concat(
            Observable.of(CodebtorActions.setDeleteCodebtor(action.payload))
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops", e)));
        })
      );
    })
  );

export const getGetListCodebtor = (action$, $state) =>
  action$.pipe(
    ofType(CodebtorTypes.GET_LIST_CODEBTOR),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          },
          campaignDocumentManagment: {
            filesUploaded,
            requirementDocumentsCodebtor
          }
        }
      } = $state;
      return Observable.from(
        App.api.a2censo.getListCodebtor({
          url: { requestCampaignId }
        })
      ).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(
              CodebtorActions.setListCodebtor(
                response,
                filesUploaded,
                requirementDocumentsCodebtor
              )
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops", e)));
        })
      );
    })
  );

export default combineEpics(
  fetchCreateCodebtor,
  fetchEditCodebtor,
  getGetListCodebtor,
  fetchDeleteCodebtor,
  addNewExternalCodebtor
);
