import { catchError, flatMap, map, mergeMap } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { forkJoin } from "rxjs";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import EnrollmentActions, {
  EnrollmentTypes
} from "../reducers/enrollment.reducer";
import ModalActions from "../reducers/modal.reducer";
import { FinancialInputSerializer } from "../../lib/serializers/financialInput.serializer";
import { ChallengeQuestionsSerializer } from "../../lib/serializers/identityValidation.serializer";
import {
  formatFormData,
  formFormatPymeData
} from "../../lib/serializers/generalInformation.serializer";
import { formatLegalRepresentativeFormData } from "../../lib/serializers/legalRepresentative.serializer";
import { UpdateEnrollmentStateSerializer } from "../../lib/serializers/updateEnrollmentState.serializer";
import User from "../../lib/models/user.model";
import UserActions from "../reducers/user.reducer";
import LoginActions from "../reducers/login.reducer";
import AnalyticsActions from "../reducers/analytics.reducer";
import AnalyticsModel from "../../lib/models/analytics.model";
import { getErrorIndex } from "../../lib/utils";
import {
  ExperianPriorityCodes,
  attemptsValidationErrors,
  ExperianPendingRiskValidation,
  ExperianDoesNotPass,
  ExperianBlockedUser,
  ExperianUnAvailabilityCodes
} from "../../lib/utils/constants.errors";
import paramNames from "../../lib/models/parameter.model";
import theme from "styles/LightTheme";

const {
  QUERIES: { citiesByCountryId },
  COUNTRY_ID: { COL },
  HASHED_CODE_PARAMS
} = paramNames;
const {
  _PARAM_001,
  _PARAM_002,
  _PARAM_003,
  _PARAM_004,
  _PARAM_005,
  _PARAM_006,
  _PARAM_007
} = HASHED_CODE_PARAMS();

const IdentityValidationErrors = [
  "EVIDENTE-05",
  "EVIDENTE-06",
  "EVIDENTE-07",
  "EVIDENTE-08",
  "EVIDENTE-09",
  "EVIDENTE-10",
  "EVIDENTE-11",
  "LISTAS_RESTRICTIVAS-301",
  "LISTAS_RESTRICTIVAS-302",
  "GENERACION_QUESTIONARIO-00",
  "GENERACION_QUESTIONARIO-02",
  "GENERACION_QUESTIONARIO-07",
  "GENERACION_QUESTIONARIO-10",
  "GENERACION_QUESTIONARIO-11",
  "GENERACION_QUESTIONARIO-12",
  "GENERACION_QUESTIONARIO-13",
  "GENERACION_QUESTIONARIO-14",
  "GENERACION_QUESTIONARIO-15",
  "GENERACION_QUESTIONARIO-17",
  "GENERACION_QUESTIONARIO-18",
  "GENERACION_QUESTIONARIO-19"
];

export const startFatcaPepForm = (formParams) => ({
  type: "START_FATCA_PEP_FORM_FLOW",
  payload: {
    route: formParams.route,
    requireUserInfo: formParams.requireUserInfo
      ? formParams.requireUserInfo
      : false
  }
});

export const getFatcaQuestions = () => ({
  type: "GET_FATCA_QUESTIONS",
  payload: {}
});

export const saveGeneralInfo = (generalInfo) => ({
  type: "SAVE_GENERAL_INFO",
  payload: generalInfo
});

export const saveGeneralInfoPyme = (generalInfo) => ({
  type: "START_SAVE_GENERAL_PYME_INFO",
  payload: generalInfo
});

export const finishSaveGeneralInfoPyme = (enrollmentState) => ({
  type: "FINISH_SAVE_GENERAL_PYME_INFO",
  payload: enrollmentState
});

export const saveLegalRepresentativeInfo = (legalRepresentative) => ({
  type: "SAVE_LEGAL_REPRESENTATIVE",
  payload: legalRepresentative
});

export const getDealSectors = () => ({
  type: "GET_DEAL_SECTORS"
});

export const validateChallengeQuestionnaireAttempts = () => ({
  type: "VALIDATE_CHALLENGE_QUESTIONNAIRE_ATTEMPTS"
});

export const challengeQuestionnaireAttempts = (action$) =>
  action$.pipe(
    ofType("VALIDATE_CHALLENGE_QUESTIONNAIRE_ATTEMPTS"),
    mergeMap(() => {
      const {
        api: {
          a2censo: { getAttemptsValidation }
        }
      } = App;
      return Observable.from(
        getAttemptsValidation({
          url: {
            type: 2
          }
        })
      ).pipe(
        mergeMap(() =>
          Observable.of(EnrollmentActions.getChallengeQuestionnaire())
        ),
        catchError((e) => {
          const attemptErrors = (e.errors || []).find((error) =>
            attemptsValidationErrors.includes(error.code)
          );

          if (attemptErrors) {
            return Observable.of(
              ModalActions.setConditionalModalState(
                true,
                "AttemptsValidation",
                {
                  clearBlur: true,
                  css: {
                    backgroundColor: theme.transparentSantas
                  }
                }
              )
            );
          }

          return Observable.of(push("/oops"));
        })
      );
    })
  );

export const organizationalStructure = (action$) =>
  action$.pipe(
    ofType(EnrollmentTypes.ORGANIZATIONAL_STRUCTURE),
    flatMap(() =>
      forkJoin([
        Observable.from(App.api.a2censo.getLegalRepresentative()),
        Observable.from(App.api.a2censo.getAssociates()),
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: { tables: `${_PARAM_004}` }
          })
        )
      ]).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(EnrollmentActions.enrollmentSuccess()),
            Observable.of(
              push("/enrollment/organizational-structure", response)
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/"
                })
              ),
              Observable.of(EnrollmentActions.enrollmentError(e))
            );
          }
          return Observable.concat(
            Observable.of(push("/oops")),
            Observable.of(EnrollmentActions.enrollmentError(e))
          );
        })
      )
    )
  );

export const saveFinancialInfo = (action$) =>
  action$.pipe(
    ofType(EnrollmentTypes.SET_FINANCIAL_INFO),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.financialInfo({
          body: FinancialInputSerializer().serialize(action.financialInfo)
        })
      ).pipe(
        flatMap(() => {
          const { user } = App.redux.store.getState();
          const personType = user.userInfo.user.person_type;
          return Observable.concat(
            Observable.of(EnrollmentActions.enrollmentSuccess()),
            Observable.of(
              UserActions.setUserState(
                User.type.natural === personType
                  ? User.state.purpose
                  : User.state.organizationalStructure
              )
            ),
            Observable.of(
              User.type.natural === personType
                ? push("/enrollment/purpose")
                : (push("/enrollment/organizational-structure"),
                  AnalyticsActions.trackEvent(
                    AnalyticsModel.saveFinancialInfoPJ
                  ))
            )
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }

          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/"
                })
              ),
              Observable.of(EnrollmentActions.enrollmentError(e))
            );
          }

          return Observable.concat(
            Observable.of(push("/oops")),
            Observable.of(EnrollmentActions.enrollmentError(e))
          );
        })
      )
    )
  );

export const fatcaPepFormFlow = (action$) =>
  action$.pipe(
    ofType("START_FATCA_PEP_FORM_FLOW"),
    flatMap((actions) =>
      forkJoin([
        Observable.from(App.api.a2censo.getUSInformationQuestions()),
        Observable.from(App.api.a2censo.getPEPInformationQuestions()),
        actions.payload.requireUserInfo
          ? Observable.from(App.api.a2censo.usersInfo())
          : Observable.fromPromise(new Promise((resolve) => resolve({}))),
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: { tables: `${_PARAM_002}&${citiesByCountryId}=${COL}` }
          })
        ),
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: {
              tables: `${_PARAM_002},${_PARAM_003},${_PARAM_001},${_PARAM_005},${_PARAM_006}`
            }
          })
        ),
        Observable.from(App.api.a2censo.politicsTermsHC())
      ]).pipe(map((response) => push(actions.payload.route, response)))
    ),
    catchError((e) => {
      if (e.statusCode === 401) {
        return Observable.of(LoginActions.loginReset());
      }

      const hasErrorIndex = getErrorIndex(e);
      if (hasErrorIndex > -1) {
        return Observable.concat(
          Observable.of(
            ModalActions.setConditionalModalState(true, "ErrorModal", {
              title: "errorModal.title",
              content: `errorCodes.${e.errors[hasErrorIndex].code}`,
              linkRedirectsTo: "/"
            })
          ),
          Observable.of(EnrollmentActions.enrollmentError(e))
        );
      }

      return Observable.concat(
        Observable.of(push("/oops")),
        Observable.of(EnrollmentActions.enrollmentError(e))
      );
    })
  );

export const fatcaQuestionsFlow = (action$) =>
  action$.pipe(
    ofType("GET_FATCA_QUESTIONS"),
    flatMap(() =>
      Observable.from(App.api.a2censo.getUSInformationQuestions()).pipe(
        flatMap((response) =>
          Observable.of(EnrollmentActions.setFatcaQuestions(response.fatca))
        ),
        catchError(() => Observable.of(push("/oops")))
      )
    )
  );

export const getChallengeQuestionnaire = (action$) =>
  action$.pipe(
    ofType(EnrollmentTypes.GET_CHALLENGE_QUESTIONNAIRE),
    flatMap(() =>
      Observable.from(App.api.a2censo.challengeQuestionnaire()).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(EnrollmentActions.setChallengeQuestionnaire(response))
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          if (e.statusCode === 500) {
            return Observable.of(
              push("/oops", {
                subtitle:
                  "enrollment.challengeQuestionnaireAnswers.oopsSubtitle",
                message: "enrollment.challengeQuestionnaireAnswers.oopsMessage",
                hideButton: true
              })
            );
          }

          let hasIdentityValidationErrors = false;
          if (Array.isArray(e.errors)) {
            e.errors.map((error) => {
              const index = IdentityValidationErrors.findIndex(
                (value) => error.code === value
              );
              if (index >= 0) {
                hasIdentityValidationErrors = true;
              }
              return error;
            });
          }
          if (hasIdentityValidationErrors) {
            if (e.errors[0].code === "EVIDENTE-06") {
              return Observable.concat(
                Observable.of(UserActions.setUserState(User.state.confirmData)),
                Observable.of(
                  ModalActions.setConditionalModalState(
                    true,
                    "IdentityValidationErrorModal",
                    {
                      errorCodes: e.errors[0].code,
                      redirectUri: "/"
                    }
                  )
                )
              );
            }
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(
                  true,
                  "IdentityValidationErrorModal",
                  {
                    errorCodes: e.errors[0].code,
                    redirectUri: "/"
                  }
                )
              )
            );
          }
          return Observable.concat(
            Observable.of(
              ModalActions.setIdentityValidationErrorModalState(true)
            ),
            Observable.of(
              EnrollmentActions.enrollmentError({ ...e, type: "questions" })
            ),
            Observable.of(push("/enrollment/identity-validation", { e }))
          );
        })
      )
    )
  );

export const sendGeneralInfo = (action$, state$) =>
  action$.pipe(
    ofType("SAVE_GENERAL_INFO"),
    flatMap((action) => {
      const {
        value: {
          user: {
            userInfo: {
              user: { id: userId }
            }
          }
        }
      } = state$;
      const formattedData = formatFormData(action.payload);
      return Observable.from(
        App.api.a2censo.saveGeneralInfo({
          body: formattedData,
          url: { userId }
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(UserActions.setUserState(User.state.financialInfo)),
            Observable.of(
              AnalyticsActions.trackEvent(AnalyticsModel.saveGralDataNatural),
              push("/enrollment/financial-info")
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }

          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/",
                  code: e.errors[hasErrorIndex].code
                })
              ),
              Observable.of(EnrollmentActions.enrollmentError(e))
            );
          }

          return Observable.concat(
            Observable.of(push("/oops")),
            Observable.of(EnrollmentActions.enrollmentError(e))
          );
        })
      );
    })
  );

export const challengeQuestionnaireAnswers = (action$) =>
  action$.pipe(
    ofType(EnrollmentTypes.CHALLENGE_QUESTIONNAIRE_ANSWERS),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.challengeQuestionnaireAnswers({
          body: ChallengeQuestionsSerializer().serialize(action.answers)
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(
              UserActions.setUserState(User.state.generalInformation)
            ),
            Observable.of(push("/enrollment/general-information"))
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(
            Observable.of(
              ModalActions.setIdentityValidationErrorModalState(true)
            ),
            Observable.of(
              EnrollmentActions.enrollmentError({ ...e, type: "answers" })
            )
          );
        })
      )
    )
  );

export const saveLegalRepresentative = (action$) =>
  action$.pipe(
    ofType("SAVE_LEGAL_REPRESENTATIVE"),
    flatMap((action) => {
      const formattedData = formatLegalRepresentativeFormData({
        ...action.payload,
        fatca_selected_option: action.payload.fatca_selected_option
          ? action.payload.fatca_selected_option.map((item) => ({
              ...item,
              answer: Number(item.answer)
            }))
          : []
      });
      return Observable.from(
        App.api.a2censo.saveLegalRepresentative({
          body: formattedData
        })
      ).pipe(
        map(() => push("/enrollment/organizational-structure")),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "legalRepresentative.legalRepresentativeErrorTitle",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/enrollment/organizational-structure"
                })
              ),
              Observable.of(EnrollmentActions.enrollmentError(e))
            );
          }
          return Observable.concat(
            Observable.of(
              ModalActions.setConditionalModalState(true, "ErrorModal", {
                title: "legalRepresentative.legalRepresentativeErrorTitle",
                content: "legalRepresentative.legalRepresentativeError",
                linkRedirectsTo: "/enrollment/organizational-structure"
              })
            ),
            Observable.of(EnrollmentActions.enrollmentError(e))
          );
        })
      );
    })
  );

export const validations = (action$, state$) =>
  action$.pipe(
    ofType(EnrollmentTypes.VALIDATIONS),
    flatMap(() =>
      Observable.from(App.api.a2censo.validateOrganizationalStructure()).pipe(
        flatMap(() => {
          const isPyme = User.getPersonType(state$.value) === User.type.pyme;

          return Observable.concat(
            isPyme
              ? Observable.concat(
                  Observable.of(
                    AnalyticsActions.trackEvent(
                      AnalyticsModel.saveLegalRepresentativeAndAssociate
                    )
                  ),
                  Observable.of(
                    ModalActions.setConditionalModalState(
                      true,
                      "VinculationPymeSuccessModal"
                    )
                  ),
                  Observable.of(UserActions.setUserState(User.state.finished))
                )
              : Observable.of(EnrollmentActions.purpose())
          );
        }),
        catchError((response) => {
          // eslint-disable-next-line no-console
          if (response.statusCode === 417) {
            const {
              associates,
              legal_representative: legalRep
            } = response.data.attributes;

            const legalRepRrhhList = !legalRep.rrhh.pass;
            const associatesAccionist = associates.find(
              (x) => !x.accionist.pass
            );
            const legalRepAcctionist = !legalRep.accionist.pass;
            const associatesRrhhList = associates.find((x) => !x.rrhh.pass);
            const experianLegalRepDetail =
              legalRep.experian.detail && legalRep.experian.code;
            const experianAssociateDetail = associates.find(
              (associate) =>
                associate.experian.detail && associate.experian.code
            );

            const listOrganizationalStructure = [...associates, legalRep];

            const thereAreBlockedCase = listOrganizationalStructure.some(
              (x) => !x.experian.pass && x.experian.code === ExperianBlockedUser
            );

            const organizationalStructureExperian = listOrganizationalStructure.find(
              (x) =>
                (!x.experian.pass && x.experian.code === ExperianBlockedUser) ||
                (!x.experian.pass &&
                  ![ExperianPendingRiskValidation].includes(x.experian.code))
            );

            const organizationalStructureUnavailabilityExperian = listOrganizationalStructure.find(
              (x) => ExperianUnAvailabilityCodes.includes(x.experian.code)
            );

            const organizationInPendingRisk = listOrganizationalStructure.some(
              (x) =>
                !x.experian.pass &&
                x.experian.code &&
                [ExperianPendingRiskValidation].includes(x.experian.code)
            );

            const notPassExperian = listOrganizationalStructure.some(
              (x) =>
                !x.experian.pass &&
                x.experian.code &&
                [ExperianDoesNotPass].includes(x.experian.code)
            );
            
            const isPendingRiskScreen =
              organizationInPendingRisk && !thereAreBlockedCase;
            const isRHHModal = associatesRrhhList || legalRepRrhhList;
            const isRepLExperianErrorModal =
              experianLegalRepDetail &&
              ExperianPriorityCodes.includes(legalRep.experian.detail);
            const isAssociateExperianErrorModal =
              experianAssociateDetail &&
              ExperianPriorityCodes.includes(
                experianAssociateDetail.experian.detail
              );
            const isAccionistErrorModal =
              associatesAccionist || legalRepAcctionist;

            if (notPassExperian) {
              return Observable.of(
                ModalActions.setConditionalModalState(
                  true,
                  "ValidationPymeModal",
                  {
                    code: organizationalStructureExperian.experian.code
                  }
                )
              );
            }

            if (organizationalStructureUnavailabilityExperian) {
              return Observable.of(
                ModalActions.setConditionalModalState(
                  true,
                  "ValidationPymeModal",
                  {
                    code: "EXPERIAN-000",
                    notRedirect: true
                  }
                )
              );
            }

            if (isAccionistErrorModal) {
              return Observable.of(
                ModalActions.setConditionalModalState(
                  true,
                  "ValidateLegalRepModal",
                  {
                    fail: 1
                  }
                )
              );
            }

            if (isRHHModal) {
              return Observable.of(
                ModalActions.setConditionalModalState(
                  true,
                  "ValidateLegalRepModal",
                  {
                    fail: 2
                  }
                )
              );
            }

            if (isPendingRiskScreen) {
              return Observable.of(
                UserActions.setUserStatus(User.state.pendingRiskValidation)
              );
            }

            if (isRepLExperianErrorModal) {
              return Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${legalRep.experian.detail}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              );
            }

            if (isAssociateExperianErrorModal) {
              return Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${experianAssociateDetail.experian.detail}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              );
            }

            if (organizationalStructureExperian) {
              return Observable.of(
                ModalActions.setConditionalModalState(
                  true,
                  "ValidationPymeModal",
                  {
                    code: organizationalStructureExperian.experian.code
                  }
                )
              );
            }
          }

          if (response.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }

          if (
            response.errors &&
            response.errors[0] &&
            response.errors[0].code
          ) {
            return Observable.of(
              ModalActions.setConditionalModalState(
                true,
                "ValidationPymeModal",
                {
                  code: response.errors[0].code
                }
              )
            );
          }

          return Observable.of(
            ModalActions.setConditionalModalState(true, "ValidationPymeModal", {
              code: "generic"
            })
          );
        })
      )
    )
  );

export const purpose = (action$) =>
  action$.pipe(
    ofType(EnrollmentTypes.PURPOSE),
    flatMap(() => {
      const formatData = UpdateEnrollmentStateSerializer().serialize({
        enrollment_state: User.state.purpose
      });
      return Observable.from(
        App.api.a2censo.updateEnrollmentState({
          body: formatData
        })
      ).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(UserActions.setUserState(User.state.purpose)),
            Observable.of(
              AnalyticsActions.trackEvent(AnalyticsModel.saveFinancialInfo),
              push("/enrollment/purpose", response)
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.of(push("/oops"));
        })
      );
    })
  );

export const fetchDealSector = (action$) =>
  action$.pipe(
    ofType("GET_DEAL_SECTORS"),
    flatMap(() =>
      Observable.from(
        App.api.a2censo.getStaticParameters({
          url: { tables: `${_PARAM_007}` }
        })
      ).pipe(
        flatMap(({ _ciiu_productive_sector: ciiuProductiveSector }) => {
          let dealSectors = {};
          ciiuProductiveSector.forEach((ciiu) => {
            dealSectors = {
              [ciiu.ciiu_id]: {
                ciuuName: ciiu.ciiu_name,
                sectorId: ciiu.sector_id,
                personType: ciiu.person_type,
                sectorName: ciiu.sector_name
              },
              ...dealSectors
            };
          });
          return Observable.of(EnrollmentActions.setDealSectors(dealSectors));
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/"
                })
              ),
              Observable.of(EnrollmentActions.enrollmentError(e))
            );
          }
          return Observable.concat(
            Observable.of(push("/oops")),
            Observable.of(EnrollmentActions.enrollmentError(e))
          );
        })
      )
    )
  );

export const saveGeneralPymeInfo = (action$, state$) =>
  action$.pipe(
    ofType("START_SAVE_GENERAL_PYME_INFO"),
    flatMap(({ payload }) => {
      const {
        value: {
          user: {
            userInfo: {
              user: { id: userId }
            }
          }
        }
      } = state$;

      return Observable.from(
        App.api.a2censo.saveGeneralInfoPyme({
          url: { userId },
          body: formFormatPymeData(payload)
        })
      ).pipe(
        flatMap((response) => {
          const { enrollment_state: enrollmentState } = response;
          return Observable.of(finishSaveGeneralInfoPyme(enrollmentState));
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(
            Observable.of(push("/oops")),
            Observable.of(EnrollmentActions.enrollmentError(e))
          );
        })
      );
    })
  );

export const finishSavePymeInfo = (action$) =>
  action$.pipe(
    ofType("FINISH_SAVE_GENERAL_PYME_INFO"),
    flatMap(({ payload }) =>
      Observable.concat(
        Observable.of(UserActions.setUserState(payload)),
        Observable.of(push("/enrollment/organizational-structure"))
      )
    )
  );

export default combineEpics(
  saveFinancialInfo,
  fatcaPepFormFlow,
  sendGeneralInfo,
  getChallengeQuestionnaire,
  challengeQuestionnaireAnswers,
  saveLegalRepresentative,
  organizationalStructure,
  purpose,
  validations,
  fetchDealSector,
  saveGeneralPymeInfo,
  finishSavePymeInfo,
  fatcaQuestionsFlow,
  challengeQuestionnaireAttempts
);
