import styled from "styled-components";
import bgDarkMobile from "../../assets/images/dark-bg-m@2x.png";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 50px 0 125px 0;
  background-image: url("/assets/images/a2censo/bg-curved@2x.png");
  background-size: cover;

  ${({ theme }) => theme.smartphone} {
    background-image: url(${bgDarkMobile});
  }
`;

export const ContentRows = styled.div`
  width: 814px;
  margin: 190px 0px 130px 0;

  ${({ theme }) => theme.smartphone} {
    margin: 150px 0 30px 0;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 77px 0;
  &.reverse {
    flex-direction: row-reverse;
  }
  ${({ theme }) => theme.smartphone} {
    flex-direction: column;
    align-items: center;
    width: inherit;
    &.reverse {
      flex-direction: column;
    }
  }
`;
export const ImageContainer = styled.div``;

export const TextContainer = styled.div`
  width: 386px;
  &.text-container {
    width: 349px;
  }
  & > p {
    line-height: 33px;
    font-size: 18px;
  }
  & > h1 {
    line-height: 40px;
    margin-bottom: 14px;
  }
  ${({ theme }) => theme.smartphone} {
    width: 321px;
    margin: 59px 0 0 0;
    &.text-container {
      width: 323px;
    }
    text-align: center;
    & > h1,
    & > p {
      text-align: center;
    }
    & > p {
      font-size: 18px;
    }
  }
`;
export const Img = styled.img`
  width: 340px;
`;
