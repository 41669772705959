import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.smartphone} {
    padding-top: 35%;
  }
`;

export const Title = styled.h1`
  text-align: center;
  margin: 2% 0;
  font-size: 36px;
  font-family: Nunito;
  font-weight: bold;
  color: ${({ theme }) => theme.tuatara};
  ${(props) => props.theme.smartphone} {
    line-height: 26px;
    font-size: 25px;
    padding: 0 20%;
    margin-top: 10%;
    margin-bottom: 4%;
  }
`;

export const Paragraph = styled.p`
  text-align: center;
  width: 50%;
  margin: 5px auto;
  color: ${({ theme }) => theme.tuatara};
  font-size: 23px;
  line-height: 30px;
  font-family: Roboto;
  ${(props) => props.theme.smartphone} {
    font-size: 15px;
    padding: 0 10%;
    line-height: 20px;
    width: 100%;
  }
`;

export const IconHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8%;
`;

export const IconHeader = styled.img`
  width: 75px;
  height: 75px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.brightTurquoiseTransparent};
  margin-top: 3%;
  position: absolute;
  ${(props) => props.theme.smartphone} {
    height: 60px;
  }
`;

export const SubmitButton = styled.button`
  width: 215px;
  height: 49px;
  background-color: ${(props) => props.theme.white};
  border-radius: 24px;
  border: none;
  color: ${(props) => props.theme.brightTurquoise};
  font-family: Nunito;
  font-size: 17px;
  font-weight: 800;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  ${(props) => props.theme.smartphone} {
    width: 170px;
    height: 40px;
    border-radius: 18px;
    font-size: 13px;
  }
`;
