import { GetTypeBlockFile } from "../models/campaign.model";

export const getFileName = (document) => {
  if (document.associate_name) {
    return `${document.name} - ${
      document.associate_name
    } ${document.associate_last_name || ""}`;
  }

  if (document.codebtor_name) {
    return `${document.name} - ${
      document.codebtor_name
    } ${document.codebtor_last_name || ""}`;
  }

  return document.name;
};

const inFileSerializer = (data) => {
  if (!data || !data.documents) {
    return [];
  }
  const documentsPyme = data.documents.pyme || data.documents.associates;

  return documentsPyme.map((item) => ({
    observation: item.observation,
    id: item.document_request_campaign_id,
    name: getFileName(item),
    stageId: item.stage_id,
    templateUrl: item.template_url,
    description: item.description,
    codebtorId: item.codebtor_id,
    associateId: item.associate_id,
    pathFile: item.path_file,
    documentStageId: item.document_stage_id,
    typeDocumentId: item.type_document_id,
    state: item.state,
    allowed_extensions: item.allowed_extensions,
    type: GetTypeBlockFile(item.is_download, item.template_url)
  }));
};

export default inFileSerializer;
