export default function getAmountAbbreviation(amount = 0) {
  const abbreviations = [
    {
      text: "MM",
      value: 1000000000
    },
    {
      text: "K",
      value: 1000
    },
    {
      text: "M",
      value: 1000000
    }
  ].sort((a, b) => {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  });

  const abbrevation = abbreviations.find((abbreviation, index) => {
    const nextAbbrevation = abbreviations[index + 1];

    if (nextAbbrevation) {
      return amount >= abbreviation.value && amount < nextAbbrevation.value;
    }

    return amount >= abbreviation.value;
  });

  if (abbrevation) {
    return `${(amount / abbrevation.value).toString()}${abbrevation.text}`;
  }

  return amount;
}
