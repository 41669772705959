import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Heading, CircleTabs } from "@bvcco/a2censo-component-lib";
import {
  StyledContainer,
  VideoContent,
  VideoIframe,
  TabsContent,
  HeadingContent
} from "./KnowUsTabsStyles.js";
import environment from "environment";

const KnowUsTabsContent = () => {
  const { t } = useTranslation();
  const { homeData } = useSelector(({ pageData }) => pageData.home);
  const timeline = homeData.a2censo_section
    ? homeData.a2censo_section.get_to_know_us[0].timeline
    : [];
  const videoID = homeData.a2censo_section
    ? homeData.a2censo_section.get_to_know_us[0].video
    : "";
  const assets = `${environment.aws.url}/`;
  return (
    <StyledContainer>
      <VideoContent>
        <VideoIframe
          title="a2censo-video"
          src={`https://www.youtube.com/embed/${videoID}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
        />
      </VideoContent>
      <HeadingContent>
        <Heading fontWeight="bold" color="white" align="center">
          {t("¿Cómo funciona a2censo?")}
        </Heading>
      </HeadingContent>
      <TabsContent>
        {timeline.length > 0 ? (
          <CircleTabs
            assets={timeline.map((item) => {
              const iconUrl = assets + item.icon;
              const imageUrl = assets + item.image;
              return { ...item, logo: imageUrl, icon: iconUrl };
            })}
            imagesPanel={{
              right: "/assets/images/Graphic-Element_right.png",
              mobile: "/assets/images/Graphic-Element_Mobile.png",
              left: "/assets/images/Graphic-Element_left.png"
            }}
          />
        ) : (
          <div />
        )}
      </TabsContent>
    </StyledContainer>
  );
};
export default KnowUsTabsContent;
