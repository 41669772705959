import styled from "styled-components";

export const Container = styled.div`
  width: 700px;
  background-color: ${({ theme }) => theme.bgColors.main};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 25px 0 45px 0;
  ${(props) => props.theme.smartphone} {
    margin: 0 5%;
    padding-top: 14px;
  }
  border-radius: 10px;

  & > h1 {
    margin: 30px 0;
    color: ${({ theme }) => theme.brightTurquoise};
    ${(props) => props.theme.smartphone} {
      font-size: 26px;
      margin-top: 15px;
    }
  }

  & > p {
    font-size: 23px;
    line-height: 32px;
    margin-bottom: 40px;
    width: 60%;
    ${(props) => props.theme.smartphone} {
      font-size: 16px;
      line-height: 23px;
      width: 78%;
      margin-bottom: 40px;
    }
  }

  & button:nth-child(4) {
    width: 215px;
    height: 49px;
    font-weight: bold;
    cursor: pointer;
    ${(props) => props.theme.smartphone} {
      width: 150px;
      font-size: 12px;
      height: 35px;
    }
  }
`;

export const Icon = styled.img`
  width: 30px;
  height: 30px;
  ${(props) => props.theme.smartphone} {
    width: 25px;
    height: 25px;
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  align-self: flex-end;
  margin-right: 30px;
  width: 34px;
  ${(props) => props.theme.smartphone} {
    margin-right: 10px;
  }
`;
