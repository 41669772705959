import React from "react";
import { useTranslation } from "react-i18next";
import environment from "environment";
import ReactModal from "react-modal";
import CloseImg from "assets/icons/Close.png";
import DownloadImg from "assets/icons/Download.png";
import DownloadButtonImg from "assets/icons/Download_Btn.png";
import moment from "moment";
import { directDownloadFile } from "../../lib/utils/downloadFile";
import "./Document.scss";
import ReactTooltip from "react-tooltip";

const Document = ({
  showModal,
  setShowModal,
  documents,
  campaignId
}) => {
  const { t } = useTranslation();
  ReactModal.setAppElement(document.createElement("div"));

  return (
    <>
      {documents ? (
        <ReactModal
          isOpen={showModal}
          className="Modal_document"
          overlayClassName="Overlay_document"
        >
          <div className="title_document">
            <p>{t("floatingButtons.documents.titleModal")}</p>
            <a data-testid="showModal" onClick={() => setShowModal(false)}>
              <img src={CloseImg} alt="" />
            </a>
          </div>
          <div className="content_document">
            <div className="docs">
              {documents.map((document) => {
                const pathDocument = document.url;

                return (
                  <div key={document.content} className="doc-item">
                    <div className="frame">
                      <p className="title_item_document" data-tip={document.title}>{document.title}</p>
                      <ReactTooltip place="bottom" effect="solid" className="tooltip_documents"/>
                      <p>
                        {t("floatingButtons.documents.publicationDate")}:{" "}
                        {`${moment(document.updated_at).format("DD/MM/YYYY")}`}
                      </p>
                    </div>
                    <a
                      data-testid="directDownloadFile" 
                      onClick={() =>
                        directDownloadFile(
                          pathDocument,
                          `${document.title}-${campaignId}`
                        )
                      }
                    >
                      <img src={DownloadImg} alt="" />
                    </a>
                  </div>
                );
              })}
            </div>
            <div className="button_document">
              <button className="Filled">
                <div className="content-button">
                  <p>{t("floatingButtons.documents.downloadButton")}</p>
                  <img src={DownloadButtonImg} alt="" />
                </div>
              </button>
            </div>
          </div>
        </ReactModal>
      ) : (
        <></>
      )}
    </>
  );
};

export default Document;
