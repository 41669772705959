import styled from "styled-components";

const Anchor = styled.a`
  & > div > button {
    width: 215px;
    height: 49px;
    font-size: 17px;
    line-height: 20px;
    font-weight: 800;
  }

  ${(props) => props.theme.max767} {
    & > div > button {
      border: 1px solid ${(props) => props.theme.brightTurquoise};
      width: 160px;
      height: 38px;
      padding: 0;
      font-size: 14px;
      font-weight: 800;
    }
  }

  ${({ theme }) => theme.smartphone} {
    & > div > button {
      margin: auto;
    }
  }
`;

export default Anchor;
