import styled from "styled-components";
import ReactTooltip from "react-tooltip";

export const ReactToolTip = styled(ReactTooltip)`
  display: flex;
  flex-direction: column;
  padding: 6px;
  align-items: flex-end;

  &__text {
    &--title {
      color: ${(props) => props.theme.white} !important;
      font-family: "Roboto";
      font-size: 11px !important;
      margin: 2px !important;
      text-align: right;
    }
  }
`;

export const ToolTipImg = styled.img`
  width: 20px;
  height: 20px;

  ${({ theme }) => theme.smartphone} {
    width: 15px;
    height: 15px;
  }
  &__tooltip_logo_select {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    @media screen and (max-width: $desktop) {
      width: 15px;
      height: 15px;
    }
  }
`;

export const ToolTipImgContainer = styled.div`
  padding: 0 15px 0 10px;
  width: 20px !important;
  position: absolute;
  right: 40px;
  bottom: 11px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.smartphone} {
    width: 15px;
    height: 15px;
  }

  @media screen and (max-width: $desktop) {
    padding: 0 10px 0 10px;
  }
`;
