import styled from "styled-components";

type LabelProps = {
  disabled?: boolean;
};

export const Label = styled.label<LabelProps>`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  opacity: ${({ disabled }) => disabled && "0.5"};
  color: ${({ theme }) => theme.cornflowerBlue};
`;
