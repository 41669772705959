import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { List, Suggestion, Label } from "./Styles";

const SuggestionList = ({ items, handleClick }) => {
  const [suggestionsState, setSuggestionsState] = useState({
    items,
    handleClick
  });
  useEffect(() => {
    setSuggestionsState({ items, handleClick });
    return () => {};
  }, [items, handleClick]);
  return (
    <List>
      {suggestionsState.items.map((suggestion, index) => (
        <Suggestion
          data-testid={`suggestion-opt-${index}`}
          borders={{ bottom: index < suggestionsState.items.length - 1 }}
          onClick={() => {
            suggestionsState.handleClick(suggestion);
          }}
        >
          <Label>{`(${suggestion.id}) ${suggestion.name}`}</Label>
        </Suggestion>
      ))}
    </List>
  );
};

SuggestionList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.string])
  ).isRequired,
  handleClick: PropTypes.func.isRequired
};

export default SuggestionList;
