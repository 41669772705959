import styled from "styled-components";

export const Footer = styled.div`
  width: 27%;
  margin: 20px auto;
  ${({ theme }) => theme.bigTablet} {
    width: 35%;
  }
  ${({ theme }) => theme.desktop} {
    width: 42%;
  }
  ${({ theme }) => theme.tablet} {
    width: 75%;
  }
  ${({ theme }) => theme.smartphone} {
    width: 85%;
  }
`;
