import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import "./styles/index.scss";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { App } from "./app";
import * as serviceWorker from "./serviceWorker";
import getPackageMetaData from "./lib/getPackageMetaData";

const windowRef: any = window;
windowRef.getA2censoVersion = () => {
  const [version, lastCommit] = getPackageMetaData();
  // eslint-disable-next-line no-console
  console.info(
    `The a2censo version is: ${version} and the last commit is: ${lastCommit}`
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

ReactDOM.render(<App />, document.getElementById("root"));
