import React from "react";
import { Modal, ModalSize, ModalProps } from "../Modal";
import { YoutubeContainer, YoutubeIframe } from "./components";

export enum VideoModalType {
  Youtube = "youtube"
}

type Props = {
  url: string;
  type: VideoModalType;
};

const types = {
  [VideoModalType.Youtube]: (url: string) => (
    <YoutubeContainer data-testid="youtube-container">
      <YoutubeIframe title="a2censo-video" src={url} />
    </YoutubeContainer>
  )
};

export const VideoModal = ({
  url = "",
  type = VideoModalType.Youtube,
  modalSize = ModalSize.Medium,
  ...props
}: Props & Omit<ModalProps, 'children'>) => {
  const modal = {
    ...props,
    modalSize,
    fullContent: true,
    backgroundColor: "transparent"
  };

  return <Modal {...modal}>{types[type](url) || <></>}</Modal>;
};
