import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  max-height: 400px;
  width: 100%;
  background-color: ${({ theme }) => theme.white};
`;
