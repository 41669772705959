import { combineEpics, ofType } from "redux-observable";

import { flatMap, map, catchError } from "rxjs/operators";
import { Observable } from "rxjs-compat";
import { push } from "connected-react-router";
import App from "../../lib/app";
import SimulatorCampaignActions, {
  newsTypes
} from "../reducers/simulator-campaign.reducer";
import ModalActions from "../reducers/modal.reducer";
import theme from "../../styles/LightTheme";
import { SimulatorFileSerializer } from "../../lib/serializers/simulatorFile.serializer";
import { base64ToDownloadFile } from "../../lib/utils/downloadFile";

export const getSimulatorCampaigns = (action$) =>
  action$.pipe(
    ofType(newsTypes.CALL_GET_SIMULATOR_CAMPAIGNS),
    flatMap((action) => {
      const payload = {
        page: action.payload.page,
        limit: action.payload.limit
      };

      return Observable.from(
        App.api.a2censo.getCampaignActive({
          url: {
            page: payload.page,
            limit: payload.limit
          }
        })
      ).pipe(
        map((response) =>
          SimulatorCampaignActions.setSimulatorCampaigns(response)
        ),
        catchError(() => Observable.concat(Observable.empty()))
      );
    })
  );

export const openSimulatorCampaignsModal = (action$) =>
  action$.pipe(
    ofType(newsTypes.CALL_OPEN_SIMULATOR_CAMPAIGNS_MODAL),
    flatMap(() =>
      Observable.of(
        ModalActions.setConditionalModalState(
          true,
          "SimulatorCampaignListModal",
          {
            clearBlur: true,
            css: {
              backgroundColor: theme.transparentMartinique
            }
          }
        )
      )
    )
  );

export const getFileSimulator = (action$) =>
  action$.pipe(
    ofType(newsTypes.CALL_GET_FILE_SIMULATOR),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.getFileSimulator({
          body: SimulatorFileSerializer().serialize(action.payload)
        })
      ).pipe(
        map((response) => {
          base64ToDownloadFile(response.name, response.file);
          return SimulatorCampaignActions.callGetFileSimulatorEnd();
        }),
        catchError(() =>
          Observable.concat(
            Observable.of(SimulatorCampaignActions.callGetFileSimulatorEnd()),
            Observable.of(push("/oops"))
          )
        )
      )
    )
  );

export const getFileSimulatorPYME = (action$) =>
  action$.pipe(
    ofType(newsTypes.CALL_GET_FILE_SIMULATOR_PYME),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.getFileSimulatorPYME({
          body: SimulatorFileSerializer().serialize(action.payload)
        })
      ).pipe(
        map((response) => {
          base64ToDownloadFile(response.name, response.file);
          return SimulatorCampaignActions.callGetFileSimulatorPYMEEnd();
        }),
        catchError(() =>
          Observable.concat(
            Observable.of(
              SimulatorCampaignActions.callGetFileSimulatorPYMEEnd()
            ),
            Observable.of(push("/oops"))
          )
        )
      )
    )
  );

export default combineEpics(
  getSimulatorCampaigns,
  openSimulatorCampaignsModal,
  getFileSimulator,
  getFileSimulatorPYME
);
