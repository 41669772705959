import React, { useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "../../components/Carousel/Carousel";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ContentFooter from "../ContentFooter/ContentFooter";
import "./CampaingNewDetail.scss";
import CampaignDetailSections from "components/CampaignDetailSections/CampaignDetailSections";
import SocialMediaShareCampaign from "components/SocialMediaShareCampaign/SocialMediaShareCampaign";
import CampaignConditions from "components/CampaignConditions/CampaignConditions";
import { push } from "connected-react-router";
import DocumentImg from "assets/icons/Document.png";
import PathImg from "assets/icons/Path.png";
import SimularImg from "assets/icons/Simular.png";
import { startSimulatorModal } from "../../redux/epics/campaign";
import User from "../../lib/models/user.model";
import DocumentModal from "components/Document/Document";
import environment from "environment";
import ReactTooltip from "react-tooltip";
import { formatNumber } from "../../lib/utils/formats";
import moment from "moment";
import CampaignsDetailActions from "../../redux/reducers/campaing-detail.reducer";
import { Loader } from "@bvcco/a2censo-component-lib";
import Info from "components/Carousel/Info/Info";
import InfoBanner from "components/CampaignDetail/InfoBanner/InfoBanner";
import {
  calculateSecondsDiff,
  colombianDateToGlobalDate,
  dateToMomentDate
} from "lib/utils/date";
import { useCountDown } from "hooks";
import countdown from "../../assets/icons/countdown.svg";
import campaignModel from "../../lib/models/campaign.model";
import deepLinkWrapper from "../../lib/deepLinkWrapper";

enum ICampaingState {
  Open = 1,
  Awarded = 2,
  Deserted = 3,
  Close = 6,
  Borrador = 11
}

enum ICampaingTypes {
  Debt = 1,
  Shares = 2,
  ConvertibleNote = 3
}

export const getUriParam = (field: string) => {
  const path = window.location.href.split("/");
  const routeIndex = path.findIndex((value) => value === "campaignNew");
  if (field === "id") {
    return path[routeIndex + 1];
  }
  return "";
};

const getQueryParam = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const getHasSession = () => {
  const login = { idToken: sessionStorage.getItem("idToken") };
  const refreshToken = sessionStorage.getItem("refreshToken");
  const hasSession =
    !!(login.idToken && refreshToken) || !!getQueryParam("emailViewToken");
  return hasSession;
};

const CampaingNewDetail = ({ location }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const Data = {
    campaingDetail: {
      campaingData: location.state.response[0],
      moreDetail: location.state.response[1].result
    }
  };

  const appStore: any = useSelector((store) => store);

  const detail = Data.campaingDetail.campaingData;
  const diferentDates =
    new Date(detail.close_date).getTime() -
    new Date(moment().format("yyyy-MM-DD")).getTime();
  const daysClose =
    diferentDates / (1000 * 60 * 60 * 24) > 0
      ? diferentDates / (1000 * 60 * 60 * 24)
      : 0;
  const carousel = Data.campaingDetail.moreDetail.filter(
    (ele: any) => ele.name == "Carrusel"
  );
  let items: any = [];
  if (carousel.length > 0) {
    carousel[0].campaignSubsectionsDetails.map((subSection: any) => {
      if (subSection.campaignDetail !== undefined) {
        subSection.campaignDetail.map((detailC: any) => {
          if (
            detailC.url !== null &&
            detailC.url !== "" &&
            detailC.url !== undefined
          ) {
            items.push({
              type: "video",
              src: detailC.url
            });
          } else if (
            detailC.url_image !== null &&
            detailC.url_image !== "" &&
            detailC.url_image !== undefined
          ) {
            items.push({
              type: "image",
              src: `${detailC.url_image}`
            });
          }
        });
      }
    });
  }

  const documents = Data.campaingDetail.moreDetail.filter(
    (elemento: any) => elemento.name == "Documentos"
  );

  let itemsDocuments: any = [];
  if (documents.length > 0) {
    documents[0].campaignSubsectionsDetails.map((subSection: any) => {
      if (subSection.campaignDetail !== undefined) {
        itemsDocuments.push(subSection.campaignDetail);
      }
    });
  }
  const Slide = styled.div`
    min-width: 100%;
    overflow: hidden;
    transition: opacity 0.5s;
    /* max-height: 500px; */
    position: relative;

    img {
      width: 100%;
      vertical-align: top;
    }
  `;

  const info = {
    logo: detail.request_campaign_id
      ? detail.logo_path
      : `${environment.aws.url}/${detail.logo_path}`,
    company: detail.campaign_company_name,
    campaign: detail.name,
    items: items,
    request_campaign_id: detail.request_campaign_id,
    campaignType: 1,
    campaignId: detail.id,
    serverTime: detail.server_time,
    availableInvestmentDate: detail.available_investment_date,
    state: detail.state,
    requestedAmount: detail.requested_amount,
    investedAmount: detail.invested_amount,
    close_date: detail.close_date
  };

  const infoContidions = {
    campaign: detail.campaignConditions?.campaign,
    sharesQuantity: detail.campaignConditions?.shares_quantity,
    sharesType: detail.campaignConditions?.sharesType?.value,
    sharesForPackage: detail.campaignConditions?.shares_for_package,
    minInversionPackage: detail.campaignConditions?.min_inversion_package,
    sharesPrice: detail.campaignConditions?.shares_price,
    patrimonialPercentageEmited:
      detail.campaignConditions?.patrimonial_percentage_emited,
    companyValue: detail.campaignConditions?.company_value,
    investmentRoundType: detail.campaignConditions?.investmentRoundType?.value,
    classification: detail.risk_classification_name,
    classificationMessage: detail.risk_classification_mesage,
    sector: detail.sector_name,
    tooltipSharesType: detail.campaignConditions?.sharesType?.message,
    tooltipInvestmentRoundType:
      detail.campaignConditions?.investmentRoundType?.message,
    interesRate: Number.isInteger(detail.campaignConditions?.interest_rate)
      ? detail.campaignConditions?.interest_rate
      : detail.campaignConditions?.interest_rate?.toFixed(2),
    termInMonths: detail.campaignConditions?.term_in_months,
    interestPayment:
      detail.campaignConditions
        ?.time_term_campaign_convertible_note_conditions_interest_paymentTotime_term
        ?.name,
    capitalPayment:
      detail.campaignConditions
        ?.time_term_campaign_convertible_note_conditions_capital_paymentTotime_term
        ?.name,
    discountRate: Number.isInteger(
      detail.campaignConditions?.discount_rate_percentage
    )
      ? detail.campaignConditions?.discount_rate_percentage
      : detail.campaignConditions?.discount_rate_percentage?.toFixed(2),
    interestRate: detail.campaignConditions?.interest_rate,
    interestPaymentDebt: detail?.campaignConditions?.interest_payment,
    interestGracePeriod: detail?.campaignConditions?.interest_grace_period,
    capitalPaymentDebt: detail?.campaignConditions?.capital_payment,
    capitalGracePeriod: detail?.campaignConditions?.capital_grace_period,
    guaranteedInvestmentPercentage:
      detail?.campaignConditions?.guaranteed_investment_percentage
  };

  const campaign = {
    interestPayment: detail?.campaignConditions?.interest_payment,
    interestRate: detail?.campaignConditions?.interest_rate,
    capital_grace_period: detail?.campaignConditions?.capital_grace_period,
    termInMonths: detail?.campaignConditions?.term_in_months,
    capitalPayment: detail?.campaignConditions?.capital_payment,
    name: detail.campaign_name,
    investedAmount: detail.invested_amount,
    requestedAmount: detail.requested_amount,
    id: detail.campaign_id,
    state: detail.state,
    close_date: detail.close_date,
    interest_grace_period: detail?.campaignConditions?.interest_grace_period,
    campaign_simulator: detail.campaign_simulator
  };

  const campaignClosed =
    (detail.state >= ICampaingState.Close &&
      detail.state != ICampaingState.Borrador) ||
    detail.state == ICampaingState.Awarded;
  const hasSession = getHasSession();
  const [showModal, setShowModal] = useState(false);
  const userData = appStore.user;
  let isPyme = false;
  isPyme = userData?.userInfo?.user?.person_type === User.type.pyme;

  let secondsUntilPublish = useCountDown(
    calculateSecondsDiff(
      detail.server_time ? dateToMomentDate(detail.server_time) : new Date(),
      detail.available_investment_date
        ? detail.available_investment_date
        : new Date()
    )
  );

  const showCountDown = useMemo(
    () => secondsUntilPublish > 0 && info.state === campaignModel.state.active,
    [secondsUntilPublish, isPyme, info]
  );

  const showInvestButton = useMemo(
    () => !isPyme && campaignModel.isActive(info) && !showCountDown,
    [isPyme, info, showCountDown]
  );

  const floatingMenuRef: any = useRef(null);
  const footerRef: any = useRef(null);

  const [isChecked, setIsChecked] = useState(false);

  const getRectTop = (elemento: HTMLElement) => {
    let rect = elemento.getBoundingClientRect();
    return rect.top;
  };

  const checkOffset = () => {
    if (floatingMenuRef.current !== null && footerRef.current !== null) {
      if (
        getRectTop(floatingMenuRef.current) +
          document.body.scrollTop +
          floatingMenuRef.current.offsetHeight >=
        getRectTop(footerRef.current) + document.body.scrollTop - 400
      ) {
        floatingMenuRef.current.style.position = "absolute";
      }
      if (
        document.body.scrollTop + window.innerHeight <
        getRectTop(footerRef.current) + document.body.scrollTop
      ) {
        floatingMenuRef.current.style.position = "fixed";
      }
    }
  };

  document.addEventListener("scroll", function() {
    checkOffset();
  });

  const drawDocuments = () => (
    <div className="btn-document">
      <button
        className={`${
          detail.business_line == ICampaingTypes.Shares
            ? "action"
            : detail.business_line == ICampaingTypes.ConvertibleNote
            ? "note"
            : "debt"
        }`}
        onClick={() => setShowModal(true)}
      >
        <img src={DocumentImg} alt=""></img>
        <p>{t("floatingButtons.documents.nameFloatingButton")}</p>
      </button>
    </div>
  );

  const drawSimulate = () => (
    <div className="btn-simulate">
      <button
        className={`${
          detail.business_line == ICampaingTypes.Shares
            ? "action"
            : detail.business_line == ICampaingTypes.ConvertibleNote
            ? "note"
            : "debt"
        }`}
        onClick={() => dispatch(startSimulatorModal({ campaign }))}
      >
        <img src={SimularImg} alt=""></img>
        <p>{t("floatingButtons.simulator.nameFloatingButton")}</p>
      </button>
    </div>
  );

  const drawFloatingMenu = () => {
    const isDrawDocuments = hasSession && documents.length > 0;
    const isDrawSimulate =
      detail.business_line == ICampaingTypes.Debt
        ? hasSession
          ? userData?.userInfo?.user?.person_type !== User.type.pyme
          : true
        : false;
    if (isDrawDocuments || isDrawSimulate) {
      return (
        <>
          <div
            ref={floatingMenuRef}
            className={`container-floating-menu${
              isChecked ? (isDrawSimulate ? "__checkedII" : "__checked") : ""
            }`}
          >
            <input
              type="checkbox"
              id="ellipse"
              onChange={() => setIsChecked(!isChecked)}
            ></input>
            <div className="floating-menu">
              {isDrawSimulate && drawSimulate()}
              {isDrawDocuments && hasSession && drawDocuments()}
            </div>
            <div
              className={
                isDrawSimulate && isDrawDocuments ? "ellipseII" : "ellipse"
              }
            >
              <label
                className={`${
                  detail.business_line == ICampaingTypes.Shares
                    ? "action"
                    : detail.business_line == ICampaingTypes.ConvertibleNote
                    ? "note"
                    : "debt"
                }`}
                htmlFor="ellipse"
              >
                <div className="icon_FloatingMenu">
                  <img src={PathImg} alt=""></img>
                </div>
              </label>
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  document.addEventListener("click", (evt) => {
    let x = (evt.clientX / innerWidth) * 100 - 8;
    document.documentElement.style.setProperty(
      "--mouse-xx",
      x.toString() + "vw"
    );
  });

  const capitalizeFirstLetter = (str: any) => {
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalized;
  };

  return (
    <>
      <div className="detail">
        <Info
          info={info}
          type={detail.business_line}
          state={detail.state}
          secondsUntilPublish={secondsUntilPublish}
          showCountDown={showCountDown}
          showInvestButton={showInvestButton}
        />
        <div className="content2">
          <button
            data-testid="content2"
            onClick={() => dispatch(push(appStore?.routeHistory?.history[1]))}
            className="detail__button"
          >
            <div className="detail__button__icon">
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <p>Volver</p>
          </button>
        </div>
        <div className="carouselDetail">
          <Carousel
            info={info}
            type={detail.business_line}
            state={detail.state}
            secondsUntilPublish={secondsUntilPublish}
            showCountDown={showCountDown}
            showInvestButton={showInvestButton}
          />
        </div>
        <div className="contentDetail">
          <div className="content__center">
            <div className="content">
              {!showCountDown ? (
                <div className="half_content">
                  <div
                    className={`detail__type ${
                      detail.business_line == ICampaingTypes.Shares
                        ? "type__Shares"
                        : detail.business_line == ICampaingTypes.ConvertibleNote
                        ? ""
                        : ""
                    }`}
                  >
                    <div
                      className={`detail__type__investment ${
                        detail.business_line == ICampaingTypes.Shares
                          ? "Shares"
                          : detail.business_line ==
                            ICampaingTypes.ConvertibleNote
                          ? "ConvertibleNote"
                          : detail.business_line == ICampaingTypes.Debt
                          ? "Debt"
                          : ""
                      }`}
                      data-tip="market"
                      data-for="market"
                    >
                      {detail.business_line == ICampaingTypes.Shares
                        ? "acciones"
                        : detail.business_line == ICampaingTypes.ConvertibleNote
                        ? "nota convertible"
                        : detail.business_line == ICampaingTypes.Debt
                        ? "Deuda"
                        : ""}
                      <span
                        id="id-icon-rtt"
                        className={`detail__type__investment__icon ${
                          detail.business_line == ICampaingTypes.Shares
                            ? "Shares_backGround"
                            : detail.business_line ==
                              ICampaingTypes.ConvertibleNote
                            ? "ConvertibleNote_backGround"
                            : detail.business_line == ICampaingTypes.Debt
                            ? "Debt_backGround"
                            : ""
                        }`}
                      ></span>
                    </div>
                    <ReactTooltip
                      className={`detail__type__investment__toopTip${
                        detail.business_line == ICampaingTypes.Shares
                          ? "__Shares"
                          : detail.business_line ==
                            ICampaingTypes.ConvertibleNote
                          ? "__ConvertibleNote"
                          : detail.business_line == ICampaingTypes.Debt
                          ? "__Debt"
                          : ""
                      }`}
                      id="market"
                      type="light"
                      place="top"
                      effect="solid"
                      placement="topCenter"
                    >
                      <div className="detail__type__investment__toopTip_text">
                        {detail.business_line == ICampaingTypes.Shares
                          ? "Inviertes comprando acciones de una empresa para volverte su socio"
                          : detail.business_line ==
                            ICampaingTypes.ConvertibleNote
                          ? "Instrumento financiero que te da el derecho a recibir acciones de una empresa si se cumplen las condiciones de conversión de la nota."
                          : detail.business_line == ICampaingTypes.Debt
                          ? "Es un préstamo que le haces a la empresa y del cual recibes una rentabilidad periódica."
                          : ""}
                      </div>
                    </ReactTooltip>
                  </div>
                  <div className="detail__amount_title">Monto recaudado</div>
                  <div className="detail__amount">
                    <div
                      className={`detail__amount__value ${
                        detail.business_line == ICampaingTypes.Shares
                          ? "Shares"
                          : detail.business_line ==
                            ICampaingTypes.ConvertibleNote
                          ? "ConvertibleNote"
                          : detail.business_line == ICampaingTypes.Debt
                          ? "Debt"
                          : ""
                      }`}
                    >
                      COP $
                      {detail?.invested_amount >= 0
                        ? formatNumber(detail.invested_amount)
                        : ""}
                    </div>
                    <div
                      className={`detail__amount__state ${
                        detail.state == ICampaingState.Open
                          ? "Open"
                          : detail.state == ICampaingState.Awarded
                          ? "Awarded"
                          : detail.state == ICampaingState.Deserted
                          ? "Deserted"
                          : detail.state >= ICampaingState.Close
                          ? "Close"
                          : ""
                      }`}
                    >
                      {detail.state == ICampaingState.Open
                        ? "Abierta"
                        : detail.state == ICampaingState.Awarded
                        ? "Adjudicado"
                        : detail.state == ICampaingState.Deserted
                        ? "Desierta"
                        : detail.state >= ICampaingState.Close &&
                          detail.state != ICampaingState.Borrador
                        ? "Cerrada"
                        : detail.state >= ICampaingState.Borrador
                        ? "Borrador"
                        : ""}
                    </div>
                  </div>
                  <div className="detail__progress">
                    <div
                      className={`detail__progress__completed ${
                        detail.business_line == ICampaingTypes.Shares
                          ? "Shares"
                          : detail.business_line ==
                            ICampaingTypes.ConvertibleNote
                          ? "ConvertibleNote"
                          : detail.business_line == ICampaingTypes.Debt
                          ? "Debt"
                          : ""
                      }`}
                      style={{
                        width: `${detail.percentage_of_invested_amount}%`
                      }}
                    ></div>
                  </div>
                  <div className="detail__progress_data">
                    <div className="detail__progress_data__completed">
                      Recaudado:{" "}
                      <div className="detail__progress_data__completed__value">
                        {detail?.percentage_of_invested_amount >= 0
                          ? detail.percentage_of_invested_amount
                          : ""}
                        %
                      </div>
                    </div>
                    <div className="detail__progress_data__total">
                      Meta COP
                      <div className="detail__progress_data__total__value">
                        $
                        {detail?.requested_amount >= 0
                          ? formatNumber(detail.requested_amount)
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="detail__info">
                    <div className="detail__info__days">
                      <div className="detail__info__days__calendar"></div>
                      <div
                        className={`detail__info__days__number ${
                          detail.business_line == ICampaingTypes.Shares
                            ? "Shares"
                            : detail.business_line ==
                              ICampaingTypes.ConvertibleNote
                            ? "ConvertibleNote"
                            : detail.business_line == ICampaingTypes.Debt
                            ? "Debt"
                            : ""
                        } ${campaignClosed ? "number_closed" : ""}`}
                      >
                        {campaignClosed ? 0 : daysClose}
                      </div>
                      {!campaignClosed && (
                        <div className="detail__info__days__title">Días</div>
                      )}
                      {campaignClosed ? (
                        <div className="detail__info__days__sub_title sub_title_closed">
                          Cerrada con éxito
                        </div>
                      ) : (
                        <div className="detail__info__days__sub_title">
                          Para cerrar
                        </div>
                      )}
                    </div>
                    <div className="detail__info__line"></div>
                    <div className="detail__info__investment">
                      <div className="detail__info__investment__icon"></div>
                      <div
                        className={`detail__info__investment__number ${
                          detail.business_line == ICampaingTypes.Shares
                            ? "Shares"
                            : detail.business_line ==
                              ICampaingTypes.ConvertibleNote
                            ? "ConvertibleNote"
                            : detail.business_line == ICampaingTypes.Debt
                            ? "Debt"
                            : ""
                        }`}
                      >
                        {detail.investors_number}
                      </div>
                      <div className="detail__info__investment__sub_title">
                        {detail.business_line == ICampaingTypes.ConvertibleNote
                          ? t("shareholdersLabel.convertibleNote")
                          : detail.business_line == ICampaingTypes.Debt
                          ? t("shareholdersLabel.debt")
                          : detail.business_line == ICampaingTypes.Shares
                          ? t("shareholdersLabel.shares")
                          : "No definido"}
                      </div>
                    </div>
                  </div>
                  <div className="detail__networks">
                    <SocialMediaShareCampaign
                      type={detail.business_line}
                      id={getUriParam("id")}
                    />
                  </div>
                </div>
              ) : showCountDown ? (
                <div className="half_content">
                  <div
                    className={`detail__type ${
                      detail.business_line == ICampaingTypes.Shares
                        ? "type__Shares"
                        : detail.business_line == ICampaingTypes.ConvertibleNote
                        ? ""
                        : ""
                    }`}
                  >
                    <div
                      className={`detail__type__investment ${
                        detail.business_line == ICampaingTypes.Shares
                          ? "Shares"
                          : detail.business_line ==
                            ICampaingTypes.ConvertibleNote
                          ? "ConvertibleNote"
                          : detail.business_line == ICampaingTypes.Debt
                          ? "Debt"
                          : ""
                      }`}
                      data-tip="market"
                      data-for="market"
                    >
                      {detail.business_line == ICampaingTypes.Shares
                        ? "acciones"
                        : detail.business_line == ICampaingTypes.ConvertibleNote
                        ? "nota convertible"
                        : detail.business_line == ICampaingTypes.Debt
                        ? "Deuda"
                        : ""}
                      <span
                        className={`detail__type__investment__icon ${
                          detail.business_line == ICampaingTypes.Shares
                            ? "Shares_backGround"
                            : detail.business_line ==
                              ICampaingTypes.ConvertibleNote
                            ? "ConvertibleNote_backGround"
                            : detail.business_line == ICampaingTypes.Debt
                            ? "Debt_backGround"
                            : ""
                        }`}
                      ></span>
                    </div>
                    <ReactTooltip
                      className={`detail__type__investment__toopTip${
                        detail.business_line == ICampaingTypes.Shares
                          ? "__Shares"
                          : detail.business_line ==
                            ICampaingTypes.ConvertibleNote
                          ? "__ConvertibleNote"
                          : detail.business_line == ICampaingTypes.Debt
                          ? "__Debt"
                          : ""
                      }`}
                      id="market"
                      type="light"
                      place="top"
                      effect="solid"
                      placement="topCenter"
                    >
                      <div className="detail__type__investment__toopTip_text">
                        {detail.business_line == ICampaingTypes.Shares
                          ? "Inviertes comprando acciones de una empresa para volverte su socio"
                          : detail.business_line ==
                            ICampaingTypes.ConvertibleNote
                          ? "Instrumento financiero que te da el derecho a recibir acciones de una empresa si se cumplen las condiciones de conversión de la nota."
                          : detail.business_line == ICampaingTypes.Debt
                          ? "Es un préstamo que le haces a la empresa y del cual recibes una rentabilidad periódica."
                          : ""}
                      </div>
                    </ReactTooltip>
                  </div>
                  <div className="detail__amount">
                    <div className="detail__amount__value__pending">
                      Meta COP
                      <div className="detail__amount__value__pending__value">
                        $
                        {detail?.requested_amount >= 0
                          ? formatNumber(detail.requested_amount)
                          : ""}
                      </div>
                    </div>
                    <div className="containerState">
                      <div className="detail__amount__state__pending">
                        <p>
                          {t("investment.investmentsList.campaignStateNext")}
                        </p>
                        <img
                          className="detail__amount__state__pending__img"
                          src={countdown}
                          alt="countdown"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="detail__AmountContainerDatePending">
                    <div className="detail__AmountContainerDatePending__Invest">
                      {`${t("campaign.invest_from")}:`}
                    </div>
                    <div className="detail__AmountContainerDatePending__Text">
                      {`${moment(
                        colombianDateToGlobalDate(info.availableInvestmentDate),
                        "YYYY-MM-DD,  h:mm:ss a"
                      ).format("DD")}`}
                      {` ${t("of")} `}
                      {`${capitalizeFirstLetter(
                        moment(
                          colombianDateToGlobalDate(
                            info.availableInvestmentDate
                          ),
                          "YYYY-MM-DD,  h:mm:ss a"
                        ).format("MMMM")
                      )}, `}
                      {moment(
                        colombianDateToGlobalDate(info.availableInvestmentDate)
                      )
                        .format("hh:mm a")
                        .toUpperCase()}
                    </div>
                  </div>
                  <div className="detail__networks">
                    <SocialMediaShareCampaign
                      type={detail.business_line}
                      id={getUriParam("id")}
                    />
                  </div>
                </div>
              ) : null}
              <div className="half_content">
                <CampaignConditions
                  info={infoContidions}
                  type={detail.business_line}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="detail__data">
          {detail.sections && (
            <CampaignDetailSections
              sections={detail.sections}
              type={detail.business_line}
              campaignCompanyName={detail.campaign_company_name}
              requestCampaignId={detail.request_campaign_id}
            />
          )}
        </div>
        {drawFloatingMenu()}
        <DocumentModal
          showModal={showModal}
          setShowModal={setShowModal}
          documents={itemsDocuments[0]}
          campaignId={detail.campaign_id}
        />
      </div>
      <div className="containerFooter" ref={footerRef}>
        {!hasSession && <InfoBanner />}
        <ContentFooter
          campaingTypes={
            detail.business_line == ICampaingTypes.Debt
              ? "Debt"
              : detail.business_line == ICampaingTypes.Shares
              ? "Shares"
              : detail.business_line == ICampaingTypes.ConvertibleNote
              ? "ConvertibleNote"
              : ""
          }
        />
      </div>
    </>
  );
};

export default deepLinkWrapper({
  withData: {
    render: CampaingNewDetail
  },
  withoutData: {
    createActionUsing: () =>
      CampaignsDetailActions.getCampaingData(
        getUriParam("id"),
        getHasSession()
      ),
    withQueryParams: ["id"],
    withParamsFromUrl: [""]
  }
});
