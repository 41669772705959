import React from "react";
import "./ContactDataAccordion.scss";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import Input from "components/Input/Input";
import { valuesSchema } from "./ContactDataAccordion.schema"
import {
    HelpIcon,
    TooltipText,
    StyledTooltips
} from "./Tooltip";
import {
    Dropdown
} from "@bvcco/a2censo-component-lib";
import { AlertForm } from "components/AlertForm";
import AlertFormType from "lib/models/alertFormType";

export type IProps = {
    enabled?: boolean;
};

const ContactDataAccordion = ({
    enabled = false
}: IProps) => {

    const { t } = useTranslation();

    const [buttonName, setButtonName] = React.useState("Editar");
    const [buttonEdit, setButtonEdit] = React.useState(false);
    const [phoneNumber, setPhone] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [editPhone, setEditPhone] = React.useState(false);
    const [editEmail, setEditEmail] = React.useState(false);
    const [enableButton, setEnableButton] = React.useState(false);
    const [showContactData, setShowContactData] = React.useState(true);

    const countryCodes = [
        { value: "+57", id: "+57" },
        { value: "+51", id: "+51" },
        { value: "+999", id: "+999" }
    ];

    const handleButtonEdit = () => {
        setButtonEdit(!buttonEdit);

        if (!buttonEdit && buttonName === "Editar") {
            setButtonName("Actualizar");
            setEditPhone(true);
            setEditEmail(true);
        } else {
            setEditPhone(false);
            setEditEmail(false);
            setButtonName("Editar");
        }
    };

    const showAlert = true;

    return (
        <div className="contact-data__section">
            <div className="contact-data__accordion">
                <input className="contact-data__accordion-checkbox" type="checkbox" id="contactDataAccordion" onClick={() => setShowContactData(!showContactData)} checked={showContactData && enabled} />
                <label htmlFor="contactDataAccordion" className="contact-data__accordion-label contact-data__accordion-label-title">
                    <div className="contact-data__accordion__label__container">
                        <div>Datos de contacto</div>
                        <div className="icon">

                            <FontAwesomeIcon icon={faChevronDown} />

                        </div>
                    </div>
                </label>
                <div className="contact-data__accordion__content">
                    <Formik initialValues={{ phoneNumber: "300 123 4567", email: "test@pruebas.com", countryCode: "+57" }} onSubmit={() => { }} validationSchema={valuesSchema(t)}>
                        {({
                            initialValues,
                            values,
                            errors,
                            isValid,
                            dirty,
                            error,
                            isInitialValid,
                            handleSubmit,
                            setFieldValue,
                            handleChange,
                            handleBlur,
                            touched
                        }) => {
                            const handlePhoneInput = (e: any) => {
                                const value = e.target.value;
                                const phone = value.replace(/\D/g, "").slice(0, 10);
                                const formattedPhone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
                                setFieldValue("phoneNumber", formattedPhone);
                                if (value === initialValues.phoneNumber) {
                                    setEditEmail(true);
                                } else {
                                    setEditEmail(false);
                                }
                            };

                            const handleEmailInput = (e: any) => {
                                const value = e.target.value;
                                const email = value.slice(0, 200);
                                setFieldValue("email", email);
                                if (value === initialValues.email) {
                                    setEditPhone(true);
                                } else {
                                    setEditPhone(false);
                                }
                            };

                            const handleCountryCodeInput = (e: any) => {
                                const value = e.id;
                                setFieldValue("countryCode", value);
                            };

                            return (
                                
                                <form onSubmit={handleSubmit} className="contact-data__accordion__form">

                                    {showAlert &&
                                        <div className="contact-data__accordion__alert-container">
                                            <AlertForm 
                                                visible={showAlert}
                                                copy={`${t("myProfile.financialInformationError")}`}
                                                background={AlertFormType.SUCCESS.background}
                                            />
                                        </div>
                                    }

                                    <div className="contact-data__accordion__data-container">

                                        <div className="contact-data__accordion__row">
                                            <div className="contact-data__accordion__row__tooltip">
                                                <p>Para actualizar estos datos, ten a la mano tu celular y correo, tanto el anterior como el nuevo para verificar el cambio.</p>
                                                <HelpIcon
                                                    data-tip={true}
                                                    data-for="subtitle_invest_tooltip"
                                                    className="financial-info__tooltip_logo"
                                                />
                                                <StyledTooltips
                                                    id="subtitle_invest_tooltip"
                                                    effect="solid"
                                                    place="top"
                                                    placement="topCenter"
                                                    clickable={true}
                                                >
                                                    <TooltipText>
                                                        {t("myProfile.tooltipText")}
                                                    </TooltipText>
                                                </StyledTooltips>
                                            </div>
                                        </div>

                                        <div className="contact-data__accordion__row">
                                            <div className="contact-data__accordion__column">
                                                <label className="contact-data__accordion-label" htmlFor="phone">
                                                    Celular *
                                                </label>
                                                <div className={!(errors.phoneNumber && touched.phoneNumber) ? "contact-data__accordion__phone-container" : "contact-data__accordion__phone-container contact-data__accordion__phone-container__error"}>
                                                    <div className={"contact-data__country-code-list-container " + (!editPhone ? "disabled" : "") }>
                                                        <Dropdown
                                                            id="countryCode"
                                                            name="countryCode"
                                                            dataTestId="countryCode"
                                                            options={countryCodes}
                                                            valueSelected={values.countryCode}
                                                            className="country-code-list"
                                                            onChange={handleCountryCodeInput}
                                                        />
                                                    </div>
                                                    <div className={"contact-data__accordion-input-divider contact-data__accordion-input-divider__disabled"}></div>
                                                    <Input
                                                        className={"contact-data__accordion-input-container"}
                                                        extraClassname="contact-data__accordion-input"
                                                        type="phoneNumber"
                                                        name="phoneNumber"
                                                        id="phoneNumber"
                                                        value={`${values.phoneNumber}`}
                                                        readOnly={!editPhone}
                                                        onChange={handlePhoneInput}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>

                                                {
                                                    errors.phoneNumber && touched.phoneNumber ? (
                                                        <div className="contact-data__accordion__error">{errors.phoneNumber}</div>
                                                    ) : null
                                                }
                                            </div>
                                            <div className="contact-data__accordion__column">
                                                <label className="contact-data__accordion-label" htmlFor="email">
                                                    Correo electrónico *
                                                </label>
                                                <Input
                                                    className={"contact-data__accordion-input-container"}
                                                    extraClassname={!(errors.email && touched.email) ? "contact-data__accordion-input" : "contact-data__accordion-input contact-data__accordion-input__error"}
                                                    type="email"
                                                    id="email"
                                                    value={`${values.email}`}
                                                    readOnly={!editEmail}
                                                    onChange={handleEmailInput}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    errors.email && touched.email ? (
                                                        <div className="contact-data__accordion__error">{errors.email}</div>
                                                    ) : null
                                                }
                                            </div>
                                        </div>
                                        <div className="contact-data__accordion__button-container">
                                            <button className={(!buttonEdit || buttonEdit && editEmail && editPhone || buttonEdit && isValid) ? "contact-data__accordion__button" : "contact-data__accordion__button contact-data__accordion__button__disabled"} onClick={handleButtonEdit} disabled={!(!buttonEdit || buttonEdit && editEmail && editPhone || buttonEdit && (isValid))}>{buttonName}</button>
                                        </div>

                                    </div>
                                </form>

                            )
                        }}
                    </Formik>
                </div>
            </div>
        </div >
    );
};

export default ContactDataAccordion;