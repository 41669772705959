import React from "react";
import {
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton
} from "react-share";
import "./SocialMediaShareCampaign.scss";
import { useTranslation } from "react-i18next";

export type IProps = {
  type: any;
  id: any;
};

enum ICampaingTypes {
  Debt = 1,
  Shares = 2,
  ConvertibleNote = 3
}

const SocialMediaShareCampaign = ({ type, id }: IProps) => {
  const { t } = useTranslation();
  let shareUrl = window.location.protocol + "//" + window.location.host + "/campaignNew/" + id;

  return (
    <div className="share">
      <div className="labelMobile">
        {t("campaignDetail.shareThisCampaignMobile")}
      </div>
      <div className="label">{t("campaignDetail.shareThisCampaign")}</div>
      <div className="sm">
        <div className="sm__linkedin">
          <LinkedinShareButton
            url={shareUrl}
            title={`${
              type === ICampaingTypes.Debt
                ? t("campaignDetail.shareDebt")
                : type === ICampaingTypes.Shares
                ? t("campaignDetail.shareShares")
                : type === ICampaingTypes.ConvertibleNote
                ? t("campaignDetail.shareConvertibleNote")
                : null
            }`}
          >
            <div className="sm__linkedin__vector"></div>
          </LinkedinShareButton>
        </div>
        <div className="sm__whatsapp">
          <WhatsappShareButton
            url={shareUrl}
            title={`${
              type === ICampaingTypes.Debt
                ? t("campaignDetail.shareDebt")
                : type === ICampaingTypes.Shares
                ? t("campaignDetail.shareShares")
                : type === ICampaingTypes.ConvertibleNote
                ? t("campaignDetail.shareConvertibleNote")
                : null
            }`}
            separator=" "
          >
            <div className="sm__whatsapp__vector"></div>
          </WhatsappShareButton>
        </div>
        <div className="sm__facebook">
          <FacebookShareButton
            url={shareUrl}
            quote={`${
              type === ICampaingTypes.Debt
                ? t("campaignDetail.shareDebt")
                : type === ICampaingTypes.Shares
                ? t("campaignDetail.shareShares")
                : type === ICampaingTypes.ConvertibleNote
                ? t("campaignDetail.shareConvertibleNote")
                : null
            }`}
          >
            <div className="sm__facebook__vector"></div>
          </FacebookShareButton>
        </div>
        <div className="sm__twitter">
          <TwitterShareButton
            url={shareUrl}
            title={`${
              type === ICampaingTypes.Debt
                ? t("campaignDetail.shareDebt")
                : type === ICampaingTypes.Shares
                ? t("campaignDetail.shareShares")
                : type === ICampaingTypes.ConvertibleNote
                ? t("campaignDetail.shareConvertibleNote")
                : null
            }`}
          >
            <div className="sm__twitter__vector"></div>
          </TwitterShareButton>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaShareCampaign;
