import styled, { css } from "styled-components";
import { getBorderRadius } from "./ContentWrapper";
import { types } from "../types";
import { LogoContainer } from "./CompanyLogo";
import { ButtonsRow } from "./ButtonsRow";
import { BusinessLines } from "lib/models";

interface Props {
  type: types;
  businessLine: BusinessLines;
}

const getWidth = {
  primary: css`
    min-height: 540px;
    max-width: 500px;
    width: 100%;
  `,
  secondary: css`
    min-height: 600px;
    max-width: 420px;
    min-width: 300px;
    width: 100%;
    margin-top: 30px;
  `,
  tertiary: css`
    min-height: 500px;
    max-width: 470px;
    width: 100%;
  `
};

const getBusinessColor = (businessLine: BusinessLines) => {
  const colors: Record<BusinessLines, string> = {
    [BusinessLines.Debt]: "robinsEggBlue",
    [BusinessLines.Shares]: "cornflowerBluee",
    [BusinessLines.ConvertibleNote]: "dodgerBlue",
    [BusinessLines.Equity]: "white"
  };

  return colors[businessLine];
};

export const Container = styled.div<Props>`
  flex: 1;
  display: flex;
  ${({ type }) => getWidth[type]}
  ${({ type }) => getBorderRadius[type]}

  position: relative;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 6.5px 5px rgba(0, 0, 0, 0.06),
    0 11.3px 8.9px rgba(0, 0, 0, 0.072), 0 15.8px 15.4px rgba(0, 0, 0, 0.086),
    0 12px 10px rgba(0, 0, 0, 0.12);

  ${LogoContainer} {
    border: 2px solid
      ${({ theme, businessLine }) => theme[getBusinessColor(businessLine)]};
  }

  ${ButtonsRow} {
    button {
      border: 2px solid
        ${({ theme, businessLine }) => theme[getBusinessColor(businessLine)]};
    }

    div:last-child {
      button {
        background-color: ${({ theme, businessLine }) =>
          theme[getBusinessColor(businessLine)]};
      }
    }
  }
`;
