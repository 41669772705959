import "../../translations/i18n";
import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { ModalContainer, ModalRoute } from "react-router-modal";
import LoaderPage from "../../components/Loader/Loader";
import Loaderhands from "../../components/LoaderHands/LoaderHands";
import "react-router-modal/css/react-router-modal.css";
import CodeValidation from "../CodeValidation/CodeValidation";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import ResetPassword from "../ResetPassword/ResetPassword";
import ForgotPasswordOtp from "../ForgotPasswordOtp/ForgotPasswordOtp";
import ForgotPasswordMethod from "../ForgotPasswordMethod/ForgotPasswordMethod";
import UserNotExistsModal from "../../components/UserNotExistsModal/UserNotExistsModal";
import AccountRecovery from "../AccountRecovery/AccountRecovery";
import AccountRecoveryOTP from "../AccountRecoveryOTP/AccountRecoveryOTP";
import AccountRecoveryOTPValidation from "../AccountRecoveryOTPValidation/AccountRecoveryOTPValidation";
import AccountRecoverySuccess from "../AccountRecoverySuccess/AccountRecoverySuccess";
import ConditionalModal from "../../components/ConditionalModal/ConditionalModal";
import Alert from "../../components/Alert/Alert";
import Simulator from "../../components/Simulator/Simulator";
import UpdateInfoSuccessModal from "../../components/UpdateUserData/UpdateInfoSuccessModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import OtherErrorModal from "../../components/OtherErrorModal/OtherErrorModal";
import ErrorModalStepper from "../../components/ErrorModalStepper/ErrorModalStepper";
import ConfirmDataPymeSuccessModal from "../../components/ConfirmDataPymeSuccessModal/ConfirmDataPymeSuccessModal";
import InvestDocument from "../InvestDocument/InvestDocument";
import ProtectedRoute from "../../components/ProtectedRoute/ProtectedRoute";
import { InvestmentList } from "../InvestmentList/InvestmentList";
import CancellationModal from "../../components/CancellationModal/CancellationModal";
import RechargeModal from "../../components/RechargeModal/RechargeModal";
import MyMovements from "../MyMovements/MyMovements";
import CancellationSuccess from "../../components/CancellationSuccess/CancellationSuccess";
import RegistrationProcessModal from "../../components/RegistrationProcessModal/RegistrationProcessModal";
import MoneyWithdrawalModal from "../../components/MoneyWithdrawalModal/MoneyWithdrawalModal";
import ConfirmDataPymeModal from "../../components/ConfirmDataPymeModal/ConfirmDataPymeModal";
import ExperianErrorModal from "../../components/ExperianErrorModal/ExperianErrorModal";
import RegisterErrorModal from "../../components/RegisterErrorModal/RegisterErrorModal";
import LoginErrorModal from "../../components/LoginErrorModal/LoginErrorModal";
import ThanksPoliticsTermsHCModal from "../../components/ThanksPoliticsTermsHCModal/ThanksPoliticsTermsHCModal";
import IdentityValidationErrorModal from "../../components/IdentityValidationErrorModal/IdentityValidationErrorModal";
import RequestSendModal from "../../components/RequestSendModal/RequestSendModal";
import Video from "../../components/Video/Video";
import ContactForm from "../../components/ContactForm/ContactForm";
import ContactFormSuccess from "../../components/ContactFormSuccess/ContactFormSuccess";
import MoneyWithdrawal from "../MoneyWithdrawal/MoneyWithdrawal";
import Politics from "../Politics/Politics";
import Terms from "../Terms/Terms";
import CreditHistory from "../CreditHistory/CreditHistory";
import PrepaidDestiny from "../PrepaidDestiny/PrepaidDestiny";
import MyPayments from "../MyPayments/MyPayments";
import PaymentsHistory from "../PaymentsHistory/PaymentsHistory";
import RejectRequestRejection from "../RejectRequestRejection/RejectRequestRejection";
import UpdatePatrimonialRights from "../UpdatePatrimonialRights/UpdatePatrimonialRights";
import UpdatePatrimonialRightsSuccess from "../UpdatePatrimonialRightsSuccess/UpdatePatrimonialRightsSuccess";
import "./App.scss";
import PaymentDetail from "../PaymentDetail/PaymentDetail";
import ConfirmPayAmount from "../ConfirmPayAmount/ConfirmPayAmount";
import PrepaidDestinyModal from "../../components/PrepaidDestinyModal/PrepaidDestinyModal";
import PaymentDetailModal from "../../components/PaymentDetailModal/PaymentDetailModal";
import ValidateLegalRepModal from "../../components/ValidateLegalRepModal/ValidateLegalRepModal";
import SimulatorNotAvailableModal from "../../components/SimulatorNotAvailableModal/SimulatorNotAvailableModal";
import SuspendedAccountModal from "../../components/SuspendedAccountModal/SuspendedAccountModal";
import SimulatorCampaignListModal from "../../components/SimulatorCampaignListModal/SimulatorCampaignListModal";
import PaymentError from "../../components/PaymentError/payment-error";
import ValidationPymeModal from "../../components/ValidationPymeModal/ValidationPymeModal";
import VinculationPymeSuccessModal from "../../components/VinculationPymeSuccessModal/VinculationPymeSuccessModal";
import RequestCampaignTermsModal from "../../components/RequestCampaignTermsModal/RequestCampaignTermsModal";
import PartiallyDoesNotPassModal from "../../components/PartiallyDoesNotPassModal/PartiallyDoesNotPassModal";
import SelfAppraisalModal from "../../components/SelfAppraisalModal/SelfAppraisalModal";
import DashboardCampaigns from "../DashboardCampaigns/DashboardCampaigns";
import CampaignApproved from "../../components/CampaignApproved/CampaignApproved";
import { MyProfile } from "../MyProfile/MyProfile";
import { MyCertificates } from "../MyCertificates";
import { CertificatesContainer } from "../Certificates";
import DownloadCertificates from "../DownloadCertificates/DownloadCertificates";
import GTM from "../../components/GTM/GTM";
import { DataUpdateContainer } from "../DataUpdateCertificates";
import { HelpForm } from "../../components/HelpForm";
import {
  ModalLoadingCertificate,
  ModalPasswordCertificate
} from "../../components/Certificates";
import { Helmet } from "react-helmet";

import { AttemptsValidation } from "../../components/AttemptsValidationModal";

import Oops from "../Oops/Oops";
import { A2censoInformation } from "../A2censoInformation/A2censoInformation.js";

const Layout = lazy(() => import("../Layout/Layout"));
const Register = lazy(() => import("../Register/Register"));
const PymeRequirements = lazy(() =>
  import("../PymeRequirements/PymeRequirements")
);

const WelcomeToPYMEModal = lazy(() =>
  import("../../components/WelcomeToPYMEModal/WelcomeToPYMEModal")
);

const SignUpReason = lazy(() => import("../SignUpReason/SignUpReason"));

const CheckEmail = lazy(() => import("../CheckEmail/CheckEmail"));
const PersonType = lazy(() => import("../PersonType/PersonType"));
const Success = lazy(() => import("../Success/Success"));
const Home = lazy(() => import("../Home/Home"));

const ResetPasswordSuccess = lazy(() =>
  import("../ResetPasswordSuccess/ResetPasswordSuccess")
);
const Enrollment = lazy(() => import("../Enrollment/Enrollment"));
const ConfirmDataForm = lazy(() => import("../ConfirmData/ConfirmDataForm"));
const Login = lazy(() => import("../Login/Login"));
const VinculationExpired = lazy(() =>
  import("../VinculationExpired/VinculationExpired")
);
const InvestmentSummary = lazy(() =>
  import("../InvestmentSummary/InvestmentSummary")
);

const InvestmentAmount = lazy(() => import("../investment/investmentAmount"));
const InvestmentAmountEquity = lazy(() =>
  import("../InvestmentAmountEquity/InvestmentAmountEquity")
);
const TransactionConfirmation = lazy(() =>
  import("../TransactionConfirmation/TransactionConfirmation")
);
const InvestmentCancellation = lazy(() =>
  import("../InvestmentCancellation/InvestmentCancellation")
);
const IdentityValidationOtp = lazy(() =>
  import("../IdentityValidationOtp/IdentityValidationOtp")
);
const QualifiedInvestor = lazy(() =>
  import("../QualifiedInvestor/QualifiedInvestor")
);
const RechargeAmount = lazy(() => import("../Recharge/RechargeAmount"));
const RechargeSummary = lazy(() =>
  import("../RechargeSummary/RechargeSummary")
);
const ThankYou = lazy(() => import("../ThankYou/ThankYou"));
const MoneyWithdrawalConfirmation = lazy(() =>
  import("../MoneyWithdrawalConfirmation/MoneyWithdrawalConfirmation")
);
const CreateCampaignStepperContainer = lazy(() =>
  import("../CreateCampaignStepperContainer/CreateCampaignStepper.container")
);
const MyCompany = lazy(() => import("../MyCompany/MyCompany"));
const Faq = lazy(() => import("../Faq/Faq"));
import { ProtectedModalRoute } from "components/ProtectedModalRoute/";
import { UserDataContainer } from "../../components/UserDataForm";
const OopsMessagePage = lazy(() =>
  import("../OopsMessagePage/OopsMessagePage")
);
import { EquityModal } from "../../components/EquityModal";

const App = () => (
  <>
    <GTM />
    <Switch>
      <Route path="/oops" component={Oops} />
      <Layout>
        <Route exact={true} path="/" component={Home} />
        <Route exact={true} path="/nosotros" component={A2censoInformation} />
        <Route
          exact={true}
          path="/vitrina-invertir-dinero-en-colombia-crowdfunding"
          component={Home}
        />
        <ProtectedRoute
          path="/campaign/:id/invest"
          component={InvestDocument}
          enrollmentRequired={true}
        />
        <ProtectedRoute
          path="/qualified-investor-validate"
          component={InvestDocument}
          enrollmentRequired={false}
        />
        <ProtectedRoute path="/my-profile" exact={true} component={MyProfile} />
        <ProtectedRoute
          path="/my-balance"
          component={InvestmentList}
          disabledToPyme={true}
        />
        <ProtectedRoute
          path="/my-certificates"
          exact={true}
          component={MyCertificates}
        />
        <ProtectedRoute
          path="/my-certificates/detail/:type"
          exact={true}
          component={CertificatesContainer}
        />
        <ProtectedRoute
          path="/my-certificates/data-update"
          exact={true}
          component={DataUpdateContainer}
        />
        <Route path="/download-certificates" component={DownloadCertificates} />

        <Route path="/register" exact={true} component={PersonType} />
        <Route path="/sign-up" exact={true} component={SignUpReason} />
        <Route
          path="/pyme-validations"
          exact={true}
          component={PymeRequirements}
        />
        <Route
          path="/forgot-password"
          exact={true}
          component={ForgotPassword}
        />
        <Route
          path="/forgot-password/otp-request/:token"
          exact={true}
          component={ForgotPasswordMethod}
        />
        <Route
          path="/forgot-password/reset"
          exact={true}
          component={ResetPassword}
        />
        <Route
          path="/forgot-password/otp-validation"
          exact={true}
          component={ForgotPasswordOtp}
        />
        <Route
          path="/forgot-password/success"
          component={ResetPasswordSuccess}
        />
        <Route path="/register/:personType" component={Register} />
        <Route
          path="/code-validation/:userId/:token"
          component={CodeValidation}
        />

        <Route path="/check-email" component={CheckEmail} />
        <Route path="/success-sign-up" component={CheckEmail} />
        <Route path="/success" component={Success} />
        <Route path="/thank-you" component={ThankYou} />
        <Route path="/enrollment" component={Enrollment} />
        <ProtectedRoute path="/confirm-data" component={ConfirmDataForm} />
        <ProtectedRoute
          path="/vinculation-expired"
          component={VinculationExpired}
        />
        <ProtectedRoute
          path="/transaction-confirmation/:transactionId"
          component={TransactionConfirmation}
        />
        <ProtectedRoute
          path="/money-withdrawal-confirmation/:transactionId"
          component={MoneyWithdrawalConfirmation}
          disabledToPyme={true}
        />
        <Route path="/qualified-investor" component={QualifiedInvestor} />
        <ProtectedRoute
          path="/investment-cancellation"
          component={InvestmentCancellation}
          enrollmentRequired={true}
          disabledToPyme={true}
        />
        <ProtectedRoute
          path="/investment-summary"
          component={InvestmentSummary}
          enrollmentRequired={true}
          disabledToPyme={true}
        />
        <ProtectedRoute
          path="/investment-amount"
          component={InvestmentAmount}
          enrollmentRequired={true}
          disabledToPyme={true}
        />
        <ProtectedRoute
          path="/investment-amount-shares"
          component={InvestmentAmountEquity}
          enrollmentRequired={true}
          disabledToPyme={true}
        />
        <ProtectedRoute
          path="/investment-amount-convertible-note"
          component={InvestmentAmountEquity}
          enrollmentRequired={true}
          disabledToPyme={true}
        />
        <ProtectedRoute
          path="/identity-validation-otp"
          component={IdentityValidationOtp}
          enrollmentRequired={true}
        />
        <ProtectedRoute
          path="/money-withdrawal"
          component={MoneyWithdrawal}
          disabledToPyme={true}
        />
        <ProtectedRoute
          path="/recharge-amount"
          component={RechargeAmount}
          enrollmentRequired={true}
          disabledToPyme={true}
        />
        <ProtectedRoute
          path="/recharge-summary"
          component={RechargeSummary}
          enrollmentRequired={true}
          disabledToPyme={true}
        />
        <ProtectedRoute
          path="/my-movements"
          component={MyMovements}
          enrollmentRequired={true}
          disabledToPyme={true}
        />
        <ProtectedRoute path="/my-payments" component={MyPayments} />
        <ProtectedRoute
          component={PaymentsHistory}
          path="/payments"
          exact={true}
        />
        <ModalRoute
          component={Login}
          path="/login"
          className="modal__container"
          backdropClassName="modal__backdrop"
        />
        <Route
          component={AccountRecovery}
          path="/account-recovery"
          exact={true}
        />
        <Route
          component={AccountRecoveryOTP}
          path="/account-recovery/otp"
          exact={true}
        />
        <Route
          component={AccountRecoveryOTPValidation}
          path="/account-recovery/otp-validation"
          exact={true}
        />
        <Route
          component={AccountRecoverySuccess}
          path="/account-recovery/success"
          exact={true}
        />
        <ProtectedRoute
          component={PrepaidDestiny}
          path="/prepaid-destiny"
          enrollmentRequired={true}
        />
        <ProtectedRoute component={PaymentDetail} path="/payment-detail" />
        <ProtectedRoute
          component={ConfirmPayAmount}
          path="/confirm-pay-amount"
          exact={true}
        />
        <Route
          path="/reject-request-rejection"
          component={RejectRequestRejection}
        />
        <ProtectedRoute
          component={UpdatePatrimonialRights}
          path="/patrimonial-rights/update"
          exact={true}
        />
        <ProtectedRoute
          component={UpdatePatrimonialRightsSuccess}
          path="/patrimonial-rights/reject-success"
          exact={true}
        />
        <ProtectedRoute
          path="/payment-error"
          component={PaymentError}
          enrollmentRequired={true}
        />
        <ProtectedRoute
          path="/dashboard-campaigns"
          component={DashboardCampaigns}
          enrollmentRequired={true}
          pymeUser={true}
        />
        <ProtectedRoute
          path="/my-company"
          component={MyCompany}
          enrollmentRequired={true}
          pymeUser={true}
        />
        <Route path="/faq" component={Faq} />
        <ProtectedRoute
          component={CreateCampaignStepperContainer}
          path="/create-campaign"
          enrollmentRequired={true}
          pymeUser={true}
        />
        <Route
          component={CampaignApproved}
          path="/campaign-approved"
          exact={true}
        />
        <ProtectedModalRoute
          component={UserDataContainer}
          path="/update-user-data"
          className="modal__container"
          backdropClassName="modal__backdrop"
        />
        <Route component={OopsMessagePage} path="/oops-message" exact={true} />
      </Layout>
    </Switch>
    <ModalContainer bodyModalOpenClassName="modal__container--open" />
    <ConditionalModal
      components={{
        Simulator,
        ErrorModal,
        OtherErrorModal,
        CancellationModal,
        MoneyWithdrawalModal,
        ConfirmDataPymeModal,
        ErrorModalStepper,
        UpdateInfoSuccessModal,
        Video,
        ContactForm,
        ContactFormSuccess,
        LoaderPage,
        Loaderhands,
        Politics,
        Terms,
        CreditHistory,
        CancellationSuccess,
        RechargeModal,
        RegistrationProcessModal,
        ExperianErrorModal,
        RegisterErrorModal,
        ConfirmDataPymeSuccessModal,
        IdentityValidationErrorModal,
        ThanksPoliticsTermsHCModal,
        LoginErrorModal,
        UserNotExistsModal,
        RequestSendModal,
        PrepaidDestinyModal,
        PaymentDetailModal,
        ValidateLegalRepModal,
        SimulatorNotAvailableModal,
        SuspendedAccountModal,
        WelcomeToPYMEModal,
        PartiallyDoesNotPassModal,
        SimulatorCampaignListModal,
        SelfAppraisalModal,
        ValidationPymeModal,
        VinculationPymeSuccessModal,
        RequestCampaignTermsModal,
        HelpForm,
        ModalLoadingCertificate,
        ModalPasswordCertificate,
        AttemptsValidation,
        EquityModal
      }}
    />
    <Alert />
  </>
);

export default App;
