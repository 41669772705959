import { createReducer, createActions } from "reduxsauce";
import { GetTypeBlockFile } from "../../lib/models/campaign.model";
import { capitalizeWord } from "../../lib/utils";
import User from "../../lib/models/user.model";

const { Types, Creators } = createActions({
  getListCodebtor: [""],
  setListCodebtor: ["list", "filesUploaded", "filesRequired"],
  createCodebtor: ["payload"],
  setAddCodebtor: ["codebtor", "files"],
  setEditOpen: ["index"],
  editCodebtor: ["payload"],
  removeDocumentCodebtor: ["documentStageId", "codebtorId"],
  addDocumentCodebtor: ["id", "file", "documentStageId", "codebtorId"],
  addDocumentCodebtorPath: ["documentStageId", "codebtorId"],
  setEditCodebtorSuccess: ["files", "codebtor", "response"],
  setEditCodebtor: ["codebtor"],
  setSelectableAssociates: ["associateList"],
  setCheckAssociate: ["keyCheckedAssociate"],
  setUnCheckAssociate: ["keyUnCheckedAssociate"],
  setDeleteCodebtor: ["codebtor"],
  deleteCodebtor: ["payload"],
  setFailNames: ["names"],
  updateIdCodebtor: ["oldId", "newId"],
  cleanState: ["payload"]
});

export const CodebtorTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isLoadingAssociateCodebtor: false,
  isFetching: false,
  names: [],
  codebtorList: [],
  codebtorAssociate: [],
  associateList: []
};

const setListCodebtor = (state, { list, filesUploaded, filesRequired }) => {
  let codebtorFilesUploaded = [];
  if (filesUploaded[0]) {
    const {
      documents: { codebtors = [] }
    } = filesUploaded[0];

    codebtorFilesUploaded = [...codebtors];
  }
  const codebtorList = list.external_codebtor.map((c) => {
    const filesCodebtor = filesRequired.map((file) => {
      const fileUploaded = codebtorFilesUploaded.find(
        (f) => f.codebtor_id === c.id && f.document_id === Number(file.id)
      );
      const fileCodebtor = { ...file, id: `codebtor_${c.id}_${file.id}` };

      fileCodebtor.type = GetTypeBlockFile(file.is_download, file.template_url);
      if (fileUploaded) {
        fileCodebtor.documentRequestCampaignId =
          fileUploaded.document_request_campaign_id;
        fileCodebtor.pathFile = fileUploaded.path_file;
        fileCodebtor.documentRequestId =
          fileUploaded.document_request_campaign_id;
      }
      return fileCodebtor;
    });

    return {
      id: c.id,
      name: c.name,
      lastname: c.last_name,
      secondLastname: c.second_last_name,
      documentType: c.document_type,
      dv: c.dv,
      documentNumber: c.document_number,
      secondName: c.second_name,
      expeditionDate: c.expedition_date,
      expeditionCountry: c.expedition_country,
      expeditionPlace: c.expedition_place,
      departmentId: c.department_id,
      files: [...filesCodebtor]
    };
  });

  return {
    ...state,
    codebtorAssociate: list.associate.map((item) => (item.id || "").toString()),
    codebtorList: [...codebtorList]
  };
};

const setFailNames = (state, { names }) => ({ ...state, names });

const setEditOff = (codebtorList) =>
  codebtorList.map((c) => {
    const codebtor = { ...c };
    codebtor.isEdit = false;
    return codebtor;
  });

const setAddCodebtor = (state, { codebtor, files }) => {
  const filesCodebtor = files.map((file) => ({
    ...file,
    id: `codebtor_${codebtor.codebtor.codebtor_id}_${file.id}`
  }));

  return {
    ...state,
    codebtorList: [
      ...setEditOff(state.codebtorList),
      {
        ...{ id: codebtor.codebtor.codebtor_id },
        isEdit: true,
        isNew: true,
        files: filesCodebtor
      }
    ]
  };
};

const setEditOpen = (state, { index }) => {
  const codebtorList = setEditOff(state.codebtorList);
  codebtorList[index] = { ...codebtorList[index], isEdit: true };

  return { ...state, codebtorList: [...codebtorList] };
};

const removeDocumentCodebtor = (state, { documentStageId, codebtorId }) => {
  const { codebtorList } = state;
  const codebtorSelected = codebtorList.find(
    (codebtor) => codebtor.id === codebtorId
  );
  const file = codebtorSelected.files.find(
    (f) => f.document_stage_id === documentStageId
  );

  if (file) {
    file.key = null;
    file.file = null;
    file.pathFile = "";
    file.documentRequestId = null;
  }

  return { ...state, codebtorList: [...codebtorList] };
};

const setDeleteCodebtor = (state, { codebtor }) => {
  const { codebtorList } = state;
  const codebtorListDeleted = codebtorList.filter(
    (item) => item.id !== codebtor.id
  );
  return { ...state, codebtorList: codebtorListDeleted };
};

const editCodebtor = (state, { payload }) => {
  const { codebtorList } = state;
  const codebtorSelected = codebtorList.find(
    (codebtor) => codebtor.id === payload.id
  );

  codebtorSelected.isFetching = true;

  return { ...state, codebtorList: [...codebtorList] };
};

const addDocumentCodebtor = (
  state,
  { id, file, documentStageId, codebtorId }
) => {
  const { codebtorList } = state;
  const codebtorSelected = codebtorList.find(
    (codebtor) => codebtor.id === codebtorId
  );

  const fileSelected = codebtorSelected.files.find(
    (f) => f.document_stage_id === documentStageId
  );

  if (fileSelected) {
    fileSelected.key = id;
    fileSelected.file = file;
    fileSelected.documentRequestId = null;
  }

  return { ...state, codebtorList: [...codebtorList] };
};

const addDocumentCodebtorPath = (state, { documentStageId, codebtorId }) => {
  const { codebtorList } = state;
  const codebtorSelected = codebtorList.find(
    (codebtor) => codebtor.id === codebtorId
  );
  const fileSelected = codebtorSelected.files.find(
    (f) => f.document_stage_id === documentStageId
  );

  if (fileSelected) {
    fileSelected.pathFile = fileSelected.name;
  }

  return { ...state, codebtorList: [...codebtorList] };
};

const setEditCodebtorSuccess = (state, { files, codebtor, response }) => {
  const { codebtorList } = state;
  const codebtorSelected = codebtorList.find(
    (c) => c.id === Number(codebtor.id)
  );

  codebtorSelected.name = codebtor.name;
  codebtorSelected.lastname = codebtor.lastname;
  codebtorSelected.secondLastname = codebtor.secondLastname;
  codebtorSelected.documentType = codebtor.documentType;
  codebtorSelected.dv = codebtor.dv;
  codebtorSelected.documentNumber = codebtor.documentNumber;
  codebtorSelected.secondName = codebtor.secondName;
  codebtorSelected.expeditionDate = codebtor.expeditionDate;
  codebtorSelected.expeditionCountry = codebtor.expeditionCountry;
  codebtorSelected.expeditionPlace = codebtor.expeditionPlace;
  codebtorSelected.departmentId = codebtor.expeditionState;
  codebtorSelected.isFetching = false;
  codebtorSelected.files.forEach((file) => {
    const fileUploaded = files.findIndex((f) => f.id === file.id);
    if (fileUploaded > -1) {
      file.pathFile = response[fileUploaded].key;
      file.documentRequestId =
        response[fileUploaded].document_request_campaign_id;
      file.key = null;
      file.file = null;
      file.documentRequestCampaignId =
        response[fileUploaded].document_request_campaign_id;
    }
  });

  return { ...state, codebtorList: [...setEditOff(codebtorList)] };
};

const setSelectableAssociates = (state, { associateList }) => {
  const getDescription = ({
    document_type: docType,
    document_number: docNumber
  }) => `${User.documentType[docType]}. ${docNumber}`;

  const getFullName = ({
    name = "",
    second_name: secondName = "",
    last_name: lastName = "",
    second_last_name: secondLastName = ""
  }) =>
    capitalizeWord(
      `${name} ${secondName || ""} ${lastName || ""} ${secondLastName || ""}`
    );

  const invalidDocumentType = [
    User.documentTypeName.NITE,
    User.documentTypeName.PA
  ];

  return {
    ...state,
    associateList: associateList
      .filter(
        (associate) => !invalidDocumentType.includes(associate.document_type)
      )
      .map((associate) => ({
        key: associate.id,
        title: getFullName(associate),
        documentType: associate.document_type,
        description: getDescription(associate),
        disabled: false
      }))
  };
};

const setCheckAssociate = (state, { keyCheckedAssociate }) => ({
  ...state,
  codebtorAssociate: [...state.codebtorAssociate, keyCheckedAssociate],
  isLoadingAssociateCodebtor: false
});

const setUnCheckAssociate = (state, { keyUnCheckedAssociate }) => ({
  ...state,
  codebtorAssociate: state.codebtorAssociate.filter(
    (associateID) => associateID !== keyUnCheckedAssociate
  ),
  isLoadingAssociateCodebtor: false
});

const createCodebtor = (state, { payload }) => {
  if (payload && payload.associateID) {
    return { ...state, isLoadingAssociateCodebtor: true };
  }
  return state;
};

const deleteCodebtor = (state, { payload }) => {
  if (payload && payload.associateId) {
    return { ...state, isLoadingAssociateCodebtor: true };
  }
  return state;
};

const updateIdCodebtor = (state, { oldId, newId }) => {
  const { codebtorList } = state;
  const newCodebtorList = codebtorList.map((item) => {
    if (item.id === oldId && item.isNew) {
      return {
        ...item,
        id: newId,
        isNew: false
      };
    }
    return item;
  });

  return {
    ...state,
    codebtorList: newCodebtorList
  };
};

const cleanState = () => ({ ...INITIAL_STATE });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_LIST_CODEBTOR]: setListCodebtor,
  [Types.SET_ADD_CODEBTOR]: setAddCodebtor,
  [Types.EDIT_CODEBTOR]: editCodebtor,
  [Types.SET_EDIT_CODEBTOR_SUCCESS]: setEditCodebtorSuccess,
  [Types.SET_EDIT_OPEN]: setEditOpen,
  [Types.REMOVE_DOCUMENT_CODEBTOR]: removeDocumentCodebtor,
  [Types.ADD_DOCUMENT_CODEBTOR]: addDocumentCodebtor,
  [Types.ADD_DOCUMENT_CODEBTOR_PATH]: addDocumentCodebtorPath,
  [Types.SET_EDIT_OPEN]: setEditOpen,
  [Types.SET_SELECTABLE_ASSOCIATES]: setSelectableAssociates,
  [Types.SET_CHECK_ASSOCIATE]: setCheckAssociate,
  [Types.SET_UN_CHECK_ASSOCIATE]: setUnCheckAssociate,
  [Types.SET_DELETE_CODEBTOR]: setDeleteCodebtor,
  [Types.CREATE_CODEBTOR]: createCodebtor,
  [Types.SET_FAIL_NAMES]: setFailNames,
  [Types.DELETE_CODEBTOR]: deleteCodebtor,
  [Types.UPDATE_ID_CODEBTOR]: updateIdCodebtor,
  [Types.CLEAN_STATE]: cleanState
});
