import styled from "styled-components";

export const ModalContainer = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  align-self: center;
`;

export default {
  ModalContainer
};
