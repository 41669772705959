const IssuerPayment = {
  state: {
    approved_by_timeout: "approved_by_timeout",
    exchange: "exchange",
    inactive: "inactive",
    issuer_approved: "issuer_approved",
    late_payment: "late_payment",
    paid: "paid",
    paid_by_collection_house: "paid_by_collection_house",
    pending_approve: "pending_approve",
    pending_new_amount_request: "pending_new_amount_request",
    pending_reject_request: "pending_reject_request",
    rejected: "rejected"
  }
};

export default IssuerPayment;
