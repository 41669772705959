import React from "react";
import InfoSvg from "assets/icons/info.svg";
import {
  Alert,
  ImgAlert,
  MessageAlert
} from "./Styles";
import AlertFormType from "lib/models/alertFormType";

export type IProps = {
  visible?: boolean;
  copy?: string;
  background?: string
};

const AlertForm = ({
  visible = false,
  copy = "",
  background = AlertFormType.WARNING.background
}: IProps) => {
  return (
    <>
      { visible &&
        <div style={{background:background, width: "100%"}}>
          <Alert>
            <ImgAlert src={InfoSvg} alt="info" />
            <MessageAlert>
                {copy}
            </MessageAlert>
          </Alert>
        </div>
      }
    </>
  );
};

export default AlertForm;
