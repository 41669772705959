const Investor = {
  qualityTax: {
    noQuality: "no_quality",
    greatContributor: "great_contributor",
    noContributor: "no_contributor",
    selfRetainer: "self_retainer",
    declarant: "declarant",
    no_declarant: "no_declarant"
  }
};

export default Investor;
