import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getHomeData: [],
  getSimulatorData: [],
  setSimulatorStatus: ["status"],
  setHomeData: ["homeData"],
  setSimulatorData: ["payload"],
  clearHomeData: []
});

export const PageDataTypes = Types;
export default Creators;

const INITIAL_STATE = {
  home: {
    homeData: {
      be_investor: {
        how_to_be_steps: [],
        how_will_it_pay_me: {
          description: ""
        }
      },
      why_choose_us: {
        investor_comments: []
      },
      footer: {
        frequest_questions: "",
        allies: []
      }
    },
    isLoading: false
  },
  simulator: {
    warranty: [],
    commissionSuccessLower3000: [],
    commissionSuccessHigher3000: [],
    gracePeriodMonthly: [],
    gracePeriodQuarterly: [],
    gracePeriodQuarterlyBiannual: [],
    gracePeriodQuarterlyAnnual: [],
    minimumSalary: [],
    contractPeriodicities: [],
    contractTime: [],
    investmentSimulator: {}
  },
  simulatorStatus: "noLoaded"
};

const getHomeData = (state) => ({
  ...state,
  home: {
    ...state.home,
    isLoading: true
  }
});

const setHomeData = (state, { homeData }) => ({
  ...state,
  home: { ...homeData, isLoading: false }
});

const setSimulatorData = (state, { payload }) => ({
  ...state,
  simulator: payload
});

const clearHomeData = () => INITIAL_STATE;

const setSimulatorStatus = (state, { status }) => ({
  ...state,
  simulatorStatus: status
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_HOME_DATA]: getHomeData,
  [Types.SET_HOME_DATA]: setHomeData,
  [Types.CLEAR_HOME_DATA]: clearHomeData,
  [Types.SET_SIMULATOR_DATA]: setSimulatorData,
  [Types.SET_SIMULATOR_STATUS]: setSimulatorStatus
});
