import styled from "styled-components";
import { ModalSize } from "../Modal";

type ContainerProps = {
  backgroundColor: string;
  boxRadius: number;
  isFullContent: boolean;
  size: ModalSize;
};

export const Container = styled.div<ContainerProps>`
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor || "white"};
  border-radius: ${({ boxRadius }) => (boxRadius ? `${boxRadius}px` : "14px")};
  padding: ${({ isFullContent }) => (isFullContent ? "0px" : "27px 77px")};
  width: ${({ size, theme }) => theme.modal.sizes.desktop[size]};

  ${({ theme }) => theme.breakpoints.mobile} {
    width: ${({ size, theme }) => theme.modal.sizes.mobile[size]};
  }
`;
