import { mergeMap, catchError } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import GetNoveltiesActions, { GetNoveltiesDataTypes } from "./reducer";
import { NoveltyConnect } from "services/novelty";

export const fetchNovelties = (action$: any) =>
  action$.pipe(
    ofType(GetNoveltiesDataTypes.START_FETCH_NOVELTIES),
    mergeMap((action: any) =>
      Observable.from(new NoveltyConnect().getAll({ ...action.payload })).pipe(
        mergeMap((response: any) =>
          Observable.concat(
            Observable.of(GetNoveltiesActions.endFetchNovelties(response))
          )
        ),
        catchError((error) =>
          Observable.of(GetNoveltiesActions.endFetchNoveltiesError(error))
        )
      )
    )
  );

export default combineEpics(fetchNovelties);
