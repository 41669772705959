import React from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { Loader } from "@bvcco/a2censo-component-lib";
import { ThemeProvider } from "styled-components";
import App from "./app";
import RequestActions from "../redux/reducers/request.reducer";
import "../pages/Loader/Loader.scss";
import { Container } from "../pages/App/Styles";
import Light from "../styles/LightTheme";

/**
 * Este wrapper permite que los componentes que envuelve puedan ser accedidos por la URL directamente.
 *
 * Contexto: Cuando se accede a una URL directamente, por ejemplo: /campaign/1. La aplicación hace render directamente
 * del Componente. Si el componenten no es el que carga la data del API (como pasa en la mayoría de los casos) se genera
 * un error. Dado que la arquitectura basada en redux define a los epics como el componente que interactúa con el API,
 * este wrapper soluciona el problema planteado, llamando a un epic que cargue la data cuando se accede a la URL directamente.
 *
 * A partir de los parámtros de la URL se debe lanzar una acción que es manejada por un epic que carga la data y redigire al componente.
 *
 * Un ejemplo de uso:
    export default deepLinkWrapper({
      withData: {
        render: Detail
      },
      withoutData: {
        createActionUsing: startCampaignDetail,
        withParamsFromUrl: ["id", "otherparam"]
        withQueryParams: ["id", "id2"]
      }
    });
 *
 * Para este ejemplo;
 * 1. Si no existe información suficiente para pintar la página, se crea un acción usando el action creator
 * startCampaignDetail.
 * Los parámetros que recibe startCampaignDetail se obtienen de la siguiente forma: para cada string dentro del arreglo se obteniene
 * el valor de la URL. Para el ejemplo, se llamaría al action creator de la siguiente forma:
 * startCampaignDetail(match.params.url, match.params.otherparam)
 * 2. Si existe información suficiente para pintar la página, se hace render del componente Detail
 * 3. Si existen queryParams debemos enviarlos similar a withParamsFromUrl con el nombre de la variable [query_param] Ejemplo: ?query_param=example
 */
export const requestStatus = {
  toSendRequest: 1,
  readyToRender: 2
};
const deepLinkWrapper = (
  {
    withData: { render },
    withoutData: { createActionUsing, withParamsFromUrl, withQueryParams }
  },
  ...restProps
) => (props) => {
  const {
    location: { state, search },
    match
  } = props;

  const component = render;
  const actionCreator = createActionUsing;
  const actionCreatorParams = withParamsFromUrl;
  const { request } = App.redux.store.getState();
  if (
    request.status === requestStatus.toSendRequest ||
    (withQueryParams &&
      withQueryParams.length > 0 &&
      (!state || Object.keys(state).length === 0))
  ) {
    const paramsValue = Object.values(queryString.parse(search));
    const dispatch = useDispatch();
    dispatch(actionCreator(...paramsValue));
    App.redux.store.dispatch(
      RequestActions.setRequestStatus(requestStatus.readyToRender)
    );
    return (
      <ThemeProvider theme={Light}>
        <Container>
          <Loader />
        </Container>
      </ThemeProvider>
    );
  }
  if (
    request.status === requestStatus.toSendRequest ||
    !state ||
    Object.keys(state).length === 0
  ) {
    const actionParams = actionCreatorParams.map((name) => match.params[name]);

    const dispatch = useDispatch();
    dispatch(actionCreator(...actionParams));
    App.redux.store.dispatch(
      RequestActions.setRequestStatus(requestStatus.readyToRender)
    );
    return (
      <ThemeProvider theme={Light}>
        <Container>
          <Loader />
        </Container>
      </ThemeProvider>
    );
  }

  return component({ ...props, ...restProps });
};

export default deepLinkWrapper;
