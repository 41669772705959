import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  Container,
  Paragraph,
  Divider,
  Button,
  CloseButton,
  Icon
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";
import PaymentsActions from "../../redux/reducers/payments.reducer";

const PaymentDetailModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { issuerPaymentId, issuerPaymentTotal } = props;

  const handleClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(PaymentsActions.setAmount(issuerPaymentTotal));
    dispatch(PaymentsActions.setShowState(false));
    dispatch(
      PaymentsActions.approvePayment({
        issuerPaymentId
      })
    );
  };

  return (
    <Container>
      <CloseButton
        id="back-button"
        onClick={() => dispatch(ModalActions.setConditionalModalState(false))}
      >
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <Paragraph>{t("paymentDetail.modal.text1")}</Paragraph>
      <Divider />
      <Button id="continue-button" onClick={() => handleClick()}>
        {t("moneyWithdrawalModal.sure")}
      </Button>
    </Container>
  );
};

PaymentDetailModal.propTypes = {
  issuerPaymentId: PropTypes.string.isRequired,
  issuerPaymentTotal: PropTypes.string.isRequired
};

export default PaymentDetailModal;
