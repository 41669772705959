import { Subject, timer } from "rxjs";
import App from "../app";

export class SessionTimeoutManager {
  sessionTimeout = new Subject();

  setTimer(timeoutValue) {
    if (timeoutValue) {
      this.sessionTimeout.next({
        value: timer(timeoutValue)
      });
      return;
    }
    const activeSession = !!(
      App.redux &&
      App.redux.store &&
      sessionStorage.getItem("idToken")
    );
    this.sessionTimeout.next({
      value:
        activeSession && App.redux.store.getState().login.sessionTimeout
          ? timer(Number(App.redux.store.getState().login.sessionTimeout))
          : null
    });
  }
}

export default async function loadSessionTimeout() {
  return Promise.resolve(new SessionTimeoutManager());
}
