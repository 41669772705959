import moment from "moment";
import _axios from "axios";
import { Deserializer } from "jsonapi-serializer";
import { tokensSerializer } from "./serializers/tokens.serializer";
import LoginActions from "../redux/reducers/login.reducer";
import ModalActions from "../redux/reducers/modal.reducer";

import App from "./app";
import conf from "../config/config";
import environment from "environment";

const HTTP_METHOD = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete"
};

export default async (_config) => {
  try {
    const FREE_TOKEN_URLS = ["/qualified-investor"];
    const idTokenExpirationTime = sessionStorage.getItem("idTokenTime");
    const refreshToken = sessionStorage.getItem("refreshToken");
    const config = { ..._config };
    if (refreshToken && moment(idTokenExpirationTime).isBefore(moment())) {
      const payload = {
        refreshToken
      };
      const login = { idToken: sessionStorage.getItem("idToken") };
      const response = await _axios.post(
        environment.apiUrl + conf.apis.a2censo.endpoints.refreshTokens.uri,
        tokensSerializer().serialize(payload),
        {
          headers: {
            token: login.idToken ? login.idToken : "",
            apiKey: environment.apiKey
          }
        }
      );

      const deserializedData = new Deserializer({
        keyForAttribute: "snake_case",
        action: {
          valueForRelationship: (relationship) => ({
            id: relationship.id
          })
        }
      }).deserialize(response.data);

      const {
        id_token: idToken,
        access_token: accessToken
      } = await deserializedData;
      let remainTime = 15;
      if (login.sessionTokenLife) {
        remainTime = ((Number(login.sessionTokenLife) / 1000 / 60) * 90) / 100;
      }
      sessionStorage.setItem("idTokenTime", moment().add(remainTime, "m"));
      App.redux.store.dispatch(
        LoginActions.refreshTokens({
          idToken,
          accessToken
        })
      );
      config.headers.token = idToken;
      FREE_TOKEN_URLS.forEach((url) => {
        if (
          _config.url.indexOf(url) >= 0 &&
          config.method === HTTP_METHOD.GET
        ) {
          delete config.headers.token;
        }
      });
      return config;
    }
    let isTokenInHeader = true;
    FREE_TOKEN_URLS.forEach((url) => {
      if (_config.url.indexOf(url) >= 0 && config.method === HTTP_METHOD.GET) {
        isTokenInHeader = false;
      }
    });
    if (sessionStorage.getItem("idToken") && isTokenInHeader) {
      config.headers.token = sessionStorage.getItem("idToken");
    }
    config.headers.apikey = environment.apiKey;
    return config;
  } catch (error) {
    App.redux.store.dispatch(
      ModalActions.setConditionalModalState(true, "ErrorModal", {
        title: "errorModal.title",
        content: "errorCodes.045",
        linkRedirectsTo: "/login",
        buttonText: "errorModal.success"
      })
    );

    await localStorage.clear();
    await sessionStorage.clear();
    return error;
  }
};
