import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setAlertState: ["status", "comment", "options", "additionalText"],
  setAlertClose: []
});

export const ServiceTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  show: false,
  type: "error",
  comment: "",
  time: 3000,
  icon: false,
  hasClose: true
};

const setAlertState = (
  state,
  {
    status = "info",
    comment = "",
    options = { time: 3000, icon: false, hasClose: true },
    additionalText
  }
) => ({
  ...state,
  show: true,
  type: status,
  comment,
  time: options.time || 3000,
  icon: options.icon,
  hasClose: options.hasClose === undefined ? true : options.hasClose,
  additionalText
});

const setAlertClose = (state) => ({
  ...state,
  show: false,
  type: "error",
  comment: "",
  time: 3000,
  icon: false,
  hasClose: true,
  additionalText: null
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ALERT_STATE]: setAlertState,
  [Types.SET_ALERT_CLOSE]: setAlertClose
});
