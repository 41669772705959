import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import pathIcon from "../../../assets/images/pathIcon.png";

import {
  PaginationContainer,
  MainContainer,
  ChangePageToRight,
  ChangePageToLeft,
  PageList,
  Label,
  Icon,
  Page
} from "./Styles";

const Direction = {
  LEFT: "left",
  RIGHT: "right"
};

const Pagination = ({
  totalItems,
  itemsPerPage,
  pageSelected,
  onClick,
  pagesPerView
}) => {
  const allPages = [...new Array(Math.ceil(totalItems / itemsPerPage))].map(
    (val, index) => index + 1
  );
  const [selected, setSelected] = useState(
    allPages.length >= pageSelected && pageSelected > 0 ? pageSelected : 1
  );
  const { t } = useTranslation();
  const startPag = pagesPerView >= selected ? 0 : selected - 1;
  const lastPag =
    pageSelected + pagesPerView <= allPages.length
      ? pageSelected + pagesPerView
      : allPages.length;
  const endPag = startPag > 0 ? lastPag : pagesPerView;
  const [pages, setPages] = useState(
    allPages.length > pagesPerView ? allPages.slice(startPag, endPag) : allPages
  );

  useEffect(() => {
    setPages(allPages.length > pagesPerView ? allPages.slice(startPag, endPag) : allPages);
    return () => {};
  }, [totalItems]);

  const getSubArray = (value, direction) => {
    if (direction === Direction.LEFT) {
      if (pages[1] > value) {
        const start = value - pagesPerView >= 0 ? value - pagesPerView + 1 : 0;
        const end = pagesPerView + start;
        const newPages = allPages.slice(start, end);
        setPages(newPages);
      }
    } else if (
      direction === Direction.RIGHT &&
      value > pages[pages.length - 2]
    ) {
      const start =
        allPages.length - pagesPerView >= 0
          ? allPages.length - pagesPerView
          : 0;
      const index = value < allPages[start] ? value - 2 : start;
      const newPages = allPages.slice(index, index + pagesPerView);
      setPages(newPages);
    }
  };
  const handleClick = (direction) => {
    if (direction === Direction.LEFT) {
      if (selected > 1) {
        const value = selected - 1;
        setSelected(value);
        getSubArray(value, Direction.LEFT);
        onClick(value);
      }
    } else if (Direction.RIGHT) {
      if (selected < allPages[allPages.length - 1]) {
        const value = selected + 1;
        setSelected(value);
        getSubArray(value, Direction.RIGHT);
        onClick(value);
      }
    }
  };
  return (
    <MainContainer>
      <PaginationContainer>
        <ChangePageToLeft>
          <Icon
            id="change-page-to-left"
            rotate={180}
            src={pathIcon}
            onClick={() => {
              handleClick(Direction.LEFT);
            }}
          />
        </ChangePageToLeft>
        <PageList>
          {pages.map((value) => (
            <Page
              id={`page-${value}`}
              isSelected={value === selected}
              onClick={() => {
                setSelected(value);
                onClick(value);
              }}
            >
              {value}
            </Page>
          ))}
        </PageList>
        <ChangePageToRight>
          <Icon
            id="change-page-to-right"
            src={pathIcon}
            onClick={() => {
              handleClick(Direction.RIGHT);
            }}
          />
        </ChangePageToRight>
      </PaginationContainer>
      <Label>
        <strong>{selected}</strong>
        &nbsp;
        {t("paginator.pageOf")}
        &nbsp;
        <strong>{allPages.length}</strong>
      </Label>
    </MainContainer>
  );
};

Pagination.propTypes = {
  totalItems: propTypes.number.isRequired,
  onClick: propTypes.func.isRequired,
  itemsPerPage: propTypes.number,
  pageSelected: propTypes.number,
  pagesPerView: propTypes.number
};

Pagination.defaultProps = {
  itemsPerPage: 10,
  pagesPerView: 5,
  pageSelected: 1
};

export default Pagination;
