const { Serializer } = require("jsonapi-serializer");

module.exports = {
  RequestCampaignBankInfoSerializer: (meta = {}) =>
    new Serializer("bank", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "bank",
        "type",
        "bank_account_number",
        "request_campaign_id",
        "city_retention",
        "city_consignment"
      ]
    })
};
