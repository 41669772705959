/* eslint-disable no-console */
import React from "react";
import "./SocialNetwork.styles.scss";
import environment from "environment";

type IProps = {
  campaignDetail: Array<{
    id: number;
    title?: string;
    urlImage?: string;
    url?: string;
  }>;
};

const SocialNetwork = ({ campaignDetail }: IProps) => (
  <div className="social-network-container">
    <div className="social-network-content-container">
      {campaignDetail.length > 0 &&
        campaignDetail.map(({ id, url, title }, i) => (
          <div key={id} className="social-network-logo-container">
            <a
              key={i}
              href={url}
              className="social-network-item"
              rel="noreferrer"
              target="_blank"
            >
              <img
                className="social-network-image"
                src={`${environment.aws.url}/icons/${title}.png`}
                alt={title}
              />
            </a>
          </div>
        ))}
    </div>
  </div>
);

export default SocialNetwork;
