import styled from "styled-components";

export const ProgressBar = styled.div`
  width: 100%;
  height: 23px;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.stormGray};
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 0 12px 12px;
`;
