import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.lightGray};
`;

export const Title = styled.div`
  display: flex;
  font-family: "Roboto Bold", sans-serif;
  font-size: 30px;
  font-weight: bold;
  ${(props) => props.theme.tablet} {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  color: ${(props) => props.theme.blueDark};
  width: 100%;
  background-color: ${(props) => props.theme.lightGray};
`;
