import moment from "moment";

export const getAmountWithDotSeparator = (amount) => {
  const amountPush = [];
  const lastTwoNumbers = Number(amount.length) - 2;

  // Get numbers from init until the last two numbers - i.e 12345 => 123
  const amountPartOne = amount.substr(0, lastTwoNumbers);

  // Get the last two numbers - i.e 12345 => 45
  const amountPartTwo = amount.substr(lastTwoNumbers);

  amountPush.push(amountPartOne, amountPartTwo);
  return amountPush.join(".");
};

export const handleAmountChange = (value, prev) => {
  let inputValue = value;
  const previousValue = prev;

  let cleanValue = inputValue.replace(/[^0-9,]/g, "");

  if (cleanValue === "") {
    return "0"
  }

  if (
    inputValue.length > previousValue.length &&
    inputValue.charAt(inputValue.length - 1) === "."
  ) {
    cleanValue = cleanValue + ",";
  }

  if ((cleanValue.match(/,/g) || []).length > 1) {
    cleanValue = cleanValue.replace(/,+$/, "");
  }

  if (!cleanValue.includes(",") && cleanValue.includes("0")) {
    cleanValue = cleanValue.replace(/^0+/, "");
    if (cleanValue === "") {
      cleanValue = "0";
    }
  }

  let formattedValue;
  if (cleanValue.includes(",")) {
    const [intPart, decPart] = cleanValue.split(",");

    let formattedInt =
      intPart === "" ? "0" : intPart.substring(0, 12);

    formattedInt = formattedInt.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    if (decPart === "") {
      formattedValue = formattedInt + ",";
    } else {
      const limitedDecPart = decPart.substring(0, 2);
      formattedValue = formattedInt + "," + limitedDecPart;
    }
  } else {
    formattedValue =
      cleanValue === "" ? "" : cleanValue.substring(0, 12);

    formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }

  return formattedValue;
};

export const numberToMoney = (
  value,
  symbol = "$",
  thousandSeparator = ".",
  decimalSeparator = ",",
  decimalSeparatorFromServer = ".",
  noSpace = false,
  defaultDecimal = false
) => {
  const hasDecimal = value && value % 1 !== 0;
  const amountAsString = `${
    value && hasDecimal ? Number.parseFloat(value).toFixed(2) : value
  }`;

  if (amountAsString.length === 0) {
    return "";
  }

  const amountSplit = amountAsString.split(decimalSeparatorFromServer);
  const amountWithoutDecimals = amountSplit[0];
  const decimals = amountSplit[1] ? amountSplit[1] : "";
  let amount = Number(amountWithoutDecimals);

  amount = `${symbol}${noSpace ? "" : " " }${
    amount.toString() === "NaN"
      ? ""
      : `${`${amount}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          `${thousandSeparator}`
        )}${decimals ? `${decimalSeparator}${decimals}` : defaultDecimal ? ",00" : ""}`
  }`;
  return amount;
};

export const moneyToNumber = (
  value,
  from,
  symbol = "$",
  thousandSeparator = ".",
  decimalSeparator = ","
) => {
  let amountAsString = `${value}`;

  if (amountAsString.length === 0) {
    return NaN;
  }

  // Remove the "$" character and all the dots
  amountAsString = amountAsString
    .replace(`${symbol}`, "")
    .split(`${thousandSeparator}`)
    .join("");

  if (from && from === "withdrawal") {
    // Remove the comma
    amountAsString = amountAsString.replace(decimalSeparator, "");
    return getAmountWithDotSeparator(amountAsString);
  }

  return Number(amountAsString.replace(decimalSeparator, "."));
};

export const numericValue = (value = "") => {
  const inputValue = `${value}`;
  return parseFloat(inputValue.replace(/\./g, "").replace(",", "."));
}

export const formateToPlusOrLessValue = (value) => {
  const formatedValue = numberToMoney(value);
  if (value >= 0) {
    return `+ ${formatedValue}`;
  }
  return `- ${formatedValue.replace("-", "")}`;
};

export const getAmount = (val) => {
  const amount = moneyToNumber(val);
  return numberToMoney(amount);
};

export const formattedValue = (val) =>
  Number(
    val
      .replace("$", "")
      .toString()
      .split(".")
      .join("")
  );

export const textCapitalize = (text = "") =>
  text ? `${text[0].toUpperCase()}${text.slice(1)}` : text;

export const numberTextWithComa = (val) => {
  const value = val.replace(/[^0-9,]/, "");
  const valueParts = value.split(",");
  const subArray = valueParts.length > 1 ? valueParts.slice(0, 2) : valueParts;
  if (subArray[1] && subArray[1].length > 1) {
    subArray[1] = subArray[1].slice(0, 2);
  }
  return subArray.join(",");
};

export const formatAmountMoney = (amount) =>
  (amount / 1000000).toFixed(2).replace(".00", "");

export const daysToClose = (close_date) => {
  const now = moment().startOf("day");
  const endDate = moment(close_date).startOf("day");
  return endDate.diff(now, "days");
};

export const truncateDecimals = (value, digits) => {
  const multiplier = Math.pow(10, digits),
    adjustedValue = value * multiplier,
    truncatedValue = Math[adjustedValue < 0 ? "ceil" : "floor"](adjustedValue);

  return truncatedValue / multiplier;
};