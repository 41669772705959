import styled from "styled-components";

export const ButtonContainer = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.smartphoneMedium} {
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
  }
`;

export const Button = styled.button`
  border: none;
  border-radius: 100px;
  width: 150px;
  height: 38px;
  padding: 8px, 24px, 8px, 24px;
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: 16px;
  font-weight: 700;
`;

export const UnderstoodButton = styled(Button)`
  background: none;
  color: ${({ theme }) => theme.white};
  border: 2px solid ${({ theme }) => theme.white};
  margin-right: 8px;
  cursor: pointer;

  ${({ theme }) => theme.smartphoneMedium} {
    margin-bottom: 10px;
    margin-right: 0px;
  }
`;

export const KnowUsButton = styled(Button)`
  color: ${({ theme }) => theme.cornflowerBlue};
  background: #FFFFFF;
  cursor: pointer;
`;
