export default {
    businessLine: {
        defaultValue: 999,
        todos: 999,
        debt: 1,
        shares: 2,
        convertibleNote: 3
    },
    investmentState: {
        defaultValue: "",
        active: "active",
        canceled: "canceled",
        exchange: "exchange"
    },
    campaignState: {
        defaultValue: "",
        active: "1",
        close: "4,5,6,7,8,9,10,12,13", 
        awarded: "2" 
    }
};