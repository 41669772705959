import React from "react";
import { Container, Image, ContainerLoader, Loader } from "./components";
import Backgroung from "assets/images/certificate-loading.png";
import { Paragraph } from "@bvcco/a2censo-component-lib";
import { useTranslation } from "react-i18next";

type IProps = {
  description: string;
  image?: string | JSX.Element;
};

const ModalLoadingCertificate = ({ description, image }: IProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Image data-testid="modal-loading-image" src={image || Backgroung} hasImage={!!image} />
      <Paragraph>
        {description}
        <br />
        <strong>{t("myCertificates.modalLoading.descriptionbold")}</strong>
      </Paragraph>
      <ContainerLoader>
        <Loader />
      </ContainerLoader>
    </Container>
  );
};

export default ModalLoadingCertificate;
