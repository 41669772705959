import styled from "styled-components";

export const Icon = styled.div`
  img {
    width: 24px;
    height: 24px;
  }
  padding: 13px 16px;
  background-color: ${({ theme }) => theme.brightTurquoise2};
  border-radius: 5px;
`;
