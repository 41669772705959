import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getPayments: ["params"],
  setPayments: ["paymentsList", "listSize"],
  submitPrepaidDestiny: ["issuer"],
  submitPrepaidDestinyEnd: [],
  approvePayment: ["payload"],
  rejectPayment: ["payload"],
  updateStatePaymentApprove: ["isApproved"],
  setIssuerPayments: ["issuerPaymentList", "paymentsList", "holidays"],
  setCampaignSelected: ["campaignSelected", "issuerPayment", "optionValue"],
  setOtherAmount: ["otherAmount"],
  payIssuerPayments: ["issuer"],
  payIssuerPaymentsEnd: [],
  fetchingEnd: [],
  setAmount: ["amount"],
  setShowState: ["showState"],
  setBanksList: ["banksList"],
  startMyPayments: []
});

export const PaymentsTypes = Types;
export default Creators;

const INITIAL_STATE = {
  isFetching: false,
  isLoading: false,
  paymentsList: [],
  issuerPaymentList: [],
  campaignSelected: 0,
  issuerPaymentSelected: {},
  otherAmount: 0,
  listSize: 0,
  showState: false,
  banksList: [],
  issuer: {},
  holidays: []
};

const payIssuerPayments = (state) => ({
  ...state,
  isFetching: true
});

const payIssuerPaymentsEnd = (state) => ({
  ...state,
  issuerPaymentSelected: {},
  isFetching: false
});

const fetchingEnd = (state) => ({
  ...state,
  isFetching: false
});

const submitPrepaidDestiny = (state) => ({ ...state, isFetching: true });

const submitPrepaidDestinyEnd = (state) => ({ ...state, isFetching: false });

const getPayments = (state) => ({
  ...state,
  isLoading: true
});

const startMyPayments = (state) => ({
  ...state,
  isLoading: true
});

const setPayments = (state, { paymentsList, listSize }) => ({
  ...state,
  paymentsList,
  listSize,
  isLoading: false
});

const approvePayment = (state) => ({
  ...state,
  isApproved: false
});

const rejectPayment = (state) => ({
  ...state,
  isApproved: false
});

const updateStatePaymentApprove = (state, { isApproved }) => ({
  ...state,
  isApproved
});

const setIssuerPayments = (
  state,
  { issuerPaymentList, paymentsList, holidays }
) => ({
  ...state,
  issuerPaymentList,
  paymentsList,
  holidays,
  isLoading: false
});

const setCampaignSelected = (state, { campaignSelected, issuerPayment }) => ({
  ...state,
  campaignSelected,
  issuerPaymentSelected: issuerPayment,
  isLoading: false
});

const setOtherAmount = (state, { otherAmount }) => ({
  ...state,
  otherAmount,
  isLoading: false
});

const setAmount = (state, amount) => ({
  ...state,
  ...amount
});

const setShowState = (state, { showState }) => ({
  ...state,
  showState,
  isLoading: false
});

const setBanksList = (state, { banksList }) => ({
  ...state,
  banksList
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUBMIT_PREPAID_DESTINY]: submitPrepaidDestiny,
  [Types.SUBMIT_PREPAID_DESTINY_END]: submitPrepaidDestinyEnd,
  [Types.GET_PAYMENTS]: getPayments,
  [Types.SET_PAYMENTS]: setPayments,
  [Types.APPROVE_PAYMENT]: approvePayment,
  [Types.REJECT_PAYMENT]: rejectPayment,
  [Types.UPDATE_STATE_PAYMENT_APPROVE]: updateStatePaymentApprove,
  [Types.SET_ISSUER_PAYMENTS]: setIssuerPayments,
  [Types.SET_CAMPAIGN_SELECTED]: setCampaignSelected,
  [Types.SET_OTHER_AMOUNT]: setOtherAmount,
  [Types.PAY_ISSUER_PAYMENTS]: payIssuerPayments,
  [Types.PAY_ISSUER_PAYMENTS_END]: payIssuerPaymentsEnd,
  [Types.FETCHING_END]: fetchingEnd,
  [Types.SET_AMOUNT]: setAmount,
  [Types.SET_SHOW_STATE]: setShowState,
  [Types.SET_BANKS_LIST]: setBanksList,
  [Types.START_MY_PAYMENTS]: startMyPayments
});
