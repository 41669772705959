export default {
  state: {
    accepted: "accepted",
    canceled: "canceled",
    pending: "pending",
    rejected: "rejected",
    approvedByBank: "approved_by_bank",
    failed: "failed"
  },
  type: {
    cancellation: "cancellation",
    capital: "capital",
    cashback: "cashback",
    earnings: "earnings",
    increase: "increase",
    investment: "investment",
    recharge: "recharge",
    retention: "retention",
    retirement: "retirement",
    interest: "interest",
    due_payment: "due_payment"
  },
  origin: {
    "4x1000": "4x1000",
    "7x100": "7x100",
    available: "available",
    campaign: "campaign",
    "fee-payment": "fee-payment",
    pse: "pse",
    unawarded: "unawarded",
    user: "user"
  }
};
