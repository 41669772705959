import React from "react";
import { useDispatch } from "react-redux";
import ModalActions from "../../redux/reducers/modal.reducer";
import Input from "../Input/Input";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import sendMessageActions from "../../redux/reducers/send-help.reducer";
import {
  Wrapper,
  Container,
  CloseButton,
  TextContainer,
  InputContainer
} from "./components";
import {
  Heading,
  Paragraph,
  Button
} from "@bvcco/a2censo-component-lib";

const HelpForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const sendData = (values: any) => {
    dispatch(sendMessageActions.startSendMessage(values));
  };

  const initialData = {
    message: ""
  };

  return (
    <>
      <Formik
        initialValues={initialData}
        onSubmit={(values) => sendData(values)}
      >
        {({ handleSubmit, values, handleChange, handleBlur }: any) => (
          <Wrapper>
            <CloseButton
              data-testid="close-modal-help"
              id="close-modal"
              onClick={() => {
                dispatch(ModalActions.setConditionalModalState(false));
              }}
            >
              <img alt="previous" src="/assets/images/icon-close.png" />
            </CloseButton>
            <Container>
              <TextContainer>
                <Heading> {t("myCertificates.help.modal.heading")} </Heading>
                <Paragraph>
                  {t("myCertificates.help.modal.paragraphPart1")}
                  <br />
                  {t("myCertificates.help.modal.paragraphPart2")}
                </Paragraph>
              </TextContainer>

              <InputContainer>
                <Paragraph>
                  {t("myCertificates.help.modal.paragraphTwo")}
                </Paragraph>
                <Input
                  inputType="textarea"
                  name="message"
                  id="message"
                  data-testid="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                />
                <Button
                  id="send-button"
                  dataTestid="send-button"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={values.message.length < 1}
                >
                  {t("myCertificates.help.modal.buttonText")}
                </Button>
              </InputContainer>
            </Container>
          </Wrapper>
        )}
      </Formik>
    </>
  );
};

export default HelpForm;
