import React, { useState } from "react";
import PropTypes from "prop-types";
import InputSummary from "../InputSummary/InputSummary";
import { Container } from "./Styles";
import SuggestionList from "./Suggestionlist";

const AutoComplete = ({
  value,
  suggestions,
  placeholder,
  onChangeText,
  error,
  regExpValidation,
  ...restProps
}) => {
  const [local, setLocal] = useState({ inputValue: value, filtered: [] });

  const debugSearch = (search) =>
    search !== undefined && search !== null
      ? search.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      : "";

  const filterSuggestions = (text) =>
    suggestions.filter((suggestion) =>
      text
        ? `${suggestion.id} ${debugSearch(
            suggestion.name?.toLowerCase()
          )}`.includes(text?.toLowerCase())
        : false
    ) || [];

  const handleChange = (event) => {
    if (
      regExpValidation.test(event.target.value) ||
      event.target.value === ""
    ) {
      setLocal({
        inputValue: event.target.value,
        filtered: filterSuggestions(event.target.value)
      });
      onChangeText(event.target.value);
    }
  };

  const handleClick = (selected) => {
    onChangeText(selected.id);
    setLocal({ inputValue: selected.id, filtered: [] });
  };

  return (
    <Container>
      <InputSummary
        data-testid="input-to-autocomplete"
        placeholder={placeholder}
        onChange={handleChange}
        value={local.inputValue}
        id="custom-autocomplete"
        showTooltip={true}
        error={error}
        {...restProps}
      />
      <SuggestionList
        items={local.filtered}
        handleClick={handleClick}
        data-testid="suggestion-list"
        id="suggestion-list"
      />
    </Container>
  );
};

AutoComplete.propTypes = {
  suggestions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.string])
  ).isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChangeText: PropTypes.func,
  error: PropTypes.string,
  regExpValidation: PropTypes.instanceOf(RegExp)
};

AutoComplete.defaultProps = {
  error: "",
  onChangeText: () => {},
  placeholder: "",
  regExpValidation: /./g,
  value: ""
};

export default AutoComplete;
