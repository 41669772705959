import styled from "styled-components";

export const Number = styled.h3`
  color: ${({ color }) => color};
  font-family: ${({ theme }) => (theme ? theme.font.family.secondary : "auto")};
  font-size: 42px;
  font-weight: 900;
  text-align: center;
  text-shadow: 3px 3px rgba(0, 0, 0, 0.08), -1px 0 ${({ color }) => color},
    0 1px ${({ color }) => color}, 1px 0 ${({ color }) => color},
    0 -1px ${({ color }) => color};
  letter-spacing: 1.88px;
  line-height: 55px;
`;
