import styled, { css } from "styled-components";

export const ContainerEmpty = styled.div`
  margin-bottom: 120px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.white};
`;

export const Header = styled.div`
  width: 90%;
  padding-top: 12px;
  padding-bottom: 13px;
  margin: auto;
  max-width: 1213px;
  display: flex;
  ${({ theme }) => theme.smartphone} {
    flex-direction: column;
  }

  button {
    background-color: ${({ theme }) => theme.seashell};
    width: 156px;
    height: 40px;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 800;
  }
  h1 {
    font-size: 18px;
    font-weight: 800;
    color: ${({ theme }) => theme.riverBed};
    line-height: 25px;
  }
`;

export const ContainerTitle = styled.div`
  ${({ theme }) => css`
    margin-left: 16px;
    align-self: center;
    font-family: ${theme.fontFamily.primary};
    p {
      color: ${theme.acentuar};
    }
  `}
  ${({ theme }) => theme.smartphone} {
    margin-top: 19px;
    margin-left: 0px;
  }
`;

export const Image = styled.img`
  width: 337px;
`;

export const ContainerInfo = styled.div`
  display: flex;
  justify-content: center;

  > div {
    margin-top: 24px;
    width: 883px;
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    > div {
      width: 323px;
    }
  }
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.riverBed};
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  font-family: ${({ theme }) =>
    theme ? theme.font.family.primary : "initial"};
  line-height: 24.55px;
  > strong {
    font-weight: 800;
  }
`;

export const FooterFormButton = styled.div`
  z-index: 3;
  width: 100%;
  height: 60px;
  background: none;
  position: fixed;
  bottom: 0;
  left: 0;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 17px;

  ${({ theme }) => theme.breakpoints.mobile} {
    gap: 0px;
  }
`;

export const Column = styled.div`
  flex: 1 1 0px;
  label {
    color: ${(props) => props.theme.cornFlower};
    font-family: ${(props) => props.theme.font.family.secondary};
  }

  #bank {
    width: 100%;
  }

  #bankAccountType {
    width: 100%;
  }

  .input__tooltip-logo-container {
    display: inline-block;
    width: auto;
  }

  > div > div {
    width: 100%;
    position: relative;
    & li {
      width: 100%;
    }
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    flex: 100%;
    margin-bottom: 20px;
  }
`;

export const SubLabel = styled(Paragraph)`
  line-height: 17.58px;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.blackLabelTax};
  font-family: ${(props) => props.theme.font.family.primary};
`;

export const Input = styled.input`
  width: 100%;
  outline: none;
  padding: 3px;
  height: 41px;
  padding-left: 16px;
  border: none;
  border-radius: 5px;
`;

export const CustomSpace = styled.div`
  height: 17px;
`;

export const Dasheds = styled.div`
  height: 0px;
  border: 1px dashed ${({ theme }) => theme.nobel};

  margin-bottom: 20px;
`;
