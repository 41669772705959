import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
export const InputContainer = styled.div`
  padding: 0 15px;
  ${(props) => props.theme.smartphone} {
    padding: 0 5px;
  }
  input.code-otp-input:focus {
    border-color: ${(props) => props.theme.brightTurquoise};
  }
`;
export const Input = styled.input`
  height: 60px;
  width: 100%;
  background-color: ${(props) => props.theme.white};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.darkGray};
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  font-family: Nunito;
  color: ${(props) => props.theme.tuatara};
  outline: none;
`;
