import styled from "styled-components";
import { types } from "../types";
import { LogoContainer } from "./CompanyLogo";
import { ButtonsRow } from "./ButtonsRow";
import { BusinessLines } from "lib/models";

interface Props {
  type: types;
  businessLine: BusinessLines;
}

const getBusinessColor = (businessLine: BusinessLines) => {
  const colors: Record<BusinessLines, string> = {
    [BusinessLines.Debt]: "robinsEggBlue",
    [BusinessLines.Shares]: "cornflowerBluee",
    [BusinessLines.ConvertibleNote]: "dodgerBlue",
    [BusinessLines.Equity]: "white"
  };

  return colors[businessLine];
};

export const Container = styled.div<Props>`
  flex: 1;
  display: flex;
  height: 350px;
  width: 350px;
  ${({ theme }) => theme.breakpoints.smallSmartphone} {
    width: 300px;
    z-index: 0;
  }
  border-radius: 12px;
  position: relative;

  ${LogoContainer} {
    border: 2px solid
      ${({ theme, businessLine }) => theme[getBusinessColor(businessLine)]};
  }

  ${ButtonsRow} {
    button {
      border: 2px solid
        ${({ theme, businessLine }) => theme[getBusinessColor(businessLine)]};
    }

    div:last-child {
      button {
        background-color: ${({ theme, businessLine }) =>
          theme[getBusinessColor(businessLine)]};
      }
    }
  }
`;
