import { catchError, mergeMap, map } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";

import investmentSummaryActions, {
  investSummaryTypes
} from "../reducers/investment-summary.reducer";

import { ShowcaseConnect } from "services";

export const getInvestmentSummary = (action$) =>
  action$.pipe(
    ofType(investSummaryTypes.FETCH_INVESTMENT_SUMMARY),
    mergeMap(() =>
      Observable.from(new ShowcaseConnect().getStats()).pipe(
        map((response) =>
          investmentSummaryActions.fetchInvestmentSummarySuccess(response)
        ),
        catchError(() => Observable.empty())
      )
    )
  );

export default combineEpics(getInvestmentSummary);
