import styled, { css } from "styled-components";

export enum TagColors {
  Blue = "blue",
  Purple = "purple",
  Green = "green",
  White = "white",
  Gray = "gray",
  Black = "black",
  DarkBlue = "dark-blue"
}

type Props = {
  background: TagColors | string;
  color?: TagColors | string;
  spacing?: boolean;
  forceCenter?: boolean;
  isChrome?: boolean;
};

const color: Record<TagColors | string, string> = {
  [TagColors.Green]: "robinsEggBlue",
  [TagColors.Purple]: "cornflowerBluee",
  [TagColors.DarkBlue]: "dodgerBlue",
  [TagColors.Gray]: "mercury",
  [TagColors.Blue]: "blue",
  [TagColors.Black]: "riverBed",
  [TagColors.White]: "white"
};

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-radius: 4px;
  padding: 8px 12px;
  width: fit-content;
  font-weight: bold;
  font-size: 14px;
  align-items: center;
  cursor: pointer;
  ${({ theme, background, color: baseColor, spacing }) =>
    css`
      background: ${theme[color[background] ?? background]};
      color: ${theme[color[baseColor ?? "black"] ?? color]};
      font: ${theme.fontFamily.primary};
      letter-spacing: ${spacing ? "0.18em" : "initial"};
    `}

  ${({ theme }) => theme.smartphone} {
    font-size: 12px;
    .__react_component_tooltip.show {
      width: 270px;
      position: sticky;
    }
  }
`;

export const Alb = styled.div<Props>`
  ${({ theme }) => theme.smartphone} {
    right: ${({ forceCenter }) => (forceCenter ? "32px" : "none")};
    position: absolute;
    display: table;
    margin-top: ${({ isChrome }) => (isChrome ? "109px" : "none")};
  }
`;
