const { Serializer } = require("jsonapi-serializer");

module.exports = {
  LoginSerializer: (meta = {}) =>
    new Serializer("login", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["email", "password", "loginAttempts"]
    })
};
