import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setModalState: ["modalState"],
  setIdentityValidationErrorModalState: ["identityValidationError"],
  setConditionalModalState: [
    "conditionalModalState",
    "componentToDisplay",
    "params"
  ],
  startContactFormFlow: ["startContactFormFlow"]
});

export const ServiceTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  modalState: false,
  identityValidationError: false,
  conditionalModalState: false,
  componentToDisplay: null,
  params: {}
};

const setModalState = (state, { modalState }) => ({ ...state, modalState });

const setIdentityValidationErrorModalState = (
  state,
  { identityValidationError }
) => ({
  ...state,
  identityValidationError
});

const setConditionalModalState = (
  state,
  { conditionalModalState, componentToDisplay = null, params = {} }
) => ({
  ...state,
  conditionalModalState,
  componentToDisplay,
  params
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_MODAL_STATE]: setModalState,
  [Types.SET_IDENTITY_VALIDATION_ERROR_MODAL_STATE]: setIdentityValidationErrorModalState,
  [Types.SET_CONDITIONAL_MODAL_STATE]: setConditionalModalState
});
