import styled from "styled-components";

export const Container = styled.div`
  width: 1210px;
  height: 100%;
  display: flex;
  border-radius: 12px;
  background: ${({ theme }) => theme.blueUserDataGradient};

  ${({ theme }) => theme.breakpoints.whenSmartphone} {
    .custom-alert {
      div {
        padding-right: 10px;
        img {
          margin-bottom: 12px;
        }
      }
    }
  }

  h2 {
    margin-bottom: 17px;
  }

  p {
    margin-bottom: 22px;
  }

  form {
    margin-top: 16px;

    input {
      margin-top: 8px;
    }

    .input__field {
      font-size: 14px;
      padding: 16px 16px;
    }

    ul {
      top: 52px;
    }
  }

  ${({ theme }) => theme.breakpoints.whenSmartphone} {
    flex-wrap: wrap;
    width: 100%;
  }
`;
