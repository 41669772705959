import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { callEquityPopup } from "redux/store";

interface Props {
  children: React.ReactNode;
}

export const InitialProcessHOC = ({ children }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(callEquityPopup());
  }, []);

  return <>{children}</>;
};
