const { Serializer } = require("jsonapi-serializer");

module.exports = {
  ConfirmDataLegalSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "name",
        "dv",
        "document_type",
        "document_number",
        "person_type",
        "user_type_id",
        "phone_code",
        "existence_certificate",
        "phone_number",
        "ciiu"
      ]
    })
};
