import { catchError, flatMap, map, mergeMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";

import PageDataActions, { PageDataTypes } from "../reducers/page-data.reducer";

export const getFoundationData = (action$) =>
  action$.pipe(
    ofType(PageDataTypes.GET_HOME_DATA),
    flatMap(() => Observable.from(App.api.a2censo.getHomeData())),
    map((response) => PageDataActions.setHomeData({ homeData: response })),
    catchError(() =>
      Observable.concat(Observable.of(PageDataActions.clearHomeData()))
    )
  );

export const getSimulatorData = (action$) =>
  action$.pipe(
    ofType(PageDataTypes.GET_SIMULATOR_DATA),
    mergeMap(() =>
      Observable.concat(
        Observable.of(PageDataActions.setSimulatorStatus("loading")),
        Observable.from(App.api.a2censo.getSimulatorData()).pipe(
          mergeMap((response) =>
            Observable.concat(
              Observable.of(
                PageDataActions.setSimulatorData(response.simulator)
              ),
              Observable.of(PageDataActions.setSimulatorStatus("loaded"))
            )
          ),
          catchError(() =>
            Observable.of(PageDataActions.setSimulatorStatus("failed"))
          )
        )
      )
    )
  );

export default combineEpics(getFoundationData, getSimulatorData);
