const { Serializer } = require("jsonapi-serializer");

module.exports = {
  moneyWithdrawalSerializer: (meta = {}) =>
    new Serializer("money-withdrawal", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "amount",
        "bank",
        "bank_account_type",
        "account_number",
        "bank_name",
        "holder_names",
        "holder_first_last_name",
        "holder_second_last_name",
        "holder_document_number",
        "holder_document_type",
        "holder_dv"
      ]
    })
};
