import styled from "styled-components";

export const ContainerHelp = styled.div`
  & > p {
    width: 329px;
    font-size: 14px;
    line-height: 15px;
    font-weight: 600;
    ${({ theme }) => theme.smartphone} {
      text-align: center;
    }
  }
  margin-top: 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.smartphone} {
    flex-direction: column;
  }
`;
