import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 15px;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 900px;
  height: calc(100vh - 118px);
  padding: 15px;
  ${(props) => props.theme.smartphone} {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 15px;
`;

export const Head = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 15px;
`;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 25px;
  width: 30px;
  ${(props) => props.theme.tablet} {
    margin-top: 20px;
  }
`;

export const BackButtonImage = styled.img`
  height: 26px;
  width: 26px;
  object-fit: contain;
  ${(props) => props.theme.smartphone} {
    display: none;
  }
`;

export const GoBack = styled.div`
  display: flex;
  height: 116px;
  width: 100%;
  ${(props) => props.theme.tablet} {
    height: 60px;
  }
`;

export const TitleMsg = styled.h1`
  font-family: "Nunito";
  ${(props) => props.theme.smartphone} {
    font-size: 20px;
  }
`;

export const Paragraph = styled.p`
  text-align: center;
  margin: 16px 0 10px 0;
  line-height: 28px;
  width: 320px;
  ${(props) => props.theme.smartphone} {
    width: 242px;
    font-size: 16px;
    margin: 10px 0 8px 0;
    line-height: 28px;
  }
`;

export const Image = styled.img`
  height: 86px;
  margin-bottom: 32px;
  width: 90px;
  ${(props) => props.theme.smartphone} {
    height: 72px;
    margin-bottom: 32px;
    width: 75px;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  height: 44px;
  width: 184px;
  ${(props) => props.theme.smartphone} {
    height: 38px;
    button {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 72px;
  height: 44px;
  width: 390px;
  ${(props) => props.theme.smartphone} {
    align-items: center;
    height: 88px;
    margin-top: 36px;
    flex-direction: column;
  }
`;
