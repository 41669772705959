import { createReducer, createActions } from "reduxsauce";
import {
  STAGE_REQUEST_CAMPAIGN_STATUS,
  STAGE_STATUS,
  AVAILABLE_STEPS
} from "../../lib/models/campaign.model";

const { Types, Creators } = createActions({
  setStep: ["step"],
  selectEditableTab: ["tabId"],
  fetchStageList: ["requestCampaignId"],
  fetchStageListSuccess: ["stageList"],
  requestError: [],
  changeStateStage: ["from"],
  changeStateStageLogout: ["from"],
  cleanAll: [],
  resetStages: [],
  submittingStep: [],
  sendPublishConfirmationEmail: ["campaignId", "companyName"]
});

export const CampaignStepperTypes = Types;

export default Creators;

export const AVAILABLE_TABS = [
  {
    id: AVAILABLE_STEPS.CAMPAIGN_INFORMATION,
    type: "text",
    lngResource: "companyCampaignInfo.tabs.tab1"
  },
  {
    id: AVAILABLE_STEPS.FIRST_DOCUMENTS,
    type: "text",
    lngResource: "companyCampaignInfo.tabs.tab2"
  },
  {
    id: AVAILABLE_STEPS.INTERVIEW,
    type: "text",
    lngResource: "companyCampaignInfo.tabs.tab3"
  },
  {
    id: AVAILABLE_STEPS.ADDITIONAL_INFORMATION,
    type: "text",
    lngResource: "companyCampaignInfo.tabs.tab4"
  },
  {
    id: AVAILABLE_STEPS.COMMITEE_ANALYSIS,
    type: "text",
    lngResource: "companyCampaignInfo.tabs.tab5"
  },
  {
    id: AVAILABLE_STEPS.PRE_APPROVAL,
    type: "text",
    lngResource: "companyCampaignInfo.tabs.tab6"
  },
  {
    id: AVAILABLE_STEPS.RELEASE,
    type: "text",
    lngResource: "companyCampaignInfo.tabs.tab7"
  }
];

export const INITIAL_STATE = {
  currentTabId: 0,
  activeTab: 0,
  stageList: [],
  isFetching: false,
  isSubmitting: false,
  selectedRequestCampaignId: null,
  stage: null,
  availableTabs: [...AVAILABLE_TABS]
};

const getUpdatedAvailableTabs = ({
  availableTabs = [],
  stageList = [],
  newTabId
}) =>
  availableTabs.map((tab) => {
    const stage = stageList.find((stageItem) => stageItem.stage_id === tab.id);

    if (stage && stage.stage_id === newTabId) {
      return { ...tab, state: "active" };
    }

    const { COMPLETED, ACTIVE } = STAGE_REQUEST_CAMPAIGN_STATUS;
    const doneStates = [COMPLETED, ACTIVE];

    if (stage && doneStates.includes(stage.status)) {
      return { ...tab, state: "done" };
    }

    return tab;
  });

const setStep = (state, { step }) => ({
  ...state,
  currentTabId: step.currentTabId,
  availableTabs: getUpdatedAvailableTabs({
    ...state,
    newTabId: step.currentTabId
  })
});

const setEditableTab = (tabs, stageList) =>
  tabs.map((tab) => {
    const stage = stageList.find((stageItem) => stageItem.stage_id === tab.id);

    return stage &&
      stage.status === STAGE_REQUEST_CAMPAIGN_STATUS.CHANGE_REQUEST
      ? { ...tab, state: "edit" }
      : { ...tab };
  });

const selectEditableTab = (state, { tabId }) => {
  const isEditable = !!state.availableTabs.find(
    (item) => item.id === tabId && item.state === "edit"
  );
  if (!isEditable) {
    return { ...state };
  }

  const stageSelected = state.stageList.find(
    (stage) => stage.stage_id === tabId
  );

  return {
    ...state,
    stage: stageSelected,
    currentTabId: tabId,
    availableTabs: getUpdatedAvailableTabs({
      ...state,
      newTabId: tabId
    })
  };
};

const fetchStageList = (state, { requestCampaignId }) => ({
  ...state,
  isFetching: true,
  selectedRequestCampaignId: requestCampaignId
});

const fetchStageListSuccess = (state, { stageList }) => {
  const tabs = setEditableTab(AVAILABLE_TABS, stageList);

  const stages = stageList.map((item) => {
    if (item.status) {
      return { ...item };
    }

    const status = Object.keys(STAGE_STATUS)
      .find((statusId) => STAGE_STATUS[statusId] === item.status_id)
      .toLowerCase();

    return { ...item, status };
  });

  const stagesReverse = [...stages].sort((a, b) => b.stage_id - a.stage_id);

  const stage = stagesReverse.find(
    (status) =>
      status.status === STAGE_REQUEST_CAMPAIGN_STATUS.ACTIVE ||
      status.status === STAGE_REQUEST_CAMPAIGN_STATUS.PENDING_APPROVE ||
      status.status === STAGE_REQUEST_CAMPAIGN_STATUS.CHANGE_REQUEST ||
      status.status === STAGE_REQUEST_CAMPAIGN_STATUS.REJECTED ||
      status.status === STAGE_REQUEST_CAMPAIGN_STATUS.COMPLETED
  );

  const isStageFourCompleted = stages.find(
    (status) =>
      status.status === STAGE_REQUEST_CAMPAIGN_STATUS.COMPLETED &&
      status.stage_id === AVAILABLE_STEPS.ADDITIONAL_INFORMATION
  );

  if (!stage && !isStageFourCompleted) {
    return { ...state };
  }

  const currentTabId = stage ? stage.stage_id : isStageFourCompleted.stage_id;

  return {
    ...state,
    isFetching: false,
    stageList: stages,
    availableTabs: [...tabs],
    activeTab: currentTabId,
    isSubmitting: false,
    stage: stage || isStageFourCompleted,
    currentTabId
  };
};

const cleanAll = () => ({ ...INITIAL_STATE });

const sendPublishConfirmationEmail = (state, { campaignId, companyName }) => ({
  ...state,
  isSubmitting: true,
  campaignId,
  companyName
});

const changeStateStage = (state) => ({
  ...state,
  isSubmitting: true
});

const changeStateStageLogout = (state) => ({
  ...state,
  isSubmitting: true
});

const submittingStep = (state) => ({
  ...state,
  isSubmitting: true
});

const requestError = (state) => ({
  ...state,
  isFetching: false,
  isSubmitting: false
});

const resetStages = (state) => ({
  ...state,
  stageList: [],
  activeTab: 0,
  currentTabId: 0,
  selectedRequestCampaignId: null,
  stage: null
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_STEP]: setStep,
  [Types.FETCH_STAGE_LIST]: fetchStageList,
  [Types.FETCH_STAGE_LIST_SUCCESS]: fetchStageListSuccess,
  [Types.REQUEST_ERROR]: requestError,
  [Types.SELECT_EDITABLE_TAB]: selectEditableTab,
  [Types.CLEAN_ALL]: cleanAll,
  [Types.CHANGE_STATE_STAGE]: changeStateStage,
  [Types.CHANGE_STATE_STAGE_LOGOUT]: changeStateStageLogout,
  [Types.RESET_STAGES]: resetStages,
  [Types.SUBMITTING_STEP]: submittingStep,
  [Types.SEND_PUBLISH_CONFIRMATION_EMAIL]: sendPublishConfirmationEmail
});
