import environment from "environment";
import q from "query-string";
import { ICampaign } from "redux/store/campaigns";
import { Connect } from "./connect";
import { IResponseCampaign, IGetCampaigns, IGetStats, IFilters } from "./types";
import { getCampaignsNormalizer } from "./showcase.normalizer";

export class ShowcaseConnect extends Connect {
  constructor() {
    const { apiKey, apiShowcaseUrl, apiShowcasePrefix } = environment;
    super(apiShowcaseUrl, apiShowcasePrefix, { apiKey });
  }

  async getCampaigns({
    state,
    page,
    limit,
    filters = ""
  }: IGetCampaigns): Promise<{
    campaigns: ICampaign[];
    total: number;
    currentPage: number;
    totalPages: number;
  }> {
    const filtersParsed = q.parse(filters);
    const response: any = await this.request({
      method: "get",
      service: "campaigns",
      params: { page, limit, state, ...filtersParsed }
    });

    // normalize data
    const campaigns: IResponseCampaign[] = response?.data.data;
    const serverTime: string = response?.data.serverTime;
    const total: number = response?.data.paginationOptions?.total;
    const currentPage: number = response?.data.paginationOptions?.page;
    const totalPages: number = response?.data.links.totalPages;

    return Promise.resolve({
      campaigns: (campaigns || []).map((campaign: IResponseCampaign) =>
        getCampaignsNormalizer(campaign, serverTime)
      ),
      total,
      currentPage: Number(currentPage),
      totalPages: Number(totalPages)
    });
  }

  async getStats(): Promise<IGetStats> {
    const response = await this.request({
      method: "get",
      service: "campaigns/stats"
    });
    return Promise.resolve(response?.data?.data);
  }

  async getFiltersCampaigns(): Promise<IFilters> {
    const response = await this.request({
      method: "get",
      service: "campaigns/active/filters"
    });
    return Promise.resolve(response?.data?.data);
  }

  async getAwardedCampaigns(): Promise<IResponseCampaign[]> {
    const response = await this.request({
      method: "get",
      service: "campaigns/awarded"
    });

    return Promise.resolve(response?.data.data);
  }
}
