import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle` 
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
  }

  .react-datepicker-wrapper {
    width: 100% !important;
  }

  .tooltip_info {
    opacity: 1 !important;
    background-color: white;
  }

  @media screen and (max-width: 480px) {
    .react-datepicker__input-container > input {
      font-size: 13px !important;
    }
  }
`;

export default GlobalStyle;
