import React from "react";
import { useTranslation } from "react-i18next";
import KeywordFilter from "./../KeywordFilter/KeywordFilter";
import FilterBy from "./../FilterBy/FilterBy";
import "./InvestFilterDetail.scss";

const InvestFilterDetail = ({
  setNameFilter,
  setNumberPage,
  setValueSelected,
  setBusinessLineFilter,
  setInvestmentStateFilter,
  setcampaignStateFilter,
  setisFilterBy,
  isDisabled
}: any) => {
  const { t } = useTranslation();

  const restoreValues = () => {
    setValueSelected(1);
    setNumberPage(1);
  };

  const keywordFilterSearch = (e: any) => {
    restoreValues();
    setNameFilter(e.target.value);
  };

  const filterBySearch = (
    businessLine: number,
    investmentState: string,
    campaignState: string
  ) => {
    restoreValues();
    setBusinessLineFilter(businessLine);
    setInvestmentStateFilter(investmentState);
    setcampaignStateFilter(campaignState);
    setisFilterBy(true);
  };

  return (
    <>
      <div className="searchInvestDetail">
        <FilterBy isDisabled={isDisabled} filterBySearch={filterBySearch} />
        <KeywordFilter
          isDisabled={isDisabled}
          keywordFilterSearch={keywordFilterSearch}
        />
      </div>
    </>
  );
};

export default InvestFilterDetail;
