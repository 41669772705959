import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setError: ["error"]
});

export const PaymentsTypes = Types;
export default Creators;

const INITIAL_STATE = {
  error: ""
};

const setError = (state, { error }) => ({ ...state, error });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ERROR]: setError
});
