import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isMobile, isBrowser } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { pathOr } from "ramda";
import Movements from "../../components/Movements/Movements";
import MovementsFilters from "../../components/MovementsFilters/MovementsFilters";
import InfiniteScroll from "../../components/paginator/InfiniteScroll/InfiniteScroll";
import MovementsActions from "../../redux/reducers/movements.reducer";
import Pagination from "../../components/paginator/pagination/pagination";
import placeholder from "../../assets/images/search_without_result.png";
import {
  Container,
  Footer,
  FooterContent,
  Label,
  DesktopView,
  MovementsListContainer,
  PaginationContainer,
  BackButton,
  BackButtonImage,
  BackContainer,
  Header,
  Title,
  Placeholder,
  ImgContainer,
  PlaceholderImage,
  PlaceholderTitle,
  PlaceholderSubtitle
} from "./Styles";
import { PageMovementsContainer } from "../../styles/Styles";

const MyMovements = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading, isLoadingFilters, list } = useSelector(
    ({ movements }) => movements
  );
  useEffect(() => {
    dispatch(MovementsActions.getMovements({ isInfiniteScroll: isMobile }));
    return () => {};
  }, []);
  const totalPages = pathOr(0, ["pagination_params", "total_pages"], list);
  const currentPage = pathOr(0, ["pagination_params", "current_page"], list);
  const hasMovements = list.transactions ? !!list.transactions.length : false;
  const hasSessionToken = !!sessionStorage.getItem("idToken");
  const [page, setPage] = useState(1);
  const fetchMovements = (requestedPage) => {
    if (currentPage < totalPages || requestedPage < currentPage) {
      let nextPage = requestedPage;
      if (!nextPage) {
        nextPage = page + 1;
      }
      nextPage = nextPage > 0 ? nextPage : 1;

      if (list.filterResponse === "") {
        dispatch(
          MovementsActions.getMovements({
            page: nextPage,
            isInfiniteScroll: isMobile
          })
        );
      } else {
        dispatch(
          MovementsActions.getFilteredMovements({
            filters: list.filterResponse,
            page: nextPage,
            isInfiniteScroll: isMobile
          })
        );
      }
      setPage(nextPage);
    }
  };

  const handleBack = () => {
    dispatch(push("/my-balance"));
  };

  const loading = isLoading || isLoadingFilters;

  const handleScrollDown = () => {
    if (loading) {
      return;
    }
    fetchMovements(0);
  };

  const withoutResults = () => (
    <MovementsListContainer>
      <Placeholder>
        <ImgContainer>
          <PlaceholderImage src={placeholder} />
        </ImgContainer>
        <PlaceholderTitle>
          {t("myMovements.searchPlaceholder.title")}
        </PlaceholderTitle>
        <PlaceholderSubtitle>
          {t("myMovements.searchPlaceholder.subtitle")}
        </PlaceholderSubtitle>
      </Placeholder>
    </MovementsListContainer>
  );
  return (
    hasSessionToken && (
      <Container>
        <InfiniteScroll
          id="infinite-scroll-my-movements"
          enable={isMobile}
          delay={500}
          onScrollDown={handleScrollDown}
        >
          <PageMovementsContainer background="#f5f6f8;">
            <BackContainer>
              <BackButton id="back-button" onClick={handleBack}>
                <BackButtonImage
                  alt="previous"
                  src="/assets/images/previous.png"
                />
              </BackButton>
            </BackContainer>
            <Header>
              <Title>{t("movements.title")}</Title>
            </Header>
            <MovementsFilters
              id="movements-filters-component"
              searchParams={(values) =>
                dispatch(
                  MovementsActions.getFilteredMovements({ filters: values })
                )
              }
            />
            {hasMovements ? (
              <>
                {isMobile && <Movements items={list} />}
                {isBrowser && (
                  <DesktopView>
                    <Movements items={list} />
                    <PaginationContainer>
                      <Pagination
                        id="pagination-to-movements"
                        totalItems={list.pagination_params.total_items}
                        onClick={(selectedPage) => {
                          fetchMovements(selectedPage);
                        }}
                        pageSelected={page}
                        itemsPerPage={10}
                        pagesPerView={5}
                      />
                    </PaginationContainer>
                  </DesktopView>
                )}
              </>
            ) : (
              withoutResults()
            )}
          </PageMovementsContainer>
          {loading && (
            <Footer>
              <FooterContent>
                <Label>{t("movements.loading")}</Label>
              </FooterContent>
            </Footer>
          )}
        </InfiniteScroll>
      </Container>
    )
  );
};

export default MyMovements;
