const { Serializer } = require("jsonapi-serializer");

module.exports = {
  ChallengeQuestionsSerializer: (meta = {}) =>
    new Serializer("challenge-questionnaire", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["id", "reg", "challenge_question"],
      challenge_question: {
        ref: "id",
        attributes: ["id", "selected_option"]
      }
    })
};
