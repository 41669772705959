import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  background-color: ${({ theme }) => theme.royalBlue};
  border-radius: 5px;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.primary};
  font-size: 14px;
  font-weight: 600;
`;
