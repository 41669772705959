import styled from "styled-components";

export const Icon = styled.div`
  font-size: 2em;
`;

export const LastIcon = styled.div`
  font-size: 1.5em;
  ${(props) => props.theme.smartphone} {
    right: 35px;
    position: absolute;
  }
`;
