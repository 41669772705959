import styled from "styled-components";
import { Button as btn } from "@bvcco/a2censo-component-lib";

export const Container = styled.div`
  width: 941px;
  height: 473px;
  background-color: ${(props) => props.theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  & h1 {
    font-size: 30px;
  }
  ${(props) => props.theme.smartphone} {
    padding: 10px 0 10px;
    margin: 0 5%;
  }
  border-radius: 10px;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => props.theme.smartphone} {
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-direction: column-reverse;
  }
`;

export const ParagraphContainer = styled.div`
  & > p {
    line-height: 28px;
  }
  width: 393px;
  margin-bottom: 85px;
  margin-top: 5px;
  ${(props) => props.theme.smartphone} {
    width: 277px;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 40px;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.brightTurquoise};
  margin-bottom: 14px;
`;

export const Button = styled(btn)`
  width: 175px;
  height: 49px;
  margin: 2%;
  font-weight: bold;
  cursor: pointer;
  ${(props) => props.theme.smartphone} {
    width: 175px;
  }
`;

export const Image = styled.img`
  height: 62px;
  width: 62px;
  margin-top: 120px;
  margin-bottom: 25px;
  object-fit: contain;
  ${({ theme }) => theme.smartphone} {
    margin-top: 100px;
  }
`;
