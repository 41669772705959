import styled from "styled-components";

export const Avatar = styled.div`
  flex: 0 0 54px;
  img {
    width: 100%;
  }
  margin-right: 13px;
  ${({ theme }) => theme.breakpoints.smallSmartphone} {
    margin-right: 6px;
  }
`;
