import React from "react";
import { Tags } from "./components";
import { BusinessTag, BusinessTagTypes } from "components/shared";
import { BusinessLines } from "lib/models";

type Props = {
  businessLine: BusinessLines;
  idTooltip: any;
};

export const CampaignCardTags = ({
  businessLine = BusinessLines.Debt,
  idTooltip
}: Props) => (
  <Tags>
    <BusinessTag businessLine={businessLine} type={BusinessTagTypes.Primary} idTooltip={idTooltip}/>
  </Tags>
);
