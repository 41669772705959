import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Heading, Paragraph, Dots } from "@bvcco/a2censo-component-lib";
import {
  StyledContainer,
  StyledListContainer,
  StyledAllie,
  StyledImage
} from "./Styles";
import environment from "environment";

const Allies = () => {
  const assets = `${environment.aws.url}/`;
  const { t } = useTranslation();
  const { footer } = useSelector(({ pageData }) => pageData.home.homeData);
  const { allies } = footer;
  const alliesList = allies.filter((allie) => allie.is_main);

  const renderAllies = () => {
    const numberOfColumns = 3;
    return alliesList.map((a, i) => {
      if (i % numberOfColumns === 0) {
        const isLast =
          alliesList.length < numberOfColumns
            ? true
            : i + numberOfColumns >= alliesList.length;
        return (
          <React.Fragment key={`container-support-${i}`}>
            <StyledListContainer>
              {alliesList.map((allie, index) => {
                if (index >= i && index < i + numberOfColumns) {
                  return (
                    <StyledAllie key={`support-${index}`}>
                      <StyledImage src={assets + allie.logo} />
                      <Paragraph
                        color="comet"
                        align="center"
                        className={allie.name}
                      >
                        {allie.description}
                      </Paragraph>
                    </StyledAllie>
                  );
                }
                return "";
              })}
            </StyledListContainer>
            {!isLast && (
              <Dots
                className="dots"
                color="comet"
                spacing={{
                  mobile: "10px",
                  desktop: "10px"
                }}
                size={{
                  mobile: "11%",
                  desktop: "11%"
                }}
              />
            )}
          </React.Fragment>
        );
      }
      return "";
    });
  };
  return (
    <StyledContainer>
      <Heading fontWeight="bold" color="comet" align="center">
        {t("a2censo.allies")}
      </Heading>
      {renderAllies()}
    </StyledContainer>
  );
};

export default Allies;
