import React from "react";
import CardIMG from "assets/images/card-document.png";
import CampaignMail from "assets/images/campaign-mail.png";
import DownloadCertificate from "assets/images/download-certificate.png";

import {
  CardWrapper,
  CardRightWrapper,
  Image,
  ButtonsWrapper,
  TitleCompany,
  CertificateQuantity,
  Description,
  ListWrapper,
  ListNameCompanyWrapper,
  ListInfoWrapper,
  ListQuantityWrapper,
  ListbuttonsWrapper,
  Label
} from "./components";

import { useTranslation } from "react-i18next";

export enum CertificateCardFromType {
  Card = "card",
  List = "list"
}
interface CertificateCardProps extends React.HTMLProps<HTMLDivElement> {
  dataTestId?: string;
  listDataTestId?: string;
  sendMailTestId?: string;
  downloadCertificatedTestId?: string;
  quantityTestId?: string;
  quantity: number | string;
  description: string;
  companyName: string;
  containerType: CertificateCardFromType;
  sendMail?: () => void;
  isInvestor: boolean;
  downloadCertificate?: () => void;
  disabledDownload?: boolean;
  companyNameLength: number;
}

export const CertificateCardItem = ({
  dataTestId = "campaign-card",
  listDataTestId = "campaign-list",
  sendMailTestId = "send-mail",
  quantityTestId = "quantity",
  downloadCertificatedTestId = "download-certificate",
  sendMail = () => {},
  downloadCertificate = () => {},
  description,
  quantity,
  companyName,
  containerType,
  isInvestor,
  disabledDownload,
  companyNameLength,
  ...props
}: CertificateCardProps) => {
  const { t } = useTranslation();
  const divProps: any = props;
  const eventSendMail = () => {
    sendMail();
  };
  const eventDownloadCertificate = () => {
    downloadCertificate();
  };

  const type = isInvestor ? "investor" : "pyme";

  return containerType == CertificateCardFromType.Card ? (
    <CardWrapper data-testid={dataTestId} {...divProps}>
      <Image src={CardIMG} />
      <CardRightWrapper
        isInvestor={isInvestor}
        companyNameLength={companyNameLength}
      >
        {isInvestor && <TitleCompany>{companyName}</TitleCompany>}
        <CertificateQuantity data-testid={quantityTestId}>{`${
          quantity ? quantity : 0
        } ${
          quantity > 1 || quantity == undefined
            ? t(`myCertificates.certificateCard.${type}.someCampaigns`)
            : t(`myCertificates.certificateCard.${type}.aCampaign`)
        }`}</CertificateQuantity>
        <Description>{description}</Description>
        <ButtonsWrapper disabledDownload={!disabledDownload}>
          <a onClick={eventSendMail} data-testid={sendMailTestId}>
            <img src={CampaignMail} />
          </a>
          {!disabledDownload && (
            <a
              onClick={eventDownloadCertificate}
              data-testid={downloadCertificatedTestId}
            >
              <img src={DownloadCertificate} />
            </a>
          )}
        </ButtonsWrapper>
      </CardRightWrapper>
    </CardWrapper>
  ) : (
    <ListWrapper
      data-testid={listDataTestId}
      isInvestor={isInvestor}
      disabledDownload={disabledDownload}
    >
      <ListNameCompanyWrapper isInvestor={isInvestor}>
        <Image src={CardIMG} />
        <div>
          <Label>
            {t(`myCertificates.certificateCard.${type}.labelCompanyName`)}
          </Label>

          <TitleCompany>
            <strong>{isInvestor ? companyName : description}</strong>
          </TitleCompany>
        </div>
      </ListNameCompanyWrapper>
      {isInvestor && (
        <ListInfoWrapper>
          <Label>
            {t(`myCertificates.certificateCard.${type}.labelDescription`)}
          </Label>
          <Description>{description}</Description>
        </ListInfoWrapper>
      )}
      <ListQuantityWrapper>
        <Label>
          {t(`myCertificates.certificateCard.${type}.labelQuantity`)}
        </Label>
        <CertificateQuantity>
          <strong>{`${quantity ? quantity : 0} ${
            quantity > 1 || quantity == undefined
              ? t(`myCertificates.certificateCard.${type}.someCampaigns`)
              : t(`myCertificates.certificateCard.${type}.aCampaign`)
          }`}</strong>
        </CertificateQuantity>
      </ListQuantityWrapper>
      <ListbuttonsWrapper>
        <a onClick={eventSendMail} data-testid={sendMailTestId}>
          <img src={CampaignMail} />
        </a>
        {!disabledDownload && (
          <a
            onClick={eventDownloadCertificate}
            data-testid={downloadCertificatedTestId}
          >
            <img src={DownloadCertificate} />
          </a>
        )}
      </ListbuttonsWrapper>
    </ListWrapper>
  );
};
