/* eslint-disable no-underscore-dangle */
import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { forkJoin } from "rxjs";
import { Observable } from "rxjs-compat";
import UserActions from "../reducers/user.reducer";
import User from "../../lib/models/user.model";
import App from "../../lib/app";
import PlacesActions from "../reducers/places.reducer";
import LoginActions from "../reducers/login.reducer";
import AnalyticsActions from "../reducers/analytics.reducer";
import AnalyticsModel from "../../lib/models/analytics.model";
import ModalActions from "../reducers/modal.reducer";
import { getErrorIndex } from "../../lib/utils";
import paramNames from "../../lib/models/parameter.model";

const {
  QUERIES: { citiesByCountryId },
  COUNTRY_ID: { COL },
  HASHED_CODE_PARAMS
} = paramNames;
const { _PARAM_002, _PARAM_013 } = HASHED_CODE_PARAMS();

export const initialSummaryDataForm = ({ tableNames, personType }) => ({
  type: "INITIAL_SUMMARY_DATA",
  payload: {
    tableNames,
    personType
  }
});

export const saveSummaryDataForm = ({ ...summaryInfo }) => ({
  type: "SAVE_SUMMARY_DATA",
  payload: {
    summaryInfo
  }
});

export const confirmSummaryDataFail = ({ error }) => ({
  type: "CONFIRM_SUMMARY_FAIL",
  payload: {
    error
  }
});

export const initialSummaryData = (action$) =>
  action$.pipe(
    ofType("INITIAL_SUMMARY_DATA"),
    flatMap((action) =>
      forkJoin([
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: { tables: action.payload.tableNames }
          })
        ),
        Observable.from(
          App.api.a2censo.purposeList({
            url: { personType: action.payload.personType }
          })
        ),
        Observable.from(App.api.a2censo.usersInfo()),
        Observable.from(App.api.a2censo.getSummaryBirthInfo()),
        Observable.from(App.api.a2censo.getSummaryFinancialInfo()),
        Observable.from(App.api.a2censo.getSummaryFamilyInfo()),
        Observable.from(App.api.a2censo.getSummaryFatcaInfo()),
        Observable.from(App.api.a2censo.getSummaryPepInfo()),
        Observable.from(App.api.a2censo.getSummaryPurposeInfo()),
        Observable.from(App.api.a2censo.getUSInformationQuestions()),
        Observable.from(App.api.a2censo.getPEPInformationQuestions()),
        Observable.from(
          App.api.a2censo.getStaticParameters({
            url: {
              tables: `${_PARAM_002}&${_PARAM_013}&${citiesByCountryId}=${COL}`
            }
          })
        )
      ]).pipe(
        flatMap((response) =>
          forkJoin([
            Observable.from(
              App.api.a2censo.getCity({
                url: { id: response[3].birth_city }
              })
            ),
            Observable.from(
              App.api.a2censo.getCity({
                url: { id: response[3].city }
              })
            ),
            Observable.from(
              App.api.a2censo.getStaticParameters({
                url: {
                  tables: `${_PARAM_002}&${_PARAM_013}&${citiesByCountryId}=${response[2].natural_person.country_id}`
                }
              })
            ),
            Observable.from(
              App.api.a2censo.getStaticParameters({
                url: {
                  tables: `${_PARAM_002}&${_PARAM_013}&${citiesByCountryId}=${response[2].natural_person.birth_country_id}`
                }
              })
            )
          ]).pipe(
            flatMap((secondResponse) => {
              response[3].birth_department = secondResponse[0][0].department_id;
              response[3].birth_country = secondResponse[0][0].country_id;
              response[3].department = secondResponse[1][0].department_id;
              response[3].country = secondResponse[1][0].country_id;
              return Observable.concat(
                Observable.of(
                  PlacesActions.setDepartmentsInfo(
                    response[0]._department.filter(
                      (departmentElement) =>
                        departmentElement.country_id == response[3].country
                    )
                  )
                ),
                Observable.of(
                  PlacesActions.setCitiesInfo(
                    secondResponse[2]._city.filter(
                      (cityElement) =>
                        cityElement.department_id == response[3].department
                    )
                  )
                ),
                Observable.of(
                  PlacesActions.setDepartmentsBirth(
                    response[0]._department.filter(
                      (departmentElement) =>
                        departmentElement.country_id ==
                        response[3].birth_country
                    )
                  )
                ),
                Observable.of(
                  PlacesActions.setCitiesBirth(
                    secondResponse[3]._city.filter(
                      (cityElement) =>
                        cityElement.department_id ==
                        response[3].birth_department
                    )
                  )
                ),
                Observable.of(
                  push("/enrollment/summary", {
                    response
                  })
                )
              );
            })
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }

          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              ),
              Observable.of(confirmSummaryDataFail(e))
            );
          }

          return Observable.concat(
            Observable.of(confirmSummaryDataFail(e)),
            Observable.of(push("/oops"))
          );
        })
      )
    )
  );

export const saveSummaryData = (action$) =>
  action$.pipe(
    ofType("SAVE_SUMMARY_DATA"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.updateSummary({
          body: {
            meta: {},
            data: {
              attributes: {
                user_info_update: {
                  birth_date: action.payload.summaryInfo.birth_date
                },
                fatca: {
                  selected_option:
                    action.payload.summaryInfo.fatca_selected_option
                },
                natural_person: {
                  landline_country_code:
                    action.payload.summaryInfo.landline_country_code,
                  landline_city_code:
                    action.payload.summaryInfo.landline_city_code,
                  landline: action.payload.summaryInfo.landline,
                  city: action.payload.summaryInfo.city,
                  address: action.payload.summaryInfo.address,
                  ciiu: action.payload.summaryInfo.ciiu,
                  birth_city: action.payload.summaryInfo.birth_city,
                  manage_public_resources:
                    action.payload.summaryInfo.manage_public_resources,
                  send_usa_resources:
                    action.payload.summaryInfo.send_usa_resources,
                  publicly_exposed_person:
                    action.payload.summaryInfo.publicly_exposed_person
                },
                user_purpose: {
                  purpose_id: action.payload.summaryInfo.porpuse_id
                },
                publicly_exposed_person: {
                  selected_option: {
                    public_figure_question_id:
                      action.payload.summaryInfo.public_figure_question_id
                  }
                },
                financial_info: {
                  equity: action.payload.summaryInfo.equity,
                  assets: action.payload.summaryInfo.assets,
                  monthly_income: action.payload.summaryInfo.monthly_income,
                  monthly_expenses: action.payload.summaryInfo.monthly_expenses,
                  profit: action.payload.summaryInfo.profit,
                  liabilities: action.payload.summaryInfo.passives
                },
                public_figure_family:
                  action.payload.summaryInfo.public_figure_family_member
              }
            }
          }
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(
              AnalyticsActions.trackEvent(AnalyticsModel.saveSummary),
              push("/enrollment/success", {
                name: `${action.payload.summaryInfo.name} ${action.payload.summaryInfo.last_name}`
              })
            ),
            Observable.of(UserActions.setUserState(User.state.finished))
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              ),
              Observable.of(confirmSummaryDataFail(e))
            );
          }
          return Observable.concat(
            Observable.of(confirmSummaryDataFail(e)),
            Observable.of(push("/oops"))
          );
        })
      )
    )
  );

export default combineEpics(initialSummaryData, saveSummaryData);
