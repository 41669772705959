import React from "react";
import { useTranslation } from "react-i18next";
import {
  Input as BasicInput,
  LinkError,
  AlertIco,
  InputWrapper,
  InputProps
} from "./components";
import AlertIcon from "assets/icons/triangle-alert.png";

export const Input = ({ error, name, ...props }: InputProps) => {
  const { t } = useTranslation();

  return (
    <InputWrapper>
      <BasicInput error={error} name={name} {...props} />
      {error && (
        <div data-testid={`alert-error-${name}`}>
          <AlertIco src={AlertIcon} />
          <LinkError>{t(error)}</LinkError>
        </div>
      )}
    </InputWrapper>
  );
};
