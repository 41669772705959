import styled from "styled-components";
import ReactTooltip from "react-tooltip";

export const HelpIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.67' height='16.67' viewBox='0 0 18 18' fill='none'%3E%3Crect width='16.6667' height='16.6667' rx='8.33333' transform='matrix(-1 0 0 1 17.4298 0.666668)' fill='%23475061'/%3E%3Cellipse cx='9.09642' cy='5.2963' rx='1.38889' ry='1.38889' fill='white'/%3E%3Crect x='7.70753' y='7.61112' width='2.77778' height='6.48148' rx='1.38889' fill='white'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    width: 25px;
    height: 25px;
    margin: 0px 0 -7px 0;
    display: inline-flex;
    cursor: pointer;
`;

export const StyledTooltips = styled(ReactTooltip)`
    background-color: #685e90 !important;
    border-radius: 8px;
    padding: 12px !important;
    width: 216px !important;
    height: auto !important;
    
    ${(props) => props.theme.smartphone} {
      left: 115px !important;
    }

    ${(props) => props.theme.min768Max990} {
      left: 519px !important
    }

    &::after {
      border-top-color: #685e90 !important;

      ${(props) => props.theme.smartphone} {
        left: 190px !important;
      }

      ${(props) => props.theme.min768Max990} {
        left: 160px !important
      }

    }
`;

export const TooltipText = styled.p`
  font-family: "Nunito";
  font-style: normal;
  text-align: center;
  width: 192px;
  height: auto;
  aling-items: center;
  display: flex;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 12px !important;
  white-space: pre-line;
  line-height: 16px;
  ${(props) => props.theme.smartphone} {
    font-size: 9px;
    width: 100%;
    font-weight: 600;
  }
`;