import styled from "styled-components";

export const Title = styled.h2`
  margin: 25px 70px 8px 70px;
  text-align: center;
  color: ${({ theme }) => theme.white};
  font-size: 24px;
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-weight: 900;

  ${({ theme }) => theme.smartphoneMedium} {
    font-size: 22px;
    margin: 0px 24px 8px 24px;
  }
`;
