// eslint-disable-next-line import/named
import { AnyAction } from "redux";

export const routerPreventMiddleware = (store: any) => (next: any) => (
  actionEvent: AnyAction = { type: "" }
) => {
  const { type } = actionEvent;
  const oopsAction = {
    type: "@@router/LOCATION_CHANGE",
    payload: {
      action: "PUSH",
      isFirstRendering: false,
      location: {
        hash: "",
        key: "",
        pathname: "/oops",
        search: "",
        state: undefined
      }
    }
  };
  if (
    type === "@@router/LOCATION_CHANGE" ||
    type === "@@router/CALL_HISTORY_METHOD"
  ) {
    const {
      internetAlert: { showInternetAlert }
    } = store.getState();

    if (showInternetAlert) {
      return next(oopsAction);
    }
    return next(actionEvent);
  }

  return next(actionEvent);
};
