import styled from "styled-components";

type Props = {
  percentage: number;
};

export const Progress = styled.div<Props>`
  width: ${({ percentage }) => `${percentage}%`};
  height: 100%;
  display: flex;
  border-bottom-right-radius: ${({ percentage }) =>
    percentage < 100 ? "12px" : 0};
  border-top-right-radius: ${({ percentage }) =>
    percentage < 100 ? "12px" : 0};
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.progressBar.color};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;
