import * as Yup from "yup";
import { emailValidate } from "../../lib/utils";

const schema = {
  email: Yup.string()
    .email("contactForm.validations.email.invalidFormat")
    .test("length", "contactForm.validations.email.required", (value) =>
      emailValidate(value)
    )
    .required("contactForm.validations.email.required")
};

export default Yup.object().shape(schema);
