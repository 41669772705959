import React, { useEffect, useState } from "react";
import InternetAlertActions from "redux/reducers/internet-alert.reducer";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { logoutCleanFlow } from "../../redux/epics/login";

export type PropsType = {
  children: React.ReactNode;
};

enum OnlineStates {
  Online = "online",
  Offline = "offline",
  Nothing = "nothing"
}

export const InternetAlertContainer = ({ children }: PropsType) => {
  const dispatch = useDispatch();

  const [isOnline, setIsOnline] = useState<OnlineStates>(OnlineStates.Nothing);
  const {
    internetAlert: { wasLogged }
  } = useSelector((store: any) => store);

  useEffect(() => {
    if (isOnline === OnlineStates.Offline) {
      const refreshToken = sessionStorage.getItem("refreshToken");
      const idToken = sessionStorage.getItem("idToken");
      const activeSession = !!(idToken && refreshToken);

      dispatch(InternetAlertActions.setInternetAlert(true, activeSession));
      dispatch(logoutCleanFlow());
    } else if (isOnline === OnlineStates.Online) {
      const redirectTo = wasLogged ? "/login" : "/";

      dispatch(InternetAlertActions.setInternetAlert(false));
      dispatch(push(redirectTo));
    }
  }, [isOnline]);

  window.addEventListener(OnlineStates.Offline, () => {
    if (isOnline !== OnlineStates.Offline) {
      setIsOnline(OnlineStates.Offline);
    }
  });

  window.addEventListener(OnlineStates.Online, () => {
    if (isOnline !== OnlineStates.Online) {
      setIsOnline(OnlineStates.Online);
    }
  });

  return <>{children}</>;
};
