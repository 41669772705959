import React from "react";
import { useTranslation } from "react-i18next";
import { goBack } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@bvcco/a2censo-component-lib";
import transactionImg from "../../assets/images/transaction-error.png";
import warningImg from "../../assets/images/warning.png";

import { warning } from "../../lib/utils/constants.errors";
import {
  Action,
  Container,
  Body,
  BackButton,
  Content,
  Image,
  GoBack,
  TitleMsg,
  Paragraph,
  ImgContainer,
  BackButtonImage,
  ActionsContainer
} from "./Styles";

const PaymentError = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleRetrun = () => {
    dispatch(goBack("/"));
  };

  const { error } = useSelector(({ paymentError }) => paymentError);

  const isWarning = error === warning;

  let description = "";
  if (isWarning || error === "PSE-CREATE_TRANSACTION-001") {
    description = `paymentError.errors.${error}`;
  } else {
    description = "paymentError.errors.generic";
  }
  return (
    <Container>
      <Body>
        <GoBack>
          <BackButton id="error-payment-go-back" onClick={handleRetrun}>
            <BackButtonImage alt="previous" src="/assets/images/previous.png" />
          </BackButton>
        </GoBack>
        <Content>
          <ImgContainer>
            <Image src={isWarning ? warningImg : transactionImg} />
          </ImgContainer>
          {isWarning ? (
            <>
              <TitleMsg>{t("paymentError.warningTitle1")}</TitleMsg>
              <TitleMsg>{t("paymentError.warningTitle2")}</TitleMsg>
            </>
          ) : (
            <>
              <TitleMsg>{t("paymentError.title1")}</TitleMsg>
              <TitleMsg>{t("paymentError.title2")}</TitleMsg>
            </>
          )}
          <Paragraph>{t(description)}</Paragraph>
          <ActionsContainer>
            <Action>
              <Button
                onClick={handleRetrun}
                rounded={true}
                fontWeight={800}
                outlined={true}
                color="primary2"
              >
                {t("paymentError.goBack")}
              </Button>
            </Action>
          </ActionsContainer>
        </Content>
      </Body>
    </Container>
  );
};

export default PaymentError;
