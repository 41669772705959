import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import {
  Container,
  IconTitle,
  IconTitleContainer,
  Title,
  Paragraph,
  ButtonContainer,
  Button
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";

const ContactFormSuccess = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const goToExplore = () => {
    dispatch(push("/"));
    dispatch(ModalActions.setConditionalModalState(false));
  };
  return (
    <Container>
      <IconTitleContainer>
        <IconTitle alt="check-round" src="/assets/images/check_round.png" />
      </IconTitleContainer>
      <Title>{t("questions.thanksTitle")}</Title>
      <Paragraph>
        <span>{t("questions.thanksText1")}</span>
      </Paragraph>
      <ButtonContainer>
        <Button onClick={goToExplore} id="go-explore">
          {t("questions.thanksExplore")}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default ContactFormSuccess;
