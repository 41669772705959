import styled from "styled-components";
import { Paragraph } from "@bvcco/a2censo-component-lib";
import { types } from "../types";

type Props = {
  type: types;
};

export const MidContent = styled.div<Props>`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-self: flex-start;
  z-index: 1;
  width: 286px;
  margin-bottom: 8px;
  & h3 {
    font-size: 18px;
    line-height: 25px;
  }
  ${({ theme }) => theme.smartphone} {
    width: 260px;
    & h3 {
      font-size: 16px;
      line-height: 26px;
    }
  }
`;

export const MidDescription = styled(Paragraph)`
  line-height: 20px;
  margin: 6px 0;
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
  opacity: 0;
`;
