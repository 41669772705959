import React, { useState } from "react";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import moment from "moment";
import Input from "../Input/Input";
import { numberToMoney, moneyToNumber } from "../../lib/formatter";
import PaymentsActions from "../../redux/reducers/payments.reducer";
import IssuerPaymentModel from "../../lib/models/issuerPayment.model";
import {
  Container,
  Item,
  TextItem,
  AmountItem,
  SubCaptionText,
  CaptionText,
  SubCaptionAmount,
  CaptionAmount,
  RadioButton,
  InputContainer,
  RadioContainer,
  Date,
  SpanDate
} from "./Styles";

const Payment = ({
  displayInput,
  available,
  selectedOption,
  amountValue,
  minimumAmountValue,
  dateLimit,
  issuerPaymentState,
  amountRequestedValue,
  daysToPay
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [amountInvalid, setAmountInvalid] = useState(false);
  const initialData = {
    amount: numberToMoney(amountValue),
    date: dateLimit,
    amountRequested: numberToMoney(amountRequestedValue)
  };
  const formatterAmount = (val) => {
    const amount = moneyToNumber(val);
    if (amount > 0) {
      return numberToMoney(amount);
    }
    return "";
  };
  const handleChangeAmount = (val) => {
    const amount = moneyToNumber(val);
    // eslint-disable-next-line no-restricted-globals
    if (amount <= minimumAmountValue || isNaN(amount)) {
      dispatch(PaymentsActions.setOtherAmount(0));
      return setAmountInvalid(true);
    }

    dispatch(PaymentsActions.setOtherAmount(amount));
    return setAmountInvalid(false);
  };

  return (
    <Formik initialValues={initialData}>
      {({ values, setFieldValue, setFieldError, errors }) => (
        <Container>
          {!displayInput && (
            <Date>
              {t("payment.date")}
              <SpanDate>
                {daysToPay === -1
                  ? moment().format("MMMM D [de] YYYY")
                  : moment(values.date).format("MMMM D [de] YYYY")}
              </SpanDate>
            </Date>
          )}
          {!displayInput ? (
            <Item
              data-testid="minimum-amount-option"
              id="minimum-amount-option"
              enableItem={available}
            >
              <TextItem>
                <SubCaptionText>{t("payment.minimumAmount")}</SubCaptionText>
                <CaptionText data-testid="minimum-amount-text">
                  {t("payment.minimumAmountText")}
                </CaptionText>
              </TextItem>
              <AmountItem>
                <SubCaptionAmount>
                  {t("payment.paymentValueText")}
                </SubCaptionAmount>
                <CaptionAmount
                  valueLength={values.amount.length}
                  data-testid="minimum-amount-value"
                >
                  {values.amount}
                </CaptionAmount>
              </AmountItem>
              <RadioContainer>
                <RadioButton checked={selectedOption} />
              </RadioContainer>
            </Item>
          ) : (
            <Item
              data-testid="other-amount-option"
              id="other-amount-option"
              enableItem={
                issuerPaymentState ===
                IssuerPaymentModel.state.pending_new_amount_request
                  ? true
                  : available
              }
            >
              <TextItem>
                <SubCaptionText>{t("payment.otherAmount")}</SubCaptionText>
                <CaptionText data-testid="other-amount-text">
                  {t("payment.otherAmountText")}
                </CaptionText>
              </TextItem>
              <AmountItem>
                <SubCaptionAmount>
                  {t("payment.paymentValueText")}
                </SubCaptionAmount>
                {issuerPaymentState ===
                IssuerPaymentModel.state.pending_new_amount_request ? (
                  <CaptionAmount
                    valueLength={values.amountRequested.length}
                    data-testid="other-amount-value"
                  >
                    {values.amountRequested}
                  </CaptionAmount>
                ) : (
                  <InputContainer>
                    <Input
                      extraClassname="campaigns"
                      type="text"
                      name="amount"
                      id="input-amount"
                      data-testid="input-amount"
                      onChange={(val) => {
                        handleChangeAmount(val.target.value);
                        setFieldValue(
                          "amount",
                          formatterAmount(val.target.value)
                        );
                      }}
                      onBlur={() => {
                        if (amountInvalid) {
                          setFieldError(
                            "amount",
                            "myPayments.validations.amount.invalid"
                          );
                        }
                      }}
                      value={`${values.amount}`}
                      error={errors.amount}
                    />
                  </InputContainer>
                )}
              </AmountItem>
              <RadioContainer>
                <RadioButton checked={selectedOption} />
              </RadioContainer>
            </Item>
          )}
        </Container>
      )}
    </Formik>
  );
};

Payment.propTypes = {
  displayInput: propTypes.bool.isRequired,
  available: propTypes.bool.isRequired,
  selectedOption: propTypes.number.isRequired,
  amountValue: propTypes.number.isRequired,
  minimumAmountValue: propTypes.number.isRequired,
  dateLimit: propTypes.string.isRequired,
  issuerPaymentState: propTypes.string.isRequired,
  amountRequestedValue: propTypes.number.isRequired,
  daysToPay: propTypes.number.isRequired
};

export default Payment;
