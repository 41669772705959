import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setDeepLink: ["deepLink"]
});

export const AccountRecoveryTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  deepLink: "",
  isLogoutFlow: false
};

const setDeepLink = (state, { deepLink }) => ({ ...state, deepLink });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_DEEP_LINK]: setDeepLink
});
