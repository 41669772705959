import { map, flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import LoginActions from "../reducers/login.reducer";
import ModalActions from "../reducers/modal.reducer";

export const sendQualifiedInvestor = (stateVal, tokenVal) => ({
  type: "SAVE_QUALIFIED_INVESTOR_DATA",
  payload: {
    state: stateVal,
    token: tokenVal
  }
});

export const qualifiedInvestorFail = ({ error }) => ({
  type: "QUALIFIED_INVESTOR_FAIL",
  payload: {
    error
  }
});

export const sendQualifiedInvestorData = (action$) =>
  action$.pipe(
    ofType("SAVE_QUALIFIED_INVESTOR_DATA"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.getQualifiedInvestor({
          url: { state: action.payload.state, token: action.payload.token }
        })
      ).pipe(
        map(() =>
          push("/qualified-investor", {
            disableButton: false
          })
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(
            Observable.of(qualifiedInvestorFail(e)),
            Observable.of(
              ModalActions.setConditionalModalState(true, "ErrorModal", {
                title: "errorModal.title",
                content: "errorCodes.046",
                linkRedirectsTo: "/",
                buttonText: "errorModal.success"
              })
            )
          );
        })
      )
    )
  );

export default sendQualifiedInvestorData;
