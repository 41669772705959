import styled from "styled-components";

export const LinkError = styled.div`
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  text-align: right;
  width: 100%;
  margin-top: 8px;
  color: ${({ theme: { violetRed } }) => violetRed};
`;
