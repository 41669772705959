import styled from "styled-components";

export const TitleCompany = styled.div`
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #5d5a6a;
`;
