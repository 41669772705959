import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  validateCode: ["userId", "token", "code"],
  requestNewCode: ["userId", "token"],
  codeSuccess: ["success"],
  codeError: ["error"],
  validateToken: ["userId", "token"]
});

export const ValidateCodeTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isFetching: false,
  error: "",
  token: ""
};

const validateCode = (state) => ({ ...state, isFetching: true, error: "" });
const requestNewCode = (state) => ({ ...state, isFetching: true, error: "" });
const codeSuccess = (state, { success }) => ({
  ...state,
  success,
  isFetching: false,
  error: ""
});
const codeError = (state, { error }) => ({
  ...state,
  error,
  isFetching: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.VALIDATE_CODE]: validateCode,
  [Types.REQUEST_NEW_CODE]: requestNewCode,
  [Types.CODE_SUCCESS]: codeSuccess,
  [Types.CODE_ERROR]: codeError
});
