import styled from "styled-components";

export const CIIUDescription = styled.div`
  font-size: 14px;
  line-height: 130.69%;
  margin-bottom: 13px;
  color: ${({ theme }) => theme.stormGray};

  ul {
    margin-left: -10px;
  }

  ul:first-child {
    margin-left: 28px;
  }
`;

export const CIIU = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;
