import User from "lib/models/user.model";
import { catchError, mergeMap } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType, ActionsObservable } from "redux-observable";
import { Observable } from "rxjs-compat";
// eslint-disable-next-line import/named
import { AnyAction } from "redux";
import App from "../../lib/app";
import UserCertificates, {
  UserCertificatesTypes,
  TypeCertificate
} from "../reducers/user-certificates.reducer";
import { base64ToDownloadFile, openFile } from "../../lib/utils/downloadFile";
import { bufferToBase64 } from "../../lib/utils/buffer-to-base64";
import AlertActions from "../reducers/alert.reducer";
import MoldalActions from "../reducers/modal.reducer";
import { emailObfuscate } from "lib/utils/formats";
import theme from "styles/LightTheme";
import AppParams from "redux/reducers/app-params.reducer";
import { PdfGeneratorConnect } from "services/pdf-generator";
import { SendPdfEmailConnect } from "services/send-pdf-email";
import { flatMap } from "lodash";

const RETENTION_FILE_NAME = "Certificado de retención en la fuente.pdf";
const REPORT_ANUAL_PYME_FILE_NAME = "Reporte Final de Inversionsitas";
const EXTENTION_XLS = ".xlsx";
const TAX_FINANCIAL_FILE_NAME =
  "Certificado de retención a título de gravamen a los movimientos financieros y de saldos.pdf";

enum OnDownloadActions {
  OnDownloadCertificate = "ON_DOWNLOAD_CERTIFICATE",
  OnDownloadCertificateTax = "ON_DOWNLOAD_CERTIFICATE_TAX_FINANCIAL",
  FinishDownloadCertificate = "FINISH_DOWNLOAD_CERTIFICATE",
  FinishDownloadCertificateTax = "FINISH_DOWNLOAD_CERTIFICATE_TAX_FINANCIAL"
}

enum OnRequestSendCertificates {
  OnRequestSendMassiveCertificates = "ON_REQUEST_SEND_MASSIVE_CERTIFICATES"
}

const OnRequestSendSummaryCertificates = "ON_REQUEST_SEND_SUMMARY_CERTIFICATES";
const OnRequestDownloadSummaryCertificates = "ON_REQUEST_DOWNLOAD_SUMMARY_CERTIFICATES";

export enum CertificatesTypes {
  WithholdingTaxPyme = "withholding_tax_pyme",
  WithholdingTaxInvestor = "withholding_tax_investor",
  AnualInvestorsCertficate = "anual_report_investor"
}

type DownloadCertificateAction = {
  type: OnDownloadActions;
  payload: {
    certificateId?: string | number;
    certificateYear?: string | number;
  };
};

type OnRequestSendMassiveCertificatesAction = {
  type: OnRequestSendCertificates;
  payload: {
    certificateType: CertificatesTypes;
    certificateYear: string | number;
  };
};

export const onDownloadCertificate = (
  certificateId: string | number
): DownloadCertificateAction => ({
  type: OnDownloadActions.OnDownloadCertificate,
  payload: {
    certificateId
  }
});

export const onDownloadCertificateTaxFinancial = (
  certificateYear: string | number
): DownloadCertificateAction => ({
  type: OnDownloadActions.OnDownloadCertificateTax,
  payload: {
    certificateYear
  }
});

export const finishDownloadCertificate = (): AnyAction => ({
  type: OnDownloadActions.FinishDownloadCertificate
});

export const finishDownloadCertificateTaxFinancial = (): AnyAction => ({
  type: OnDownloadActions.FinishDownloadCertificateTax
});

export const onRequestSendMassiveCertificates = (
  certificateType: CertificatesTypes,
  certificateYear: string | number
): OnRequestSendMassiveCertificatesAction => ({
  type: OnRequestSendCertificates.OnRequestSendMassiveCertificates,
  payload: {
    certificateType,
    certificateYear
  }
});

export const onRequestSendSummaryCertificates = (
  userId: number,
  year: string | number,
  typePdf: string | number,
  userIp: string
) => ({
  type: OnRequestSendSummaryCertificates,
  payload: {
    userId,
    year,
    typePdf,
    userIp
  }
});

export const onRequestDownloadSummaryCertificates = (
  userId: number,
  year: string | number,
  typePdf: string | number
) => ({
  type: OnRequestDownloadSummaryCertificates,
  payload: {
    userId,
    year,
    typePdf
  }
});

export const fetchAvailables = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    ofType(UserCertificatesTypes.FETCH_AVAILABLES),
    mergeMap(() => {
      const {
        api: {
          a2censo: { getAvailableCerts }
        }
      }: any = App;
      return Observable.from(getAvailableCerts()).pipe(
        mergeMap((response) =>
          Observable.of(UserCertificates.setAvailables(response))
        ),
        catchError(() => Observable.of(push("/oops")))
      );
    })
  );

export const fetchCertificatesByType = (
  action$: ActionsObservable<AnyAction>
) =>
  action$.pipe(
    ofType(UserCertificatesTypes.FETCH_CERTIFICATES),
    mergeMap(({ payload }: any) => {
      const { api }: any = App;
      return Observable.from(
        api.a2censo.getCertificates({
          url: payload
        })
      ).pipe(
        mergeMap((response) =>
          Observable.of(
            TypeCertificate.ReportAnual === payload.type
              ? UserCertificates.setCertificatesReport(response)
              : UserCertificates.setCertificates(response, payload.isInvestor)
          )
        ),
        catchError(() => Observable.of(push("/oops")))
      );
    })
  );

const ErrorCertificates = ({ code }: any) =>
  Observable.concat(
    Observable.of(
      AlertActions.setAlertState(
        "error",
        code === "1029" ? "downloadCertificates.errors.1029" : "alert.tryAgain",
        {
          icon: true,
          hasClose: false
        }
      )
    ),
    Observable.of(
      MoldalActions.setConditionalModalState(false, "ModalLoadingCertificate")
    )
  );

export const fetchDownloadSummaryCertificate = (action$: any) =>
  action$.pipe(
    ofType(OnRequestDownloadSummaryCertificates),
    mergeMap((action: any) =>
      Observable.from(
        new PdfGeneratorConnect().getSignedUrl(action.payload)
      ).pipe(
        mergeMap((response: any) => {
          openFile(response.data.attributes.signedUrl, false);
          return Observable.concat(
            Observable.of(finishDownloadCertificate()),
            Observable.of(
              MoldalActions.setConditionalModalState(
                false,
                "ModalLoadingCertificate"
              )
            ),
            Observable.of(
              MoldalActions.setConditionalModalState(
                true,
                "ModalPasswordCertificate",
                {
                  clearBlur: true,
                  css: {
                    backgroundColor: theme.transparentSantas
                  }
                }
              )
            )
          );
        }),
        catchError(ErrorCertificates)
      ))
  );

export const fetchSendSummaryCertificate = (action$: any, state$: any) =>
  action$.pipe(
    ofType(OnRequestSendSummaryCertificates),
    mergeMap((action: any) => Observable.from(
        new PdfGeneratorConnect().getSignedUrl(action.payload)
      ).pipe(
        mergeMap((response: any) => {
          const {
            value: {
              user: {
                userInfo: {
                  user: { email }
                }
              }
            }
          }: any = state$;
          const dataEmail = {
            userId: action.payload.userId,
            fileName: response.data.attributes.fileName,
            pdfType: action.payload.typePdf.toString(),
            year: action.payload.year.toString(),
            userIp: action.payload.userIp
          };
          return Observable.from(
            new SendPdfEmailConnect().sendEmailSummary(dataEmail)
          ).pipe(
            mergeMap((responseEmail: any) =>
               Observable.concat(
                Observable.of(finishDownloadCertificate()),
                Observable.of(
                  MoldalActions.setConditionalModalState(
                    false,
                    "ModalLoadingCertificate"
                  )
                ),
                Observable.of(
                  AlertActions.setAlertState(
                    "success",
                    "myCertificates.successSendEmail",
                    {
                      icon: true,
                      hasClose: false
                    },
                    emailObfuscate(email)
                  )
                )
              )
            )
          );
        }),
        catchError(ErrorCertificates)
      )
    )
  );
export const fetchDownloadCertificate = (
  action$: ActionsObservable<AnyAction>
) =>
  action$.pipe(
    ofType(OnDownloadActions.OnDownloadCertificate),
    mergeMap(({ certificateId }) => {
      const {
        api: {
          a2censo: { downloadCertificate }
        }
      }: any = App;
      return Observable.from(
        downloadCertificate({ url: { certificateId } })
      ).pipe(
        mergeMap(({ file: { data } }: any) => {
          const base64 = bufferToBase64(data);
          base64ToDownloadFile(RETENTION_FILE_NAME, base64);
          return Observable.concat(
            Observable.of(finishDownloadCertificate()),
            Observable.of(
              MoldalActions.setConditionalModalState(
                false,
                "ModalLoadingCertificate"
              )
            ),
            Observable.of(
              MoldalActions.setConditionalModalState(
                true,
                "ModalPasswordCertificate",
                {
                  clearBlur: true,
                  css: {
                    backgroundColor: theme.transparentSantas
                  }
                }
              )
            )
          );
        }),
        catchError(ErrorCertificates)
      );
    })
  );

export const fetchDownloadCertificateTaxFinancial = (
  action$: ActionsObservable<DownloadCertificateAction>
) =>
  action$.pipe(
    ofType(OnDownloadActions.OnDownloadCertificateTax),
    mergeMap(({ payload: { certificateYear } }) => {
      const {
        api: {
          a2censo: { downloadCertificateTaxFinancial }
        }
      }: any = App;
      return Observable.from(
        downloadCertificateTaxFinancial({ url: { certificateYear } })
      ).pipe(
        mergeMap(({ file: { data } }: any) => {
          const base64 = bufferToBase64(data);
          base64ToDownloadFile(TAX_FINANCIAL_FILE_NAME, base64);
          return Observable.concat(
            Observable.of(finishDownloadCertificateTaxFinancial()),
            Observable.of(
              MoldalActions.setConditionalModalState(
                false,
                "ModalLoadingCertificate"
              )
            ),
            Observable.of(
              MoldalActions.setConditionalModalState(
                true,
                "ModalPasswordCertificate",
                {
                  clearBlur: true,
                  css: {
                    backgroundColor: theme.transparentSantas
                  }
                }
              )
            )
          );
        }),
        catchError(ErrorCertificates)
      );
    })
  );

export const onDownloadCertificateByToken = (action$: any) =>
  action$.pipe(
    ofType(UserCertificatesTypes.ON_DOWNLOAD_CERTIFICATES_BY_TOKEN),
    mergeMap(({ token }: any) => {
      const {
        api: {
          a2censo: { downloadCertificateByToken }
        }
      }: any = App;
      const openInNewWindow = false;
      return Observable.from(
        downloadCertificateByToken({ url: { token } })
      ).pipe(
        mergeMap(({ file }: any) => {
          openFile(file, openInNewWindow);
          return Observable.concat(Observable.of(AppParams.setDeepLink("")));
        }),
        catchError((error) =>
          Observable.of(
            UserCertificates.setDownloadError(error?.errors[0]?.code)
          )
        )
      );
    })
  );

export const fetchDownloadReportAnualPYME = (
  action$: ActionsObservable<AnyAction>
) =>
  action$.pipe(
    ofType(UserCertificatesTypes.ON_DOWNLOAD_REPORT_PYME),
    mergeMap(({ certificateId, campaignName }) => {
      const {
        api: {
          a2censo: { downloadReportAnualPyme }
        }
      }: any = App;
      return Observable.from(
        downloadReportAnualPyme({ url: { certificateId } })
      ).pipe(
        mergeMap(({ file: { data } }: any) => {
          const base64 = bufferToBase64(data);
          const fileName = `${REPORT_ANUAL_PYME_FILE_NAME} - ${campaignName}${EXTENTION_XLS}`;
          base64ToDownloadFile(fileName, base64);
          return Observable.concat(
            Observable.of(
              AlertActions.setAlertState(
                "success",
                "myCertificates.downloadStart",
                {
                  icon: true,
                  hasClose: false
                }
              )
            ),
            Observable.of(
              MoldalActions.setConditionalModalState(
                false,
                "ModalLoadingCertificate"
              )
            )
          );
        }),
        catchError(ErrorCertificates)
      );
    })
  );

export const sendEmailFinancialTax = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UserCertificatesTypes.SEND_EMAIL_FINANCIAL_TAX),
    mergeMap(({ year }: any) => {
      const { api }: any = App;
      const {
        value: {
          user: {
            userInfo: {
              user: { email }
            }
          }
        }
      }: any = state$;
      return Observable.from(
        api.a2censo.sendEmailFinancialTax({
          url: { year }
        })
      ).pipe(
        mergeMap(() =>
          Observable.concat(
            Observable.of(
              AlertActions.setAlertState(
                "success",
                "myCertificates.successSendEmail",
                {
                  icon: true,
                  hasClose: false
                },
                emailObfuscate(email)
              )
            ),
            Observable.of(
              MoldalActions.setConditionalModalState(
                false,
                "ModalLoadingCertificate"
              )
            )
          )
        ),
        catchError(ErrorCertificates)
      );
    })
  );
export const onFetchRequestSendMassiveCertificates = (
  action$: ActionsObservable<OnRequestSendMassiveCertificatesAction>,
  state$: any
) =>
  action$.pipe(
    ofType(OnRequestSendCertificates.OnRequestSendMassiveCertificates),
    mergeMap(({ payload: { certificateType, certificateYear } }) => {
      const {
        api: {
          a2censo: { requestSendMassiveCertificates }
        }
      }: any = App;
      const {
        value: {
          user: {
            userInfo: {
              user: { email }
            }
          }
        }
      }: any = state$;
      return Observable.from(
        requestSendMassiveCertificates({
          body: {
            data: {
              attributes: {
                type: certificateType,
                year: certificateYear
              }
            }
          }
        })
      )
        .delay(3000)
        .pipe(
          mergeMap(() =>
            Observable.concat(
              Observable.of(
                MoldalActions.setConditionalModalState(
                  false,
                  "ModalLoadingCertificate"
                )
              ),
              Observable.of(
                AlertActions.setAlertState(
                  "success",
                  "myCertificates.successSendMassiveMail",
                  {
                    icon: true,
                    hasClose: false
                  },
                  emailObfuscate(email)
                )
              )
            )
          ),
          catchError(ErrorCertificates)
        );
    })
  );

export const onSendEmailReportSingle = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UserCertificatesTypes.ON_SEND_EMAIL_REPORT),
    mergeMap(({ certificateId, year }) => {
      const {
        api: {
          a2censo: { requestSendMassiveCertificates }
        }
      }: any = App;
      const {
        value: {
          user: {
            userInfo: {
              user: { email }
            }
          }
        }
      }: any = state$;
      return Observable.from(
        requestSendMassiveCertificates({
          body: {
            data: {
              attributes: {
                type: CertificatesTypes.AnualInvestorsCertficate,
                year: year,
                report_uuid: certificateId
              }
            }
          }
        })
      ).pipe(
        mergeMap(() =>
          Observable.concat(
            Observable.of(
              MoldalActions.setConditionalModalState(
                false,
                "ModalLoadingCertificate"
              )
            ),
            Observable.of(
              AlertActions.setAlertState(
                "success",
                "myCertificates.successSendEmail",
                {
                  icon: true,
                  hasClose: false
                },
                emailObfuscate(email)
              )
            )
          )
        ),
        catchError(ErrorCertificates)
      );
    })
  );

export const onSendEmailRetentionSingleInvestor = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UserCertificatesTypes.ON_SEND_EMAIL_RETENTION_SINGLE),
    mergeMap(({ campaignCompanyId, year, campaignId }) => {
      const {
        api: {
          a2censo: { requestSendMassiveCertificates }
        }
      }: any = App;
      const {
        value: {
          user: {
            userInfo: {
              user: { email, person_type: personType }
            }
          }
        }
      }: any = state$;

      const type =
        personType === User.type.pyme
          ? CertificatesTypes.WithholdingTaxPyme
          : CertificatesTypes.WithholdingTaxInvestor;

      return Observable.from(
        requestSendMassiveCertificates({
          body: {
            data: {
              attributes: {
                type,
                year,
                campaign_company_id: campaignCompanyId,
                campaign_id: campaignId
              }
            }
          }
        })
      ).pipe(
        mergeMap(() =>
          Observable.concat(
            Observable.of(
              MoldalActions.setConditionalModalState(
                false,
                "ModalLoadingCertificate"
              )
            ),
            Observable.of(
              AlertActions.setAlertState(
                "success",
                "myCertificates.successSendEmail",
                {
                  icon: true,
                  hasClose: false
                },
                emailObfuscate(email)
              )
            )
          )
        ),
        catchError(ErrorCertificates)
      );
    })
  );

export default combineEpics(
  fetchAvailables,
  fetchCertificatesByType,
  fetchDownloadCertificate,
  fetchDownloadCertificateTaxFinancial,
  onDownloadCertificateByToken,
  fetchDownloadReportAnualPYME,
  onSendEmailReportSingle,
  sendEmailFinancialTax,
  onFetchRequestSendMassiveCertificates,
  onSendEmailRetentionSingleInvestor,
  fetchDownloadSummaryCertificate,
  fetchSendSummaryCertificate
);
