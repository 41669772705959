import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Creators } from "../../redux/reducers/conditions.reducer";
import ModalActions from "../../redux/reducers/modal.reducer";
import { startSaveTerms } from "../../redux/epics/politics-terms-hc";
import {
  Container,
  CloseButton,
  CloseImage,
  TitleContainer,
  TitleSubContainer,
  Title,
  Scroll,
  Paragraph,
  ButtonContainer,
  Button
} from "./Styles";
import User from "../../lib/models/user.model";
import CloseButtonPymeIcon from "../../assets/images/register/close-button-pyme.png";

const Terms = ({ termsObject, requestFrom, personType }) => {
  const [bottomReached, setBottomReached] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleScroll = (event) => {
    const node = event.target;
    const bottom = node.scrollHeight - node.scrollTop <= node.clientHeight + 20;
    if (bottom) {
      setBottomReached(true);
    }
  };
  const setListener = (node) => {
    if (node) {
      node.addEventListener("scroll", handleScroll);
    }
  };

  const acceptOnClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    if (requestFrom === "register") {
      dispatch(Creators.setTerms({ termsAccepted: bottomReached }));
    } else {
      dispatch(startSaveTerms(termsObject.id, termsObject.version));
    }
  };

  const close = () => {
    dispatch(ModalActions.setConditionalModalState(false));
  };

  const isPyme = personType === User.type.pyme;
  const closeButtonIcon = isPyme
    ? CloseButtonPymeIcon
    : "/assets/images/close_2.png";

  return (
    <Container>
      {requestFrom === "register" && (
        <CloseButton
          id="close-button"
          onClick={close}
          data-testid="terms-close-btn"
        >
          <CloseImage alt="close" src={closeButtonIcon} />
        </CloseButton>
      )}
      <TitleContainer>
        <TitleSubContainer>
          {requestFrom === "register" ? (
            <Title personType={personType}>{t("terms.title")}</Title>
          ) : (
            <Title personType={personType}>{t("terms.title2")}</Title>
          )}
          <Scroll
            data-testid="terms-modal-content"
            id="terms-modal-content"
            ref={(ref) => {
              setListener(ref);
              if (ref) {
                const bottom =
                  ref.scrollHeight - ref.scrollTop <= ref.clientHeight + 20;
                if (bottom) {
                  setBottomReached(true);
                }
              }
            }}
          >
            <Paragraph>{termsObject.text}</Paragraph>
          </Scroll>
        </TitleSubContainer>
        <ButtonContainer personType={personType}>
          <Button
            id="continue-button"
            data-testid="terms-accept-btn"
            disabled={!bottomReached}
            onClick={acceptOnClick}
            personType={personType}
          >
            {t("terms.acceptButton")}
          </Button>
        </ButtonContainer>
      </TitleContainer>
    </Container>
  );
};

Terms.propTypes = {
  termsObject: PropTypes.shape({
    id: PropTypes.number,
    version: PropTypes.string,
    url: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  requestFrom: PropTypes.string.isRequired,
  personType: PropTypes.string.isRequired
};

export default Terms;
