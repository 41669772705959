/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import User from "../../lib/models/user.model";
import App from "../../lib/app";
import { startLogoutFlow } from "../../redux/epics/login";

const UserTimer = ({ children }) => {
  const timeout = Number(App.parameters?.timeout_session.value);

  const dispatch = useDispatch();

  useEffect(() => {
    const events = ["click", "keypress"];
    events.forEach((event) => {
      window.addEventListener(event, () => {
        const dateSessionTimeout = new Date();
        dateSessionTimeout.setMilliseconds(
          dateSessionTimeout.getMilliseconds() + Number(timeout)
        );
        sessionStorage.setItem("session_timeout", dateSessionTimeout);
      });
    });
  }, []);

  useEffect(() => {
    const intervalTimeoutSession = setInterval(() => {
      if (!sessionStorage.getItem("session_timeout") && !User.activeSession()) {
        return false;
      }

      if (!sessionStorage.getItem("session_timeout") && User.activeSession()) {
        dispatch(startLogoutFlow());
        return false;
      }

      const sessionTimeout = new Date(
        sessionStorage.getItem("session_timeout")
      ).getTime();
      const currentDate = new Date().getTime();

      if (User.activeSession() && currentDate >= sessionTimeout) {
        dispatch(startLogoutFlow());
      }
    }, 1000);

    return () => {
      clearInterval(intervalTimeoutSession);
    };
  }, []);

  return <div>{children}</div>;
};

UserTimer.propTypes = {
  children: PropTypes.node.isRequired
};

export default UserTimer;
