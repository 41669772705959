import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Heading, Paragraph, Button } from "@bvcco/a2censo-component-lib";
import { Container, CloseButton, Icon } from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";
import { startLogoutFlow } from "../../redux/epics/login";

const SelfAppraisalModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { name } = useSelector((state) => state.user.userInfo.user);

  const handleClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(startLogoutFlow());
  };

  return (
    <Container>
      <CloseButton id="close-button" onClick={handleClick}>
        <Icon alt="previous" src="/assets/images/close_2.png" />
      </CloseButton>
      <Heading align="center">{t("selfAppraisalModal.title")}</Heading>
      <Paragraph align="center">
        {t("selfAppraisalModal.description1")}
        <strong>{name}</strong>
        {t("selfAppraisalModal.description2")}
      </Paragraph>

      <Button
        id="return-button"
        dataTestid="return-button"
        onClick={handleClick}
        fontWeight="bold"
        rounded={true}
      >
        {t("selfAppraisalModal.back")}
      </Button>
    </Container>
  );
};

export default SelfAppraisalModal;
