import API from "../api.js";

export default async function loadApis(app) {
  const { apis } = app.config;
  const api = [];

  Object.keys(apis).forEach((key) => {
    api[key] = new API(apis[key]);
  });
  return Promise.resolve(api);
}
