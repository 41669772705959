import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation, getI18n } from "react-i18next";
import DownloadFile from "../DownloadFile/DownloadFile";
import { formateToPlusOrLessValue, numberToMoney } from "../../lib/formatter";

import {
  Container,
  DateContent,
  CardContainer,
  HeaderContainer,
  HeaderDueContent,
  HeaderStateContent,
  PaymentContent,
  PaymentAmountContainer,
  OtherPaymentsContainer,
  FooterContainer,
  ParagraphDue,
  ParagraphState,
  HeadingContent,
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  PaymentRow,
  PaymentRowString,
  PaymentRowNumber,
  ReferenceContent,
  ReferenceString,
  ReferenceNumber
} from "./Styles.js";

const PaymentHistoryCard = ({ dueNumber, payment }) => {
  const { t } = useTranslation();
  const {
    payment_expiration_date: paymentExpirationDate,
    equity_rights_url: fileUrl,
    state,
    total,
    num_ref: numRef,
    details
  } = payment;

  const localLocale = moment(paymentExpirationDate);
  localLocale.locale(getI18n().language);
  return (
    <Container>
      <DateContent>
        {`${t("paymentHistoryCard.date")} ${moment(localLocale).format(
          "MMMM D [de] YYYY"
        )}`}
      </DateContent>
      <CardContainer>
        <HeaderContainer>
          <HeaderDueContent>
            <ParagraphDue>
              {`${t("paymentHistoryCard.due")}  ${dueNumber}`}
            </ParagraphDue>
          </HeaderDueContent>
          <HeaderStateContent>
            <ParagraphState state={state}>
              {t(`paymentHistoryCard.${state}`)}
            </ParagraphState>
          </HeaderStateContent>
        </HeaderContainer>
        <PaymentContent>
          <PaymentAmountContainer>
            <HeadingOne>{t("paymentHistoryCard.minimumAmount")}</HeadingOne>
            <HeadingContent>
              <HeadingTwo>{t("paymentHistoryCard.paidTotalAmount")}</HeadingTwo>
              <HeadingThree>{numberToMoney(total)}</HeadingThree>
            </HeadingContent>
          </PaymentAmountContainer>
          <OtherPaymentsContainer>
            {details.map((item) => (
              <PaymentRow>
                <PaymentRowString>
                  {t(`paymentHistoryCard.${item.type_id}`)}
                  {item.type_id === "bvc_commission" && item.num_ref && (
                    <span>
                      {`${t("paymentHistoryCard.sharpReferenceNumber")} ${
                        item.num_ref
                      }`}
                    </span>
                  )}
                </PaymentRowString>
                <PaymentRowNumber
                  className={
                    item.type_id === "less_retentions" ? "bright-turquoise" : ""
                  }
                >
                  {formateToPlusOrLessValue(item.amount)}
                </PaymentRowNumber>
              </PaymentRow>
            ))}
          </OtherPaymentsContainer>
        </PaymentContent>
        <FooterContainer>
          {fileUrl && (
            <DownloadFile
              fileName="Derechos patrimoniales"
              fileUrl={fileUrl}
              className="download-file-custom"
            />
          )}
          <ReferenceContent>
            <ReferenceString>
              {t("paymentHistoryCard.stringReferenceNumber")}
            </ReferenceString>
            <ReferenceNumber>{numRef}</ReferenceNumber>
          </ReferenceContent>
        </FooterContainer>
      </CardContainer>
    </Container>
  );
};

PaymentHistoryCard.propTypes = {
  dueNumber: PropTypes.string.isRequired,
  payment: PropTypes.shape({
    id: PropTypes.number,
    payment_expiration_date: PropTypes.string,
    num_ref: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    patrimonial_batch_id: PropTypes.number,
    debt_id: PropTypes.number,
    state: PropTypes.string,
    equity_rights_url: PropTypes.string,
    total_rows: PropTypes.number,
    details: PropTypes.shape([]),
    total: PropTypes.number
  }).isRequired
};

export default PaymentHistoryCard;
