import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import PropTypes from "prop-types";

import {
  Container,
  AccordionItem,
  Checkbox,
  Label,
  Content,
  Title
} from "./Styles";

const Accordion = ({ items }) => {
  const [active, setActive] = useState((items[0] && items[0].firstElement) ? items[0].id : 0);

  const renderAccordionItems = () => {
    return items.map((item) => (
      <AccordionItem>
        <Checkbox
          type="checkbox"
          id={item.id}
          onChange={() => {
            const itemActivated = active === item.id ? 0 : item.id;
            setActive(itemActivated);
          }}
          checked={active === item.id}
        />
        <Label htmlFor={item.id}>
          <Title>{item.title}</Title>
          <div className="icon">
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </Label>
        <Content>{item.content}</Content>
      </AccordionItem>
    ));
  };

  return <Container>{renderAccordionItems()}</Container>;
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Accordion;
