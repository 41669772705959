import { catchError, flatMap } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { push } from "connected-react-router";
import { ofType, combineEpics } from "redux-observable";
import { Observable } from "rxjs-compat";
import { RechargeSerializer } from "../../lib/serializers/recharge.serializer";
import App from "../../lib/app";
import RechargeActions from "../reducers/recharge.reducer";
import LoginActions from "../reducers/login.reducer";
import PaymentError from "../reducers/payment-error.reducer";
import ErrorsPSE, { warning } from "../../lib/utils/constants.errors";
import { moneyToNumber } from "../../lib/formatter";
import User from "../../lib/models/user.model";
import ModalActions from "../reducers/modal.reducer";
import { getErrorIndex } from "../../lib/utils";
import paramNames from "../../lib/models/parameter.model";
import AlertActions from "../reducers/alert.reducer";

const { HASHED_CODE_PARAMS } = paramNames;
const { _PARAM_010 } = HASHED_CODE_PARAMS();

export const validateRechargeAmount = (
  amount,
  amountFormatted,
  values,
  setSubmitting,
  userIp
) => ({
  type: "START_RECHARGE_AMOUNT_VALIDATION",
  payload: { amount, amountFormatted, values, setSubmitting, userIp }
});

export const rechargeDataFail = ({ error }) => ({
  type: "RECHARGE_DATA_FAIL",
  payload: {
    error
  }
});

export const rechargeAmountValidation = ($action) =>
  $action.pipe(
    ofType("START_RECHARGE_AMOUNT_VALIDATION"),
    flatMap(({ payload: { amount, values, setSubmitting, userIp } }) =>
      forkJoin([
        App.api.a2censo.getStaticParameters({
          url: {
            tables: `${_PARAM_010}`
          }
        }),
        Observable.from(
          App.api.a2censo.rechargeAmountValidation({
            url: {
              amount
            }
          })
        )
      ]).pipe(
        flatMap(() =>
          Observable.of(
            RechargeActions.endRechargeFlow({
              amount: moneyToNumber(values.amount),
              bankId: values.bank,
              documentType: User.documentType[values.documentType],
              documentNumber: values.documentNumber,
              userIp
            })
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              ),
              Observable.of(RechargeActions.setRechargeAmountError(e.errors))
            );
          }
          setSubmitting(false);
          return e.errors
            ? Observable.of(RechargeActions.setRechargeAmountError(e.errors))
            : Observable.of(
                AlertActions.setAlertState("error", "alert.tryAgain", {
                  icon: true
                })
              );
        })
      )
    )
  );

export const rechargeConfirmation = ($action) =>
  $action.pipe(
    ofType("END_RECHARGE_FLOW"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.recharge({
          body: RechargeSerializer().serialize({
            amount: action.payload.amount,
            bankCode: action.payload.bankId,
            documentNumber: action.payload.documentNumber,
            documentType: action.payload.documentType,
            userIp: action.payload.userIp
          })
        })
      ).pipe(
        flatMap((response) => {
          window.location.assign(response.bank_url);
          return Observable.empty();
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          if (
            e.errors &&
            e.errors.length > 0 &&
            ErrorsPSE.includes(e.errors[0].code)
          ) {
            return Observable.concat(
              Observable.of(rechargeDataFail(e)),
              Observable.of(PaymentError.setError(e.errors[0].code)),
              Observable.of(push("/payment-error"))
            );
          }
          const hasErrorIndex = getErrorIndex(e);
          if (hasErrorIndex > -1) {
            return Observable.concat(
              Observable.of(
                ModalActions.setConditionalModalState(true, "ErrorModal", {
                  title: "errorModal.title",
                  content: `errorCodes.${e.errors[hasErrorIndex].code}`,
                  linkRedirectsTo: "/",
                  buttonText: "errorModal.success"
                })
              ),
              Observable.of(PaymentError.setError(warning))
            );
          }
          return Observable.concat(
            Observable.of(PaymentError.setError(warning)),
            Observable.of(push("/payment-error"))
          );
        })
      )
    )
  );

export default combineEpics(rechargeAmountValidation, rechargeConfirmation);
