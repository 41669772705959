import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { startGetPoliticsTermsHC } from "../../redux/epics/politics-terms-hc";
import AppParams from "../../redux/reducers/app-params.reducer";

import { ModalRoute } from "react-router-modal";
import { push } from "connected-react-router";

type Props = {
  component: React.ReactNode;
  path: string;
};

export const ProtectedModalRoute = ({ component: Component, path }: Props) => {
  const dispatch = useDispatch();

  const location = useSelector(({ router }: any) => router.location);
  const idToken = sessionStorage.getItem("idToken");

  useEffect(() => {
    if (location.pathname === path) {
      dispatch(startGetPoliticsTermsHC());
      if (!idToken) {
        sessionStorage.setItem("pathToRedirect", path);
        dispatch(AppParams.setDeepLink(location.pathname));
        dispatch(push("/login"));
      }
    }
  }, [location, path]);

  return <ModalRoute component={Component} path={path} />;
};

ProtectedModalRoute.propTypes = {
  component: PropTypes.element.isRequired,
  path: PropTypes.string.isRequired
};
