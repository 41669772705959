const { Serializer } = require("jsonapi-serializer");

module.exports = {
  RechargeSerializer: (meta = {}) =>
    new Serializer("recharge", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "amount",
        "bankCode",
        "documentNumber",
        "documentType",
        "userIp"
      ]
    })
};
