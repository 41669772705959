import React from "react";
import "./TextImage.Styles.scss";
import environment from "environment";

type IProps = {
  campaignDetail: Array<{
    id: number;
    content?: string;
    url_image?: string;
  }>;
};
const TextImage = ({ campaignDetail }: IProps) => {
  const contentFilled = campaignDetail.filter(
    (detail) => detail.content && detail.content != ""
  )[0];
  const contentToDisplay =
    contentFilled &&
    contentFilled.content &&
    contentFilled.content
      .split("\n")
      .filter((line) => line != "\r" && line != "");
  return (
    <div className="text-image-container">
      {contentToDisplay &&
        contentToDisplay.map((line, i) => (
          <>
            {line && <div className="text-container">{line}</div>}
            {i < contentToDisplay.length - 1 && <br />}
          </>
        ))}

      <div className="image-container">
        {campaignDetail &&
          campaignDetail.map(
            ({ id, url_image }) =>
              url_image &&
              url_image != "" && (
                <div key={id} className="image-content-container">
                  {url_image && (
                    <img
                      className="image"
                      src={`${environment.aws.url}/${url_image}`}
                    />
                  )}
                </div>
              ))}
      </div>
    </div>
  );
};

export default TextImage;
