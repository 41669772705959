import styled from "styled-components";

export const Container = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.riverBed};
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-weight: 700;
  font-size: 18px;
`;

export const Text = styled.p`
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.primary};
  color: ${({ theme }) => theme.riverBed};
  font-size: 16px;
`;

export const SubButtonContainer = styled.div`
  width: 151px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
