import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const VideoContainer = styled.div`
  width: 70%;
  ${(props) => props.theme.tablet} {
    width: 90%;
  }

  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const Icon = styled.img`
  width: 50px;
  height: 50px;
  ${(props) => props.theme.smartphone} {
    width: 35px;
    height: 35px;
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: -60px;
  top: -50px;
  ${(props) => props.theme.tablet} {
    right: -40px;
  }
  ${(props) => props.theme.smartphone} {
    right: -20px;
  }
`;

export const Iframe = styled.iframe`
  max-width: 100%;
  height: 482px;
`;
