import { flatMap, catchError } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import { push } from "connected-react-router";
import App from "../../lib/app";

import LoginActions from "../reducers/login.reducer";
import RequestCampaignActions, {
  RequestCampaignTypes
} from "../reducers/request-campaign.reducer";
import CampaignStepperActions from "../reducers/create-campaign-stepper.reducer";
import paramNames from "../../lib/models/parameter.model";

import { RequestCampaignSerializer } from "../../lib/serializers/requestCampaign.serializer";
import { RequestCampaignBankInfoSerializer } from "../../lib/serializers/requestCampaignBankInfo.serializer";
import RequestCampaignPublishSerializer from "../../lib/serializers/request-campaign-publish.serializer";
import AlertActions from "../reducers/alert.reducer";
import CampaignDocumentManagmentActions from "../reducers/campaign-document-managment.reducer";
import {
  AVAILABLE_STEPS,
  FINANCIAL_TERMS_STATE
} from "../../lib/models/campaign.model";

export const getFinancialTerms = () => ({
  type: "GET_CAMPAIGN_FINANCIAL_TERMS_STEP_FIVE"
});

export const editFinancialTerms = () => ({
  type: "EDIT_FINANCIAL_TERMS_STEP_FIVE"
});

export const approveFinancialTerms = () => ({
  type: "APPROVE_FINANCIAL_TERMS_STEP_FIVE"
});

const { HASHED_CODE_PARAMS } = paramNames;
const { _PARAM_004 } = HASHED_CODE_PARAMS();

export const onFetchFinancialTermsStepFive = ($action, $state) =>
  $action.pipe(
    ofType("GET_CAMPAIGN_FINANCIAL_TERMS_STEP_FIVE"),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;

      return Observable.from(
        App.api.a2censo.campaignFinancialTerms({
          url: { requestCampaignId }
        })
      ).pipe(
        flatMap((response) =>
          Observable.of(
            RequestCampaignActions.setFinancialTermsSuggestions(response)
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      );
    })
  );

export const onEditFinancialTerms = ($action) =>
  $action.pipe(
    ofType("EDIT_FINANCIAL_TERMS_STEP_FIVE"),
    flatMap(() =>
      Observable.concat(
        Observable.of(
          CampaignStepperActions.setStep({
            currentTabId: AVAILABLE_STEPS.CAMPAIGN_INFORMATION
          })
        )
      )
    )
  );

export const onFetchRequestCampaignInfo = ($action) =>
  $action.pipe(
    ofType("FETCH_REQUEST_CAMPAIGN_INFO"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.getRequestCampaignInfo({
          url: { id: action.info.id }
        })
      ).pipe(
        flatMap((response) => {
          const info = response;
          return Observable.concat(
            Observable.of(RequestCampaignActions.setRequestCampaignInfo(info)),
            Observable.of(push("/create-campaign/information"))
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      )
    )
  );

export const onFetchGetParameterMinimalAmount = ($action) =>
  $action.pipe(
    ofType("FETCH_MINIMAL_AMOUNT"),
    flatMap(() =>
      Observable.from(
        App.api.a2censo.getStaticParameters({
          url: {
            tables: `${_PARAM_004}`
          }
        })
      ).pipe(
        flatMap((response) => {
          // eslint-disable-next-line no-underscore-dangle
          const minimalAmount = response._parameter.find(
            (parameter) => parameter.name === "invest_minimal_amount"
          );
          return Observable.concat(
            Observable.of(
              RequestCampaignActions.setMinimalAmount(
                Number(minimalAmount.value)
              )
            )
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      )
    )
  );

export const onSubmitRequestCampaignInfo = ($action) =>
  $action.pipe(
    ofType("SUBMIT_REQUEST_CAMPAIGN_INFO_FORM"),
    flatMap(
      ({
        payload: {
          info,
          setSubmitting,
          isValid,
          isNext,
          isFinancialTermsEdited
        }
      }) => {
        const finalInfo = { ...info, isFinancialTermsEdited };

        finalInfo.next = isValid;
        return Observable.from(
          App.api.a2censo.saveRequestCampaign({
            body: RequestCampaignSerializer().serialize(finalInfo)
          })
        ).pipe(
          flatMap((response) => {
            finalInfo.request_campaign.id = response.id;
            setSubmitting(false);
            if (!isNext) {
              return Observable.of(
                RequestCampaignActions.setRequestCampaignInfo({
                  ...finalInfo,
                  ...response
                })
              );
            }
            return Observable.concat(
              Observable.of(
                RequestCampaignActions.setRequestCampaignInfo({
                  ...finalInfo,
                  ...response
                })
              ),
              Observable.of(
                CampaignStepperActions.changeStateStage(
                  AVAILABLE_STEPS.CAMPAIGN_INFORMATION
                )
              ),
              Observable.of(
                RequestCampaignActions.setEditedFinancialTerms(
                  isFinancialTermsEdited || false
                )
              )
            );
          }),
          catchError((e) => {
            setSubmitting(false);
            if (e.statusCode === 401) {
              return Observable.of(LoginActions.loginReset());
            }
            return Observable.concat(
              Observable.of(RequestCampaignActions.setFetchingEnd()),
              Observable.of(push("/oops"))
            );
          })
        );
      }
    )
  );

export const onApproveFinancialTerms = ($action, $state) =>
  $action.pipe(
    ofType("APPROVE_FINANCIAL_TERMS_STEP_FIVE"),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;

      return Observable.from(
        App.api.a2censo.campaignFinancialTermsApprove({
          body: {
            request_campaign_id: requestCampaignId
          }
        })
      ).pipe(
        flatMap(() =>
          Observable.of(
            RequestCampaignActions.setFinancialTermsState(
              FINANCIAL_TERMS_STATE.APPROVE_BY_PYME
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      );
    })
  );

export const fetchRequestCampaignOtp = (action$, $state) =>
  action$.pipe(
    ofType(RequestCampaignTypes.START_REQUEST_CAMPAIGN_OTP_FLOW),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;
      return Observable.from(
        App.api.a2censo.requestCampaignOTPSend({
          url: {
            requestCampaignId
          }
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(RequestCampaignActions.setOtpDateSent())
          )
        ),
        catchError(() =>
          Observable.concat(
            Observable.of(RequestCampaignActions.setOtpFailRequest())
          )
        )
      );
    })
  );

export const fetchValidateCampaignOtp = (action$, $state) =>
  action$.pipe(
    ofType(RequestCampaignTypes.START_VALIDATE_CAMPAIGN_OTP_FLOW),
    flatMap((action) => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;
      return Observable.from(
        App.api.a2censo.requestCampaignOTPValidate({
          url: {
            requestCampaignId
          },
          body: {
            code: action.code
          }
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(RequestCampaignActions.setOtpValidateSuccess())
          )
        ),
        catchError(() =>
          Observable.concat(
            Observable.of(RequestCampaignActions.setOtpValidateInvalid())
          )
        )
      );
    })
  );

export const fetchRequestPreApprovalInfo = (action$, $state) =>
  action$.pipe(
    ofType(RequestCampaignTypes.START_REQUEST_CAMPAIGN_PRE_APPROVAL_FLOW),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;
      return Observable.from(
        App.api.a2censo.requestCampaignGetPreApprovalInfo({
          url: {
            requestCampaignId
          }
        })
      ).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(RequestCampaignActions.setPreApprovalInfo(response))
          )
        ),
        catchError(() =>
          Observable.concat(
            Observable.of(RequestCampaignActions.setPreApprovalInfoFailed())
          )
        )
      );
    })
  );
export const onSubmitBankInfoRequest = ($action, $state) =>
  $action.pipe(
    ofType(RequestCampaignTypes.SET_BANK_INFO_REQUEST),
    flatMap((action) => {
      const {
        bankInfo: {
          bank,
          bankAccountType,
          bankNumber,
          cityRetention,
          cityConsignment
        },
        isSendRequest
      } = action;
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;
      return Observable.from(
        App.api.a2censo.requestCampaignSaveBankInfo({
          body: RequestCampaignBankInfoSerializer().serialize({
            bank,
            type: bankAccountType,
            bank_account_number: bankNumber,
            request_campaign_id: requestCampaignId,
            city_retention: cityRetention,
            city_consignment: cityConsignment
          })
        })
      ).pipe(
        flatMap(() => {
          if (isSendRequest) {
            return Observable.concat(
              Observable.of(CampaignStepperActions.changeStateStage()),
              Observable.of(RequestCampaignActions.setFetchingEnd())
            );
          }
          return Observable.concat(
            Observable.of(RequestCampaignActions.setFetchingEnd()),
            Observable.of(CampaignDocumentManagmentActions.onModified())
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.concat(
              Observable.of(RequestCampaignActions.setFetchingEnd()),
              Observable.of(LoginActions.loginReset())
            );
          }
          return Observable.concat(
            Observable.of(RequestCampaignActions.setFetchingEnd()),
            Observable.of(push("/oops"))
          );
        })
      );
    })
  );

export const onFetchRequestCampaignPublishInfo = ($action, $state) =>
  $action.pipe(
    ofType(RequestCampaignTypes.FETCH_REQUEST_CAMPAIGN_PUBLISH_INFO),
    flatMap(() => {
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;
      return Observable.from(
        App.api.a2censo.requestCampaignInfo({
          url: { requestCampaignId }
        })
      ).pipe(
        flatMap((response) => {
          const info = response;
          return Observable.concat(
            Observable.of(
              RequestCampaignActions.setRequestCampaignPublishInfo(info)
            )
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.of(LoginActions.loginReset());
          }
          return Observable.concat(Observable.of(push("/oops")));
        })
      );
    })
  );

export const requestCampaignPublish = ($action, $state) =>
  $action.pipe(
    ofType(RequestCampaignTypes.REQUEST_CAMPAIGN_PUBLISH),
    flatMap(({ values, isSend }) => {
      const payload = RequestCampaignPublishSerializer(values, isSend);
      const {
        value: {
          requestCampaign: {
            info: {
              request_campaign: { id: requestCampaignId }
            }
          }
        }
      } = $state;
      return Observable.from(
        App.api.a2censo.requestCampaignPublish({
          url: { requestCampaignId },
          body: payload
        })
      ).pipe(
        flatMap((response) => {
          if (isSend && response.campaign_id && response.company_name) {
            return Observable.concat(
              Observable.of(CampaignStepperActions.sendPublishConfirmationEmail(response.campaign_id, response.company_name))
            );
          }
          return Observable.of(
            RequestCampaignActions.finishRequestCampaignPublish()
          );
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.concat(
              Observable.of(LoginActions.loginReset()),
              Observable.of(
                RequestCampaignActions.finishRequestCampaignPublish()
              )
            );
          }
          return Observable.concat(
            Observable.of(
              AlertActions.setAlertState("error", "alert.tryAgain")
            ),
            Observable.of(RequestCampaignActions.finishRequestCampaignPublish())
          );
        })
      );
    })
  );

export default combineEpics(
  onSubmitRequestCampaignInfo,
  onFetchRequestCampaignInfo,
  onFetchGetParameterMinimalAmount,
  onFetchFinancialTermsStepFive,
  onEditFinancialTerms,
  onApproveFinancialTerms,
  fetchRequestCampaignOtp,
  fetchValidateCampaignOtp,
  fetchRequestPreApprovalInfo,
  onSubmitBankInfoRequest,
  onFetchRequestCampaignPublishInfo,
  requestCampaignPublish
);
