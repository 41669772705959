import styled, { keyframes } from "styled-components";

const animationIn = keyframes`
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
`;

export const ContainerLoader = styled.div`
  background-color: ${({ theme }) => theme.altoTwo};
  border-radius: 40px;
  width: 204px;
  margin-top: 30px;
`;

export const Loader = styled.div`
  border-radius: 40px;
  height: 7px;
  background-color: ${({ theme }) => theme.lightPurple};
  animation: ${animationIn} 3s both;
`;
