import styled from "styled-components";

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 21.5px;
  margin-right: 21px;

  ${({ theme }) => theme.smartphone} {
    align-self: flex-start;
    margin-top: 2px;
  }
`;
