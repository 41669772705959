import { object, string } from "yup";
import { FormProps } from "./UserDataForm";

const internationalRegExpPN = /^[a-zA-ZÑñ ]+$/;

export const SchemaValidation = ({ ciiuList }: FormProps) =>
  object().shape({
    firstName: string()
      .required("updatePNData.form.validations.firstName")
      .matches(internationalRegExpPN, "register.validations.name.type"),
    lastName: string()
      .required("updatePNData.form.validations.lastName")
      .matches(internationalRegExpPN, "register.validations.name.type"),
    secondLastName: string().matches(
      internationalRegExpPN,
      "register.validations.name.type"
    ),
    secondName: string().matches(
      internationalRegExpPN,
      "register.validations.name.type"
    ),
    ciiu: string()
      .required("updatePNData.form.validations.ciiu")
      .test(
        "ciiuValidation",
        "confirmDataPyme.validations.ciiu.invalid",
        (value: string | null | undefined) => {
          const ciius = ciiuList.filter((item) => item.id === value);
          return ciius.length > 0;
        }
      )
  });
