const { Serializer } = require("jsonapi-serializer");

module.exports = {
  FinancialInputSerializer: (meta = {}) =>
    new Serializer("financialInput", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: [
        "assets",
        "monthlyIncome",
        "equity",
        "monthlyExpenses",
        "profit",
        "liabilities"
      ]
    })
};
