export const emailObfuscate = (email: string) =>
  `${email.charAt(0)}${email
    .substr(1, email.length - 7)
    .replace(/[^@]/g, "*")}${email.substr(email.length - 6, email.length)}`;

export const formatNumber = (value: number): string => {
  let formatValue: string;
  const pattern = new RegExp(/(\d)(?=(?:\d{3})+(?:\.|$))/);


  if (pattern.test(value.toString()) && value % 1 !== 0) {
    let partValue = value.toString().split(".");
    formatValue = `${partValue[0].replace(
      /(\d)(?=(?:\d{3})+(?:\.|$))/g,
      "$1."
    )},${partValue[1]}`;
    return formatValue;
  }

  formatValue = value
    .toString()
    .replace(/(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1.");
  return formatValue;
};
