import React from "react";
import "./Modal.scss";
import ReactModal from "react-modal";

const Modal = ({
  showModal,
  campaign,
  item,
  setShowModal,
  getPoints,
  getControls,
  validateUriVideo
}) => (
  <>
    <ReactModal isOpen={showModal} className="Modal" overlayClassName="Overlay">
      <div className="Modal__label">
        <div className="content-label">
          {campaign}
        </div>
        <div data-testid="modal-carousel-close" className="Modal__close" onClick={() => setShowModal(false)}></div>
      </div>
      <div className="Modal__img">
        {item !== undefined && item.type === "video" ? (
          <iframe
            className="size"
            src={validateUriVideo(item.src, true)}
            frameBorder="0"
            data-testid="preview-video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            title="campaign"
            allowFullScreen="1"
          />
        ) : item !== undefined && item.type === "image" ? (
          <img className="size" src={item.src} alt="" />
        ) : (
          ""
        )}
      </div>
      {getControls("Modal")}
      {getPoints("Modal")}
    </ReactModal>
  </>
);

export default Modal;
