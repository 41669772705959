import React from "react";
import { useTranslation } from "react-i18next";
import "./InvestDetail.scss";
import {
  numberToMoney,
  formatAmountMoney,
  truncateDecimals
} from "../../lib/formatter";
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { startInvestFlow, canIInvest } from "../../redux/epics/invest";
import User from "../../lib/models/user.model";
import InvestmentModel from "../../lib/models/investment.model";
import ModalActions from "../../redux/reducers/modal.reducer";

enum ICampaingTypes {
  Debt = 1,
  Shares = 2,
  ConvertibleNote = 3
}

enum ICampaingState {
  Open = 1,
  Awarded = 2,
  Close = 6
}

const CampaignState = {
  active: 1,
  awarded: 2,
  deserted: 3,
  cancelled: 4,
  inactive: 5
};

export type IProps = {
  investDetail: any;
};

const InvestDetail = ({ investDetail }: IProps) => {
  const { t } = useTranslation();

  const getCampaignPercentage = (
    investmentAmount: number,
    requestedAmount: number,
    limit: boolean
  ) => {
    const percentage: number = Number(
      (investmentAmount * 100) / requestedAmount
    );
    if (limit) {
      return percentage <= 100 ? percentage : "100";
    }
    return truncateDecimals(percentage, 2);
  };

  const appStore: any = useSelector((store) => store);
  const userState = useSelector(() => {
    return { ...appStore.user };
  });
  const dispatch = useDispatch();
  let userPayload = {};
  const documentTypeArray: any = User.documentType;
  if (userState.userInfo) {
    const { user } = userState.userInfo;
    userPayload = {
      personType: user.person_type,
      documentNumber: user.document_number,
      documentType: documentTypeArray[user.document_type],
      name: user.name,
      lastName: user.last_name,
      userId: user.id
    };
  }

  const campaignInactive =
    investDetail.campaign_state !== CampaignState.active ||
    investDetail.investment_state !== InvestmentModel.status.active ||
    investDetail.investment_amount >= investDetail.requested_amount;

  return (
    <div className="investDetail">
      <div className="content_invest_detail_desktop">
        <div className="half_content_invest_detail_desktop">
          <div className="line_invest_detail"></div>
          <div className="card_invest_detail">
            <div className="investDetail__title">{t("investDetail.title")}</div>
            <div className="investDetail__value">
              {t("investDetail.currency")}{" "}
              {investDetail?.net_amount >= 0
                ? numberToMoney(investDetail.net_amount)
                : 0}
            </div>
          </div>
          <div className="line_invest_detail"></div>
          <div className="content_flex_desktop">
            {ICampaingTypes.Debt == investDetail?.business_line ? (
              <>
                <div className="card_invest_detail card_deb_desktop">
                  <div className="content_invest_detail">
                    <div className="half_content_invest_detail">
                      <div className="investDetail__sub_title">
                        {t("investDetail.subTitle1")}
                      </div>
                    </div>
                    <div className="half_content_invest_detail">
                      <div
                        className="investDetail__icon"
                        data-tip="tooltipDebOne"
                        data-for="tooltipDebOne"
                      ></div>
                      <ReactTooltip
                        className="tooltip_invest_detail"
                        id="tooltipDebOne"
                        place="top"
                        effect="solid"
                        placement="topCenter"
                      >
                        <div className="tooltipDebOne">
                          {t("investDetail.tooltipDebOne")}
                        </div>
                      </ReactTooltip>
                    </div>
                  </div>
                  <div className="investDetail__sub_value">
                    {t("investDetail.currency")}{" "}
                    {investDetail?.value_in_paid_capital >= 0
                      ? numberToMoney(investDetail.value_in_paid_capital)
                      : 0}
                  </div>
                </div>
                <div className="border_vertical_desktop"></div>
                <div className="line_invest_detail display_invest_detail_desktop"></div>
                <div className="card_invest_detail card_deb_desktop">
                  <div className="content_invest_detail">
                    <div className="half_content_invest_detail">
                      <div className="investDetail__sub_title">
                        {t("investDetail.subTitle2")}
                      </div>
                    </div>
                    <div className="half_content_invest_detail">
                      <div
                        className="investDetail__icon"
                        data-tip="tooltipDebTwo"
                        data-for="tooltipDebTwo"
                      ></div>
                      <ReactTooltip
                        className="tooltip_invest_detail"
                        id="tooltipDebTwo"
                        place="top"
                        effect="solid"
                        placement="topCenter"
                      >
                        <div className="tooltipDebTwo">
                          {t("investDetail.tooltipDebTwo")}
                        </div>
                      </ReactTooltip>
                    </div>
                  </div>
                  <div className="investDetail__sub_value">
                    {t("investDetail.currency")}{" "}
                    {investDetail?.capital_pending_payable >= 0
                      ? numberToMoney(investDetail.capital_pending_payable)
                      : 0}
                  </div>
                </div>
                <div className="border_vertical_desktop"></div>
                <div className="card_invest_detail card_deb_desktop">
                  <div className="content_invest_detail">
                    <div className="half_content_invest_detail">
                      <div className="investDetail__sub_title">
                        {t("investDetail.subTitle3")}
                      </div>
                    </div>
                    <div className="half_content_invest_detail">
                      <div
                        className="investDetail__icon"
                        data-tip="tooltipDebThree"
                        data-for="tooltipDebThree"
                      ></div>
                      <ReactTooltip
                        className="tooltip_invest_detail"
                        id="tooltipDebThree"
                        place="top"
                        effect="solid"
                        placement="topCenter"
                      >
                        <div className="tooltipDebThree">
                          {t("investDetail.tooltipDebThree")}
                        </div>
                      </ReactTooltip>
                    </div>
                  </div>
                  <div className="investDetail__sub_value">
                    {t("investDetail.currency")}{" "}
                    {investDetail?.returns_paid >= 0
                      ? numberToMoney(investDetail.returns_paid)
                      : 0}
                  </div>
                </div>
              </>
            ) : ICampaingTypes.Shares == investDetail?.business_line ? (
              <>
                <div className="card_invest_detail card_share_desktop">
                  <div className="content_invest_detail">
                    <div className="investDetail__sub_title_share">
                      {t("investDetail.subTitle1_share")}
                    </div>
                  </div>
                  <div className="investDetail__sub_value_share">
                    {investDetail?.packagesamount}
                  </div>
                </div>
                <div className="border_vertical_desktop"></div>
                <div className="line_invest_detail display_invest_detail_desktop"></div>
                <div className="card_invest_detail card_share_desktop">
                  <div className="content_invest_detail">
                    <div className="investDetail__sub_title_share">
                      {t("investDetail.subTitle2_share")}
                    </div>
                  </div>
                  <div className="investDetail__sub_value_share">
                    {investDetail?.sharesamount}
                  </div>
                </div>
              </>
            ) : ICampaingTypes.ConvertibleNote ==
              investDetail?.business_line ? (
              <>
                <div className="card_invest_detail">
                  <div className="content_invest_detail">
                    <div className="half_content_invest_detail_80 width_title_note">
                      <div className="investDetail__sub_title_note">
                        {t("investDetail.subTitle1_note")}
                      </div>
                    </div>
                    <div className="half_content_invest_detail_30 margin_icon_container_desktop">
                      <div
                        className="investDetail__icon icon_note_desktop"
                        data-tip="tooltipNote"
                        data-for="tooltipNote"
                      ></div>
                      <ReactTooltip
                        className="tooltip_invest_detail"
                        id="tooltipNote"
                        place="top"
                        effect="solid"
                        placement="topCenter"
                      >
                        <div className="tooltipNote">
                          {t("investDetail.tooltipNote")}
                        </div>
                      </ReactTooltip>
                    </div>
                  </div>
                  <div className="investDetail__sub_value_note">
                    {t("investDetail.currency")}{" "}
                    {investDetail?.pending_to_convert >= 0
                      ? numberToMoney(investDetail.pending_to_convert)
                      : 0}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="border_vertical_desktop"></div>
        <div className="half_content_invest_detail_desktop">
          <div className="line_invest_detail"></div>
          <div className="card_invest_detail">
            <div className="content_invest_detail">
              <div className="half_content_invest_detail_55">
                <div className="investDetail__title_amount">
                  {t("investDetail.titleAmount")}
                </div>
              </div>
              <div className="half_content_invest_detail_45">
                <div
                  className={`investDetail__tab_state ${
                    investDetail?.campaign_state == ICampaingState.Open
                      ? "open_invest_state"
                      : investDetail?.campaign_state == ICampaingState.Awarded
                      ? "awarded_invest_state"
                      : "close_invest_state"
                  }`}
                >
                  {investDetail?.campaign_state == ICampaingState.Open
                    ? t("investDetail.stateOpen")
                    : investDetail?.campaign_state == ICampaingState.Awarded
                    ? t("investDetail.stateAwarded")
                    : t("investDetail.stateClose")}
                </div>
              </div>
            </div>
            <div className="content_invest_detail">
              <div className="investDetail__cop_raised">
                {t("investDetail.cop")}
              </div>
              <div className="investDetail__amount_raised">
                {investDetail?.investment_amount >= 0
                  ? `${numberToMoney(
                      formatAmountMoney(investDetail.investment_amount),
                      ""
                    )}M`
                  : "0M"}
              </div>
            </div>
            <div className="investDetail__bar_invest">
              <div
                className={`investDetail__bar_invest__progress ${
                  ICampaingTypes.Debt == investDetail?.business_line
                    ? "debt_invest"
                    : ICampaingTypes.Shares == investDetail?.business_line
                    ? "share_invest"
                    : ICampaingTypes.ConvertibleNote ==
                      investDetail?.business_line
                    ? "note_invest"
                    : null
                }`}
                style={{
                  width:
                    getCampaignPercentage(
                      investDetail?.investment_amount || 0,
                      investDetail?.requested_amount,
                      true
                    ) + "%"
                }}
              ></div>
            </div>
            <div className="content_invest_detail">
              <div className="half_content_invest_detail">
                <div className="investDetail__porcent_invest">
                  {getCampaignPercentage(
                    investDetail?.investment_amount || 0,
                    investDetail?.requested_amount,
                    false
                  )}
                  %
                </div>
                <div className="investDetail__porcent_invest_text">
                  {t("investDetail.porcentText")}
                </div>
              </div>
              <div className="half_content_invest_detail">
                <div className="investDetail__goal_value">
                  {`${numberToMoney(
                    formatAmountMoney(investDetail?.requested_amount),
                    ""
                  )}M`}
                </div>
                <div className="investDetail__goal_text">
                  {t("investDetail.goalText")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="line_invest_detail"></div>
      <div className="card_invest_detail">
        <div className="container_button">
          <div
            className={`investDetail__button_invest ${
              ICampaingTypes.Debt == investDetail?.business_line
                ? "debt_invest"
                : ICampaingTypes.Shares == investDetail?.business_line
                ? "share_invest"
                : ICampaingTypes.ConvertibleNote == investDetail?.business_line
                ? "note_invest"
                : null
            } ${campaignInactive ? "inactive_buttons_invest_detail" : ""}`}
            onClick={() => {
              if (!campaignInactive) {
                const action = !userState.userInfo
                  ? startInvestFlow(investDetail.campaign_id)
                  : canIInvest(investDetail.campaign_id, userPayload);
                dispatch(action);
              }
            }}
          >
            {t("investDetail.investButton")}
          </div>
        </div>
        <div
          className={`investDetail__cancel_invest ${
            campaignInactive ? "inactive_buttons_invest_detail" : ""
          }`}
          onClick={() => {
            if (!campaignInactive) {
              dispatch(
                ModalActions.setConditionalModalState(
                  true,
                  "CancellationModal",
                  {
                    campaignId: investDetail.campaign_id,
                    amount: investDetail.net_amount
                  }
                )
              );
            }
          }}
        >
          {t("investDetail.cancelButton")}
        </div>
      </div>
    </div>
  );
};

export default InvestDetail;
