import { createReducer, createActions } from "reduxsauce";

export const { Types, Creators } = createActions({
  setTerms: ({ termsAccepted }) => ({
    type: "SET_TERMS",
    payload: { termsAccepted }
  }),
  setPolitics: ({ politicsAccepted }) => ({
    type: "SET_POLITICS",
    payload: { politicsAccepted }
  }),
  setHC: ({ hcAccepted }) => ({
    type: "SET_HC",
    payload: { hcAccepted }
  }),
  setClearConditions: () => ({
    type: "SET_CLEAR_CONDITIONS"
  })
});

const initialState = {
  isTermsAccepted: false,
  isPoliticsAccepted: false,
  isHCAccepted: false
};

const setTermsValue = (state, { payload }) => {
  const { termsAccepted } = payload;
  return { ...state, isTermsAccepted: termsAccepted };
};
const setPoliticsValue = (state, { payload }) => {
  const { politicsAccepted } = payload;
  return { ...state, isPoliticsAccepted: politicsAccepted };
};
const setHCValue = (state, { payload }) => {
  const { hcAccepted } = payload;
  return { ...state, isHCAccepted: hcAccepted };
};

const setClearConditions = (state) => ({
  ...state,
  isTermsAccepted: false,
  isPoliticsAccepted: false,
  isHCAccepted: false
});

export default createReducer(initialState, {
  [Types.SET_TERMS]: setTermsValue,
  [Types.SET_POLITICS]: setPoliticsValue,
  [Types.SET_HC]: setHCValue,
  [Types.SET_CLEAR_CONDITIONS]: setClearConditions
});
