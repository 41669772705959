import styled from "styled-components";

export const FancyBG = styled.div``;

export const Container = styled.div`
  padding-top: 8%;
  ${(props) => props.theme.smartphone} {
    padding-top: 20px;
  }
`;

export const ContentTitle = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.tuatara};
  font-family: Nunito;
  font-size: 23px;
  margin-top: 20px;
  margin-bottom: 32px;
  font-weight: 800;
  text-align: center;
  padding-left: 8px;
  border-left: 6px solid;
  border-left-color: ${(props) => props.theme.brightTurquoise};
  ${(props) => props.theme.smartphone} {
    margin-top: 10px;
    font-size: 19px;
  }
`;

export const Subtitle = styled.p`
  color: ${(props) => props.theme.tuatara};
  font-family: Roboto;
  font-size: 17px;
  text-align: center;
  max-width: 400px;
  margin-top: 20px;
  font-weight: 200;
  margin-bottom: 30px;
  ${(props) => props.theme.smartphone} {
    margin-top: 10px;
    font-size: 14px;
  }
`;
export const OptionContainer = styled.div`
  justify-content: center;
`;

export const Option = styled.div`
  background: ${(props) => props.theme.white};
  border-radius: 8px;
  cursor: pointer;
  padding-bottom: 20px;
  margin: 10px 10px 0 10px;
  display: flex;
  justify-content: space-around;
`;

export const OptionTitle = styled.div`
  font-family: Nunito;
  font-weight: bold;
  font-size: 25px;
  margin: 14px 0;
  text-align: left;
  padding: 0;
  ${(props) => props.theme.tablet} {
    font-size: 20px;
    text-align: center;
  }
  width: 150px;
  padding-top: 28px;
}
`;

export const OptionContent = styled.div`
  padding: 0;
  text-align: left;
  ${(props) => props.theme.smartphone} {
    margin-left: 14px;
    padding-right: 24px;
  }
}
`;

export const IconContainer = styled.div`
  background: #e6f8fd;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-top: 30px;
  padding-bottom: 15px;
  width: 65px;
  text-align: center;
`;
export const Icon = styled.img``;

export const Cancel = styled.a`
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.brightTurquoise};
`;

export const CancelContainer = styled.p`
  margin-top: 50px;
  width: 100%;
  text-align: center;
`;
