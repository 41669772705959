/* eslint no-nested-ternary: 0 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { Container, Label, Input, TooltipErrorText } from "./Style";
import truncateString from "../../lib/utils/truncateString";

const InputFile = (props) => {
  const {
    error,
    id = "file-upload",
    disabled,
    readOnly,
    onChange: changeCallback
  } = props;
  const [fileName, setFileName] = useState("");
  const { t } = useTranslation();
  return (
    <Container>
      <Label
        id="label-file-upload"
        disabled={disabled}
        readOnly={readOnly}
        for="file-upload"
      >
        {truncateString(fileName, 15) || t("inputFile.text")}
      </Label>
      <Input
        {...props}
        id="file-upload"
        type="file"
        name="file"
        onChange={(e) => {
          setFileName(e.target.value.split("\\").pop());
          changeCallback(e);
        }}
        data-testid="upload_document"
      />
      {error && (
        <>
          <div
            className="input__tooltip-logo-container"
            data-tip={true}
            data-for={`errorInfo-${id}`}
          >
            <img
              className="input__tooltip_logo"
              src="/assets/images/error.png"
              alt="error"
            />
          </div>

          <ReactTooltip
            className="error-tooltip__back"
            id={`errorInfo-${id}`}
            place="right"
            type="light"
            effect="solid"
          >
            <TooltipErrorText>{t(error)}</TooltipErrorText>
          </ReactTooltip>
        </>
      )}
    </Container>
  );
};

InputFile.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

InputFile.defaultProps = {
  label: null,
  error: null,
  disabled: false,
  readOnly: false
};

export default InputFile;
