import * as Yup from "yup";
import { emailValidate } from "../../lib/utils";

const schema = {
  email: Yup.string()
    .email("contactForm.validations.email.invalidFormat")
    .test("length", "contactForm.validations.email.required", (value) =>
      emailValidate(value)
    )
    .required("contactForm.validations.email.required"),
  name: Yup.string().required("contactForm.validations.required"),
  description: Yup.string().required("contactForm.validations.required"),
  pymeEmail: Yup.string()
    .email("contactForm.validations.email.invalidFormat")
    .test("length", "contactForm.validations.email.required", (value) =>
      emailValidate(value)
    )
    .required("contactForm.validations.email.required"),
  pymeName: Yup.string().required("contactForm.validations.required"),
  pymeContactName: Yup.string().required("contactForm.validations.required"),
  pymePhone: Yup.string("register.validations.number")
    .matches(/^[1-9][0-9]{1,10}$/, "register.validations.number")
    .required("contactForm.validations.required")
    .test(
      "len",
      "register.validations.phone.invalid",
      (val) => val && val.toString().length === 10
    ),
  pymeDescription: Yup.string().required("contactForm.validations.required")
};

export default Yup.object().shape(schema);
