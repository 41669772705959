import styled from "styled-components";

export const TextContainer = styled.div`
  > h1,
  > p {
    text-align: center;
    color: ${({ theme }) => theme.midGray};
  }

  > h1 {
    margin-top: 52px;
    margin-bottom: 4px;
    font-weight: 900;
    font-size: 20px;
    line-height: 27px;
  }

  > p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 54px;
  }
`;
