import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  width: 233px;
  height: 216px;
  position: relative;
  cursor: pointer;

  ${({ theme }) => theme.smartphone} {
    width: 100%;
    height: 201px;
  }

  h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    color: ${({ theme }) => theme.midGray};
    width: 192px;
    ${({ theme }) => theme.smartphone} {
      width: 279px;
    }
  }

  p {
    position: absolute;
    bottom: 20px;
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.lightPurple};
  }
`;

export const Line = styled.div`
  background-color: ${({ theme }) => theme.lightPurple};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 8px;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

export const DocumentIMG = styled.img`
  margin-top: 7px;
  height: 32px;
  width: 32px;
`;

export const BackgroundIMG = styled.img`
  height: 91px;
  width: 80px;
  position: absolute;
  right: 0px;
  bottom: 0px;
`;

export const ContainerBody = styled.div`
  padding-left: 23px;
  padding-right: 16px;
  padding-top: 13px;
  height: 100%;
  padding-bottom: 18px;
  position: relative;
`;

export const ContainerIcons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const ArrowIMG = styled.img`
  height: 17px;
  width: 11px;
`;

export const ContainerArrow = styled.div`
  background-color: ${({ theme }) => theme.whiteSmoke};
  border-radius: 50%;
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
