import styled from "styled-components";

export const SearchInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  position: relative;
`;

export const SearchInput = styled.input`
  flex: 1;
  padding: 10px 20px 10px 45px;
  outline: none;
  border: none;
  font-family: "Nunito";
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.spiderSilk.bgColor};
`;

export const SearchIconImg = styled.img`
  height: 18px;
  width: 18px;
  object-fit: contain;
  position: absolute;
  top: 15px;
  left: 15px;
`;
