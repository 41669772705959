import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  #general_info_tooltip,
  #contact_tooltip {
    box-shadow: 0 6px 12px 0 rgb(44 50 65 / 10%);
  }
`;

export const PublicExposedContainer = styled.div`
  margin-bottom: 32px;
`;

export const QuestionIcon = styled.img`
  position: absolute;
  right: 30px;
  top: 20px;
  width: 25px;
  height: 25px;
`;

export const TooltipContainerCustom = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 6px;
  align-items: flex-end;
  background: transparent;
  width: 800px;
  ${(props) => props.theme.smartphone} {
    width: auto;
    width: 500px;
    align-items: start;
  }
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 6px;
  align-items: flex-end;
  background: transparent;
`;

export const TooltipTitle = styled.p`
  font-family: Roboto;
  font-weight: bold;
  font-size: 14px;
  margin: 2px !important;
  text-align: left;
  color: ${(props) => props.theme.nevada};
  white-space: pre-line;
  width: 100%;
  line-height: 20px;
  margin-bottom: 20px;
  ${(props) => props.theme.smartphone} {
    color: ${(props) => props.theme.brightTurquoise};
    font-size: 10px;
    font-weight: normal;
    line-height: 14px;
    width: 280px;
  }
`;

export const TooltipText = styled.p`
  font-family: Roboto;
  font-size: 11px !important;
  margin: 2px !important;
  text-align: left;
`;

export const TooltipLongText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  margin: 2px !important;
  text-align: left;
  color: ${(props) => props.theme.tooltipColorFont};
  white-space: pre-line;
  width: 100%;
  line-height: 20px;
  ${(props) => props.theme.smartphone} {
    font-size: 10px;
    font-weight: normal;
    line-height: 13px;
    width: 280px;
  }
`;

export const TextHeader = styled.div`
  align-items: center;
  color: #31302f;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  flex-direction: row;
  height: 100%;
  padding: 0 30px;
  width: 100%;
  white-space: pre-line;
  ${(props) => props.theme.smartphone} {
    font-size: 12px;
    padding: 0 16px;
  }
`;

const getBorders = (theme, borders) => {
  let styles = "";
  if (borders) {
    if (borders.right) {
      styles += `border-right: 0.7px ${theme.lineGray} solid;`;
    }
    if (borders.left) {
      styles += `border-left: 0.7px ${theme.lineGray} solid;`;
    }
    if (borders.bottom) {
      styles += `border-bottom: 0.7px ${theme.lineGray} solid;`;
    }
    if (borders.top) {
      styles += `border-top: 0.7px ${theme.lineGray} solid;`;
    }
  }
  return styles;
};

export const FormRow = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: row;
  ${({ theme, borders }) => getBorders(theme, borders)}
  ${(props) => props.theme.smartphone} {
    height: 48px;
  }
  height: 65px;
  width: 100%;
`;

export const FormColum = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : "100%")};
  ${({ theme, borders }) => getBorders(theme, borders)}
`;

export const EconomicActivity = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HelpIcon = styled.img`
  width: 25px;
  height: 25px;
  ${(props) => props.theme.smartphone} {
    height: 24px;
    width: 24px;
  }
`;
