import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Container,
  Row,
  HeadText,
  Caption,
  NumberCaption,
  SpanText
} from "./Styles";
import { formatAmount } from "../../lib/utils.js";
import Transaction from "../../lib/models/transaction.model";

const MovementItem = ({ movement }) => {
  const { t } = useTranslation();
  return (
    <Container type={movement.transaction_type} state={movement.state}>
      <Row>
        <HeadText className="content-text">
          {movement.transaction_type === Transaction.type.retention ||
          movement.transaction_type === Transaction.type.investment ||
          movement.transaction_type === Transaction.type.increase ||
          movement.transaction_type === Transaction.type.cashback
            ? t(
                `myMovements.${movement.transaction_type}.${movement.origin}.${movement.state}`
              )
            : t(`myMovements.${movement.transaction_type}.${movement.state}`)}
          <SpanText>
            {t(
              `myMovements.states.${movement.transaction_type}.${movement.state}`
            )}
          </SpanText>
        </HeadText>
        <HeadText>{moment(movement.created_at).format("hh:mm a")}</HeadText>
      </Row>
      <Row>
        <Caption type={movement.transaction_type} state={movement.state}>
          {movement.campaign_name !== null
            ? `${t("myMovements.campaignConcept")}${movement.campaign_name}`
            : ""}
        </Caption>
        <NumberCaption type={movement.transaction_type} state={movement.state}>
          {formatAmount(movement.net_amount)}
        </NumberCaption>
      </Row>
    </Container>
  );
};

MovementItem.propTypes = {
  movement: PropTypes.shape({
    transaction_type: PropTypes.string,
    state: PropTypes.string,
    origin: PropTypes.string,
    created_at: PropTypes.string,
    net_amount: PropTypes.number,
    concept: PropTypes.string,
    campaign_name: PropTypes.string,
    date: PropTypes.oneOfType(PropTypes.date, PropTypes.string)
  }).isRequired
};

export default MovementItem;
