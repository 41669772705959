import React, {
  useEffect,
  useState,
  useImperativeHandle,
  ReactElement
} from "react";
import "./CodeInput.scss";
import { Input } from "./InputStyle";

const isNumberRex = (val: string) => {
  const regex = new RegExp("^[0-9]*$", "g");
  return regex.test(val);
};

type Props = {
  error?: string;
  onChange?: (text: string) => void;
  otpSize?: number;
  disabled?: boolean;
  parentRef?: React.RefObject<any>;
  attempt?: number;
  timeUp?: boolean;
};

export const CodeInput = ({
  onChange = () => {},
  otpSize = 6,
  parentRef,
  error = "",
  disabled = false,
  attempt,
  timeUp= false
}: Props) => {
  const [inputValues, setInputValues] = useState<string[]>([]);
  const refs: React.Ref<HTMLInputElement>[] = [];

  const handleRef = (ref: React.Ref<any>) => refs.push(ref);

  const setNewInputValue = (i: number, newValue: string) => {
    const newInputValues = [...inputValues];
    newValue == "" ? delete newInputValues[i] : newInputValues[i] = newValue;
    setInputValues(newInputValues);
  };

  useImperativeHandle(parentRef, () => ({
    cleanValues() {
      for (const ref of refs) {
        const refToUse: any = ref;
        if (refToUse) {
          refToUse.value = "";
        }
      }

      setInputValues([]);
    }
  }));

  // onChange inputValues
  useEffect(() => {
    onChange(inputValues.join(""));
  }, [inputValues]);

  useEffect(() => {
    refs.forEach((_, i) => {
      const ref: any = refs[i];
      ref.onfocus = () => {
        ref.value = "";
        setNewInputValue(i, "");
      };
      ref.onpaste = (e: {
        preventDefault: () => void;
        clipboardData: { getData: (arg0: string) => any };
      }) => {
        e.preventDefault();
        const text = e.clipboardData.getData("Text");
        ref.value = text.length > 1 ? text.charAt(0) : text;
      };
      ref.oninput = (e: {
        preventDefault: () => void;
        target: { value: string, style:any };
      }) => {
        e.preventDefault();
        if (!e.target.value) {
          ref.value = "";
          ref.focus();
          return;
        }
        setNewInputValue(i, e.target.value);
        const nextReference: any = refs[i + 1];
        if (i < refs.length - 1 && nextReference) {
          nextReference.focus();
        } else {
          ref.blur();
        }
      };
      ref.onkeydown = (e: {
        key: string;
        preventDefault: () => void;
        keyCode: number;
        target: { value: string };
      }) => {
        if (!isNumberRex(e.key)) {
          e.preventDefault();
        }

        if (e.keyCode === 8) {
          ref.value = "";
          const referenceToUse: any = refs[i > 0 ? i - 1 : i];
          setNewInputValue(i, e.target.value);
          referenceToUse.focus();
        }
      };
    });
    return () => {};
  }, [inputValues]);

  const renderInputs = () => {
    const inputs: ReactElement[] = [];

    for (let i = 0; i < otpSize; i++) {
      inputs.push(
        <div className="codeInput__InputContainer">
          <Input
            className={`attempt-${attempt} ${timeUp ? "timeUp" : ""}`}
            data-testid={`otp-code-input-${i}`}
            type="number"
            placeholder="*"
            maxLength="1"
            disabled={disabled}
            error={error}
            ref={handleRef}
          />
        </div>
      );
    }
    return inputs;
  };
  return <div className="codeInput">{renderInputs()}</div>;
};
