import { flatMap, catchError } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import FileUploaderActions from "../reducers/file-uploader.reducer";
import LoginActions from "../reducers/login.reducer";

export const setUploadFile = (file, name) => ({
  type: "START_UPLOAD_FILE",
  payload: {
    name,
    file
  }
});

export const onStartFileUpload = ($action) =>
  $action.pipe(
    ofType("START_UPLOAD_FILE"),
    flatMap(({ payload: { file, name } }) => {
      const formData = new FormData();

      formData.append("file", file);
      return Observable.concat(
        Observable.of(FileUploaderActions.setNewFileUpload({ name })),
        Observable.from(
          App.api.a2censo.uploadFile({
            body: formData
          })
        ).pipe(
          flatMap(({ key }) =>
            Observable.of(FileUploaderActions.endUploadFile(name, key))
          ),
          catchError((err) => {
            if (err.statusCode === 401) {
              return Observable.of(LoginActions.loginReset());
            }
            return Observable.of(
              FileUploaderActions.errorUploadFile(name, err.message)
            );
          })
        )
      );
    })
  );

export default combineEpics(onStartFileUpload);
