import styled from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => theme.iPhone6s} {
    margin-bottom: 70px;
    height: 545px;
    & > div:nth-child(2) {
      height: 500px;
      & > div:nth-child(2) {
        height: 545px;
      }
    }
  }
  ${({ theme }) => theme.iPhone8Plus} {
    margin-bottom: 70px;
    & > div:nth-child(2) {
      height: 560px;
      & > div:nth-child(2) {
        height: 578px;
      }
    }
  }
  ${({ theme }) => theme.iPhoneX} {
    margin-bottom: 70px;
    & > div:nth-child(2) {
      height: 560px;
      & > div:nth-child(2) {
        height: 578px;
      }
    }
  }

  ${({ theme }) => theme.verySmallDevices} {
    & > div:nth-child(2) {
      height: 400px;
      & > div:nth-child(3) {
        height: 530px;
      }
    }
  }
`;

export const Container = styled.div`
  width: 548px;
  height: 515px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 12px;
  padding-top: 30px;
  position: relative;

  ${({ theme }) => theme.smartphoneMedium} {
    width: 320px;
    height: 565px;
    box-sizing: border-box;
    padding-top: 30px;
  }

  ${({ theme }) => theme.verySmallDevices} {
    height: 400px;
  }
`;
