import styled from "styled-components";

export const ContainerUpdateData = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.backTagGray};
  border-radius: 5px;
  height: 67px;
  display: flex;
  margin-top: 24px;
  align-items: center;
  padding-right: 20px;

  ${({ theme }) => theme.smartphone} {
    height: auto;
    flex-direction: column;
    padding-top: 20px;
    padding-right: 16px;
    padding-bottom: 16px;
    width: 325px;
  }
  div {
    display: flex;
    align-items: center;
  }
  p {
    font-family: Nunito, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.gunPowder};
    ${({ theme }) => theme.smartphone} {
      width: 257px;
    }
  }

  > button {
    width: 117px;
    margin-left: auto;
    font-size: 16px;
    font-weight: 800;
    ${({ theme }) => theme.smartphone} {
      margin-top: 15px;
    }
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 21.5px;
  margin-right: 21px;

  ${({ theme }) => theme.smartphone} {
    align-self: flex-start;
    margin-top: 2px;
  }
`;
