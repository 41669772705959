import * as React from "react";
import { UserDataWrapper } from ".";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { push } from "connected-react-router";
import UpdateUserDataActions from "redux/store/update-user-data/reducer";
import { TypeUserDataReducer, getUserUpdateData } from "redux/store";
import UserModel from "lib/models/user.model";
import { MainContainer } from "./components/MainContainer";
import { FormUserDataValues } from "components/UserDataForm";

export const UserDataContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userInfo = useSelector(
    ({ user: { userInfo: userData } }: any) => userData
  );

  const { isFetching, error, success }: TypeUserDataReducer = useSelector(
    getUserUpdateData
  );

  let apiError = error === UserModel.UpdateUserState.apiError;
  let messageError = "";

  if (error === UserModel.UpdateUserState.nameNotCoincidence) {
    messageError = t("updatePNData.error");
  }

  if (error === UserModel.UpdateUserState.allowedCodeCiiu) {
    messageError = t("updatePNData.allowed");
  }

  const closeModal = () => {
    dispatch(push("/my-balance"));
  };

  const saveData = (values: FormUserDataValues) => {
    dispatch(UpdateUserDataActions.startFetchUpdateUserData(values));
  };

  React.useEffect(() => {
    if (Number(userInfo?.user.data_update_completed)) {
      closeModal();
    }
  }, []);

  return (
    <MainContainer isOver={!userInfo?.have_attempts || apiError}>
      <UserDataWrapper
        isSuccess={success}
        haveAttempts={userInfo?.have_attempts}
        loading={isFetching}
        errorMessage={messageError}
        names={userInfo?.user?.name}
        lastName={userInfo?.user?.last_name}
        secondLastName={userInfo?.user?.second_last_name}
        ciiu={userInfo?.natural_person?.ciiu}
        onSave={saveData}
        onClose={closeModal}
        isErrorAPI={apiError}
      />
    </MainContainer>
  );
};
