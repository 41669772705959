module.exports = {
  InvestSerializer: ({
    transactionType,
    documentNumber,
    documentType,
    bankCode,
    campaignId,
    available,
    pse,
    userIp,
    userEmail
  }) => ({
    meta: {},
    data: {
      attributes: {
        campaign_id: campaignId,
        transaction_type: transactionType,
        document_number: documentNumber,
        document_type: documentType,
        bank_code: bankCode,
        user_ip: userIp,
        user_email: userEmail,
        origin: {
          available: available,
          pse: pse
        }
      }
    }
  }),
  canIInvestSerializer: ({
    document_type,
    document_number,
    dv,
    person_type,
    name,
    last_name,
    userId,
    userEmail,
    userIp
  }) => ({
    meta: {},
    document_type: document_type,
    document_number: document_number,
    dv: dv,
    person_type: person_type,
    name: name,
    last_name: last_name,
    userId: userId,
    userEmail: userEmail,
    user_ip: userIp
  }),

  getTransactionSerializer: ({ transaction, person_type }) => ({
    transaction: transaction,
    person_type: person_type
  })
};
