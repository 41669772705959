import styled from "styled-components";

export const Container = styled.div`
  width: 828px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px 0 20px;
  ${(props) => props.theme.smartphone} {
    padding: 10px 0 10px;
    margin: 0 5%;
  }
  border-radius: 10px;
`;

export const Title = styled.h2`
  margin-bottom: 20px;
  margin-top: 60px;
  max-width: 60%;
`;

export const Paragraph = styled.div`
  font-size: 23px;
  line-height: 32px;
  margin-bottom: 40px;
  max-width: 60%;
  ${(props) => props.theme.smartphone} {
    max-width: 80%;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 40px;
  }
`;

export const Anchor = styled.a`
font-size: 23px;
line-height: 32px;
color: ${(props) => props.theme.brightTurquoise}
${(props) => props.theme.smartphone} {
  font-size: 14px;
  line-height: 20px;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.brightTurquoise};
  margin-bottom: 14px;
`;

export const Button = styled.div`
  color: ${(props) => props.theme.brightTurquoise};
  width: 215px;
  height: 49px;
  border: 2px solid ${(props) => props.theme.brightTurquoise};
  border-radius: 50px;
  padding-top: 12px;
  font-weight: bold;
  cursor: pointer;
  ${(props) => props.theme.smartphone} {
    width: 150px;
    font-size: 12px;
    border: 1px solid ${(props) => props.theme.brightTurquoise};
    padding-top: 9px;
    height: 35px;
  }
`;

export const IconTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  ${(props) => props.theme.smartphone} {
    margin-top: 15px;
  }
`;

export const IconTitle = styled.img`
  width: 100px;
  height: 100px;
  ${(props) => props.theme.smartphone} {
    width: 60px;
    height: 60px;
  }
`;

export const Icon = styled.img`
  width: 30px;
  height: 30px;
  ${(props) => props.theme.smartphone} {
    width: 25px;
    height: 25px;
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  align-self: flex-end;
  margin-right: 30px;
  ${(props) => props.theme.smartphone} {
    margin-right: 10px;
  }
`;
