import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  WrapperRef,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
  DownArrow,
  DropDownSelect,
  ReactToolTip,
  ToolTipImg,
  ToolTipImgContainer
} from "./components";
import Arrow from "../../../assets/images/down-arrow.png";

export type DropdownIOption = {
  id: number | string;
  value: any;
};
type IProps = {
  valueSelected: number | string;
  onChange: (item: DropdownIOption) => void;
  initialLabel?: string;
  options: Array<DropdownIOption>;
  dataTestId?: string;
  name: string;
  id: string;
  disabled?: boolean;
  error?: string;
  onBlur?: () => void;
};

export const Dropdown = ({
  valueSelected,
  onChange,
  initialLabel = "Selecciona",
  options,
  dataTestId = "test-custom-dropdown",
  name,
  id,
  disabled = false,
  error,
  onBlur = () => {}
}: IProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const { current } = wrapperRef;
      if (
        !(current || { contains: (t: EventTarget | null) => !!t }).contains(
          event.target
        )
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRef]);

  const toggling = () => {
    setIsOpen(!isOpen);
    onBlur();
  };

  const onOptionClicked = (value: DropdownIOption) => () => {
    onChange(value);
    setIsOpen(false);
  };

  const optionSelected = options.find((option) => option.id === valueSelected);

  const value = optionSelected ? optionSelected.value : null;

  const getWidthOption = () => {
    if (document.querySelector(".ddl")) {
      const element: HTMLElement | null = document.querySelector(".ddl");
      if (element) {
        return `${element.offsetWidth}`;
      }
    }
  };

  return (
    <WrapperRef ref={wrapperRef}>
      <DropDownHeader
        isOpen={isOpen}
        onClick={toggling}
        data-testid={dataTestId}
        value={value || ""}
        className="ddl"
        name={name}
        id={id}
        disabled={disabled}
      >
        {value || initialLabel}
        <DownArrow isOpen={isOpen} src={Arrow} />
      </DropDownHeader>

      {isOpen && (
        <DropDownListContainer>
          <DropDownSelect>
            <DropDownList>
              {options.map((option, i) => (
                <ListItem
                  onClick={onOptionClicked(option)}
                  key={option.id}
                  widthOption={getWidthOption()}
                  id={option.id.toString()}
                  data-testid={`list-item-${i}`}
                >
                  {option.value}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownSelect>
        </DropDownListContainer>
      )}
      {error && (
        <>
          <ToolTipImgContainer
            data-tip={`dropdown-tooltip_${id}`}
            data-for={`dropdown-tooltip_${id}`}
          >
            <ToolTipImg src="/assets/images/error.png" alt="error" />
          </ToolTipImgContainer>
          <ReactToolTip
            place="bottom"
            type="error"
            effect="solid"
            id={`dropdown-tooltip_${id}`}
          >
            <div
              className="error-tooltip__container"
              data-testid="error-tooltip-dropdown"
            >
              <p className="error-tooltip__text--title">{t(error)}</p>
            </div>
          </ReactToolTip>
        </>
      )}
    </WrapperRef>
  );
};
