import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Container,
  Question,
  QuestionText,
  SubQuestions,
  SubQuestionText,
  OptionsContainer,
  TooltipContent,
  TooltipTitle,
  Tooltip,
  Lines,
  SubQuestionContainer,
  QuestionOptions
} from "./Styles";
import {
  SubQuestion,  
  ShortLine,
  LongLine,
  LineCircle,
  QuestionIcon
} from "../Form/Styles";
import RadioButton from "../RadioButton/RadioButton";
import {
  TooltipContainer,
  TooltipText
} from "../../pages/GeneralInformation/Styles";

const RadioQuestions = ({
  questionText,
  questionValue,
  setQuestion,
  subQuestions,
  setSubQuestions,
  testId,
  tooltipText,
  tooltipTitle,
  tooltipWidth,
  tooltiPlace,
  tooltipType,
  textAlign,
  id,
  isSummary,
  isPep
}) => {
  const { t } = useTranslation();
  const { list, answers } = subQuestions;
  const arrayTooltipText = tooltipText ? tooltipText.split("%br") : [];

  const anwsersStatusCkecked = (index, comparation) =>
    answers[index].answer !== null
      ? Number(answers[index].answer) === comparation
      : null;

  return (
    <Container>
      <Question className="col-md-12">
        <QuestionText isSummary={isSummary}>{questionText}</QuestionText>
        {arrayTooltipText.length > 0 ? (
          <QuestionIcon
            data-tip={true}
            data-for={`radio_question_tooltip_${testId}`}
            src="/assets/images/icono_pregunta.png"
            alt="question"
          />
        ) : null}
        <Tooltip
          id={`radio_question_tooltip_${testId}`}
          place={tooltiPlace}
          type={tooltipType}
          effect="solid"
        >
          <TooltipContainer>
            {tooltipTitle ? <TooltipTitle>{tooltipTitle}</TooltipTitle> : null}
            <TooltipContent tooltipWidth={tooltipWidth}>
              {arrayTooltipText.map((text) => (
                <TooltipText textAlign={textAlign}>{text}</TooltipText>
              ))}
            </TooltipContent>
          </TooltipContainer>
        </Tooltip>
      </Question>
      <OptionsContainer isPep={isPep} questionVal={questionValue}>
        <QuestionOptions isSummary={isSummary}>
          <RadioButton
            data-testid={`${testId}_yes`}
            check={questionValue === 1}
            text={t("generalInformation.yes")}
            onCheck={() => setQuestion(1)}
            data-active={questionValue === 1}
            id={`${id}_yes`}
            isSummary={isSummary}
          />
          <RadioButton
            data-testid={`${testId}_no`}
            check={questionValue === 0}
            text={t("generalInformation.no")}
            onCheck={() => setQuestion(0)}
            data-active={questionValue === 0}
            id={`${id}_no`}
            isSummary={isSummary}
          />
        </QuestionOptions>
      </OptionsContainer>
      {questionValue === 1 && subQuestions.answers.length > 0 && (
        <SubQuestions isPep={isPep}>
          {list.map((item, index) => (
            <SubQuestionContainer key={index} isSummary={isSummary} isPep={isPep}>
              <Lines isSummary={isSummary}>
                <ShortLine isSummary={isSummary}/>
                <LineCircle isSummary={isSummary}/>
                <LongLine isSummary={isSummary}/>
              </Lines>
              <SubQuestion>
                <SubQuestionText isSummary={isSummary}>{item.name}</SubQuestionText>
                <QuestionOptions isSummary={isSummary}>
                  <RadioButton
                    data-testid={`${testId}_sub_question_${item.id}_answer_yes`}
                    check={anwsersStatusCkecked(index, 1)}
                    text={t("generalInformation.yes")}
                    onCheck={() => setSubQuestions(index, 1, item)}
                    longRadioButton={!isSummary}
                    id={`${id}_sub_question_${item.id}_answer_yes`}
                    isSummary={isSummary}
                  />
                  <RadioButton
                    data-testid={`${testId}_sub_question_${item.id}_answer_no`}
                    check={anwsersStatusCkecked(index, 0)}
                    text={t("generalInformation.no")}
                    onCheck={() => setSubQuestions(index, 0, item)}
                    longRadioButton={!isSummary}
                    id={`${id}_sub_question_${item.id}_answer_no`}
                    isSummary={isSummary}
                  />
                </QuestionOptions>
              </SubQuestion>
            </SubQuestionContainer>
          ))}
        </SubQuestions>
      )}
    </Container>
  );
};

RadioQuestions.propTypes = {
  questionText: PropTypes.string,
  questionValue: PropTypes.number,
  setQuestion: PropTypes.func.isRequired,
  subQuestions: PropTypes.shape({
    list: PropTypes.shape([]),
    answers: PropTypes.shape([])
  }),
  setSubQuestions: PropTypes.func,
  testId: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipTitle: PropTypes.string,
  tooltiPlace: PropTypes.string,
  tooltipType: PropTypes.string,
  tooltipWidth: PropTypes.string,
  textAlign: PropTypes.string,
  id: PropTypes.string,
  isSummary: PropTypes.bool,
  isPep: PropTypes.bool
};

RadioQuestions.defaultProps = {
  questionText: "Question?",
  questionValue: 0,
  setSubQuestions: null,
  subQuestions: {
    list: [],
    answers: []
  },
  testId: "radio_questions_",
  tooltiPlace: "left",

  tooltipText: "",
  tooltipTitle: "",
  tooltipType: "light",

  tooltipWidth: "",
  textAlign: "",
  id: "",
  isSummary: false,
  isPep: false
};

export default RadioQuestions;
