import React from "react";
import { useTranslation } from "react-i18next";
import { Heading, Paragraph } from "@bvcco/a2censo-component-lib";
import {
  Container,
  HeadingContainer,
  ParagraphContainer,
  Footer,
  VerticalDivider,
  PillContainer,
  Image
} from "./components";
import LogoA2censo from "assets/images/logo-a2censo.png";
import LogoBVC from "assets/images/logotipo-bvc.png";

export enum AboutUsFromType {
  OurAllies = "ourAllies",
  KnowUs = "knowUs"
}

type Props = {
  from: AboutUsFromType;
};

export const AboutUs = ({ from }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <HeadingContainer from={from}>
        <Heading color="comet" align="center" fontWeight="600">
          {t(`a2censo.aboutUs.${from}.heading.normal`)}
          <strong>{t(`a2censo.aboutUs.${from}.heading.bold`)}</strong>
        </Heading>
      </HeadingContainer>
      <ParagraphContainer from={from}>
        <Paragraph color="comet" align="center" fontWeight="bold" from={from}>
          {t(`a2censo.aboutUs.${from}.paragraph`)}
        </Paragraph>
      </ParagraphContainer>
      {from === AboutUsFromType.OurAllies && (
        <Footer data-testid="our-allies-footer">
          <PillContainer>
            <Image src={LogoA2censo} />
            <VerticalDivider />
            <Image src={LogoBVC} />
          </PillContainer>
        </Footer>
      )}
    </Container>
  );
};
