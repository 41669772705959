import React from "react";
import { Wrapper } from "./components";

type Props = {
  steps: JSX.Element[];
};

export const StepList = ({ steps = [] }: Props) => (
  <Wrapper data-testid="step-list">
    {steps.map((step, index) => (
      <div key={`step-${index}`}>{step}</div>
    ))}
  </Wrapper>
);
