import React, { useState } from "react";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { registerLocale } from "react-datepicker";


import es from "date-fns/locale/es";
import {
  Picker,
  Container,
  ImgContainer,
  Icon,
  ReactToolTip,
  ToolTipImg,
  ToolTipImgContainer
} from "./Styles";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("es", es);

export const launchPicker = (element) => {
  if (!element) {
    return;
  }
  element.click();
};

const DatePickerInput = (props) => {
  const {
    value,
    format,
    placeholder,
    onChange,
    monthDropdown,
    yearDropdown,
    showTooltip,
    error,
    className,
    maxDate,
    onBlur,
    setTouchedIcon,
    isSummary,
    isDisabled
  } = props;
  const [startDate, setStartDate] = useState(value ? new Date(value) : "");
  const renderTooltip = showTooltip && error;
  const { t } = useTranslation();

  
  const handleCalendarClose = () => {
    if (startDate === "") {
      setTouchedIcon(true);
    }
  };

  const handleCalendarOpen = () => {
    setTouchedIcon(false);
  };

  return (
    <Container className={className} isSummary={isSummary}>
      <Picker
        data-testid="datepicker-test"
        id="datepicker"
        isSummary={isSummary}
        isDisabled={isDisabled}
        selected={startDate}
        locale="es"
        dateFormat={format}
        placeholderText={placeholder}
        showMonthDropdown={monthDropdown}
        showYearDropdown={yearDropdown}
        scrollableYearDropdown={true}
        maxDate={maxDate}
        dropdownMode="select"
        onChange={(date) => {
          onChange(date);
          setStartDate(date);
        }}
        onCalendarClose={handleCalendarClose}
        onCalendarOpen={handleCalendarOpen}
        {...props}
      />
      {renderTooltip && (
        <>
          <ToolTipImgContainer data-tip={true}>
            <ToolTipImg src="/assets/images/error.png" alt="error" />
          </ToolTipImgContainer>

          <ReactToolTip place="bottom" type="error" effect="solid">
            <div className="error-tooltip__container">
              <p className="error-tooltip__text--title">{t(error)}</p>
            </div>
          </ReactToolTip>
        </>
      )}
      <ImgContainer
        isDisabled={isDisabled}
        onClick={() => {
          launchPicker(document.getElementById(props.id));
          onBlur();
        }}
        id="picker-icon"
      >
        <Icon src={isSummary ? "/assets/images/imageCalendarSummary_.png" : "/assets/images/calendar_icon.png"} 
        alt="Date picker" isSummary={isSummary}
        isDisabled={isDisabled} />
      </ImgContainer>
    </Container>
  );
};

DatePickerInput.propTypes = {
  value: propTypes.string,
  format: propTypes.string,
  error: propTypes.string,
  showTooltip: propTypes.bool,
  placeholder: propTypes.string,
  monthDropdown: propTypes.bool,
  yearDropdown: propTypes.bool,
  onChange: propTypes.func.isRequired,
  id: propTypes.string,
  className: propTypes.string,
  maxDate: propTypes.instanceOf(Date),
  onBlur: propTypes.func,
  isSummary: propTypes.bool,
  isDisabled: propTypes.bool
};

DatePickerInput.defaultProps = {
  maxDate: undefined,
  value: "",
  className: "",
  format: "yyyy-MM-dd",
  placeholder: "",
  monthDropdown: true,
  yearDropdown: true,
  error: null,
  showTooltip: true,
  id: "",
  isSummary: false,
  isDisabled: false,
  onBlur: () => {}
};

export default DatePickerInput;
