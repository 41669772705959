import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import * as R from "ramda";
import App from "../../lib/app";
import LoginActions from "../reducers/login.reducer";
import { getUserInfo } from "./confirm-data";
import ModalActions from "../reducers/modal.reducer";
import User from "../../lib/models/user.model";

export const startGetPoliticsTermsHC = () => ({
  type: "START_GET_POLITICS_TERMS_HC_DATA"
});

export const startSavePolitics = (politicsId, politicsVersion) => ({
  type: "START_SAVE_POLITICS_DATA",
  payload: {
    politicsId,
    politicsVersion
  }
});

export const startSaveTerms = (termsId, termsVersion) => ({
  type: "START_SAVE_TERMS_DATA",
  payload: {
    termsId,
    termsVersion
  }
});

export const startSaveHC = (hcId, hcVersion) => ({
  type: "START_SAVE_HC_DATA",
  payload: {
    hcId,
    hcVersion
  }
});

export const politicsTermsHCFail = ({ error }) => ({
  type: "POLITICS_TERMS_HC_FAIL",
  payload: {
    error
  }
});

export const userValidatePoliticsTermsHC = (terms) => {
  const { user } = App.redux.store.getState();
  const politicsAccepted = R.pathOr(
    "",
    ["userInfo", "last_accepted_policy", "version"],
    user
  );
  const termsAccepted = R.pathOr(
    "",
    ["userInfo", "last_accepted_term", "version"],
    user
  );
  const politicsActive = R.pathOr("", ["last_policy", "version"], terms);
  const termsActive = R.pathOr("", ["last_term", "version"], terms);
  const hcAccepted = R.pathOr(
    "",
    ["userInfo", "last_accepted_hc", "version"],
    user
  );
  const hcActive = R.pathOr("", ["last_hc", "version"], terms);
  return {
    politics: politicsAccepted !== politicsActive,
    terms: termsAccepted !== termsActive,
    hc: hcAccepted !== hcActive
  };
};

export const getPoliticsTermsHC = (action$, $store) =>
  action$.pipe(
    ofType("START_GET_POLITICS_TERMS_HC_DATA"),
    flatMap(() => {
      const personType = R.pathOr(
        User.type.natural,
        ["value", "user", "userInfo", "user", "person_type"],
        $store
      );
      return Observable.from(
        App.api.a2censo.politicsTermsHCbyPersonType({
          url: {
            personType: User.typeIds[personType]
          }
        })
      ).pipe(
        flatMap((response) => {
          const idToken = sessionStorage.getItem("idToken");
          const refreshToken = sessionStorage.getItem("refreshToken");
          const activeSession = !!(idToken && refreshToken);

          if (activeSession) {
            const { user } = App.redux.store.getState();
            if (user.userInfo !== null) {
              const validations = userValidatePoliticsTermsHC(response);

              if (validations.politics) {
                return Observable.concat(
                  Observable.of(
                    ModalActions.setConditionalModalState(true, "Politics", {
                      politicsObject: response.last_policy,
                      requestFrom: "home"
                    })
                  )
                );
              }

              if (validations.terms) {
                return Observable.concat(
                  Observable.of(
                    ModalActions.setConditionalModalState(true, "Terms", {
                      termsObject: response.last_term,
                      requestFrom: "home"
                    })
                  )
                );
              }
              return Observable.empty();
            }

            return Observable.empty();
          }

          return Observable.empty();
        }),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.concat(
              Observable.of(LoginActions.loginReset()),
              Observable.of(push("/"))
            );
          }

          return Observable.concat(
            Observable.of(politicsTermsHCFail(e)),
            Observable.of(push("/oops"))
          );
        })
      );
    })
  );

export const savePolitics = (action$) =>
  action$.pipe(
    ofType("START_SAVE_POLITICS_DATA"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.savePoliticsTermsHC({
          body: {
            meta: {},
            data: {
              attributes: {
                last_policy: {
                  id: action.payload.politicsId
                },
                last_term: {},
                last_hc: {}
              }
            }
          }
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(getUserInfo()),
            Observable.of(
              ModalActions.setConditionalModalState(
                true,
                "ThanksPoliticsTermsHCModal",
                {
                  message: "politics.thanks"
                }
              )
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.concat(
              Observable.of(LoginActions.loginReset()),
              Observable.of(push("/"))
            );
          }

          return Observable.concat(
            Observable.of(politicsTermsHCFail(e)),
            Observable.of(push("/oops"))
          );
        })
      )
    )
  );

export const saveTerms = (action$) =>
  action$.pipe(
    ofType("START_SAVE_TERMS_DATA"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.savePoliticsTermsHC({
          body: {
            meta: {},
            data: {
              attributes: {
                last_term: {
                  id: action.payload.termsId
                },
                last_policy: {},
                last_hc: {}
              }
            }
          }
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(getUserInfo()),
            Observable.of(
              ModalActions.setConditionalModalState(
                true,
                "ThanksPoliticsTermsHCModal",
                {
                  message: "terms.thanks"
                }
              )
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.concat(
              Observable.of(LoginActions.loginReset()),
              Observable.of(push("/"))
            );
          }

          return Observable.concat(
            Observable.of(politicsTermsHCFail(e)),
            Observable.of(push("/oops"))
          );
        })
      )
    )
  );

export const saveHC = (action$) =>
  action$.pipe(
    ofType("START_SAVE_HC_DATA"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.savePoliticsTermsHC({
          body: {
            meta: {},
            data: {
              attributes: {
                last_hc: {
                  id: action.payload.hcId
                },
                last_term: {},
                last_policy: {}
              }
            }
          }
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(getUserInfo()),
            Observable.of(
              ModalActions.setConditionalModalState(
                true,
                "ThanksPoliticsTermsHCModal",
                {
                  message: "creditHistory.thanks"
                }
              )
            )
          )
        ),
        catchError((e) => {
          if (e.statusCode === 401) {
            return Observable.concat(
              Observable.of(LoginActions.loginReset()),
              Observable.of(push("/"))
            );
          }

          return Observable.concat(
            Observable.of(politicsTermsHCFail(e)),
            Observable.of(push("/oops"))
          );
        })
      )
    )
  );

export default combineEpics(
  getPoliticsTermsHC,
  savePolitics,
  saveTerms,
  saveHC
);
