import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import "./MyProfile.scss";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ContactDataAccordion from "components/ContactDataAccordion/ContactDataAccordion";
import { AlertForm } from "components/AlertForm";
import AlertFormType from "lib/models/alertFormType";

export type IProps = {
  disabledEdit?: boolean;
};

const MyProfile = ({
  disabledEdit = true
}: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleBack = () => {
    dispatch(push("/"));
  };
  const showAlert = false;

  // TODO traer informacion desde API
  const financialInformation = {
    "attributes": {
      "equity": "150000000",
      "assets": "20000000",
      "monthly_income": "5000000",
      "monthly_expenses": "1800000",
      "profit": "50",
      "liabilities": "51"
    }
  }

  const validatedFinancialInformation = financialInformation.attributes.equity <= financialInformation.attributes.assets;

  return (
    <div className="profile-container">
      <div className="profile-content">
        <button id="profile-go-back" className="profile-go-back" onClick={handleBack}>
          <div className="profile-content-button">
            <FontAwesomeIcon
              className="profile-content-button__icon"
              icon={faChevronLeft} />
            <p>Volver</p>
          </div>
        </button>
        <div className="profile">
          <div className="title-info-profile">
            <p className="title-profile">{t("header.profile")}</p>
            <p className="description-subtitle-profile">{t("myProfile.subtitle")}</p>
          </div>
          <AlertForm 
              visible={validatedFinancialInformation}
              copy={`${t("myProfile.financialInformationError")}`}
              background={AlertFormType.WARNING.background}
          />
          <div className="space"></div>
          <ContactDataAccordion 
              enabled={!showAlert}
          />
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
