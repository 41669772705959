/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Container, Description, Icon } from "./components";
type Props = {
  link: string;
  linkText: string;
  icon: JSX.Element;
  description: string;
  "data-testid": string;
};
export const FooterLink = ({
  icon,
  link,
  linkText,
  description,
  ...props
}: Props & React.HTMLAttributes<HTMLElement>) => {
  const newProps = { ...props };
  newProps["data-testid"] = newProps["data-testid"] || "footer-link";
  return (
    <Container {...newProps}>
      <Icon data-testid={`${newProps["data-testid"]}-icon`}>{icon}</Icon>
      <Description>
        <a
          data-testid={`${newProps["data-testid"]}-href`}
          href={link}
          rel="noreferrer"
          target="_blank"
        >
          {linkText}
        </a>
        {description}
      </Description>
    </Container>
  );
};
