import { LightTheme } from "@bvcco/a2censo-component-lib";

const theme = {
  altoTwo: "#CECECE",
  altoForest: "#dfdfdf",
  galleryAtmosphere: "#ECECEC",
  blackSqueeze: "#F2F7FB",
  silverNow: "#C4C4C4",
  neutral80: "#282828",
  fiordTwo: "#3F4960",
  silverStart: "#BBBBBB",
  abbey: "#51595E",
  black: "#000000",
  cornflowerBluee: "#7D7BFA",
  backTagGray: "#e7e7e7",
  brightTurquoise: "#0bbbef",
  brightTurquoiseTransparent: "#B1E4F6",
  darkestBlue: "#121b2a",
  turquoiseBlue: "#52EFED",
  boneGray: "#f2f2f2",
  dodgerBlue: "#496CFF",
  brightTurquoiseDisabled: "#80daf4",
  lightTurquoise: "#eaf6f5",
  congressBlue: "#004997",
  congressBlueVariant: "#00489D",
  lightComet: "#5D6176",
  regalBlue: "#04366B",
  congressBlueTransparent: "rgba(0,73,151,0.4)",
  tuatara: "#31302f",
  PureOrmMstlyPureCyan: "#00fff0",
  gunPowder: "#464A60",
  springGreen: "rgba(57, 251, 191, 1)",
  transparentSpringGreen: "rgba(57, 251, 191, .2)",
  orange: "#ff8400",
  transparentGray: "rgba(200, 200, 201, 0.39)",
  blueUserDataGradient:
    "linear-gradient(137.48deg, #6262f9 -27.08%, #39fbbf 188.15%)",
  transparentWhite: "rgba(255, 255, 255, 0.6)",
  opacityWhite: "rgba(255,255,255,0.86)",
  middleGray: "rgb(166, 166, 169)",
  gray: "#f5f6f8",
  grayGradient: "linear-gradient(180deg, #FFFFFF 0%, #EAEAEA 100%)",
  silver: "#BBBBBB",
  transparentBlack: "rgba(0,0,0,0.38)",
  darkGray: "#babdca",
  veryDarkGray: "#575757",
  lineGray: "#cfd1da",
  backGray: "#d8d8d8",
  white: "#ffffff",
  brightBlue: "#ddf1f8",
  darkCerulean: "#0FA5E4",
  cerulean: "#10aced",
  red: "#E11A2C",
  alabaster: "#F7F7F7",
  grayBlue: "#f3fbfe",
  lightPurple: "#6262F9",
  boxShadowE0: "#e0e2e4",
  borderCertificate: "#a7a7a7",
  blackLabelTax: "#535353",
  springGreenOrigin: "rgba(0, 255, 102, 0.24)",
  transparentRed: "rgba(225,26,44,0.4)",
  smartphone: "@media screen and (max-width: 480px) ",
  smartphone375: "@media screen and (max-width: 375px) ",
  smartphone425: "@media screen and (min-width: 425px) and (max-width: 480px)  ",
  smartphoneSmall: "@media screen and (max-width: 320px) ",
  smartphoneMedium:
    "@media screen and (min-width: 360px) and (max-width: 480px) ",
  smallTablet: "@media screen and (max-width: 673px) ",
  tablet: "@media screen and (max-width: 768px) ",
  tabletMinWidth: "@media screen and (min-width: 768px) ",
  desktop: "@media screen and (max-width: 1024px) ",
  desktopMin: "@media screen and (min-width: 1024px) ",
  bigTablet: "@media screen and (max-width: 1450px) ",
  mediumScreens: "@media (min-width: 800px) ",
  desktopMinWidth990px: "@media screen and (min-width: 990px) ",
  desktopMinWidth1200px: "@media screen and (min-width: 1200px)",
  desktopMaxWidth900px: "@media screen and (max-width: 900px)",
  desktopMinHeight800px: "@media screen and (min-height: 800px)",
  iPhoneX:
    "@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3)",
  iPhone8Plus:
    "@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3)",
  iPhone6s:
    "@media screen and (min-device-width: 375px) and (max-device-height: 667px)",
  verySmallDevices:
    "@media screen and (min-device-width: 360px) and (max-device-height: 640px)",
  blueGray: "#97a4b1",
  tooltipColorFont: "#A1AEBA",
  nevada: "#6D7278",
  nevadaVariant: "#646d75",
  opaquePurple: "#3a4972",
  purple: "#4568E0",
  ironSideGray: "#686664",
  poloBlue: "#91AFD0",
  azure: "#3361A8",
  violetRed: "#fb3d76",
  violetRedTrans15: "rgba(251, 61, 118, 0.15)",
  masala: "#494746",
  martinique: "#35395C",
  ironsideGray: "#686664",
  stratos: "#000D3A",
  darkBlue2: "#1D2D49",
  darkBlue3: "#424E9D",
  brightGray2: "#F0F0F0",
  brightGray3: "#686664",
  timeLineRed: "#EF2653",
  timeLineGrey: "#CCDADA",
  timeLineTransparentRed: "rgba(239, 38, 83, 0.15)",
  blueDark: "#00084B",
  grayBright: "#4A4A4A",
  endeavour: "#0153A1",
  dustyGray: "#9B9B9B",
  foscousGray: "#535353",
  whisper: "#F1F2F8",
  gallery: "#EFEFEF",
  blue: "#0BBEF0",
  timeLinePictonBlue: "#39EFED",
  timeLineTransparentPictonBlue: "rgba(57, 239, 237, 0.15)",
  timeLineTransparentBrightTurquoise: "rgba(11, 190, 240, 0.15)",
  pizazz: "#FF9306",
  brightTurquoise2: "#0CD5B5",
  radicalRed: "#FF2160",
  blueRibbon: "#0650FF",
  eastBay: "#363F7A",
  eastBayDark: "#3B4085",
  pizazzRgba: "rgba(255, 147, 6, 0.1)",
  brightTurquoiseRgba: "rgba(12,213,181,0.1)",
  radicalRedRgba: "rgba(255,189,202,0.45)",
  blueRibbonRgba: "rgba(6,31,255,0.1)",
  chicago: "#5D5B59",
  acentuar: "#5D5A6A",
  governorBay: "#3C3CCC",
  min991Max1024:
    "@media only screen and (min-width: 991px) and (max-width: 1024px)",
  min768Max990:
    "@media only screen and (min-width: 768px) and (max-width: 990px)",
  max767: "@media only screen and (max-width: 767px)",
  fuscousGray: "#52514F",
  cornFlower: "#5B7DEF",
  lynch: "#627A92",
  alto: "#CFCFCF",
  turquoise: "#46CDD1",
  curiousBlue: "#3895d3",
  lightRed: "#FFF5F5",
  lightBlue: "#00FFFF",
  royalBlue: {
    light: "#4583E0",
    dark: "#455FE0"
  },
  riverBed: "#475061",
  riverBedLight: "#4750611c",
  titanWhite: "#F0F0FF",
  titanWhiteVariation: "#E8E8FF",
  alabasterVariation: "#F9F9F9",
  portage: "#8989FB",
  mako: "#475061",
  amber: "#FF7D00",
  santasGray: "#999CA9",
  transparentSantas: "rgba(56, 60, 93, 0.5)",
  nepal: "#9aa7c2",
  mercury: "#e4e4e4",
  transparentMartinique: "rgba(56, 60, 93, 0.95)",
  mineShaft: "#3f3f3f",
  wildSand: "#F4F4F4",
  tuataraLight: "#475061",
  blueSky: "#02BBEF",
  blueSkyVariant: "#00BDF5",
  opaqueBlue: "#5888E4",
  athensGray: "#E9E9F2",
  athensGray2: "#F8F9FA",
  comet: "#535B77",
  whiteSmoke: "#F3F3F3",
  darkPurple: "#283472",
  purpleGradient: "linear-gradient(90deg, #4583E0 0%, #455FE0 82.29%)",
  silverChalice: "#acacac",
  ...LightTheme,
  graySuit: "#BABDCA",
  cornflowerBlue: "#6262f9",
  lightGray: "#F5F6F8",
  seashell: "#F1F1F1",
  manatee: "#878A99",
  stormGray: "#737582",
  midGray: "#5D5A6A",
  cyan: "#00FFF0",
  robinsEggBlue: "#02BAC6",
  brightTurquoiseGreen: "#39FBBF",
  /* component-lib */
  fontFamily: {
    primary: "'Nunito', sans-serif",
    secondary: "'Roboto', sans-serif"
  },
  textColors: {
    white: "#fff",
    black: "#000",
    lightGray: "#F5F6F8",
    darkGray: "#babdca",
    red: "#E11A2C"
  },
  bgColors: {
    primary: "#0bbbef",
    secondary: "#004997",
    main: "#fff"
  },
  primary: "#0bbbef",
  secondary: "#004997",
  main: "#fff",
  backgroundColor: "#fff",
  backgroundBlue: "#4E567A",
  nobel: "#b6b6b6",

  colors: {
    ...LightTheme.colors,
    simulator: {
      bgColor: "#02BAC6",
      color: "#02BAC6"
    },
    main: {
      bgColor: "#fff",
      color: "#5C5C5C"
    },
    cornFlowerBlue: {
      bgColor: "#6262F9",
      color: "#6262F9"
    },
    cornFlowerBlueAndWaterloo: {
      bgColor: "#E6E6E6",
      color: "#6F6F93"
    },
    cornFlowerBlueBg: {
      bgColor: "#6262F9",
      color: "#FFFFFF"
    },
    primary: {
      bgColor: "#0bbbef",
      color: "#fff"
    },
    progressBar: {
      bgColor: "#757687",
      color: "#39fbbf"
    },
    primary2: {
      bgColor: "#0bbbef",
      color: "#0bbbef"
    },
    secondary: {
      bgColor: "#004997",
      color: "#fff"
    },
    secondary2: {
      bgColor: "#004997",
      color: "#004997"
    },
    darkGray: {
      bgColor: "#babdca",
      color: "#babdca"
    },
    lynch: {
      bgColor: "#63698D",
      color: "#63698D"
    },
    riverBed: {
      bgColor: "#475061",
      color: "#475061"
    },
    white: {
      bgColor: "#ffffff",
      color: "#ffffff"
    },
    whiteManatee: {
      bgColor: "#ffffff",
      color: "#878A99"
    },
    whiteTransparent: {
      bgColor: "rgba(255, 255, 255, 0.5)",
      color: "#ffffff"
    },
    black: {
      bgColor: "#000000",
      color: "#000000"
    },
    whiteBrightTurquoise: {
      bgColor: "#ffffff",
      color: "#0bbbef"
    },
    lightGray: {
      bgColor: "#F5F6F8",
      color: "#F5F6F8"
    },
    whiteAndRoyalBlue: {
      bgColor: "#fff",
      color: "#4572E0"
    },
    brightTurquoise: {
      bgColor: "#0bbbef",
      color: "#0bbbef"
    },
    tuatara: {
      color: "#31302f",
      bgColor: "#31302f"
    },
    congressBlue: {
      color: "#004997",
      bgColor: "#004997"
    },
    whiteAndGray: {
      bgColor: "#fff",
      color: "#475061"
    },
    mercuryAndGray: {
      bgColor: "#EAE9E9",
      color: "#475061"
    },
    red: {
      bgColor: "#E11A2C",
      color: "#E11A2C"
    },
    tooltipColor: {
      bgColor: "#62618b",
      color: "#62618b"
    },
    darkGrayHeading: {
      bgColor: "#fff",
      color: "#475061"
    },
    mineShaft: {
      bgColor: "#3f3f3f",
      color: "#3f3f3f"
    },
    rhino: {
      bgColor: "#2A365C",
      color: "#FFF"
    },
    CloudBurst: {
      bgColor: "#1B2648",
      color: "#FFF"
    },
    shuttleGray: {
      bgColor: "#5D6373",
      color: "#5D6373"
    },
    CornflowerBlue: {
      bgColor: "#5B89F7",
      color: "#5B89F7"
    },
    alto: {
      bgColor: "#CFCFCF",
      color: "#CFCFCF"
    },
    dustyGray: {
      bgColor: "#9B9B9B",
      color: "#9B9B9B",
      dark: "#979797"
    },
    comet: {
      bgColor: "#525A6B",
      color: "#525A6B"
    },
    regentGray: {
      bgColor: "#858C9A",
      color: "#858C9A"
    },
    cometTwo: {
      color: "#535B77",
      bgColor: "#fff"
    },
    regalBlueWhite: {
      bgColor: "#ffffff",
      color: "#04366B"
    },
    cornflowerBlueWhite: {
      bgColor: "#ffffff",
      color: "#6262f9"
    },
    backGray: {
      bgColor: "#d8d8d8",
      color: "#d8d8d8"
    },
    blue: {
      bgColor: "#0BBEF0",
      color: "#0BBEF0"
    },
    fiord: {
      bgColor: "#414965",
      color: "#414965"
    },
    silverChalice: {
      bgColor: "#A2A2A2",
      color: "#A2A2A2"
    },
    fog: {
      bgColor: "#D9D9FF",
      color: "#D9D9FF"
    },
    cornflowerBlueWhiteInverse: {
      bgColor: "#6262f9",
      color: "#fff"
    },
    eastBay: { bgColor: "#363F7A", color: "#363F7A" },
    transparent: {
      color: "transparent",
      bgColor: "transparent"
    },
    wePeep: {
      bgColor: "#F5D1D1",
      color: "#F5D1D1"
    },
    gunPowder: {
      bgColor: "#464A60",
      color: "#464A60"
    },
    concrete: {
      bgColor: "#F2F2F2",
      color: "#F2F2F2"
    },
    scorpion: {
      bgColor: "#5C5C5C",
      color: "#5C5C5C"
    },
    alabaster: {
      bgColor: "#F7F7F7",
      color: "#F7F7F7"
    },
    stormGray: { bgColor: "#737582", color: "#737582" },
    acentuar: { bgColor: "#5D5A6A", color: "#5D5A6A" },
    dolphin: {
      bgColor: "#62618B",
      color: "#62618B"
    },
    royalBlue: {
      color: "#455FE0",
      bgColor: "#455FE0"
    },
    azalea: {
      color: "#F9D5D5",
      bgColor: "#F9D5D5"
    },
    alabasterTonalyty: {
      bgColor: "#FAFAFA",
      color: "#FAFAFA"
    }
  },
  font: {
    size: {
      smaller: "10px",
      small: "12px",
      less_small: "14px",
      medium: "16px",
      medium18: "18px",
      large: "20px",
      large22: "22px",
      large23: "23px",
      large24: "24px",
      large26: "26px",
      large36: "36px",
      large40: "40px",
      h1: "32px",
      h2: "24px",
      h3: "18.72px",
      large28: "28px"
    },
    family: {
      primary: "'Nunito', sans-serif",
      secondary: "'Roboto', sans-serif"
    }
  },
  breakpoints: {
    minWidth320px: "@media screen and (min-width: 320px) ",
    smartphone: "@media screen and (min-width: 480px) ",
    whenSmartphone: "@media screen and (max-width: 480px) ",
    tabletMinWidth: "@media screen and (min-width: 769px) ",
    tabletMinWidth769px: "@media screen and (min-width: 769px) ",
    desktopMinWidth990px: "@media screen and (min-width: 990px) ",
    desktopMinWidth900px: "@media screen and (min-width: 900px)",
    desktopMinWidth1048px: "@media screen and (min-width: 1048px) ",
    desktopMinWidth1200px: "@media screen and (min-width: 1200)",
    bigTabletMinWidth: "@media screen and (min-width: 1450px) ",
    desktopMedium: "@media (max-width: 1400px) ",
    desktopSmall: "@media (max-width: 1024px) ",
    mediumScreens: "@media (min-width: 800px) ",
    intervalMobileSmall:
      "@media all and (max-width: 380px) and (min-width: 0px) ",
    intervalMobileMedium:
      "@media all and (max-width: 780px) and (min-width: 380px) ",
    intervalDesktopSmall:
      "@media all and (max-width: 1025px) and (min-width: 780px) ",
    intervalDesktopMedium:
      "@media all and (max-width: 1400px) and (min-width: 1025px) ",
    tabletsLandscape:
      "@media (min-width: 720px) and (max-width: 1366px) and (orientation: landscape) ",
    tabledPortrait: "@media (min-width: 768px) and (max-width: 1024px) ",
    smallSmartphone: "@media (max-width: 380px)",
    iphone678Plus: "@media screen and (max-width: 414px) ",
    mobile: "@media screen and (max-width: 768px) "
  }
};

export default theme;
