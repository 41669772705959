import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setInternetAlert: ["showAlert", "logged"]
});

export const internetAlertTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  showInternetAlert: false,
  wasLogged: false
};

const setInternetAlert = (state: Object, { showAlert, logged }: any) => ({
  ...state,
  showInternetAlert: showAlert,
  wasLogged: logged
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_INTERNET_ALERT]: setInternetAlert
});
