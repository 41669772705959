import React from "react";
import "./SimpleText.styles.scss";

type IProps = {
  campaignDetail: {
    id?: number;
    content?: string;
    second_content?: string;
  };
};

const SimpleText = ({
  campaignDetail: { id, content, second_content }
}: IProps) => {
  const contentToDisplay =
    content && content.split("\n").filter((line) => line != "\r" && line != "");
  const seccondContentToDisplay =
    second_content &&
    second_content.split("\n").filter((line) => line != "\r" && line != "");

  const isInnerHTML = (value: string): boolean => {
    return value.includes("<b>");
  };

  return (
    <div className="simple-text">
      <div className="simple-text__column">
        {contentToDisplay &&
          contentToDisplay.map((line: string, i: number) => (
            <div key={`content_${id}`} className="simple-text__container">
              {isInnerHTML(line) ? (
                <p
                  className="simple-text__content"
                  dangerouslySetInnerHTML={{
                    __html: line
                  }}
                />
              ) : (
                <p className="simple-text__content">{line}</p>
              )}
              {i < contentToDisplay.length - 1 && <br />}
            </div>
          ))}
      </div>
      {second_content && second_content != "" && (
        <div className="simple-text__column">
          <>
            {seccondContentToDisplay &&
              seccondContentToDisplay.map((line: string, i: number) => (
                <div
                  key={`second_content_${id}`}
                  className="simple-text__container"
                >
                  {isInnerHTML(line) ? (
                    <p
                      className="simple-text__content"
                      dangerouslySetInnerHTML={{
                        __html: line
                      }}
                    />
                  ) : (
                    <p className="simple-text__content">{line}</p>
                  )}
                  {i < seccondContentToDisplay.length - 1 && <br />}
                </div>
              ))}
          </>
        </div>
      )}
    </div>
  );
};

export default SimpleText;
