const { Serializer } = require("jsonapi-serializer");

module.exports = {
  ChangeStageSerializer: (meta = {}) =>
    new Serializer("store", {
      meta,
      pluralizeType: false,
      keyForAttribute: "snake_case",
      attributes: ["from", "documentsToLoad", "documentsToUpdate"],
      documentsToUpdate: {
        ref: "documentsToUpdate",
        attributes: ["id", "name", "state"]
      },
      documentsToLoad: {
        ref: "documentsToLoad",
        attributes: ["id", "name", "state"]
      }
    })
};
