import styled from "styled-components";
import { FormRow, InputContainer } from "../../../components/Form/Styles";
import SelectField from "../../../components/Select/Select";

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 2% auto;
  flex-direction: column;

  ${({ theme }) => theme.smartphone} {
    margin-top: 5%;
    width: 90%;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  ${({ theme }) => theme.smartphone} {
    flex-direction: column;
  }
`;

export const FilterFormRow = styled(FormRow)`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => (width ? `${width}%` : "33%")};
  height: 50px;
  overflow: hidden;
  ${({ theme }) => theme.smartphone} {
    width: 100%;
  }
`;

export const FilterInputContainer = styled(InputContainer)`
  ${(props) =>
    props.background ? `background-color: ${props.theme.white};` : ""}
  width: ${({ width }) => (width ? `${width}%` : "100%")};
  height: 50px;
  border-radius: 5px;
  ${({ theme }) => theme.smartphone} {
    width: ${({ widthSmartphone }) =>
      widthSmartphone ? `${widthSmartphone}%` : "100%"};
  }
`;

export const Select = styled(SelectField)`
  height: 50px;
  select {
    color: ${({ theme }) => theme.darkGray};
    padding: 0 30px;
  }
`;

export const Spacer = styled.div`
  width: 10px;
  ${({ theme }) => theme.smartphone} {
    display: none;
  }
`;

export const LinkText = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.brightTurquoise};
  text-decoration: underline;
  margin-top: 20px;
  ${({ theme }) => theme.smartphone} {
    font-size: 15px;
  }
`;

export const Icon = styled.img`
  width: 20px;
  position: absolute;
  right: 35px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const Button = styled.button`
  border: none;
  background-color: ${(props) => props.theme.white};
  border-radius: 25px;
  height: 50px;
  width: 200px;
  font-family: "Nunito";
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.brightTurquoise};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  ${({ theme }) => theme.smartphone} {
    height: 45px;
    font-size: 18px;
  }
`;
