import styled from "styled-components";

type Props = {
  backgroundColor?: string;
};

export const ItemWrapper = styled.div<Props>`
  display: flex;
  max-width: 476px;
  padding: 17px 26px 17px 0px;
  border-radius: 56px;
  background-color: ${({ backgroundColor }) => backgroundColor || "#fafafa"};
  align-items: center;
  font-family: ${({ theme }) =>
    theme ? theme.font.family.primary : "initial"};
  ${({ theme }) => theme.breakpoints.mobile} {
    max-width: 100%;
  }
`;
