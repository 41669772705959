import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { Heading, Paragraph } from "@bvcco/a2censo-component-lib";
import { formatNumber, formatResult } from "../../lib/utils";
import { calculateSecondsDiff, formatToLocalDateTime } from "lib/utils/date";
import { useCountDown } from "hooks";

import campaignModel from "../../lib/models/campaign.model";

import ModalActions from "../../redux/reducers/modal.reducer";
import {
  Wrapper,
  Row,
  Header,
  CampaignTitle,
  Input,
  Currency,
  InputContainer,
  InfoCol,
  Padding,
  ResultContainer,
  Col,
  Label,
  Amount,
  SmallLabel,
  InfoValue,
  InfoLabel,
  InputLabel,
  InfoContainer,
  ErrorIcon,
  TooltipContainer,
  TooltipText,
  SmallAmount,
  LeftHeader,
  RightHeader,
  RightHeaderDetail,
  RightHeaderDetailValue,
  RightHeaderDetailLabel,
  RightHeaderDetailWrapper,
  DownArrow,
  SuperHeader,
  CloseButton,
  Card,
  RecieveContainer,
  RecieveCol,
  Body,
  SeeVideoPanel,
  LinkSeeVideo,
  ButtonPanel,
  InfoColHalfWidth,
  InfoColFullWidth,
  InfoColHalfWidthRight,
  Hr,
  Button
} from "./Styles";

import { CalcEarnings } from "../../lib/calculations";
import conf from "../../config/config";

import SimulatorCampaignActions from "../../redux/reducers/simulator-campaign.reducer";
import getAmountAbbreviation from "../../lib/utils/amount-abbreviation";
import excelIcon from "../../assets/images/excel@3x.png";
import closeIcon from "../../assets/icons/Close.png";
import { startInvestFlow, canIInvest } from "../../redux/epics/invest";
import User from "../../lib/models/user.model";
import { VideoModal } from "../shared";
import CampaignActions from "redux/store/campaigns/reducer";

const Simulator = ({ campaign, isFromSimuladorCampaigns }) => {
  const [crb, setCRB] = useState("0000000,00");
  const [total, setTotal] = useState("0000000,00");
  const [earnings, setEarnings] = useState("0000000,00");
  const [gracePeriod, setGracePeriod] = useState("0000000,00");
  const [investmentValidity, setInvestmentValidity] = useState(false);
  const [touchedInput, setTouchedInput] = useState(false);
  const [isOpenVideo, setOpenVideo] = useState(false);
  const [investment, setInvestment] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { minimumInvestment, multiple } = conf;
  const { loadingFile } = useSelector(
    ({ simulatorCampaign }) => simulatorCampaign
  );
  const cmsDataInvestmentSimulator = useSelector(
    (state) => state.pageData.home.homeData.investment_simulator
  );
  const learningVideo = cmsDataInvestmentSimulator.learning_video;
  const login = { idToken: sessionStorage.getItem("idToken") };
  const refreshToken = sessionStorage.getItem("refreshToken");
  const hasSession = !!(login.idToken && refreshToken);

  const userState = useSelector(({ user }) => ({ ...user }));

  const personType = hasSession && userState.userInfo.user.person_type;
  const isPyme = personType === User.type.pyme;

  let userPayload = {};
  if (userState.userInfo) {
    const { user } = userState.userInfo;
    userPayload = {
      personType: user.person_type,
      documentNumber: user.document_number,
      documentType: User.documentType[user.document_type],
      name: user.name,
      lastName: user.last_name,
      userId: user.id
    };
  }

  const handleChange = ({ target: { value } }) => {
    const formattedValue = Number(
      value
        .toString()
        .split(".")
        .join("")
    );

    const valuesAsString = String(formattedValue);
    if (valuesAsString.length < 21 && valuesAsString.match(/^[0-9]*$/)) {
      const isValidInvestment =
        formattedValue >= minimumInvestment && formattedValue % multiple === 0;

      setInvestmentValidity(isValidInvestment);
      setInvestment(formattedValue);

      if (isValidInvestment) {
        const results = CalcEarnings(campaign, formattedValue);
        setCRB(results[0]);
        setTotal(results[1]);
        setEarnings(results[2]);

        if (results[3]) {
          setGracePeriod(results[3]);
        } else {
          setGracePeriod("No aplica");
        }
      } else {
        setCRB("0000000,00");
        setTotal("0000000,00");
        setEarnings("0000000,00");
        setGracePeriod("0000000,00");
      }
    }
  };

  const handleBlur = () => setTouchedInput(true);

  const goSimulatorCampaignsModal = () => {
    if (isFromSimuladorCampaigns) {
      const cleanCampaigns = [];
      dispatch(CampaignActions.setCampaigns(cleanCampaigns));
      dispatch(SimulatorCampaignActions.callOpenSimulatorCampaignsModal());
    }
  };

  const closeModal = () => {
    if (isFromSimuladorCampaigns) {
      return goSimulatorCampaignsModal();
    }

    dispatch(ModalActions.setConditionalModalState(false));
  };

  const getCampaignValue = (attr) => {
    if (campaign[attr]) {
      const month = campaign[attr] === 1 ? "mes" : "meses";
      return `${campaign[attr]} ${month}`;
    }

    return "No aplica";
  };

  const formatNumberToGeekNotation = (number) => {
    const numberInMillions = Number((number / 1000000).toFixed(2)) * 1000000;
    return getAmountAbbreviation(numberInMillions);
  };
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const secondsUntilPublish = useCountDown(
    calculateSecondsDiff(
      campaign.server_time ? campaign.server_time : new Date(),
      campaign.available_investment_date
        ? formatToLocalDateTime(campaign.available_investment_date)
        : new Date()
    )
  );

  const isPublished = secondsUntilPublish === 0;

  return (
    <Wrapper data-testid="campaign-modal-simulator">
      <SuperHeader>
        <Heading hieranchy="large_secondary" fontWeight="bold" color="primary">
          {t("simulator.listCampaign.superTitle")}
        </Heading>
        <CloseButton
          data-testid="simulator-back-button"
          src={closeIcon}
          onClick={closeModal}
        />
      </SuperHeader>
      <Card>
        <Header
          data-testid="header"
          id="header"
          onClick={goSimulatorCampaignsModal}
        >
          <LeftHeader>
            <Heading fontWeight="bold" hieranchy="quaternary" color="white">
              {`${t("simulator.campaign")}`}
            </Heading>
          </LeftHeader>
          <RightHeader>
            <CampaignTitle>
              <Heading fontWeight="bold" hieranchy="tertiary">
                {campaign.name}
              </Heading>
            </CampaignTitle>
            <RightHeaderDetailWrapper>
              <RightHeaderDetail>
                <RightHeaderDetailLabel>
                  <Paragraph size="small" fontWeight="600">
                    {`${t("simulator.collectedAmount")}`}
                  </Paragraph>
                </RightHeaderDetailLabel>
                <RightHeaderDetailValue>
                  <Paragraph size="medium" fontWeight="800">
                    {`COP ${formatNumberToGeekNotation(campaign.investedAmount)}`}
                  </Paragraph>
                </RightHeaderDetailValue>
              </RightHeaderDetail>
              <RightHeaderDetail>
                <RightHeaderDetailLabel>
                  <Paragraph size="small" fontWeight="600">
                    {`${t("simulator.goal")}`}
                  </Paragraph>
                </RightHeaderDetailLabel>
                <RightHeaderDetailValue>
                  <Paragraph size="medium" fontWeight="800">
                    {`COP ${formatNumberToGeekNotation(campaign.requestedAmount)}`}
                  </Paragraph>
                </RightHeaderDetailValue>
              </RightHeaderDetail>
            </RightHeaderDetailWrapper>
            <DownArrow
              src="/assets/images/multimedia-option@3x.png"
              alt="Down arrow"
            />
          </RightHeader>
        </Header>
        <Body>
          <Padding>
            <InfoContainer>
              <InfoCol>
                <InfoLabel>
                  <Paragraph size="less_small">
                    {`${t("simulator.fixedFee")}`}
                  </Paragraph>
                </InfoLabel>
                <InfoValue>
                  <Paragraph size="large" fontWeight="bold">
                    {`${campaign.interestRate}% ${t("simulator.ea")}`}
                  </Paragraph>
                </InfoValue>
              </InfoCol>

              <InfoCol>
                <InfoLabel>
                  <Paragraph size="less_small">
                    {`${t("simulator.term")}`}
                  </Paragraph>
                </InfoLabel>
                <InfoValue>
                  <Paragraph size="large" fontWeight="bold">
                    {` ${campaign.termInMonths}
                    ${t("simulator.months")}`}
                  </Paragraph>
                </InfoValue>
              </InfoCol>

              <InfoCol>
                <InfoLabel>
                  <Paragraph size="less_small">
                    {t("simulator.paymentPlusCapital")}
                  </Paragraph>
                </InfoLabel>
                <InfoValue>
                  <Paragraph size="large" fontWeight="bold">
                    {`${campaignModel.paymentFrequency(
                      campaign.interestPayment
                    )}`}
                  </Paragraph>
                </InfoValue>
              </InfoCol>

              <InfoColHalfWidth>
                <InfoLabel>
                  <Paragraph size="less_small">
                    {t("simulator.interestGracePeriod")}
                  </Paragraph>
                </InfoLabel>
                <InfoValue>
                  <Paragraph size="large" fontWeight="bold">
                    {getCampaignValue("interest_grace_period")}
                  </Paragraph>
                </InfoValue>
              </InfoColHalfWidth>

              <InfoColHalfWidthRight>
                <InfoLabel>
                  <Paragraph size="less_small">
                    {t("simulator.capitalPayment")}
                  </Paragraph>
                </InfoLabel>
                <InfoValue>
                  <Paragraph size="large" fontWeight="bold">
                    {`${campaignModel.paymentFrequency(
                      campaign.capitalPayment
                    )}`}
                  </Paragraph>
                </InfoValue>
              </InfoColHalfWidthRight>

              <InfoColFullWidth>
                <InfoLabel>
                  <Paragraph size="less_small">
                    {t("simulator.capitalGracePeriod")}
                  </Paragraph>
                </InfoLabel>
                <InfoValue>
                  <Paragraph size="large" fontWeight="bold">
                    {getCampaignValue("capital_grace_period")}
                  </Paragraph>
                </InfoValue>
              </InfoColFullWidth>
            </InfoContainer>
            <InputContainer>
              <InputLabel>
                <Heading hieranchy="quaternary" color="primary2">
                  {t("simulator.howMuch")}
                </Heading>
              </InputLabel>
              <Row>
                <Currency>COP</Currency>
                <Input
                  type="text"
                  placeholder={`${t(
                    "simulator.minimum"
                  )} COP ${minimumInvestment
                    .toString()
                    .replace(/\B(?=(\d{3})*(?!\d))/g, ".")}`}
                  onChange={handleChange}
                  value={formatNumber(investment)}
                  onBlur={handleBlur}
                  id="simulator-input"
                  data-testid="simulator-input"
                />
              </Row>
              {!investmentValidity && touchedInput && (
                <>
                  <ErrorIcon
                    data-tip={true}
                    data-for="simulator-input-error"
                    src="/assets/images/error.png"
                    alt="error"
                  />
                  <ReactTooltip
                    className="error-tooltip__back"
                    id="simulator-input-error"
                    place="left"
                    type="light"
                    effect="solid"
                    globalEventOff={isMobile ? "click" : undefined}
                  >
                    <TooltipContainer>
                      <TooltipText>
                        {t("simulator.investmentError")}
                      </TooltipText>
                    </TooltipContainer>
                  </ReactTooltip>
                </>
              )}

              {!investmentValidity && isMobile && touchedInput && (
                <>
                  <ErrorIcon src="/assets/images/error.png" alt="error" />
                  <TooltipContainer>
                    <TooltipText>{t("simulator.investmentError")}</TooltipText>
                  </TooltipContainer>
                </>
              )}
            </InputContainer>
          </Padding>
          <ResultContainer>
            <Col>
              <Label>
                <Heading
                  hieranchy="quaternary"
                  color="primary2"
                  fontWeight="bold"
                >
                  {t("simulator.total")}
                </Heading>
              </Label>
              <Amount>
                <Paragraph size="h1" fontWeight="bold">
                  {`COP ${formatResult(total)}`}
                </Paragraph>
              </Amount>
              <SmallLabel>
                <Paragraph size="less_small" fontWeight="bold" color="primary2">
                  {t("simulator.earn")}
                </Paragraph>
              </SmallLabel>
              <SmallAmount>
                <Paragraph size="medium">
                  {`COP ${formatResult(earnings)}`}
                </Paragraph>
              </SmallAmount>
            </Col>
          </ResultContainer>
          <Padding>
            <RecieveContainer>
              <RecieveCol>
                <SmallLabel>
                  <Paragraph
                    className="label_receive"
                    size="less_small"
                    fontWeight="800"
                    color="primary2"
                  >
                    {t("simulator.periodicFeeCapital")}
                  </Paragraph>
                </SmallLabel>
                <SmallAmount>
                  <Paragraph size="medium" color="main" fontWeight="800">
                    {`COP ${formatResult(crb)}`}
                  </Paragraph>
                </SmallAmount>
              </RecieveCol>
              <RecieveCol>
                <SmallLabel>
                  <Paragraph
                    className="label_receive"
                    size="less_small"
                    fontWeight="800"
                    color="main"
                  >
                    {t("simulator.periodicFeeGrace")}
                  </Paragraph>
                </SmallLabel>
                <SmallAmount>
                  <Paragraph size="medium" color="primary2">
                    {gracePeriod === "No aplica"
                      ? gracePeriod
                      : `COP ${formatResult(gracePeriod)}`}
                  </Paragraph>
                </SmallAmount>
              </RecieveCol>
            </RecieveContainer>

            <ButtonPanel>
              <Button
                className="to-hide"
                type="button"
                outlined={true}
                rounded={true}
                disabled={loadingFile ? true : !investmentValidity}
                fontWeight="bold"
                id="detail_campaign"
                color="primary2"
                onClick={() => {
                  if (investment) {
                    dispatch(
                      SimulatorCampaignActions.callGetFileSimulator({
                        campaignId: campaign.id,
                        investmentAmount: investment
                      })
                    );
                  }
                }}
                icon={{
                  position: "right",
                  icon: <img src={excelIcon} alt="file" />
                }}
              >
                {t("simulator.downloadDetail")}
              </Button>
              {campaignModel.isActive(campaign) && !isPyme && (
                <Button
                  type="button"
                  rounded={true}
                  fontWeight="bold"
                  id="invest_campaign"
                  onClick={() => {
                    dispatch(ModalActions.setConditionalModalState(false));
                    const action =
                      !userState.userInfo || !hasSession
                        ? startInvestFlow(campaign.id)
                        : canIInvest(campaign.id, userPayload);
                    dispatch(action);
                  }}
                  disabled={!isPublished}
                >
                  {t("simulator.invest")}
                </Button>
              )}
            </ButtonPanel>
            <Hr className="to-hide" />
            <SeeVideoPanel className="to-hide">
              <Paragraph className="see_video_text" size="h3" fontWeight="600">
                {`${t("simulator.seeVideoLearn")}`}
              </Paragraph>
              &nbsp;
              <LinkSeeVideo
                data-testid="open-video-simulator"
                onClick={() => setOpenVideo(true)}
              >
                <Paragraph size="h3" fontWeight="bold" color="primary2">
                  {`${t("simulator.seeVideo")}`}
                </Paragraph>
              </LinkSeeVideo>
            </SeeVideoPanel>
          </Padding>
        </Body>
      </Card>
      <VideoModal
        data-testid="modal-video-simulator"
        open={isOpenVideo}
        onClose={() => setOpenVideo(false)}
        modalSize="medium"
        url={learningVideo}
      />
    </Wrapper>
  );
};

Simulator.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.number.isRequired,
    campaignName: PropTypes.string.isRequired,
    termInMonths: PropTypes.number.isRequired,
    interestRate: PropTypes.number.isRequired,
    logoPath: PropTypes.string.isRequired,
    campaignId: PropTypes.number.isRequired,
    interestPayment: PropTypes.number.isRequired,
    capitalPayment: PropTypes.number.isRequired,
    interestGracePeriod: PropTypes.number.isRequired,
    capitalGracePeriod: PropTypes.number.isRequired,
    requestedAmount: PropTypes.string.isRequired,
    investedAmount: PropTypes.number.isRequired
  }).isRequired,
  isFromSimuladorCampaigns: PropTypes.bool
};

Simulator.defaultProps = {
  isFromSimuladorCampaigns: false
};

export default Simulator;
