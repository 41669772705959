import styled from "styled-components";
import EquityModalBackground from "assets/images/equity/modal-background.png";
import EquityModalShadow from "assets/images/equity/modal-shadow.png";

export const Background = styled.div`
  background: url(${EquityModalBackground}), url(${EquityModalShadow}) no-repeat;
  background-position: bottom, 0px 83px;
  background-size: cover;
  height: 100%;
  border-radius: 0 0 12px 12px;

  ${({ theme }) => theme.smartphoneMedium} {
    background-position: -50px 0px, -60px 113px;
  }

  ${({ theme }) => theme.iPhone6s} {
    height: 545px;
  }
  ${({ theme }) => theme.iPhoneX} {
    height: 540px;
  }
`;
