import React from "react";
import { Container, Icon } from "./components";
import { Paragraph } from "../Paragraph";
import countDown from "../../../assets/icons/count-down.svg";
import { useTranslation } from "react-i18next";

type Props = {
  dataTestId?: string;
};

export const ComingSoon = ({ dataTestId = "coming-soon" }: Props) => {
  const { t } = useTranslation();

  return (
    <Container data-testid={dataTestId}>
      <Paragraph size="medium18" fontWeight="800">
        {t("campaign.comingSoon")}
      </Paragraph>
      <Icon src={countDown} />
    </Container>
  );
};
