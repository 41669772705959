import styled, { keyframes } from "styled-components";

const animationIn = keyframes`
  from {
    bottom: -50px;
  }

  to {
    bottom: 70px;
  }
`;

export const Container = styled.div`
  width: 100%;
  position: fixed;
  bottom: 70px;
  z-index: 9999;
  justify-content: center;
  display: flex;
  animation: ${animationIn} 0.5s linear;
`;

export const ContainerCenter = styled.div`
  ${({ theme }) => theme.smartphone} {
    max-width: 90%;
  }
`;
