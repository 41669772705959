import React from "react";
import "./Graphics.Styles.scss";
import environment from "environment";

type IProps = {
  campaignDetail: Array<{
    id: number;
    title?: string;
    url_image?: string;
  }>;
  hasBucked?: boolean;
};
const Graphics = ({ campaignDetail, hasBucked }: IProps) => {
  const getImageURl = (image: any) =>
    hasBucked ? image : `${environment.aws.url}/${image}`;

  return (
    <div className="text_image_container">
      <div className="group_container">
        <div className="image_container">
          {campaignDetail &&
            campaignDetail.map(
              ({ id, title, url_image }) =>
                url_image &&
                url_image != "" && (
                  <div key={id} className="image_content_container">
                    <div className="title">{title}</div>
                    {url_image && (
                      <img
                        className="image_graphics"
                        src={getImageURl(url_image)}
                      />
                    )}
                  </div>
                )
            )}
        </div>
      </div>
    </div>
  );
};

export default Graphics;
