import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Heading, Loader } from "@bvcco/a2censo-component-lib";
import {
  Wrapper,
  Card,
  SuperHeader,
  HeaderContainer,
  Header,
  Body,
  HeaderMobile,
  CloseButton,
  LoaderWrapper,
  EmptyContainer,
  EmptyImage
} from "./Styles";
import SimulatorCampaignList from "../SimulatorCampaignList/SimulatorCampaignList";
import ModalActions from "../../redux/reducers/modal.reducer";
import theme from "../../styles/LightTheme";
import ConditionalList from "../paginator/ConditionalList/ConditionalList";
import "./styles.scss";
import { Paragraph } from "@bvcco/a2censo-component-lib";
import { fetchSimulatorsCampaigns } from "redux/store";
import CampaignActions from "redux/store/campaigns/reducer";

const SimulatorCampaignListModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    campaigns: allCampaigns,
    isLoading,
    totalPages,
    currentPage
  } = useSelector(({ campaigns }) => campaigns);

  const sizeIfiniteScroll = 8;

  const campaignsMapped = allCampaigns.map((campaign) => ({
    ...campaign,
    campaignId: campaign.id,
    name: campaign.name,
    termInMonths: campaign.financialConditions.termInMonths,
    capitalPayment: campaign.financialConditions.capitalPayment,
    interestRate: campaign.financialConditions.interestRate,
    interestPayment: campaign.financialConditions.interestPayment,
    capital_grace_period: campaign.financialConditions.capitalGracePeriod,
    capitalGracePeriod: campaign.financialConditions.capitalGracePeriod,
    interest_grace_period: campaign.financialConditions.interestGracePeriod
  }));

  const openSimulator = (campaign) => {
    if (!campaign.campaignSimulator) {
      return dispatch(
        ModalActions.setConditionalModalState(
          true,
          "SimulatorNotAvailableModal",
          {
            campaign
          }
        )
      );
    }

    return dispatch(
      ModalActions.setConditionalModalState(true, "Simulator", {
        campaign,
        isFromSimulatorCampaignList: true,
        clearBlur: true,
        css: {
          backgroundColor: theme.transparentMartinique
        },
        isFromSimuladorCampaigns: true
      })
    );
  };

  const closeModal = () => {
    const cleanCampaigns = [];
    dispatch(CampaignActions.setCampaigns(cleanCampaigns));
    dispatch(ModalActions.setConditionalModalState(false));
  };

  useEffect(() => {
    dispatch(CampaignActions.setLoading());
    dispatch(fetchSimulatorsCampaigns({ page: 1, limit: sizeIfiniteScroll }));
  }, []);

  const handleScrollDown = (nextPage) => {
    if (nextPage <= totalPages) {
      dispatch(
        fetchSimulatorsCampaigns({ page: nextPage, limit: sizeIfiniteScroll })
      );
    }
  };

  const renderCampaignList = () => {
    if (isLoading) {
      return (
        <LoaderWrapper data-testId="loader-wrapper">
          <Loader />
        </LoaderWrapper>
      );
    }

    return (
      <SimulatorCampaignList
        campaigns={campaignsMapped}
        onSelect={openSimulator}
      />
    );
  };

  return (
    <>
      <Wrapper>
        <SuperHeader>
          <Heading
            hieranchy="large_secondary"
            fontWeight="bold"
            color="primary"
          >
            {t("simulator.listCampaign.superTitle")}
          </Heading>
          <CloseButton
            data-testid="simulator-campaign-close-buttun"
            src="/assets/images/close-btn.png"
            onClick={closeModal}
          />
        </SuperHeader>
        <Card>
          <HeaderContainer>
            <Header>
              <Heading hieranchy="quaternary" fontWeight="bold" color="primary">
                {t("simulator.listCampaign.modalTitle")}
              </Heading>
            </Header>
            <HeaderMobile>
              <Heading hieranchy="quaternary" fontWeight="bold" color="primary">
                {t("simulator.listCampaign.modalTitleMobile")}
              </Heading>
            </HeaderMobile>
          </HeaderContainer>
          <Body>
            {campaignsMapped.length || isLoading ? (
              <ConditionalList
                id="infinite-scroll"
                listType="infinite-scroll"
                maxHeight="50vh"
                delay={500}
                currentPage={currentPage}
                totalPages={totalPages}
                onUpdateList={handleScrollDown}
              >
                {renderCampaignList()}
              </ConditionalList>
            ) : (
              <EmptyContainer data-testid="empty-container">
                <EmptyImage src="/assets/images/empty-state-campaigns.png" />
                <Paragraph
                  fontWeight="bolder"
                  color="whiteAndGray"
                  align="center"
                >
                  {t("campaign.noDataSimulator")}
                </Paragraph>
              </EmptyContainer>
            )}
          </Body>
        </Card>
      </Wrapper>
    </>
  );
};

export default SimulatorCampaignListModal;
