import * as Yup from "yup";
import { emailValidate } from "../../lib/utils";

const schema = {
  email: Yup.string()
    .email("register.validations.email.required")
    .test("length", "register.validations.email.required", (value) =>
      emailValidate(value)
    )
    .required("register.validations.email.required")
};
export default Yup.object().shape(schema);
