import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { selectForgotPasswordOtpType } from "../../redux/epics/forgot-password";

import {
  Container,
  ContentTitle,
  Title,
  OptionContainer,
  Option,
  IconContainer,
  Icon,
  OptionTitle,
  Subtitle,
  FancyBG,
  CancelContainer,
  Cancel
} from "./styles.js";

const ForgotPasswordMethod = ({ match }) => {
  const { token } = match.params;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <FancyBG>
      <Container className="container">
        <ContentTitle>
          <Title>{t("forgotPasswordMethod.title")}</Title>
          <Subtitle>{t("forgotPasswordMethod.subtitle")}</Subtitle>
        </ContentTitle>
        <OptionContainer className="row">
          <Option
            className=" col-md-4"
            onClick={() =>
              dispatch(selectForgotPasswordOtpType({ token, otpType: "sms" }))
            }
            id="sms-option"
          >
            <IconContainer>
              <Icon
                src="/assets/images/smartphone_1.png"
                alt="natural person"
                className="person-type__image"
              />
            </IconContainer>
            <OptionTitle>{t("forgotPasswordMethod.emailOption")}</OptionTitle>
          </Option>
          <Option
            className="offset-md-2 col-md-4"
            onClick={() =>
              dispatch(selectForgotPasswordOtpType({ token, otpType: "email" }))
            }
            id="email-option"
          >
            <IconContainer>
              <Icon
                src="/assets/images/mail_1.png"
                alt="natural person"
                className="person-type__image"
              />
            </IconContainer>
            <OptionTitle>{t("forgotPasswordMethod.phoneOption")}</OptionTitle>
          </Option>
        </OptionContainer>
        <CancelContainer>
          <Cancel href="/">{t("forgotPassword.cancel")}</Cancel>
        </CancelContainer>
      </Container>
    </FancyBG>
  );
};

ForgotPasswordMethod.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string
    })
  }).isRequired
};

export default ForgotPasswordMethod;
