import React from "react";
import { Dropdown } from "components/shared";
import Chevron from "../../assets/icons/Chevron.png";
import ChevronDisabled from "../../assets/icons/Chevron-disabled.png";
import "./InvestmentsPaginator.scss";

const InvestmentsPaginator = ({
  paginatorData,
  setNumberPage,
  setPageSize,
  valueSelected,
  setValueSelected,
  isDisabled
}: any) => {
  const onChangePrevious = () => {
    if (paginatorData.prevPage != 0) {
      setNumberPage(paginatorData.prevPage);
    }
  };

  const onChangeNext = () => {
    if (paginatorData.nextPage != 0) {
      setNumberPage(paginatorData.nextPage);
    }
  };

  const onChangePageSize = (item: any) => {
    setPageSize(Number(item.value));
    setNumberPage(1);
    setValueSelected(Number(item.id));
  };

  const getOptions: any = () => {
    
    let optionArray 
    paginatorData.totalItems ? optionArray = [{ id: 1, value: 10 }]  : optionArray = [{ id: 1, value: 0 }]
    if (paginatorData.totalItems >= 20) {
      optionArray.push({ id: 2, value: 20 });
    }
    if (paginatorData.totalItems >= 30) {
      optionArray.push({ id: 3, value: 30 });
    }
    return optionArray;
  };

  return (
    <div className="paginator">
      <div className="paginator__container">
        <div className="paginator__info">
          Mostrando
          <Dropdown
            disabled={isDisabled || paginatorData.totalItems < 20}
            name={`${
              isDisabled || paginatorData.totalItems < 20
                ? "drop-down-paginator-disabled"
                : "drop-down-paginator"
            }`}
            id={`${
              isDisabled || paginatorData.totalItems < 20
                ? "drop-down-paginator-disabled"
                : "drop-down-paginator"
            }`}
            options={getOptions()}
            valueSelected={valueSelected}
            data-testid="dropdown"
            onChange={onChangePageSize}
            initialLabel={"0"}
          />
          resultados de{" "}
          <b className={`${isDisabled ? "disabled_invest_paginator" : ""}`}>
            &nbsp;{paginatorData.totalItems}
          </b>
        </div>
        <div
          className={`paginator__controls ${
            isDisabled || paginatorData.totalItems <= 10
              ? "disabled_invest_paginator"
              : ""
          }`}
        >
          <button
            className="paginator__icon-left"
            onClick={onChangePrevious}
            data-testid="prevPage"
            disabled={paginatorData.prevPage === 0}
          >
            <img
              src={paginatorData.prevPage === 0 ? ChevronDisabled : Chevron}
            />
          </button>
          <span className={`${isDisabled ? "disabled_invest_paginator" : ""}`}>
            {" "}
            {paginatorData.currentPage}&nbsp;de&nbsp;{paginatorData.totalPages}{" "}
          </span>
          <button
            className="paginator__icon-right"
            onClick={onChangeNext}
            data-testid="nextPage"
            disabled={paginatorData.nextPage === 0}
          >
            <img
              src={paginatorData.nextPage === 0 ? ChevronDisabled : Chevron}
              className=""
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvestmentsPaginator;
