import React, { useState, useEffect } from "react";
import {
  ContainerCertificates,
  ContainerPagination,
  ContainerPages,
  Option,
  ContainerLoadMore
} from "./Pagination.Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paragraph, Button } from "@bvcco/a2censo-component-lib";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import {
  CertificateCardItem,
  CertificateCardFromType
} from "components/shared";
import { useTranslation } from "react-i18next";

export type IProps = {
  certificates: Array<{
    quantity: number;
    description: string;
    companyName: string;
    onClickEmail?: () => void;
    onClickDownload?: () => void;
    disabledDownload?: boolean;
  }>;
  certificatesPerPage: number;
  pages: number;
  typeCard: CertificateCardFromType;
  isInvestor: boolean;
  isMobile: boolean;
};

const Pagination = ({
  certificates,
  certificatesPerPage,
  pages,
  typeCard,
  isInvestor,
  isMobile
}: IProps) => {
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    setPage(1);
  }, [certificates]);

  const certificatesToShow = certificates.slice(
    isMobile ? 0 : page * certificatesPerPage - certificatesPerPage,
    page * certificatesPerPage
  );

  const arraOfPages = Array.from(Array(pages).keys());

  const onChangePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const onChangeNext = () => {
    if (page < pages) {
      setPage(page + 1);
    }
  };

  const onAddItemsMobile = () => {
    setPage(page + 1);
  };

  const LoadCards = () =>
    certificatesToShow.map((certificate, index) => (
      <CertificateCardItem
        key={index}
        isInvestor={isInvestor}
        dataTestId={`card-${certificate.companyName}`}
        listDataTestId={`list-${certificate.companyName}`}
        description={certificate.description}
        sendMail={certificate.onClickEmail}
        downloadCertificate={certificate.onClickDownload}
        quantity={certificate.quantity}
        companyName={certificate.companyName}
        containerType={typeCard}
        disabledDownload={certificate.disabledDownload}
        companyNameLength={certificate.companyName?.length}
      />
    ));

  return (
    <ContainerPagination>
      <ContainerCertificates typeCard={typeCard}>
        {LoadCards()}
      </ContainerCertificates>
      {pages > 1 && !isMobile && (
        <ContainerPages>
          <Option onClick={onChangePrevious} data-testid="option-previus">
            <FontAwesomeIcon icon={faChevronLeft} />
          </Option>
          {arraOfPages.map((_, index) => {
            const numberOfPage = index + 1;
            const onClick = () => {
              setPage(numberOfPage);
            };
            return (
              <Option
                key={index}
                data-testid={`option-number-${index}`}
                onClick={onClick}
                selected={page === numberOfPage}
              >
                <span>{numberOfPage}</span>
              </Option>
            );
          })}
          <Option onClick={onChangeNext} data-testid="option-next">
            <FontAwesomeIcon icon={faChevronRight} />
          </Option>
        </ContainerPages>
      )}
      {isMobile && (
        <ContainerLoadMore>
          <Paragraph color="acentuar">
            {t("myCertificates.pagination.countCertificates", {
              current: certificatesToShow.length,
              total: certificates.length
            })}
          </Paragraph>
          {certificatesToShow.length !== certificates.length && (
            <Button
              color="cornFlowerBlueBg"
              dataTestid={"add-more-items"}
              onClick={onAddItemsMobile}
            >
              {t("myCertificates.pagination.loadMore")}
            </Button>
          )}
        </ContainerLoadMore>
      )}
    </ContainerPagination>
  );
};

export default Pagination;
