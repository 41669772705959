import React from "react";
import PropTypes from "prop-types";
import { Button } from "./Styles";

const RadioButton = ({
  check,
  longRadioButton,
  extendedTextRadioButton,
  onCheck,
  id,
  text,
  value,
  isSummary,
  ...restProps
}) => (
  <Button
    onClick={(e) => {
      e.preventDefault();
      onCheck(e);
    }}
    id={`radiobutton_${id}`}
    value={value}
    check={check}
    longRadioButton={longRadioButton}
    extendedTextRadioButton={extendedTextRadioButton}
    isSummary={isSummary}
    {...restProps}
  >
    {text}
  </Button>
);

RadioButton.propTypes = {
  check: PropTypes.bool,
  onCheck: PropTypes.func,
  id: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.bool,
  longRadioButton: PropTypes.bool,
  extendedTextRadioButton: PropTypes.bool,
  isSummary: PropTypes.bool
};

RadioButton.defaultProps = {
  check: false,
  onCheck: null,
  id: "",
  text: "",
  value: false,
  longRadioButton: false,
  extendedTextRadioButton: false,
  isSummary: false
};

export default RadioButton;
