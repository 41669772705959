import styled from "styled-components";

type Props = {
  loading?: boolean;
};

export const Footer = styled.div<Props>`
  display: flex;
  justify-content: flex-end;
  button {
    width: 144px;
  }
  button + button {
    margin-left: 12px;
  }

  button:first-child {
    color: ${({ theme }) => theme.lightPurple};
  }

  button:last-child {
    width: ${({ loading }) => (loading ? "188px" : "144px")};
  }

  ${({ theme }) => theme.smartphone} {
    width: 100%;
    justify-content: center;
    margin-top: 38px;

    margin-left: 0px;
    margin-right: 0px;

    flex-wrap: nowrap;

    button {
      width: 132px;
    }
  }
`;
