import { combineEpics } from "redux-observable";
import {
  campaignDetail,
  campaignShowcase,
  featuredCampaignList,
  campaignSimulatorFlow,
  getCampaignsSuccessfull,
  getCampaignsFeatured,
  getAllCampaignsBySections
} from "./campaign";
import { campaingData } from "./campaign-detail";
import setInvestingCampaigns from "./investingCampaigns";
import register from "./register.js";
import validateCode from "./validate-code";
import enrollment from "./enrollment";
import forgotPassword from "./forgot-password";
import confirmData from "./confirm-data";
import confirmDataPYME from "./confirm-data-pyme";
import purpose from "./purpose";
import login from "./login";
import summary from "./summary";
import associate from "./associate";
import invest from "./invest";
import places from "./places";
import investmentCancellation from "./investment-cancellation";
import qualifiedInvestor from "./qualified-investor.js";
import contactForm from "./contact-form";
import recharge from "./recharge";
import balance from "./myBalance";
import movements from "./movements";
import politicsTermsHC from "./politics-terms-hc";
import newsList from "./news";
import accountRecovery from "./account-recovery";
import issuerPayment from "./issuer-payment";
import payments from "./payments";
import myPayments from "./my-payments";
import rejectRequestRejection from "./reject-request-rejection";
import welcomePYME from "./welcome-pyme";
import simulatorCampaigns from "./simulator-campaigns";
import cms from "./foundation-data";
import investmentSummary from "./investment-summary";
import financeYourself from "./finance-yourself";
import transaction from "./transaction";
import faq from "./faq";
import router from "./router";
import selfAppraisal from "./self-appraisal";
import dashboardCampaigns from "./dashboard-campaigns";
import requestCampaign from "./request-campaign";
import fileUploader from "./file-uploader";
import myCompanyInfo from "./my-company";
import parameters from "./parameters";
import campaignDocumentManagment from "./campaign-document-managment";
import createCampaignStepper from "./create-campaign-stepper";
import interview from "./interview";
import codebtor from "./codebtor";
import requestCampaignValidation from "./request-campaign-validation";
import requestCampaignTerms from "./request-campaign-terms";
import userCertificates from "./user-certificates";
import sendHelpMessage from "./send-help";
import company from "./company";

export default combineEpics(
  campaignDetail,
  campaingData,
  campaignShowcase,
  campaignSimulatorFlow,
  setInvestingCampaigns,
  validateCode,
  register,
  enrollment,
  forgotPassword,
  confirmData,
  confirmDataPYME,
  login,
  purpose,
  summary,
  associate,
  featuredCampaignList,
  invest,
  places,
  investmentCancellation,
  qualifiedInvestor,
  contactForm,
  recharge,
  balance,
  movements,
  politicsTermsHC,
  newsList,
  accountRecovery,
  payments,
  myPayments,
  issuerPayment,
  rejectRequestRejection,
  welcomePYME,
  getCampaignsSuccessfull,
  getCampaignsFeatured,
  simulatorCampaigns,
  cms,
  investmentSummary,
  financeYourself,
  transaction,
  faq,
  router,
  selfAppraisal,
  dashboardCampaigns,
  requestCampaign,
  fileUploader,
  myCompanyInfo,
  parameters,
  interview,
  campaignDocumentManagment,
  createCampaignStepper,
  codebtor,
  requestCampaignValidation,
  requestCampaignTerms,
  userCertificates,
  sendHelpMessage,
  company,
  getAllCampaignsBySections
);
