/* eslint-disable */
import reduxLoader from "./loaders/redux_loader";
//import analyticsLoader from "./loaders/analytics_loader";
import apiLoader from "./loaders/api_loader";
import recaptchaLoader from "./loaders/recaptcha_loader";
import sessionTimeoutManagerLoader from "./loaders/sessionTimeout_loader";
import versionManagerLoader from "./loaders/versionManager_loader";
import parametersLoader from "./loaders/parameters_loader";
import config from "../config/config";

// put here all the async things the app needs to do when booting.
// for example: loading remote data, building internal objects, etc
// we call those, loaders
// Use as follows:
// Each value in the object is an async function, that returns something
// Each key in the object is the key where the returned value will be stored inside of `app`
// for instance, adding `gameData: configLoader`
// will assign to app.gameData the value resolved by the async function configLoader
const loaders = {
  versionManager: versionManagerLoader,
  /*analytics: analyticsLoader,*/
  redux: reduxLoader,
  api: apiLoader,
  recaptcha: recaptchaLoader,
  sessionTimeoutManager: sessionTimeoutManagerLoader,
  parameters: parametersLoader
};

export default {
  async boot() {
    this.config = config;

    return new Promise(async (resolve, reject) => {
      let resolvedPromises = 0;
      for (const key in loaders) {
        const loader = loaders[key];
        try {
          this[key] = await loader(this);
        } catch (error) {
          reject(error);
        } finally {
          resolvedPromises += 1;
          // if all loaders finished successfully
          if (resolvedPromises === Object.keys(loaders).length) {
            resolve();
          }
        }
      }
    });
  }
};
