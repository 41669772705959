import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 15.5%;
  background-color: rgb(220, 220, 220, 0.7);
  overflow: scroll;
  padding-top: 10%;
  backdrop-filter: blur(8px);
  ${({ theme }) => theme.smartphone} {
    padding: 45% 2%;
  }
`;

export const IconTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  ${(props) => props.theme.smartphone} {
    margin-top: 15px;
  }
`;

export const IconTitle = styled.img`
  width: 80px;
  height: 80px;
`;

export const Title = styled.h1`
  font-size: 36px;
  font-family: Nunito;
  font-weight: bold;
  text-align: center;
  margin: 3% 0;
  color: ${({ theme }) => theme.tuatara};
  ${(props) => props.theme.smartphone} {
    font-size: 24px;
    margin: 10% 0;
  }
`;

export const Paragraph = styled.p`
  font-family: Roboto;
  font-size: 23px;
  line-height: 32px;
  text-align: center;
  color: ${({ theme }) => theme.tuatara};
  padding: 0 16%;
  span {
    display: block;
    width: 100%;
    margin: 1% 0;
    padding: 0;
  }
  ${({ theme }) => theme.smartphone} {
    font-size: 14px;
    line-height: 23px;
    padding: 0 5%;
    span {
      margin-top: 3%;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 7%;
  ${({ theme }) => theme.smartphone} {
    margin-top: 15%;
  }
`;

export const Button = styled.button`
  border: none;
  width: 250px;
  height: 75px;
  background-color: ${({ theme }) => theme.brightTurquoise};
  color: ${({ theme }) => theme.white};
  font-size: 28px;
  font-family: Nunito;
  font-weight: bold;
  border-radius: 75px;
  cursor: pointer;
  ${({ theme }) => theme.smartphone} {
    width: 130px;
    height: 40px;
    font-size: 14px;
  }
`;
