import React from "react";

import { ItemWrapper, Icon, Image, LastIcon, Description } from "./components";

export type CardItemProps = {
  dataTestId?: string;
  icon: JSX.Element;
  image: JSX.Element;
  description: string | JSX.Element;
  indicator: JSX.Element;
  backgroundColor?: string;
};

export const CardListItem = ({
  dataTestId = "card-list-item",
  icon,
  image,
  description,
  indicator,
  backgroundColor
}: CardItemProps) => (
  <ItemWrapper backgroundColor={backgroundColor} data-testid={dataTestId}>
    <Icon>{icon}</Icon>
    <Image>{image}</Image>
    <Description>{description}</Description>
    <LastIcon>{indicator}</LastIcon>
  </ItemWrapper>
);
