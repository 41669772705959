import styled from "styled-components";

export const CloseButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 16px;
`;
