import styled from "styled-components";

export const Container = styled.div`
  height: 470px;
  width: 900px;
  background-color: ${(props) => props.theme.white};
  max-height: 90%;
  max-width: 90%;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.nevada};
  font-family: Nunito;
  font-size: 45px;
  font-weight: 800;
  margin: 13px;
`;

export const Subtitle = styled.h3`
  color: ${(props) => props.theme.nevada};
  font-family: Roboto;
  font-size: 23px;
  line-height: 40px;
  font-weight: 300;
  padding: 0px 20px;
  text-align: center;
  max-width: 540px;
  margin: 13px;
`;
export const BottomButtonContainer = styled.div`
  height: 85px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Button = styled.button`
  width: 140px;
  height: 49px;
  border-radius: 24px;
  background-color: ${(props) => props.theme.brightTurquoise};
  border: 2px solid ${(props) => props.theme.brightTurquoise};
  color: ${(props) => props.theme.white};
  font-family: Nunito;
  font-size: 17px;
  font-weight: 800;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  ${(props) => props.theme.smartphone} {
    width: 170px;
    height: 40px;
    border-radius: 18px;
    font-size: 13px;
  }
`;
