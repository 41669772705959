import UploadFile from "./upload-file";

import CampaignDocumentManagment from "../../redux/reducers/campaign-document-managment.reducer";
import App from "../app";

class UploadFileCampaign extends UploadFile {
  constructor(apiUrl, fileKey) {
    super(apiUrl);
    this.fileKey = fileKey;
  }

  onProgressUpdate() {
    App.redux.store.dispatch(
      CampaignDocumentManagment.updateProgressFile(this.fileKey, this.progress)
    );
  }
}

export default UploadFileCampaign;
