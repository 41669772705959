import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../styles/Styles";
import validationSchema from "./ConfirmPayAmount.schema";
import { formatAmount } from "../../lib/utils.js";
import User from "../../lib/models/user.model";
import {
  Container,
  GoBack,
  BackButton,
  BackButtonImage,
  Destiny,
  ConfirmContent,
  SubTitle,
  ConfirmFooter,
  ConfirmButton,
  Draw,
  Line,
  Circle,
  Form,
  TextHeader,
  FormRow,
  FormColum,
  Description,
  Text,
  ContainerPSE,
  IconPSE,
  DescriptionPSE
} from "./Styles";
import Input from "../../components/Input/Input";
import Select from "../../components/Select/Select";
import PaymentsActions from "../../redux/reducers/payments.reducer";
import pseActions from "../../redux/reducers/pse-data.reducer";

const ConfirmPayAmount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useSelector(({ user }) => user.userInfo);

  const documentTypes = Object.keys(User.documentType).map((key) => ({
    value: key,
    text: User.documentType[key]
  }));

  const issuerName = useSelector(({ user }) => user.userInfo.user.name);
  const isFetching = useSelector(({ payments }) => payments.isFetching);
  const campaignId = useSelector(({ payments }) => payments.campaignSelected);
  const { id: issuerPaymentId } = useSelector(
    ({ payments }) => payments.issuerPaymentSelected
  );
  const issuerPayment = useSelector(({ payments }) =>
    payments.issuerPaymentList.find(
      (item) => item.campaign_id === Number(campaignId)
    )
  ).issuer_payment;
  const { banksList } = useSelector(({ payments }) => payments);
  const { data } = useSelector((state) => state.pseData);

  let personTypeSaved;
  if (data.documentTypeId) {
    personTypeSaved =
      Number(data.documentTypeId) === 3 ? User.type.legal : User.type.natural;
  }

  const [selectPersonType, setPersonType] = useState(
    personTypeSaved || userInfo.user.person_type
  );
  const handleBack = () => {
    dispatch(push("/my-payments"));
  };

  const banksOptions = banksList.map((item) => ({
    value: item.id,
    text: item.name
  }));

  const [userIp, setUserIp] = useState("");

  useEffect(() => {
    const fetchIp = async () => {
      let res = await fetch("https://api.ipify.org?format=json", { method: "GET", headers: {} })
      let ip = await res.json();
      setUserIp(ip.ip);
    }
    fetchIp()
  });

  const handleContinue = (values) => {
    const payload = {
      issuerPaymentId,
      documentType: User.documentType[values.documentType],
      documentTypeId: values.documentType,
      documentNumber: values.documentNumber,
      bankCode: values.bank,
      dv: values.dv,
      clientIp: userIp,
      campaignId: campaignId
    };
    dispatch(PaymentsActions.payIssuerPayments(payload));
    dispatch(pseActions.setData(payload));
  };
  const formData = {
    documentType: data.documentTypeId || userInfo.user.document_type,
    documentNumber: data.documentNumber || userInfo.user.document_number,
    bank: data.bankCode || undefined,
    dv: data.dv || userInfo.user.dv
  };
  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema(selectPersonType)}
      onSubmit={(values, { setSubmitting }) => {
        handleContinue(values, setSubmitting);
      }}
      id="confirm-form"
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => {
        const errorKeys = Object.keys(errors);
        const isNaturalPersonSelected =
          User.documentType[values.documentType] !== "NIT";
        return (
          <form>
            <ConfirmContent>
              <Container>
                <GoBack>
                  <BackButton onClick={handleBack} id="confirm-go-back">
                    <BackButtonImage
                      alt="previous"
                      src="/assets/images/previous.png"
                    />
                  </BackButton>
                </GoBack>
                <Destiny>
                  <PageTitle>{t("confirmPayAmount.title")}</PageTitle>
                  <SubTitle>
                    <strong>{issuerName}</strong>
                    {t("confirmPayAmount.description")}
                    <Description>
                      {t("confirmPayAmount.description2")}
                    </Description>
                    {t("confirmPayAmount.description3")}
                  </SubTitle>
                </Destiny>
                <Draw margins={{ top: "30px", left: "15px" }}>
                  <Circle />
                  <Line />
                </Draw>
                <Form personType={userInfo.person_type}>
                  <TextHeader>{t("confirmPayAmount.titleSection")}</TextHeader>
                  <FormRow>
                    <FormColum
                      width="100%"
                      mobileWidth="100%"
                      mobileBorders={{
                        bottom: true
                      }}
                      borders={{ top: true, bottom: true }}
                    >
                      <Text>{formatAmount(issuerPayment.total)}</Text>
                    </FormColum>
                  </FormRow>
                  <FormRow>
                    <FormColum
                      width="100%"
                      mobileWidth="100%"
                      mobileBorders={{
                        bottom: true
                      }}
                      borders={{ bottom: true }}
                    >
                      <Select
                        label={t("confirmPayAmount.placeholder.listOfBanks")}
                        type="bank"
                        name="bank"
                        id="bank"
                        placeholder={t(
                          "confirmPayAmount.placeholder.listOfBanks"
                        )}
                        options={banksOptions}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.bank}
                        error={touched.bank && errors.bank}
                      />
                    </FormColum>
                  </FormRow>
                  <FormRow>
                    <FormColum
                      borders={{ right: true }}
                      width="20%"
                      mobileWidth="20%"
                    >
                      <Select
                        data-testid="documentType"
                        label={t("confirmPayAmount.placeholder.documentType")}
                        type="documentType"
                        name="documentType"
                        id="documentType"
                        placeholder={t(
                          "confirmPayAmount.placeholder.documentType"
                        )}
                        disabled={true}
                        options={documentTypes}
                        onChange={(e) => {
                          setPersonType(
                            Number(e.target.value) === 3
                              ? User.type.legal
                              : User.type.natural
                          );
                          setFieldValue("documentType", e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.documentType}
                        error={touched.documentType && errors.documentType}
                      />
                    </FormColum>
                    <FormColum
                      width="80%"
                      mobileWidth="60%"
                      borders={{ right: !isNaturalPersonSelected }}
                    >
                      <Input
                        label={t("confirmPayAmount.placeholder.documentNumber")}
                        type="documentNumber"
                        name="documentNumber"
                        id="documentNumber"
                        placeholder={t(
                          "confirmPayAmount.placeholder.documentNumber"
                        )}
                        disabled={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.documentNumber}
                        error={touched.documentNumber && errors.documentNumber}
                      />
                    </FormColum>
                    {!isNaturalPersonSelected && (
                      <FormColum width="20%" mobileWidth="20%">
                        <Input
                          label={t("confirmPayAmount.placeholder.dv")}
                          type="dv"
                          name="dv"
                          id="dv"
                          disabled={true}
                          placeholder={t("confirmPayAmount.placeholder.dv")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.dv}
                          error={touched.dv && errors.dv}
                        />
                      </FormColum>
                    )}
                  </FormRow>
                </Form>
                <ContainerPSE>
                  <IconPSE alt="pse" src="/assets/images/pse.png" />
                  <DescriptionPSE>
                    {t("confirmPayAmount.descriptionPayInit")}
                    <strong>{t("confirmPayAmount.descriptionPayApp")}</strong>
                    {t("confirmPayAmount.descriptionPayEnd")}
                  </DescriptionPSE>
                </ContainerPSE>
              </Container>
              <ConfirmFooter>
                <ConfirmButton
                  id="send_button"
                  type="button"
                  data-testid="save-button-recharge"
                  onClick={handleSubmit}
                  disabled={isFetching || errorKeys.length || isSubmitting}
                >
                  {isFetching
                    ? t("confirmPayAmount.loading")
                    : t("confirmPayAmount.continue")}
                </ConfirmButton>
              </ConfirmFooter>
            </ConfirmContent>
          </form>
        );
      }}
    </Formik>
  );
};

export default ConfirmPayAmount;
