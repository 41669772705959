import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 4px;
  width: 500px;
  padding: 50px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    font-size: 22px;
    line-height: 26px;
    color: ${({ theme }) => theme.brightTurquoise};
    text-align: center;
  }

  ${({ theme }) => theme.smartphone} {
    width: 80%;
    & > p {
      font-size: 20px;
      line-height: 22px;
    }
  }

  > button {
    margin-top: 33px;
    width: 124px;
    height: 40px;
    border-color: ${({ theme }) => theme.brightTurquoise};
    background-color: ${({ theme }) => theme.brightTurquoise};
  }
`;
