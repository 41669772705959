import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { sendRejectRequestRejection } from "../../redux/epics/reject-request-rejection";
import { Container, Title, Subtitle } from "./Styles";

const RejectRequestRejection = ({ location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (location.search !== "") {
    const params = location.search.split("&");
    const params1 = params[0].split("=");
    const params2 = params[1].split("=");
    const issuerPaymentId = params1[1];
    const token = params2[1];

    dispatch(sendRejectRequestRejection(issuerPaymentId, token));
  }

  return location.search === "" ? (
    <Container className="container">
      <Title>{t("rejectRequestRejection.title")}</Title>
      <Subtitle>{t("rejectRequestRejection.subtitle")}</Subtitle>
    </Container>
  ) : null;
};
RejectRequestRejection.propTypes = {
  location: PropTypes.shape({
    search: PropTypes,
    state: PropTypes.shape({
      disableButton: PropTypes.bool
    })
  }).isRequired
};

export default RejectRequestRejection;
