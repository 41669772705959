import { createReducer, createActions } from "reduxsauce";

export const { Types, Creators } = createActions({
  getDepartments: ["payload"],
  getCities: ["payload"],
  setDepartmentsInfo: ["departmentsInfo"],
  setCitiesInfo: ["citiesInfo"],
  setDepartmentsBirth: ["departmentsBirth"],
  setCitiesBirth: ["citiesBirth"],
  clearPlaces: []
});

export const PlacesTypes = Types;
export default Creators;

const INITIAL_STATE = {
  departmentsInfo: [],
  citiesInfo: [],
  departmentsBirth: [],
  citiesBirth: []
};

const setDepartmentsInfo = (state, { departmentsInfo }) => ({
  ...state,
  departmentsInfo
});

const setCitiesInfo = (state, { citiesInfo }) => ({ ...state, citiesInfo });

const setDepartmentsBirth = (state, { departmentsBirth }) => ({
  ...state,
  departmentsBirth
});

const clearPlaces = () => INITIAL_STATE;

const setCitiesBirth = (state, { citiesBirth }) => ({ ...state, citiesBirth });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_DEPARTMENTS_INFO]: setDepartmentsInfo,
  [Types.SET_CITIES_INFO]: setCitiesInfo,
  [Types.SET_CITIES_BIRTH]: setCitiesBirth,
  [Types.SET_DEPARTMENTS_BIRTH]: setDepartmentsBirth,
  [Types.CLEAR_PLACES]: clearPlaces
});
