import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Heading } from "@bvcco/a2censo-component-lib";
import { push } from "connected-react-router";
import {
  Container,
  Divider,
  Button,
  ParagraphDescription,
  ButtonsContainer,
  ContainerList,
  Options
} from "./Styles";
import ModalActions from "../../redux/reducers/modal.reducer";

const ConfirmDataPymeSuccessModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { name } = props;
  const handleClick = () => {
    dispatch(ModalActions.setConditionalModalState(false));
    dispatch(push("/enrollment/self-appraisal"));
  };

  return (
    <Container>
      <Heading hieranchy="primary" fontWeight="bold" color="brightTurquoise">
        {t("confirmDataPyme.DataPymeModalSuccess.title")}
      </Heading>
      <ParagraphDescription>
        {`${name} ${t(
          "confirmDataPyme.DataPymeModalSuccess.confirmationMessage"
        )}`}
      </ParagraphDescription>
      <ContainerList>
        <Options>
          {t("confirmDataPyme.DataPymeModalSuccess.option_one")}
        </Options>
        <Options>
          {t("confirmDataPyme.DataPymeModalSuccess.option_two")}
        </Options>
        <Options>
          {t("confirmDataPyme.DataPymeModalSuccess.option_three")}
        </Options>
      </ContainerList>
      <Divider />
      <ButtonsContainer>
        <Button
          rounded={true}
          id="continue-button"
          onClick={handleClick}
          primary="true"
        >
          {t("confirmDataPyme.DataPymeModalSuccess.sure")}
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

ConfirmDataPymeSuccessModal.propTypes = {
  name: PropTypes.string.isRequired
};

export default ConfirmDataPymeSuccessModal;
