import styled from "styled-components";

export const InfiniteScrollContainer = styled.div`
  ${(props) =>
    props.orientation === "x"
      ? `max-width: ${props.maxWidth};`
      : `max-height: ${props.maxHeight};`}
  ${(props) => {
    if (props.conditionalList) {
      return "";
    }
    return `overflow-${props.orientation}: scroll; scroll-behavior: smooth;`;
  }}
`;

export const FooterLoading = styled.div``;
