import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .input {
    width: 536px;
  }

  .input textarea {
    height: 134px;
  }

  > p {
    margin-bottom: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.cornflowerBlue};
  }
  button {
    width: 111px;
    height: 40px;
    background: ${({ theme }) => theme.cornflowerBlue};
    border-radius: 100px;
    border: ${({ theme }) => theme.cornflowerBlue};
    align-self: flex-end;
  }

  ${({ theme }) => theme.smartphone} {
    width: 90%;
    .input {
      width: 100%;
    }
  }
`;
