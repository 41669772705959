import styled from "styled-components";

export const Container = styled.div`
  height: 421px;
  width: 448px;
  background-color: ${(props) => props.theme.white};
  max-height: 90%;

  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

export const GlobalContainer = styled.div`
  position: relative;
`;
export const Image = styled.img`
  height: 176px;
  width: 282px;
  margin-bottom: 30px;
  object-fit: contain;
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.riverBed};
  font-family: Nunito;
  font-size: 18px;
  font-weight: 900;
`;

export const Subtitle = styled.h3`
  color: ${(props) => props.theme.riverBed};
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 0px 20px;
  text-align: center;
  max-width: 540px;
`;
export const BottomButtonContainer = styled.div`
  height: 90px;
  padding-top: 21px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  outline: none;
  position: absolute;
  top: -40px;
  right: -70px;
  border: none;
  cursor: pointer;
  align-self: flex-end;
  margin-right: 30px;
  ${(props) => props.theme.smartphone} {
    margin-right: 10px;
  }
`;
export const Icon = styled.img`
  width: 30px;
  height: 30px;
  ${(props) => props.theme.smartphone} {
    width: 25px;
    height: 25px;
  }
`;
export const Button = styled.button`
  width: 139px;
  height: 40px;
  border-radius: 24px;
  border: transparent;
  background-color: ${(props) => props.theme.brightTurquoise};
  color: ${(props) => props.theme.white};
  font-family: Nunito;
  font-size: 16px;
  font-weight: 800;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  ${(props) => props.theme.smartphone} {
    width: 170px;
    height: 40px;
    border-radius: 18px;
    font-size: 13px;
  }
`;
