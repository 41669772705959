import { createReducer, createActions } from "reduxsauce";
import CampaignModel from "../../lib/models/campaign.model";

const { Types, Creators } = createActions({
  getDashboardCampaigns: ["search"],
  getDashboardVideo: [""],
  setDashboardCampaigns: ["campaigns"],
  setDashboardVideo: ["video"],
  getPymeCampaignsDashboard: [],
  setDashboardCampaignsPyme: ["campaigns"],
  clearCampaigns: []
});

export const dashboardTypes = Types;
export default Creators;

const INITIAL_STATE = {
  campaigns: [],
  activeCampaings: [],
  awardedCampaigns: [],
  video: {
    videoGuide: "",
    videoTips: ""
  }
};

const setCampaigns = (state, { campaigns }) => ({ ...state, campaigns });

const setCampaignsPYME = (state, { campaigns }) => {
  const campaingsPyme = Array.isArray(campaigns)
    ? campaigns.filter(
        (campaign) => campaign.state !== CampaignModel.state.draft
      )
    : [];

  const awardedCampaigns = campaingsPyme.filter(
    (c) => c.state === CampaignModel.state.awarded
  );

  const campaingMapped = campaingsPyme.map((c) => ({
    ...c,
    request_campaign_name: c.campaign_name,
    state: CampaignModel.stateName[c.state]
  }));
  return { ...state, activeCampaings: [...campaingMapped], awardedCampaigns };
};

const setVideo = (state, { video }) => ({ ...state, video });

const clearCampaigns = (state) => ({
  ...state,
  campaigns: [],
  activeCampaings: [],
  awardedCampaigns: []
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_DASHBOARD_CAMPAIGNS]: setCampaigns,
  [Types.SET_DASHBOARD_CAMPAIGNS_PYME]: setCampaignsPYME,
  [Types.SET_DASHBOARD_VIDEO]: setVideo,
  [Types.CLEAR_CAMPAIGNS]: clearCampaigns
});
