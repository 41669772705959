import styled from "styled-components";

export const Container = styled.div`
  margin-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${(props) => props.theme.smartphone} {
    margin-top: 20px;
  }
`;

export const Image = styled.img`
  margin: 10px 0;
  height: 200px;
  width: 150px;
  object-fit: contain;
  ${(props) => props.theme.smartphone} {
    height: 150px;
    width: 100px;
  }
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.tuatara};
  font-family: Nunito;
  font-size: 23px;
  margin-top: 20px;
  margin-bottom: 32px;
  font-weight: 800;
  text-align: center;
  padding-left: 8px;
  border-left: 6px solid;
  border-left-color: ${(props) => props.theme.brightTurquoise};
  ${(props) => props.theme.smartphone} {
    margin-top: 10px;
    font-size: 19px;
  }
`;
export const Subtitle = styled.p`
  color: ${(props) => props.theme.tuatara};
  font-family: Roboto;
  font-size: 17px;
  text-align: center;
  max-width: 400px;
  margin-top: 20px;
  font-weight: 200;
  margin-bottom: 30px;
  ${(props) => props.theme.smartphone} {
    margin-top: 10px;
    font-size: 14px;
  }
`;
export const Error = styled.p`
  color: ${(props) => props.theme.red};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 200;
  margin-top: 10px;
  ${(props) => props.theme.smartphone} {
    margin-top: 5px;
    font-size: 12px;
  }
`;

export const SentNewCodeMessage = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 200;
  margin-top: 20px;
  ${(props) => props.theme.smartphone} {
    margin-top: 5px;
    font-size: 12px;
  }
`;

export const InfoText = styled.p`
  margin-top: 20px;
  color: ${(props) => props.theme.tuatara};
  font-family: Roboto;
  font-size: 17px;
  text-align: center;
  ${(props) => props.theme.smartphone} {
    margin-top: 10px;
    font-size: 14px;
  }
`;
export const NewCodeLink = styled.button`
  color: ${(props) => props.theme.brightTurquoise};
  font-family: Roboto;
  font-size: 17px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  ${(props) => props.theme.smartphone} {
    font-size: 14px;
  }
`;
export const ErrorLoaderContainer = styled.div`
  min-height: 70px;
  ${(props) => props.theme.smartphone} {
    min-height: 45px;
  }
`;
