import { catchError, flatMap, map } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { push } from "connected-react-router";
import { Observable } from "rxjs-compat";
import {
  PaymentsSerializer,
  RequestNewAmount
} from "../../lib/serializers/payments.serializer";
import App from "../../lib/app";
import PaymentsActions, { PaymentsTypes } from "../reducers/payments.reducer";
import ModalActions from "../reducers/modal.reducer";
import IssuerPaymentModel from "../../lib/models/issuerPayment.model";
import PaymentError from "../reducers/payment-error.reducer";
import ErrorsPSE, { warning } from "../../lib/utils/constants.errors";
import { getErrorIndex } from "../../lib/utils";
import paramNames from "../../lib/models/parameter.model";

const { HASHED_CODE_PARAMS } = paramNames;
const { _PARAM_010 } = HASHED_CODE_PARAMS();

const PaymentsErrors = ["1020"];

export const getPaymentsAction = {
  type: PaymentsTypes.GET_PAYMENTS
};

export const submitPrepaidDestiny = (action$) =>
  action$.pipe(
    ofType(PaymentsTypes.SUBMIT_PREPAID_DESTINY),
    flatMap(
      ({ issuer: { issuerPaymentId, reduceType, issuerName, newAmount } }) =>
        Observable.from(
          App.api.a2censo.paymentNewAmountRequest({
            body: RequestNewAmount().serialize({
              id: issuerPaymentId,
              reduceType,
              newAmount
            })
          })
        ).pipe(
          flatMap(() =>
            Observable.concat(
              Observable.of(PaymentsActions.submitPrepaidDestinyEnd()),
              Observable.of(push("/")),
              Observable.of(
                ModalActions.setConditionalModalState(
                  true,
                  "RequestSendModal",
                  {
                    issuerName
                  }
                )
              )
            )
          ),
          catchError((e) => {
            let hasError = false;
            if (Array.isArray(e.errors)) {
              e.errors.map((error) => {
                const index = PaymentsErrors.findIndex(
                  (value) => error.code === value
                );
                if (index >= 0) {
                  hasError = true;
                }
                return error;
              });
            }
            if (hasError) {
              return Observable.concat(
                Observable.of(PaymentsActions.submitPrepaidDestinyEnd()),
                Observable.of(
                  ModalActions.setConditionalModalState(true, "ErrorModal", {
                    title: "prepaidDestiny.errorByTime.title",
                    content: e.errors[0].message
                  })
                )
              );
            }
            return Observable.concat(
              Observable.of(PaymentsActions.submitPrepaidDestinyEnd()),
              Observable.of(push("/oops"))
            );
          })
        )
    )
  );

export const getPayments = (action$) =>
  action$.pipe(
    ofType(PaymentsTypes.GET_PAYMENTS),
    flatMap((action) => {
      let urlVars = `?${
        action.params.url.campaignId !== null
          ? `campaign_id=${action.params.url.campaignId}&`
          : ""
      }`;
      urlVars = `${urlVars}${
        action.params.url.state !== null
          ? `state=${action.params.url.state}&`
          : ""
      }`;
      urlVars = `${urlVars}${
        action.params.url.yearMonth !== null
          ? `year_month=${action.params.url.yearMonth}&`
          : ""
      }`;
      urlVars = `${urlVars}${
        action.params.url.currentPage !== null
          ? `page=${action.params.url.currentPage}&`
          : ""
      }`;
      urlVars = `${urlVars}${
        action.params.url.pageSize !== null
          ? `size=${action.params.url.pageSize}`
          : ""
      }`;
      return Observable.from(
        App.api.a2censo.getPayments({
          url: { urlVars }
        })
      ).pipe(
        map((data) => {
          if (data.payment_history === 0) {
            return PaymentsActions.setPayments("noData");
          }
          const {
            pagination_params: { total_items: totalItems }
          } = data;

          if (action.params.url.currentPage > 1) {
            const { paymentsList } = App.redux.store.getState().payments;
            return PaymentsActions.setPayments(
              [...paymentsList, ...data.payment_history],
              totalItems
            );
          }
          return PaymentsActions.setPayments(data.payment_history, totalItems);
        }),
        catchError((e) => Observable.concat(Observable.of(push("/oops", e))))
      );
    })
  );

export const payIssuerPayments = (action$) =>
  action$.pipe(
    ofType(PaymentsTypes.PAY_ISSUER_PAYMENTS),
    flatMap((action) => {
      const {
        issuerPaymentId,
        documentNumber,
        documentType,
        bankCode,
        clientIp,
        campaignId
      } = action.issuer;
      return Observable.from(
        App.api.a2censo.issuerPayment({
          body: PaymentsSerializer().serialize({
            issuerPaymentId,
            documentNumber,
            documentType,
            bankCode,
            clientIp,
            campaignId
          })
        })
      ).pipe(
        flatMap((response) => {
          window.location.assign(response.bank_url);
          return Observable.concat(
            Observable.of(PaymentsActions.payIssuerPaymentsEnd())
          );
        }),
        catchError((e) => {
          if (
            e.errors &&
            e.errors.length > 0 &&
            ErrorsPSE.includes(e.errors[0].code)
          ) {
            return Observable.concat(
              Observable.of(PaymentsActions.fetchingEnd()),
              Observable.of(PaymentError.setError(e.errors[0].code)),
              Observable.of(push("/payment-error"))
            );
          }
          return Observable.concat(
            Observable.of(PaymentsActions.fetchingEnd()),
            Observable.of(PaymentError.setError(warning)),
            Observable.of(push("/payment-error"))
          );
        })
      );
    })
  );

export const approvePayment = (action$) =>
  action$.pipe(
    ofType(PaymentsTypes.APPROVE_PAYMENT),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.approvePayment({
          body: {
            data: {
              attributes: {
                message: "",
                issuer_payment_id: action.payload.issuerPaymentId,
                request_type: "approve",
                file: "",
                file_name: ""
              }
            }
          }
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(PaymentsActions.updateStatePaymentApprove(true)),
            Observable.of(push("/confirm-pay-amount"))
          )
        ),
        catchError(() =>
          Observable.concat(
            Observable.of(PaymentsActions.updateStatePaymentApprove(false)),
            Observable.of(push("/oops"))
          )
        )
      )
    )
  );

export const rejectPayment = (action$) =>
  action$.pipe(
    ofType(PaymentsTypes.REJECT_PAYMENT),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.approvePayment({
          body: {
            data: {
              attributes: {
                message: action.payload.comment,
                issuer_payment_id: action.payload.issuerPaymentId,
                request_type: "rejection",
                file: action.payload.file,
                file_name: action.payload.fileName
              }
            }
          }
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(PaymentsActions.updateStatePaymentApprove(false)),
            Observable.of(push("/patrimonial-rights/reject-success"))
          )
        ),
        catchError(() =>
          Observable.concat(
            Observable.of(PaymentsActions.updateStatePaymentApprove(false)),
            Observable.of(push("/oops"))
          )
        )
      )
    )
  );

export const setCampaignSelected = (action$) =>
  action$.pipe(
    ofType(PaymentsTypes.SET_CAMPAIGN_SELECTED),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.getStaticParameters({
          url: { tables: `${_PARAM_010}` }
        })
      ).pipe(
        flatMap((response) => {
          const { state } = IssuerPaymentModel;
          const { issuerPayment, optionValue } = action;
          if (
            [
              state.pending_approve,
              state.rejected,
              state.pending_new_amount_request,
              state.pending_reject_request
            ].includes(issuerPayment.state)
          ) {
            if (optionValue > 0) {
              return Observable.concat(
                // eslint-disable-next-line no-underscore-dangle
                Observable.of(PaymentsActions.setBanksList(response._pse_bank)),
                Observable.of(push("/payment-detail"))
              );
            }

            return Observable.concat(
              // eslint-disable-next-line no-underscore-dangle
              Observable.of(PaymentsActions.setBanksList(response._pse_bank)),
              Observable.of(push("/prepaid-destiny"))
            );
          }

          if (
            issuerPayment.state === state.approved_by_timeout ||
            issuerPayment.state === state.issuer_approved
          ) {
            return Observable.concat(
              // eslint-disable-next-line no-underscore-dangle
              Observable.of(PaymentsActions.setBanksList(response._pse_bank)),
              Observable.of(push("/confirm-pay-amount"))
            );
          }
        })
      )
    ),
    catchError((e) => {
      const hasErrorIndex = getErrorIndex(e);
      if (hasErrorIndex > -1) {
        return Observable.of(
          ModalActions.setConditionalModalState(true, "ErrorModal", {
            title: "errorModal.title",
            content: `errorCodes.${e.errors[hasErrorIndex].code}`,
            linkRedirectsTo: "/",
            buttonText: "errorModal.success"
          })
        );
      }
      return Observable.of(push("/oops"));
    })
  );

export default combineEpics(
  getPayments,
  submitPrepaidDestiny,
  approvePayment,
  rejectPayment,
  payIssuerPayments,
  setCampaignSelected
);
