import styled from "styled-components";

export const ContainerFinancialTax = styled.div`
  width: 90%;
  padding-top: 16px;
  margin: auto;
  max-width: 1221px;

  ${({ theme }) => theme.smartphone} {
    padding: 29px 25px 0px 27px;
  }
`;

export const Header = styled.div`
  display: flex;
  ${({ theme }) => theme.smartphone} {
    flex-direction: column;
  }

  button {
    width: 156px;
    height: 40px;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 800;
  }
  h1 {
    font-size: 18px;
    font-weight: 800;
    color: ${({ theme }) => theme.riverBed};
    line-height: 25px;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.midGray};
  }
`;

export const ContainerTitle = styled.div`
  margin-left: 16px;
  align-self: center;
  ${({ theme }) => theme.smartphone} {
    margin-top: 19px;
    margin-left: 0px;
  }
`;

export const ContainerOptions = styled.div`
  margin-top: 19px;
`;
