import styled from "styled-components";
import { AboutUsFromType } from "../AboutUs";

export const HeadingContainer = styled.div<{ from: AboutUsFromType }>`
  width: 35%;
  margin: 0 auto;
  ${({ theme }) => theme.bigTablet} {
    width: 43%;
  }
  ${({ theme }) => theme.desktop} {
    width: 50%;
  }
  ${({ theme }) => theme.tablet} {
    width: 80%;
  }
  > h1 {
    font-size: 32px;
    line-height: 44px;
  }
  ${({ theme }) => theme.smartphone} {
    width: ${({ from }) =>
      from === AboutUsFromType.OurAllies ? "312px" : "242px"};
  }
  > h1 {
    font-size: 24px;
    line-height: 33px;
  }
`;
